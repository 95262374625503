export const bars_pubs = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.252721, 59.993093]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Maximilian",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_website": "https:\/\/www.maximilian-beer.ru\/",
                "contact_vk": "https:\/\/vk.com\/maximilian_brauhaus",
                "contact_instagram": "https:\/\/instagram.com\/maximilian_brauhaus",
                "contact_phone": "+7 812 9388893;+7 921 9388893",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.204686, 59.989685]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.254479, 60.016544]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.489238, 59.955405]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Портовый трактир",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.28567, 59.945513]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.387451, 59.969354]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Beer Bag",
                "type": "Бар",
                "opening_hours": "15:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309615, 60.01761]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Beer Bag",
                "type": "Бар",
                "opening_hours": "15:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309615, 60.01761]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Gastro Punx Club",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/gastropunxclub\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.290289, 59.919235]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Оазис",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.108684, 59.781127]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35255, 59.86705]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324889, 59.857335]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Wong Kar Wine",
                "type": "Бар",
                "opening_hours": "Fr-Sa 12:00-24:00;Mo-Th,Su 12:00-23:00",
                "contact_website": "https:\/\/wongkarwine.ru\/",
                "contact_vk": "https:\/\/vk.com\/wongkarwine",
                "contact_instagram": "https:\/\/instagram.com\/wongkarwine",
                "contact_phone": "+7 812 9431930",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.33946, 59.941857]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.33677, 60.045823]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Леон",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.252663, 59.994097]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Fleet-street",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.404427, 60.005787]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Ящик пива",
                "type": "Бар",
                "opening_hours": "Mo-Th 16:00-00:00; Fr 16:00-02:00; Sa 14:00-02:00; Su 14:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355288, 59.85372]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Пивница",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.207424, 60.002368]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Burger Planet",
                "type": "Бар",
                "opening_hours": "Mo-Fr 11:00-22:00; Sa-Su 13:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.380637, 59.941724]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Бар 8",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309966, 59.959737]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322119, 59.942606]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322119, 59.942606]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.37615, 59.859933]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Craft Beer",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316196, 59.855933]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Jenga",
                "type": "Бар",
                "opening_hours": "12:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.212647, 59.953308]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Вулкан, ООО, шаверма",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.268358, 59.891527]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Пеперони",
                "type": "Бар",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_website": "http:\/\/peperoni.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/peperonispb",
                "contact_instagram": "https:\/\/instagram.com\/peperoni_spb",
                "contact_phone": "+7 812 2451050",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312387, 60.015114]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Маслоу Бар",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.296893, 59.964092]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Пивляндия",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.154682, 59.849599]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "TimeWine",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284642, 59.946946]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Балканский гурман",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284827, 59.943313]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Merula винный двор",
                "type": "Бар",
                "opening_hours": "Mo-Fr 14:00-02:00; Sa-Su 11:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321516, 59.943689]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Merula винный двор",
                "type": "Бар",
                "opening_hours": "Mo-Fr 14:00-02:00; Sa-Su 11:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321516, 59.943689]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Palma",
                "type": "Бар",
                "opening_hours": "Mo-We 07:00-22:00;Sa 10:00-23:00;Su 08:00-22:00;Th-Fr 07:00-23:00",
                "contact_website": "https:\/\/palma.rest\/",
                "contact_vk": null,
                "contact_instagram": "https:\/\/instagram.com\/palma.rest",
                "contact_phone": "+7 812 9371117",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368811, 59.885212]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Live Pizza",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_website": "https:\/\/livepizza.ru\/",
                "contact_vk": "https:\/\/vk.com\/livepizza",
                "contact_instagram": null,
                "contact_phone": "+7 921 9081534",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.466005, 59.932134]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "OFFLINE lounge",
                "type": "Бар",
                "opening_hours": "Mo-Fr 15:00-03:00;Sa-Su 15:00-05:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.30524, 59.958997]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.292908, 59.959842]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Фогель",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.379803, 59.941307]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.293942, 60.029337]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "BARbolin HOUSE",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-24:00, Fr,Sa 12:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.155618, 59.869135]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Twiga",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-06:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.377352, 59.855353]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Black and White Bar",
                "type": "Бар",
                "opening_hours": "Mo-Fr 13:00-01:00;Sa-Su 16:00-03:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bw_bar",
                "contact_instagram": "https:\/\/instagram.com\/blackandwhite_bar",
                "contact_phone": "+7 921 3773234",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32609, 59.88867]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Hustle Lounge Bar",
                "type": "Бар",
                "opening_hours": "Fr-Sa 12:00-06:00;Mo-Th,Su 12:00-03:00",
                "contact_website": "https:\/\/hustleloungebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 995 8901812",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356585, 59.924674]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346401, 59.919163]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309379, 60.01763]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309379, 60.01763]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Рога",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-24:00",
                "contact_website": "https:\/\/barroga.ru\/",
                "contact_vk": "https:\/\/vk.com\/public198616552",
                "contact_instagram": "https:\/\/instagram.com\/Roga_bar",
                "contact_phone": "+7 911 9863119",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.329636, 59.821947]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Караоке Чабрец",
                "type": "Бар",
                "opening_hours": "Fr-Sa 22:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.365886, 60.031642]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Диско-бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.44579, 59.86458]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.260635, 59.930855]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Mr.Pinky",
                "type": "Бар",
                "opening_hours": "Tu-Su 17:00-00:00",
                "contact_website": "https:\/\/mrpinky.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 965 2237311",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.291375, 59.917025]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Milwaukee",
                "type": "Бар",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4358565;+7 921 5548925",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.188182, 59.989181]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Meat Makers",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.274653, 59.940395]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "La Sultana",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35264, 59.855336]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Селфи",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.269398, 59.933865]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Бар Хоп энд Гусь",
                "type": "Бар",
                "opening_hours": "Mo-Th 15:00-24:00, Fr,Sa 16:00-03:00, Su 15:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.245941, 60.034611]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Green Ray",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312919, 59.88647]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Green Ray",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312919, 59.88647]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Бар Green Gray",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312836, 59.886545]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Бар Green Gray",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312836, 59.886545]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "HarleQuin RestoBar",
                "type": "Бар",
                "opening_hours": "Mo-Th 11:00-01:00, Fr 11:00-02:00, Sa 12:00-02:00, Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/barharlequin",
                "contact_instagram": null,
                "contact_phone": "+7 911 9206443",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322394, 59.902591]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.364207, 59.940049]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Еще по одной",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.325088, 59.854783]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324115, 60.036383]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Лобби-бар заблудшего дракона",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327745, 59.937597]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Чебуреки и рюмочная у Ларисы",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/larisochnaya",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.810794, 60.18517]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Караоке Che-Dor",
                "type": "Бар",
                "opening_hours": "Сб 21:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.923502, 60.150253]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Завтрак туриста",
                "type": "Бар",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9797000",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": null,
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.941727, 60.15164]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 60",
                "name": "Бар разбитых сердец Тельмы и Луизы",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.283107, 59.947599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-я Красноармейская улица, 9\/3",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barspb_official",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.313506, 59.915427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-я линия Васильевского острова, 41\/13",
                "name": "deep",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.283672, 59.944932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 23",
                "name": "РосАл",
                "type": "Бар",
                "opening_hours": "11:00-22:00 open \"магазин и бар\", 22:00-11:00 open \"бар\"",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.369354, 59.931233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 47",
                "name": "Wine bar 74",
                "type": "Бар",
                "opening_hours": "Mo-Th 13:00-23:00; Fr-Sa 13:00-00:00; Su 13:00-23:00",
                "contact_website": "http:\/\/winebar74.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9954794",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.27848, 59.946558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 6-я Красноармейская улица, 1",
                "name": "One Love",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/ванлав.рф\/",
                "contact_vk": "https:\/\/vk.com\/onelove_lounge",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.317521, 59.912589]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 14",
                "name": "Mini",
                "type": "Бар",
                "opening_hours": "Mo-Su 10:30-20:30",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284032, 59.937977]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 28",
                "name": "Sultan",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.2814, 59.940235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 42",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": "Su-Th 13:00-01:00, Fr-Sa 13:00-03:00",
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barspb_official",
                "contact_instagram": "https:\/\/www.instagram.com\/spb_bar\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.279423, 59.941993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 45\/34",
                "name": "Бr?t & Буфет",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 9136556",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.276872, 59.942038]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 55",
                "name": "Пивной бутик 1516",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-00:00",
                "contact_website": "http:\/\/butik1516.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.274905, 59.943755]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 10",
                "name": "Giani",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.372255, 59.937414]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 24",
                "name": "Амазонка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.278256, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 11",
                "name": "Варшава",
                "type": "Бар",
                "opening_hours": "Tu-Th, Sa, Su 16:00-00:00; Mo 16:00-01:30; Fr 16:00-03:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/warszawa_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320018, 59.931436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 11",
                "name": "Борода",
                "type": "Бар",
                "opening_hours": "Mo-Fr 16:00-02:00; Sa-Su 16:00-04:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/borodabar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320018, 59.931436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 26\/27",
                "name": "Стирка 40°",
                "type": "Бар",
                "opening_hours": "11:00-23:00",
                "contact_website": "http:\/\/40gradusov.ru\/",
                "contact_vk": "https:\/\/vk.com\/stirka40",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.317808, 59.930561]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 26\/27",
                "name": "summer bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.317808, 59.930561]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 18",
                "name": "W Garage",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-24:00, Fr-Sa 12:00-2:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 9224892",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32071, 59.974342]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Афонская улица, 25",
                "name": "BeerFass",
                "type": "Бар",
                "opening_hours": "12:00-24:00",
                "contact_website": "https:\/\/vk.com\/beerfass",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 9838547",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.303489, 60.026145]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Байконурская улица, 13к2",
                "name": "Лео клуб",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "http:\/\/clubleo.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7(812)394-62-48;+7(812)991-03-25",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.276181, 60.002391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "name": "Шиллинг",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Байконурская улица, 15",
                "name": "BeerGrad",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.276279, 60.002764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Банковский переулок, 3",
                "name": "Mad Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/mad_bar_spb",
                "contact_instagram": null,
                "contact_phone": "+7 812 9593985",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323953, 59.930543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Банковский переулок, 3",
                "name": "Parabellum",
                "type": "Бар",
                "opening_hours": "18:00+",
                "contact_website": "http:\/\/military-bar.ru\/",
                "contact_vk": "https:\/\/vk.com\/strikebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323953, 59.930543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Банковский переулок, 3",
                "name": "Nico Piano Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323953, 59.930543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Банковский переулок, 6",
                "name": "Бермуды",
                "type": "Бар",
                "opening_hours": "18:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bar_bermudy",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32504, 59.930219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бармалеева улица, 4",
                "name": "Barmaley Bar",
                "type": "Бар",
                "opening_hours": "Su-Th 15:00-01:00; Fr,Sa 14:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/barmaleybar4",
                "contact_instagram": "https:\/\/www.instagram.com\/barmaleybar4\/",
                "contact_phone": "+7 921 7584156",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.30728, 59.963521]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Басков переулок, 20",
                "name": "13й кран",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359841, 59.939631]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Басков переулок, 23",
                "name": "Spontan",
                "type": "Бар",
                "opening_hours": "Mo-Th 16:00-01:00, Fr 16:00-02:00, Sa 14:00-02:00, Su 14:00-01:00",
                "contact_website": "https:\/\/www.facebook.com\/SpontanBarRussia\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358206, 59.93937]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Биржевой переулок,  4",
                "name": "Хоттабыч",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.293563, 59.945017]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Биржевой переулок, 2",
                "name": "Биржа",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.293563, 59.945017]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 10",
                "name": "Ziga",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.280861, 59.998364]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 55к1",
                "name": "Пивомания",
                "type": "Бар",
                "opening_hours": "12:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bogatyrskiy_55",
                "contact_instagram": null,
                "contact_phone": "+7 931 3063611",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.213891, 60.00715]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 1",
                "name": "Daiquiri",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-04:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/daiquiribar",
                "contact_instagram": null,
                "contact_phone": "+7 921 9438114",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324151, 59.941029]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 2",
                "name": "Endorphin",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/endorphinbar_official",
                "contact_instagram": "https:\/\/www.instagram.com\/endorphinbar_official\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.939555]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 2",
                "name": "le Tour de Vin",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-01:00; Fr-Sa 18:00-03:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/letourdevin",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.939555]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 2",
                "name": "JoJo",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/jojobarspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.939555]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 31",
                "name": "ZanaveS",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/zsgroup.org\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321914, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 31",
                "name": "Библиотека вкусов",
                "type": "Бар",
                "opening_hours": "Su-Th 17:00-23:00; Fr-Sa 17:00-01:00",
                "contact_website": "http:\/\/ilovenevsky.ru\/",
                "contact_vk": "https:\/\/vk.com\/bibliotekavkusov",
                "contact_instagram": null,
                "contact_phone": "+7 812 2441594",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321914, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "name": "Roomie Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/roomiebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/roomiebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 11",
                "name": "Ленинград",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.960976]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 13",
                "name": "TX Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.935209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 13",
                "name": "Тихий Ход",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+79119352169",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.935209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 17",
                "name": "WTF",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/wtf_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314916, 59.934925]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 36",
                "name": "Spirit",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-24:00, Fr-Sa 12:00-02:00",
                "contact_website": "https:\/\/spiritgastro.bar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9801566",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312014, 59.933149]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 39",
                "name": "Lichfield",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.roccofortehotels.com\/hotels-and-resorts\/hotel-astoria\/restaurants-and-bars\/lichfield-bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 494 5757",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309239, 59.932901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Посадская улица, 1",
                "name": "3 этаж",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 7930000",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322345, 59.957917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 3",
                "name": "beerhere",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.297516, 59.954791]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 38",
                "name": "Пушкарь",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.305978, 59.960494]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Разночинная улица, 15",
                "name": "#BeerHERE",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/beer_here",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.289377, 59.960219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 24",
                "name": "VinoVeka Shop&Wine bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.282002, 59.938374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 8\/4",
                "name": "Maler",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.28767, 59.94006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 26\/2",
                "name": "Барвин",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-03:00",
                "contact_website": "http:\/\/barvinbar.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9440026",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.29642, 59.95639]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 45",
                "name": "Большой бар",
                "type": "Бар",
                "opening_hours": "Su-Th 09:00-01:00; Fr-Sa 09:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.304675, 59.960994]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 46\/1",
                "name": "Твид Энди ит",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.30136, 59.959512]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 47",
                "name": "Yoda Noodle Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/facebook.com\/yodanoodlebar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.305043, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 50",
                "name": "Топка",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-02:00",
                "contact_website": "https:\/\/topka.me\/",
                "contact_vk": "https:\/\/m.vk.com\/topkakoptilnya",
                "contact_instagram": "https:\/\/www.instagram.com\/topka_koptilnya\/",
                "contact_phone": "+7 812 6400885",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302501, 59.960174]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 65",
                "name": "Дегустатор",
                "type": "Бар",
                "opening_hours": "Mo-Th 16:00-00:00 open; Fr 16:00-02:00 open; Sa 14:00-02:00 open; Su 14:00-00:00 open",
                "contact_website": "https:\/\/vk.com\/degustatorbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 1997752",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309257, 59.963769]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 79",
                "name": "KillFish",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-03:00",
                "contact_website": "https:\/\/killfish.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4480800",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.313811, 59.966457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 45",
                "name": "Мирбир",
                "type": "Бар",
                "opening_hours": "Mo-Su,PH 11:00-20:00",
                "contact_website": "https:\/\/www.mirbeer.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.341982, 59.969601]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 79",
                "name": "Боржч",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/borzch.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.334769, 59.98864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боровая улица, 14",
                "name": "White",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/white_bar01",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348181, 59.921011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боровая улица, 6",
                "name": "Don Kraft",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/dkbeer",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348684, 59.921958]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боровая улица, 6",
                "name": "Рюмочная на Боровой",
                "type": "Бар",
                "opening_hours": "Tu-Su 14:00-23:00",
                "contact_website": "https:\/\/vk.com\/barnaborovoy",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 931 3481338",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348684, 59.921958]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боровая улица, 7",
                "name": "Dion",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/dionloungebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347857, 59.921759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 4",
                "name": "Beer House",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320719, 59.916545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 7",
                "name": "Трактир на Бронницкой",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320521, 59.916018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Будапештская улица, 44",
                "name": "Престиж",
                "type": "Бар",
                "opening_hours": "Fr-Su 11:00-04:00; Mo-Th 11:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.378984, 59.858363]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Будапештская улица, 49к1",
                "name": "Дружба",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.382038, 59.852692]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 47",
                "name": "BBQ",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.390581, 59.859406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 74",
                "name": "Контакт Бар",
                "type": "Бар",
                "opening_hours": "Mo,Tu,We,Th,Su 16:00-03:00; Fr-Sa 15:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9987499",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.381688, 59.870867]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 74",
                "name": "Скорпион",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.381688, 59.870867]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 74к3",
                "name": "спорт-бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.381625, 59.871346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Варшавская улица, 23к3",
                "name": "Beer House",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.313281, 59.866463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Варшавская улица, 6к2",
                "name": "Hopkins",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315266, 59.879353]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Владимирский проспект",
                "name": "Почта",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/pochtabar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347758, 59.930796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 15",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barspb_official",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347309, 59.929276]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 17",
                "name": "Контакт бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/kontaktbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9518395",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347156, 59.928771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 1",
                "name": "Xander",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.fourseasons.com\/stpetersburg\/dining\/lounges\/xander_bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308143, 59.934948]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 13",
                "name": "Вознесенский 13",
                "type": "Бар",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-03:00",
                "contact_website": "http:\/\/bar-voznesenskiy.ru\/",
                "contact_vk": "https:\/\/vk.com\/voznesenskiy13",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308026, 59.928929]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 18",
                "name": "Мясная лавка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/m-lvk.ru\/",
                "contact_vk": "https:\/\/vk.com\/m_lavka",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308628, 59.929353]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 26",
                "name": "Все твои друзья",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/vtd_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308421, 59.927658]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 39",
                "name": "5 перцев",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/hookahpacman",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.307792, 59.923527]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 4",
                "name": "Library",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/m.vk.com\/barlibrary",
                "contact_vk": "https:\/\/vk.com\/barlibrary",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308897, 59.935227]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Воронежская улица, 6-8",
                "name": "Горбыль",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.349824, 59.91835]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гаванская улица, 37",
                "name": "Гавань",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.23917, 59.935489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 33к1",
                "name": "Бирлога",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.258726, 60.005185]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Галерная улица, 18",
                "name": "Крокодил",
                "type": "Бар",
                "opening_hours": "12:30-24:00",
                "contact_website": "http:\/\/bar-crocodile.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3149437",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.298063, 59.934263]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гапсальская улица, 5",
                "name": "Vinissimo",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.257217, 59.911223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гончарная улица, 23",
                "name": "sourgood",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.367746, 59.928411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 24\/24",
                "name": "I’m Thankful for Today",
                "type": "Бар",
                "opening_hours": "Mo-Th 10:00-01:00, Fr-Sa 10:00-02:00, Su 11:00-01:00",
                "contact_website": "https:\/\/www.coffeeroomspb.com\/thankful",
                "contact_vk": "https:\/\/vk.com\/coffeeroomspb",
                "contact_instagram": null,
                "contact_phone": "+7 911 9240249",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318949, 59.930782]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 26",
                "name": "Rockets & Bishops",
                "type": "Бар",
                "opening_hours": "Su-Th 13:00-00:00, Fr-Sa 13:00-02:00",
                "contact_website": "http:\/\/www.bakuningroup.com\/rnb.html",
                "contact_vk": "https:\/\/vk.com\/rocketsandbishops",
                "contact_instagram": "rocketsandbishops",
                "contact_phone": "+7 812 9850814",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319875, 59.93016]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 28",
                "name": "Cuba",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/cubabar.ru\/",
                "contact_vk": "https:\/\/vk.com\/cuba28bar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320189, 59.929984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 31",
                "name": "Лаборатория 31",
                "type": "Бар",
                "opening_hours": "15:00-08:00",
                "contact_website": "http:\/\/laborbar31.ru\/",
                "contact_vk": "https:\/\/vk.com\/laborbar31",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319407, 59.930002]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 32",
                "name": "Рядом",
                "type": "Бар",
                "opening_hours": "18:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5702457",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.929434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 42",
                "name": "Sabotage",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-01:00, Fr-Sa 15:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/sbtgbar",
                "contact_instagram": null,
                "contact_phone": "+7 951 6764041",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324222, 59.927234]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 46",
                "name": "Декабрист",
                "type": "Бар",
                "opening_hours": "12:00-23:30",
                "contact_website": "https:\/\/www.decabrist.net\/steakbar",
                "contact_vk": "https:\/\/vk.com\/cafedecabrist",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324995, 59.926679]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 57",
                "name": "Wine me",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-23:00, Fr 12:00-01:00, Sa 13:00-01:00, Su 13:00-23:00",
                "contact_website": "https:\/\/wineme.business.site\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.325821, 59.925719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 66",
                "name": "Творчество",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-24:00, Fr-Sa 12:00-03:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/tvorchestvobar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330951, 59.92281]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "name": "Громов",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/gromovbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6458889",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "name": "Feromon",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/feromon.group\/feromon-na-grazhdanskom.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 969?77?77",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 36",
                "name": "Illi",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.394138, 60.010938]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Графский переулок, 3",
                "name": "La Perla Seafood Bar",
                "type": "Бар",
                "opening_hours": "Mo-Su  14:00-02:00",
                "contact_website": "https:\/\/laperlarest.ru",
                "contact_vk": "https:\/\/vk.com\/laperlarubinsteina",
                "contact_instagram": null,
                "contact_phone": "+7 921 3666893",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344336, 59.930701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Графский переулок, 3",
                "name": "Boozy Street",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/boozystreet.ru\/",
                "contact_vk": "https:\/\/vk.com\/boozystreet",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344336, 59.930701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 57к1",
                "name": "Paluba",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.467836, 59.883133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дачный проспект, 18",
                "name": "Chika bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.255043, 59.8424]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 7",
                "name": "Fidel",
                "type": "Бар",
                "opening_hours": "Mo-Su 20:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club6021859",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327762, 59.933176]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 9",
                "name": "Belgrad",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/belgrad-bar.ru\/",
                "contact_vk": "https:\/\/vk.com\/public285658",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327322, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 9",
                "name": "Дача",
                "type": "Бар",
                "opening_hours": "Mo-Th,Su 18:00-03:00; Fr-Sa 18:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bardacha",
                "contact_instagram": "https:\/\/www.instagram.com\/dacha.bar\/",
                "contact_phone": "+7 911 7654578",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327322, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 9",
                "name": "Clabnika",
                "type": "Бар",
                "opening_hours": "Su-Th 23:00-06:00; Fr-Sa 22:00-08:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/clabnikaroom",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327322, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 9",
                "name": "Duma",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/barduma",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327322, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 9",
                "name": "GS",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327322, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица, 9",
                "name": "National",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327322, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "name": "Рамка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Железнодорожный переулок, 8",
                "name": "Panama club",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6174826",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326001, 60.001244]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 32",
                "name": "KillFish 2.0",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-02:00",
                "contact_website": "https:\/\/killfish.ru\/",
                "contact_vk": "https:\/\/vk.com\/killfish20",
                "contact_instagram": null,
                "contact_phone": "+7 812 4480800",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.338676, 59.924438]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 39",
                "name": "Bar-B-Q",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/bbq_cafe_grill\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.332128, 59.922251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 41-43",
                "name": "Крепость 24",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "https:\/\/krepost24.ru\/",
                "contact_vk": "https:\/\/vk.com\/public141065266",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330699, 59.921651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 2А",
                "name": "Пивоварни",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.395953, 59.84993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 38",
                "name": "Матрица Караоке-бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 19:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/matrix_karaoke",
                "contact_instagram": null,
                "contact_phone": "+7 812 4492365",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.431077, 59.933099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заставская улица, 33Д",
                "name": "Крафтовый бар BrewCrew",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-23:00; Fr-Sa 12:00-01:00; Su 12:00-23:00",
                "contact_website": "http:\/\/www.vk.com\/gokerclub",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7(812)9239109",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.317871, 59.890112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Звёздная улица, 2",
                "name": "Дружба",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344677, 59.832118]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 1к2",
                "name": "Doski",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6124330",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335802, 59.922314]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 2\/44",
                "name": "Дружба",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "https:\/\/bar-2510.business.site\/",
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/rumkadrujba\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.336017, 59.92309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зверинская улица, 42",
                "name": "42",
                "type": "Бар",
                "opening_hours": "\"by appointment\"",
                "contact_website": "http:\/\/42bar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 904 5501332",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.303435, 59.952439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Средний проспект, 28",
                "name": "Контакт Бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 13:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9667719",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.68018, 60.195015]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 13",
                "name": "Чайхана \"ПроПлов\"",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.441578, 59.874982]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 17",
                "name": "Кальянная Feromon Freemen's club",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/feromon.group\/freemens.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 907?00?95",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.439854, 59.874454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 17",
                "name": "Санскрит",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.439854, 59.874454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 7",
                "name": "Корюшка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "www.petsburg.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.446052, 59.876233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 8\/77",
                "name": "Рундук",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.444588, 59.875239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 8\/77",
                "name": "Роджер",
                "type": "Бар",
                "opening_hours": "Mo-Su 17:00-02:00",
                "contact_website": "https:\/\/vk.com\/pubroger",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 962 690-10-18",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.444588, 59.875239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Инструментальная улица, 3В",
                "name": "Big Wine Freaks",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-1:00, Fr-Sa 18:00-3:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 9386063",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324267, 59.974369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 25",
                "name": "Jasmin",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "http:\/\/jasmine-cafe.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.337293, 59.9358]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 33",
                "name": "Prostovino",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 931 2760871",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.340248, 59.935435]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кадетская линия, 19",
                "name": "Chalet",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.287257, 59.944688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Киевская улица, 12",
                "name": "Farang",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": "http:\/\/farangbar.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (921) 866-39-03",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.325624, 59.903228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 13",
                "name": "DERЖИСЬ",
                "type": "Бар",
                "opening_hours": "Mo-Th 13:00-02:00, Fr-Sa 15:00-05:00, Su 16:00-02:00",
                "contact_website": "http:\/\/www.derzhis.ru\/",
                "contact_vk": "https:\/\/vk.com\/bar_derzhis",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355026, 59.943796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Клочков переулок, 6к1",
                "name": "SPb",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.46179, 59.919612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ковенский переулок, 14",
                "name": "Utopist",
                "type": "Бар",
                "opening_hours": "15:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/utopist.bar\/",
                "contact_phone": "+7 921 3392774",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359005, 59.937481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ковенский переулок, 20",
                "name": "Respawn",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/respawnbarspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361871, 59.937481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40",
                "name": "Пивмастерия 17М",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.242036, 59.924397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Е",
                "name": "Kuta",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.kutabar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.239754, 59.924957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колокольная улица, 2\/18",
                "name": "El Copitas",
                "type": "Бар",
                "opening_hours": "Th-Sa 19:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9417168",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348755, 59.929033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 11",
                "name": "Simple",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/gastrosimplebarr",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354235, 59.925196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 3",
                "name": "A Guest",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/aguestloungebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354936, 59.92626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 9",
                "name": "Mr. Toad Bar",
                "type": "Бар",
                "opening_hours": "18:00-02:00; Fr,Sa 18:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/mrtoadbar",
                "contact_instagram": null,
                "contact_phone": "+7 965 8132590",
                "wheelchair": "no",
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354379, 59.925353]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 9",
                "name": "mr toad",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354379, 59.925353]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 20",
                "name": "Пивной стандарт",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.298315, 59.998774]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, проспект Ленина, 53",
                "name": "New York",
                "type": "Бар",
                "opening_hours": "Mo-Su 20:00-09:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/clubnewyorkk",
                "contact_instagram": null,
                "contact_phone": "7?981?981?02?02",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.570424, 59.75053]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, улица Максима Горького, 4",
                "name": "Код",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_website": "http:\/\/kodbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4645640",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.577145, 59.808594]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 17к1",
                "name": "BeerRoom",
                "type": "Бар",
                "opening_hours": "14:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.255591, 60.012539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "name": "VNVNC",
                "type": "Бар",
                "opening_hours": "Fr-Sa 21:00-08:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/vnvnc",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "name": "1899",
                "type": "Бар",
                "opening_hours": "Mo-Th 18:00-02:00; Fr-Su 16:00-06:00",
                "contact_website": "https:\/\/vk.com\/bar1899",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 5692999",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Костромской проспект, 30",
                "name": "Рюмка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316695, 60.017292]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Костромской проспект, 30",
                "name": "Best Beer",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316695, 60.017292]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, Гвардейская улица, 5",
                "name": "Пивнович",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.088148, 59.735963]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 61к1",
                "name": "Red city",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.08883, 59.737509]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельская улица, 16",
                "name": "На ход ноги",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.294614, 59.958061]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кременчугская улица, 11к1",
                "name": "Пивная Культура",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.371222, 59.922878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 27Б",
                "name": "Лаундж Бар GTA",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.317656, 59.957543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 45",
                "name": "Магазин пива",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312616, 59.956922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 53",
                "name": "Спецбуфет №1",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.307999, 59.955376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 65",
                "name": "На вина!",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-23:00; Fr 12:00-24:00; Sa 00:00-02:00,12:00-24:00; Su 00:00-02:00,12:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9127400",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.306292, 59.953673]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Восстания, 13",
                "name": "Клуб КАЗЕМАТ",
                "type": "Бар",
                "opening_hours": "Tu-Su 17:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6678586",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.768283, 60.001712]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Карла Маркса, 9",
                "name": "В дрова",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/v_drova_bar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.768883, 59.998258]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Комсомола, 5\/1",
                "name": "На троих",
                "type": "Бар",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.7874, 59.99197]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадтская улица, 22",
                "name": "рюмочная \"Пять капель\"",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.257774, 59.868297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадтская улица, 24",
                "name": "Батя",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.258035, 59.867484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 15",
                "name": "Hama.ru",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.384481, 59.838819]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 15к1",
                "name": "Айсберг",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.38494, 59.839086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 23",
                "name": "БиллиYard",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.39138, 59.833407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 32к1Д",
                "name": "РазлиВНович",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.399789, 59.828903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курляндская улица, 48",
                "name": "AF Brew Taproom",
                "type": "Бар",
                "opening_hours": "Th-Su 15:00-22:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/afbrew.taproom",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.267314, 59.91111]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курляндская улица, 49",
                "name": "Братство Пивца",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/fellowship.beer",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.267862, 59.91162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 3к2",
                "name": "Berry Wood",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.369704, 59.987992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лахтинская улица",
                "name": "Маслоу Бар",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.30039, 59.962679]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лахтинская улица, 8",
                "name": "Ателье Tapas&Bar",
                "type": "Бар",
                "opening_hours": "12:30-01:00",
                "contact_website": "https:\/\/www.facebook.com\/ateliertapasbar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9040855",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302393, 59.96167]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 119Б",
                "name": "Alibi",
                "type": "Бар",
                "opening_hours": "Mo-Fr 17:00-00:00; Sa-Su 14:00-00:00",
                "contact_website": "http:\/\/vk.com\/baralibi",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2404704",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.258322, 59.851345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 121к5",
                "name": "Snob bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6002300",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.261277, 59.851408]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 129",
                "name": "Chelsea",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.270045, 59.851376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 164",
                "name": "Brewski Craft beer",
                "type": "Бар",
                "opening_hours": "Mo-Su 13:00-22:00",
                "contact_website": "http:\/\/vk.com\/brewski",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 981 7021132",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308196, 59.852764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 72к1",
                "name": "Tsun-ami Bar",
                "type": "Бар",
                "opening_hours": "Mo-Th, Su 14:00-02:00; Fr, Sa 14:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.182235, 59.861494]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 72к1",
                "name": "Миру Beer",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.182235, 59.861494]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 72к1",
                "name": "TastyPizzaBar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.182235, 59.861494]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 31",
                "name": "Beer Here",
                "type": "Бар",
                "opening_hours": "Mo-Su 14:00-01:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/beer_here",
                "contact_instagram": null,
                "contact_phone": "+7 812 9225587",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.295899, 59.918268]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 8\/10А",
                "name": "Арарат",
                "type": "Бар",
                "opening_hours": "11:00-23:00",
                "contact_website": "http:\/\/www.ararat-wine.ru\/",
                "contact_vk": "https:\/\/vk.com\/araratwine",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.292117, 59.923482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лесной проспект, 9",
                "name": "У Швондера",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351971, 59.963453]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский переулок, 50В",
                "name": "Дюны",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-24:00+",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.363281, 59.924267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 107",
                "name": "Райский сад",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/rayskiysad",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353481, 59.920533]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 111-113-115В",
                "name": "Золотая рыбка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352744, 59.919477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 117",
                "name": "Lockout",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/lockoutbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352474, 59.918972]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 289Б",
                "name": "House",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330385, 59.895172]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 39",
                "name": "Clouds",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/clouds_bar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361161, 59.932153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50\/13",
                "name": "Бродяги Дхармы",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.362203, 59.923414]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50\/13",
                "name": "Andy bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.362203, 59.923414]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50\/13",
                "name": "Ящик",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.362203, 59.923414]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50\/13",
                "name": "Flamel",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361431, 59.922995]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50П",
                "name": "Poison",
                "type": "Бар",
                "opening_hours": "Tu-Th 19:00-02:00, Fr-Sa 19:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/poisonkaraoke",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361709, 59.925317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50П",
                "name": "1703",
                "type": "Бар",
                "opening_hours": "Su-Th 19:00-03:00; Fr-Sa 19:00-06:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361709, 59.925317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50У",
                "name": "Поддон",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "vk.com\/poddonbar_spb",
                "contact_instagram": "https:\/\/www.instagram.com\/poddonbar",
                "contact_phone": "+7-931-00-666-13",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360919, 59.92295]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50Ф",
                "name": "Неканон",
                "type": "Бар",
                "opening_hours": "Su-Fr 13:00-23:00; Fr,Sa 13:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+79657491654",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361152, 59.925101]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 51",
                "name": "Staff",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/staffbarspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359499, 59.929393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "name": "Выдержка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/viderzhka_bar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "name": "Баланс белого",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 93",
                "name": "Бургер Хаб",
                "type": "Бар",
                "opening_hours": "Fr-Sa 13:00-01:00; Su-Th 11:00-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/burgerhub_spb",
                "contact_instagram": "https:\/\/www.instagram.com\/burgerhub_spb\/",
                "contact_phone": "+7 999 2185242",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355538, 59.923284]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 26",
                "name": "Professor Преображенский",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/lobbiprofessor",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348998, 59.941912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 36",
                "name": "Mint",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-06:00, Fr-Sa 18:30-07:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/mintbarspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348764, 59.939424]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 53",
                "name": "Smoke on the water гриль-бар и кальянная",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/teahouse-group.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 8349475",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347704, 59.936332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 55",
                "name": "Union",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-04:00, Fr-Sa 18:00-06:00",
                "contact_website": "http:\/\/unionbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/barunion",
                "contact_instagram": null,
                "contact_phone": "+7 812 6445414",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347731, 59.935669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 57",
                "name": "Гадкий Койот",
                "type": "Бар",
                "opening_hours": "20:00-06:00",
                "contact_website": "http:\/\/coyoteugly.ru\/sankt-peterburg\/",
                "contact_vk": "https:\/\/vk.com\/coyoteuglyspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347686, 59.934786]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 61",
                "name": "баревич",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347507, 59.933677]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 64\/78",
                "name": "Филин Гуд",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348423, 59.933099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 64\/78",
                "name": "Джин-Тоник",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348423, 59.933099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Красноармейская улица, 15",
                "name": "Магазин разливного пива",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.774668, 59.907297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Победы, 21",
                "name": "Diamond",
                "type": "Бар",
                "opening_hours": "Su-Th 11:00-24:00; Fr-Sa 11:00-02:00",
                "contact_website": "http:\/\/vk.com\/diamondbar",
                "contact_vk": "https:\/\/vk.com\/diamondbar",
                "contact_instagram": null,
                "contact_phone": "7 (812) 313?222?4",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.769617, 59.903612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Львиный переулок, 1",
                "name": "Гараж",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/гаражбар.рф\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.300705, 59.92731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 14",
                "name": "ToNYc",
                "type": "Бар",
                "opening_hours": "Mo 12:00-01:00, Tu-Fr 12:00-02:00, Sa 13:00-02:00, Su 13:00-01:00",
                "contact_website": "http:\/\/tonycbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/tonycbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.311233, 59.934731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 18",
                "name": "Prof. Freud",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31029, 59.934231]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 3",
                "name": "Horror bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320872, 59.957642]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект, 10",
                "name": "Литрбол",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/liter_ball",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327906, 59.913546]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 5",
                "name": "Хоккейный пивной клуб Off Season",
                "type": "Бар",
                "opening_hours": "Su-Th 14:00-00:00; Fr-Sa 14:00-01:00",
                "contact_website": "http:\/\/hbc-offseason.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.277986, 59.948271]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мебельная улица, 35к2",
                "name": "LIEBENBIER",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.199572, 59.995638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мебельная улица, 47к1",
                "name": "4friends",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-02:00; Fr-Sa 16:00-04:00",
                "contact_website": "https:\/\/vk.com\/bar4friends",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9809586",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.196985, 59.994346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Москательный переулок, 1",
                "name": "Monte Cristo",
                "type": "Бар",
                "opening_hours": "12:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/montecristo_lounge_bar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321932, 59.930863]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 136",
                "name": "Весёлый Роджер",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320405, 59.8873]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 153",
                "name": "Mozzarella",
                "type": "Бар",
                "opening_hours": "Mo-Fr 09:00-24:00, Sa,Su 10:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319704, 59.872236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 163",
                "name": "Кофейная Гамма",
                "type": "Бар",
                "opening_hours": "Mo-Th 09:00-01:00, Fr,Sa 09:00-03:00, Su 09:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31992, 59.867231]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 166",
                "name": "Чешска Пивница",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320818, 59.879128]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 173",
                "name": "Кальянная Feromon Amsterdam Bar",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/feromon.group\/amsterdam.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 907?00?98",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319416, 59.861123]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 183-185",
                "name": "Craft Beer",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319839, 59.855331]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 30",
                "name": "БАР.ИН",
                "type": "Бар",
                "opening_hours": "Mo-Fr 09:30-21:00",
                "contact_website": "http:\/\/stud-bar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318509, 59.915819]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 73к5",
                "name": "MSM Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/msmbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/msmbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314808, 59.904189]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 97",
                "name": "Korovabar",
                "type": "Бар",
                "opening_hours": "Mo-Th 11:00-01:00; Fr 11:00-02:00; Sa 13:00-02:00; Su 13:00-000:00",
                "contact_website": "http:\/\/korovabar.info",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316722, 59.893304]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Моховая улица, 31",
                "name": "Буковски",
                "type": "Бар",
                "opening_hours": "Mo-Th 18:00-02:00, Fr-Sa 15:00-04:00, Su 15:00-02:00",
                "contact_website": "https:\/\/vk.com\/bar_bukovski_spb",
                "contact_vk": "https:\/\/vk.com\/bar_bukovski_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344848, 59.940898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Моховая улица, 45",
                "name": "Mainstream",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/barmainstream",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344794, 59.93887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Муринская дорога, 68к1",
                "name": "Live Beer",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.45738, 60.032047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мучной переулок, 1\/38",
                "name": "Hugs",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/hugsbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321177, 59.930633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мучной переулок, 5",
                "name": "Арт лаб Medovarus",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322399, 59.930011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мучной переулок, 9\/27",
                "name": "Bloody Mary",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/bloodymarybar.ru\/",
                "contact_vk": "https:\/\/vk.com\/bloodymarybar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323459, 59.929524]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мытнинская улица, 4\/48",
                "name": "Бар 17",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bar17spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.374312, 59.931116]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 1",
                "name": "Новая Голландия",
                "type": "Бар",
                "opening_hours": "12:30-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/novodutch",
                "contact_instagram": null,
                "contact_phone": "+7 812 3146240",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.291443, 59.931427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "name": "Бекицер",
                "type": "Бар",
                "opening_hours": "Mo-Fr 12:00-23:00; Sa,Su 11:00-23:00",
                "contact_website": "http:\/\/bekitzer.ru\/",
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/bekitzer_spb\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "name": "Рюмочная в Бутылке",
                "type": "Бар",
                "opening_hours": "Mo-Th 14:00-23:00, Sa,Su 12:00-24:00; Fr 14:00-24:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/vipil_zakusi",
                "contact_instagram": null,
                "contact_phone": "+7 965 7477441",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 107\/6",
                "name": "Господа! Товарищи!",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/gospodatovarishchi",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.299932, 59.925872]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 19",
                "name": "Цоколь крафт бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/instagram.com\/soklecraft?r=nametag",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326199, 59.936237]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 19",
                "name": "Крафт бар Цоколь",
                "type": "Бар",
                "opening_hours": "Mo-Fr 16:00-00:00; Sa-Su 12:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326199, 59.936237]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "name": "Resident",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/residentbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "name": "СССР",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/cccpbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 27",
                "name": "Full House",
                "type": "Бар",
                "opening_hours": "12:00-24:00",
                "contact_website": "http:\/\/fullhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/fullhousebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324645, 59.932522]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 28\/1",
                "name": "Явка Шпионки",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.sakvojagcafe.ru\/javka",
                "contact_vk": "https:\/\/vk.com\/barshpionkispb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32592, 59.93259]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 28\/1",
                "name": "Руки вверх",
                "type": "Бар",
                "opening_hours": "Su-Th 17:00-05:00; Fr-Sa 17:00-06:00",
                "contact_website": "http:\/\/9994949.ru\/",
                "contact_vk": "https:\/\/vk.com\/bar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9994949",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326028, 59.932459]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 30-32Ж",
                "name": "Soul Kitchen",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.soulkitchen.ru\/",
                "contact_vk": "https:\/\/vk.com\/soulkitchenbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327968, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 30-32Ж",
                "name": "Apotheke",
                "type": "Бар",
                "opening_hours": "20:00+",
                "contact_website": "http:\/\/hatgroup.ru\/apotheke-bar\/",
                "contact_vk": "https:\/\/vk.com\/apothekebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327968, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 30-32Ж",
                "name": "Голубая устрица",
                "type": "Бар",
                "opening_hours": "19:00-06:00",
                "contact_website": "https:\/\/www.boyster.ru\/",
                "contact_vk": "https:\/\/vk.com\/boysterbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327968, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 31",
                "name": "Пиф-Паф",
                "type": "Бар",
                "opening_hours": "Mo-Fr 10:00-01:00; Sa, Su 10:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/pifpafbar",
                "contact_instagram": "https:\/\/www.instagram.com\/pifpafbar\/",
                "contact_phone": "+7 812 3126227",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323612, 59.932067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 50",
                "name": "Every Dog Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/everydogbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315859, 59.927991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 7",
                "name": "Dom7",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/dom_7",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327367, 59.939068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 91",
                "name": "Знахарь",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bar.znahar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.303211, 59.927346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 23Я",
                "name": "Old Dogs",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.olddogsspb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.297938, 59.919761]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 10",
                "name": "Butterfly",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.293761, 59.945472]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 14",
                "name": "Buterbrodskybar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.29059, 59.945986]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 46",
                "name": "Фрау Мюллер",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-23:00; Fr 12:00-01:00; Sa 14:00-01:00; Su 14:00-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fraumullerbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9845025",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353166, 59.914227]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 5С",
                "name": "Hi-Hat",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318303, 59.969587]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 59",
                "name": "Атланты",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.taleonimperialhotel.com\/restaurant\/st-petersburg-atlantes-lobby-bar-st-petersburg\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318563, 59.935764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 59",
                "name": "Грибоедов",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.taleonimperialhotel.com\/restaurant\/st-petersburg-griboedov-gastro-bar-georgian-cuisine-st-petersburg\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318563, 59.935764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 99",
                "name": "Nove",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/dominarussia.com\/dining-and-events\/nove",
                "contact_vk": "https:\/\/vk.com\/bar.nove",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.304289, 59.931062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 109",
                "name": "Парашют",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/barparachute.ru\/",
                "contact_vk": "https:\/\/vk.com\/parabar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319165, 59.922896]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 129",
                "name": "Mustang",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/hookahmustang.com\/",
                "contact_vk": "https:\/\/vk.com\/hookahmustang",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.306391, 59.919581]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 142",
                "name": "Sky bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.294659, 59.915693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 17",
                "name": "Продукты",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-00:00, Fr-Sa 16:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fontanka17",
                "contact_instagram": null,
                "contact_phone": "+7 812 3125754",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343141, 59.935858]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 18Г",
                "name": "Sandwich Club",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343141, 59.941092]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "name": "Utka",
                "type": "Бар",
                "opening_hours": "Mo-Su 18:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 905 2247990",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "name": "Дикмаn's deli",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-23:00; Fr-Sa 16:00-03:00",
                "contact_website": "http:\/\/dikmansdeli.ru\/restaurants\/golitsin",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 9434227",
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "name": "Треска",
                "type": "Бар",
                "opening_hours": "Mo-Fr 13:00-01:00, Sa-Su 13:00+",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/treskabar",
                "contact_instagram": null,
                "contact_phone": "+7 929 1066835",
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "name": "Tawny Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 38",
                "name": "Maestro",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/maestromusicbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344722, 59.934096]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 40\/68",
                "name": "Mishka",
                "type": "Бар",
                "opening_hours": "Mo-Su 00:00-06:00,18:00-24:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/mishkabar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344623, 59.933708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 45",
                "name": "Люблю",
                "type": "Бар",
                "opening_hours": "Mo-Fr 11:00-23:30; Sa-Su 12:00-00:00",
                "contact_website": "http:\/\/yourstream.ru\/fontanka45\/",
                "contact_vk": "https:\/\/vk.com\/fontanka45",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.338874, 59.930372]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 55",
                "name": "Top Hops",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-01:00",
                "contact_website": "http:\/\/www.tophops.ru",
                "contact_vk": "https:\/\/vk.com\/tophopsspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.336305, 59.929267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 59",
                "name": "Barking",
                "type": "Бар",
                "opening_hours": "16:00+",
                "contact_website": "http:\/\/www.barking.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barking_bar",
                "contact_instagram": null,
                "contact_phone": "+7 905 2358290",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.332271, 59.927757]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 66",
                "name": "Сезоны",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/барсезоны.рф\/",
                "contact_vk": "https:\/\/vk.com\/barsezony",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.337095, 59.928374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 124",
                "name": "Загадка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/zagadkabar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.364916, 59.930742]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 130",
                "name": "Пивная диета",
                "type": "Бар",
                "opening_hours": "Mo-Su 14:00-22:00",
                "contact_website": null,
                "contact_vk": "http:\/\/vk.com\/beerdietspb",
                "contact_instagram": null,
                "contact_phone": "+7 921 3788715",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.366856, 59.930516]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 131Б",
                "name": "The Game of Swords",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/public160353908",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.375399, 59.926923]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 20",
                "name": "Аптека",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321222, 59.936309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 35В",
                "name": "100 ярдов",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": "http:\/\/vk.com\/hundredyards",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 931 9697519",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.331526, 59.933974]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 43",
                "name": "Сайгон",
                "type": "Бар",
                "opening_hours": "Mo-Su 00:00-05:00,15:00-24:00",
                "contact_website": "https:\/\/barsaigon.ru\/",
                "contact_vk": "https:\/\/vk.com\/barsaigon",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345674, 59.932662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 43",
                "name": "Гедонист",
                "type": "Бар",
                "opening_hours": "We-Su 20:00-05:00",
                "contact_website": "https:\/\/gedobar.ru\/",
                "contact_vk": "https:\/\/vk.com\/bargedonist",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345674, 59.932662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 43",
                "name": "Commode",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-05:00, Fr-Sa 18:00-07:00",
                "contact_website": "https:\/\/commode.club\/",
                "contact_vk": "https:\/\/vk.com\/commode.club",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345674, 59.932662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 45\/2",
                "name": "Beergeek",
                "type": "Бар",
                "opening_hours": "12:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6432484",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346725, 59.932545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 46",
                "name": "FS",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.333565, 59.934777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 49\/2",
                "name": "Cannelle",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.radissonblu.com\/hotel-stpetersburg\/bars",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.348558, 59.932324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 53",
                "name": "Las Torres",
                "type": "Бар",
                "opening_hours": "12:00-05:00",
                "contact_website": "http:\/\/www.las-torres.ru\/",
                "contact_vk": "https:\/\/vk.com\/lastorres",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.349708, 59.932153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 61",
                "name": "Густограм",
                "type": "Бар",
                "opening_hours": "Mo,Tu,We,Th,Su 12:00-23:00; Fr-Sa 12:00-02:00",
                "contact_website": "https:\/\/gustogram.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9027778",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352079, 59.931896]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 8",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barspb_official",
                "contact_instagram": null,
                "contact_phone": "+7 812 2445611",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31488, 59.936985]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 81",
                "name": "Deep eMotion",
                "type": "Бар",
                "opening_hours": "Mo-Th 13:00-01:00; Fr-Sa 13:00-03:00; Su 13:00-01:00",
                "contact_website": "https:\/\/m.vk.com\/deep.emotion",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9224253",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359508, 59.930944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 81",
                "name": "1\/2 Of You",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359508, 59.930944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 90-92Б",
                "name": "Police Station",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.police-station.ru\/",
                "contact_vk": "https:\/\/vk.com\/policestationbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353067, 59.93236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Общественный переулок, 5",
                "name": "Бар Yo-Питер",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-23:00; Fr-Sa 16:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+79219953126",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.42672, 59.902799]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Парашютная улица, 23к2",
                "name": "Flat 23",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.270934, 60.020125]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Парашютная улица, 54 ",
                "name": "Адмирал Бенбоу BAR&GRILL",
                "type": "Бар",
                "opening_hours": "Tu-Su 19:00-02:00",
                "contact_website": "https:\/\/vk.com\/barbenbow",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.243922, 60.034874]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Антоненко, 2",
                "name": "L Terrasa",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-02:00; Oct-Apr off",
                "contact_website": "https:\/\/www.lottehotel.com\/stpetersburg-hotel\/ru\/dining\/bar-l-terrasa.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3361000",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.310631, 59.931409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Бринько, 1-3-5",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barspb_official",
                "contact_instagram": null,
                "contact_phone": "+7 812 3353316",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316344, 59.925637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Бринько, 2",
                "name": "Craft Beer Shop",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316533, 59.925466]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Бринько, 6",
                "name": "Luxor",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/luxorbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315482, 59.925331]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 18",
                "name": "KoraDuba",
                "type": "Бар",
                "opening_hours": "Mo-Fr 11:00-23:00, Sa-Su,PH 12:00-01:00",
                "contact_website": "http:\/\/koraduba.com\/",
                "contact_vk": "https:\/\/vk.com\/koraduba_bar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316057, 59.928483]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 2",
                "name": "62 Bar",
                "type": "Бар",
                "opening_hours": "Su-Th 13:00-01:00, Fr-Sa 14:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/sixtytwobar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312652, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 2",
                "name": "Depeche Mode",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/dmbar.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/dm_bar_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312652, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Матюшенко, 12",
                "name": "Живое пиво",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.44059, 59.875732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Ботаническая улица, 18к3",
                "name": "XL",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/xlpeterhof",
                "contact_instagram": null,
                "contact_phone": "7 (812) 428?73?12",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.853643, 59.87029]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 45",
                "name": "Пивляндия",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.152851, 59.848303]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петропавловская улица, 8",
                "name": "The Office",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314772, 59.967209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 1",
                "name": "stereo",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.405511, 59.957584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Стачек, 7",
                "name": "Контакт Бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9508290",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.27221, 59.900719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Труда, 4Д",
                "name": "Скотный двор",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Sa 12:00-06:00; Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9264421",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.295387, 59.932788]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 2",
                "name": "Моя история",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/my-story.bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3656538",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319156, 59.868234]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Поварской переулок",
                "name": "Karabas Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/karabas.bar\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352124, 59.92965]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Поварской переулок, 11",
                "name": "FSB",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351756, 59.929493]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Подрезова улица, 17",
                "name": "Bar76",
                "type": "Бар",
                "opening_hours": "Su-Th 13:00-02:00; Fr-Sa 13:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.303642, 59.964651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Политехническая улица, 17к2",
                "name": "Хороший повод",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_website": "https:\/\/horoshiy-povod.business.site",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (995) 997-17-02",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.367153, 59.999413]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полтавская улица или Конная улица, 2",
                "name": "Кальянная NA-KARTE.NET",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-02:00",
                "contact_website": "https:\/\/www.instagram.com\/nakartenet",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+79811489389",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.370863, 59.928172]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Металлострой, ?Полевая, 27 к3",
                "name": "Охота",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "other",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.57107, 59.816577]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Заречная улица, 19к1",
                "name": "House Smoke Lounge",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "7?911?237?14?53",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32123, 60.071712]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Потёмкинская улица, 4",
                "name": "Белый бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368788, 59.945815]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Потёмкинская улица, 4",
                "name": "Чёрный бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368788, 59.945815]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Провиантская улица, 10",
                "name": "Breaking Bad",
                "type": "Бар",
                "opening_hours": "13:00-01:00",
                "contact_website": "https:\/\/vk.com\/breakingbadbeercafe",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 821 9252235",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302744, 59.950646]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 49",
                "name": "Пивариус",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/pivariusbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.223611, 60.027898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 151к1",
                "name": "Степка",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.132468, 59.83584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Героев, 23",
                "name": "Loft beer cafe",
                "type": "Бар",
                "opening_hours": "15:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.162068, 59.863012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 14",
                "name": "Рюмочная",
                "type": "Бар",
                "opening_hours": "Mo-Su 06:30-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.422642, 59.895777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 33",
                "name": "Брюкке",
                "type": "Бар",
                "opening_hours": "Mo-Su 16:00-00:00; Fr-Sa 16:00-03:00",
                "contact_website": "http:\/\/www.brucke.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6566000",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.264431, 60.008266]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "name": "Кальянная Feromon Komenda",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "https:\/\/feromon.group\/feromon-cafe-komenda.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 944?00?98",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.264269, 60.00792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7",
                "name": "Ямамото",
                "type": "Бар",
                "opening_hours": "Mo-Fr 12:00-02:00; Tu-Su 12:00-06:00",
                "contact_website": "http:\/\/ymamoto.spb.ru\/restorans.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9522207",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.296635, 60.003984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7",
                "name": "SNOB",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/snobbar.ru\/NODE",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9548918",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.296635, 60.003984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 8к1",
                "name": "Золотое руно",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.288802, 60.003669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Королёва, 39к1",
                "name": "Кстати! Бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.256319, 60.020067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "name": "The Western bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 65к2",
                "name": "Munchell",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358017, 59.836685]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 5к3",
                "name": "Hookah Village",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/hookahvillage",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9207270",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322704, 60.044284]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Наставников, 45к1",
                "name": "Глав Пиво",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.482065, 59.955741]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Науки, 17к2",
                "name": "Craft Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.385595, 60.014895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 138к2",
                "name": "Сыто-Пьяно",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.484715, 59.845785]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 199",
                "name": "Раскачай",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.461278, 59.86799]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 21\/139",
                "name": "Sultan",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335559, 60.051917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 25",
                "name": "Контакт Бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9987497",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.339036, 60.051329]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 37к1",
                "name": "Фортуна",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354774, 60.048296]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 41",
                "name": "Dream zone",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/dreamzone41",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5995457;+7 963 3006365",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359553, 60.047245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 85",
                "name": "Блюз",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.408772, 60.03709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Пятилеток, 8к1",
                "name": "Rock'n'roll bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.459392, 59.921809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 13",
                "name": "РосАл",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "http:\/\/www.rosal24.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.307604, 59.924546]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Славы, 26к2",
                "name": "Пять кротов",
                "type": "Бар",
                "opening_hours": "Su-Th 17:00-00:00; Fr-Sa 17:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 906 2417016",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.383116, 59.858937]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 27",
                "name": "РосАл",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.270449, 59.889796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 96",
                "name": "Рюмочная",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.261709, 59.866039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Тореза, 9",
                "name": "Аврора",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.364081, 60.000263]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Художников, 10к1",
                "name": "Громов",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/gromovbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6458889",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343123, 60.036407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Художников, 10к1",
                "name": "The Murphy`s Pub",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343123, 60.036407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 11\/57",
                "name": "Контакт бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/kontaktbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9453213",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.946058]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 9",
                "name": "MF Bagel",
                "type": "Бар",
                "opening_hours": "11:00+",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/mfbagel",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358951, 59.947009]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "name": "O'Briens",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 97",
                "name": "Beering room",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320324, 60.025992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энтузиастов, 26",
                "name": "Барсук",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.476496, 59.948226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 14Д",
                "name": "Pulkovo-park",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327331, 59.832127]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 20",
                "name": "Рюмочная",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326001, 59.828021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 24к2",
                "name": "Тёплый угол",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-23:00; Fr-Su 12:00-24:00",
                "contact_website": "http:\/\/vk.com\/teplyi_ygol",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3867518",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327178, 59.8267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 3к1",
                "name": "Ред Фокс",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31753, 59.840655]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "name": "Камчатка",
                "type": "Бар",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 7",
                "name": "Vodka",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": "http:\/\/bashnya-pushkin.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9010808",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.399317, 59.717844]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 7",
                "name": "Караоке",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": "http:\/\/bashnya-pushkin.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9010808",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.399276, 59.717806]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 5",
                "name": "Bar Duck",
                "type": "Бар",
                "opening_hours": "12:00-03:00",
                "contact_website": null,
                "contact_vk": "http:\/\/www.vk.com\/bar_duckbar",
                "contact_instagram": null,
                "contact_phone": "+7 952 2890868",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.399283, 59.721809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 15А",
                "name": "Были Тут",
                "type": "Бар",
                "opening_hours": "Mo-Fr 16:00-02:00; Sa-Su 16:00-05:00",
                "contact_website": "http:\/\/vk.com\/bilitut",
                "contact_vk": "https:\/\/vk.com\/bilitut",
                "contact_instagram": "https:\/\/www.instagram.com\/bili_tut\/",
                "contact_phone": "+7 812 9838810",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.407701, 59.717997]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, улица Генерала Хазова, 8",
                "name": "Пивная кружка",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": "gutsait.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "7 (812) 476?66?22",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.403671, 59.738231]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 1\/77",
                "name": "янтарь",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.930962]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 5",
                "name": "Lapa Smoke Lounge",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/lapasmoke",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357209, 59.93034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 8",
                "name": "Хромой Синий Кот",
                "type": "Бар",
                "opening_hours": "Mo-Su 14:00-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club30069175",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357792, 59.929912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 9",
                "name": "Пушкин Kurit",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/puskinkurit",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.929637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 2",
                "name": "За углом",
                "type": "Бар",
                "opening_hours": "18:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343195, 59.925989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 9",
                "name": "Рюмочная",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344246, 59.925196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Расстанная улица, 2к1",
                "name": "Сумасшедший охотник",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.339763, 59.909183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рижский проспект, 12",
                "name": "диего жжёт",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.294776, 59.913699]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рижский проспект, 52",
                "name": "Ялта Group",
                "type": "Бар",
                "opening_hours": "11:00-05:00",
                "contact_website": "https:\/\/yalta-bar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.27848, 59.913171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ропшинская улица, 32",
                "name": "ГлавПиво",
                "type": "Бар",
                "opening_hours": "10:00-23:00",
                "contact_website": "https:\/\/glav-beer.ru\/pub\/magazin-na-chkalovskom\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.291066, 59.960206]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рыбацкая улица, 4",
                "name": "Бюро",
                "type": "Бар",
                "opening_hours": "Mo-Th 12:00-01:00; Fr 12:00-03:00; Sa 13:00-03:00; Su 13:00-01:00",
                "contact_website": "http:\/\/barbureau.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9391298",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.29756, 59.958169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рыбацкая улица, 4",
                "name": "Lure",
                "type": "Бар",
                "opening_hours": "We-Th 18:00-04:00, Fr-Sa 18:00-06:00, Su 18:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.29756, 59.958169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 12",
                "name": "Бегемот",
                "type": "Бар",
                "opening_hours": "Th 21:00-05:00; Fr-Sa 21:00-06:00",
                "contact_website": "https:\/\/ginza.ru\/spb\/restaurant\/begemoth_is_back",
                "contact_vk": "https:\/\/vk.com\/begemotisback",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.336116, 59.935534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 13-15\/50Б",
                "name": "Бюро",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.barbureau.ru",
                "contact_vk": "https:\/\/vk.com\/bureaubar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.3352, 59.935209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 14\/52",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/barspb_official",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335703, 59.93479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 22\/2",
                "name": "Majestic Bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/hotelmajestic.ru\/restaurants\/lobbi-bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.333538, 59.933122]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 35",
                "name": "Контакт бар",
                "type": "Бар",
                "opening_hours": "12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/kontaktbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9985573",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320225, 59.927991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 45",
                "name": "KillFish",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-03:00",
                "contact_website": "https:\/\/killfish.ru\/",
                "contact_vk": "https:\/\/vk.com\/killfishbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 4480800",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314718, 59.925696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 62",
                "name": "Meininger Bar",
                "type": "Бар",
                "opening_hours": "11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302384, 59.921155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 7-9-11",
                "name": "Куба Либре",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "http:\/\/spb.barcubalibre.ru\/",
                "contact_vk": "https:\/\/vk.com\/cubalibrespb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335451, 59.935651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 8\/7",
                "name": "Jack and Chan",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/jack-and-chan.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9820535",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.336592, 59.937008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 8\/7",
                "name": "Salute!",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.336592, 59.937008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 109к1",
                "name": "У Попая",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.400444, 60.040483]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 2В",
                "name": "За Стеной",
                "type": "Бар",
                "opening_hours": "Mo-Th, Su 17:00-02:00; Fr, Sa 17:00-06:00",
                "contact_website": "https:\/\/vk.com\/za_stenoy_spb",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 1965139",
                "wheelchair": "no",
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.329666, 59.994333]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, 41-й километр Приморского шоссе, 1С",
                "name": "Easy-bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.easy-bar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.939366, 60.128648]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Скачков переулок, 5",
                "name": "Бродвей",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.506176, 59.831245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Соляной переулок, 14",
                "name": "Бар Роз",
                "type": "Бар",
                "opening_hours": "Mo-Th 11:00-01:00, Fr 11:00-04:00, Sa 12:00-04:00, Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/rosesbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.341874, 59.943264]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 29",
                "name": "Дерби",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.415518, 59.954205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 52",
                "name": "красин",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.270108, 59.940411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 53",
                "name": "Бюро",
                "type": "Бар",
                "opening_hours": "Mo-Th 08:00-01:00; Fr 08:00-03:00; Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/barbureau.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9038473",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.271267, 59.941209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 21к1",
                "name": "Zippy Moose",
                "type": "Бар",
                "opening_hours": "09:00-22:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/zippymoose",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.275821, 59.911439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 54",
                "name": "Чердак",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.275327, 59.901409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Столярный переулок, 13",
                "name": "Ukusno",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-01:00",
                "contact_website": "https:\/\/vk.com\/ukusno",
                "contact_vk": "https:\/\/vk.com\/ukusno",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312652, 59.926381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Столярный переулок, 16",
                "name": "Петербургский бар «Печоринъ»",
                "type": "Бар",
                "opening_hours": "We-Su 18:00-01:00",
                "contact_website": "http:\/\/pechorinbar.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+78129643040",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312931, 59.926657]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Стремянная улица, 16",
                "name": "Кальянная Feromon Stremyannaya",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "https:\/\/feromon.group\/feromon_stremyannaya.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 952?99?99",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35269, 59.930967]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Стремянная улица, 21\/5",
                "name": "Контакт Бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/kontaktbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9518394",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35402, 59.93034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Стремянная улица, 3",
                "name": "Бонвиван",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-02:00, Fr-Sa 16:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bonvivanspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.349214, 59.931075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Стремянная улица, 3Б",
                "name": "Granada",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club140027224",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.34969, 59.930944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Суздальское шоссе, 22к4",
                "name": "Дорогая, здесь не ловит",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.275965, 60.058475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Таврическая улица, 5",
                "name": "Кальянная Огонёк",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.378768, 59.941475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Театральная площадь, 4",
                "name": "Terki",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": "terkibar",
                "contact_instagram": "terkibar",
                "contact_phone": "+7 812 9339527",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.299132, 59.926368]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 26к1",
                "name": "Спарта",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.409616, 60.043179]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Товарищеский проспект, 8к2",
                "name": "Кафе-Бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.482622, 59.91876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к2",
                "name": "ГлавПиво",
                "type": "Бар",
                "opening_hours": "Mo-Su,PH 22:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.25233, 59.989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица, 20к2",
                "name": "Пивотека",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.205384, 59.998297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица, 20к2",
                "name": "Пинта",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.205384, 59.998297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица, 28к3",
                "name": "Коптильня",
                "type": "Бар",
                "opening_hours": "Mo-Su 10:00-02:00",
                "contact_website": "https:\/\/untappd.com\/v\/koptilnya\/7298221",
                "contact_vk": "https:\/\/vk.com\/kopteampub",
                "contact_instagram": null,
                "contact_phone": "+7 812 3177134",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.205815, 60.004271]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Восстания, 24\/27Г",
                "name": "Do Immigration",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-01:00, Fr-Sa 18:00-03:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/do.immigration",
                "contact_instagram": null,
                "contact_phone": "+7 (981) 986-98-26;+7 (962) 684-86-62",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359886, 59.938604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "name": "Cernovar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "name": "Bankir coctail bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Черокова, 18к2",
                "name": "Loft bar",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+79818280181",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.142089, 59.853482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Аккуратова, 13",
                "name": "Караоке бар Мелодия",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "http:\/\/melodybar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9850588",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.310793, 60.016186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 38к2",
                "name": "SПБ",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.442414, 59.878093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бадаева, 6к1",
                "name": "Beerland",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.45004, 59.917696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 1",
                "name": "St. Martin",
                "type": "Бар",
                "opening_hours": "Mo-Th 17:00-02:00, Fr 17:00-06:00, Sa 13:00-06:00, Su 13:00-02:00",
                "contact_website": "https:\/\/stmbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/stmbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344345, 59.937941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 11",
                "name": "The Hat",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/hatgroup.ru",
                "contact_vk": "https:\/\/vk.com\/thehatbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347022, 59.938076]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 11",
                "name": "Terminal",
                "type": "Бар",
                "opening_hours": "14:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/terminalbarspb",
                "contact_instagram": null,
                "contact_phone": "+7 812 9396123",
                "wheelchair": "no",
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347022, 59.938076]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 7",
                "name": "Б7",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/kalyanbarb7",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.34633, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 7",
                "name": "скоро весна",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.34633, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 11к3",
                "name": "Unity",
                "type": "Бар",
                "opening_hours": "Mo-Su 13:00-3:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9266671",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.401675, 60.005077]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 11к4",
                "name": "Fleet-street",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": "http:\/\/fleet-street.pub",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4017140",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.40287, 60.006246]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Вавиловых, 19",
                "name": "Голодный волк",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.395171, 60.024293]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 10\/51",
                "name": "Каникулы",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.instagram.com\/kanikuly_bar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359912, 59.93548]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 22",
                "name": "Downtown",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360065, 59.938076]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 24\/27Б",
                "name": "HopHead Tap Room",
                "type": "Бар",
                "opening_hours": "Mo-Th 15:00-00:00; Fr-Sa 15:00-03:00; Su 15:00-00:00",
                "contact_website": "http:\/\/hopheadbar.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (911) 123-62-88",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358799, 59.938094]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 24\/27Г",
                "name": "Royal Smoke",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35905, 59.938229]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 30\/7",
                "name": "Килька Гастро-Бар",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-02:00",
                "contact_website": "https:\/\/kilka.bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360236, 59.940078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 30\/7",
                "name": "KillFish",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-03:00",
                "contact_website": "https:\/\/killfish.ru\/",
                "contact_vk": "https:\/\/vk.com\/killfishbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 4480800",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360236, 59.940078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 35",
                "name": "РосАл",
                "type": "Бар",
                "opening_hours": "11:00-22:00 open \"магазин и бар\", 22:00-11:00 open \"бар\"",
                "contact_website": "http:\/\/rosal24.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360883, 59.939077]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 37-39",
                "name": "Пивная диета",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/beerdietspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360919, 59.939717]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 37-39",
                "name": "Хочу в Сибирь",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/hochuvsibirbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360919, 59.939717]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Всеволода Вишневского, 3",
                "name": "Блуждающий форвард",
                "type": "Бар",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_website": "https:\/\/forwardbar.ru\/",
                "contact_vk": "vk.com\/lonestrikerbar",
                "contact_instagram": "instagram.com\/lonestrikerbar",
                "contact_phone": "+7 812 9878727",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.303741, 59.966656]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 10",
                "name": "ящик пива",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.305789, 59.928622]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 5",
                "name": "Russofeel",
                "type": "Бар",
                "opening_hours": "Mo-Su 17:00-01:00; Fr-Sa 17:00-02:00",
                "contact_website": "http:\/\/russofeel.ru",
                "contact_vk": "yes",
                "contact_instagram": null,
                "contact_phone": "+7 812 3375606",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.306238, 59.929033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 53",
                "name": "Закусочная",
                "type": "Бар",
                "opening_hours": "10:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284589, 59.924181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Доблести, 26к1",
                "name": "Marco Polo",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.176944, 59.852669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Достоевского, 19\/21М",
                "name": "MadLabBar",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/www.madlab.bar\/",
                "contact_vk": "https:\/\/vk.com\/madlabbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346555, 59.923311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 13к1",
                "name": "Rock'n Roll",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.46418, 59.901008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 13к5",
                "name": "РосАл",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.465195, 59.901915]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Егорова, 18",
                "name": "На волне",
                "type": "Бар",
                "opening_hours": "11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314296, 59.91277]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "name": "Спорт-Бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 11",
                "name": "812",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.bar812.ru\/",
                "contact_vk": "https:\/\/vk.com\/bar812",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352115, 59.93575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 12",
                "name": "Dead Poets",
                "type": "Бар",
                "opening_hours": "Fr-Sa 14:00-08:00, Su-Th 14:00-02:00",
                "contact_website": "http:\/\/deadpoetsbar.com\/",
                "contact_vk": "https:\/\/vk.com\/deadpoetsbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 4494656",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353364, 59.936124]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 12",
                "name": "Holy Ribs",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/holyribsbar\/",
                "contact_phone": null,
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353364, 59.936124]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 15",
                "name": "Honey Badger",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/honeybadgerbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352762, 59.935759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 15",
                "name": "Dr. Moro",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/dr.morobar1",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352762, 59.935759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 17",
                "name": "Шотшоп",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fastdrink",
                "contact_instagram": null,
                "contact_phone": "+7 964 3334285",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35313, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 17",
                "name": "7Fridays",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35313, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 18",
                "name": "Black Beans",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/blackbeans",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356517, 59.936102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 22",
                "name": "Кальянная Feromon Zhukovskogo",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/feromon.group\/zhukovskogo.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7?921?966?22?22",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357828, 59.936079]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 28",
                "name": "Филин Гуд",
                "type": "Бар",
                "opening_hours": "Tu-Th 15:00-01:00; Fr-Sa 15:00-02:00; Su 15:00-01:00",
                "contact_website": "https:\/\/www.restoclub.ru\/spb\/place\/fillin-good",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359697, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 28",
                "name": "Friends Time karaoke-bar",
                "type": "Бар",
                "opening_hours": "Fr-Su 00:00-24:00",
                "contact_website": "http:\/\/karaokefriends.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+78129850011",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359697, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 29",
                "name": "Бюро",
                "type": "Бар",
                "opening_hours": "12:00-01:00",
                "contact_website": "http:\/\/barbureau.ru",
                "contact_vk": "https:\/\/vk.com\/bureaubar",
                "contact_instagram": null,
                "contact_phone": "+7 812 9403008",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355735, 59.935732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 3",
                "name": "Синий Пушкин",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-02:00, Fr-Sa 16:00-06:00",
                "contact_website": "https:\/\/spushkin.com\/",
                "contact_vk": "https:\/\/vk.com\/bar_pushkin",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350238, 59.935791]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 6",
                "name": "Imbibe",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-03:00, Fr-Sa 18:00-6:00",
                "contact_website": "http:\/\/imbibebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/imbibecrew",
                "contact_instagram": null,
                "contact_phone": "+7 812 9886748",
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351352, 59.936165]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 7-9",
                "name": "Al Capone",
                "type": "Бар",
                "opening_hours": "Mo-Su 17:00-06:00",
                "contact_website": "https:\/\/alcaponebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/alcaponebarspb",
                "contact_instagram": null,
                "contact_phone": "+7 911 7339948",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351531, 59.935773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 6к1",
                "name": "Beer Fabrique",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/beerfabrique",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.45057, 59.915535]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Композиторов, 12",
                "name": "TAPROOM beer shop & bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/taproom",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314871, 60.052245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Композиторов, 22к4",
                "name": "Пивная Старый Скряга",
                "type": "Бар",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 900 6253355",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316623, 60.060815]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Комсомола, 16",
                "name": "1-я рюмочная",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357289, 59.954462]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Комсомола, 35",
                "name": "рюмочная \"По чуть-чуть\"",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358233, 59.955002]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Комсомола, 35Ж",
                "name": "Ленинградский буфет",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358709, 59.956349]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Константина Заслонова, 25",
                "name": "Четверть пинты",
                "type": "Бар",
                "opening_hours": "16:00-02:00",
                "contact_website": "https:\/\/pintquarter.ru\/",
                "contact_vk": "https:\/\/vk.com\/pintacraftbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344614, 59.917966]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 32к3",
                "name": "Jenga",
                "type": "Бар",
                "opening_hours": "12:00-24:00",
                "contact_website": "http:\/\/jengabar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 953 3777739",
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.214709, 59.953358]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Крыленко, 21к1С1",
                "name": "Разливное пиво",
                "type": "Бар",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.476154, 59.896815]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 33\/8Б",
                "name": "Локалита",
                "type": "Бар",
                "opening_hours": "Mo-Sa 08:00-01:00; Su 10:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 918-81-45",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 59.957534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленина, 8",
                "name": "Бар 8",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308367, 59.959701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 34",
                "name": "PulkoffClub",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7269943",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330699, 59.847322]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 36",
                "name": "ZIS",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330978, 59.846924]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 95",
                "name": "Рюмочная \"У Иванова\"",
                "type": "Бар",
                "opening_hours": "09:00-21:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347147, 59.833339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 95",
                "name": "НормаБар",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347147, 59.833339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 14",
                "name": "Пивной Этикет",
                "type": "Бар",
                "opening_hours": "Fr-Sa 15:00-04:00; Su-Th 15:00-02:00",
                "contact_website": "http:\/\/www.vk.com\/pivnoyetiket",
                "contact_vk": "https:\/\/vk.com\/pivnoyetiket",
                "contact_instagram": null,
                "contact_phone": "+7 812 7641068",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354559, 59.929285]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 19",
                "name": "KwakInn",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/kwakinn.ru\/reservations\/butylochnaya-kwakinn-draught-bottle",
                "contact_vk": "https:\/\/vk.com\/kwakinnpub",
                "contact_instagram": null,
                "contact_phone": "+7 812 9052236",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353355, 59.92855]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 35",
                "name": "Лаборатория 31",
                "type": "Бар",
                "opening_hours": "15:00-06:00",
                "contact_website": "http:\/\/laborbar31.ru\/",
                "contact_vk": "https:\/\/vk.com\/laborbar31",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351558, 59.925768]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маркина, 1",
                "name": "Ясли",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/yaslibar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4387665",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.30781, 59.955845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 47",
                "name": "Place",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/placeclub.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.289826, 59.899496]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Тухачевского, 1",
                "name": "Крафтовое пивo",
                "type": "Бар",
                "opening_hours": "Mo-Fr 15:00-01:00; Sa-Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9275898",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.431832, 59.960899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 10",
                "name": "Бакалавриат",
                "type": "Бар",
                "opening_hours": "Su-Th 08:00-02:00, Fr-Sa 08:00-06:00",
                "contact_website": "https:\/\/www.bakalavriat.com\/",
                "contact_vk": "https:\/\/vk.com\/bakalavriatbar",
                "contact_instagram": null,
                "contact_phone": "+7 911 1642222",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354235, 59.9336]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 22-24",
                "name": "Brimborium",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-01:00, Fr-Sa 16:00-04:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/brimboriumbar",
                "contact_instagram": null,
                "contact_phone": "+7 921 5770907",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35481, 59.937337]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 23\/6",
                "name": "ToBe",
                "type": "Бар",
                "opening_hours": "Mo-Fr 12:00-01:00; Sa-Su 14:00-01:00",
                "contact_website": "http:\/\/tobe.bar",
                "contact_vk": "https:\/\/vk.com\/tobeguest",
                "contact_instagram": null,
                "contact_phone": "+7 812 9105656",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355708, 59.939825]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 23\/6",
                "name": "Kiosk",
                "type": "Бар",
                "opening_hours": "Mo-Th 15:00-01:00, Fr 15:00-03:00, Sa 13:00-03:00, Su 13:00-01:00",
                "contact_website": "http:\/\/bakuningroup.com\/kioskspb.html",
                "contact_vk": "https:\/\/vk.com\/kioskspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355708, 59.939825]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 34",
                "name": "Полторы комнаты",
                "type": "Бар",
                "opening_hours": "Mo-Su 00:00-02:00,18:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 9418207",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354972, 59.939947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 34к1",
                "name": "Бирдекель",
                "type": "Бар",
                "opening_hours": "Mo-Fr 15:00-23:00; Sa-Su 13:00-23:00",
                "contact_website": "http:\/\/vk.com\/bierdeckelmarket",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.215841, 59.999813]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 4",
                "name": "Leon",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.251567, 59.992213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 4",
                "name": "Леон",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.251567, 59.992213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 42\/24",
                "name": "Бюро",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-03:00",
                "contact_website": "http:\/\/barbureau.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.20807, 60.000438]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 45к1",
                "name": "Пивница",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.205681, 60.002324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Передовиков, 13к2",
                "name": "Крик Души",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.466938, 59.941407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пестеля, 19",
                "name": "Omnes",
                "type": "Бар",
                "opening_hours": "Mo-Th 10:00-00:00, Fr 10:00-01:00, Sa 11:00-01:00, Su 11:00-23:00",
                "contact_website": "https:\/\/www.omnesrestobar.com\/",
                "contact_vk": "https:\/\/vk.com\/dandygastrobar",
                "contact_instagram": "https:\/\/www.instagram.com\/omnes.restobar\/",
                "contact_phone": "+7 812 9222494",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345899, 59.942584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пестеля, 21",
                "name": "Plan B",
                "type": "Бар",
                "opening_hours": "13:00+",
                "contact_website": "https:\/\/vk.com\/id477996179",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346438, 59.942588]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 36к1",
                "name": "Гарькуша",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.147919, 59.832475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Правды, 12\/12",
                "name": "ТБП",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342584, 59.923103]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Правды, 17",
                "name": "Alex P.",
                "type": "Бар",
                "opening_hours": "Mo-Th 09:00-23:00, Fr 09:00-02:00, Sa 12:00-02:00, Su 12:00-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/alexp_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.338829, 59.92148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Правды, 7",
                "name": "SanDiego Lounge",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/sandiego001",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342458, 59.923626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Правды, 9\/10",
                "name": "Electricity",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/electricity_vape",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342171, 59.923459]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Радищева, 25",
                "name": "Ossi bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.363721, 59.938419]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Радищева, 4",
                "name": "Пробирочная",
                "type": "Бар",
                "opening_hours": "17:00-00:00",
                "contact_website": "http:\/\/probirka-bar.ru\/",
                "contact_vk": "https:\/\/vk.com\/probirkabar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361961, 59.936327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Радищева, 4",
                "name": "Chacha",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/chacha_wine_bar\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361961, 59.936327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Радищева, 40",
                "name": "Eclectic bar",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/eclecticbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.363443, 59.942124]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "name": "Tesla",
                "type": "Бар",
                "opening_hours": "15:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/teslabarspb",
                "contact_instagram": null,
                "contact_phone": "+7 812 9427651",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "name": "Бекицер",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/bekitzer.ru",
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/bekitzer_spb\/",
                "contact_phone": "+7 812 9264342",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "name": "Барслона",
                "type": "Бар",
                "opening_hours": "12:00-06:00",
                "contact_website": "http:\/\/barslona.ru",
                "contact_vk": "https:\/\/vk.com\/barslonaforever",
                "contact_instagram": null,
                "contact_phone": "+7 812 9000112",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "name": "Vinostudia",
                "type": "Бар",
                "opening_hours": "Mo-Fr 00:00-02:00,16:00-24:00; Sa,Su 00:00-05:00,16:00-24:00",
                "contact_website": "http:\/\/vinostudia.com",
                "contact_vk": "https:\/\/vk.com\/vinostudia",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 13",
                "name": "Poison",
                "type": "Бар",
                "opening_hours": "Mo-Fr 00:00-05:00,19:00-24:00; Sa,Su 00:00-07:00,19:00-24:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/poisonkaraoke",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343949, 59.929407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 13",
                "name": "Архитектор",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/arhitektor_bar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343949, 59.929407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 14",
                "name": "#трудовыебудни",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345459, 59.930913]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 20",
                "name": "БарМитя",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 960 2598393",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344974, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 23",
                "name": "Встретились | поговорили",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/vstrtls",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343051, 59.927991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 27",
                "name": "Diligent Drinkers",
                "type": "Бар",
                "opening_hours": "18:00+",
                "contact_website": "https:\/\/hatgroup.ru\/aoud\/",
                "contact_vk": "https:\/\/vk.com\/anonymous.drinkers",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342593, 59.927175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 27",
                "name": "Mr. Rowdy & Co",
                "type": "Бар",
                "opening_hours": "Su-Th 15:00-03:00, Fr-Sa 15:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/rowdybar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342593, 59.927175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 27",
                "name": "Анонимное общество усердных дегустаторов",
                "type": "Бар",
                "opening_hours": "10:00-02:00",
                "contact_website": "https:\/\/hatgroup.ru\/aoud\/",
                "contact_vk": "https:\/\/vk.com\/anonymous.drinkers",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342593, 59.927175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 27",
                "name": "DovLaTov",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/www.dovlatov.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/dovlatovbar\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342593, 59.927175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 4",
                "name": "Угрюмочная",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346123, 59.932108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 6",
                "name": "Crazy Wine",
                "type": "Бар",
                "opening_hours": "Su-Th 14:00-01:00, Fr-Sa 14:00-06:00",
                "contact_website": "http:\/\/reca.rest\/restaurants\/crazywine",
                "contact_vk": "https:\/\/vk.com\/crazywinespb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346025, 59.9319]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 6",
                "name": "Угрюмочная",
                "type": "Бар",
                "opening_hours": "Su-Th 16:00-04:00; Fr-Sa 16:00-06:00",
                "contact_website": "http:\/\/www.ugrumo.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6002012",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346025, 59.9319]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 8",
                "name": "Проходимец",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bar_prohodimec",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345926, 59.931648]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 9",
                "name": "Punk Brew",
                "type": "Бар",
                "opening_hours": "Su-Th 12:00-03:00, Fr-Sa 12:00-06:00",
                "contact_website": "http:\/\/reca.rest\/restaurants\/punkbrew",
                "contact_vk": "https:\/\/vk.com\/punkbrew",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.34447, 59.930327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 143к1",
                "name": "Beer House",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-24:00+",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.20233, 59.989481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 20",
                "name": "Главпиво",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.292862, 59.985458]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 28",
                "name": "Мидл Паб",
                "type": "Бар",
                "opening_hours": "Su-Th 14:00-02:00; Fr-Sa 14:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9933599",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.289269, 59.985201]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Сикейроса, 11к1",
                "name": "Окенава",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.329406, 60.034465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Тамбасова, 4к1",
                "name": "BarBaris",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.158762, 59.841672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Тельмана, 48к1",
                "name": "Барс",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9222221",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.483215, 59.893186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "name": "7 Миля",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 29",
                "name": "Ящик пива",
                "type": "Бар",
                "opening_hours": "Mo-Th 16:00-00:00; Fr 16:00-02:00; Sa 14:00-02:00; Su 14:00-00:00",
                "contact_website": "http:\/\/vk.com\/beeclb",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35728, 59.85397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 8",
                "name": "Tortilla",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.331903, 59.851878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чайковского, 34",
                "name": "Arnold & Фёдор",
                "type": "Бар",
                "opening_hours": "12:00-00:00",
                "contact_website": "http:\/\/arnoldifedor.ru\/",
                "contact_vk": "http:\/\/vk.com\/arnoldifedor",
                "contact_instagram": null,
                "contact_phone": "+7 812 9036693",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356822, 59.946721]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чайковского, 36",
                "name": "Bar Do Bar",
                "type": "Бар",
                "opening_hours": "Mo-Fr 11:00+; Sa 14:00+; Su 16:00+",
                "contact_website": "http:\/\/vk.com\/bardobar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5796798",
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Черняховского, 55",
                "name": "Пена Дней",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/momsbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359293, 59.920307]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чехова, 17\/9",
                "name": "Палата №6",
                "type": "Бар",
                "opening_hours": "Su-We 13:30-00:00; Th-Sa 14:00-05:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/craftbarpalata6",
                "contact_instagram": null,
                "contact_phone": "+7 812 9059806",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35331, 59.938689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 11Б",
                "name": "Явмясо",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.390752, 59.832222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 5",
                "name": "All Right",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.830676]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Финляндский проспект, 1",
                "name": "Douglas",
                "type": "Бар",
                "opening_hours": "12:45-02:00",
                "contact_website": "http:\/\/www.douglasbar.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5424300",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342081, 59.958381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 1\/14",
                "name": "Russian Paradise",
                "type": "Бар",
                "opening_hours": "12:00-07:00",
                "contact_website": "http:\/\/rpbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9500072",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.349429, 59.945062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 11-13",
                "name": "luminous",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.340976, 59.926508]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 12\/66",
                "name": "Blackbird",
                "type": "Бар",
                "opening_hours": "Su-Th 14:00-23:00; Fr-Sa 14:00-01:00",
                "contact_website": "https:\/\/vk.com\/blackbirdbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.337446, 59.928068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 12\/66",
                "name": "Gypsies & Jews",
                "type": "Бар",
                "opening_hours": "16:00-04:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.337446, 59.928068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Quarenghi",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/quarenghibar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Poison",
                "type": "Бар",
                "opening_hours": "Mo-Su 18:00-07:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/poisonkaraoke",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Чёрт побери",
                "type": "Бар",
                "opening_hours": "18:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bar_4ertpoberi",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Morrison",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/morrisonkaraokebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Banka",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bankaclub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Bad Habits",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/badhabitsbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "name": "Sсandal",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "https:\/\/scandalbar.ru\/",
                "contact_vk": "https:\/\/vk.com\/scandalbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 22",
                "name": "На бровях",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/www.nabrovyah.ru\/",
                "contact_vk": "https:\/\/vk.com\/nabrovjah",
                "contact_instagram": null,
                "contact_phone": "+7 950 0317977",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.340536, 59.927053]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 17",
                "name": "Цветочки",
                "type": "Бар",
                "opening_hours": "Mo-Th 14:00-02:00, Fr-Su 14:00-03:00",
                "contact_website": "https:\/\/vk.com\/flowersbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357074, 59.938662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 22",
                "name": "Bazin",
                "type": "Бар",
                "opening_hours": "Mo-Fr 00:00-02:00,17:30-24:00; Sa,Su 00:00-05:00,17:30-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356202, 59.938991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 24",
                "name": "Залив",
                "type": "Бар",
                "opening_hours": "Mo-Th, Su 16:00-02:00; Fr, Sa 16:00-05:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 272-35-81",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356643, 59.938991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 26",
                "name": "Хроники",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-02:00, Fr-Sa 18:00-05:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/xronikibar",
                "contact_instagram": null,
                "contact_phone": "+7 911 2753161",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357092, 59.938987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 28",
                "name": "Сидр и Нэнси",
                "type": "Бар",
                "opening_hours": "Su-Th 15:00-01:00, Fr-Sa 15:00-03:00",
                "contact_website": "https:\/\/vk.com\/ciderandnancy",
                "contact_vk": "https:\/\/vk.com\/ciderandnancy",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357712, 59.938964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 3-5",
                "name": "Gagen Bar",
                "type": "Бар",
                "opening_hours": "15:00-02:00",
                "contact_website": "https:\/\/gagen.bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4095090",
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351396, 59.938793]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 36",
                "name": "Holy Water",
                "type": "Бар",
                "opening_hours": "Su-Th 18:00-02:00, Fr-Sa 18:00-04:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/holywaterbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359302, 59.938933]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 52",
                "name": "Бравые Парни",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bravieparni",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.365913, 59.938703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шамшева улица, 15Б",
                "name": "Hookah Craft",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/hookahcraft.club",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9052895",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.300309, 59.960944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шведский переулок, 2",
                "name": "Big Liver Place",
                "type": "Бар",
                "opening_hours": "Mo-Su 18:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bigliverplace",
                "contact_instagram": null,
                "contact_phone": "+7 812 5715265",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.325345, 59.939009]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шведский переулок, 2",
                "name": "Brisket Eat & Fun",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_website": "https:\/\/brisket.spb.ru",
                "contact_vk": "https:\/\/vk.com\/brisketspb",
                "contact_instagram": null,
                "contact_phone": "+7 812 9072770?",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.325345, 59.939009]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шведский переулок, 2",
                "name": "The Right Place",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/theright.ru",
                "contact_vk": "https:\/\/vk.com\/the_rightplace",
                "contact_instagram": null,
                "contact_phone": "+7 812 9298844",
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.325345, 59.939009]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 31",
                "name": "J.F.C. Jazz Club",
                "type": "Бар",
                "opening_hours": "Mo-Su 19:00-23:00",
                "contact_website": "https:\/\/jfc-club.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354936, 59.948848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 41к1",
                "name": "Шуваловъ",
                "type": "Бар",
                "opening_hours": "Mo-Th 15:00-24:00; Fr 15:00-02:00; Sa 12:00-02:00; Su 12:00-24:00",
                "contact_website": "http:\/\/vk.com\/shuvalovbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.241434, 60.030052]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 74к1",
                "name": "Moby Dick",
                "type": "Бар",
                "opening_hours": "Su, Tu-Th 13:00-01:00; Fr-Sa 13:00-02:00; Mo 14:00-01:00",
                "contact_website": "mobydickbar.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9422880",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.197075, 60.004942]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южное шоссе, 55к1",
                "name": "Live Beer",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/livebeerspb",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.405143, 59.857712]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Яхтенная улица, 3к1",
                "name": "борода бразерз",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.216236, 59.985219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Королёва 61",
                "name": "Кальян-бар Mirage Lounge",
                "type": "Бар",
                "opening_hours": "Mo-Su 15:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "other",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.241649, 60.031841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 63к1",
                "name": "Кальянная Feromon Neft",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/feromon.group\/neft.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 995?00?98",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357388, 59.838602]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Короленко, 14",
                "name": "Кальян-бар",
                "type": "Бар",
                "opening_hours": "Mo-Fr 12:00-01:00, Sa-Su 14:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/kaliyan_bar_spb\/",
                "contact_phone": "+7 812 2735906",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350085, 59.942543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 45к1",
                "name": "Кальянная Feromon Optikov",
                "type": "Бар",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/feromon.group\/feromon_optikov.html",
                "contact_vk": "https:\/\/vk.com\/fg_opt",
                "contact_instagram": null,
                "contact_phone": "+7?921?965?00?97",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.205681, 60.002324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 7",
                "name": "Кальянная Feromon Lounge Cafe",
                "type": "Бар",
                "opening_hours": "24\/7",
                "contact_website": "https:\/\/feromon.group\/feromon.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (812) 912?00?98",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.331687, 59.852692]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Промышленная улица, 6",
                "name": "Leet Lounge - Антикафе, Кальян-бар",
                "type": "Бар",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.272929, 59.898774]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 38с2",
                "name": "Craft Bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360083, 59.999791]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 18",
                "name": "Тара Бруч",
                "type": "Паб",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/thepub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.372731, 59.93057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 25А",
                "name": "Бакунин",
                "type": "Паб",
                "opening_hours": "Su-Th 13:00-00:00, Fr-Sa 13:00-02:00",
                "contact_website": "http:\/\/www.bakuningroup.com\/bakunin.html",
                "contact_vk": "https:\/\/vk.com\/cafebakunin",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.371159, 59.930611]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 3-я линия Васильевского острова, 54",
                "name": "L Bar",
                "type": "Паб",
                "opening_hours": "Mo-Th 16:00-23:00; Fr 15:00-24:00; Sa 00:00-01:00,15:00-24:00; Su 00:00-01:00,16:00-23:00",
                "contact_website": "http:\/\/vk.com\/lbarspb",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.279873, 59.947487]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 18\/6",
                "name": "Saint Patrick",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/st.patrick_irishpub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368285, 59.932951]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 5-я линия Васильевского острова, 54",
                "name": "Shannon",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.278372, 59.945797]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 6-7-я линии В.О., 72",
                "name": "Дикая Утка",
                "type": "Паб",
                "opening_hours": "12:00-02:00; Fr,Sa 12:00-04:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/wildduckpub",
                "contact_instagram": null,
                "contact_phone": "+7 812 3238088",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.273387, 59.946919]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 1\/25",
                "name": "Стрит Фуд Бар №1",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": "http:\/\/1streetfood.bar\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3251122",
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.286484, 59.93671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 25",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Mo-Th,Su 11:00-01:00, Fr-Sa 11:00-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-shestoy-linii-vo\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 3233881;+7 911 8246398",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.280268, 59.942043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 11-13",
                "name": "The 8th Line Pub",
                "type": "Паб",
                "opening_hours": "Mo-Su 17:00-02:00",
                "contact_website": "http:\/\/www.sokosrestaurants.ru\/the-8th-line-pub.html",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3352290",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.282406, 59.937301]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 43",
                "name": "Толстый фраер",
                "type": "Паб",
                "opening_hours": "Su-Th 10:00-01:00; Fr-Sa 10:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.277241, 59.941727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский проспект, 8",
                "name": "Radio Ireland",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "https:\/\/radioireland.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309751, 59.935976]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 5",
                "name": "Manneken Pis",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-03:00",
                "contact_website": "http:\/\/reca.rest\/restaurants\/mannekenpiskazanskaya",
                "contact_vk": "https:\/\/vk.com\/mannekenpisspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321869, 59.933429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 5",
                "name": "The Office",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-03:00",
                "contact_website": "http:\/\/molly.su\/office\/",
                "contact_vk": "https:\/\/vk.com\/office_pub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321869, 59.933429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Английский проспект, 26",
                "name": "Red Morrison",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club87801058",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284247, 59.92446]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 14",
                "name": "Krebspub",
                "type": "Паб",
                "opening_hours": "13:00-24:00",
                "contact_website": "http:\/\/krebspub.ru\/",
                "contact_vk": "https:\/\/vk.com\/krebspub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327088, 59.928086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарская набережная, 20",
                "name": "НаBeeRежная",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.nabeer.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 9624746",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315464, 59.978246]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "name": "Билл-Хук",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/balkanskaja.billhook.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6761872",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Благодатная улица, 39",
                "name": "Foggy Dew",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/foggydew.pub\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 (931) 974-93-09",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321312, 59.875555]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 7к8",
                "name": "O'Briens",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.obriens.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3003004",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284292, 59.99998]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 14",
                "name": "O'Hooligans",
                "type": "Паб",
                "opening_hours": "Mo-Th, Su 12:00-01:00; Fr, Sa 12:00-03:00",
                "contact_website": "http:\/\/konn.ohooligans.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9648040",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322866, 59.936341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 14",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Mo-Th,Su 11:00-01:00, Fr-Sa 11:00-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-nevskom-22\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 3143858",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322866, 59.936341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 14 ",
                "name": "Tower",
                "type": "Паб",
                "opening_hours": "24\/7",
                "contact_website": "http:\/\/pub-tower.ru",
                "contact_vk": "https:\/\/vk.com\/pub_tower",
                "contact_instagram": null,
                "contact_phone": "+7 812 3151431",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322866, 59.936341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 29",
                "name": "Palm",
                "type": "Паб",
                "opening_hours": "Su-Th 11:00-03:00, Fr-Sa 11:00-06:00",
                "contact_website": "http:\/\/www.palminspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/palmbrasserie",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322066, 59.936562]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 5",
                "name": "Моллис Мьюз",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-03:00",
                "contact_website": "http:\/\/molly.su\/mews\/",
                "contact_vk": "https:\/\/vk.com\/mollies_mews",
                "contact_instagram": null,
                "contact_phone": "+7 812 5715171",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323863, 59.940294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 9",
                "name": "Var Craft Bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/barobeer",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346357, 59.925931]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 7Б",
                "name": "My friends",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/myfriendspub.ru",
                "contact_vk": "https:\/\/vk.com\/myfriendspub",
                "contact_instagram": null,
                "contact_phone": "+7 921 5752234",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.305313, 59.925732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 50",
                "name": "Репин",
                "type": "Паб",
                "opening_hours": "Mo-Su 17:00-00:00",
                "contact_website": "https:\/\/vk.com\/repinbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308547, 59.962534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 37",
                "name": "KwakInn",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/kwakinn.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.278049, 59.938239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 70",
                "name": "Patrick Pub",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.258565, 59.931756]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 84",
                "name": "Гольф рестопаб",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.309355, 59.964286]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бронницкая ул., 4",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.287877, 59.919441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.267314, 59.850649]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 2к2",
                "name": "Шеннон",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/www.shannon.spb.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.269596, 59.852773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 114к1",
                "name": "Templet bar",
                "type": "Паб",
                "opening_hours": "13:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.405331, 59.849189]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 72к1",
                "name": "У Гоши",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.378283, 59.874024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Варшавская улица, 63к1",
                "name": "Route 66",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.314116, 59.849153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 6\/1",
                "name": "The Бар",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/thebar.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/thebar.spb\/",
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342315, 59.947762]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 21",
                "name": "Bearstein",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/beerstein_project",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6000320",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.256301, 60.00002]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 34",
                "name": "Контакт Бар",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-03:00; Fr-Su12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9508229",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 60.006597]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гжатская улица, 22к2",
                "name": "Woodman Bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "https:\/\/woodman-bar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.386736, 60.01119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гжатская улица, 22к4",
                "name": "Docker",
                "type": "Паб",
                "opening_hours": "Mo-Th, Sa, Su 17:00-00:00; Fr, Sa 17:00-02:00",
                "contact_website": "https:\/\/vk.com\/pubdocker",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.388281, 60.01365]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гончарная улица, 24",
                "name": "Регби Хаус",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/rugbypub.ru\/",
                "contact_vk": "https:\/\/vk.com\/rugbyhouse",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368788, 59.928438]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гончарная улица, 27",
                "name": "СБГ-25",
                "type": "Паб",
                "opening_hours": "Su-Th 14:00-01:00, Fr-Sa 14:00-05:00",
                "contact_website": "https:\/\/vk.com\/sbg25",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368608, 59.928081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 34",
                "name": "J?ger",
                "type": "Паб",
                "opening_hours": "Su-Th 11:30-01:00, Fr-Sa 11:30-02:30",
                "contact_website": "http:\/\/www.jagerhaus.ru\/kontakty\/restoran-na-gorohovoy\/",
                "contact_vk": "https:\/\/vk.com\/jagerhaus",
                "contact_instagram": null,
                "contact_phone": "+7 812 3108270;+7 812 6111110",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32151, 59.929082]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 40",
                "name": "Bier K?nig",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/www.bier-konig.ru\/locations\/bier-k%C3%B6nig-%D0%BD%D0%B0-%D0%B3%D0%BE%D1%80%D0%BE%D1%85%D0%BE%D0%B2%D0%BE%D0%B9",
                "contact_vk": "https:\/\/vk.com\/bierkoniig",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323863, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 47Б",
                "name": "Co-op Garage",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/cooperativegarage.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 965 0122222",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.323091, 59.9275]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 53",
                "name": "Marco Polo",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/marcopolo_bestpub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324357, 59.926693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 36",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.394138, 60.010938]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 43к2",
                "name": "Живое пиво",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.400588, 60.012939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Графский переулок, 7",
                "name": "Подстреленная гусыня",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_website": "https:\/\/www.pivnicespb.ru\/",
                "contact_vk": "https:\/\/vk.com\/potrefena",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.34642, 59.930169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гродненский переулок, 17",
                "name": "Траппист",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_website": "https:\/\/beercard.ru\/trappist\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2759935",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.363398, 59.941484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дачный проспект, 9к1",
                "name": "The Templet Bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.templetbar.ru\/templet-dachnyj\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3246876",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.244362, 59.846549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дибуновская улица, 37",
                "name": "Вацлав Замок",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-00:00; Fr-Sa 12:00-01:00; Su 13:00-00:00",
                "contact_website": "http:\/\/vaclav-spb.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 925-95-63",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.269147, 59.985512]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 12к3",
                "name": "Степан",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.243473, 60.017665]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Думская улица",
                "name": "Толстый фраер -Гастроном",
                "type": "Паб",
                "opening_hours": "Su-Th 10:00-01:00, Fr-Sa 10:00-03:00",
                "contact_website": "http:\/\/tolstiy-fraer.ru\/rubric\/21\/",
                "contact_vk": "https:\/\/vk.com\/tolstiyfraer",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.328552, 59.933519]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 9",
                "name": "Bar Ma? und Spass",
                "type": "Паб",
                "opening_hours": "Mo 00:00-02:00; Tu 15:00-24:00; We-Fr 00:00-02:00,15:00-24:00; Sa,Su 00:00-02:00,12:00-24:00",
                "contact_website": "http:\/\/massundspass.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6869829",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.398756, 59.844985]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 10",
                "name": "Эль и Вино",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Su 12:00-03:00",
                "contact_website": "http:\/\/sohaus.pub",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4440595",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.408853, 59.928695]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "name": "Пивная бочка",
                "type": "Паб",
                "opening_hours": "10:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 8",
                "name": "Морячок",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.407523, 59.928411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 13",
                "name": "Sherlock",
                "type": "Паб",
                "opening_hours": "Mo-Th 09:00-02:00, Fr 09:00-03:00, Sa 12:00-03:00, Su 12:00-02:00",
                "contact_website": "https:\/\/pubsherlock.ru\/",
                "contact_vk": "https:\/\/vk.com\/pubsherlock_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.353301, 59.947563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 2\/44",
                "name": "KwakInn",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/kwakinn.ru\/reservations\/zvenigorodsky",
                "contact_vk": "https:\/\/vk.com\/kwakinnpub",
                "contact_instagram": null,
                "contact_phone": "+7 812 4560515",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.336017, 59.92309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зверинская улица, 2",
                "name": "I Believe bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.292952, 59.95357]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Приморское шоссе, 570М",
                "name": "Пивная пристань",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.665048, 60.190411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ивановская улица, 8\/77",
                "name": "Hanse Bier",
                "type": "Паб",
                "opening_hours": "Mo-Fr 00:00-02:00,12:00-24:00; Sa,Su 00:00-05:00,12:00-24:00",
                "contact_website": "http:\/\/www.hansebier.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6355548",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.444588, 59.875239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ижорская улица, 3",
                "name": "Red Fox",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.299159, 59.958602]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 17",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-italianskoy-17\/",
                "contact_vk": "https:\/\/vk.com\/kriek",
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 5954153;+7 921 8870313",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.334293, 59.936133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 29",
                "name": "Молли Шелтер",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/shelter\/",
                "contact_vk": "https:\/\/vk.com\/moll_shelter",
                "contact_instagram": null,
                "contact_phone": "+7 812 3154754;+7 812 7777966",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.339251, 59.935547]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кавалергардская улица, 20",
                "name": "Кавалерия",
                "type": "Паб",
                "opening_hours": "Mo-Th 11:00-23:00, Fr 11:00-01:00, Sa 12:00-23:00, Su 13:00-22:00",
                "contact_website": "http:\/\/kavaleria.ru\/",
                "contact_vk": "https:\/\/vk.com\/kavaleria_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.38237, 59.945427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Казанская улица, 5",
                "name": "The Office",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-03:00",
                "contact_website": "http:\/\/molly.su\/office\/",
                "contact_vk": "https:\/\/vk.com\/office_pub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321869, 59.933429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 12",
                "name": "Manneken Pis",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-03:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9038454",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31771, 59.958976]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 18\/11",
                "name": "Толстый фраер -Гастроном",
                "type": "Паб",
                "opening_hours": "Su-Th 10:00-01:00; Fr-Sa 10:00-03:00",
                "contact_website": "http:\/\/www.tolstiy-fraer.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2323056",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.316677, 59.959606]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 37",
                "name": "SПБ",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-05:00",
                "contact_website": "http:\/\/barspb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6556712",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.311412, 59.966439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерская улица, 27, подъезд 1",
                "name": "Beer House Staff",
                "type": "Паб",
                "opening_hours": "12:00+",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.288074, 59.919324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Капитанская улица, 4",
                "name": "Пивные погреба",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.214736, 59.954773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 11",
                "name": "My friends",
                "type": "Паб",
                "opening_hours": "Mo-Fr 09:00-00:00; Sa, Su 14:00-00:00",
                "contact_website": "http:\/\/myfriendspub.ru\/",
                "contact_vk": "https:\/\/vk.com\/myfriendspub",
                "contact_instagram": null,
                "contact_phone": "+7 812 9602534",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354559, 59.943787]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 17",
                "name": "BlackWood",
                "type": "Паб",
                "opening_hours": "12:00+",
                "contact_website": "http:\/\/blackwoodpub.ru\/",
                "contact_vk": "https:\/\/vk.com\/blackwoodspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359077, 59.943706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Клинский проспект, 26\/2Б",
                "name": "Old Friends",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/oldfriends.pub\/1\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321456, 59.914254]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ковенский переулок, 14",
                "name": "Camorra Pizza&Birra",
                "type": "Паб",
                "opening_hours": "Mo-Su 13:00-23:00",
                "contact_website": "https:\/\/vk.com\/camorrapizza",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9057527",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359005, 59.937481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Е",
                "name": "Axiom",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-23:00; Fr-Sa 12:00-01:00; Su 12:00-23:00",
                "contact_website": "https:\/\/facebook.com\/axiompubspb\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 2484119",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.239754, 59.924957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колокольная улица, 4",
                "name": "Ring o'Bells",
                "type": "Паб",
                "opening_hours": "Mo-Su 00:00-05:00,12:00-24:00",
                "contact_website": "https:\/\/ringo-bells.ru\/",
                "contact_vk": "https:\/\/vk.com\/ringobells",
                "contact_instagram": null,
                "contact_phone": "+7 812 7642289",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.34933, 59.928947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к2",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.299429, 60.001523]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к2",
                "name": "Контакт Бар",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9508260",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.299429, 60.001523]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "name": "Пиворама",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/pivorama.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, улица Анисимова, 5к5",
                "name": "Пивная Кружка",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.605719, 59.732988]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 21к1",
                "name": "Public Pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.254136, 60.013627]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 30к1",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.249815, 60.014873]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конная улица, 21",
                "name": "Bier Konig",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.bier-konig.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.379379, 59.927067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Краснодонская улица, 4",
                "name": "Beer & Cakes",
                "type": "Паб",
                "opening_hours": "Fr-Sa 18:00-02:00, Su-Th 18:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.412805, 59.955755]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 22",
                "name": "Mc Clane",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/mc_clane",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357236, 59.926841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 34к1",
                "name": "Красный Лев",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.redlionpub.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7778988",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.398576, 59.827021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 3к2",
                "name": "My Little Pub",
                "type": "Паб",
                "opening_hours": "Mo-Su 14:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.369704, 59.987992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 119к1",
                "name": "Король Гамбринус",
                "type": "Паб",
                "opening_hours": "Mo-Th 11:00-06:00; Fr-Sa 18:00-10:00; Su 18:00-06:00",
                "contact_website": "http:\/\/www.king-gambrinus.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3765272",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.259086, 59.851381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 87к1",
                "name": "Molly O'Bryan",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.204208, 59.855267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 33-35",
                "name": "Old City",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/spb_oldcity_pub",
                "contact_instagram": "https:\/\/www.instagram.com\/oldcity_pub_spb\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361476, 59.932748]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 53",
                "name": "Вильям Басс",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/williambass.ru\/",
                "contact_vk": "https:\/\/vk.com\/thewilliambass",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359338, 59.929136]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "name": "GreenBeerGer",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-23:00, Fr-Sa 12:00-01:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/greenbeerger",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Костылева, 18",
                "name": "Smoke2",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.776996, 59.912477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Рубакина, 12",
                "name": "Виктория",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.767815, 59.916397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Десятинная улица, 11",
                "name": "Zerno",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/zernobeer",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 9870308",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.294937, 60.033036]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 7-я линии В.О. 72",
                "name": "Дикая Утка",
                "type": "Паб",
                "opening_hours": "12:00-02:00; Fr,Sa 12:00-04:00",
                "contact_website": "http:\/\/wildduck.su",
                "contact_vk": "https:\/\/vk.com\/wildduckpub",
                "contact_instagram": null,
                "contact_phone": "+7 812 3238088",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.273387, 59.946919]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 40",
                "name": "Jager Haus",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.jagerhaus.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2350795;+7 812 6111110",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.295171, 59.958962]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Морская набережная, 15",
                "name": "Mollie Island",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.212849, 59.940055]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 139к1",
                "name": "Гент",
                "type": "Паб",
                "opening_hours": "Mo-Su,PH 11:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318527, 59.879864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 18",
                "name": "13 Rules",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-24:00, Fr-Su 12:00-02:00",
                "contact_website": "https:\/\/13rules.ru\/",
                "contact_vk": "https:\/\/vk.com\/13rules_piter",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318267, 59.920641]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 190",
                "name": "Finnegan's",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-05:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3375755",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.321411, 59.864652]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 198",
                "name": "Маннекен Пис",
                "type": "Паб",
                "opening_hours": "Mo-Th 17:00-24:00; Fr-Sa 12:00-02:00; Su 12:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3719031",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322812, 59.861476]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 216",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322345, 59.848552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 8",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318033, 59.924366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Моховая улица, 30",
                "name": "Сидрерия",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-03:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/sidreria",
                "contact_instagram": null,
                "contact_phone": "+7 812 9946752",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345521, 59.941263]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 10",
                "name": "Богемиус",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_website": "https:\/\/www.bogemius.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3201506",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.293761, 59.945472]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 18",
                "name": "357",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 9359800",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.287832, 59.946513]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 108",
                "name": "D&D Beer",
                "type": "Паб",
                "opening_hours": "12:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/dd.beer_shop\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31294, 59.908028]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "name": "Gildia Pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/gildiya-club.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 56",
                "name": "Oldham",
                "type": "Паб",
                "opening_hours": "12:00-03:00",
                "contact_website": "http:\/\/oldham-pub.ru\/",
                "contact_vk": "https:\/\/vk.com\/oldhamspb",
                "contact_instagram": null,
                "contact_phone": "+7 812 9301150",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315024, 59.932518]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 108",
                "name": "Диккенс",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": "http:\/\/dickensrest.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318922, 59.921416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 29\/66",
                "name": "Сибирская корона",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/vk.com\/pubsk",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9667910;+7 812 3105522",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342638, 59.933704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 38",
                "name": "Elephant",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_website": "https:\/\/www.elephantcafe.ru\/",
                "contact_vk": "https:\/\/vk.com\/cafe_elephant",
                "contact_instagram": null,
                "contact_phone": "+7 812 9045375",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344722, 59.934096]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 9",
                "name": "Let It Bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/letitbar",
                "contact_instagram": null,
                "contact_phone": "+78125703246",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.342692, 59.93708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 109",
                "name": "Harat's",
                "type": "Паб",
                "opening_hours": "12:00-02:00",
                "contact_website": "https:\/\/spb.harats.com\/74\/",
                "contact_vk": "https:\/\/vk.com\/haratspubspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.369632, 59.929024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 118",
                "name": "Щелкунчик",
                "type": "Паб",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.362329, 59.931594]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 32-34Н",
                "name": "Спарта Гриль-бар",
                "type": "Паб",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_website": "https:\/\/www.instagram.com\/sparta_grillbar\/",
                "contact_vk": "https:\/\/vk.com\/spartagrill",
                "contact_instagram": null,
                "contact_phone": "+79531725388",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.329576, 59.936508]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 38\/4",
                "name": "Rock Pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.rock-pub.spb.ru\/blogN.html",
                "contact_vk": "https:\/\/vk.com\/rock_pub",
                "contact_instagram": null,
                "contact_phone": "+7 812 9480063",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.331274, 59.935232]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 45\/2",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Mo-Th, Su 11:30-01:00; Fr, Sa 11:30-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-nevskom-47\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 4183993",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.346725, 59.932545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 53",
                "name": "Бар 53",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/bar53.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.349708, 59.932153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 55",
                "name": "Manneken Pis",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/reca.rest\/restaurants\/mannekenpisnevskiy",
                "contact_vk": "https:\/\/vk.com\/mannekenpisspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350292, 59.931999]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 60",
                "name": "Карловы пивовары",
                "type": "Паб",
                "opening_hours": "Su-Th 11:30-01:00, Fr-Sa 11:30-02:30",
                "contact_website": "http:\/\/karlovypivovary.ru\/",
                "contact_vk": "https:\/\/vk.com\/karlovypivovary",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.33917, 59.93406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 62",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.339808, 59.933983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 64",
                "name": "Иван да Марья",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-01:00; Fr-Sa 11:00-02:30",
                "contact_website": "http:\/\/ivandamaria.bar\/",
                "contact_vk": "https:\/\/vk.com\/gastropub_ivandamaria",
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.340419, 59.933911]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 64",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Mo-Th,Su 12:00-01:00, Fr-Sa 12:00-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-nevskom-64\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 4182425",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.340419, 59.933911]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 84-86",
                "name": "J?ger",
                "type": "Паб",
                "opening_hours": "Su-Th 11:30-01:00, Fr-Sa 11:30-02:30",
                "contact_website": "http:\/\/www.jagerhaus.ru\/kontakty\/restoran-na-nevskom\/",
                "contact_vk": "https:\/\/vk.com\/jagerhaus",
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 4183949",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350498, 59.932644]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новоколомяжский проспект, 4к1",
                "name": "Sixty six",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.297569, 60.026784]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новосмоленская набережная, 1Д",
                "name": "Fish and chips",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/molly.su\/fishchips\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9847172",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.224554, 59.950078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ораниенбаумская улица, 16 ",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Mo-Th,Su 12:00-01:00, Fr-Sa 12:00-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-malom-48\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 2354621",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.297264, 59.960701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ординарная улица, 19",
                "name": "Компания",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.305385, 59.967579]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Перекопская улица, 7",
                "name": "Мясная лавка",
                "type": "Паб",
                "opening_hours": "12:00-23:00",
                "contact_website": "https:\/\/www.m-lvk.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.270539, 59.902343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Бобыльская дорога, 59",
                "name": "Бегемот",
                "type": "Паб",
                "opening_hours": "Mo-Fr 15:00-23:00; Sa-Su 13:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9168623",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.861473, 59.886731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Гостилицкая улица, 2",
                "name": "Бегемот",
                "type": "Паб",
                "opening_hours": "Mo-Fr 12:00+; Sa,Su 15:00+",
                "contact_website": null,
                "contact_vk": "http:\/\/vk.com\/behemothpub",
                "contact_instagram": null,
                "contact_phone": "+7 950 0019565",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.856577, 59.87537]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, улица Дашкевича, 6В",
                "name": "MacNaMara Irish Pub",
                "type": "Паб",
                "opening_hours": "Su-Th 17:00-1:00; Fr-Sa 17:00-3:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9253569",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [29.919639, 59.867877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пионерская улица, 11",
                "name": "The Pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/thepub.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 9973198",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.293266, 59.956719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8к2",
                "name": "Борода",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/borodaparnas",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.338236, 60.07367]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 137к1",
                "name": "О'Хулиганс",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.216146, 59.983531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 8к1",
                "name": "Белла - магазин алкогольных напитков",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.243545, 60.011855]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Бакунина, 5",
                "name": "O'Hooligans",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.374995, 59.929799]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 69",
                "name": "The Library English Pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/librarypub.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.231337, 59.838182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 30к2",
                "name": "У чаши",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.264206, 60.006012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "name": "Molly",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "name": "Gaststatte",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Королёва, 21к1",
                "name": "O'Briens",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.obriens.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.279567, 60.011914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Королёва, 31к1",
                "name": "Толстый фраер -Гастроном",
                "type": "Паб",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.263703, 60.014342]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 11к3",
                "name": "Veleson",
                "type": "Паб",
                "opening_hours": "Su-Th 13:00-01:00; Fr-Sa 13:00-03:00",
                "contact_website": "https:\/\/www.velesonpub.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9982694",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330151, 60.042029]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 1к1",
                "name": "Irish milligans",
                "type": "Паб",
                "opening_hours": "13:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318446, 60.043628]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 46",
                "name": "Толстый фраер",
                "type": "Паб",
                "opening_hours": "Su-Th 10:00-01:00; Fr-Sa 10:00-03:00",
                "contact_website": "http:\/\/tolstiy-fraer.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5923885",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327519, 60.044424]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 52к1",
                "name": "Радио Ирландия",
                "type": "Паб",
                "opening_hours": "Su-Th 14:00-02:00; Fr-Sa 14:00-03:00",
                "contact_website": "https:\/\/radioireland.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9077787",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.333592, 60.043332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 76к2",
                "name": "КультБар",
                "type": "Паб",
                "opening_hours": "Mo-Fr 12:00-23:59; Sa-Su 13:00-23:59",
                "contact_website": "http:\/\/www.cultbar.ru",
                "contact_vk": "https:\/\/vk.com\/kultbar",
                "contact_instagram": "https:\/\/www.instagram.com\/kultbar_spb",
                "contact_phone": "+7 812 5573694",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.365132, 60.036906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 116к1",
                "name": "Bar Beer Wood",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.395171, 59.971848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 10",
                "name": "The БАР",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr 12:00-03:00; Sa 14:00-03:00; Su 14:00-02:00",
                "contact_website": "http:\/\/www.barspb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6556714",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.282154, 59.850156]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Наставников, 27",
                "name": "Вацлав Замок",
                "type": "Паб",
                "opening_hours": "Mo-Fr 12:30-24:00; Sa,Su 00:00-01:00,12:30-24:00",
                "contact_website": "http:\/\/www.vaclav-beer.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 521-30-98",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.490635, 59.947672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Наставников, 45к1",
                "name": "Литра",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.482065, 59.955741]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Науки, 19к2",
                "name": "Pub Dublin",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.394444, 60.012966]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 16к1",
                "name": "BEERstudia",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/beerstudia.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 7552520",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.996205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 138к2",
                "name": "Old Friends",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.484715, 59.845785]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 15",
                "name": "Odd's",
                "type": "Паб",
                "opening_hours": "11:00-23:00",
                "contact_website": "https:\/\/vk.com\/oddspubclub",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.327196, 60.053952]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 76к1",
                "name": "The Templet Bar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/www.templetbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5943242",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.395189, 60.041004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Пятилеток, 9к1",
                "name": "Royalty",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.456841, 59.923748]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 1",
                "name": "London",
                "type": "Паб",
                "opening_hours": "24\/7",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/publondon_spb",
                "contact_instagram": "https:\/\/www.instagram.com\/londonpub_spb\/",
                "contact_phone": "+7 812 5726079;+7 931 5347602",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 59.924515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Славы, 40к1",
                "name": "Holy Molly",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.398837, 59.861376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 55",
                "name": "Black beer",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3851157",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.257442, 59.877226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 69",
                "name": "Dog & Duck",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.259247, 59.869517]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "name": "Билл Хук",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Тореза, 21",
                "name": "Морис-Паб",
                "type": "Паб",
                "opening_hours": "12:00-06:00",
                "contact_website": "http:\/\/vk.com\/club7488627",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7(812)2975300",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.36055, 60.00285]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Художников, 9к1",
                "name": "Peggy O`Neill`s",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344488, 60.035315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "name": "O'Briens",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:30; Fr-Sa 12:00-02:30",
                "contact_website": "https:\/\/vk.com\/obriens",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6802777",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "name": "Kriek",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-01:00, Fr-Sa 12:00-04:00, Su 12:00-02:30",
                "contact_website": "http:\/\/www.kriek.ru\/contacts\/restoran-na-engelsa-124\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6111110;+7 812 9073410",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 133к1",
                "name": "SPb",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.333394, 60.048804]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 133к1Б",
                "name": "Molly Malone",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/malone\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2960229",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.333879, 60.049698]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 139\/21",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335236, 60.051513]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "name": "Molly Malone",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 58",
                "name": "Tumann",
                "type": "Паб",
                "opening_hours": "Mo-Su 14:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.32248, 60.015183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковская улица, 10к1",
                "name": "Моране",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350902, 59.836147]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 3к1",
                "name": "RedFox Pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31753, 59.840655]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 3к1",
                "name": "Red Fox",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.31753, 59.840655]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Красносельское шоссе, 35",
                "name": "Пивная лавка",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.368375, 59.703984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, улица Вячеслава Шишкова, 32\/15",
                "name": "Belfast pub",
                "type": "Бар",
                "opening_hours": "Mo-Fr 12:00-06:00; Sa-Su 14:00-06:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/pubelfast",
                "contact_instagram": "https:\/\/www.instagram.com\/belfast.pushkin\/",
                "contact_phone": "+7 812 4763780",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.405764, 59.736638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 1\/77",
                "name": "Рюмочная",
                "type": "Паб",
                "opening_hours": "12:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.930962]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 11",
                "name": "Dog's Nose",
                "type": "Паб",
                "opening_hours": "Mo-Fr 00:00-01:00,17:00-24:00; Sa,Su 00:00-03:00,17:00-24:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344686, 59.925056]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 19",
                "name": "Drunk and Happy",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-00:00; Fr 12:00-02:00; Sa 13:00-02:00; Su 13:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6556090",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347058, 59.924064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рашетова улица, 3",
                "name": "Вацлав",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-00:00; Fr-Sa 12:00-01:00; Su 13:00-00:00",
                "contact_website": "http:\/\/vaclav-spb.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 553-34-53",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.322309, 60.020737]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рижский проспект, 54",
                "name": "Флотилия",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": "http:\/\/www.flotilia.su\/",
                "contact_vk": "https:\/\/vk.com\/flotilla_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.276809, 59.913162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рядовая улица, 1",
                "name": "DR-Z",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "7?981?850?08?71",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.134337, 59.996313]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 13-15\/50",
                "name": "O'Hooligans",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/sad.ohooligans.ru\/",
                "contact_vk": "https:\/\/vk.com\/ohooligans",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335074, 59.934898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 5",
                "name": "Irish House",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/irishhousepub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.335739, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 60",
                "name": "Jager Haus",
                "type": "Паб",
                "opening_hours": "Su-Tu 11:00-01:00; Fr-Sa 11:00-02:30",
                "contact_website": "http:\/\/www.jagerhaus.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3375895;+7 812 6111110",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.408754, 59.953655]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 40к1",
                "name": "O'Brien's",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-01:30; Fr-Sa 12:00-02:30",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.376549, 60.023191]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Свечной переулок, 13",
                "name": "Old Friends",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "http:\/\/oldfriends.pub\/",
                "contact_vk": "https:\/\/vk.com\/oldfriendspub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350597, 59.924361]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Серебристый бульвар, 21",
                "name": "Niccolo Polo",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.283933, 60.007074]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Софийская улица, 33к1",
                "name": "Перекрёсток",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.399304, 59.875524]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Софийская улица, 39к1",
                "name": "Пивпост 78",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.401936, 59.873202]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Спасский переулок, 7",
                "name": "Tarabar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/tarabarspb\/",
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.318015, 59.928627]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 28",
                "name": "Контакт Бар",
                "type": "Паб",
                "opening_hours": "Su-Th 15:00-01:00; Fr-Sa 15:00-06:00",
                "contact_website": "https:\/\/kontaktbar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.279325, 59.943129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 64",
                "name": "Jager Haus",
                "type": "Паб",
                "opening_hours": "Mo-Fr 11:00-01:00; Sa,Su 11:00-02:30",
                "contact_website": "http:\/\/www.jagerhaus.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3213375;+7 812 6111110",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.266515, 59.939419]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Стародеревенская улица, 6к1",
                "name": "Gladiators",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.242045, 59.986668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торжковская улица, 6",
                "name": "Тара бруч",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "https:\/\/vk.com\/thepub",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3366666",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.308349, 59.988536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица 22",
                "name": "Beer History",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "other",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.206696, 59.999282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица, 10к1",
                "name": "Irish pub",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": "no",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.202626, 59.989967]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица, 22",
                "name": "Beer History",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "other",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.206696, 59.999282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туристская улица, 28к1",
                "name": "Antwerpen",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.209382, 60.003228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тучков переулок, 11\/5",
                "name": "Muscat",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.288829, 59.944914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Удельный проспект, 25",
                "name": "У друзей",
                "type": "Паб",
                "opening_hours": "10:00+",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.315006, 60.017468]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "name": "Macnamara",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 105к1",
                "name": "Бильярдный клуб \"Барс\"",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.451109, 59.867593]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 11",
                "name": "Фарш & Бочка",
                "type": "Паб",
                "opening_hours": "Mo-Fr 17:00-24:00; Fr-Sa 12:00-02:00; Su 12:00-24:00",
                "contact_website": "http:\/\/www.beercard.ru\/farshbochka\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9415170",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347022, 59.938076]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 13",
                "name": "Толстый фраер",
                "type": "Паб",
                "opening_hours": "Su-Th 10:00-01:00, Fr-Sa 10:00-03:00",
                "contact_website": "http:\/\/tolstiy-fraer.ru\/rubric\/15\/",
                "contact_vk": "https:\/\/vk.com\/tolstiyfraer",
                "contact_instagram": null,
                "contact_phone": "+7 812 2727943",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347848, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 13",
                "name": "Чешская пивница",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-05:00",
                "contact_website": "https:\/\/parkking.ru\/restoranyi\/mesto-vstrechi-na-belinskogo\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.347848, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 3",
                "name": "Oliver",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2723361",
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345198, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Беринга, 27к1",
                "name": "Ми Фан",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_website": "http:\/\/www.mifan777.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4989198",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.237005, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Воскова, 31\/20",
                "name": "Porter House",
                "type": "Паб",
                "opening_hours": "12:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2333352",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.311341, 59.958516]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 17",
                "name": "Подстреленная Гусыня",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_website": "https:\/\/www.pivnicespb.ru\/",
                "contact_vk": "https:\/\/vk.com\/potrefena",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360577, 59.934961]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 20\/16",
                "name": "Брюссель",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": "http:\/\/originalbelgian.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9440336",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360065, 59.93763]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 26",
                "name": "United Butchers",
                "type": "Паб",
                "opening_hours": "Mo-Th, Su 12:00-01:00; Fr, Sa 12:00-02:00",
                "contact_website": "https:\/\/unitedbutchers.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 6002119",
                "wheelchair": "limited",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360173, 59.939126]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 37-39",
                "name": "Foggy New",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club553820",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360919, 59.939717]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 42",
                "name": "Пивной двор",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club152125340",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.360505, 59.94326]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 55",
                "name": "Пивная карта",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.361161, 59.943323]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 27",
                "name": "Шемрок",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-02:00",
                "contact_website": "http:\/\/shamrock.spb.ru",
                "contact_vk": "https:\/\/vk.com\/theshamrockspb",
                "contact_instagram": null,
                "contact_phone": "+7 812 5704625",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.295467, 59.926584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 53",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.284589, 59.924181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Егорова, 11 ",
                "name": "Husky bar",
                "type": "Паб",
                "opening_hours": "Mo-Su 14:00-00:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9458248",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.313748, 59.913595]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "name": "Пивная библиотека",
                "type": "Паб",
                "opening_hours": "Sa-Su 14:00-02:00; Mo-Fr 15:00-01:00",
                "contact_website": "http:\/\/vk.com\/la_biblioteque_de_biere",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3",
                "name": "Shilling",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 3242429",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.319857, 59.925899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 5",
                "name": "Пенное",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.255726, 59.856121]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 18",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Sa 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.464476, 59.91931]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 18",
                "name": "Dublin",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.464476, 59.91931]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 31к2",
                "name": "Glory Pub",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_website": "http:\/\/glory-pub.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 911 9282711",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.484581, 59.922476]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 23",
                "name": "Tower",
                "type": "Паб",
                "opening_hours": "Mo-Th 11:00-23:00; Fr-Sa 11:00-06:00; Su 11:00-23:00",
                "contact_website": "http:\/\/www.pub-tower.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2335733",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.331508, 59.956557]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 38-40",
                "name": "Чердак",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-23:30; Fr-Sa 12:00-02:00",
                "contact_website": "http:\/\/www.cherdakcafe.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2321182",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.333816, 59.957994]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленина, 16\/42",
                "name": "Бражник бар",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-03:00",
                "contact_website": "https:\/\/vk.com\/brajnikbar",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2305359",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.306642, 59.961102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленина, 25",
                "name": "БарLestrBar",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302474, 59.963183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 20",
                "name": "Marco Polo",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.330304, 59.853329]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 90",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.351989, 59.832001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 11",
                "name": "Marius",
                "type": "Паб",
                "opening_hours": "24\/7",
                "contact_website": "https:\/\/mariuspub.ru\/",
                "contact_vk": "https:\/\/vk.com\/mariusrestaurant",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354002, 59.929574]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 2\/73-75",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355816, 59.931242]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 36-38",
                "name": "Bristol",
                "type": "Паб",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-03:00",
                "contact_website": "http:\/\/molly.su\/bristol\/",
                "contact_vk": "https:\/\/vk.com\/bristol_pub",
                "contact_instagram": null,
                "contact_phone": "+7 812 3323255",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352519, 59.925705]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 4",
                "name": "J?ger",
                "type": "Паб",
                "opening_hours": "Su-Th 11:30-01:00, Fr-Sa 11:30-06:00",
                "contact_website": "http:\/\/www.jagerhaus.ru\/kontakty\/restoran-na-marata\/",
                "contact_vk": "https:\/\/vk.com\/jagerhaus",
                "contact_instagram": null,
                "contact_phone": "+7 812 3158574;+7 812 6111110",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355592, 59.93089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1Б",
                "name": "Лит.Ra",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.249375, 59.86036]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Тухачевского, 41",
                "name": "Вацлав Замок",
                "type": "Паб",
                "opening_hours": "Mo-Fr 12:00-24:00; Sa,Su 00:00-01:00,13:00-24:00",
                "contact_website": "http:\/\/www.vaclav-beer.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 925-96-11",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.416956, 59.973203]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 11",
                "name": "Jawsspot",
                "type": "Паб",
                "opening_hours": "Su-Th 16:00-01:00; Fr-Sa 14:00-02:00",
                "contact_website": "https:\/\/www.jaws.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/jawsspotspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.355502, 59.936814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 16",
                "name": "Ливерпуль",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-01:00, Fr 12:00-03:00, Sa 16:00-03:00, Su 16:00-01:00",
                "contact_website": "http:\/\/www.liverpool.ru\/",
                "contact_vk": "https:\/\/vk.com\/liverpoolbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354765, 59.936224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 16",
                "name": "Spirt Bar",
                "type": "Паб",
                "opening_hours": "Mo off",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.354765, 59.936224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Некрасова, 19",
                "name": "Irish Yoga",
                "type": "Паб",
                "opening_hours": "16:00-05:00",
                "contact_website": "http:\/\/irishyoga.ru\/",
                "contact_vk": "https:\/\/vk.com\/irishyoga",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357397, 59.938653]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Некрасова, 25",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359185, 59.938608]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Некрасова, 26",
                "name": "Redrum",
                "type": "Паб",
                "opening_hours": "Mo-Th 16:00-01:00, Fr 16:00-02:00, Sa 15:00-02:00, Su 15:00-01:00",
                "contact_website": "https:\/\/www.facebook.com\/redrumbarspb",
                "contact_vk": "https:\/\/vk.com\/redrumbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357092, 59.938987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Некрасова, 34",
                "name": "Сеновал",
                "type": "Паб",
                "opening_hours": "11:30-23:30",
                "contact_website": "https:\/\/www.senoval-traktir.ru\/",
                "contact_vk": "https:\/\/vk.com\/traktirsenoval",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358987, 59.938933]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Некрасова, 37\/20, подъезд 1",
                "name": "The Templet Bar",
                "type": "Паб",
                "opening_hours": "12:00-02:00",
                "contact_website": "http:\/\/templetbar.ru\/templet-centr\/",
                "contact_vk": "https:\/\/vk.com\/templetbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 5791855",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.3632, 59.93859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Некрасова, 6",
                "name": "Красный угол",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/theredcornerpub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350687, 59.939185]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 47к1",
                "name": "Dublin Times",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.202195, 60.003169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Подвойского, 16к1В",
                "name": "Камелот",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.468698, 59.911886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "name": "Mollie's Pub",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/mollies\/",
                "contact_vk": "https:\/\/vk.com\/mollies_irish",
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 21",
                "name": "Базара нет",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/bazaranetbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.343276, 59.928226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 5",
                "name": "Fiddler's Green",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": "https:\/\/hatgroup.ru\/fiddlers-green\/",
                "contact_vk": "https:\/\/vk.com\/fiddlersgreen",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.345189, 59.931576]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рылеева, 6",
                "name": "Pickwick",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/pickwick_pub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.356292, 59.94262]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Турку, 35",
                "name": "Пенное - магазин пива",
                "type": "Паб",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.412437, 59.866829]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Хлопина, 7к4",
                "name": "Пивляндия",
                "type": "Паб",
                "opening_hours": "11:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 921 5754325",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.370117, 59.999696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Циолковского, 10Б",
                "name": "Пивас",
                "type": "Паб",
                "opening_hours": "14:00-22:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.288856, 59.91037]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чайковского, 38\/9",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": "no",
                "outdoor_seating": "yes",
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358646, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Шаврова, 10к2",
                "name": "Wartburg",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.23157, 60.023565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Щербакова, 4\/18",
                "name": "Новый Порт",
                "type": "Паб",
                "opening_hours": "Su-Th 14:00-23:00; Fr-Sa 14:00-02:00",
                "contact_website": "http:\/\/npspb.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 931 3112982",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.300651, 60.028708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 5",
                "name": "SПБ",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.830676]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Уральская улица, 6",
                "name": "Богатырский Хмель",
                "type": "Паб",
                "opening_hours": "Mo-Fr 11:00-23:59; Sa-Su 11:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.264997, 59.95129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 14к1",
                "name": "Black Cat",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.312742, 60.012827]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 37",
                "name": "Beer Culture",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.364422, 59.944877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 9",
                "name": "Золотая пинта",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-23:00; Fr, Sa 12:00-01:00; Su 13:00-23:00",
                "contact_website": "http:\/\/www.goldenpint.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7196015",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.352897, 59.945067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 19",
                "name": "Irish Yoga",
                "type": "Паб",
                "opening_hours": "16:00-05:00",
                "contact_website": "http:\/\/irishyoga.ru\/",
                "contact_vk": "https:\/\/vk.com\/irishyoga",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357397, 59.938653]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 25",
                "name": "Beer House",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_website": "http:\/\/beerhousebar.ru\/",
                "contact_vk": "https:\/\/vk.com\/beerhousebar",
                "contact_instagram": null,
                "contact_phone": "+7 812 501 21 64",
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.359185, 59.938608]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 26",
                "name": "Redrum",
                "type": "Паб",
                "opening_hours": "Mo-Th 16:00-01:00, Fr 16:00-02:00, Sa 15:00-02:00, Su 15:00-01:00",
                "contact_website": "https:\/\/www.facebook.com\/redrumbarspb",
                "contact_vk": "https:\/\/vk.com\/redrumbar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.357092, 59.938987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 34",
                "name": "Сеновал",
                "type": "Паб",
                "opening_hours": "11:30-23:30",
                "contact_website": "https:\/\/www.senoval-traktir.ru\/",
                "contact_vk": "https:\/\/vk.com\/traktirsenoval",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.358987, 59.938933]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 37\/20",
                "name": "The Templet Bar",
                "type": "Паб",
                "opening_hours": "12:00-02:00",
                "contact_website": "http:\/\/templetbar.ru\/templet-centr\/",
                "contact_vk": "https:\/\/vk.com\/templetbar",
                "contact_instagram": null,
                "contact_phone": "+7 812 5791855",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.3632, 59.93859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 6",
                "name": "Красный угол",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/theredcornerpub",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.350687, 59.939185]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 58",
                "name": "Синдикат",
                "type": "Паб",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302357, 59.968434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шамшева улица, 4",
                "name": "Molly Sullivan",
                "type": "Паб",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_website": "http:\/\/molly.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 7777966",
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.302771, 59.959552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, шоссе Революции",
                "name": "Пивной дозор",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "street",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.445342, 59.960422]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Яхтенная улица, 1к1",
                "name": "Anfield",
                "type": "Паб",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "outdoor_seating": null,
                "precision": "exact",
                "city_function": "Питание"
            },
            "geometry": {"type": "Point", "coordinates": [30.215571, 59.984274]}
        }
    ]
}
