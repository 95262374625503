export const landfills = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {
            "type": "Feature",
            "properties": {"landuse": "landfill", "name": "Полигон ТБО", "type": "Свалка", "city_function": "Мусор"},
            "geometry": {"type": "Point", "coordinates": [30.435919, 59.925014]}
        },
        {
            "type": "Feature",
            "properties": {"landuse": "landfill", "name": "Полигон ТБО", "type": "Свалка", "city_function": "Мусор"},
            "geometry": {"type": "Point", "coordinates": [30.421509, 59.850648]}
        },
        {
            "type": "Feature",
            "properties": {"landuse": "landfill", "name": "Полигон ТБО", "type": "Свалка", "city_function": "Мусор"},
            "geometry": {"type": "Point", "coordinates": [30.158373, 59.812754]}
        },
        {
            "type": "Feature",
            "properties": {"landuse": "landfill", "name": "Полигон ТБО", "type": "Свалка", "city_function": "Мусор"},
            "geometry": {"type": "Point", "coordinates": [30.263984, 60.026141]}
        },
        {
            "type": "Feature",
            "properties": {"landuse": "landfill", "name": "Полигон ТБО", "type": "Свалка", "city_function": "Мусор"},
            "geometry": {"type": "Point", "coordinates": [30.550474, 59.821891]}
        },
        {
            "type": "Feature",
            "properties": {"landuse": "landfill", "name": "Полигон ТБО", "type": "Свалка", "city_function": "Мусор"},
            "geometry": {"type": "Point", "coordinates": [30.142944, 59.817446]}
        }
    ]
}
