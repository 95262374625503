export const theaters_concert_halls = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 124",
                "city_function": "Культура, развлечения, творчество",
                "name": "Чеширский Кот",
                "short_name": null,
                "contact_phone": "+7 996 777 04 65",
                "contact_website": "https:\/\/www.catartclub.com\/",
                "contact_vk": "https:\/\/vk.com\/catartclub",
                "opening_hours": "10:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.364916, 59.930742]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лахтинская улица, 25АЖ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр поколений им. З. Я. Корогодского",
                "short_name": null,
                "contact_phone": "+7 911 245-85-90",
                "contact_website": "http:\/\/www.pokoleniy.ru\/",
                "contact_vk": null,
                "opening_hours": "Fr-Su 10:00-23:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 33407.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.297183, 59.964097]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект В.О., 83",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворец культуры им. С.М. Кирова",
                "short_name": "«Львёнок»",
                "contact_phone": "+7 911 168-55-28",
                "contact_website": "http:\/\/dkkirova.spb.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "number_of_places": "1120",
                "ref_mkrf_theaters": 18385.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.254001, 59.933577]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 15А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский государственный академический театр балета имени Леонида Якобсона",
                "short_name": "Театр балета им Л. Якобсона",
                "contact_phone": "+7 812 273-19-97",
                "contact_website": "http:\/\/yacobsonballet.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "number_of_places": "собственная сцена отсутствует",
                "ref_mkrf_theaters": 11314.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.355556, 59.937914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Новостроек, 25",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр детского балета",
                "short_name": null,
                "contact_phone": "+7 812 784-62-03",
                "contact_website": "http:\/\/www.children-ballet.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 13006.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.270009, 59.873473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 34к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр «ТриЧетыре»",
                "short_name": null,
                "contact_phone": "+7 812 980-20-01",
                "contact_website": "http:\/\/teatr34.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 11:00-20:00; Sa-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 31518.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.355484, 60.000686]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Моховая улица, 33-35",
                "city_function": "Культура, развлечения, творчество",
                "name": "Учебный театр «На Моховой»",
                "short_name": null,
                "contact_phone": "+7 812 273-04-32",
                "contact_website": "http:\/\/учебный-театр.рф",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 11:00-22:00; Sa-Su 15:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 19470.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.344749, 59.940614]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 104",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский Интерьерный театр",
                "short_name": null,
                "contact_phone": "+7 812 273-14-54",
                "contact_website": "http:\/\/spb-ith.ru\/",
                "contact_vk": "https:\/\/vk.com\/nevski104",
                "opening_hours": "Mo-Fr 12:00-21:00; Sa 11:00-23:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 40579.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.356589, 59.931882]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 114",
                "city_function": "Культура, развлечения, творчество",
                "name": "СпбГБУК \"Санкт-Петербургский молодежный театр на Фонтанке\"",
                "short_name": "Молодёжный театр на Фонтанке",
                "contact_phone": "+7 812 316-65-64",
                "contact_website": "http:\/\/www.mtfontanka.spb.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 12:00-21:00; Sa-Su 12:00-20:00",
                "number_of_places": "815",
                "ref_mkrf_theaters": 14373.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31338, 59.919067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 29",
                "city_function": "Культура, развлечения, творчество",
                "name": "Особняк",
                "short_name": null,
                "contact_phone": "+7 921 906-91-53",
                "contact_website": "http:\/\/osobnjak.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 14:00-22:00; Sa-Su 12:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 18377.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.307424, 59.971398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Тореза, 39к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Гиппократ",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/hippokrat-club.narod2.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 16:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.352322, 60.009804]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 56",
                "city_function": "Культура, развлечения, творчество",
                "name": "Эра",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Sa 14:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.303507, 59.960773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр Karlsson Haus на Ломоносова",
                "short_name": null,
                "contact_phone": "+7 812 309-56-01",
                "contact_website": "http:\/\/www.karlssonhaus.ru\/",
                "contact_vk": "https:\/\/vk.com\/karlssonhaus",
                "opening_hours": "Mo-Su 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 39025.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.338479, 59.927874]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 50К",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр Karlsson Haus на Фонтанке",
                "short_name": null,
                "contact_phone": "+7 812 309-56-01",
                "contact_website": "http:\/\/www.karlssonhaus.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 39022.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.342584, 59.930268]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 30",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр Karlsson Haus на Фурштатской",
                "short_name": null,
                "contact_phone": "+7 812 309-56-01",
                "contact_website": "http:\/\/www.karlssonhaus.ru\/furshtatskaya.php",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 39024.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.356706, 59.94545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 41",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный Пушкинский театральный центр",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.pushkincenter.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 18521.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339269, 59.930705]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 43",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр на Коломенской",
                "short_name": null,
                "contact_phone": "+7 812 380-83-72",
                "contact_website": "https:\/\/privatetheatre.ru\/",
                "contact_vk": "https:\/\/vk.com\/theprivatetheatre",
                "opening_hours": "Mo-Su 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 23805.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.349591, 59.920889]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 44",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кот Вильям",
                "short_name": "Детский интерактивный театр",
                "contact_phone": "+7 931 2966252;+7 812 7196159",
                "contact_website": "https:\/\/tavrik.com\/",
                "contact_vk": "https:\/\/vk.com\/kotwiliam",
                "opening_hours": "Mo-Su 10:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362329, 59.945373]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 79",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственная филармония Санкт-Петербурга для детей и юношества",
                "short_name": null,
                "contact_phone": "+7 812 295-42-67",
                "contact_website": "http:\/\/www.filspb.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 11455.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.334769, 59.98864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "квесты с актерами 2+2",
                "short_name": null,
                "contact_phone": "+79119252840",
                "contact_website": "http:\/\/2plus2spb.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.297686, 59.921385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 31А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр «Комик-Трест»",
                "short_name": null,
                "contact_phone": "+7 812 328-16-19",
                "contact_website": "https:\/\/www.comic-trust.com",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 38931.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.279567, 59.940465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Зои Космодемьянской, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр у Нарвских ворот",
                "short_name": null,
                "contact_phone": "+7 812 786-51-48",
                "contact_website": "http:\/\/www.teatrlutz.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-16:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 12996.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.272039, 59.893267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Театральная площадь, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мариинский театр",
                "short_name": null,
                "contact_phone": "+7 812 326-41-41",
                "contact_website": "https:\/\/www.mariinsky.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 9541.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.29624, 59.925728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 34",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мариинский-2",
                "short_name": "Вторая сцена Мариинского театра",
                "contact_phone": "+7 812 326-41-41",
                "contact_website": "https:\/\/www.mariinsky.ru\/",
                "contact_vk": "https:\/\/vk.com\/mariinskytheatre",
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 9542.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293805, 59.925678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 12А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культуры \"Санкт-Петербургский академический Театр имени Ленсовета\"",
                "short_name": "Театр имени Ленсовета",
                "contact_phone": "+7 812 764-39-65",
                "contact_website": "http:\/\/lensov-theatre.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/public70891806",
                "opening_hours": "Mo-Su 11:00-20:00",
                "number_of_places": "729",
                "ref_mkrf_theaters": 11318.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.348297, 59.930025]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Народная улица, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПбГБУК \"Санкт-Петербургский государственный театр \"Мастерская\"",
                "short_name": "Санкт-Петербургский театр «Мастерская»",
                "contact_phone": "+7 812 922-21-42",
                "contact_website": "http:\/\/www.vteatrekozlov.net",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-21:00",
                "number_of_places": "349",
                "ref_mkrf_theaters": 12278.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.457398, 59.877253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 13",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное автономное учреждение культуры \"Санкт-Петербургский государственный театр музыкальной комедии\"",
                "short_name": "Театр музыкальной комедии",
                "contact_phone": "+7 812 570-53-16",
                "contact_website": "http:\/\/www.muzcomedy.ru\/",
                "contact_vk": "https:\/\/vk.com\/spb_muzcomedy",
                "opening_hours": "Mo-Su 11:00-21:00",
                "number_of_places": "1228",
                "ref_mkrf_theaters": 14398.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.333107, 59.936296]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культуры \"Академический драматический театр им. В.Ф.Комиссаржевской\"",
                "short_name": "Академический драматический театр имени В. Ф. Комиссаржевской",
                "contact_phone": "+7 812 315-53-55",
                "contact_website": "http:\/\/www.teatrvfk.ru\/",
                "contact_vk": "https:\/\/vk.com\/teatrvfk",
                "opening_hours": "Mo-Su 11:00-23:00",
                "number_of_places": "628",
                "ref_mkrf_theaters": 11322.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.335505, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 56",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПб ГБУК «Академический театр Комедии им. Н. П. Акимова»",
                "short_name": "Театр комедии имени Н. П. Акимова",
                "contact_phone": "+7 812 312-45-55",
                "contact_website": "http:\/\/akimovkomedia.ru\/",
                "contact_vk": "https:\/\/vk.com\/club83542",
                "opening_hours": "Mo-Su 11:30-23:00",
                "number_of_places": "561",
                "ref_mkrf_theaters": 15840.0,
                "wheelchair": "no",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.338074, 59.934199]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Галерная улица, 33",
                "city_function": "Культура, развлечения, творчество",
                "name": "Спб ГБУК «Камерный музыкальный театр “Санктъ-Петербургъ Опера”»",
                "short_name": "Санктъ-Петербургъ Опера",
                "contact_phone": "+7 812 312-39-82",
                "contact_website": "https:\/\/www.spbopera.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 12:00-19:00",
                "number_of_places": "171",
                "ref_mkrf_theaters": 14391.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.292665, 59.932951]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 27",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПБГБУ культуры \"Театр Эстрады имени Аркадия Райкина\"",
                "short_name": "Театр эстрады имени Аркадия Райкина",
                "contact_phone": "+7 812 314-66-61",
                "contact_website": "http:\/\/www.estrada.spb.ru",
                "contact_vk": "https:\/\/vk.com\/estrada_spb",
                "opening_hours": "Mo-Su 12:00-19:00",
                "number_of_places": "522",
                "ref_mkrf_theaters": 11328.0,
                "wheelchair": "yes",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.322363, 59.936999]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 27",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культуры \"Государственный драматичекий театр \"Приют комедианта\"",
                "short_name": "Приют комедианта",
                "contact_phone": "+7 812 3103309",
                "contact_website": "http:\/\/www.pkteatr.ru\/",
                "contact_vk": "https:\/\/vk.com\/priut_komedianta",
                "opening_hours": "Mo-Su 12:00-20:00",
                "number_of_places": "185",
                "ref_mkrf_theaters": 26256.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323162, 59.929285]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 35\/75",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр имени Андрея Миронова",
                "short_name": "Русская Антреприза имени Андрея Миронова",
                "contact_phone": "+7 812 346-16-79",
                "contact_website": "https:\/\/www.mironov-theatre.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 12:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.312859, 59.965809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 20",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственная академическая капелла Санкт-Петербурга",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/capella-spb.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Su 12:00-20:30",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": "no",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321069, 59.940019]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 18\/5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Академический Малый драматический театр – Театр Европы",
                "short_name": "Театр Европы",
                "contact_phone": "+7 812 713-20-78",
                "contact_website": "http:\/\/mdt-dodin.ru\/",
                "contact_vk": "https:\/\/vk.com\/mdt_dodin",
                "opening_hours": "Mo-Su 12:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 15312.0,
                "wheelchair": "yes",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.345135, 59.930327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рижский проспект, 23",
                "city_function": "Культура, развлечения, творчество",
                "name": "Клоун-мим-театр «Мимигранты»",
                "short_name": null,
                "contact_phone": "+7 812 251-15-51",
                "contact_website": "https:\/\/www.mimigrants.spb.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Th, Su 10:00-18:00; Fr-Sa 10:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 12900.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.282639, 59.913663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 87к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Аквариум (детский)",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Th 15:00-20:00 Fr 15:00-21:00 Sa 10:00-19:00 Su 9:30-17:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.414521, 60.036097]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 48\/27",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр на Васильевском",
                "short_name": "Государственный драматический театр на Васильевском",
                "contact_phone": "+7 812 3215996",
                "contact_website": "http:\/\/www.teatrvo.ru\/",
                "contact_vk": null,
                "opening_hours": "Tu-Su 11:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 12473.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.271743, 59.940902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Шаумяна, 22",
                "city_function": "Культура, развлечения, творчество",
                "name": "Спб ГБУК \"Санкт-Петербургский государственный музыкально-драматический театр \"Буфф\"",
                "short_name": "Буфф",
                "contact_phone": "+7 812 5768351",
                "contact_website": "http:\/\/buffspb.ru",
                "contact_vk": null,
                "opening_hours": "Tu-Su 12:00-20:00; Mo 13:00-18:00",
                "number_of_places": "820",
                "ref_mkrf_theaters": 11111.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.417297, 59.931648]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 30",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПбГБУ культуры \"Санкт-Петербургский театр \"Суббота\"",
                "short_name": "Санкт-Петербургский театр «Суббота»",
                "contact_phone": "+7 812 764-82-02",
                "contact_website": "http:\/\/teatr-subbota.ru\/",
                "contact_vk": "https:\/\/vk.com\/teatr_subbota",
                "opening_hours": "Tu-Su 12:00-23:00",
                "number_of_places": "96",
                "ref_mkrf_theaters": 11122.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.343024, 59.9183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 130АД",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр Дождей",
                "short_name": null,
                "contact_phone": "+7 812 2514190",
                "contact_website": "http:\/\/rains.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/rains_spb",
                "opening_hours": "Tu-Th 16:00-19:00; Fr 08:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 29179.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.304289, 59.91752]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Островского, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Александринский театр",
                "short_name": null,
                "contact_phone": "+7 812 961-77-99",
                "contact_website": "https:\/\/alexandrinsky.ru\/",
                "contact_vk": null,
                "opening_hours": "Tu-Su 13:00-23:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 28288.0,
                "wheelchair": "limited",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.336269, 59.93177]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Казначейская улица, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр-Проект «Музыка города»",
                "short_name": null,
                "contact_phone": "+7 911 139-63-83",
                "contact_website": "http:\/\/музыкагорода.рф",
                "contact_vk": null,
                "opening_hours": "Tu, Fr-Sa 13:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 36931.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.312922, 59.92704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "За чёрной речкой",
                "short_name": null,
                "contact_phone": "+7 812 6433904",
                "contact_website": "http:\/\/teatrzcr.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 15:00-19:00; Sa-Su 10:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.291084, 59.999354]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 27",
                "city_function": "Культура, развлечения, творчество",
                "name": "Филармония джазовой музыки",
                "short_name": null,
                "contact_phone": "7 (812) 764‒85‒65",
                "contact_website": "https:\/\/www.jazz-hall.ru\/",
                "contact_vk": "https:\/\/vk.com\/jazz_hall",
                "opening_hours": "Tu-Su 18:30-22:30",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.337041, 59.924167]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Советский переулок, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПбГБУК \"Санкт-Петербургский государственный детский драматический театр \"На Неве\"",
                "short_name": "Детский театр «На Неве»",
                "contact_phone": "7 (812) 251‒20‒06",
                "contact_website": "www.tnaneve.spb.ru",
                "contact_vk": "https:\/\/vk.com\/tnaneve",
                "opening_hours": "Mo-Su 10:00-19:00",
                "number_of_places": "501",
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.30931, 59.914069]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 44",
                "city_function": "Культура, развлечения, творчество",
                "name": "Драматический театр «Комедианты»",
                "short_name": null,
                "contact_phone": "+7 812 572-10-04",
                "contact_website": "http:\/\/komedianty.com\/",
                "contact_vk": "https:\/\/vk.com\/teatrkomediantispb",
                "opening_hours": "Mo-Su 12:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 12475.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.358933, 59.926156]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворец Культуры имени Ленсовета",
                "short_name": null,
                "contact_phone": "+7 812 3460438",
                "contact_website": "http:\/\/www.lensoveta.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 9:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309984, 59.966863]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 6к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Куклы",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "https:\/\/www.duklo6.com\/",
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": "yes",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.358071, 60.010088]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Лицедеи",
                "short_name": null,
                "contact_phone": "+7 921 9672842",
                "contact_website": "http:\/\/licedei.com",
                "contact_vk": null,
                "opening_hours": "Tu-Su 14:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31567, 59.965124]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 83",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мимигранты",
                "short_name": null,
                "contact_phone": "+7(812)678-96-01",
                "contact_website": "http:\/\/www.mimigrants.ru",
                "contact_vk": null,
                "opening_hours": "Tu, Th 10:00-18:00 We 10:00-15:00 Fr 14:00-20:00 Sa 11:00-20:00 Su 10:00-16:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.254001, 59.933577]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Лабутина, 36",
                "city_function": "Культура, развлечения, творчество",
                "name": "Рок-опера",
                "short_name": null,
                "contact_phone": "7 (921) 755-66-77",
                "contact_website": "http:\/\/rock-opera.ru\/",
                "contact_vk": null,
                "opening_hours": "Fr-Su 19:00-23:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.28723, 59.91692]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Александровский парк, 1А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Сказкин Дом",
                "short_name": null,
                "contact_phone": "+7 812 3258777",
                "contact_website": "https:\/\/skazkindom.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309212, 59.953615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 52",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культуры \"Санкт-Петербургский государственный театр марионеток им. Е.С. Деммени\"",
                "short_name": "Театр марионеток им. Е. С. Деммени",
                "contact_phone": "7 812 571-21-56",
                "contact_website": "https:\/\/www.demmeni.ru\/",
                "contact_vk": "https:\/\/vk.com\/club2091370",
                "opening_hours": "Tu-Su 10:00-18:80",
                "number_of_places": "174",
                "ref_mkrf_theaters": null,
                "wheelchair": "no",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.335775, 59.934515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 42",
                "city_function": "Культура, развлечения, творчество",
                "name": "Алеко",
                "short_name": null,
                "contact_phone": "+7 812 9311213",
                "contact_website": "http:\/\/www.teatr-aleko.ru\/",
                "contact_vk": null,
                "opening_hours": "Tu 16:00-19:00 We-Fr 12:00-19:00 Sa-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339817, 59.840704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 59",
                "city_function": "Культура, развлечения, творчество",
                "name": "Бродячая собачка",
                "short_name": "Театр кукол",
                "contact_phone": null,
                "contact_website": "http:\/\/www.puppettheatre.spb.ru\/",
                "contact_vk": null,
                "opening_hours": "Th-Fr 14:00-18:00 Sa-Su 10:00-16:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.257909, 59.874047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 51",
                "city_function": "Культура, развлечения, творчество",
                "name": "Драматический театр «На Литейном»",
                "short_name": null,
                "contact_phone": "+7 812 273-53-35",
                "contact_website": "http:\/\/naliteinom.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo 11:00-19:00 Tu-Su 11:00-21:80",
                "number_of_places": null,
                "ref_mkrf_theaters": 12472.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.347776, 59.937197]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 16",
                "city_function": "Культура, развлечения, творчество",
                "name": "Академическая филармония имени Д. Д. Шостаковича (Малый зал)",
                "short_name": null,
                "contact_phone": "+7 812 5718333",
                "contact_website": "https:\/\/www.philharmonia.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/philharmoniaspb",
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327232, 59.935687]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бородинская улица, 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Крошка Арт",
                "short_name": "Театр кукол",
                "contact_phone": "+7 812 9310748",
                "contact_website": "http:\/\/крошка-арт.рф\/",
                "contact_vk": "https:\/\/vk.com\/kroshkaart",
                "opening_hours": "We-Fr 17:00-19:00 Sa-Su 10:30-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.334948, 59.923671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 13",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культурь 'Детский музыкальный театр \"Зазеркалье\"",
                "short_name": "Санкт-Петербургский государственный детский музыкальный театр «Зазеркалье»",
                "contact_phone": "+7 812 712-50-00",
                "contact_website": "http:\/\/www.zazerkal.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/teatrzazerkalye",
                "opening_hours": "Mo-Su 12:00-19:00",
                "number_of_places": "408",
                "ref_mkrf_theaters": 14380.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.343949, 59.929407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 107к5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Скороход",
                "short_name": null,
                "contact_phone": "7 (812) 987‒66‒90",
                "contact_website": "http:\/\/www.skorohod.me",
                "contact_vk": null,
                "opening_hours": "Mo-Su 12:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31691, 59.890875]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 26-28",
                "city_function": "Культура, развлечения, творчество",
                "name": "Остров",
                "short_name": null,
                "contact_phone": "+7 812 346-43-43",
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "We-Su 14:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 14403.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Софийская улица, 44",
                "city_function": "Культура, развлечения, творчество",
                "name": "Задверье",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Sa 17:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.41117, 59.869472]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Политехническая улица, 29",
                "city_function": "Культура, развлечения, творчество",
                "name": "Белый зал СПбПУ",
                "short_name": null,
                "contact_phone": "+7 812 5527645",
                "contact_website": "http:\/\/whitehall.spbstu.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 11:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.3731, 60.007461]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кукольный формат",
                "short_name": "Кукфо",
                "contact_phone": null,
                "contact_website": "http:\/\/www.kukfo.ru\/",
                "contact_vk": "https:\/\/vk.com\/club647800",
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.355619, 59.927364]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 42",
                "city_function": "Культура, развлечения, творчество",
                "name": "Малый Музыкальный Театр",
                "short_name": null,
                "contact_phone": "7 (905) 221-22-17",
                "contact_website": "https:\/\/spbmmt.ru\/",
                "contact_vk": "https:\/\/vk.com\/spb_mmt",
                "opening_hours": "Th 18:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": "limited",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.348836, 59.938306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 107\/6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Синий сарафан",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/bluesarafanteatr.ru\/",
                "contact_vk": "https:\/\/vk.com\/blue_sarafan",
                "opening_hours": "Mo-Su 12:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299932, 59.925872]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Реzиденция",
                "short_name": null,
                "contact_phone": "7 (812) 984‒79‒07",
                "contact_website": "http:\/\/residence-space.ru\/",
                "contact_vk": "https:\/\/vk.com\/residence_space",
                "opening_hours": "Mo-Su 12:00-22:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 12\/20В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Цехъ",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/cehtheatre.com\/",
                "contact_vk": "https:\/\/vk.com\/ceh13",
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.287284, 59.959814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чапаева, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр-студия «Смешарики»",
                "short_name": null,
                "contact_phone": "+7 911 9280345",
                "contact_website": "http:\/\/spb.smeshariki.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330795, 59.96244]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Промышленная улица, 19Р",
                "city_function": "Культура, развлечения, творчество",
                "name": "Странствующие куклы господина Пэжо Театр",
                "short_name": null,
                "contact_phone": "+7 812 4933350;+7 951 6775339;+7 901 3739534",
                "contact_website": "www.pejo.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.258196, 59.896616]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 94",
                "city_function": "Культура, развлечения, творчество",
                "name": "Домашний театр Юсуповского дворца",
                "short_name": null,
                "contact_phone": "+7 921 9703038;+7 812 3143859",
                "contact_website": "https:\/\/www.yusupov-palace.ru\/ru\/theatre",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:30-17:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": "limited",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299016, 59.929475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 41",
                "city_function": "Культура, развлечения, творчество",
                "name": "Камерный театр Малыщицкого",
                "short_name": null,
                "contact_phone": "+7 812 5798836",
                "contact_website": "http:\/\/www.vmtheatre.ru\/",
                "contact_vk": "https:\/\/vk.com\/vmtheatre_spb",
                "opening_hours": "We-Fr 14:00-20:00 Sa-Su 11:00-2:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.360937, 59.940001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, шоссе Революции, 84Ч",
                "city_function": "Культура, развлечения, творчество",
                "name": "Порох",
                "short_name": "Театральная площадка",
                "contact_phone": null,
                "contact_website": "https:\/\/www.facebook.com\/porokh.akhe\/",
                "contact_vk": "https:\/\/vk.com\/porokh.akhe",
                "opening_hours": "We-Su 19:00-23:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.454936, 59.962836]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, шоссе Революции, 84Ч",
                "city_function": "Культура, развлечения, творчество",
                "name": "Инженерный театр «Ахе»",
                "short_name": null,
                "contact_phone": "+7 960 2428078",
                "contact_website": "https:\/\/akhe.ru\/",
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.454936, 59.962836]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 47к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Детский театр \"Малышок\"",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "https:\/\/vk.com\/theater_malishok",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.488515, 59.889322]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тамбовская улица, 63",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский драматический театр им. графини С. В.Паниной",
                "short_name": null,
                "contact_phone": "7 (812) 457‒54‒52",
                "contact_website": "https:\/\/teatrpaninoy.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.35119, 59.909382]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мончегорская улица, 10Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр движения «Люди дождя»",
                "short_name": null,
                "contact_phone": "+7 812 230-16-73",
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 18358.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293751, 59.957551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 84-86",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театральная лаборатория Вадима Максимова",
                "short_name": null,
                "contact_phone": "+7 904 557-73-04",
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Fr 11:00-17:30",
                "number_of_places": null,
                "ref_mkrf_theaters": 18356.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.350526, 59.932562]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1Е",
                "city_function": "Культура, развлечения, творчество",
                "name": "Левендаль, театр",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Fr 11:00-17:30",
                "number_of_places": null,
                "ref_mkrf_theaters": 16393.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.249312, 59.860744]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект КИМа, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культуры \"Театр-студия \"Небольшой драматический театр\"",
                "short_name": "Театр-студия «Небольшой драматический театр»",
                "contact_phone": "+7 921 752-01-13",
                "contact_website": "https:\/\/nebdt.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 10:00-22:00",
                "number_of_places": "70",
                "ref_mkrf_theaters": 14750.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.24606, 59.950839]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Капитана Воронина, 13",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр «Глагол»",
                "short_name": null,
                "contact_phone": "7 (812) 596-38-72",
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Sa 18:00-22:00",
                "number_of_places": "80",
                "ref_mkrf_theaters": 14401.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.346788, 59.987001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дворцовая набережная, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Эрмитажный театр",
                "short_name": null,
                "contact_phone": "7 (812) 710-90-30",
                "contact_website": "http:\/\/www.hermitagemuseum.org\/wps\/portal\/hermitage\/what-s-on\/articles\/music_theatre\/",
                "contact_vk": "https:\/\/vk.com\/hermitagetheatre",
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317826, 59.942777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Площадь искусств 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПбГБУК \"Санкт-Петербургский государственный академический театр оперы и балета им. М.П. Мусоргского - Михайловский театр\"",
                "short_name": "Михайловский театр",
                "contact_phone": "+7 812 595-43-19",
                "contact_website": "https:\/\/mikhailovsky.ru\/",
                "contact_vk": "https:\/\/vk.com\/mikhailovsky_theatre",
                "opening_hours": "Mo-Su 10:00-21:00",
                "number_of_places": "865",
                "ref_mkrf_theaters": 11326.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.328274, 59.938067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Александровский парк, 4Т",
                "city_function": "Культура, развлечения, творчество",
                "name": "Спб ГБУК \"Театр-фестиваль \"Балтийский дом\"",
                "short_name": "Балтийский дом",
                "contact_phone": "+78122330932",
                "contact_website": "https:\/\/baltic-house.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-21:00",
                "number_of_places": "995",
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.312733, 59.955683]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Александровский парк, 4М",
                "city_function": "Культура, развлечения, творчество",
                "name": "СпбГБУК \"Театр \"Мюзик-Холл\"",
                "short_name": "Мюзик-Холл",
                "contact_phone": "+7 812 232-61-65",
                "contact_website": "http:\/\/musichallspb.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": "1708",
                "ref_mkrf_theaters": 11116.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310487, 59.954935]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рижский проспект, 3Л",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский государственный музыкальный театр «Карамболь»",
                "short_name": null,
                "contact_phone": "+7 812 570-53-16",
                "contact_website": "http:\/\/www.teatrkarambol.ru\/",
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": 14397.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.294695, 59.915251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 121",
                "city_function": "Культура, развлечения, творчество",
                "name": "Спб ГБУК \"Кукольный театр сказки\"",
                "short_name": "Кукольный театр \"Сказка\"",
                "contact_phone": "7 (812) 388‒00‒31",
                "contact_website": "www.teatrskazki.spb.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Sa 10:00-19:00 Su 10:00-17:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318419, 59.887535]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Автовская улица, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Цирк в Автово",
                "short_name": "Санкт-Петербургский государственный цирк в Автово",
                "contact_phone": "7 (812) 784‒97‒42",
                "contact_website": "https:\/\/www.circus-avtovo.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.264835, 59.865149]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Писарева, 20",
                "city_function": "Культура, развлечения, творчество",
                "name": "Концертный зал Мариинского театра",
                "short_name": null,
                "contact_phone": "+7 812 3264141",
                "contact_website": "https:\/\/www.mariinsky.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.286763, 59.925182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Большой Санкт-Петербургский государственный цирк",
                "short_name": "Цирк Чинизелли, Цирк на Фонтанке",
                "contact_phone": null,
                "contact_website": "http:\/\/circus.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/circusciniselli",
                "opening_hours": "Mo-Su 10:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.341335, 59.938685]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 65",
                "city_function": "Культура, развлечения, творчество",
                "name": "Большой драматический театр имени Г. А. Товстоногова",
                "short_name": "БДТ",
                "contact_phone": "+7 812 2441071",
                "contact_website": "https:\/\/bdt.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/bdtspb",
                "opening_hours": "Mo-Su 11:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": 9233.0,
                "wheelchair": "yes",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330852, 59.927491]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пионерская площадь, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учереждение культуры \"Театр юных зрителей им. А.А. Брянцева\"",
                "short_name": "ТЮЗ им. А.А. Брянцева",
                "contact_phone": "+7 812 712-40-68",
                "contact_website": "www.tyuz-spb.ru",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-18:00",
                "number_of_places": "830",
                "ref_mkrf_theaters": 11106.0,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.334679, 59.920018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Крестовки, 10-12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каменноостровский театр",
                "short_name": null,
                "contact_phone": "7 (812) 244‒59‒04",
                "contact_website": "bdt.spb.ru",
                "contact_vk": "https:\/\/vk.com\/bdtspb",
                "opening_hours": "Mo-Su 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": "limited",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.275399, 59.976697]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, Михайловская улица, 2\/9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Академическая филармония имени Д. Д. Шостаковича",
                "short_name": null,
                "contact_phone": "+7 812 2400180",
                "contact_website": "https:\/\/www.philharmonia.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/philharmoniaspb",
                "opening_hours": "Mo-Su 11:00-20:30",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": "yes",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.331481, 59.936061]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский 39Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театрально-концертный комплекс «Карнавал» Городского Дворца пионеров",
                "short_name": "Карнавал",
                "contact_phone": "+78123149555",
                "contact_website": "http:\/\/www.anichkov.ru\/page\/karnaval\/",
                "contact_vk": null,
                "opening_hours": "Mo-Fr 11:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339853, 59.93103]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Галерная улица, 52В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Театр-студия СПбГУ",
                "short_name": null,
                "contact_phone": "7 (812) 312-51-97",
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Sa 9:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.286529, 59.93034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мошков переулок, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Вернувшиеся",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.bezlikie.show\/",
                "contact_vk": "https:\/\/vk.com\/bezlikie.show",
                "opening_hours": "Th-Su 18:30-23:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321384, 59.943679]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 21Э",
                "city_function": "Культура, развлечения, творчество",
                "name": "Летняя эстрада",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.251342, 59.9726]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворец культуры и техники им. И.И. Газа",
                "short_name": null,
                "contact_phone": "7 (812) 783‒32‒78",
                "contact_website": "domcultury.space",
                "contact_vk": null,
                "opening_hours": "Mo-Sa 14:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.262859, 59.878382]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 49А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Новая сцена Александринского театра",
                "short_name": null,
                "contact_phone": "+7 812 4015341",
                "contact_website": "https:\/\/alexandrinsky.ru\/",
                "contact_vk": null,
                "opening_hours": "Mo-Su 11:00-20:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.337571, 59.930412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 105к1Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Детский музыкальный театр им. Виктора Резникова",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Fr 10:00-19:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.239745, 59.854933]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 44Ю",
                "city_function": "Культура, развлечения, творчество",
                "name": "Упсала-Цирк",
                "short_name": null,
                "contact_phone": "7 (921) 555-78-80",
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": "Mo-Su 9:00-21:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.402052, 59.961224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 10-12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургское государственное бюджетное учреждение культуры \"Санкт-Петербургский большой театр кукол\"",
                "short_name": "Большой театр Кукол",
                "contact_phone": "+7 812 2736672",
                "contact_website": "http:\/\/www.puppets.ru\/",
                "contact_vk": "https:\/\/vk.com\/club1136859",
                "opening_hours": "Mo-Su 10:30-20:00",
                "number_of_places": "390",
                "ref_mkrf_theaters": null,
                "wheelchair": "yes",
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.352277, 59.939158]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 122",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский Дом музыки",
                "short_name": null,
                "contact_phone": "+7 812 7026096",
                "contact_website": "http:\/\/www.spdm.ru\/",
                "contact_vk": "https:\/\/vk.com\/dommuzyki_spb",
                "opening_hours": "Mo-Fr 11:00-18:00",
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.283771, 59.928176]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Эстрадный театр",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.257397, 59.98047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шамшева улица, д.17, л.А",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПбГБУК \"Академический театр Балета под руководством Бориса Эйфмана\"",
                "short_name": "СПб ГБУК \"АТБ п\/р Б.Эйфмана\"",
                "contact_phone": "235-4897",
                "contact_website": "www.eifmanballet.spb.ru",
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": "Собственная сцена отсутствует",
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299377, 59.961223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Макаровская улица, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Драматический театр Балтийского флота",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.764123, 59.989171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Посадская улица, 26Г",
                "city_function": "Культура, развлечения, творчество",
                "name": "Сказочный остров",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.761518, 59.994841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Левашово, улица Володарского, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом культуры \"Левашовский\"",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.212562, 60.102889]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Пушкинская улица, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Интерактивный музей-театр \"Сказки Пушкина\"",
                "short_name": null,
                "contact_phone": "78129952650",
                "contact_website": "https:\/\/skazka-pushkina.ru",
                "contact_vk": "https:\/\/vk.com\/skazka_pushkina",
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.40252, 59.722391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20Л",
                "city_function": "Культура, развлечения, творчество",
                "name": "Круглый зал",
                "short_name": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.pavlovskmuseum.ru\/about\/park\/layout\/41\/1155\/",
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Театр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.443797, 59.693133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Профессора Попова, 47",
                "city_function": "Культура, развлечения, творчество",
                "name": "ЛДМ. Новая сцена",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288364, 59.971486]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, просп. Медиков, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "A2 Green Concert",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314989, 59.969118]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пироговская наб., 5\/2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Aurora",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342763, 59.956259]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, просп. Добролюбова, 18",
                "city_function": "Культура, развлечения, творчество",
                "name": "Юбилейный",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292023, 59.950287]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Арсенальная наб., 13\/1",
                "city_function": "Культура, развлечения, творчество",
                "name": "СПб ГБУ Культурно-досуговый центр Калининского района",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35748, 59.953235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный просп., 17-19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Comedy Place",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348005, 59.943547]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Виновница Концерт Холл",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326211, 59.940167]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Морзе",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239889, 59.924501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Декабристов, 54",
                "city_function": "Культура, развлечения, творчество",
                "name": "Яани Кирик",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286671, 59.923984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Труда, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Николаевский Дворец",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292964, 59.932406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Казанская ул., 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Freedom Music Hall",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321493, 59.93247]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, наб. реки Фонтанки, 34",
                "city_function": "Культура, развлечения, творчество",
                "name": "Белый зал",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345657, 59.936112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский просп., 100И",
                "city_function": "Культура, развлечения, творчество",
                "name": "Колизей",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355893, 59.932485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский просп., 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Большой концертный зал Октябрьский",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365096, 59.935703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кондратьевский просп., 44",
                "city_function": "Культура, развлечения, творчество",
                "name": "Концертный Зал Гигант Холл",
                "short_name": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "opening_hours": null,
                "number_of_places": null,
                "ref_mkrf_theaters": null,
                "wheelchair": null,
                "type": "Концертный зал",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386353, 59.971192]}
        }
    ]
}
