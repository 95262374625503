export const playgrounds = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Советский переулок, 4Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310811, 59.91494]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 37к4Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228758, 59.954218]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 37к4Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228758, 59.954218]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 37к4Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228758, 59.954218]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 37к4Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228758, 59.954218]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Дружбы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348181, 59.944174]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский, Искровский проспект, 20",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный, улица Латышских Стрелков, 5к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Смоленская улица, 18",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324914, 59.904613]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Доблести, 17к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.173539, 59.860292]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 55к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.172129, 59.861828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Варваринская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311529, 60.046351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.203219, 60.010407]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Альпийский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381616, 59.853406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ольгино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.139807, 60.00078]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Воронцовский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237526, 59.845917]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Воронцовский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237526, 59.845917]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Воронцовский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237526, 59.845917]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 53",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140203, 59.84904]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 195",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459131, 59.869363]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 195",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459131, 59.869363]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Остров Декабристов, жилой комплекс Самоцветы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264323, 59.94997]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Смоленки, 3к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262796, 59.94969]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 55к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419713, 59.843593]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 55к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419713, 59.843593]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 55к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419713, 59.843593]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 55к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419713, 59.843593]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Волна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.189547, 59.86308]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351064, 60.022306]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Фонтанчик",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426909, 59.900037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Волна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.189547, 59.86308]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Днепропетровский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359957, 59.914827]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Смоленский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319955, 59.904193]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 9В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254019, 59.952628]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Кантемировский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376936, 59.988023]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Кантемировский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376936, 59.988023]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новосергиево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.108753, 59.840257]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 241к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151369, 59.82637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 63",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202132, 60.008698]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 99",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428598, 60.033185]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 79Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334239, 59.988968]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Крымский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233933, 60.018862]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Девятого Января",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.892112]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Ольминского",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420216, 59.897785]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дрезденская улица, 8к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327447, 60.0145]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк имени Бабушкина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439216, 59.882975]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Провиантская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303211, 59.950254]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Кронверкское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314898, 59.957814]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Опочинина улица, 33",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237553, 59.934416]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Днепровский переулок, 13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284283, 59.937332]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 9-я Красноармейская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302474, 59.913699]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261251, 59.902849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 3-я линия 2-й половины",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283151, 60.021015]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 52Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32265, 60.013722]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский посад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328966, 59.8276]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Маркина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306633, 59.956111]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 38В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323809, 59.928032]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Молодёжный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469094, 59.866897]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398369, 60.027022]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398369, 60.027022]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Шувалово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298674, 60.047281]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Циолковского",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285101, 59.912806]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 61Е",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3231, 60.018308]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253938, 60.017971]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Палевский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41727, 59.893218]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Александровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317494, 59.956043]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Александровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317494, 59.956043]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Георгиевский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415527, 59.947969]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264035, 60.016631]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 46",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353561, 59.921529]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304909, 59.959742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 19В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290356, 59.962791]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304909, 59.959742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304909, 59.959742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица, 5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303929, 59.914656]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 6-я Красноармейская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312895, 59.912373]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Тихий Отдых",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289673, 59.978732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Тихий Отдых",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289673, 59.978732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 8к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288802, 60.003669]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Лазаретный переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327277, 59.920988]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305879, 60.034214]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 24к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274025, 59.887449]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Введенский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298881, 59.951267]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ярославский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319255, 60.018115]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351064, 60.022306]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351064, 60.022306]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 30Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41409, 59.928447]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Троицкий проспект, 3-5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299097, 59.916433]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 14к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369273, 60.041984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский посад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328966, 59.8276]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Резной переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281849, 59.965876]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 61к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356625, 59.839637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302869, 59.873252]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина, жилой комплекс Жемчужная Премьера",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.137535, 59.848936]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина, жилой комплекс Жемчужная Премьера",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.137535, 59.848936]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина, жилой комплекс Жемчужная Премьера",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.137535, 59.848936]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 48",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273755, 59.943927]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 10-я линия Васильевского острова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273791, 59.941425]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 11-я линия Васильевского острова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273791, 59.941425]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 46Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273441, 59.94335]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 26Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28131, 59.947825]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Олимпия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319758, 59.912851]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Лабутина, 10Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293491, 59.917944]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Батайский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321042, 59.913072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект, 17-19\/34",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32477, 59.913045]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Серпуховская улица, 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323189, 59.914737]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Строителей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466354, 59.909332]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 17к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254064, 59.854427]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 81",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296689, 59.854612]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 12",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321725, 59.932049]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 8-10",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322049, 59.932509]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351064, 60.022306]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Академика Лихачёва",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355951, 60.000083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Барочная улица, 4АЛ",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28599, 59.965421]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Певческий переулок, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327016, 59.958665]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 41к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241434, 60.030052]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Товстоногова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 59.953804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Товстоногова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 59.953804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Товстоногова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 59.953804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малоохтинский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410667, 59.932563]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Семенцы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324699, 59.914236]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 59",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.134328, 59.849071]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 57",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.136178, 59.848552]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358224, 59.938635]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Овсянниковский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376513, 59.931192]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291434, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291434, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерская улица, 6-8-10",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291991, 59.919906]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерская улица, 17",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290024, 59.919824]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Ладожский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461593, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Английский проспект, 46",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288209, 59.919879]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Витебская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279603, 59.920375]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 83-85",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284418, 59.920082]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Конная Лахта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.09959, 60.018169]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, садоводство Климовец, 2-я Конная Лахта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.110298, 60.015736]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, садоводческое некоммерческое товарищество Верхний сад Ольгино, 1-я аллея",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.109552, 60.01159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Лизы Чайкиной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302142, 59.955322]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Съезжинская улица, 16",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299195, 59.954358]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Съезжинская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300354, 59.954124]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289988, 59.919635]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Никольский переулок, 9",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304774, 59.919522]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Лабутина, 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292584, 59.918296]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291434, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 168Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284903, 59.918169]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291434, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Авиаторов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300911, 59.867913]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дивенская улица, 5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320962, 59.959868]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 27",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332882, 59.957223]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Ладожский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461593, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 31",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316218, 59.95725]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 31",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316218, 59.95725]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, переулок Нестерова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.953209]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Зверинская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298809, 59.953029]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Зверинская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298809, 59.953029]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Зверинская улица, 22",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299483, 59.953178]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 122",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.178947, 59.832575]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Графтио, 6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306633, 59.974383]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Тарасова улица, 13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413272, 59.948942]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 6к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45057, 59.915535]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Карпинского",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424097, 60.007614]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 17",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415258, 59.951497]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 31",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312733, 59.929475]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.92851]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 53к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234723, 60.030272]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 52",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245943, 60.034501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Юбилейный Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241649, 60.031841]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 51к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238577, 60.031]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 53к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236637, 60.031634]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 53к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238712, 60.032425]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Юбилейный Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241649, 60.031841]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Юбилейный Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241649, 60.031841]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309131, 59.924145]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 22",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305789, 59.923279]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Добровольцев",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.165284, 59.834266]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 4Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3064, 59.926147]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Греческий сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364889, 59.933505]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Писарева, 14",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28661, 59.926702]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Марсель",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206696, 59.999282]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314637, 60.01727]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Атмосфера",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197722, 60.004726]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 42",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339817, 59.840704]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кирилловская улица, 14",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381957, 59.935191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Моисеенко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385227, 59.937571]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 10-я Советская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381436, 59.936368]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кирилловская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382263, 59.935101]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327977, 59.821413]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Триумф Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3329, 59.823833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Моисеенко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385227, 59.937571]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 4-я Советская улица, 14",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368725, 59.93401]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 16",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36797, 59.933068]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малоохтинский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410667, 59.932563]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 35",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376837, 59.93543]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Витебская улица, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28122, 59.920898]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Таврическая улица, 45В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378849, 59.949983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенская улица, 10",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354765, 59.925033]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35481, 59.926828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Дом БДТ",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.179908, 59.86387]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Земледельческая улица, 5к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.990665]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Атланта-2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390958, 59.98295]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Летний сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33555, 59.944905]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Ладожский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461593, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 56к5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243069, 60.035544]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 56к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243868, 60.036133]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 19к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312634, 59.873166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Адмирала Коновалова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.15153, 59.849731]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дмитровский переулок, 8",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350615, 59.930025]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Разъезжая улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348405, 59.923712]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Веденеева, 8к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374492, 60.019536]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Веденеева, 8к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374492, 60.019536]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Веденеева, 8к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374492, 60.019536]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Веденеева, 8к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374492, 60.019536]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Летний",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327663, 59.820078]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пулковский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32601, 59.838367]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Боевого Братства",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450202, 59.919125]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Боевого Братства",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450202, 59.919125]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 2Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246168, 60.009148]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Изотовский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225004, 60.029292]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 5к1Е",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 60.04064]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 5к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321114, 60.04002]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 5к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320719, 60.040717]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 5к1Е",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 60.04064]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гребная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268751, 59.971825]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Стародеревенская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244317, 59.996762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Волховский переулок, 6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290176, 59.944859]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Рио",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439377, 59.915616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 52",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284337, 59.94654]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Триумф Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3329, 59.823833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Вива",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333251, 59.831933]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Интернационалистов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401666, 59.858711]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Воронежский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34315, 59.910974]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 132",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413901, 59.839221]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 5-я линия Васильевского острова, 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278372, 59.945797]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Белоостровская улица, 25",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.987551]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московское шоссе, 30к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34306, 59.834361]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 56",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272381, 59.945116]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шкиперский проток, 20",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.219739, 59.936544]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Фортуна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194371, 59.997068]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калужский переулок, 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380107, 59.945576]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265059, 59.933866]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Басков переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358493, 59.939514]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Дворцовый",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327546, 59.941047]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443366, 59.885133]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Крымский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233933, 60.018862]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370611, 59.932162]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Саблинская улица, 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308295, 59.956075]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петрозаводская улица, 13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288487, 59.965453]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петрозаводская улица, 13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288487, 59.965453]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Чкаловское, жилой комплекс Классика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282011, 59.964633]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.938779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 9",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325309, 60.0437]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парадная улица, 3к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367261, 59.942079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Цимбалина, 44",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42275, 59.8832]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Цимбалина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420378, 59.883729]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33193, 59.828125]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Есенина, 26к1Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337284, 60.048764]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад имени 30-летия Октября",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403858, 59.912066]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Калина-парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372372, 59.986979]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская, жилой комплекс Царская Столица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370162, 59.921132]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская, жилой комплекс Царская Столица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370162, 59.921132]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Кантемировский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376936, 59.988023]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 65к1У",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329352, 59.771513]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302789, 59.787284]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дибуновская улица, 37",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269147, 59.985512]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пять Звёзд",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404424, 59.96257]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Новосельковская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286134, 60.030987]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308196, 59.971154]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, исторический район Волково",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228255, 60.031562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Граффити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.250264, 60.028245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Юбилейный Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241649, 60.031841]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400409, 59.843909]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Море",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.141676, 59.853532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Море",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.141676, 59.853532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Василеостровец",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257037, 59.934389]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новоорловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 60.057887]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новоорловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 60.057887]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская, жилой комплекс Царская Столица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370162, 59.921132]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Граффити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.250264, 60.028245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Олимпия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319758, 59.912851]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Вологодско-Ямская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274546, 59.86762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Вологодско-Ямская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274546, 59.86762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Вологодско-Ямская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274546, 59.86762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Вологодско-Ямская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274546, 59.86762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава, жилой комплекс Московские Ворота",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315114, 59.886966]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новоорловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 60.057887]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава, жилой комплекс Московские Ворота",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315114, 59.886966]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Мира",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321321, 59.9608]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Мира, 16",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319138, 59.960512]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Черняховского, 11Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357397, 59.916189]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Крапивный переулок, 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340078, 59.970105]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304909, 59.959742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320764, 59.962228]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 2\/27",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338227, 59.935403]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новоорловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 60.057887]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Зималето",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438192, 59.938464]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Зималето",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438192, 59.938464]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 9к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43989, 59.937175]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Казанский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312589, 59.931332]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 32",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376612, 59.936129]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Заячий переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379253, 59.939654]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 6-я Советская улица, 29Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374456, 59.933947]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фурштатская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358134, 59.94513]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пионерский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418016, 59.982784]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк имени 300-летия Санкт-Петербурга",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199348, 59.982995]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Героев, 27к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.159067, 59.861137]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Героев, 25к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.16072, 59.861968]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Героев, 23",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.162068, 59.863012]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Ап Комендантский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254235, 60.026662]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Триумф Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3329, 59.823833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Триумф Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3329, 59.823833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Орловский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269129, 60.056113]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Садовая улица, 62",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302384, 59.921155]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Типанова, 25к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347453, 59.85388]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Советский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30993, 59.91383]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Серебристый бульвар, 16к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279307, 60.002611]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Шелгунова, 7к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457479, 59.86762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Шелгунова, 7к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457479, 59.86762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новоорловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 60.057887]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Некрасовский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367512, 59.937387]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, садоводство Электроаппарат, 3-я аллея, 11А",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266164, 59.831499]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 29",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317009, 59.957237]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38714, 60.039715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.458125, 59.861702]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский проспект, 73к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316686, 59.905006]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Квартет",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370459, 59.82983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Лидии Клемент",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325803, 59.915255]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Подрезова улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303498, 59.964561]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Балканы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402367, 59.834501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Бугский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28617, 59.938036]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Семёновский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323297, 59.913045]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Семёновский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323297, 59.913045]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 90Ф",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330286, 59.92419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468681, 59.944134]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, аллея Академика Лихачёва",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347749, 60.002791]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кронверкская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311763, 59.959985]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Ленина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303112, 59.962728]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Подковырова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303696, 59.963845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Александровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317494, 59.956043]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полозова улица, 28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299222, 59.964732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Бармалеева улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 59.964543]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315374, 59.95703]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Зверинская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298809, 59.953029]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Щербаков переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34271, 59.928514]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 14Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338649, 59.928271]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Лизы Чайкиной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302142, 59.955322]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Щербаков переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34271, 59.928514]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 24",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340805, 59.926941]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Серебряный Пруд",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346842, 60.003696]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Серебряный Пруд",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346842, 60.003696]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Съезжинская улица, 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294596, 59.953989]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ручьи",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 60.004694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Стрельнинская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299357, 59.959661]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Бармалеева улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 59.964543]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 46",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343375, 59.931828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Лахтинская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30039, 59.962679]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296087, 59.957642]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Василеостровец",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257037, 59.934389]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Василеостровец",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257037, 59.934389]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 78",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254971, 59.930674]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Таврический сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373136, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.232855, 59.936485]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Днепровский переулок, 13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284283, 59.937332]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 20\/2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283475, 59.938495]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 35к6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356643, 60.009134]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ординарная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307649, 59.965525]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ординарная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307649, 59.965525]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 92В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309553, 59.96548]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Профессора Попова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310811, 59.967763]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Мира, 7Г",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314727, 59.959282]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Мира",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321321, 59.9608]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, переулок Джамбула, 8",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334454, 59.926039]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 42",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.498873, 59.910961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302465, 59.91443]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица, 18",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299851, 59.914213]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 9-я Красноармейская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302474, 59.913699]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 10-я Красноармейская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299878, 59.912932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312886, 59.911633]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Рязанский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350983, 59.918485]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 11-я Красноармейская улица, 9",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302825, 59.912513]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 3-я Красноармейская улица, 8В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314736, 59.914231]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 12-я Красноармейская улица, 29",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296303, 59.911602]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Манчестерская улица, 10к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333933, 60.01217]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гданьская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3276, 60.015831]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 13-я Красноармейская улица, 20",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300785, 59.914949]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица, 15-17",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301244, 59.914565]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Берёзовый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317521, 60.022683]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кубанская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320153, 60.012881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Сергея Марго",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 60.026303]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Берёзовый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317521, 60.022683]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ярославский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319255, 60.018115]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Берёзовый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317521, 60.022683]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261251, 59.902849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Рижский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280016, 59.913392]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дрезденская улица, 11к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328984, 60.013245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Озерной переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362733, 59.937973]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, площадь Льва Мациевича",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285721, 60.013411]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398369, 60.027022]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Димитрова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377708, 59.847132]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 60.045655]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Мартыновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287733, 60.029705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.160711, 59.83281]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.160711, 59.83281]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 15-я линия Васильевского острова, 70",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263173, 59.944355]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дрезденская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332226, 60.013838]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дрезденская улица, 22к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333448, 60.014778]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дрезденская улица, 22к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333448, 60.014778]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 8",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293329, 59.923811]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 11",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296015, 59.923351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 51",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293868, 59.922562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304998, 59.927026]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Мариуса Петипа",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293302, 59.926526]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Новознаменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140409, 59.846084]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Лодейнопольская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290122, 59.964566]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Мартыновский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.022845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Новознаменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140409, 59.846084]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299573, 60.008783]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468681, 59.944134]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 93",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302789, 59.927324]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Константина Заслонова, 9\/4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350597, 59.92042]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Социалистическая улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343024, 59.922327]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Раисы Штрейс",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3246, 59.827076]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Раисы Штрейс",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3246, 59.827076]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294857, 59.931837]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Печатника Григорьева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349519, 59.918828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304998, 59.927026]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304998, 59.927026]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233744, 60.044307]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291434, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Никольская площадь",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30013, 59.922634]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29483, 59.922562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 2-й Адмиралтейский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305088, 59.934885]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291434, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Мастерская улица, 12",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289997, 59.922179]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гданьская улица, 16к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329433, 60.016847]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 22к8",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318177, 59.997757]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304998, 59.927026]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Разночинная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289152, 59.960107]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Финский переулок, 4А",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353813, 59.957043]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Яблоневый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363649, 59.863572]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Яблоневый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363649, 59.863572]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кропоткина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30878, 59.958769]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 57",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282963, 59.923757]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Английский проспект, 17-19Г",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283107, 59.924037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282918, 59.922264]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, переулок Макаренко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29818, 59.918986]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Алексеевский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284732, 59.927437]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Поклонная гора",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323773, 60.028654]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское, жилой комплекс Сергей Есенин",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330879, 60.0294]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Яблоневый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363649, 59.863572]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 8М",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28935, 59.911656]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Курляндская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279073, 59.911696]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк имени Бабушкина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439216, 59.882975]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Сан-Галли",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358233, 59.922607]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Галины Старовойтовой",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375453, 59.938058]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Пархоменко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343195, 59.999111]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Болотная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353463, 60.000483]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Мациевича",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287113, 59.997707]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, аллея Академика Глушко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373621, 60.023398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Брестский бульвар",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197883, 59.85454]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.925083]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310119, 59.927536]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 25",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280421, 59.912044]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 23Ж",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281031, 59.912377]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 8Д",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278067, 59.911006]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299573, 60.008783]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дивенский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318698, 59.959147]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Шувалово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298674, 60.047281]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Доминанта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349573, 59.854644]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Доминанта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349573, 59.854644]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Доминанта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349573, 59.854644]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Доминанта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349573, 59.854644]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 71к2Д",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258699, 59.984413]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ладожский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.460982, 59.941998]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 60.007636]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Орджоникидзе, 28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332182, 59.845383]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Косая дорожка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.149455, 59.995966]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 90к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3905, 60.032115]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кемская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261008, 59.973406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 60.003759]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Хвойная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140571, 60.000339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк имени 300-летия Санкт-Петербурга",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199348, 59.982995]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк имени 300-летия Санкт-Петербурга",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199348, 59.982995]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Подольская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325166, 59.91507]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Можайская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327573, 59.915973]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237625, 59.901423]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.155195, 59.791626]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.155195, 59.791626]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.155195, 59.791626]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 58И",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275713, 59.912824]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Казанский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312589, 59.931332]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Земский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290859, 60.024437]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324564, 59.912833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Сикейроса, 10к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329702, 60.035571]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Чапаева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330547, 59.962129]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Ладожский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461593, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное, жилой комплекс Монплезир",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243464, 59.852768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Мира, 38",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329433, 59.962764]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 1-я Берёзовая аллея",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292701, 59.978088]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, исторический район Колтовская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277321, 59.963273]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ботанический сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325336, 59.969866]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Таврический сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373136, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Таврический сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373136, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Предпортовая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302079, 59.836717]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Нева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410586, 59.960607]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Нева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410586, 59.960607]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное, жилой комплекс Монплезир",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243464, 59.852768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 23Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295198, 59.919076]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Литераторов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311871, 59.969137]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ржевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510039, 59.980182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Рентгена, 23Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328642, 59.965259]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Турбинная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269731, 59.893358]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Турбинная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269731, 59.893358]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Турбинная улица, 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267467, 59.897194]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Промышленная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263901, 59.89742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Севастопольская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266542, 59.892915]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полюстровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425013, 59.964804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полюстровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425013, 59.964804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полюстровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425013, 59.964804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полюстровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425013, 59.964804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Правды, 2\/13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345036, 59.924582]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 60Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409113, 59.954502]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Северный проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378274, 60.029827]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, аллея Академика Глушко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373621, 60.023398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, аллея Академика Глушко",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373621, 60.023398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Веденеева, 12",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376028, 60.019302]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Симбирский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360038, 59.955196]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Туристская улица, 28к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205815, 60.004271]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 62к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472301, 59.881123]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 56У",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350974, 59.933988]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411566, 59.944828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 45к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2041, 60.003682]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Лётчика Пилютова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140265, 59.83541]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Старая улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152123, 59.991142]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, Андреевская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368815, 59.900304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Волково",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365743, 59.899433]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Волково",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365743, 59.899433]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Введенский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298881, 59.951267]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Благовещенский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273288, 59.946162]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 15-я линия Васильевского острова, 50",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266083, 59.941831]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 15-я линия Васильевского острова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267808, 59.940654]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Любашинский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397103, 59.969051]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Пархоменко, 20",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337643, 60.000582]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Моховая улица, 30Г",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346671, 59.941231]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Девятого Января",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.892112]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Девятого Января",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.892112]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кирилловская улица, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379873, 59.933262]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Марата, 18Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355089, 59.92823]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Опочининский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239215, 59.928356]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Опочининский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239215, 59.928356]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Опочининский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239215, 59.928356]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Гуси-лебеди",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214484, 60.003538]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Фортуна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194371, 59.997068]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Мичуринская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328579, 59.955309]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Брестский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466713, 59.889038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470405, 59.855317]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гусева улица, 6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413057, 59.950114]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Серебристый бульвар, 12к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280735, 60.000088]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром, жилой комплекс Зенит",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290697, 60.010618]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Щербакова, 14к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293329, 60.02762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Мартыновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287733, 60.029705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Мартыновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287733, 60.029705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 3-я линия 2-й половины",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283151, 60.021015]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Жореса Алфёрова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429298, 60.002256]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Жореса Алфёрова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429298, 60.002256]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261251, 59.902849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24756, 59.971591]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ольгино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.139807, 60.00078]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ольховая улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259921, 60.016033]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малая Десятинная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297372, 60.036668]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 1-я Утиная улица, 28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298701, 60.033985]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 1-я Утиная улица, 28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298701, 60.033985]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Северный проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378274, 60.029827]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Саратовская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343249, 59.959877]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гжатская улица, 5Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38008, 59.9984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта, жилой комплекс Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423199, 59.933154]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297713, 60.00321]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Спортивная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270925, 59.968223]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Литераторов, 15Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312634, 59.96961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27733, 59.956079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146257, 59.853401]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146257, 59.853401]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полевая дорога",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.163397, 59.989526]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 5-я Жерновская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486108, 59.960255]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Генерала Медведева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23943, 60.023686]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Бадаева-Ворошилова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44271, 59.918814]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Бадаева-Ворошилова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44271, 59.918814]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шамшева улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301477, 59.960237]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Александровское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466974, 59.864286]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кирилловская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382263, 59.935101]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Данилевский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349276, 60.005054]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Константина Заслонова, 9\/4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350597, 59.92042]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Бенуа",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371025, 60.018443]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новая Скандинавия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290284, 60.060649]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новая Скандинавия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290284, 60.060649]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, Авиационная улица, 38",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332676, 59.854133]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ораниенбаумская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297462, 59.960881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ораниенбаумский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294048, 59.961746]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 21к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280232, 60.012228]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Ольги Берггольц, 27",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413183, 59.891308]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295324, 59.96357]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Железнодорожный переулок, 12",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324537, 60.000911]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 23А",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369866, 59.937499]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.160711, 59.83281]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Вяземский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298198, 59.97396]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312356, 59.978079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Моховая улица, 37",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344839, 59.940262]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новосергиево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.108753, 59.840257]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 57к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.175129, 59.861159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 7-я Советская улица, 9\/20",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371384, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 63к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357388, 59.838602]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский проспект, 136",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368195, 59.930025]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский проспект, 146",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370854, 59.929078]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 9-я Советская улица, 5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37195, 59.938189]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Архиерейская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432622, 59.907437]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петровский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26205, 59.95985]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Ладожский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461593, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Рылеева, 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356014, 59.942291]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 73",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357173, 59.925728]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Боевого Братства",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450202, 59.919125]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Геометрия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.19791, 59.998121]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 28-30-32Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419713, 59.931233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Седова, 46к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422202, 59.890035]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 15к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.145278, 59.840166]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305879, 60.034214]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Ладожский Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461593, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Латышских Стрелков, 9к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.465572, 59.928005]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470405, 59.855317]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кубинская улица, 24",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296617, 59.863057]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 32В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344012, 59.943598]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малая Десятинная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297372, 60.036668]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463165, 59.923798]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463165, 59.923798]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463165, 59.923798]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Строгановский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.983635]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Даниила Гранина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34218, 59.944571]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гагаринская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342701, 59.945824]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Фортуна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194371, 59.997068]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308134, 59.874187]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Утренняя Звезда",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370827, 59.984499]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Георгиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289116, 60.032425]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 26-28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334463, 59.961034]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 132к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328076, 60.046737]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 31к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319398, 60.060334]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380664, 60.043804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Придорожная аллея",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361179, 60.055421]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Придорожная аллея",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361179, 60.055421]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Придорожная аллея",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361179, 60.055421]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Сиреневый бульвар",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357433, 60.052932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Империал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321339, 59.901599]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 35к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302393, 60.066091]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Московский Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315401, 59.879566]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.155195, 59.791626]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.155195, 59.791626]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.155195, 59.791626]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Горелово, жилой комплекс Дудергофская Линия 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152878, 59.793659]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Мартыновская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258358, 60.022715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Вива",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333251, 59.831933]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Ивана Фомина",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346635, 60.052205]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Генерала Медведева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23943, 60.023686]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 14к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312742, 60.012827]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Шведская Крона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309876, 60.013065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ручьи",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 60.004694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ручьи",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 60.004694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ручьи",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 60.004694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ручьи",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 60.004694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Ручьи",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 60.004694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Любашинский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397103, 59.969051]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Юбилейный Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241649, 60.031841]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Старо-Муринская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389476, 59.998004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 62",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338461, 59.975432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лесной",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343428, 60.000803]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Бунинский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332379, 59.92552]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Дом с Курантами",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216461, 59.996151]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Тамбасова, 8к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.159912, 59.840447]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305879, 60.034214]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Новосергиево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.108753, 59.840257]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Юсуповский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313443, 59.922986]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 117",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312895, 59.92129]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 2-я Комсомольская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143652, 59.836219]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 36",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325031, 59.912427]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Круговая улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.1381, 59.994936]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский 2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32778, 59.819069]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Линкор",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154055, 59.853338]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Линкор",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154055, 59.853338]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Линкор",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154055, 59.853338]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Кронверкское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314898, 59.957814]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское, жилой комплекс Перемена",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306499, 59.842422]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, переулок Крылова, 5Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334266, 59.93213]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кронверкская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311763, 59.959985]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271213, 59.850097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полежаевский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.195691, 59.842549]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Вива",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333251, 59.831933]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349285, 59.823448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Триумф Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3329, 59.823833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Сампсониевский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346258, 59.968615]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Европа Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315293, 59.975477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Европа Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315293, 59.975477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский посад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328966, 59.8276]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Академика Сахарова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413326, 59.978111]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Европа Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315293, 59.975477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Европа Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315293, 59.975477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Введенский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298881, 59.951267]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 50АЕ",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342638, 59.930543]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Сканди Клуб",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319389, 59.974995]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Дмитровский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350184, 59.929876]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Доблести, 28",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.176881, 59.850997]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475337, 59.898164]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Жерновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.458539, 59.952939]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Лётчика Тихомирова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143679, 59.854409]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Море",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.141676, 59.853532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470405, 59.855317]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Театра Юных Зрителей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335056, 59.92065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Театра Юных Зрителей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335056, 59.92065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Среднерогатская улица, 9",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333071, 59.82509]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 58к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.451532, 59.868672]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Андрея Петрова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312958, 59.963296]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32645, 59.821815]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Смольнинское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381634, 59.941479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 27",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355367, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ивановский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427726, 59.876341]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Пулковский посад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328966, 59.8276]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321698, 59.836337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пушкарский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302277, 59.957003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское, жилой комплекс Клевер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308268, 59.848213]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304909, 59.959742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское, жилой комплекс Лиственный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336484, 60.027984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское, жилой комплекс Лиственный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336484, 60.027984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское, жилой комплекс Лиственный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336484, 60.027984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское, жилой комплекс Лиственный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336484, 60.027984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской, жилой комплекс Я – Романтик",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202591, 59.938365]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 18",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405071, 60.044761]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шкиперский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242781, 59.933239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Новая Скандинавия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290284, 60.060649]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 147",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443447, 59.884144]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 33",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30322, 59.971375]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Песочная набережная, 14Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29606, 59.972965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 37к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296653, 59.972505]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Александровское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466974, 59.864286]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ново-Александровская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461305, 59.865456]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Куракина Дача",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45234, 59.8722]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Куракина Дача",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45234, 59.8722]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Куракина Дача",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45234, 59.8722]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Куракина Дача",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45234, 59.8722]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Заневский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412464, 59.919446]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пионерский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324357, 59.992456]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312347, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Тихомировская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275722, 59.890568]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский проспект, 183-185",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319839, 59.855331]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский проспект, 183-185Бк10",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315087, 59.857043]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Легенда на Оптиков 34",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216083, 59.999422]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Легенда на Оптиков 34",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216083, 59.999422]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Полтавский проезд, 2В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366353, 59.92515]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 22к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395656, 59.851182]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс Твин Пикс",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402681, 59.850821]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Новая Голландия",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290401, 59.929439]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Венгерка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.47187, 59.861896]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Лыжный переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199078, 59.999705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Александровский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30693, 59.936309]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Козлова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.223108, 59.834659]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малая Каштановая аллея, 8",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394677, 59.845464]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Малоохтинский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410667, 59.932563]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петровский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27733, 59.956079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Опочинина улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238317, 59.932256]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22071, 60.034376]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Калина-парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372372, 59.986979]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 23",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256471, 59.951808]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 23",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256471, 59.951808]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Одоевского",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249474, 59.950443]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 21к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221284, 59.944634]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 32к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216784, 59.953462]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 34",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217736, 59.954043]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Капитанская улица, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214736, 59.954773]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Капитанская улица, 4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214736, 59.954773]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Остров Декабристов, жилой комплекс Морской Фасад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214367, 59.953822]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211214, 59.952718]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Яблоневый сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363649, 59.863572]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Квартет",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370459, 59.82983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Граффити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.250264, 60.028245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Квартет",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370459, 59.82983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Рижский проспект",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280016, 59.913392]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Екатерининский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435596, 59.977989]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Екатерининский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435596, 59.977989]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Екатерининский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435596, 59.977989]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Екатерининский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435596, 59.977989]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Екатерининский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435596, 59.977989]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 82к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.462374, 59.862935]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Сириус",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337535, 59.826099]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Олеко Дундича, 36к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42478, 59.835107]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34978, 59.948131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Усть-Славянка, жилой комплекс Живи в Рыбацком",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.53689, 59.829133]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Георгиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289116, 60.032425]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Георгиевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289116, 60.032425]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 46\/1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316497, 59.929114]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 34",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357604, 59.945427]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309939, 59.972586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ковенский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359921, 59.937301]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Аврора",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.452421, 59.911403]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Аврора",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.452421, 59.911403]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Аврора",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.452421, 59.911403]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шкиперский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242781, 59.933239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Шкиперский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242781, 59.933239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Времена Года",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313604, 59.904622]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Тапиола",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312949, 59.907252]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Времена Года",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313604, 59.904622]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Времена Года",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313604, 59.904622]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Красуцкого, 5",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312634, 59.903142]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Красуцкого, 3М",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315122, 59.903061]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 19",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310847, 59.911796]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Егорова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314036, 59.912594]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 23",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317503, 59.931283]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 22-24",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282738, 59.911687]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 52к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237607, 59.98359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Малые Гаванцы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.220961, 59.939262]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сквер Малые Гаванцы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.220961, 59.939262]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Золотое Время",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264359, 59.895497]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290473, 60.019037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калужский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381158, 59.945702]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317773, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 5Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317835, 59.927973]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Турку",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386107, 59.868035]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Фарфоровский Пост",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407532, 59.878057]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Фарфоровский Пост",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407532, 59.878057]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Адмирал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196689, 59.858521]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Морская набережная, 35к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2215, 59.959692]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 30",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251881, 59.952223]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Железноводская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248036, 59.952128]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Витебская Сортировочная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350723, 59.887133]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Киевская улица, 3к2В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320935, 59.901427]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Времена Года",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313604, 59.904622]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Времена Года",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313604, 59.904622]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36046, 59.886848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407757, 59.857762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 34",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280097, 59.940993]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский проспект, 60И",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339296, 59.934565]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325875, 59.932216]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Садовая улица, 49",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312517, 59.92479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394291, 59.90432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394291, 59.90432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394291, 59.90432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Усть-Славянка, жилой комплекс Живи в Рыбацком",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.53689, 59.829133]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Ультра Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.227007, 60.035508]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Медный Всадник",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197704, 59.999341]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Медный Всадник",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197704, 59.999341]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Медный Всадник",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197704, 59.999341]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Фортуна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194371, 59.997068]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ № 65, жилой комплекс Фортуна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194371, 59.997068]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Тапиола",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312949, 59.907252]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Циолковского",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285101, 59.912806]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325875, 59.932216]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Сканди Клуб",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319389, 59.974995]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 49",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323459, 59.927261]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров, жилой комплекс Сканди Клуб",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319389, 59.974995]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313874, 59.983369]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450023, 59.919689]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Садовая улица, 60",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304433, 59.921574]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Шведская Крона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309876, 60.013065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Шведская Крона",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309876, 60.013065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 60.01333]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404801, 59.993532]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Квартет",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370459, 59.82983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 102к4",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365383, 59.831069]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский проспект, 107Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368249, 59.928654]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской, жилой комплекс Я – Романтик",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202591, 59.938365]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской, жилой комплекс Я – Романтик",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202591, 59.938365]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской, жилой комплекс Я – Романтик",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202591, 59.938365]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Вяземский переулок, 5-7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30198, 59.972366]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398369, 60.027022]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398369, 60.027022]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Зималето",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438192, 59.938464]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 12-я линия Васильевского острова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270871, 59.941038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Тверская улица, 14Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384131, 59.947383]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 84к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464467, 59.862339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 84к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464467, 59.862339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 14",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320683, 59.940947]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Кирилл и Дарья",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370665, 59.985998]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422839, 59.894748]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423962, 59.883417]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Оккервиль",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463165, 59.923798]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 88Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323558, 59.908728]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 92В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299636, 59.929696]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 45",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27884, 59.946243]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402079, 60.038191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274635, 59.945562]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334284, 59.873338]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334284, 59.873338]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334284, 59.873338]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Расстанная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34854, 59.906923]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Расстанный сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357262, 59.90543]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Расстанная улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34854, 59.906923]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239224, 59.954178]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Театра Юных Зрителей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335056, 59.92065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Театра Юных Зрителей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335056, 59.92065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, жилой комплекс Цивилизация",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.452691, 59.899248]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402331, 59.973131]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466462, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Чернова Дача",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.460937, 59.87481]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25313, 59.895479]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, сад Театра Юных Зрителей",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335056, 59.92065]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Земская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.174401, 59.810256]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Горелово, жилой комплекс Дудергофская Линия 3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152878, 59.793659]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 35Д",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289691, 59.926345]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 35Д",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289691, 59.926345]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 22",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308565, 59.92865]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167404, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241029, 60.036245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241029, 60.036245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241029, 60.036245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное, жилой комплекс 4Ю",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337724, 59.824208]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Щербаков переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34271, 59.928514]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266874, 59.868586]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459509, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215742, 60.038802]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Воронцовский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237526, 59.845917]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410003, 59.854106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410003, 59.854106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410003, 59.854106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Торики",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.111573, 59.782289]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Тихий Отдых",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289673, 59.978732]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 59.815229]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290284, 59.93112]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325875, 59.932216]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 11",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326477, 59.928009]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272866, 59.935931]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24756, 59.971591]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241029, 60.036245]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25392, 60.038106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Интернационалистов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401666, 59.858711]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24756, 59.971591]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Симбирский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360038, 59.955196]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Ивановский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427726, 59.876341]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, жилой комплекс Новая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456167, 60.032695]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Юнтоловский проспект, 51к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143463, 60.032605]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143841, 60.030816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Новознаменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140409, 59.846084]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 40к6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148961, 59.830373]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, 2-я Комсомольская улица",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143652, 59.836219]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.160711, 59.83281]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Триумф Парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3329, 59.823833]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс На Королёва",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236673, 60.034627]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Цветной Город",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480646, 60.008518]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 38В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323809, 59.928032]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43874, 59.874779]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472768, 59.945432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Стереос",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.51056, 59.979304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Стереос",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.51056, 59.979304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Приневский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510632, 59.866861]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424106, 59.926287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парфёновская улица, 9к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311511, 59.900953]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парфёновская улица, 9к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311511, 59.900953]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354082, 59.925038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475813, 59.898878]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Георгиевский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415527, 59.947969]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426037, 59.947901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.131444, 60.023924]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Юнтолово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.131444, 60.023924]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439827, 59.954845]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Выборгский сад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345827, 59.962958]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45543, 59.881516]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 60к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225614, 60.035351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 60к3",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226261, 60.034542]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Ультра Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.227007, 60.035508]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 60к2",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224617, 60.034681]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Семёновский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323297, 59.913045]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470405, 59.855317]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский, жилой комплекс Маршал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387491, 59.975869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Днепровский переулок, 13",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284283, 59.937332]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс На Королёва",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236673, 60.034627]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Синема",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38767, 59.985701]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Синема",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38767, 59.985701]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Калина-парк",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372372, 59.986979]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Прилукский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348315, 59.909996]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Прилукский сквер",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348315, 59.909996]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новая Каменка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234139, 60.03394]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Калейдоскоп",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378633, 59.985512]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410003, 59.854106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.154567, 59.835247]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 10",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258879, 59.952452]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Остров Декабристов, жилой комплекс Самоцветы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264323, 59.94997]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Уральская улица, 6",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264997, 59.95129]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.891652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Парфёновская улица, 9к1",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311511, 59.900953]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.901098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236161, 59.953849]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 55В",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323252, 59.925953]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, площадь Тургенева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289161, 59.918404]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, площадь Тургенева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289161, 59.918404]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, площадь Тургенева",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289161, 59.918404]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410003, 59.854106]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Мартыновка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287733, 60.029705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 102Б",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364422, 59.830694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром, жилой комплекс Приморский Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305834, 59.99899]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром, жилой комплекс Приморский Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305834, 59.99899]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Кантемировский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376936, 59.988023]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан, жилой комплекс Граф Орлов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.856072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан, жилой комплекс Граф Орлов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.856072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан, жилой комплекс Граф Орлов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.856072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан, жилой комплекс Граф Орлов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.856072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан, жилой комплекс Граф Орлов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.856072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан, жилой комплекс Граф Орлов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.856072]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, исторический район Семенцы",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324699, 59.914236]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475337, 59.898164]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Адмирала Коновалова",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.15153, 59.849731]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 60",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242557, 59.957444]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Южная Акватория",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.16107, 59.868089]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Южная Акватория",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.16107, 59.868089]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17689, 59.834406]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418007, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 9-11",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291569, 59.905339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 9-11",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291569, 59.905339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Грин Сити",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453724, 59.861819]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Александровское",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466974, 59.864286]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава, жилой комплекс Московские Ворота",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315114, 59.886966]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава, жилой комплекс Московские Ворота",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315114, 59.886966]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава, жилой комплекс Московские Ворота",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315114, 59.886966]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Московский Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315401, 59.879566]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, жилой комплекс Московский Квартал",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315401, 59.879566]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453526, 59.867584]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440734, 59.865881]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Белевский переулок",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438434, 59.86804]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241164, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205537, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург, парк Интернационалистов",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401666, 59.858711]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.774869, 59.988286]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.770557, 59.988052]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773509, 59.989477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.770509, 59.990868]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773581, 59.989712]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.511688, 60.224114]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.965551, 60.091266]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773102, 59.91112]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.037643, 59.853208]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.999429, 60.1506]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.872768, 60.16559]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.003494, 60.003047]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.931948, 60.142933]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.9808, 60.079124]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.871464, 60.165429]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.95968, 60.098048]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.946718, 60.102279]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.957754, 60.090983]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.948684, 60.092529]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.955995, 60.093337]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.946913, 60.108688]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.000511, 60.020279]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.704422, 60.194688]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.707451, 60.198842]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.69994, 60.191438]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.873945, 60.169978]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.975901, 60.08195]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.947185, 60.090205]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.967554, 60.089153]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.01262, 59.839907]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.035731, 59.852082]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.81747, 60.194359]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.960093, 60.092405]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.92333, 59.879402]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.93748, 60.090027]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.000926, 60.137045]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.998611, 60.138828]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.961498, 60.081862]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.963643, 60.079312]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.96499, 60.078632]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.966353, 60.07776]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.803338, 60.1883]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.803806, 60.188145]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.968221, 60.074809]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.967928, 60.075418]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.968357, 60.073703]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.970649, 60.107725]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.710683, 60.196999]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.948978, 60.092024]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.704493, 60.198594]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.706753, 60.198795]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.713239, 60.19603]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.713912, 60.196154]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.687225, 60.197315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.805333, 60.187174]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.804783, 60.187405]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.804251, 60.187691]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.803201, 60.188124]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.710668, 60.201102]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.618385, 60.199037]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.76911, 60.180965]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.86176, 60.163672]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.7534, 59.991161]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.931503, 60.143155]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.939262, 60.127514]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.745367, 59.994682]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.967264, 60.077448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.967551, 60.076875]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.966616, 60.078899]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.80774, 60.185497]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.645351, 60.213241]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.939166, 60.127686]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.81125, 59.908362]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.81745, 59.900821]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.995068, 60.136643]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [29.976299, 60.095153]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Детская площадка ",
                "city_function": "Жилье",
                "address": "Россия, Санкт-Петербург",
                "name": null
            },
            "geometry": {"type": "Point", "coordinates": [30.081072, 59.825996]}
        }
    ]
}
