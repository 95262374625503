//https://t.me/elemnt3 Y:2021
import React from "react";
import {Button, Col, Collapse, Row} from 'antd';
import MapSurvey from "./MapSurvey";
import service_description from "../../assets/download/Описание_сервиса_по_выбору_МО_досуга.docx";

const {Panel} = Collapse;

function About() {
    function callback(event) {
        const id  = event.target.offsetParent.id;
        fetch(`https://basket-api.onti.actcognitive.org/nirma/downloader?dataTime=${Math.floor(Date.now() / 1000)}&id=${id}`)
            .then((response) => response.json())
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    const service_description_text = 'Описание сервиса по выбору МО досуга'

    return (
        <div id='_about' className='block aboutBlock'>
            <div className='container-fluid'>
                <div className='titleHolder'>
                    <h2>О Сервисе</h2>
                    <p>Оценка привлекательности муниципальных образований для различных видов досуга</p>
                </div>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <p style={{textIndent: '1em', textAlign: "justify"}}>
                            Задача сервиса показать пользователю лучшие муниципальные образования для проведения досуга,
                            исходя из предпочтений пользователя.
                            Сервис не только показывает рейтинг 10 лучших муниципальных образований, но также выводит
                            объекты досуга и информацию о них.
                        </p>
                        <p style={{textIndent: '1em', textAlign: "justify"}}>
                            Также в оценке учитываются объекты с негативным влиянием на окружающую среду.
                        </p>
                        <p style={{textIndent: '1em', textAlign: "justify"}}>
                            Сервис создан в рамках ИЦКР и ИДУ ИТМО на базе платформы цифровой урбанистики.
                        </p>
                        <h3 style={{fontWeight: 'bold'}}>Описание алгоритмов</h3>
                        <p style={{textIndent: '1em', textAlign: "justify", textDecoration: 'underline'}}>
                            Площадные сервисы
                        </p>
                        <p style={{textIndent: '1em', textAlign: "justify"}}>
                            Оценка привлекательности данного МО рассчитывается по формуле:
                        </p>
                        <ul>
                            <li>
                                Вычисляется эффективная площадь МО как результат исключения территорий, которые
                                находятся под воздействием «промышленного предприятия» и/или «свалки».
                            </li>
                            <li>
                                Складываются площади всех сервисов, пересекающих эффективную геометрию МО.
                            </li>
                            <li>
                                Далее вычисляется, какую часть от всей площади МО занимает площадь сервисов.
                            </li>
                            <li>
                                Вычисляются проценты для всех МО.
                            </li>
                            <li>
                                Составляется рейтинг МО по убыванию процента покрытия сервисами.
                            </li>
                            <li>
                                На карту выводятся и подкрашиваются 10 лучших МО.
                            </li>
                        </ul>
                        <p style={{textIndent: '1em', textAlign: "justify", textDecoration: 'underline'}}>
                            Точечные сервисы
                        </p>
                        <p style={{textIndent: '1em', textAlign: "justify"}}>
                            Оценка привлекательности данного МО рассчитывается по формуле:
                        </p>
                        <ul>
                            <li>
                                Вычисляется эффективная площадь МО как результат исключения территорий, которые
                                находятся под воздействием «промышленного предприятия» и/или «свалки».
                            </li>
                            <li>
                                Подсчитывается количество всех сервисов, попадающих в эффективную геометрию МО.
                            </li>
                            <li>
                                Далее вычисляется, плотность сервисов на единицу площади всего МО.
                            </li>
                            <li>
                                Вычисляются плотности для всех МО.
                            </li>
                            <li>
                                Составляется рейтинг МО по убыванию плотности сервисов.
                            </li>
                            <li>
                                На карту выводятся и подкрашиваются 10 лучших МО.
                            </li>
                        </ul>
                        <h3>Документы с подробным описанием алгоритмов:</h3>
                        <Collapse expandIconPosition='left'>
                            <Panel header="Описание_сервиса_по_выбору_МО_досуга.docx" key="99">
                                <div>{service_description_text}<a href={service_description} target="_blank"
                                                               rel="noopener noreferrer" download>
                                    <Button id='service_description' style={{textAlign: 'center'}} type="link" size='small'
                                            onClick={callback}>Скачать</Button>
                                </a>
                                </div>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>

            <MapSurvey />
        </div>
    );
}

export default About
