//https://t.me/elemnt3 Y:2021
import React, {useState} from "react";
// import cover_spb from '../../assets/images/cover_spb.jpg'
// import main_cover from '../../assets/images/main-cover.jpg'
import main_cover from '../../assets/images/idu-banner.jpg'

function Hero() {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div id='_main' className='heroBlock'>
            <img src={main_cover} className='mainPic' alt="cover_spb"/>
            <div className='heroBlockText'>
            <h1 style={{textAlign: 'center'}} className='heroBlockTextH1'>
                Сервис по выбору различных мест досуга
                <p style={{textIndent: '1.5em', textAlign: 'center', marginTop: '24px', marginBottom: 0}}>
                    Для выбранного пользователем вида досуга вывод подходящих объектов, в результате анализа
                    разнообразных наборов открытых данных
                </p>
            </h1>
            </div>
        </div>
    );
}

export default Hero
