export const sushi_shawarma_fast_foods = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {
            "type": "Feature",
            "properties": {
                "name": "Бери и беги",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 10-я Красноармейская улица, 20",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297012, 59.912725]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Burj Khalifa",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 2к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33096, 60.003241]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Копылов",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 4-я Советская улица, 18\/9",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370207, 59.933501]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Felicita",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 40",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279738, 59.941736]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофе с собой",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Авиационная, 21а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337607, 59.839113]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ollis Club",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 34",
                "opening_hours": "11:00-23:00",
                "website": "https:\/\/www.ollis.ru\/restaurants\/kazanskaya"
            },
            "geometry": {"type": "Point", "coordinates": [30.314, 59.929502]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Primal vegan burgers",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7Д",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320315, 59.931851]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хачапури",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Аннинское шоссе, 57в",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.126467, 59.781836]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Meshuga Grill",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 1",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324519, 59.928956]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофе с собой",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317764, 59.96866]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Околица",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "NVB",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Al Petra",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "opening_hours": "Mo-Su 10:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хачапури Хинкали",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hardy's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Central park",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Барочная улица, 10к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288119, 59.967529]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "StartUp",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Баррикадная улица, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258663, 59.895985]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Назима",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Библиотечный переулок, 6",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296914, 59.90737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Stay True Bar",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323234, 59.939]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sweet Wave",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 11",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.960976]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофе Будешь?",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 41",
                "opening_hours": "https:\/\/cbenefice.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311278, 59.963224]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Лев и Птичка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 19",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": "vk.com\/leviptichka"
            },
            "geometry": {"type": "Point", "coordinates": [30.296932, 59.956219]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "FRANK",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 35",
                "opening_hours": "https:\/\/frankmeat.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301971, 59.959287]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Красное море",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 67",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309571, 59.963994]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофейня Любовь и кофе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 29",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343312, 59.966039]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Эст",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 71",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253345, 59.842029]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ТРИ СОУСА",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 73к4",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": "https:\/\/trisousa.uds.app\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.252061, 59.841993]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Geo",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 31к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384347, 59.865786]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ollis",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 72к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378283, 59.874024]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кура-гриль",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Введенская улица, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299842, 59.957678]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "От заката до рассвета",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Верхняя улица, 12А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364898, 60.057792]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сад",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Воронежская улица, 42",
                "opening_hours": "Mo-Fr 10:00-19:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347156, 59.914412]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Simple food",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Воронежская улица, 5",
                "opening_hours": "Mo-Su 09:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348728, 59.917962]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Трактир",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241281, 59.930476]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Metropolis Kitchen & bar",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Глиняная улица, 5к1",
                "opening_hours": "10:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392081, 59.916406]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Drink dreams",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 17\/56",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316102, 59.932171]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 29",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31921, 59.930142]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ollis Club",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 70",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.ollis.ru\/restaurants\/zagorodniy"
            },
            "geometry": {"type": "Point", "coordinates": [30.33193, 59.922242]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "NVB",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "https:\/\/vk.com\/public115703822",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Rotana",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Tandir",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 66к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396061, 60.013883]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Farm truck",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дачный проспект, 9к1",
                "opening_hours": "12:00-24:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244362, 59.846549]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Na kuhne",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Двинская улица, 3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255977, 59.909391]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Фалафель у дяди Фади",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Думская улица, 5\/22",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": "http:\/\/vk.com\/dydyfadi"
            },
            "geometry": {"type": "Point", "coordinates": [30.328085, 59.933393]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Первая чебуречная",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Елагин остров, 4г",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Остров",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Т",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260568, 59.977751]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Food Master",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 68к2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234966, 59.949073]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "inter'PAN",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 68к2",
                "opening_hours": "Mo-Su 11:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234966, 59.949073]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Street Food Market",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 18",
                "opening_hours": "http:\/\/street-food.market\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343186, 59.926183]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Fenster",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 5",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su,PH 10:00-20:00",
                "website": "http:\/\/ruirui.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344884, 59.927297]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бодрая сова",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Горячая выпечка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофикс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "08:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Joly Woo",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 9",
                "opening_hours": "11:00-23:00",
                "website": "http:\/\/jolywoo.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344093, 59.926995]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофе Бон",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 20",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 09:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414692, 59.929849]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Цимес",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ЧебуRoom",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "NVB",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пельмешъ",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заставская улица, 3",
                "opening_hours": "Mo-Fr 08:00-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338065, 59.888379]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Mr.Cat",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 6",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446672, 59.875817]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ода",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 5",
                "opening_hours": "09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307172, 59.917732]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Русалка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кавалергардская улица, 4",
                "opening_hours": "Mo-Fr 13:00-16:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382299, 59.943142]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ChiefBurgers",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 31",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284562, 59.947108]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Трапезная",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Камская улица, 22",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253336, 59.945968]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск в Диво-Острове",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кемская, 1д",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Быстрое питание",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 15",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357164, 59.943715]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск по продаже свежей выпечки из тандыра",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357164, 59.943715]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Fenster",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357164, 59.943715]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусно & Сытно",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359077, 59.943706]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Огонёк",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Клинский проспект, 19",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321626, 59.914804]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мясопотамия",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "NVB",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Иволга",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "opening_hours": "10:00-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hood",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Denim",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 69",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28793, 59.859872]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кройцберг Дёнерия",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 53",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307999, 59.955376]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Узген сити",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 32к1А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398226, 59.828527]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Fresh Point",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 11\/7с4",
                "opening_hours": "https:\/\/fresh-point.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289107, 59.967592]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Папирус",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 129к6",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271312, 59.851331]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ollis",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 74к1",
                "opening_hours": "11:00-23:00",
                "website": "https:\/\/www.ollis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.184939, 59.860861]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пельмени и вареники",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 1А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353975, 59.960422]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Оллис",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 32",
                "opening_hours": "11:00-23:00",
                "website": "https:\/\/www.ollis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.34721, 59.972262]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 135",
                "opening_hours": "Mo-Su 09:00-22:00",
                "website": "http:\/\/stolovayastreet.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.351549, 59.917484]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Takara",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хачапурная",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 154",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349582, 59.912734]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Барбекю лэнд",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ёгурти",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Fire wok",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "PhoBo",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Moo Moo",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 44Б",
                "opening_hours": "Mo-Th, Su 09:00-23:30, Fr, Sa 09:00-01:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359077, 59.926431]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ukusno",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50И",
                "opening_hours": "Mo-Th 11:00-20:00; Fr-Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361673, 59.923405]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Felicita",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 57-59",
                "opening_hours": "09:00-22:00",
                "website": "http:\/\/evro-stolovaya.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.928438]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ALLO PITER",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 85",
                "opening_hours": "09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356346, 59.924429]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кафетерий",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 21",
                "opening_hours": "Mo-Fr 10:00-20:00 Sa-Su 09:00-19:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348189, 59.943255]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пестеля 27",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 24",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349016, 59.942507]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Чебуroom",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 63",
                "opening_hours": "24\/7",
                "website": "https:\/\/cheburoom.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347579, 59.93319]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Chaneль №7",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Магнитогорская улица, 30",
                "opening_hours": "Mo-Fr 08:30-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422049, 59.939546]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пища богов",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 53",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259023, 59.942809]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофейная станция",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Митрофаньевское шоссе, 2к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300893, 59.907428]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Придворный повар",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 183-185",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319839, 59.855331]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сытный дворик",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 38",
                "opening_hours": "09:00-23:50",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318527, 59.914719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Wrap Roll",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 39",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/wraproll.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317934, 59.912932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СССР",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 49",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317943, 59.911511]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Berry Blin",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4АА",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319767, 59.924812]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Smoke Food Bar",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 51",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317961, 59.911344]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ДвижОк",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 7",
                "opening_hours": "http:\/\/dvijok-cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.924718]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Русские Традиции",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 79А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317243, 59.899153]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Боб Культ",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Моховая улица, 39",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344812, 59.939821]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Kuznya cafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2М",
                "opening_hours": "10:30-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288838, 59.929502]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ferma Burger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pho'n'Roll",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "Mo-Su 12:00-23:00",
                "website": "https:\/\/www.phonrollcafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Tutti Frutti",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326414, 59.936697]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "City Soup",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa,Su,PH 11:00-21:00",
                "website": "http:\/\/soupperia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хачапури Хинкали",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бодрая сова",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Трансмаркет",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Царский аппетит",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Zёрна",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "MarioShop",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 136к1Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281579, 59.908083]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ЩиБорщи",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 219-221",
                "opening_hours": "Mo-Fr 07:00-21:00; Sa-Su 09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27477, 59.909617]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бодрая Сова",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 36",
                "opening_hours": "07:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357685, 59.913433]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "АНТОНИО",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 63",
                "opening_hours": "Mo-Sa 10:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356059, 59.914994]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Diner №1",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 29\/66",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342638, 59.933704]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сахара",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 31",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27309, 59.902154]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "https:\/\/cinnabonrussia.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Щелкунчик",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 118",
                "opening_hours": "08:00-23:00",
                "website": "http:\/\/www.schelkunchik.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362329, 59.931594]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 120",
                "opening_hours": "24\/7",
                "website": "http:\/\/stolovayastreet.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.363667, 59.930895]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Fresh Point",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 126\/2",
                "opening_hours": "Mo-Fr 08:00-21:30; Sa-Su 10:30-21:30",
                "website": "http:\/\/fresh-point.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.365527, 59.930908]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 151",
                "opening_hours": "http:\/\/stolovayastreet.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379316, 59.925498]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дедеушка Хо",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 178",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381634, 59.925259]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "SB Burgers",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 27",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 00:00-24:00",
                "website": "http:\/\/sbburgers.me\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327052, 59.93502]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Green box",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 63",
                "opening_hours": "24\/7",
                "website": "http:\/\/greenboxes.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.352834, 59.931815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ice Cream Bar",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 90-92",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352403, 59.932383]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Koreana light",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 95",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.koreanalight.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.366479, 59.930187]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Don Bosco",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Новгородская улица, 12а",
                "opening_hours": "https:\/\/donbosco.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386943, 59.9374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Новоовсянниковская, 16",
                "opening_hours": "Mo-Fr 09:00-16:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263936, 59.888279]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Shell",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 88",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22662, 60.051868]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Тайяки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, переулок Бринько, 1-3-5",
                "opening_hours": "Su-Th 11:00-21:00; Fr-Sa 11:00-22:00",
                "website": "http:\/\/taiyakicafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316344, 59.925637]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 26",
                "opening_hours": "Su-Th 08:00-23:00, Fr-Sa 08:00-04:00",
                "website": "https:\/\/www.hesburger.ru\/260?tid=1230"
            },
            "geometry": {"type": "Point", "coordinates": [30.316991, 59.927392]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сфинкс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, переулок Матюшенко, 8",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442647, 59.876639]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "EDA",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 22",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334302, 59.960282]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 6",
                "opening_hours": "Mo-Su 10:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356436, 59.955737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Чебуреки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 8к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355178, 59.956832]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Лига",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Политехническая, 11в",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск в Диво-Острове",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск в Диво-Острове",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск в Диво-Острове",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск в Диво-Острове",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск в Диво-Острове",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "deli2go",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, 64",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.13713, 60.011288]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Dahab",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Просвещения проспект, 37 к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369183, 60.045736]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Street food Inc",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 78",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215769, 59.832937]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Эмираты",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 15",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422085, 59.896228]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "World Cheef",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 19",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420324, 59.895646]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Chicken Kebab",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 29к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Картаго",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Траттория",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ЧебуRoom",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Funky Pho",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "National",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Soup & rolls",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Розарио",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "La Cucaracha",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "deli by shell",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 19А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373019, 60.043031]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Fratelli",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 5В",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314548, 59.969547]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Falafel House Kebob",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 57",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409805, 59.969087]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кафе 24",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 14Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401136, 60.01266]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Gyros",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Ш",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471456, 59.865686]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ламантин",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 271",
                "opening_hours": "https:\/\/lamantin-kafe.ru\/bistro-lamantin-obuhov\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479254, 59.851245]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 271Ж",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.478984, 59.852]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Stopexpress",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 271Ж",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.478984, 59.852]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Посиделки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 37Б",
                "opening_hours": "9:00-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267817, 59.888261]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Экспомаркет",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 45к2",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25852, 59.887575]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Halal",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 66",
                "opening_hours": "09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263056, 59.880405]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бульк",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 22",
                "opening_hours": "08:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35976, 59.94412]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Greek cafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 111 ст3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334248, 60.051553]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Secret Spot",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 12",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328265, 60.000519]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Картаго",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Околица",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Batista",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Oliva",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Фабрика Кухня",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27Т",
                "opening_hours": "Mo-Sa 09:00-20:00; Su 09:00-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 60.005324]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ляванги",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 70В",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435946, 59.971996]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Grill Republic",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энтузиастов",
                "opening_hours": "Mo-Su 09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480125, 59.948924]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "DriveCafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 21к2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319183, 59.823702]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 35",
                "opening_hours": "Mo-Fr 07:30-21:00; Sa-Su 09:00-21:00",
                "website": "https:\/\/doza.business.site\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350956, 59.922197]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Lazzat",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 41В",
                "opening_hours": "Mo-Su 08:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273126, 59.914971]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Трапеза",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 58",
                "opening_hours": "Mo-Fr 09:00-17:45",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274995, 59.913054]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Joly Woo",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": "11:00-23:00",
                "website": "http:\/\/jolywoo.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шашлыки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 48к4",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38255, 60.025296]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бодрый день",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 4",
                "opening_hours": "http:\/\/bodryi-den.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318779, 59.926359]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СоХо",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 6",
                "opening_hours": "07:30-20:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318231, 59.926179]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "BreakFast",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Софийская улица, 85",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430215, 59.845568]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шашлычный магазин №1",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 13",
                "opening_hours": "11:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284032, 59.944896]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хычины",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 34",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27698, 59.942444]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Петергофский",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 28",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276567, 59.906003]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Starsky",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 41",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275327, 59.902343]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Grill Republic",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Тепловозная улица, 34",
                "opening_hours": "09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.501253, 59.832408]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sibylla",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "opening_hours": "10:00-19:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Оптимальный Дворик",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торжковская улица, 5",
                "opening_hours": "Mo-Fr 09:30-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3094, 59.987825]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ice Fresh",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Makkale",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pum Poni",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Snapy",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Транспортный переулок, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.920668]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкуснотут",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Тульская улица, 11",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391954, 59.94267]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Симсим",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314224, 60.0174]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "5 звезд",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 11",
                "opening_hours": "10:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422058, 59.896811]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Белинского, 13",
                "opening_hours": "https:\/\/cinnabonrussia.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347848, 59.938081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Русские традиции",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Возрождения, 20А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273917, 59.881638]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Питаник",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 16",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303983, 59.92819]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "My",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 33",
                "opening_hours": "http:\/\/dekabristov.mcoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291173, 59.925619]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 43-45",
                "opening_hours": "09:00-22:00",
                "website": "http:\/\/stolovayastreet.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.286844, 59.924609]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Уличный шеф",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Достоевского",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34774, 59.92373]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Жемчужина",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Есенина, 12к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330376, 60.039153]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Феникс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 6",
                "opening_hours": "09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323333, 59.924578]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Поесть Пора",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Книпович, 21",
                "opening_hours": "Mo-Fr 10:00-16:00",
                "website": "http:\/\/www.поесть-пора.рф\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.386844, 59.901653]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Оллис",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 34",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217736, 59.954043]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Фабрика",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Красного Текстильщика, 10-12литО",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "website": "https:\/\/www.7771000.ru\/info\/centr-obschestvennogo-pitanija-idc-59\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.396429, 59.940718]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Friendly Coffee",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Марата, 26",
                "opening_hours": "http:\/\/friendly-coffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345593, 59.921498]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sticker Coffee",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Марата, 39",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351235, 59.925209]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Тайяки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Марата, 59",
                "opening_hours": "Su-Th 11:00-21:00; Fr-Sa 11:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347112, 59.92217]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мариоль",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 29",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274321, 59.88488]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Соберуши",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 3",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355071, 59.933974]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Старварим",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Мира, 15к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318653, 59.960088]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Чебуреки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Мира, 31",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326369, 59.961643]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хива",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Моисеенко, 43",
                "opening_hours": "Mo-Fr 09:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393599, 59.937332]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Медина",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 27",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236753, 59.948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Mouse Tail",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 10",
                "opening_hours": "https:\/\/mousetail.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344965, 59.942876]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "On Lunch",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Правды, 4",
                "opening_hours": "Mo-Su 09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344515, 59.924253]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 37К",
                "opening_hours": "Mo-Fr 08:00-20:00",
                "website": "http:\/\/greenboxes.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308196, 59.971154]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофейная гуща",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Рентгена, 15\/31",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa, Su 10:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323306, 59.964201]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "У Ларисы",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 29\/28",
                "opening_hours": "Su-Th 12:00-04:00, Fr-Sa 12:00-06:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342467, 59.926841]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "deli2go",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Руставели, 42А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432137, 60.020193]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пельмеш",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Седова, 10",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393671, 59.903214]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Фудстар",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Седова, 11к2",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399672, 59.900475]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кофе Окно",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 12А",
                "opening_hours": "Mo-Fr 08:00-18:30, 09:00-18:00; Sa, Su 09:00-18:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342144, 59.970862]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хохлома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "LeGur Ленивый Гурман",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пельменная",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 12",
                "opening_hours": "Mo-Fr 10:00-20:00; Sa-Su 11:00-19:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346034, 59.946815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Mealty",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 15",
                "opening_hours": "https:\/\/mealty.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331337, 59.961179]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Take & Wake",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 6",
                "opening_hours": "http:\/\/takeandwake.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296941, 59.907663]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Тай-Экспресс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Финляндский проспект, 1",
                "opening_hours": "11:00-22:00",
                "website": "http:\/\/thai-express.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.342081, 59.958381]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пельменная #1",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Финский переулок, 6",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354136, 59.956822]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Любава",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Химический переулок, 4Б",
                "opening_hours": "10:00-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284939, 59.890735]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пузата Хата",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 40",
                "opening_hours": "12:00-24:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361395, 59.938883]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Нямбург",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295324, 59.96357]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Wrap roll",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Южное шоссе, 55к1",
                "opening_hours": "11:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405143, 59.857712]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ломоносов, Краснофлотское шоссе, 49Е",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.692408, 59.9296]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Левашово, улица Володарского, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214834, 60.104142]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 7",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399298, 59.718]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 5",
                "opening_hours": "Mo-Fr 10:00-21:00; Sa,Su 11:00-20:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398985, 59.721901]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 14\/50",
                "opening_hours": "Mo-Su 09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406349, 59.718283]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 15А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407745, 59.718002]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Средняя улица, 38",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408681, 59.715693]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая \"ГЕРМЕС\"",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Университетский проспект, 26",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.835394, 59.878228]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая № 30",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Ульяновская улица, 9к1",
                "opening_hours": "Mo-Sa 09:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.824702, 59.880405]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "столовая №20",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Университетский проспект, 35В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.831189, 59.882263]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "столовая №23",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Ульяновская улица, 3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.823371, 59.882485]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Столовая №24",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Ульяновская улица, 1",
                "opening_hours": "Jan-May, Sep-Dec: Mo-Th 10:00-17:00; Fr 10:00-15:00,16:00-17:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.826205, 59.881859]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусно Суши",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Софийская улица, 33к1",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399304, 59.875524]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусные Суши",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 49к2",
                "opening_hours": "https:\/\/vkusnyesushi.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.222407, 60.006615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусные суши",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Брянцева, 13к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407505, 60.039768]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пицца-суши маг",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 27к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479918, 59.905592]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Самба суши",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 10",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369444, 59.938581]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши & box",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Малая Балканская улица, 30Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385937, 59.82846]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши бар",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 90",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39032, 59.863034]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши и Пицца",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Адмирала Коновалова, 2-4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.149707, 59.849989]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Маг",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Ольги Форш, 13к1",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/mag-sushi.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.391443, 60.039975]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Маг",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 93",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346752, 59.833728]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Маркет",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 45",
                "opening_hours": "Mo-Su 09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350139, 59.851214]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Рай",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Роял",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Кузнецова, 11к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.189655, 59.862294]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши смак",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 35",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265571, 60.020993]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Те Вёсла",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Народная улица, 2",
                "opening_hours": "https:\/\/stvclub.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456913, 59.879895]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шок",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Костромской проспект, 27",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317314, 60.01785]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 73к4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252061, 59.841993]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 23к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313281, 59.866463]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 23",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.415132, 59.929348]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 21\/75",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436305, 59.873306]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Клочков переулок, 10к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463201, 59.921078]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Адмирала Черокова, 18к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.142179, 59.851851]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 24к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479119, 59.9063]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 10",
                "opening_hours": "https:\/\/www.sushishop.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295566, 59.907316]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Решетникова, 5",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323845, 59.878667]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Рихарда Зорге, 4к1",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.179782, 59.851019]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Экспресс",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 60",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469444, 59.88311]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши White",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 35к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241928, 60.003858]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши White",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 33к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385712, 59.864674]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 35к2",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199572, 59.995638]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 19",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.368446, 59.931526]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, аллея Поликарпова, 2",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294318, 60.006386]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 15",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.276279, 60.002764]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 5",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292997, 59.953772]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 94",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.311143, 59.965458]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 117к1",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.418752, 60.032847]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 49к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.400822, 60.014468]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 14",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.410038, 59.928902]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42БА",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.308834, 59.968232]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 27",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.363075, 59.943683]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 45",
                "opening_hours": "10:00-23:00",
                "website": "http:\/\/sushi-wok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.312616, 59.956922]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, ​Ленина, 27",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 1А",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353975, 59.960422]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Липовая аллея, 11",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256822, 59.985791]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Моховая улица, 25",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.345, 59.942385]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши WOK",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Наличная улица, 40к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.231903, 59.949907]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Невский проспект, 50",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334778, 59.934592]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 6к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.290976, 60.003403]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 30",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/sushiwok.ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.496789, 59.945644]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 33А",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347597, 60.03958]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Науки, 12",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.392692, 60.01476]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Науки, 24к1",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412733, 60.00966]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 30к1",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327672, 60.054837]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 68к1",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.378993, 60.044347]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 9",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.364081, 60.000263]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Ударников, 30к1",
                "opening_hours": "10:00-23:00",
                "website": "http:\/\/www.sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.472301, 59.952065]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 150к1Б",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.335146, 60.054738]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 62",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322093, 60.016235]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Садовая улица, 13-15\/50",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.335074, 59.934898]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 66",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.398244, 60.040353]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 56",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.386152, 59.944508]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 30А",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.414575, 60.042056]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Торжковская улица, 6",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.308349, 59.988536]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Туристская улица, 22",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20737, 59.999125]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 25",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.315006, 60.017468]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Есенина, 32к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.339889, 60.052384]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 9",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295872, 59.986169]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 12",
                "opening_hours": "Mo-Su,PH 10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.343078, 59.971069]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 15М",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.343976, 59.970758]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Уточкина, 2к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.sushi-wok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.261547, 60.009935]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Учительская улица, 9к1",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392099, 60.034169]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 28",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294407, 59.96326]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Юнтоловский проспект, 51к1",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143239, 60.033283]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши-бар",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 11к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401675, 60.005077]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши-сити",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Туристская улица, 28к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209382, 60.003228]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши-шоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 9к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239305, 59.860025]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши-house",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 47",
                "opening_hours": "Mo-Fr 12:00-00:00; Sa-Su 13:00-00:00",
                "website": "http:\/\/kido.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.323926, 60.014023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиМаг",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Муринская дорога, 78",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.451981, 60.035405]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиМаг",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 158",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148925, 59.834935]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиСет",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 71",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/setsushi.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.221608, 60.041099]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 33к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.385712, 59.864674]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 41-43",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.330699, 59.921651]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 116",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.247785, 59.852335]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 159",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.316066, 59.851566]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 76к1",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.187885, 59.860184]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 33",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.296114, 59.918102]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 65",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.357945, 59.927166]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Московский проспект, 73",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.317934, 59.905001]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Народная улица, 16",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464566, 59.881033]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Невский проспект, 91",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.365284, 59.930327]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сушишоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 110",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202096, 59.832747]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 122",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.178947, 59.832575]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 19",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.420324, 59.895646]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 31к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.497849, 59.94472]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Кузнецова, 22к1",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.186601, 59.85473]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 13",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.307604, 59.924546]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 26",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27274, 59.889968]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 20",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326001, 59.828021]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Садовая улица, 33",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320809, 59.928289]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Софийская улица, 31",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397974, 59.876684]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 52",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.275471, 59.901906]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 5\/1",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.sushishop.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.449996, 59.913144]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 11\/3",
                "opening_hours": "https:\/\/www.sushishop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267072, 59.878107]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сушишоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Чудновского, 19",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.474555, 59.925683]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 46",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379334, 59.857414]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 84к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199177, 59.857649]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 39",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319524, 59.864625]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиШоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 90",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20781, 59.855755]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сушишоп Пиццашоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 31к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484581, 59.922476]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 42",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28634, 59.94472]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 44",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.274671, 59.943115]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 73",
                "opening_hours": "https:\/\/sushiwok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236744, 59.937986]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Терияки",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Терияки гриль",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Тихий",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 10к1",
                "opening_hours": "Mo-Th 10:00-22:00, Fr-Sa 10:00-23:00, Su 10:00-22:00",
                "website": "https:\/\/tihiysushi.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316335, 59.974707]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Япона Суши",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Московский проспект, 189",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320656, 59.854065]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Aziana",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Cушишоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 159",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316066, 59.851566]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "I Love Sushi",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 16",
                "opening_hours": "10:00-23:00",
                "website": "http:\/\/sushi-v-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314646, 59.876003]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Mr. Sushi",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pro-Sushi",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 61к1",
                "opening_hours": "Mo-Su,PH 12:00-24:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313955, 59.851598]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pro-Sushi",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 45",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152851, 59.848303]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi Booffet",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi City",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 81",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248234, 59.842413]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi City",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 3к2",
                "opening_hours": "Mo-Su 11:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369704, 59.987992]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi Land",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 92",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396366, 59.839782]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi Love",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 71",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221608, 60.041099]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi Love",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 47к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/spb-optikov.sushi-love.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.202195, 60.003169]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi One",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 32",
                "opening_hours": "11:00-23:00",
                "website": "http:\/\/sushione.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308511, 60.017112]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi-City",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Львиный переулок, 1",
                "opening_hours": "Su-Th 11:00-22:00; Fr-Sa 11:00-23:00",
                "website": "https:\/\/sushi-city.com\/shop\/dekabristov28\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.300705, 59.92731]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sushi-fresh",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 62к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453382, 59.881923]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "SushiMarketWok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Московский проспект, 7",
                "opening_hours": "11:00-23:00",
                "website": "http:\/\/www.sushimarketwok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.924718]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Wok-кафе",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Woker",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Кронштадт, Гражданская улица, 7",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.766436, 59.997929]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 16к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/sushiwok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.342232, 60.077647]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Суши Wok",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 4",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/sushiwok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.336113, 60.070812]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СушиВэй",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Пушкин, Привокзальная площадь, 1к3А",
                "opening_hours": "Mo-Su 12:00-02:00",
                "website": "https:\/\/sushiway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.428762, 59.723931]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сушишоп",
                "city_function": "Питание",
                "type": "суши",
                "address": "Россия, Санкт-Петербург, Пушкин, Привокзальная площадь, 4",
                "opening_hours": "https:\/\/www.sushishop.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427157, 59.722186]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 41",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352421, 59.921579]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Александровский парк, 8",
                "opening_hours": "Mo-Su 07:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322174, 59.953295]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5литО",
                "opening_hours": "09:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.377412, 59.830757]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 3к1",
                "opening_hours": "https:\/\/burgerking.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35463, 59.958169]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 92",
                "opening_hours": "Mo-Su 08:00-02:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.396366, 59.839782]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 30",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.36956, 59.883611]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Выборгская набережная, 57к1",
                "opening_hours": "07:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326711, 59.978232]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 20к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.452996, 59.906206]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 14к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.246051, 60.016771]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 27к1",
                "opening_hours": "Mo-Fr 06:00-03:00;Sa-Su 07:00-01:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.363209, 59.83186]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 68к2",
                "opening_hours": "Mo-Su 07:00-01:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.234966, 59.949073]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 45",
                "opening_hours": "06:00-04:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.329864, 59.921389]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 40",
                "opening_hours": "Mo-Su 07:00-04:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.310694, 59.966255]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 86Б",
                "opening_hours": "Fr-Sa 10:00-22:30;Mo,Tu,We,Th,Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.305951, 59.852538]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 100к1",
                "opening_hours": "Mo-Su 08:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.220036, 59.853265]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 128к2",
                "opening_hours": "Su-Th 08:00-03:00; Fr-Sa 08:00-04:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.268419, 59.852425]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 61",
                "opening_hours": "Mo-Su 06:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.178013, 59.860735]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "Mo-Su 10:00 - 22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "Mo 08:00 - 23:30;Tu-Su 08:00-23:30",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малая Балканская улица, 27",
                "opening_hours": "Mo-Su 06:00-02:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.382909, 59.829554]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 23",
                "opening_hours": "Mo-Su 06:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317665, 59.915882]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120",
                "opening_hours": "Mo-Su 06:00-02:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.297677, 59.907289]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "Mo 09:00 - 23:00;Tu-Su 09:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 18",
                "opening_hours": "24\/7",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318249, 59.936557]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 38\/4",
                "opening_hours": "Fr-Sa 07:00-05:00;Mo,Tu,We,Th,Su 07:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331274, 59.935232]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 84-86",
                "opening_hours": "Su-Th 07:00-03:00, Fr-Sa 07:00-05:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.350498, 59.932644]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": "Mo-Su 07:00-05:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Планерная улица, 59",
                "opening_hours": "10:00 - 22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.225291, 60.02222]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 6",
                "opening_hours": "https:\/\/burgerking.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356436, 59.955737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "Mo 10:00 - 23:00;Tu-Su 10:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "Mo-Su 08:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 97",
                "opening_hours": "Mo-Tu 08:00-24:00;We-Su 08:00-04:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.237849, 59.984548]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 32к1",
                "opening_hours": "Mo-Su 08:00-03:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.493357, 59.895452]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7",
                "opening_hours": "07:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296635, 60.003984]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Маршала Блюхера, 41",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.407927, 59.975274]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 147к2",
                "opening_hours": "Mo-Su 07:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.249905, 59.831883]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 31",
                "opening_hours": "Mo,Tu,We,Th,Fr,Su 08:00-04:00;Sa 08:00-05:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.342368, 60.050641]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 86к1",
                "opening_hours": "Mo-Su 06:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.418941, 60.036214]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27Д",
                "opening_hours": "Mo-Su 08:00- 23:30",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327241, 60.007866]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 39\/41",
                "opening_hours": "https:\/\/burgerking.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439665, 59.959462]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 38к1",
                "opening_hours": "08:00-03:00",
                "website": "https:\/\/burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.275174, 59.941862]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Тепловозная, 31",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Транспортный переулок, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.920668]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.379325, 59.870014]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 6",
                "opening_hours": "Mo-Su 07:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.265616, 59.879918]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Демьяна Бедного, 15",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.387392, 60.042707]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 27к4",
                "opening_hours": "Fr-Sa 06:00-03:00;Mo,Tu,We,Th,Su 06:00-01:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.482218, 59.906066]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Карбышева, 9",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353265, 59.993001]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 101",
                "opening_hours": "Mo-Th, Su 06:00-02:00; Fr-Sa 06:00-03:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348899, 59.832313]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 47",
                "opening_hours": "08:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.173458, 59.824909]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 27",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358188, 59.944999]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Центральный район, Кирпичный переулок, 2",
                "opening_hours": "06:00-03:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315464, 59.936111]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "БургерЛэнд",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Константина Заслонова, 32-34",
                "opening_hours": "12:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343797, 59.917285]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургерная #1",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 19к2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394444, 60.012966]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бутерброд",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 33к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225201, 59.856374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Варна pizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 29",
                "opening_hours": "Mo-Fr 10:30-22:00; Sa 11:00-22:00; Su 11:30-21:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273144, 59.902357]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная курочка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 122",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297507, 59.908047]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Горизонталь",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "opening_hours": "13:02-21:42",
                "website": "https:\/\/vk.com\/veganstreetfood"
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Горячая кружка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Циолковского, 10Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288856, 59.91037]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Гриль-репаблик",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 30к1",
                "opening_hours": "Mo-Su 09:00-00:00",
                "website": "https:\/\/dodopizza.ru\/peterburg\/tczebra\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.49766, 59.946049]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 17к2",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/dodopizza.ru\/peterburg"
            },
            "geometry": {"type": "Point", "coordinates": [30.385595, 60.014895]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ДоДо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 84к1",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/dodopizza.ru\/peterburg"
            },
            "geometry": {"type": "Point", "coordinates": [30.415572, 60.036852]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Маршала Захарова, 20",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "https:\/\/www.dodopizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.179513, 59.857567]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "opening_hours": "Mo-Su 10:00-00:00",
                "website": "https:\/\/dodopizza.ru\/peterburg\/tczebra\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 54\/32с3",
                "opening_hours": "Mo-Su 10:00-00:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2109, 60.006008]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 67\/22В",
                "opening_hours": "09:00-01:00",
                "website": "https:\/\/dodopizza.ru\/peterburg\/ligovsky67"
            },
            "geometry": {"type": "Point", "coordinates": [30.357837, 59.926896]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 137",
                "opening_hours": "Mo-Su 10:00-18:00",
                "website": "https:\/\/dodopizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314673, 59.881719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 18",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/dodopizza.ru\/peterburg\/moskovskiy18\/contacts"
            },
            "geometry": {"type": "Point", "coordinates": [30.318267, 59.920641]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ДоДо пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 51",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304352, 59.986043]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 7",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": "http:\/\/didopizza.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.27221, 59.900719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 2",
                "opening_hours": "Mo-Su 09:00-24:00",
                "website": "https:\/\/dodopizza.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.473361, 59.917885]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Славы, 30к1",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387419, 59.858349]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Садовая улица, 49",
                "opening_hours": "08:00-24:00",
                "website": "https:\/\/dodopizza.ru\/peterburg\/sadovaya49\/contacts"
            },
            "geometry": {"type": "Point", "coordinates": [30.312517, 59.92479]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7",
                "opening_hours": "Mo-Su 08:00-01:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257325, 59.9894]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Софьи Ковалевской, 3к1",
                "opening_hours": "09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411377, 60.012431]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Домино'с",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 88",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204477, 59.856474]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Домино'с",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 23",
                "opening_hours": "Mo-Su 12:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28034, 59.939595]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Домино`с пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/spb.dominospizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дорогая, здесь не ловит",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Суздальское шоссе, 22к4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275965, 60.058475]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кафе по продаже фастфудной продукции",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324151, 59.941029]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кетч Ап Бургерс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "Mo-Th 09:00-23:00; Fr 09:00-01:00; Sa 11:00-01:00; Su 11:00-23:00",
                "website": "http:\/\/ketch-up.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кетчап Бургерс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 8",
                "opening_hours": "Mo-Th 11:00-23:00; Sa 11:00-01:00; Su, Fr 11:00-23:00",
                "website": "http:\/\/ketch-up.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.259238, 60.005967]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "КИНГ АВТО",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 97",
                "opening_hours": "24\/7",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.237849, 59.984548]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "КингАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 20к2",
                "opening_hours": "24\/7",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.452996, 59.906206]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "КингАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 86Б",
                "opening_hours": "Mo-Su 06:00-05:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305951, 59.852538]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "КингАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Демьяна Бедного, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387392, 60.042707]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск фастфудной продукции",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Елагин остров, 4а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kartoshka.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 16к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.451666, 59.908358]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "https:\/\/www.kartoshka.com",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kartoshka.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 17к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316111, 59.828844]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка Картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Марата, 86",
                "opening_hours": "https:\/\/www.kartoshka.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339072, 59.918886]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Курица гриль",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 81к1Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247399, 59.84254]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "КФЦ Экспресс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 37Д",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311332, 59.966629]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ларёк-Кулёк",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2Г",
                "opening_hours": "https:\/\/russiaseafood.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325992, 59.940704]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 42",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.228471, 60.002899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 22",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.476217, 59.945734]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ключевая улица, 6",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.40481, 59.975788]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 4",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.261206, 60.006327]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85литВ",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.146042, 59.994063]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Народная улица, 4к2",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.459024, 59.880162]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 305",
                "opening_hours": "Mo-Su 06:00-05:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.483979, 59.840912]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Славы, 23к4",
                "opening_hours": "Mo-Su 06:00-05:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.382451, 59.856185]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 116",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.321159, 60.035337]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Таллинское шоссе, 161",
                "opening_hours": "Mo-Su 06:00-05:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.162454, 59.805762]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 40к2Б",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.143365, 59.787913]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 101Б",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.34854, 59.831752]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119А",
                "opening_hours": "Mo-Su 00:00-05:00,06:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.227618, 59.986151]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 13",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320405, 59.806966]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ярославский проспект, 45",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320243, 60.017252]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 15",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.309077, 59.916275]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Александра Невского площадь, 2",
                "opening_hours": "00:00-02:00,06:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.37292, 59.934438]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 42",
                "opening_hours": "07:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.228471, 60.002899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 32",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.370504, 59.882699]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 15",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.311862, 60.05295]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.397812, 60.011536]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 14к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.246051, 60.016771]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 45А",
                "opening_hours": "Mo-Su 23:30-04:00;05:00-06:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.329558, 59.921263]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 71к1",
                "opening_hours": "Mo-Su 06:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.440222, 59.933302]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 22",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.476217, 59.945734]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 39",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.310829, 59.966962]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ключевая улица, 6",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.40481, 59.975788]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 1",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.007668]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 4",
                "opening_hours": "00:00-02:00,06:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.261206, 60.006327]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85литВ",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.146042, 59.994063]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "09:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 88к2",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.229154, 59.938743]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 137",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.314673, 59.881719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 195А",
                "opening_hours": "Mo-Su 06:30-02:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320503, 59.849352]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 53",
                "opening_hours": "Mo-Su 06:00-00:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.302852, 59.987091]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Народная улица, 4к2",
                "opening_hours": "Mo-Su 09:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.459024, 59.880162]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": "Mo-Su 10:00-22:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Планерная улица, 59",
                "opening_hours": "Mo-Su 07:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.225291, 60.02222]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 9",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.273495, 59.899925]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пражская улица, 48\/50",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.404074, 59.863328]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18",
                "opening_hours": "Mo-Sa 06:30-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.482479, 59.908507]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 27",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.485892, 59.900507]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.296635, 60.003984]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 45",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.350139, 59.851214]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 31к1",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.22866, 59.858634]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 305",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.483979, 59.840912]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 80к2",
                "opening_hours": "https:\/\/mcdonalds.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409796, 60.039027]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Славы, 23к4",
                "opening_hours": "Mo-Su 07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.382451, 59.856185]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 116",
                "opening_hours": "06:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.321159, 60.035337]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "opening_hours": "Mo-Su 00:00-01:00, 05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "Mo-Su 05:00-02:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 29А",
                "opening_hours": "05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.278678, 59.943462]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Таллинское шоссе, 161",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.162454, 59.805762]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Тепловозная, 31",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 40к2Б",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.143365, 59.787913]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "opening_hours": "Mo-Su 06:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "07:00-02:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 101Б",
                "opening_hours": "Mo-Su 07:00-23:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34854, 59.831752]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Марата, 86",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.339072, 59.918886]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 2",
                "opening_hours": "08:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.179657, 59.843525]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 2\/45",
                "opening_hours": "06:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.346267, 59.932369]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119А",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.227618, 59.986151]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "08:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "opening_hours": "10:00-22:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Центральный район, Кирпичный переулок, 6",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.316281, 59.935633]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 13",
                "opening_hours": "07:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320405, 59.806966]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ярославский проспект, 45",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320243, 60.017252]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакКафе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Думская улица, 4",
                "opening_hours": "07:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328328, 59.933113]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакКафе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 29А",
                "opening_hours": "Mo-Su 07:00-23:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278678, 59.943462]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакКафе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "07:30-23:00",
                "website": "https:\/\/mcdonalds.ru\/restaurants\/653"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакКафе",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 2\/45",
                "opening_hours": "07:00-23:00",
                "website": "https:\/\/mcdonalds.ru\/restaurants\/283"
            },
            "geometry": {"type": "Point", "coordinates": [30.346267, 59.932369]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 70-72",
                "opening_hours": "Mo-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/petrogradka"
            },
            "geometry": {"type": "Point", "coordinates": [30.306921, 59.962922]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 21-23",
                "opening_hours": "Mo-Fr 09:00-01:00, Sa-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/zagorodniy"
            },
            "geometry": {"type": "Point", "coordinates": [30.339081, 59.92497]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Караванная улица, 3\/35",
                "opening_hours": "https:\/\/www.mamaroma.ru\/restaurants\/karavannaya",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340886, 59.935236]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 4\/2",
                "opening_hours": "09:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/konnushennaya"
            },
            "geometry": {"type": "Point", "coordinates": [30.326289, 59.937941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 192-194",
                "opening_hours": "Mo-Fr 08:00-01:00, Sa,Su 11:00-01:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321599, 59.863364]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 34",
                "opening_hours": "Mo-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/fontanka"
            },
            "geometry": {"type": "Point", "coordinates": [30.345459, 59.936449]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 142",
                "opening_hours": "Mo-Fr 08:00-01:00, Sa-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/nevskiy142"
            },
            "geometry": {"type": "Point", "coordinates": [30.369542, 59.929587]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 63",
                "opening_hours": "Su-Th 11:00-01:00; Fr-Sa 11:00-02:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/nevskiy63"
            },
            "geometry": {"type": "Point", "coordinates": [30.352834, 59.931815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 9к1Б",
                "opening_hours": "Mo-Su 11:00-01:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473504, 59.915134]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Славы, 30к1",
                "opening_hours": "Mo-Su 11:00-01:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387419, 59.858349]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 86",
                "opening_hours": "https:\/\/www.mamaroma.ru\/restaurants\/stachek",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260406, 59.869643]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 35",
                "opening_hours": "Mo-Fr 09:00-01:00; Sa-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/novoherkassky"
            },
            "geometry": {"type": "Point", "coordinates": [30.408934, 59.931616]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 6\/13",
                "opening_hours": "Mo-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/restaurant-5"
            },
            "geometry": {"type": "Point", "coordinates": [30.288029, 59.945666]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": "Mo-Su 09:00-01:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мама Рома",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 128к1Б",
                "opening_hours": "Mo-Su 11:00-01:00",
                "website": "https:\/\/www.mamaroma.ru\/restaurants\/savushkina"
            },
            "geometry": {"type": "Point", "coordinates": [30.200408, 59.988658]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мегабургер",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 9",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267054, 59.852479]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мегабургер",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 101",
                "opening_hours": "https:\/\/megaburger.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204836, 59.834135]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мясная Лавка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 12к1",
                "opening_hours": "Mo-Su 12:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257415, 60.008095]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пицца Марио",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Б",
                "opening_hours": "http:\/\/www.pizza-mario.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472903, 59.865384]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пицца Оллис",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 18-я линия Васильевского острова, 29литЗ",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265023, 59.937738]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пицца Оллис",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 22к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254351, 60.00204]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пицца плюс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 86",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349681, 59.832909]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пицца шоп",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Клочков переулок, 10",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.462886, 59.920659]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ПиццаХат",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 23",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.256831, 60.001743]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пиццерия Крэнга",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 28",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320189, 59.929984]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Просто Вася",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 27к4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482218, 59.906066]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Роял Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 74к1",
                "opening_hours": "Mo-Su 09:00-02:30",
                "website": "https:\/\/royalpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.197075, 60.004942]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Роял пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Садовая улица, 53",
                "opening_hours": "24\/7",
                "website": "https:\/\/royalpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309804, 59.923622]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Телепицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 3",
                "opening_hours": "Mo-Th, Su 11:00-23:00; Fr, Sa 11:00-00:00",
                "website": "https:\/\/telepizza-russia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350049, 59.945026]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Телепицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Телепицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Стародеревенская, 33",
                "opening_hours": "11:00-24:00",
                "website": "www.telepizza-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 60.002616]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 34",
                "opening_hours": "Mo-Su 07:30-23:00",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.280097, 59.940993]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 101",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348899, 59.832313]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​5-я линия В.О., 40а",
                "opening_hours": "Mo-Su 09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281804, 59.9433]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 20",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.31771, 59.866382]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 13",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271949, 60.000285]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 42",
                "opening_hours": "Mo-Su 08:30-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228471, 60.002899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 11",
                "opening_hours": "07:30-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316704, 59.935777]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 64",
                "opening_hours": "Mo-Fr 09:00-22:00;Sa-Su 10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/places.phtml?place=203"
            },
            "geometry": {"type": "Point", "coordinates": [30.305564, 59.962052]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 3к1",
                "opening_hours": "09:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35463, 59.958169]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Ветеранов проспект, 110а",
                "opening_hours": "10:00-22:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204567, 59.828464]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 8",
                "opening_hours": "08:30-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365437, 59.9297]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Гражданский проспект, 116 лит Б",
                "opening_hours": "10:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.422974, 60.031805]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Гражданский проспект, 40ж",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404433, 60.019675]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 2",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321842, 60.016766]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320997, 60.016941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 18",
                "opening_hours": "08:30-23:00",
                "website": "https:\/\/teremok.ru\/places\/r_zagorodnyy\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.343186, 59.926183]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Загородный проспект, 2а",
                "opening_hours": "10:00-22:30",
                "website": "https:\/\/teremok.ru\/places\/t_vladimirskaya\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.346752, 59.925944]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Загородный проспект, 52ш",
                "opening_hours": "https:\/\/teremok.ru\/places\/pushkinskaya\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332343, 59.922143]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 65к1",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432802, 59.93021]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 1к2",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335802, 59.922314]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 5",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 09:00-20:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.338065, 59.92056]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 27",
                "opening_hours": "Mo-Su 09:00-22:30",
                "website": "https:\/\/teremok.ru\/places\/t_manezh\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.338542, 59.935615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Каменноостровский проспект, 39а",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316174, 59.970983]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.007668]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297776, 59.933298]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 47",
                "opening_hours": "08:00-23:00",
                "website": "https:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311871, 59.956809]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Кронверкский проспект, 9а",
                "opening_hours": "08:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322138, 59.956764]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238487, 59.852081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 100-104",
                "opening_hours": "Mo-Fr 07:30-23:00; Sa,Su,PH 08:30-23:00",
                "website": "https:\/\/teremok.ru\/places\/r_ligovskiy__100\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354208, 59.919951]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "08:00-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 8",
                "opening_hours": "Mo-Su 09:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.363973, 59.93442]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 22",
                "opening_hours": "07:30-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.348971, 59.943196]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 88к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.229154, 59.938743]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 139к1",
                "opening_hours": "Mo-Fr 07:30-23:00; Sa,Su 09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318527, 59.879864]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 197",
                "opening_hours": "07:30-23:00",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.848665]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 6",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318042, 59.924749]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Набережная Обводного канала, 120а",
                "opening_hours": "https:\/\/teremok.ru\/places\/t_baltiyskaya\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296608, 59.907501]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 93",
                "opening_hours": "08:30-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.365958, 59.93025]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 98",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.093589, 59.851666]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 9",
                "opening_hours": "Mo-Su 07:30-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.273495, 59.899925]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, площадь Труда, 3",
                "opening_hours": "07:30-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.291317, 59.931828]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Политехническая улица",
                "opening_hours": "Mo-Fr 08:00-22:30; Sa,Su 09:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Прибрежная улица, 20",
                "opening_hours": "Mo-Th 08:30-22:30; Fr-Sa 08:30-23:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.505125, 59.831752]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Промышленная улица",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263901, 59.89742]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 10к1",
                "opening_hours": "09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480125, 59.912003]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 36к2",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.247551, 59.842956]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299168, 59.949794]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 35",
                "opening_hours": "Mo-Su 10:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.262454, 60.008437]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 45",
                "opening_hours": "08:30-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350139, 59.851214]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 31к1",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22866, 59.858634]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 17к1",
                "opening_hours": "http:\/\/terrmok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388605, 60.01427]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 44",
                "opening_hours": "09:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.42637, 60.006287]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 80к3",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.408044, 60.038506]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 11\/57",
                "opening_hours": "07:30-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.946058]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": "09:00-23:00",
                "website": "http:\/\/www.teremok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Профессора Попова, 29а",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308196, 59.971154]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 17к2",
                "opening_hours": "Mo-Su 08:30-23:00",
                "website": "http:\/\/teremok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.316218, 59.82737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "Mo-Su 10:00-09:00",
                "website": "https:\/\/www.teremok.ru\/places\/r_aeroport\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Рюхина, 3 лит В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238909, 59.975031]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Садовая улица, 32\/1",
                "opening_hours": "07:30-22:00",
                "website": "https:\/\/teremok.ru\/places\/r_sadovaya__32\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323683, 59.929033]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Советский переулок, 1",
                "opening_hours": "http:\/\/www.teremok.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309526, 59.915612]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 38к1",
                "opening_hours": "Mo-Su 07:30-23:00",
                "website": "http:\/\/www.teremok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.275174, 59.941862]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Стачек проспект, 94а",
                "opening_hours": "Mo-Fr 09:00-22:30; Sa-Su 10:00-22:30",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 59.875863]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Стачек проспект, 94а",
                "opening_hours": "08:00-23:00",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Сытнинская площадь, 3а",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 09:00-21:00",
                "website": "http:\/\/www.teremok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.957755]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Типанова, 3Б",
                "opening_hours": "10:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.324483, 59.853763]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "http:\/\/teremok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Блохина, 33",
                "opening_hours": "Mo-Su 07:30-23:00",
                "website": "http:\/\/teremok78-29.sanktpeterburggid24.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.291838, 59.952759]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269515, 59.878301]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Восстания, 32",
                "opening_hours": "Mo-Su 09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360236, 59.940447]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Димитрова",
                "opening_hours": "http:\/\/www.teremok.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398289, 59.851173]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ильюшина",
                "opening_hours": "Mo-Su 10:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.246913, 60.008518]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.179657, 59.843525]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина",
                "opening_hours": "Mo-Su 09:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246204, 59.984503]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119к3",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.226099, 59.988946]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Уральская улица, 29к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244308, 59.956322]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект",
                "opening_hours": "10:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.295324, 59.96357]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 18",
                "opening_hours": "10:00-22:30",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.291173, 59.961278]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Энгельса проспект, 111Б",
                "opening_hours": "10:00-23:00",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.334248, 60.051553]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок Экспресс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 197",
                "opening_hours": "24\/7",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.848665]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок экспресс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 80к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408044, 60.038506]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Фалафель King",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 22",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341758, 59.925615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Фастфуд",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Корабельная улица, 4К",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249321, 59.875443]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Чао пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ямм Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 9Б",
                "opening_hours": "Su-Th 10:00-23:00; Fr-Sa 10:00-00:00",
                "website": "https:\/\/www.yummpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315617, 59.970727]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ямм Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Восстания, 35",
                "opening_hours": "https:\/\/www.yummpizza.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360883, 59.939077]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ямм Pizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 87к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204208, 59.855267]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ямм Pizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 33к1",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-24:00",
                "website": "https:\/\/www.yummpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344533, 60.050273]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ямм-Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Серебристый бульвар, 17к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281885, 60.004483]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Black Star Burger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "http:\/\/blackstarburger.com",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "BroS BurritoS",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 57",
                "opening_hours": "Mo-Th 11:00-23:00; Fr-Sa 11:00-23:30; Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325821, 59.925719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Bros Burritos",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Моховая улица, 26",
                "opening_hours": "Mo-Fr 10:00+; Sa-Su 11:00+",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345548, 59.941903]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Bros burritos",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331409, 59.95707]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Buns & Burgers",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Правды, 12\/12",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342584, 59.923103]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Burger Lab",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 27",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349492, 59.942719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Burger Planet",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 42",
                "opening_hours": "Mo-Fr 11:00-22:00; Sa-Su 13:00-23:00",
                "website": "http:\/\/бургер-планет.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.380457, 59.941741]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "BURGER SYNDICATE",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Блохина, 23Б",
                "opening_hours": "10:30-22:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297587, 59.952083]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 42",
                "opening_hours": "Su-Th 10:00-23:00, Fr-Sa 10:00-04:00",
                "website": "http:\/\/carlsjr.ru\/adresa\/spb\/30264\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.279423, 59.941993]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85к2",
                "opening_hours": "Mo-Su 07:00-22:00",
                "website": "http:\/\/carlsjr.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.151252, 59.993266]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "08:00-23:00",
                "website": "http:\/\/carlsjr.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": "http:\/\/carlsjr.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пражская улица, 48\/50",
                "opening_hours": "http:\/\/carlsjr.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404074, 59.863328]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "http:\/\/carlsjr.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "10:00-23:00",
                "website": "http:\/\/carlsjr.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Carl's Jr.",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Domino's Pizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 111",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416201, 60.029755]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.hesburger.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 47",
                "opening_hours": "Su-Th 08:00-04:00; Fr-Sa 08:00-06:00",
                "website": "https:\/\/www.hesburger.ru\/260?tid=1227"
            },
            "geometry": {"type": "Point", "coordinates": [30.347902, 59.937991]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.hesburger.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 10",
                "opening_hours": "Su-Th 08:00-23:00; Fr-Sa 08:00-04:00",
                "website": "https:\/\/www.hesburger.ru\/260?tid=1232"
            },
            "geometry": {"type": "Point", "coordinates": [30.359715, 59.947343]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.hesburger.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/www.hesburger.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hot Dogs",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 16",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360964, 59.929601]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "HotDogger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": "https:\/\/hdogger.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Jawsspot",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 11",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355502, 59.936814]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 15",
                "opening_hours": "08:00-02:00",
                "website": "https:\/\/www.kfc.ru\/restaurants\/260"
            },
            "geometry": {"type": "Point", "coordinates": [30.309077, 59.916275]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Большая Разночинная, 16 лит А",
                "opening_hours": "Mo-Th 08:00-22:00; Fr 08:00-23:00; Sa 09:00-23:00; Su 09:00-22:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.295324, 59.96357]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 19к2",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.308358, 60.057379]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 3к1",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320665, 60.038281]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 23",
                "opening_hours": "Mo-Su 08:00-23:30",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.256831, 60.001743]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 16\/71",
                "opening_hours": "Mo-Fr 08:00-22:30; Sa-Su 09:00-22:30",
                "website": "https:\/\/www.kfc.ru\/restaurants\/516"
            },
            "geometry": {"type": "Point", "coordinates": [30.3147, 59.933541]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 2",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru\/restaurants\/683"
            },
            "geometry": {"type": "Point", "coordinates": [30.346788, 59.927608]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 77",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.442944, 59.934912]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Зольная, 11",
                "opening_hours": "Mo-Su 07:00-23:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.419821, 59.913713]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 37Д",
                "opening_hours": "06:00-03:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.311332, 59.966629]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 26",
                "opening_hours": "Mo-Su 07:00-05:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358619, 59.944134]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 1к1",
                "opening_hours": "http:\/\/www.kfc.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367638, 59.986695]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 128к2",
                "opening_hours": "Mo-Su 07:30-00:30",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.268419, 59.852425]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "08:00-23:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 48",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.358125, 59.925484]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 8",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.337787, 59.934619]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4",
                "opening_hours": "06:00-03:00",
                "website": "https:\/\/www.kfc.ru\/restaurants\/750"
            },
            "geometry": {"type": "Point", "coordinates": [30.317961, 59.925385]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 96",
                "opening_hours": "08:00-07:00",
                "website": "https:\/\/www.kfc.ru\/restaurants\/189"
            },
            "geometry": {"type": "Point", "coordinates": [30.354568, 59.932112]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 75к1",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.243805, 60.039791]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Прибрежная улица, 18",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.50544, 59.832082]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 30к1Б",
                "opening_hours": "http:\/\/www.kfc.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.498684, 59.946031]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 21Ак1",
                "opening_hours": "Mo-Su 8:00-02:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.375831, 60.045093]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 41",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/www.kfc.ru\/restaurants\/878"
            },
            "geometry": {"type": "Point", "coordinates": [30.389656, 60.053224]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 7А",
                "opening_hours": "Mo-Su 08:00-00:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367934, 60.037252]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 35к1",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224482, 59.855877]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 147Б",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.251028, 59.832213]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 15к1",
                "opening_hours": "08:00-23:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.379352, 60.016159]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 87к1",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414521, 60.036097]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 90",
                "opening_hours": "http:\/\/www.kfc.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261116, 59.867918]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 21к2",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319183, 59.823702]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Софийская улица, 89",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.431176, 59.844059]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 36\/40",
                "opening_hours": "06:00-04:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.27592, 59.942101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 43-45Б",
                "opening_hours": "07:30-23:00",
                "website": "https:\/\/www.kfc.ru\/restaurants\/1125"
            },
            "geometry": {"type": "Point", "coordinates": [30.274456, 59.901842]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 2к1",
                "opening_hours": "08:00-23:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.125425, 59.772704]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Бадаева, 2",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.454811, 59.916622]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.379325, 59.870014]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "http:\/\/www.kfc.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 45",
                "opening_hours": "09:00-23:00",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.35349, 59.955674]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": "https:\/\/www.kfc.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 33",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.233223, 59.948532]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119к3",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.226099, 59.988946]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Типанова, 24",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349501, 59.8521]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Фучика, 6к3А",
                "opening_hours": "Mo-Su 07:00-00:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.370261, 59.878857]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 6",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.390365, 59.833325]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Центральный район, Кирпичный переулок, 2",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.315464, 59.936111]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 9",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.kfc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.3208, 59.809174]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 19к2",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308358, 60.057379]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Народная улица, 100",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.490007, 59.88558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 7А",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367934, 60.037252]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 21к2",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319183, 59.823702]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Софийская улица, 89",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.431176, 59.844059]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Жукова, 2А",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.406688, 59.961097]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Фучика, 6к3А",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.370261, 59.878857]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Экспресс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 21Ак1",
                "opening_hours": "http:\/\/www.kfc.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375831, 60.045093]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Auto",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 77",
                "opening_hours": "Mo-Su 05:00-03:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.442944, 59.934912]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Express",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 26",
                "opening_hours": "Mo-Su 07:00-05:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358619, 59.944134]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Express",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 1к1",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367638, 59.986695]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Lucky Pizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 2-я Красноармейская улица, 3",
                "opening_hours": "11:00-23:00",
                "website": "http:\/\/www.luckypizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316245, 59.915494]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "MaX",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Политехническая улица",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Max Burger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263011, 59.879651]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "McDonald's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Думская улица, 4",
                "opening_hours": "Mo-Su 00:00-04:00, 05:00-24:00",
                "website": "https:\/\/mcdonalds.ru\/restaurants\/670"
            },
            "geometry": {"type": "Point", "coordinates": [30.328328, 59.933113]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Meat Up Burgers",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Д",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240715, 59.924934]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ollis",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Искровский проспект, 22",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468959, 59.905619]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Papa Grill",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Papa John's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 21",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": "https:\/\/www.papajohns.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.256301, 60.00002]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Papa John's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 47",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-03:00",
                "website": "https:\/\/spb.papajohns.ru\/adresa\/spb-10"
            },
            "geometry": {"type": "Point", "coordinates": [30.359688, 59.929736]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Papa John's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Науки, 25",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406176, 60.009728]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Papa John's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Транспортный переулок, 1",
                "opening_hours": "https:\/\/www.papajohns.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.920668]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Papa John's",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 22к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394363, 59.850816]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Peperoni",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 22",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312239, 60.015152]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "PitaGrill",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 87к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343087, 59.835559]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza 22 cm",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 2",
                "opening_hours": "http:\/\/pizza22cm.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.939555]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Cafe Bistro",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312643, 59.936787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 165",
                "opening_hours": "Mo-Th 10:00-00:00; Fr 10:00-01:00; Sa 11:00-01:00; Su 11:00-00:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32, 59.866499]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 96",
                "opening_hours": "09:30-23:00",
                "website": "http:\/\/pizzahut.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354568, 59.932112]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 19А",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/spb.pizzahut.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.373019, 60.043031]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 40\/36",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.276118, 59.941971]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 86",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.305412, 59.852506]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 2-4Б",
                "opening_hours": "09:00-22:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350103, 59.927685]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 71",
                "opening_hours": "Mo-Th 12:00-20:00; Fr-Su 11:00-22:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315266, 59.933383]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ропшинская улица, 32",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "https:\/\/spb.pizzahut.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.291066, 59.960206]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Олеко Дундича, 17к1",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.399124, 59.827514]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza LLIS",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 61\/28",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306894, 59.954448]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Milano",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 40\/68",
                "opening_hours": "Mo-Su 12:00+",
                "website": "http:\/\/www.pizzamilanobar.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344623, 59.933708]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Ollis",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Прибрежная улица, 18",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.ollis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.50544, 59.832082]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Ollis",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 69",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395773, 60.039786]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Ollis",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 39",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396213, 59.837978]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Pasta",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Pazza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Короленко, 3",
                "opening_hours": "Su-Th 13:00-23:00, Fr-Sa 13:00-01:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350489, 59.939428]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Roni",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Крыленко, 39\/29",
                "opening_hours": "http:\/\/pizzaroni.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.487895, 59.899554]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Roni",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Костюшко, 68",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309346, 59.844958]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Smile",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Новоколомяжский проспект, 8а",
                "opening_hours": "Mo-Sa 08:00-00:00; Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295449, 60.028465]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Time",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Туристская улица, 30к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.21028, 60.005036]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza-море",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 17",
                "opening_hours": "https:\/\/pizzamore.ru\/k",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359077, 59.943706]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza's Park",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "opening_hours": "Mo-Th 11:00-01:00;Fr-Sa 11:00-02:00;Su 11:00-00:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "PIZZARONI",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 104к1",
                "opening_hours": "Mo-Fr 11:00-23:00",
                "website": "http:\/\/baltpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.411449, 60.027939]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "opening_hours": "Mo-Su 09:00-21:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 12",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 59.86631]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 64",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264224, 59.932933]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 50",
                "opening_hours": "09:00-23:00",
                "website": "https:\/\/www.subway.ru\/restaurants\/northwest\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.302501, 59.960174]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 98",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312868, 59.966475]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 62",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "website": "https:\/\/www.subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.338461, 59.975432]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 74к3",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381625, 59.871346]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 16",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449528, 59.909878]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "09:00-22:00",
                "website": "https:\/\/subway.ru\/stores\/detail\/52292\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 19",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.413793, 59.929082]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 8",
                "opening_hours": "https:\/\/www.subway.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349393, 59.833918]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 7\/2",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.288973, 59.943323]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 16",
                "opening_hours": "https:\/\/www.subway.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360964, 59.929601]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 84\/2Б",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.355807, 59.921137]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 17",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-21:00",
                "website": "https:\/\/www.subway.ru\/restaurants\/northwest\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.329442, 59.957994]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, ​Московский проспект, 109 к2",
                "opening_hours": "9:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317871, 59.890112]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 145",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319021, 59.877353]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 197",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.848665]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 31",
                "opening_hours": "https:\/\/subway.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31779, 59.914371]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Московский проспект, 70",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319012, 59.906892]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Мучной переулок, 7",
                "opening_hours": "11:00-22:00",
                "website": "http:\/\/sabaysabay.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323144, 59.929687]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Невский проспект, 20",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.subway-n20.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321222, 59.936309]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 14-16",
                "opening_hours": "24\/7",
                "website": "http:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315051, 59.929524]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 40",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425157, 59.985025]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 15",
                "opening_hours": "Mo-Su 09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422085, 59.896228]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "09:00-22:00",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 86к1",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.418941, 60.036214]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 9",
                "opening_hours": "https:\/\/www.subway.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364081, 60.000263]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 4-6",
                "opening_hours": "09:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296384, 59.913735]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сытнинская площадь, 4Б",
                "opening_hours": "09:00-22:00",
                "website": "http:\/\/subway.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.310002, 59.957395]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314224, 60.0174]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Белинского, 1",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344345, 59.937941]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Блохина, 33",
                "opening_hours": "24\/7",
                "website": "https:\/\/www.subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.291838, 59.952759]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Восстания, 11",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360416, 59.933686]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 36\/1",
                "opening_hours": "24\/7",
                "website": "http:\/\/subway.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.292593, 59.925493]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "https:\/\/www.subway.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Марата 26",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.345593, 59.921498]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 23",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/stores\/detail\/57848\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347049, 59.942683]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 118",
                "opening_hours": "https:\/\/subway.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211987, 59.987181]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 63",
                "opening_hours": "Sa 10:00-21:00; Su 11:00-20:00; Mo-Fr 08:00-21:00",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.361565, 59.946238]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 23к3",
                "opening_hours": "11:00-00:00",
                "website": "https:\/\/telepizza-russia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.313281, 59.866463]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Искровский проспект, 40",
                "opening_hours": "10:00-23:00",
                "website": "https:\/\/telepizza-russia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.477789, 59.895217]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 51к1",
                "opening_hours": "11:00-00:00",
                "website": "https:\/\/telepizza-russia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.238577, 60.031]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 82к1",
                "opening_hours": "Mo-Su 11:00-00:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196257, 59.858367]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 7",
                "opening_hours": "11:00-24:00",
                "website": "https:\/\/telepizza-russia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.290715, 60.010731]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 25",
                "opening_hours": "11:00-24:00",
                "website": "https:\/\/telepizza-russia.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.339036, 60.051329]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Telepizza",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 27к3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228013, 59.84329]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, бульвар Трудящихся, 18к4Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.582018, 59.735466]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, ​Новодеревенская, 8а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430847, 59.721782]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Радищева, 9\/17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407639, 59.708951]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Коммунаров, 6",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.956814, 60.093564]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Блины и пирожки",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кронштадт, Флотская улица, 27",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.769522, 59.99798]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Октябрьская улица, 8",
                "opening_hours": "Fr 10:00-03:00;Mo,We 10:00-23:00;Sa 09:00-03:00;Tu,Th,Su 09:00-23:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.622957, 59.738879]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 503к1",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.260352, 60.099086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317018, 59.819775]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.270531, 59.800256]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "website": "https:\/\/burgerking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.273167, 59.799682]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бургер Кинг",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 251",
                "opening_hours": "24\/7",
                "website": "burgerking.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.969563, 60.071314]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Газпромнефть",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кронштадт, Кронштадтское шоссе, 15А",
                "opening_hours": "24\/7",
                "website": "www.gpnbonus.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.737099, 60.008595]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Вокзальная улица, 7",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.703733, 60.205771]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Тверская улица, 34",
                "opening_hours": "Mo-Su 08:00-24:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.61068, 59.740899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Чичеринская улица, 2",
                "opening_hours": "09:00-23:00",
                "website": "https:\/\/dodopizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.847843, 59.870379]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Крошка картошка",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 17к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316843, 59.829023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Октябрьская улица, 8",
                "opening_hours": "Mo-Su 00:00-05:00,06:00-24:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.621298, 59.739495]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 36",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.582787, 59.740924]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 214к4",
                "opening_hours": "Mo-Su 06:00-04:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.275799, 60.074359]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 35",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321009, 59.807418]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Московское шоссе, 16к2",
                "opening_hours": "Mo-Su 00:00-05:00,06:00-24:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.400057, 59.799986]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Таллинское шоссе, 161",
                "opening_hours": "Mo-Su 06:00-05:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.16226, 59.805684]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МакАвто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 40к2Б",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-24:00",
                "website": "https:\/\/mcdonalds.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.143338, 59.787895]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Октябрьская улица, 8",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.621427, 59.739525]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 36",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.583101, 59.740924]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 214к4",
                "opening_hours": "07:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.275723, 60.074392]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "10:00-22:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.316176, 59.820736]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 35",
                "opening_hours": "07:00-23:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.321009, 59.807325]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "opening_hours": "Mo-Su 00:00-01:00, 05:00-24:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.268291, 59.798732]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "Mo-Su 05:00-02:00",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.271795, 59.799547]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Московское шоссе, 16к2",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.399965, 59.799942]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, территория Славянка, Ростовская улица, 20",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472764, 59.742764]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Таллинское шоссе, 161",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.162376, 59.805772]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Макдоналдс",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 40к2Б",
                "opening_hours": "07:00-23:30",
                "website": "https:\/\/mcdonalds.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.143291, 59.78791]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МегаБургер",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 102к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.089155, 59.734766]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мегабургер",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 13\/52",
                "opening_hours": "24\/7",
                "website": "https:\/\/megaburger.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.406471, 59.717691]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мегабургер",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Привокзальная площадь, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429104, 59.723184]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Роснефть",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 2к4",
                "opening_hours": "24\/7",
                "website": "www.rosneft.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.113887, 59.761575]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Роснефть",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 1к2",
                "opening_hours": "24\/7",
                "website": "www.rosneft.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.117648, 59.768308]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Смайл",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Приморское шоссе, 516А",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": "сушисмайл.рф"
            },
            "geometry": {"type": "Point", "coordinates": [29.725999, 60.18947]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Смак",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339146, 60.074905]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Старый бар",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Привокзальная площадь, 3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.927441, 59.864336]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335469, 60.067153]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 17к2",
                "opening_hours": "Mo-Su 08:30-23:00",
                "website": "http:\/\/teremok.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.317059, 59.826916]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "Mo-Su 10:00-09:00",
                "website": "https:\/\/www.teremok.ru\/places\/r_aeroport\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.27279, 59.799267]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, территория Славянка, Ростовская улица, 20",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472387, 59.743272]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Drive",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Витебский проспект, 138",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394459, 59.787774]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Drive",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Московское шоссе, 295",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.481406, 59.758239]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Drive",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Московское шоссе, 296",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479212, 59.756852]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Drive Cafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 264",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.958206, 60.081316]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "DriveCafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Сапёрный, Петрозаводское шоссе, 52",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.701819, 59.773423]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "DriveCafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 21к2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319249, 59.82367]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "DriveCafe",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Витебский проспект, 145",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390131, 59.796746]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316171, 59.820657]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Hesburger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 2к1",
                "opening_hours": "Mo-Th 09:00-21:00; Fr-Sa 10:00-21:00; Su 09:00-21:00",
                "website": "https:\/\/www.hesburger.ru\/260?tid=1418"
            },
            "geometry": {"type": "Point", "coordinates": [30.125312, 59.772699]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, бульвар Трудящихся, 12",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.571814, 59.7367]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Красное Село, Театральная улица, 6Б",
                "opening_hours": "08:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.081696, 59.7234]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Красное Село, Театральная улица, 6Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.081642, 59.723462]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 503к1",
                "opening_hours": "http:\/\/www.kfc.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260223, 60.098189]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338648, 60.066362]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 21к2",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319226, 59.823819]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, территория Московская Славянка, 17А",
                "opening_hours": "Mo-Su 08:00-00:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.49638, 59.747844]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 264А",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.957326, 60.082474]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 9",
                "opening_hours": "24\/7",
                "website": "http:\/\/www.kfc.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320847, 59.809147]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33874, 60.066296]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 21к2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319028, 59.823852]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, территория Московская Славянка, 17А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496303, 59.747809]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "KFC Авто",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 264А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.957195, 60.082403]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "L’Americano",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.891668, 59.881256]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Neo Burger",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ломоносов, Александровская улица, 32Б",
                "opening_hours": "24\/7",
                "website": "https:\/\/m.vk.com\/neoburger"
            },
            "geometry": {"type": "Point", "coordinates": [29.775633, 59.910814]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "PanAsiaDa",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 25Б",
                "opening_hours": "Mo-Sa 11:00-19:00; Su 11:00-18:00",
                "website": "https:\/\/vk.com\/astreetfood"
            },
            "geometry": {"type": "Point", "coordinates": [30.405535, 59.719166]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Parkfood",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Петергоф, ​Морского Десанта, 1в",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.905477, 59.885124]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 36",
                "opening_hours": "Mo-Su 10:00-22:30",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.584725, 59.740726]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 36",
                "opening_hours": "Mo-Su 10:00-22:30",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.584087, 59.740739]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 23к1",
                "opening_hours": "Fr-Sa 11:00-23:59;Mo-Th,Su 11:00-23:00",
                "website": "https:\/\/spb.pizzahut.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.3447, 60.081144]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pizza Hut",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 23к1",
                "opening_hours": "https:\/\/spb.pizzahut.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343798, 60.081013]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 16",
                "opening_hours": "24\/7",
                "website": "https:\/\/subway.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.766279, 59.99652]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Пулковское шоссе, 60к1Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329717, 59.793888]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Take Eat",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Ломоносов, Ораниенбаумский проспект, 39Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773566, 59.896202]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Uno momento",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Генерала Хазова, 8",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403751, 59.738509]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Wake up!",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 13\/52",
                "opening_hours": "http:\/\/wakeup.city\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40698, 59.718102]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Wild Bean",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Московское шоссе, 90",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440214, 59.778203]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "WildBean",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, Кпосёлок Петро-Славянка, Софийская улица, 108к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459241, 59.813695]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "WildBean",
                "city_function": "Питание",
                "type": "фастфуд",
                "address": "Россия, Санкт-Петербург, посёлок Петро-Славянка, Софийская улица, 141",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472973, 59.80494]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "#Я люблю шаверму",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Славы, 5к3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371231, 59.854314]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "А ля Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Московский район, Авиационная улица, 15",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325381, 59.855276]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "А-ля",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Орджоникидзе, 58к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365653, 59.844682]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Али-Баба",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 26А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297192, 60.00415]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 43",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347866, 59.939113]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Марата, 86",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339072, 59.918886]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "АрмКебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Олеко Дундича, 21 лит А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410874, 59.830997]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Армянская шаверма на тандыре",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Земский переулок, 8В",
                "opening_hours": "10:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289898, 60.025363]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Баня",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 46",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353561, 59.921529]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро 24 - Doner Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Маршала Захарова, 42",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.19871, 59.852845]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 26к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34633, 59.985651]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Народная улица, 16",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464566, 59.881033]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 16В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468878, 59.904099]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бистро-шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 26А",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408889, 60.043184]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Блины Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 225",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468052, 59.865054]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Брынза кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Малый проспект П.С., 57",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "В лаваше",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 7",
                "opening_hours": "Su-Th 09:00-01:00, Fr-Sa 09:00-03:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324699, 59.936746]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкус кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Большевиков проспект, 24а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.487689, 59.901251]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 98",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218536, 59.85383]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Искровский проспект, 7",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459563, 59.912391]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Клинский проспект, 22",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322785, 59.914624]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Маршала Казакова, 35л",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 34к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306166, 59.857979]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пражская улица, 26к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392, 59.872656]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Просвещения проспект, 74 к2г",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386134, 60.041853]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 42",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354505, 59.852389]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Наставников, 18",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489045, 59.943278]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 16",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309957, 59.958052]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 18к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388856, 59.849401]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 12к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457802, 59.917493]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 32к1Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488363, 59.924654]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 25к2",
                "opening_hours": "Mo-Su 09:00-21:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.231435, 59.844298]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Лётчика Пилютова, 11к4",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.139367, 59.839764]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24871, 59.860093]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "вкусная шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 22",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.177563, 59.829427]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 29Ак1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.491318, 59.916302]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Рихарда Зорге, 20",
                "opening_hours": "Mo-Su 07:00-05:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.193347, 59.850929]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Тельмана, 56\/41",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489261, 59.893055]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 9к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38865, 59.831399]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Шлиссельбургский проспект, 26к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.512698, 59.835053]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вулкан, ООО, шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Трефолева, 23",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268383, 59.891561]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Гриль кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 17",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267386, 59.848696]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Гурмэ Шаурмэ",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 72к1",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197919, 60.005931]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дамаск кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дамаск кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 9к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29659, 60.003574]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Два вертела",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 11Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367934, 59.86879]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дом шавермы",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 23",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349726, 59.946356]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дом Шавермы",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 11",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352115, 59.93575]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Дом шавермы",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Рылеева, 5",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352672, 59.942399]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Донер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 43",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276028, 59.945707]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Донер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 12",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 59.86631]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Донер City",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 53",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359338, 59.929136]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро Донер",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Богатырский проспект, 1а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281391, 60.001581]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 30\/16",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308816, 59.911444]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 54\/32с3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2109, 60.006008]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гренадерская улица, 22",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347839, 59.970272]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 25к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453328, 59.904058]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 9к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436278, 59.937761]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро Кебаб 24",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Муринская дорога, 84",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449519, 60.036969]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро-Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Славы, 12",
                "opening_hours": "Mo-Su 05:00-04:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378463, 59.856451]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ЕвроКебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 71",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221608, 60.041099]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ЕвроКебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Среднерогатская улица, 20",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3382, 59.820282]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Еврокебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Олеко Дундича, 10к2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392683, 59.828532]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Египетская шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 17",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423944, 59.895687]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 41",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352421, 59.921579]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб Араб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 6",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446672, 59.875817]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб сити",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, переулок Матюшенко, 8",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442647, 59.876639]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб тайм",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Боткинская, 3Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380152, 59.968606]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб хауз",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Боткинская, 3Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35039, 59.958512]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб Хаус",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 38",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279918, 59.941556]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб Хаус",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 4В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294695, 60.002985]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кебаб-микс",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Невский проспект, 124",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364916, 59.930742]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Кинг Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Камышовая улица, 13",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248494, 60.006188]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "КручуВерчу",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Спасский переулок, 14\/35",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319461, 59.928131]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Ливанская шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 45\/34",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276872, 59.942038]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Лучшая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 22к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37442, 60.046998]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Лучшая Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Руднева, 1к2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360658, 60.03851]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Н",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377313, 59.829088]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 3к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35463, 59.958169]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 79к9",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247794, 59.842436]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 43",
                "opening_hours": "http:\/\/masterkebab.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321456, 59.928523]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 72А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398055, 60.013924]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Московский проспект, 103к8",
                "opening_hours": "Mo-Sa 07:00-06:00, Su 08:00-07:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317773, 59.892265]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 55",
                "opening_hours": "http:\/\/masterkebab.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303929, 59.986511]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 19",
                "opening_hours": "http:\/\/masterkebab.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480691, 59.90765]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 2к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471681, 59.919567]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 84к1",
                "opening_hours": "Mo-Su 08:00-02:00",
                "website": "http:\/\/masterkebab.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.415572, 60.036852]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "09:00-08:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 34",
                "opening_hours": "http:\/\/masterkebab.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.232873, 59.947946]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 16",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347049, 59.971614]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "МастерА Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Мастеркебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 75к5",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260119, 59.867191]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Микс Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Липовая аллея, 14а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Настоящая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325049, 59.929118]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Паб-шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 54",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329064, 59.924086]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Пышки, Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 33",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376468, 59.859181]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "РашЛаваш",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 16Ж",
                "opening_hours": "Mo-Su 10:30-21:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470253, 59.904162]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сирийская шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 10\/53",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292826, 59.922675]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Сочная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Софийская улица, 41к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403454, 59.872932]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Султан Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 20",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342279, 59.925858]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Супер Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Художников",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351136, 60.044846]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Твоя любимая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 79",
                "opening_hours": "https:\/\/www.yourfavoriteshawarma.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.924997]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Твоя любимая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Новаторов бульвар, 112 к1",
                "opening_hours": "11:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259418, 59.842061]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Твоя шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Заставская улица, 38",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320692, 59.888627]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Турбо Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 53",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347704, 59.936332]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Турецкий донер-кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кронштадтская улица, 11",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255456, 59.868988]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "У Захара Шаверма, жареный картофель",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Новочеркасский проспект, 39\/2",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хорошая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Альпийский переулок, 30а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381616, 59.853406]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хорошая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 102",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409661, 60.02637]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хорошая шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Есенина 30Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Хорошая Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Энгельса проспект, 133а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаваролл экспресс",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, площадь Балтийского вокзала, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299456, 59.907207]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверам Делюкс",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 56а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337607, 59.839113]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Александра Матросова, 12",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338003, 59.979961]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Орджоникидзе, 22",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329055, 59.844746]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Турку",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386107, 59.868035]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Уткин проспект, 13к15",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437904, 59.932054]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Типанова",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343123, 59.852524]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345683, 60.000821]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 2-я Красноармейская улица, 9\/3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313506, 59.915427]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Английский проспект, 41",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288281, 59.91899]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Благодатная улица",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325525, 59.876048]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 8",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29447, 59.95985]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 11",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368563, 59.869065]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 19к1Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372363, 59.864611]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 87к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400507, 59.834022]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, бульвар Красных Зорь",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.441659, 59.870881]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 98В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253184, 59.841346]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Бухарестская, 31к1в",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373549, 59.861869]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 14Д",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240293, 59.931084]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 72А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398055, 60.013924]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 57к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467836, 59.883133]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Заставская улица, 13Э",
                "opening_hours": "Mo-Su 08:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328705, 59.888948]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Земский переулок, 8Г",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289952, 60.025511]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 25",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435048, 59.872936]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 21",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335505, 59.936039]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 40а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384571, 59.968543]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кронштадтская улица, 11",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255456, 59.868988]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ленская улица, 17к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.494085, 59.939749]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 57-59",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.928438]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Лиственная улица, 18к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335784, 60.028096]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 60",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34845, 59.933785]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 64\/78",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348423, 59.933099]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Магнитогорская улица",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425058, 59.939203]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Малая Балканская, 38а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389296, 59.836057]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 13к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327259, 59.957205]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Московский проспект, 197А",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320863, 59.848045]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4АБ",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319398, 59.924659]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Московский проспект, 54",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.910167]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 53к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303355, 59.986988]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Наличная улица, 25\/84",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234562, 59.937184]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Народная улица, 87А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.487186, 59.884492]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 40в",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432532, 59.990746]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 46Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426406, 59.988455]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 20",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365222, 60.000272]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Приморский проспект",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245009, 59.983711]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Просвещения проспект, 87а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410676, 60.036362]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 115",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.180627, 59.83357]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 148к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.156058, 59.834185]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 160",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144281, 59.834962]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 168",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140912, 59.834989]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 53",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23855, 59.840826]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Проспект Ветеранов, 89б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216865, 59.831304]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Героев, 27к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.159067, 59.861137]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Елизарова",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418186, 59.894572]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 46Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251773, 60.022472]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496789, 59.945644]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 43а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373594, 60.034506]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Науки",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401945, 60.011959]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Науки, 36",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419938, 60.008306]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Науки, 38",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421034, 60.007708]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 3А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329001, 60.053885]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 45Б",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366587, 60.046104]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Славы, 16",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382074, 59.857206]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Славы, 58к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413057, 59.865533]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Солидарности, 9к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.490725, 59.921191]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274231, 59.898896]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 101к1Е",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246554, 59.859158]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 105к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240562, 59.855182]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 98к4",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265886, 59.867873]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 11",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329936, 59.999404]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 12",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328265, 60.000519]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27Т",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 60.005324]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436817, 59.952101]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ржевская площадь, 11",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510479, 59.981213]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 54Д\/1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394165, 60.034951]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Смоленская улица, 6",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.904017]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, территория Сергиево, улица Свердлова, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.093248, 59.833429]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, территория Старая Деревня",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 26к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409616, 60.043179]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 6е",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 111Б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.452978, 59.864435]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 75",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444103, 59.875989]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Ворошилова, 31к4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463578, 59.932901]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Генерала Симоняка, 10",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205474, 59.827926]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Костюшко, 68",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309346, 59.844958]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Котина, 7",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215436, 59.859465]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Лётчика Пилютова, 5а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140265, 59.83541]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1Г",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247677, 59.860247]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Политрука Пасечника, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.104108, 59.780124]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Седова, 99к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44351, 59.86532]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Симонова, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323441, 60.056175]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Стахановцев, 8",
                "opening_hours": "Mo-Su 08:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407838, 59.927617]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Типанова, 5",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327537, 59.853713]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 6",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296941, 59.907663]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 41\/39",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439773, 59.959683]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Южное шоссе",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414674, 59.863003]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ярослава Гашека, 12а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Яхтенная улица, 4к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма \"У Салама\"",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 51к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389575, 59.973325]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма 24",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372309, 59.845541]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Алладин",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 8к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355178, 59.956832]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма быстро и вкусно",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 86",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260406, 59.869643]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Вкусно & Быстро",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 149к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29924, 59.851313]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Дон Педро",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 17",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347156, 59.928771]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма от Шеф-Повара",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Демьяна Бедного, 26а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386134, 60.041853]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма по Питерски",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 45",
                "opening_hours": "Mo-Su 11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152851, 59.848303]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма по турецки",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Херсонская улица, 31",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381616, 59.927892]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Фалафель",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 11",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424519, 59.896896]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Хаус",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 50",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315859, 59.927991]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма хаус",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 26\/16",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411916, 59.929826]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма экспресс",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 32",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338676, 59.924438]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма City",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 18",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339458, 59.927405]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Mix",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Садовая улица, 48",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313847, 59.924857]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Mix",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Pro",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма Stop",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 11к2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392153, 59.832638]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма-сити",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ул. Правды, 12\/12, этаж цокольный",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342584, 59.923103]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма.ru",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 18",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28422, 59.939063]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма+",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Восстания, 36",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360353, 59.941294]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "шавермания",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 7к8",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284292, 59.99998]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаврема",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 33А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355017, 60.006656]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шавуха",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 6",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356436, 59.955737]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Автовская улица, 3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266326, 59.866603]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 16",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365689, 59.835727]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 45",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312616, 59.956922]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Невский проспект, 3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312643, 59.936787]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 24",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316829, 59.927797]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Садовая улица, 34",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323037, 59.928758]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Жени Егоровой, 12А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313613, 60.064681]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 12к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 59.878793]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурма'с",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гренадерская улица, 20",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347785, 59.970686]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаурменная",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Васенко, 3 к4",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388039, 59.970546]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахерезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахерезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахерезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.007668]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахерезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Моховая улица, 31",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344848, 59.940898]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахерезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахерезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": "10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахеризада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Энтузиастов",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480125, 59.948924]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шахрезада",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шашлык кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 15\/29",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294066, 59.962404]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 19А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48202, 59.906937]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 14",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327034, 59.95553]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 46",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314422, 59.866477]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Волковский проспект, 30",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357496, 59.896657]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 90",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261116, 59.867918]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 54",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275327, 59.901409]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф-Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Московский проспект",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320072, 59.885426]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф-кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шеф-шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 43-45",
                "opening_hours": "10:00-23:00",
                "website": "http:\/\/chefshaurma.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.377439, 59.939474]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "BBQ Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 11-я линия Васильевского острова, 12",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278345, 59.937103]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "BBQ Shaverma",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 57",
                "opening_hours": "11:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358349, 59.946261]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Black Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Проспект Энгельса, 128б",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner & Мясо",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 5В",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314548, 59.969547]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner City",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 4",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318779, 59.926359]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 65",
                "opening_hours": "24\/7",
                "website": "http:\/\/m.vk.com\/chef_donerkebab"
            },
            "geometry": {"type": "Point", "coordinates": [30.338497, 59.864205]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 32к1А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398226, 59.828527]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Московский проспект, 220а",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 18к1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388856, 59.849401]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Donner City",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 17",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339647, 59.925218]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "I love kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 21",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26797, 59.87089]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Kavkaz-House",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 76к1",
                "opening_hours": "Mo-Su 09:00-00:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.187885, 59.860184]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Kebab шеф",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 6",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318994, 59.957678]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Kebab burger",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, ​Просвещения проспект, 36 к1",
                "opening_hours": "06:00-21:30",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369183, 60.045736]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Kebab Undeground",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Lavaш",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Щербаков переулок, 12",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345063, 59.928095]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "lavAzza",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 14",
                "opening_hours": "08:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422642, 59.895777]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pita's",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 32",
                "opening_hours": "Mo-Su 10:00-23:00",
                "website": "http:\/\/www.pitas.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.929434]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pita's",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "08:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "PITA'S",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 10",
                "opening_hours": "10:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312661, 59.967687]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Pita's",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Невский проспект, 65",
                "opening_hours": "Mo-Th, Su 11:00-23:00; Fr, Sa 11:00-01:00",
                "website": "http:\/\/www.pitas.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353391, 59.931724]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ShavaTime",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 38к5",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375876, 60.022904]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Shawarma Mix",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Невский проспект, 124",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364916, 59.930742]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Shawarma mix",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263011, 59.879651]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Shawerma Room 99",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 27",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279927, 59.942376]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Turkish Döner",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Невский проспект, 120",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363667, 59.930895]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Turkish Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Финский переулок, 9",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354594, 59.956431]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Колпино, улица Ижорского Батальона, 13",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.617849, 59.736286]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Вкусная Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Слуцкая улица, 3",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430528, 59.691035]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Влаваше",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 23к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329965, 60.069469]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 21к1",
                "opening_hours": "Mo-Su 00:00-04:00,05:00-23:59",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343872, 60.07957]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Евро Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Новгородский проспект, 6",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367635, 59.810353]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "ЕвроКебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Новгородский проспект, 6",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367716, 59.81051]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Киоск по продаже шавермы и самсы",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пушкин, ​Железнодорожная, 81е",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420684, 59.739607]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Паб Кебаб",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 102к1",
                "opening_hours": "Mo-Su 09:00-23:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.089217, 59.734951]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Зеленогорск, ​Вокзальная, 9 лит В",
                "opening_hours": "Mo-Su 08:00-21:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.701591, 60.206353]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Колпино, проспект Ленина, 72",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.563421, 59.749693]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Красное Село, Красногородская улица, 17",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.076182, 59.718196]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 85",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.08327, 59.729028]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Кронштадт, Макаровская улица, 2Щ",
                "opening_hours": "Mo-Su 10:00-22:00",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.771313, 59.985805]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Молодёжное, Приморское шоссе, 661",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.518577, 60.19335]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Победы, 36А",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.772067, 59.896706]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 88",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.033567, 59.852877]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, ​1 Мая, 107\/5",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241124, 60.0719]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 4к3",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388921, 59.706404]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 21",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408781, 59.71939]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пушкин, ​Парковая, 26а",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402729, 59.710479]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Пушкин, Привокзальная площадь, 1к3В",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428589, 59.72418]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, улица Политрука Пасечника, 15",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.104104, 59.780052]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "шаверма 24",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Красное Село, Стрельнинское шоссе, 4к1",
                "opening_hours": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.091005, 59.745347]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Doner Kebab",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Петергоф, улица Халтурина, 1",
                "opening_hours": "24\/7",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.864096, 59.880749]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Giros",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Заречная улица, 33",
                "opening_hours": "Mo-Su 08:00-22:00",
                "website": "https:\/\/giros-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.332491, 60.080238]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "VЛАVАШЕ",
                "city_function": "Питание",
                "type": "шаверма",
                "address": "Россия, Санкт-Петербург, Петергоф, ​Санкт-Петербургский проспект, 60 лит Ц",
                "opening_hours": "24\/7",
                "website": "https:\/\/vlavashe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.894142, 59.881409]}
        }
    ]
}
