export const zoos = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Спасский переулок. 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Вот Енот",
                "note": null,
                "contact_phone": "7(812) 429-56-11",
                "contact_website": null,
                "price": "500-1000",
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": "yes",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.317674, 59.928848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. набережная реки Фонтанки. 80\/2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Добрый енот",
                "note": null,
                "contact_phone": "7(812) 929-25-15",
                "contact_website": null,
                "price": "500",
                "opening_hours": "Mo-Su 12:00-22:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.333017, 59.926796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Кронверкский проспект. 27Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Друг енот",
                "note": null,
                "contact_phone": "7-964-333-99-89",
                "contact_website": null,
                "price": "425-4500",
                "opening_hours": "Mo-Su 11:30-20:00",
                "wheelchair": "yes",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.317656, 59.957543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Садовая улица. 24",
                "city_function": "Культура, развлечения, творчество",
                "name": "Енотовиль",
                "note": null,
                "contact_phone": "7-921-402-23-13",
                "contact_website": "enotovil.ru\/",
                "price": null,
                "opening_hours": "11:00-20:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.332676, 59.932716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Ново-Александровская улица. 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "ЕнотоДрайв",
                "note": "0 этаж",
                "contact_phone": "7(812) 926-50-79",
                "contact_website": "enotodrive.ru",
                "price": "0-300",
                "opening_hours": "Mo-Su 11:00-21:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.464656, 59.865488]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. проспект Народного Ополчения. 247к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Зоомирок",
                "note": "2 этаж",
                "contact_phone": "7(812) 642-64-12",
                "contact_website": "vk.com\/priut_polanka",
                "price": "0-200",
                "opening_hours": "Mo-Su 15:00-19:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.144874, 59.826343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Елагин остров. 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Зоопарк",
                "note": null,
                "contact_phone": "7(812) 430-09-11",
                "contact_website": "elaginpark.org\/detyam\/mini-zoopark\/",
                "price": "0",
                "opening_hours": "Mo-Su 06:00-23:00",
                "wheelchair": "yes",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Южная дорога. 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Зоопарк им. Чебурашки",
                "note": null,
                "contact_phone": "-",
                "contact_website": "k-f.ru",
                "price": "0",
                "opening_hours": "Mo-Su 09:00-21:00",
                "wheelchair": "yes",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.232522, 59.969993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Большая Морская улица. 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Контактный зоопарк",
                "note": null,
                "contact_phone": "7(812) 938-55-06",
                "contact_website": "bm5spb.ru\/ru\/spb\/contactzoo.net",
                "price": "350-2000",
                "opening_hours": "Mo-Su 11:00-00:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.317287, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Бассейная улица. 41",
                "city_function": "Культура, развлечения, творчество",
                "name": "Крошка Енот",
                "note": null,
                "contact_phone": "7-911-813-84-77",
                "contact_website": "-",
                "price": "500",
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": "-",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.32195, 59.864652]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. проспект Космонавтов. 36",
                "city_function": "Культура, развлечения, творчество",
                "name": "Крошка Енот",
                "note": null,
                "contact_phone": "7-911-813-84-77",
                "contact_website": "-",
                "price": "500",
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": "-",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.352376, 59.856361]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Александровский парк. 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ленинградский зоопарк",
                "note": "Ленинградский зоопарк",
                "contact_phone": "7(812) 232-82-60",
                "contact_website": "www.spbzoo.ru",
                "price": null,
                "opening_hours": "10:00-18:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.308259, 59.951997]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Тележная улица. 13к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ми-ми-ми",
                "note": null,
                "contact_phone": null,
                "contact_website": "vk.com\/public134128227",
                "price": null,
                "opening_hours": "Mo-Su 10:00-23:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.373881, 59.92612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Московский проспект. 201",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ми-ми-ми",
                "note": null,
                "contact_phone": "7-931-366-09-46",
                "contact_website": "vk.com\/public134128227",
                "price": null,
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.319246, 59.847544]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Крестовский проспект. 19Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мини-зоопарк",
                "note": "Эколого-биологический центр",
                "contact_phone": "\n+7 812 320-79-78",
                "contact_website": "kidsreview.ru\/spb\/catalog\/zoopark-imeni-cheburashki-v-restorane-karl-i-fridrikh-krestovskii-ostrov-spb",
                "price": null,
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.252636, 59.970128]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. проспект Энегельса. 154",
                "city_function": "Культура, развлечения, творчество",
                "name": "Пушистые друзья",
                "note": "ТЦ Гранд Каньон (3 этаж)",
                "contact_phone": "7-921-898-98-20",
                "contact_website": null,
                "price": "0-350",
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": "yes",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Большая Морская улица. 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Сад бабочек",
                "note": null,
                "contact_phone": "7(812) 938-55-06",
                "contact_website": "bm5spb.ru\/ru\/spb\/tropicalbutterfly.ru",
                "price": "-",
                "opening_hours": "Mo-Su 11:00-00:00",
                "wheelchair": "no",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.317287, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Бухарестская улица. 30",
                "city_function": "Культура, развлечения, творчество",
                "name": "Трогательный зоопарк",
                "note": "Континент (3 этаж)",
                "contact_phone": "7(812) 984-45-06",
                "contact_website": "open-zoo.ru\/",
                "price": "200-300",
                "opening_hours": "10:00-22:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.36956, 59.883611]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Брантовская дорога. 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Трогательный зоопарк",
                "note": "ТЦ Охта Молл (3 этаж)",
                "contact_phone": "7-911-928-45-95",
                "contact_website": "open-zoo.ru",
                "price": "0-250",
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": "-",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.418366, 59.940145]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. проспект Энегельса. 154",
                "city_function": "Культура, развлечения, творчество",
                "name": "Экзоменю",
                "note": "ТЦ Гранд Каньон (3 этаж)",
                "contact_phone": "8(800) 550-19-87",
                "contact_website": "www.exomenu.ru",
                "price": "200-300",
                "opening_hours": "Mo-Su 10:00-20:00",
                "wheelchair": "yes",
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. улица Фучика. 2А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Экзоопарк",
                "note": "ТЦ РИО (1 этаж)",
                "contact_phone": "7-965-797-82-31",
                "contact_website": null,
                "price": "250-50",
                "opening_hours": "Mo-Su 10:00-22:00",
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.356894, 59.876003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. Планерная, 59",
                "city_function": "Культура, развлечения, творчество",
                "name": "Лесное посольство",
                "note": "ТРЦ LeoMall",
                "contact_phone": null,
                "contact_website": null,
                "price": null,
                "opening_hours": null,
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [30.225424, 60.021971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. ​Санкт-Петербургское шоссе, 111 к13",
                "city_function": "Культура, развлечения, творчество",
                "name": "Скотный двор",
                "note": null,
                "contact_phone": null,
                "contact_website": null,
                "price": null,
                "opening_hours": null,
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [29.982942, 59.867113]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия. Санкт-Петербург. ​Приморское шоссе, 536з",
                "city_function": "Культура, развлечения, творчество",
                "name": "Радуга",
                "note": null,
                "contact_phone": null,
                "contact_website": null,
                "price": null,
                "opening_hours": null,
                "wheelchair": null,
                "type": "Зоопарк"
            },
            "geometry": {"type": "Point", "coordinates": [29.698752, 60.188945]}
        }
    ]
}
