export const monuments = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Александровская колонна",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Дворцовая площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.315836, 59.939052]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сторожевые львы на Адмиралтейской набережной",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Адмиралтейская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.30534, 59.938153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Николаю I",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Исаакиевская площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.308424, 59.93208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Медный всадник",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Сенатская площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.302219, 59.936385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятный знак \"Стрелка Васильевского острова\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Биржевая площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.30707, 59.944222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник первой линии Петербургского трамвая",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Адмиралтейский проспект"
            },
            "geometry": {"type": "Point", "coordinates": [30.310431, 59.936728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Львы Ши-Цза на Петровской набережной",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Петровская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.331591, 59.952627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ростральная колонна (северная)",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Биржевая площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.305003, 59.944646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ростральная колонна (южная)",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Биржевая площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.30695, 59.943064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Трезини",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Университетская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.288131, 59.936782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Сахарову",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, площадь Академика Сахарова"
            },
            "geometry": {"type": "Point", "coordinates": [30.297284, 59.943394]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник «Царь-плотник»",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Адмиралтейская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.307616, 59.939227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Ломоносову",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Университетская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.301635, 59.940538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обелиск Румянцева",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Румянцевская площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.292582, 59.938438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сфинксы на Университетской набережной",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Университетская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.291249, 59.937101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Пушкину",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, площадь Искусств"
            },
            "geometry": {"type": "Point", "coordinates": [30.331697, 59.937258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Екатерине II",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, площадь Островского"
            },
            "geometry": {"type": "Point", "coordinates": [30.337027, 59.93334]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник коту Елисею и кошке Василисе",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Малая Садовая улица"
            },
            "geometry": {"type": "Point", "coordinates": [30.337711, 59.934398]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Барклаю-де-Толли",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Казанская площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.324252, 59.935228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Анне Ахматовой",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, улица Восстания"
            },
            "geometry": {"type": "Point", "coordinates": [30.359826, 59.934398]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Скульптурная группа \"Октябрь\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Лиговский проспект"
            },
            "geometry": {"type": "Point", "coordinates": [30.365003, 59.935895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чижик-Пыжик",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, набережная реки Фонтанки"
            },
            "geometry": {"type": "Point", "coordinates": [30.338037, 59.941702]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колокол Мира",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, парк Академика Сахарова"
            },
            "geometry": {"type": "Point", "coordinates": [30.415118, 59.979367]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник фонарщику",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Одесская улица"
            },
            "geometry": {"type": "Point", "coordinates": [30.387409, 59.945827]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербургский ангел",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Измайловский сад"
            },
            "geometry": {"type": "Point", "coordinates": [30.313025, 59.919603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник кораблю \"Полтава\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Воскресенская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.359464, 59.949851]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Остапу Бендеру",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Итальянская улица д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.328617, 59.93709]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Муму",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Садовая улица, д. 94"
            },
            "geometry": {"type": "Point", "coordinates": [30.290894, 59.91822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник бравому солдату Швейку",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Балканская площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.377572, 59.830397]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Петру I",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Кронштадт, Петровский парк"
            },
            "geometry": {"type": "Point", "coordinates": [29.768857, 59.987052]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник котлу",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Кронштадт, Безымянный переулок"
            },
            "geometry": {"type": "Point", "coordinates": [29.765004, 59.99478]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Святые благоверные Петр и Феврония Муромские",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, улица Чайковского, д. 22"
            },
            "geometry": {"type": "Point", "coordinates": [30.381524, 60.00691]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник \"Кошка Тишина Матроскина\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, улица Марата, д. 34"
            },
            "geometry": {"type": "Point", "coordinates": [30.352365, 59.925914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пристань со сфинксами на Свердловской набережной",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Свердловская набережная, д. 40"
            },
            "geometry": {"type": "Point", "coordinates": [30.399837, 59.958261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Человеку-невидимке",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, набережная реки Фонтанки, д. 132"
            },
            "geometry": {"type": "Point", "coordinates": [30.302293, 59.917566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Зайчику, спасшемуся от наводнения",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Петропавловская крепость"
            },
            "geometry": {"type": "Point", "coordinates": [30.32277, 59.952412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник фотографу",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Малая Садовая улица, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.337362, 59.934472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник конке",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, 6-7-я линия В.О."
            },
            "geometry": {"type": "Point", "coordinates": [30.278934, 59.942859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник городовому",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Шведский переулок"
            },
            "geometry": {"type": "Point", "coordinates": [30.326101, 59.938746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник водовозу",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Шпалерная улица, д. 56"
            },
            "geometry": {"type": "Point", "coordinates": [30.376094, 59.949358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник пушкарю Василию",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, 7-я линия В.О., д. 34"
            },
            "geometry": {"type": "Point", "coordinates": [30.280636, 59.941124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Гаврюше",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, улица Правды, д. 13"
            },
            "geometry": {"type": "Point", "coordinates": [30.340628, 59.922491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фонтан \"Четыре сфинкса\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Пулковское шоссе, д. 74"
            },
            "geometry": {"type": "Point", "coordinates": [30.325496, 59.779023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник жертвам политических репрессий",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Воскресенская набережная"
            },
            "geometry": {"type": "Point", "coordinates": [30.363572, 59.949874]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятнику Петру I",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Кленовая улица"
            },
            "geometry": {"type": "Point", "coordinates": [30.338604, 59.939213]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Александру III",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Миллионная улица, д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.327473, 59.945366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Кутузову",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Казанская площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.325959, 59.934964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник К.Э. Циолковскому",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, улица Циолковского, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.288223, 59.909619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник И. А. Крылову",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Летний сад"
            },
            "geometry": {"type": "Point", "coordinates": [30.335042, 59.946225]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Петру I",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Петропавловская крепость"
            },
            "geometry": {"type": "Point", "coordinates": [30.317896, 59.950143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колонна \"Конец света\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Павловск, Павловский парк"
            },
            "geometry": {"type": "Point", "coordinates": [30.464757, 59.696053]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник Петру I",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Малый Сампсониевский проспект, д. 34"
            },
            "geometry": {"type": "Point", "coordinates": [30.341698, 59.968374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обелиск в честь 300-летия учреждения Ордена Святого Апостола Андрея Первозванного",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, 6-я линия В.О., д. 11"
            },
            "geometry": {"type": "Point", "coordinates": [30.283046, 59.939444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник \"Братство по оружию\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Московский парк Победы"
            },
            "geometry": {"type": "Point", "coordinates": [30.322704, 59.870448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Памятник \"Памяти моряков полярных конвоев 1941-1945 годов\"",
                "city_function": "Достопримечательности",
                "type": "Памятник",
                "address": "г. Санкт-Петербург, Большой Смоленский проспект, д. 36"
            },
            "geometry": {"type": "Point", "coordinates": [30.410034, 59.89551]}
        }
    ]
}
