export const architectural_structures = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Зимний дворец",
                "name_2": "Эрмитаж",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Дворцовая площадь, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.314564, 59.939872]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Большой петергофский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Петергоф, Разводная улица, д.2"
            },
            "geometry": {"type": "Point", "coordinates": [29.908795, 59.884576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мариинский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Исаакиевская площадь, д. 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.309779, 59.930641]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Юсуповский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Мойки, д. 94"
            },
            "geometry": {"type": "Point", "coordinates": [30.298991, 59.929486]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мраморный дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Миллионная улица, д. 5А"
            },
            "geometry": {"type": "Point", "coordinates": [30.326576, 59.944867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Таврический дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Шпалерная улица, д. 47"
            },
            "geometry": {"type": "Point", "coordinates": [30.375981, 59.947933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Меншиковский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Университетская набережная, д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.296029, 59.938783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летний дворец Петра I",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Летний сад, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.336108, 59.947219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Александровский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Дворцовая улица, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.392686, 59.720945]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зимний дворец Петра I",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Дворцовая набережная, д. 32"
            },
            "geometry": {"type": "Point", "coordinates": [30.317627, 59.942698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Михайловский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Инженерная улица, д. 2-4А"
            },
            "geometry": {"type": "Point", "coordinates": [30.332415, 59.938844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Михайловский замок",
                "name_2": "Инженерный замок",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Садовая улица, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.337622, 59.940738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Строгановский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Мойки, д. 46"
            },
            "geometry": {"type": "Point", "coordinates": [30.320244, 59.935771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аничков дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 39"
            },
            "geometry": {"type": "Point", "coordinates": [30.339772, 59.932976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Елагин дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Елагин остров, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.270033, 59.97927]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец Белосельских-Белозерских",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 41"
            },
            "geometry": {"type": "Point", "coordinates": [30.344785, 59.932712]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каменноостровский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Малой Невки, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.304566, 59.979893]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чесменский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, улица Гастелло, д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.327809, 59.857566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Николаевский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, площадь Труда, д. 4В"
            },
            "geometry": {"type": "Point", "coordinates": [30.293058, 59.93243]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Екатерининский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Садовая улица, д. 9"
            },
            "geometry": {"type": "Point", "coordinates": [30.395635, 59.715995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шереметевский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Фонтанки, д. 34"
            },
            "geometry": {"type": "Point", "coordinates": [30.345462, 59.936614]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец государя молдавского Дмитрия Кантемира",
                "name_2": "Дом Громова",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Миллионная улица, д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.32577, 59.944619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Воронцовский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Садовая улица, д. 26Д"
            },
            "geometry": {"type": "Point", "coordinates": [30.33172, 59.931447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец Разумовского",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Мойки, д. 48-50-52"
            },
            "geometry": {"type": "Point", "coordinates": [30.32012, 59.934608]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец Петра II",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Университетская набережная, д. 7-9-11В"
            },
            "geometry": {"type": "Point", "coordinates": [30.299328, 59.939844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Румянцева",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Английская набережная, д. 44"
            },
            "geometry": {"type": "Point", "coordinates": [30.289448, 59.932788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец великого князя Алексея Александровича",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Мойки, д. 122"
            },
            "geometry": {"type": "Point", "coordinates": [30.283525, 59.927908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец великого князя Николая Николаевича",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Петровская набережная, д. 2 к. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.32974, 59.952727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец Нарышкиных-Шуваловых",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Фонтанки, д. 21"
            },
            "geometry": {"type": "Point", "coordinates": [30.343086, 59.934854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Константиновский дворец",
                "name_2": "Дворец конгрессов",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Стрельна, улица Глинки, д. 6 стр. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.064532, 59.856749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Трубецких-Нарышкиных",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, улица Чайковского, д. 29"
            },
            "geometry": {"type": "Point", "coordinates": [30.352285, 59.946312]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Итальянский дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Кронштадт, Макаровская улица, д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [29.764147, 59.989136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Усадьба княгини Е. Р. Воронцовой-Дашковой",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, проспект Стачек, д. 45"
            },
            "geometry": {"type": "Point", "coordinates": [30.265384, 59.885918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Усадьба Александрино",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, проспект Стачек, д. 226"
            },
            "geometry": {"type": "Point", "coordinates": [30.220329, 59.845175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец Петра I",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Стрельна, Больничная горка, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.044817, 59.853726]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Людвига Нобеля",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Пироговская набережная, д. 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.338193, 59.965594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Павильон \"Зал на острову\"",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Екатерининский парк"
            },
            "geometry": {"type": "Point", "coordinates": [30.395468, 59.710816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Д. Б. Нейдгарта",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Захарьевская улица, д. 31"
            },
            "geometry": {"type": "Point", "coordinates": [30.361264, 59.947504]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Литейный дом княгини Юсуповой",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Литейный проспект, д. 42"
            },
            "geometry": {"type": "Point", "coordinates": [30.348654, 59.938253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк А. А. Половцова",
                "name_2": "Дом архитектора",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Большая Морская улица, 52"
            },
            "geometry": {"type": "Point", "coordinates": [30.304608, 59.93143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание главного Адмиралтейства",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Адмиралтейский проезд, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.308523, 59.937525]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Главного штаба",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Дворцовая площадь, д. 6-8"
            },
            "geometry": {"type": "Point", "coordinates": [30.317897, 59.938786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Сената и Синода",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Сенатская площадь, 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.301071, 59.935872]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Биржи",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Биржевая площадь, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.305158, 59.943645]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание двенадцати коллегий",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Меделеевская линия, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.299583, 59.941499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Большой Гостиный двор",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 35"
            },
            "geometry": {"type": "Point", "coordinates": [30.333377, 59.934069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Санкт-Петербургской академии художеств имени Ильи Репина",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Университетская набережная, д. 17"
            },
            "geometry": {"type": "Point", "coordinates": [30.290476, 59.937371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом графини А. Г. Лаваль",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Английская набережная, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.299382, 59.935782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Конногвардейский Манеж",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Исаакиевская площадь, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.302814, 59.934098]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Штаба Гвардейского корпуса",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Дворцовая площадь, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.317175, 59.940208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русский торгово-промышленный банк",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Большая Морская улица, д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.315137, 59.935193]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом компании \"Зингер\"",
                "name_2": "Дом книги",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 28"
            },
            "geometry": {"type": "Point", "coordinates": [30.325866, 59.935924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом компании \"Эсдерс и Схейфальс\"",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Мойки, д. 73"
            },
            "geometry": {"type": "Point", "coordinates": [30.314464, 59.933423]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петропавловская крепость",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Петропавловская крепость, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.31748, 59.950184]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом Раскольникова",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Гражданская улица, д. 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.310898, 59.927768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый дом \"Пассаж\"",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 48"
            },
            "geometry": {"type": "Point", "coordinates": [30.334447, 59.93526]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом К. Г. Фаберже",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Большая Морская улица, д. 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.314599, 59.93424]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк П. Н. Демидова",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Большая Морская улица, д. 43"
            },
            "geometry": {"type": "Point", "coordinates": [30.305861, 59.932142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом композиторов",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Большая Морская улица, д. 45"
            },
            "geometry": {"type": "Point", "coordinates": [30.305433, 59.932001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Северный пакгауз Биржи",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная Макарова, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.304035, 59.944242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Южный пакгауз Биржи",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Биржевая площадь, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.305657, 59.94282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Литературный дом",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 68А"
            },
            "geometry": {"type": "Point", "coordinates": [30.344897, 59.93333]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Павильон Росси",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Инженерная улица, д. 2-4Б"
            },
            "geometry": {"type": "Point", "coordinates": [30.334007, 59.940844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Главная придворная аптека",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Миллионная улица, д. 4\/1"
            },
            "geometry": {"type": "Point", "coordinates": [30.325523, 59.943997]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Российской национальной библиотеки",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Садовая улица, д. 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.334225, 59.933491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание городской думы",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Думская улица, д. 1-3"
            },
            "geometry": {"type": "Point", "coordinates": [30.32902, 59.934268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Санкт-Петербургского горного университета",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная Лейтенанта Шмидта, д. 49"
            },
            "geometry": {"type": "Point", "coordinates": [30.269517, 59.929234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание института физиологии им. И. П. Павлова",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Тифлисская улица, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.299819, 59.94506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Библиотека Российской академии наук",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Биржевая линия, д. 1\/1"
            },
            "geometry": {"type": "Point", "coordinates": [30.296796, 59.943782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Российской академии наук",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Университетская набережная, д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.302888, 59.940898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Манеж Первого кадетского корпуса",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Университетская набережная, д. 13"
            },
            "geometry": {"type": "Point", "coordinates": [30.29783, 59.939777]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тенишевское коммерческое училище",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Моховая улица, д. 33-35"
            },
            "geometry": {"type": "Point", "coordinates": [30.34477, 59.940583]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Обуховской больницы",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Загородный проспект, д. 47"
            },
            "geometry": {"type": "Point", "coordinates": [30.321291, 59.921741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк В. С. Кочубея",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Фурштатская улица, д. 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.355418, 59.945476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Н. В. Спиридонова",
                "name_2": "Дворец торжественной регистрации рождений \"Малютка\"",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Фурштатская улица, д. 58"
            },
            "geometry": {"type": "Point", "coordinates": [30.366162, 59.945384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Малый Мраморный дворец",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Гагаринская улица, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.341387, 59.947854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Санкт-Петербургского государственного экономического университета",
                "name_2": "Здание бывшего Ассигнационного банка",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная канала Грибоедова, д. 30-32"
            },
            "geometry": {"type": "Point", "coordinates": [30.325644, 59.931824]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Доходный дом Н. П. Басина",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, переулок Крылова, д. 7\/5"
            },
            "geometry": {"type": "Point", "coordinates": [30.335106, 59.932112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Главное здание Мариинской больницы",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Литейный проспект, д. 56"
            },
            "geometry": {"type": "Point", "coordinates": [30.349148, 59.935119]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нахимовское военно-морское училище",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Петроградская набережная, д. 2-4"
            },
            "geometry": {"type": "Point", "coordinates": [30.336201, 59.955689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Технологического института",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Московский проспект, д. 24-26\/49"
            },
            "geometry": {"type": "Point", "coordinates": [30.318921, 59.917574]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральная городская публичная библиотека имени В. В. Маяковского",
                "name_2": "Особняк графини Н. Ф. Карловой",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Фонтанки, д. 46"
            },
            "geometry": {"type": "Point", "coordinates": [30.343387, 59.931867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киностудия \"Ленфильм\"",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Каменноостровский проспект, д. 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.316627, 59.958249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом Яковлевых (Евментьева А.Ф.)",
                "name_2": "Дом с ротондой",
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Гороховая улица, д. 57"
            },
            "geometry": {"type": "Point", "coordinates": [30.325873, 59.925639]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здание Санкт-Петербургского общества взаимного кредита",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, набережная канала Грибоедова, д. 13"
            },
            "geometry": {"type": "Point", "coordinates": [30.326268, 59.93705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Ю. К. Добберт",
                "name_2": null,
                "city_function": "Достопримечательности",
                "type": "Архитектурное сооружение",
                "address": "г. Санкт-Петербург, Большая Пушкарская улица, д. 14, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.299303, 59.95638]}
        }
    ]
}
