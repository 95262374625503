//https://t.me/elemnt3 Y:2021
import React, {useState, useEffect} from 'react';
import {Column} from '@ant-design/charts';
import ENTITIESCOLUMN from '../../../assets/get_column.json'

function EntitiesColumn() {
    const [data, setData] = useState([]);
    useEffect(() => {
        asyncFetch();
    }, []);
    const asyncFetch = () => {
        setData(ENTITIESCOLUMN)
    };
    let config = {
        data: data,
        xField: 'norm',
        yField: 'count',
        xAxis: {label: {autoRotate: true}},
        scrollbar: {type: 'horizontal'},
        color: '#ffa726',
        columnBackground: {
            style: {
                fill: '#ffe0b2',
                fillOpacity: 0.1,
            }
        }
    };
    return (
        <Column {...config} />
    )

}

export default EntitiesColumn;

