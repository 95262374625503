export const restaurants = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "#стейк давай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 30к1",
                "opening_hours": "Su-Th 12:00-24:00; Fr-Sa 12:00-03:00",
                "contact_phone": "http:\/\/steakdavai.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.387419, 59.858349]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#стейкдавай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 56",
                "opening_hours": "24\/7",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303507, 59.960773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1001 ночь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 21",
                "opening_hours": "https:\/\/www.1001night.org\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322228, 59.943142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1010 Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Конюшенная улица, 39",
                "opening_hours": "Mo-Fr 07:00-23:00, Su-Sa 10:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415778, 59.72137]}
        },
        {"type": "Feature",
            "properties": {
                "name": "18\/53",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 67\/22В",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357837, 59.926896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1913 год",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 2",
                "opening_hours": "Su-Th 12:00-24:00, Fr-Sa 12:00-01:00",
                "contact_phone": "http:\/\/www.restaurant-1913.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.30772, 59.929069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "48 стульев",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 5",
                "opening_hours": "Mo-Su 00:00-02:00,12:00-24:00",
                "contact_phone": "https:\/\/48chairs.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345189, 59.931576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "78RUS",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Афонская улица, 25",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 964 3747878",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.303489, 60.026145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Абажур кафе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 30",
                "opening_hours": "Mo-Su 13:00-23:00",
                "contact_phone": "+7 812 4090039",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.277555, 59.9407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Абрикосовъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 40-42",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/www.abrikosov-spb.com\/restaurant\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.332316, 59.934943]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Адам и Ева",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 217",
                "opening_hours": "+7 812 921-61-11",
                "contact_phone": "https:\/\/adam-eva.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.466147, 59.866644]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аджабсандал",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Белинского, 6",
                "opening_hours": "http:\/\/аджабсандал.рф\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346941, 59.938405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аджика",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 33к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344533, 60.050273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Адмиралтейство",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица,7",
                "opening_hours": "+7 812 4653549",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398957, 59.710432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Азалия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311565, 59.924515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Акварель",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, ​Добролюбова проспект, 14а​2",
                "opening_hours": "Mo-Sa 17:00-06:00; Su 14:00-06:00",
                "contact_phone": "7‒931‒532‒65‒88",
                "website": "banketnaneve.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.298881, 59.951267]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Акио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Российский проспект, 14",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472355, 59.929682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Акрополь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вербная улица, 8к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280852, 60.023776]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аладастури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 10",
                "opening_hours": "11:00+",
                "contact_phone": "http:\/\/aladasturi.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.323639, 59.937815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аладастури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 42\/24",
                "opening_hours": "Mo-Su 00:00-02:00,12:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20807, 60.000438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алекс Хаус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 44",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.908194, 59.879534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Али",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 71",
                "opening_hours": "http:\/\/www.asteria.ru\/restaurant\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329199, 59.926548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алиот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Возрождения, 4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267907, 59.882939]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аллегро",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 55",
                "opening_hours": "Mo-Su,PH 11:30-22:30 open; 22:30-23:30 open \"bar only\"",
                "contact_phone": "+7 812 3159445;+7 964 3847170",
                "website": "http:\/\/allegrorest.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315536, 59.929425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алтамареа",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "Mo-Su 11:00-02:00",
                "contact_phone": "+7 812 3133355",
                "website": "http:\/\/www.altamarea.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аль-Шарк",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.893124, 59.881987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Альбион Диккенс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 108",
                "opening_hours": "http:\/\/dickensrest.ru\/restaurant",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318922, 59.921416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амарант",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Циолковского, 13-15",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-05:00",
                "contact_phone": "http:\/\/www.amarantspb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.287176, 59.909576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Американский Бар и Гриль",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "opening_hours": "24\/7",
                "contact_phone": "+7 911 9100558",
                "website": "http:\/\/www.rosinter.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амроц",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 176",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9076100",
                "website": "http:\/\/www.amrots.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.38096, 59.925696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амроц",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Передовиков, 27",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461763, 59.949411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амулет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 6",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282029, 59.852859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амулет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 35",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/www.amuletresto.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360883, 59.939077]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Анданте",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Мытнинская набережная, 3",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306301, 59.948771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ани",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Софийская улица, 39к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401936, 59.873202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антикафе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Пятилеток, 13к1",
                "opening_hours": "15:00-06:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.454298, 59.924303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Априори",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 261",
                "opening_hours": "+7 812 3626308;+7 812 9670687",
                "contact_phone": "https:\/\/apriori-resto.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.478984, 59.858516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Апшерон",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 39",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/apsheron-kafe.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.310227, 59.9291]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арагви",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 9",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 5705643",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.342692, 59.93708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арбат",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Артиллерийская улица, 4",
                "opening_hours": "07:30-24:00",
                "contact_phone": "http:\/\/arbat-nord.ru\/restoran-gostinicy\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.351612, 59.941971]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арена",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вязовая улица, 10",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273611, 59.96716]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арису",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 20-22",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374304, 59.936746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 27",
                "opening_hours": "09:00-06:00",
                "contact_phone": "http:\/\/arka.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322363, 59.936999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Асахи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 206",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322291, 59.857301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ассамблея",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 1",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/www.oktober-hotel.spb.ru\/oktober.nsf\/ru\/assambleya",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.363416, 59.93323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Атлантис",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное, Петровская улица, 2к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.92953, 60.144403]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Атриум-Холл",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Английская набережная, 70",
                "opening_hours": "https:\/\/atriumhall.ru\/atrium\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28422, 59.931513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аура",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 43к4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388919, 59.861318]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ачарули",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Малая улица, 27\/12",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405851, 59.717602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аragosta",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Достоевского",
                "opening_hours": "http:\/\/www.aragosta.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34774, 59.92373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабагануш",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 19",
                "opening_hours": "Su-Th 10:00-22:00; Fr-Sa 10:00-23:00",
                "contact_phone": "+79119284241",
                "website": "https:\/\/www.restoclub.ru\/spb\/place\/babaganush"
            },
            "geometry": {"type": "Point", "coordinates": [30.360604, 59.935412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баварский",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 34",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.910422, 59.879374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Багатель",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 1",
                "opening_hours": "+7 812 3143627",
                "contact_phone": "http:\/\/www.cafebagatelle.spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.324151, 59.941029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Багратион Холл",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 148",
                "opening_hours": "Mo-Th 12:00-23:00; Fr-Su 12:00-00:00",
                "contact_phone": "+7 812 2411752",
                "website": "http:\/\/bagration-holl.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.293895, 59.852434]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баден Баден",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 61",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389153, 60.041202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бакенбарды",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица, 4",
                "opening_hours": "http:\/\/bakenbards.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397716, 59.717368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бакинский бульвар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курская улица, 24",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351091, 59.9118]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бакинский дворик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 52",
                "opening_hours": "http:\/\/bakinskiydvorik.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328615, 59.924379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баку",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 23",
                "opening_hours": "http:\/\/restoran-baku.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336404, 59.935926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Балканский гурман",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 3-я линия Васильевского острова, 24",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284715, 59.943264]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Балканский гурман",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 14А",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 3138272",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317611, 59.962115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Балтия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 5к1",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": "https:\/\/www.baltiyahotel.ru\/restoran.html",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.339179, 59.970186]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бальзак",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 25",
                "opening_hours": "Mo-Su,PH 11:00-23:30",
                "contact_phone": "+7 812 2759089",
                "website": "http:\/\/restoran.balzak.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354603, 59.935728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бальмонт",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Александра Невского, 2Е",
                "opening_hours": "+7 812 6271602",
                "contact_phone": "https:\/\/rest-balmont.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.38697, 59.924794]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банкет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40287, 59.915197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банкетный зал \"Зеркальный\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 81к2",
                "opening_hours": "+7 921 7980089",
                "contact_phone": "banketvip.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.303373, 59.855575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банкетный зал \"Небесный\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 60",
                "opening_hours": "+7 812 3896714",
                "contact_phone": "https:\/\/zalnebesniy.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.408664, 59.954701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банкетный зал «Елагин»",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дибуновская улица, 37",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 9030756",
                "website": "http:\/\/www.9619965.ru\/yelagin"
            },
            "geometry": {"type": "Point", "coordinates": [30.269147, 59.985512]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банкетный зал Marry Me",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Морская набережная, 29",
                "opening_hours": "https:\/\/vk.com\/marrymehall",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.21558, 59.958453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банкетный комплекс \"Звезда\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 49к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30366, 59.859461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банщики",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дегтярная улица, 1А",
                "opening_hours": "Su-Tu 11:00-23:00; We-Th 11:00-00:00; Fr-Sa 11:00-01:00",
                "contact_phone": "+7 921 9411744",
                "website": "http:\/\/www.banshiki.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.370198, 59.930313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барбазан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 49\/2",
                "opening_hours": "https:\/\/www.radissonblu.com\/ru\/hotel-stpetersburg\/restaurants",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348558, 59.932324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барбария",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 55",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 7647333",
                "website": "http:\/\/www.barbaria.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.348082, 59.922445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барклай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Моховая улица, 12",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345539, 59.944634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барселона",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Думская улица, 1-3",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328975, 59.934199]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бастион",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово, Приморское шоссе, 494",
                "opening_hours": "+7 812 9325733",
                "contact_phone": "http:\/\/restoran-bastion.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.768288, 60.181061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Батат",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337394, 60.066825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 30",
                "opening_hours": "Su-Th 11:00-01:00; Fr-Sa 11:00-03:00",
                "contact_phone": "+7 812 6127556",
                "website": "https:\/\/www.bahroma1.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.297785, 59.957246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.820992, 60.168183]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 7к2",
                "opening_hours": "+7 812 2075887;+7 812 6777888",
                "contact_phone": "https:\/\/www.bahroma1.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.471357, 59.915463]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 48",
                "opening_hours": "12:00-03:00",
                "contact_phone": "+7 812 6777888",
                "website": "https:\/\/www.bahroma1.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353831, 60.049761]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Сизова, 9",
                "opening_hours": "Mo-Su 12:00-03:00",
                "contact_phone": "+7 812 6123137",
                "website": "https:\/\/www.bahroma1.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.273584, 60.004348]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 33",
                "opening_hours": "Mo-Su 12:00-03:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388245, 59.857468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахрома №1",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Доблести, 35к1",
                "opening_hours": "+7 812 6122492",
                "contact_phone": "https:\/\/www.bahroma1.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.174824, 59.84899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баязет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 112",
                "opening_hours": "Su-We 11:00-23:00, Th-Sa 11:00-02:00",
                "contact_phone": "https:\/\/bayazetspb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.315122, 59.920429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Безумный Макс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 12",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 9009012",
                "website": "http:\/\/mad-m.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336116, 59.935534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белая лошадь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 21",
                "opening_hours": "Mo-Fr 12:00-00:00; Sa-Su 06:00-00:00",
                "contact_phone": "+7 931 5923747",
                "website": "http:\/\/whitehorsse.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.378669, 59.855331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Рыбацкая улица, 2",
                "opening_hours": "Mo-Th 09:00-23:00; Fr 09:00-01:00; Sa 10:00-01:00; Su 10:00-23:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/belkarest"
            },
            "geometry": {"type": "Point", "coordinates": [30.298153, 59.957935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Беринг",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пироговская набережная, 5\/2",
                "opening_hours": "07:00-01:00",
                "contact_phone": "+7 812 6330486",
                "website": "http:\/\/bering-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341353, 59.956989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бермудский треугольник",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 53",
                "opening_hours": "+7 812 6076344",
                "contact_phone": "http:\/\/btbeer.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.140203, 59.84904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Библиотека вкусов",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 31",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 2441594",
                "website": "http:\/\/ilovenevsky.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321914, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бивень",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 10к1",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 812 9643100",
                "website": "https:\/\/bivenbar.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316335, 59.974707]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бивон",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 45",
                "opening_hours": "10:00-22:50",
                "contact_phone": "+7 981 8939928",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.307361, 59.928054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Биография-бар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ломоносов, Манежная улица, 8",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.765229, 59.912952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бирштубе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 166",
                "opening_hours": "http:\/\/www.courtyardstpetersburgpushkin.ru\/hotel-bierstube-restaurant",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28458, 59.918963]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бирюза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 6",
                "opening_hours": "12:00-23:00",
                "contact_phone": "https:\/\/www.banketnij-zal-biryuza.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.357936, 59.930173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Благодать",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, ​Петровская набережная, 2а",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330645, 59.962233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ближние Дубки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коннолахтинский проспект, 1",
                "opening_hours": "+7 812 3099188",
                "contact_phone": "http:\/\/spbdubki.ru\/restoran.html",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.132747, 59.996173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Блок",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Потёмкинская улица, 4",
                "opening_hours": "00:00-01:00,12:00-24:00",
                "contact_phone": "+7 812 4154040",
                "website": "http:\/\/blok.restaurant\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.368788, 59.945815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Большая черепаха",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Карла Либкнехта, 29",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.77889, 59.99423]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Большой Колонный Зал",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20",
                "opening_hours": "+7 812 9570019",
                "contact_phone": "http:\/\/www.palace-rest.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.453679, 59.685297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Борщ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 1",
                "opening_hours": "+7 (812) 318-73-99",
                "contact_phone": "http:\/\/borsch.gutsait.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.427475, 59.723855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Борщ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 20",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40887, 59.719086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Борщ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 53",
                "opening_hours": "http:\/\/borsch.gutsait.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41522, 59.724466]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ботаника",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 7",
                "opening_hours": "11:00-00:00",
                "contact_phone": "+7 812 2727091",
                "website": "http:\/\/botanika.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341308, 59.942196]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ботанический сад",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 2П",
                "opening_hours": "11:00-18:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322857, 59.971253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Братья Карамазовы",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 11АВ",
                "opening_hours": "07:30-23:30",
                "contact_phone": "http:\/\/karamazovhotel.ru\/restaurant\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.341982, 59.922422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бриз",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 270",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.955731, 60.086065]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бричмула",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 13к1",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/brichmula"
            },
            "geometry": {"type": "Point", "coordinates": [30.259984, 60.008878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бруно Иванович",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 1-я Утиная улица, 32",
                "opening_hours": "+7 812 3016666",
                "contact_phone": "http:\/\/www.bruno-restoran.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.297192, 60.034713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, улица Морского Десанта, 3",
                "opening_hours": "10:00-20:00",
                "contact_phone": "+7 812 9444490",
                "website": "https:\/\/cafebrynza.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.903841, 59.885131]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брют",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Крапивный переулок, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337266, 59.96952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бульвар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 12к2",
                "opening_hours": "https:\/\/www.bul-var.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239044, 59.859167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буржуй",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Ш",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471456, 59.865686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буфетъ на Благодатной",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 49",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa,Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328876, 59.875641]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Българ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Театральная площадь, 8",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": "https:\/\/www.blgar.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.300103, 59.925376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "В Гараже",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 35к1",
                "opening_hours": "Mo-Th 12:00-00:00; Fr-Su 12:00-02:00",
                "contact_phone": "+7 911 9263561",
                "website": "http:\/\/welovegarage.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.241928, 60.003858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "В двух шагах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 21к1",
                "opening_hours": "11:00+",
                "contact_phone": "+7 812 3409590;+7 911 9279590",
                "website": "http:\/\/www.vdvuhshagah-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.279567, 60.011914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Варштайнер Форум",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 120",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/www.wf120.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.363667, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 222",
                "opening_hours": "Mo-Fr 11:00-06:00; Sa,Su 12:00-06:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323198, 59.845175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 26\/16",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411916, 59.929826]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4799, 59.908164]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 15к1",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-01:00",
                "contact_phone": "+7 812 2447645",
                "website": "http:\/\/wasabico.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.333448, 60.04211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 5В",
                "opening_hours": "Mo-Th 10:00-01:00; Fr 10:00-03:00; Sa 12:00-03:00; Su 12:00-01:00",
                "contact_phone": "+7 812 2447301",
                "website": "http:\/\/wasabico.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.314548, 59.969547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 46к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352358, 60.049918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Мосина, 12",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.965226, 60.091331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.830676]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби Family",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 2",
                "opening_hours": "Mo-Th, Su 12:00-01:00; Fr, Sa 12:00-02:00",
                "contact_phone": "+7 812 2447310",
                "website": "http:\/\/wasabifamily.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.265877, 60.001073]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васабико",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васабико",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 1\/2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300606, 59.986817]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватерлоо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 12",
                "opening_hours": "12:00-02:00",
                "contact_phone": "https:\/\/italy-group.ru\/restaurants\/waterloo\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345611, 59.931197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Веранда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 17-19",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373603, 59.936589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Веранда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 38",
                "opening_hours": "http:\/\/cafeveranda111.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337491, 59.923861]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Веселидзе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 20\/16",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360065, 59.93763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ВЕСЕННИЙ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 199-201В",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28573, 59.909793]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Весенний",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 108",
                "opening_hours": "12:00-20:00",
                "contact_phone": "https:\/\/vesenniy.com\/restorany-dlya-svadby\/fontanki.php",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.318922, 59.921416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Весенний",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 83-85",
                "opening_hours": "12:00-20:00",
                "contact_phone": "https:\/\/vesenniy.com\/restorany-dlya-svadby\/rimskogo.php",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.284418, 59.920082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Виктория",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 59",
                "opening_hours": "12:00-23:00",
                "contact_phone": "https:\/\/www.taleonimperialhotel.com\/restaurant\/t-petersburg-victoria-restaurant\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.318563, 59.935764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Винегрет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Морская набережная, 9",
                "opening_hours": "+7 812 9809801",
                "contact_phone": "http:\/\/www.vinegreat.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.217458, 59.938162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вино & Вода",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 17",
                "opening_hours": "http:\/\/vinovoda.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347543, 59.946324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкус есть",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 82\/1",
                "opening_hours": "+7 812 9833376",
                "contact_phone": "https:\/\/tastetoeat.tastetoeat.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.332047, 59.926436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вместе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 119",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-02:00",
                "contact_phone": "+7 812 447061",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.257343, 59.851245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водограй",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 2",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/vodograi.info\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.34191, 59.937607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопад",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 2к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36842, 60.010425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волна",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петровская набережная, 4",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 10:00-00:00",
                "contact_phone": "+7 921 4486141",
                "website": "http:\/\/www.volnarestaurant.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.330394, 59.953759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восток-Запад",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Б",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9275318",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.472903, 59.865384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Высокие отношения",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 26-28Б",
                "opening_hours": "https:\/\/wotnosh.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.923631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Габи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 41",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389656, 60.053224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гайот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 23Е",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313658, 59.972014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарден кафе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85литВ",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146042, 59.994063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гастрономика",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 5",
                "opening_hours": "Mo-Fr 11:00-01:00; Sa-Su 12:00-01:00",
                "contact_phone": "https:\/\/ginza.ru\/spb\/restaurant\/gastronomika",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.354415, 59.930201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "География",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 5",
                "opening_hours": "+7 812 3400074",
                "contact_phone": "https:\/\/geo-rest.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345189, 59.931576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гиара",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 88",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.034153, 59.852751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гимназия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 21",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 9330758",
                "website": "http:\/\/www.gimnazya.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.294749, 59.932924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гирлянда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 12",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "http:\/\/localsteak.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345611, 59.931197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гнездо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473361, 59.917885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гоголь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 8",
                "opening_hours": "+7 812 3126097",
                "contact_phone": "http:\/\/www.restaurant-gogol.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.313497, 59.935633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гонконг",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Горный орел",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Александровский парк, 1А",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 952 2753565",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.309212, 59.953615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городская дача",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Воскова, 20А",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 9280643",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.308457, 59.95835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гости",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 13",
                "opening_hours": "Mo-Fr 08:30+; Sa-Su 09:00+",
                "contact_phone": "+7 812 3125820",
                "website": "https:\/\/www.gdegosti.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312185, 59.93561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиная",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 87",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308035, 59.966867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиная",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиница \"Киевская\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Днепропетровская улица, 49",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358484, 59.912734]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиница \"Киевская\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курская улица, 40",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356795, 59.910898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Государь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 32",
                "opening_hours": "10:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342072, 59.967435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Градус Фаренгейта",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 21",
                "opening_hours": "Mo-Th 12:00-24:00, Fr,Sa 12:00-02:00, Su 14:00-24:00",
                "contact_phone": "+7 812 3256500",
                "website": "http:\/\/gradus-f.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309517, 59.86429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Градъ Петровъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Университетская набережная, 5",
                "opening_hours": "+7 812 3260137",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302932, 59.940862]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд кафе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 85",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401226, 59.971105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Граф Х",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фарфоровская улица, 8",
                "opening_hours": "http:\/\/www.restorangrafx.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440015, 59.88125]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гречка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 14к2",
                "opening_hours": "Mo-Th 10:00-01:00; Fr, Sa 10:00-03:00; Su 10:00-01:00",
                "contact_phone": "+7 812 9510571",
                "website": "http:\/\/www.restorangrechka.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.246051, 60.016771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гриль фактор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 6к1с6",
                "opening_hours": "Fr-Sa 11:00-02:00; Mo-Th 11:00-23:00; Su 11:00-23:00",
                "contact_phone": "+78124078000",
                "website": "https:\/\/www.restoclub.ru\/spb\/place\/gril-factory-2"
            },
            "geometry": {"type": "Point", "coordinates": [30.147955, 59.854346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гриль Factory",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 25",
                "opening_hours": "Mo-Th, Su 11:00-23:00; Fr, Sa 11:00-02:00",
                "contact_phone": "+7 812 4078000",
                "website": "http:\/\/grillfactory.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.286745, 59.945157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Громов",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинский ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 33",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233223, 59.948532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Груша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 10",
                "opening_hours": "Mo-Sa 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361709, 59.954273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гужва",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 9",
                "opening_hours": "Mo-Th 07:30-00:00, Fr 7:30-02:00, Sa 08:00-02:00, Su 08:00-00:00",
                "contact_phone": "https:\/\/vk.com\/guzvastreet",
                "website": "fast_food"
            },
            "geometry": {"type": "Point", "coordinates": [30.344093, 59.926995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гурмания",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 48",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236798, 59.939239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гуси-Лебеди",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 16",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 9065154",
                "website": "https:\/\/gusilebedi.spb.ru\/gagarinskaya\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.343069, 59.945152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гуси-Лебеди",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 9103200",
                "website": "gusilebedi.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Данини",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Архитектора Данини, 5",
                "opening_hours": "Mo-Fr 07:30-23:00, Sa-Su 09:00-23:00",
                "contact_phone": "+7 812 4405555; +7 921 4405555",
                "website": "http:\/\/daninirest.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.418316, 59.69532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дао щан юань",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 15к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333448, 60.04211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дача",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Нахимова, 8",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 921 946 7436",
                "website": "http:\/\/dachavspb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.234921, 59.943291]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два Му",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 94",
                "opening_hours": "Mo-Su,PH 10:00-23:00",
                "contact_phone": "https:\/\/dvamu.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.290679, 59.918147]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 14",
                "opening_hours": "Mo-Su 08:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/restaurants\/nevskiy-pr-22\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322866, 59.936341]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 74",
                "opening_hours": "Mo-Su 08:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.307505, 59.963187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 6\/4",
                "opening_hours": "Mo-Su 08:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/restaurants\/ul-italyanskaya-6\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.334239, 59.93649]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к2",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.299429, 60.001523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 36",
                "opening_hours": "11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.585522, 59.740699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 127к1",
                "opening_hours": "Mo-Su 09:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.264835, 59.851349]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "Mo-Su 10:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 205",
                "opening_hours": "Mo-Su 08:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.320539, 59.845731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 21",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317422, 59.916748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 47",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/dvepalochki.ru\/restaurants\/nevskiy-pr-47\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.347381, 59.932441]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 96",
                "opening_hours": "08:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/restaurants\/nevskiy-prospekt-96\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.354568, 59.932112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 19",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.480691, 59.90765]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 3к1",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.471393, 59.917637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Науки, 14к1",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.402169, 60.012413]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 87к1",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.414521, 60.036097]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 134к3",
                "opening_hours": "+7 812 2447771",
                "contact_phone": "http:\/\/dvepalochki.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.330852, 60.049015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 15",
                "opening_hours": "Mo-Su 11:00-06:00",
                "contact_phone": "http:\/\/dvepalochki.ru\/restaurants\/ul-vosstaniya-15\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360541, 59.934641]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Две палочки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 34",
                "opening_hours": "Mo-Su 09:00-06:00",
                "contact_phone": "+7 800 2202020",
                "website": "http:\/\/dvepalochki.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.232873, 59.947946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Двордзен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334293, 59.936133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворец Олимпия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 14",
                "opening_hours": "http:\/\/olympia-palace.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349142, 59.94495]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворянское Собрание",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 9",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9000160",
                "website": "https:\/\/www.philharmonia.spb.ru\/about\/restaurants\/noble_assembly\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331876, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ДеДа хинкали",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Финляндский проспект, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342081, 59.958381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Декантер",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Воскресенская набережная, 4",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/decanter-restoran.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.372372, 59.950659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дельмар «Филадельфия»",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 17к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255591, 60.012539]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Демьянова уха",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Маркина, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308196, 59.955471]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Джей Хинд",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рылеева, 17-19",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 2721990",
                "website": "http:\/\/rileeva.jaihind.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.356831, 59.942268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Джеймис Италиан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2Д",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-00:30",
                "contact_phone": "+7 (812) 640-16-16",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/jamies_italian"
            },
            "geometry": {"type": "Point", "coordinates": [30.327672, 59.940573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Джельсомино",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Полтавская улица, 5\/29Б",
                "opening_hours": "https:\/\/ginza.ru\/spb\/restaurant\/jelsomino_spb",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369318, 59.927346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Джигитерия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 16к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294928, 59.950181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дзен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дивный сад",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гатчинская улица, 16",
                "opening_hours": "12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299815, 59.961715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дикмаn's deli",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 2",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": "+7 911 7072323",
                "website": "http:\/\/www.dikmansdeli.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.317764, 59.96866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Добрый Грузин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 60к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204827, 60.007101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Добрый Грузин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Полтавская улица, 3",
                "opening_hours": "http:\/\/dobriygruzin.ru\/Nevskiy111",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370351, 59.928108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Добрый грузин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 7-9",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 921 0972525",
                "website": "dobriygruzin.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.351396, 59.94504]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Долина Рейна",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146257, 59.853401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Долма",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304666, 59.933005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки",
                "opening_hours": "http:\/\/rest-dom.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307675, 59.931797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом актёра",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 84-86",
                "opening_hours": "http:\/\/domaktera.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350498, 59.932644]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом карри",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Глинки, 3-5-7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29562, 59.926959]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домик у причала",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ломоносов, набережная Сидоровского канала, 3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.774607, 59.919224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Доппи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, бульвар Красных Зорь, 8к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443528, 59.870908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Драго",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 15",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 4308823",
                "website": "https:\/\/www.drago.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.293527, 59.984481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дружба",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Л",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.474942, 59.863662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дружба",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 70",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270952, 59.912892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 3331947",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Ю",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37919, 59.82741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 18",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288838, 59.962809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 12",
                "opening_hours": "Fr 11:00-05:00;Mo-Th 11:00-23:00;Sa 12:00-05:00;Su 12:00-23:00",
                "contact_phone": "+7 812 2444184",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323064, 59.936913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 13",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 3142181",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.935209]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 98",
                "opening_hours": "Fr-Sa 11:00-05:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 2444715",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312868, 59.966475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Брантовская дорога, 3",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "+7 812 4554748",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.418366, 59.940145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 32",
                "opening_hours": "Fr-Sa 12:00-24:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 2442198",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.269767, 59.847883]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 118к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406302, 59.846875]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 23к4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31276, 59.868681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Владимирский проспект",
                "opening_hours": "Mo-Su 12:00-05:00",
                "contact_phone": "+7 812 3103879",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347758, 59.930796]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Галерная улица, 26",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 4069656",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.290796, 59.932135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 45",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 5702782",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322228, 59.928117]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 116к5",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-24:00",
                "contact_phone": "+7 812 2905225",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.417108, 60.034627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 11:00-22:00",
                "contact_phone": "+7 812 3351888",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.397812, 60.011536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 64",
                "opening_hours": "Fr-Sa 11:00-05:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 6160384",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322641, 59.918183]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "Fr-Sa 11:00-02:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 3335008",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Вокзальная улица, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.703683, 60.205691]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 29",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 2447059",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.339251, 59.935547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 44В",
                "opening_hours": "Fr-Sa 12:00-05:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 2447065",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308223, 59.968912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к2",
                "opening_hours": "cafe",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299429, 60.001523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, Октябрьская улица, 8",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 4906860",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.622413, 59.739135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 7",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 2447076",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.58966, 59.74117]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, проспект Ленина, 22",
                "opening_hours": "Mo-Th 11:00-23:00; Fr-Su 11:00-05:00",
                "contact_phone": "+7 812 4613218",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.58277, 59.749839]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 13к1",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 9858343",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.259984, 60.008878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 51",
                "opening_hours": "Fr-Sa 11:00-03:00;Mo,Tu,We,Th,Su 11:00-24:00",
                "contact_phone": "+7 812 4479379",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.090878, 59.741331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 24",
                "opening_hours": "Mo-Fr 11:00-23:00;Sa-Su 12:00-22:00",
                "contact_phone": "+7 812 7750054",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.26214, 59.967795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 13\/2",
                "opening_hours": "Mo-Sa 11:00-05:00;Su 12:00-05:00",
                "contact_phone": "+7 812 2328541",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321231, 59.956494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 31",
                "opening_hours": "Fr-Sa 11:00-02:00;Mo,Tu,We,Th,Su 11:00-22:00",
                "contact_phone": "+7 812 3114186",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.76407, 59.994771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 95к1",
                "opening_hours": "Fr-Sa 12:00-05:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 7436948",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.213963, 59.85304]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 138",
                "opening_hours": "https:\/\/evrasia.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35039, 59.913956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 93",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 5757725",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.355538, 59.923284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный 28",
                "opening_hours": "Mo-Su 12:00-05:00",
                "contact_phone": "+7 812 5790736",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.34889, 59.941272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ломоносов, Красноармейская улица, 19",
                "opening_hours": "Fr-Sa 11:00-24:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 7037846",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.775027, 59.907436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 3\/54",
                "opening_hours": "Fr-Sa 11:00-24:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 3107566",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33723, 59.934601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 145",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 3690760",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319021, 59.877353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 188АлитАБ",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325103, 59.866852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 192-194",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 3881747",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321599, 59.863364]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 195",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 3719078",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320926, 59.850043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 222",
                "opening_hours": "Mo-Su 12:00-05:00",
                "contact_phone": "+7 812 3731916",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323198, 59.845175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московское шоссе, 14к1",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 3738354",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33449, 59.836753]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 12",
                "opening_hours": "Mo-Su 12:00-05:00",
                "contact_phone": "+7 812 3159474",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327411, 59.936287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 22",
                "opening_hours": "Fr-Sa 11:00-02:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 3100152",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326477, 59.933879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 2",
                "opening_hours": "Mo-Su 12:00-05:00",
                "contact_phone": "+7 812 3256213",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.303139, 59.944485]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 51, подъезд 1",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 4964831",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.303938, 59.986151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 175",
                "opening_hours": "Mo-Fr 11:00-23:00;Sa-Su 12:00-23:00",
                "contact_phone": "+7 812 2447078",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.383574, 59.923942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 40-42",
                "opening_hours": "Mo-Su 10:00-05:00",
                "contact_phone": "+7 812 4568660",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.332316, 59.934943]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": "Fr-Sa 10:00-05:00;Mo,Tu,We,Th,Su 10:00-23:00",
                "contact_phone": "+7 812 2442173",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пироговская набережная, 21",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "+7 812 3204922",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.338326, 59.965948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 25",
                "opening_hours": "Fr-Sa 11:00-02:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 812 3630969",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.413542, 59.969668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Карла Фаберже, 8",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 775-02-90",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.436745, 59.934461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 68\/2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.052105, 59.851107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, территория Славянка, Ростовская улица, 22",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 4686563",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.47594, 59.744184]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 27",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.485892, 59.900507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 2Б",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 5879035",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.472498, 59.919355]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 142",
                "opening_hours": "Fr-Sa 12:00-03:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 3846942",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.166883, 59.832548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 41",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 4481772",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.389656, 60.053224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "11:00-22:00",
                "contact_phone": "+7 812 3316240",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 32к1",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 6175911",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331634, 60.054055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 53к1",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 5915700",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.380843, 60.042923]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 78",
                "opening_hours": "Fr-Sa 12:00-05:00;Mo,Tu,We,Th,Su 12:00-02:00",
                "contact_phone": "+7 812 5945454",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.403831, 60.03927]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 43",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 7725108",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.390608, 59.858042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 77",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 7831125",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.260262, 59.866057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 3331245",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 11\/57",
                "opening_hours": "Mo-Su 11:00-05:00",
                "contact_phone": "+7 812 2726505",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.946058]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 134к3",
                "opening_hours": "Mo-Su 12:00-05:00",
                "contact_phone": "+7 812 5976446",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.330852, 60.049015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "Fr-Sa 11:00-01:00;Mo,Tu,We,Th,Su 11:00-22:00",
                "contact_phone": "+7 812 2445610",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 21",
                "opening_hours": "Fr-Sa 12:00-05:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 5500262",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.328831, 60.00289]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энтузиастов, 24",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 5204028",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.473792, 59.947631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 4493294",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Малиновская улица, 8",
                "opening_hours": "Fr-Sa 12:00-24:00;Mo,Tu,We,Th,Su 12:00-23:00",
                "contact_phone": "+7 812 4524363",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.420621, 59.699735]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Октябрьский бульвар, 7\/29",
                "opening_hours": "Fr-Sa 12:00-02:00;Mo,Tu,We,Th,Su 12:00-24:00",
                "contact_phone": "+7 812 4519778",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.406274, 59.724932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 38",
                "opening_hours": "12:00-23:00",
                "contact_phone": "cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321788, 59.928158]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 60к1",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 5596594",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.39536, 60.036596]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 348",
                "opening_hours": "Fr-Sa 11:00-02:00;Mo,Tu,We,Th,Su 11:00-23:00",
                "contact_phone": "+7 911 8391915",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.976038, 60.103537]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Токарева, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.954487, 60.084965]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6776288",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.379325, 59.870014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "Fr-Sa 11:00-05:00; Su, Mo-Th 11:00-23:00",
                "contact_phone": "+7 812 2443245",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 36к2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 2723472",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362967, 59.93594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 86",
                "opening_hours": "https:\/\/evrasia.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349681, 59.832909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Руднева, 25",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 5989630",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.370162, 60.05008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "Fr-Sa 11:00-23:00;Mo,Tu,We,Th,Su 11:00-22:00",
                "contact_phone": "+7 812 2442130",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 969 7065203",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Щербакова, 11",
                "opening_hours": "Mo 12:00-23:00; Fr-Sa 12:00-05:00; Su 12:00-23:00",
                "contact_phone": "+7 812 3042357",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296581, 60.028631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 13",
                "opening_hours": "Fr-Sa 11:00-23:00; Mo-Th,Su 11:00-22:00",
                "contact_phone": "+7 812 3324725",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320405, 59.806966]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 13",
                "opening_hours": "Fr-Sa 11:00-23:00; Mo-Th,Su 11:00-22:00",
                "contact_phone": "+7 812 3324725",
                "website": "https:\/\/evrasia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320701, 59.80691]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евразия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Витебский проспект, 41к1",
                "opening_hours": "Mo-Su 13:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360245, 59.855873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Едим руками",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 41",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/ginza.ru\/spb\/restaurant\/edimrukami",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.315563, 59.925994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Екiбастуз",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 9",
                "opening_hours": "24\/7",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358951, 59.947009]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ели-Пили",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ударников, 29к1",
                "opening_hours": "+7 812 5290529",
                "contact_phone": "http:\/\/elipili.info",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.477924, 59.952515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ели-Пили",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Школьная улица, 64",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 4310136",
                "website": "http:\/\/elipili.info"
            },
            "geometry": {"type": "Point", "coordinates": [30.263784, 59.986974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЕлиПили",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Полевая улица, 26",
                "opening_hours": "http:\/\/vk.com\/club13228274",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.569709, 59.817111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ель",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Серово, Приморское шоссе, 640а",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 4332312",
                "website": "www.restoranel.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.567222, 60.197232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЕМ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 84",
                "opening_hours": "http:\/\/emrestaurant.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302106, 59.930219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Емельян",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 83\/7",
                "opening_hours": "+7 812 5794379",
                "contact_phone": "http:\/\/www.chudo-spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.367054, 59.946198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Желания",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298099, 59.950344]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жемчужина",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 55к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.138109, 59.84852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Живи вкусно",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 28",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 4081983",
                "website": "http:\/\/live-tasty.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.284957, 59.921768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жиробас",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 32",
                "opening_hours": "Mo-Su 13:00-23:00",
                "contact_phone": "+7 911 2190068",
                "website": "mariniere.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.343419, 59.943489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жуи Лун",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 215",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347228, 59.910762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зазходи генацвале",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Пороховская улица, 10",
                "opening_hours": "+79531480736",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413165, 59.953854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зал \"Созвездие\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 99",
                "opening_hours": "+7 812 35565733",
                "contact_phone": "http:\/\/zalsozvezdie.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.428598, 60.033185]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зевс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 28В",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9256226;+7 812 2728087",
                "website": "http:\/\/restoranzevs.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.352995, 59.949438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зейтун Палас",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 63",
                "opening_hours": "+7 812 7770393",
                "contact_phone": "http:\/\/zeytunpalace.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.429559, 59.84551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зеленый Чай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотая панда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 4",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241946, 59.927815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой Двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Белевский проспект, 20",
                "opening_hours": "24\/7",
                "contact_phone": "+79992414111",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.430313, 59.872272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой лев",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 45",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.762074, 59.992531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ивериони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, улица Первого Мая, 89Е",
                "opening_hours": "+7 911 2660054",
                "contact_phone": "https:\/\/vk.com\/club39832427",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.835748, 59.883392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Идиот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 82",
                "opening_hours": "11:00-01:00",
                "contact_phone": "https:\/\/idiot-spb.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.303364, 59.930223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ижора",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, улица Труда, 5\/4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.60463, 59.749092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Империя",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Арсенальная набережная, 13\/1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357487, 59.953263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Индокитай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 28",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324779, 60.00375]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Инжир",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 30к4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22954, 59.856307]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ирис",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 57",
                "opening_hours": "https:\/\/r-iris.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282963, 59.923757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Итальянский ресторан \"Фортунато\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 12",
                "opening_hours": "Mo-Th 10:00-22:00; Fr-Sa 10:00-23:00; Su 11:00-22:00",
                "contact_phone": "+7 812 4763388",
                "website": "http:\/\/www.pizzafortunato.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.405136, 59.717142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кабанчик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 31",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284562, 59.947108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кабинет-Портрет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Московская улица, 7",
                "opening_hours": "Mo-Su 11:30-23:30",
                "contact_phone": "+7 812 3893887;+7 812 7124321",
                "website": "https:\/\/www.restoranspb.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349456, 59.925876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кавказ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Стахановцев, 5",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 4444309",
                "website": "http:\/\/kavkaz-rest.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.406086, 59.928555]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кавказ бар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 18",
                "opening_hours": "11:00-01:00",
                "contact_phone": "+7 812 3121665",
                "website": "http:\/\/kavkaz-bar.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341632, 59.935227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кавказский двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, территория Лахта, Приморская улица, 4",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 812 4983253",
                "website": "http:\/\/www.kavkazdvor.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.164942, 59.991493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Калипсо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Заречная дорога, 1Ж",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.941688, 60.123112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кальянная Feromon 5 uglov",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 18",
                "opening_hours": "24\/7",
                "contact_phone": "+7 (812) 907‒07‒95",
                "website": "https:\/\/feromon.group\/feromon5uglov.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.339458, 59.927405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кальянная Feromon Efimova",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 5",
                "opening_hours": "24\/7",
                "contact_phone": "+7 (812) 933‒36‒33",
                "website": "https:\/\/feromon.group\/feromon-efimova.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.322605, 59.92447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кальянная Feromon Povarskoy",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Поварской переулок, 8",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_phone": "+7 (812) 999‒98‒76",
                "website": "https:\/\/feromon.group\/feromon_povarskoy.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.352124, 59.92965]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кальянная Feromon Primorskaya",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "24\/7",
                "contact_phone": "+7 (812) 934‒44‒44",
                "website": "https:\/\/feromon.group\/feromon-korablestr.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кальянная PS lounge bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 81",
                "opening_hours": "Mo-Th 15:00-03:00; Fr-Sa 15:00-05:00; Su 15:00-03:00",
                "contact_phone": "+7 812 9127536",
                "website": "http:\/\/кальянныйклуб.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.359508, 59.930944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Камелот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, ​Заводской проспект, 40 к4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.58247, 59.732404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Камелот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, улица Ижорского Батальона, 6",
                "opening_hours": "Mo-Fr 10:00-01:00; Sa-Su 11:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.612964, 59.735084]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канарейка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кавалергардская улица, 20",
                "opening_hours": "https:\/\/kenarkids.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38237, 59.945427]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Капулетти",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 74",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/capuletti"
            },
            "geometry": {"type": "Point", "coordinates": [30.307505, 59.963187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Караван",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 123\/5",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "http:\/\/www.caravanspb.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.309481, 59.920289]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Караван-сарай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 1\/38",
                "opening_hours": "http:\/\/karavan-sarai.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349564, 59.938852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Караман",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 73",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342539, 59.920835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кардамон",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 2",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290509, 59.940857]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Карл и Фридрих",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Южная дорога, 15",
                "opening_hours": "+7-812-320-79-78",
                "contact_phone": "http:\/\/www.k-f.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.232522, 59.969993]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кашалот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 7",
                "opening_hours": "+7 812 4014100",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323737, 59.993293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квартира Кости Кройца",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 71",
                "opening_hours": "+7 921 6517788",
                "contact_phone": "http:\/\/kreutzflat.com",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.355071, 59.931517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квартира №162",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 123",
                "opening_hours": "Mo-Su 09:00-06:00",
                "contact_phone": "+7 812 4933162",
                "website": "http:\/\/restoranchik-kv.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35225, 59.918471]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кинг Понг",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "http:\/\/www.kingpong.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.226207, 59.98652]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кинза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Александровская, Ленинградское шоссе, 2А",
                "opening_hours": "+7 812 9229808",
                "contact_phone": "https:\/\/kinza-pushkin.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.354976, 59.728825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кирочный Двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 36М",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9071037",
                "website": "http:\/\/www.banket.life"
            },
            "geometry": {"type": "Point", "coordinates": [30.364206, 59.944296]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китай Город",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 5",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/2.k-gorod.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.36903, 59.932333]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайская грамота",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 7, подъезд 1",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 921 9070710",
                "website": "http:\/\/chinagramota.ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325561, 59.942588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайская еда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Прилукская улица, 15",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 7128810",
                "website": "http:\/\/vkontakte.ru\/club724305"
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 59.909815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайская кухня",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Елецкая улица, 1",
                "opening_hours": "12:00-23:30",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321366, 60.017836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайская столовая",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гжатская улица, 22к2",
                "opening_hours": "09:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386736, 60.01119]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайский двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь труда, 3",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 5717584;+7 812 3126869",
                "website": "www.kit-dvor.narod.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.291443, 59.931427]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киш-миш",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 26",
                "opening_hours": "https:\/\/vk.com\/club104401918",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357092, 59.938987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КИDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 36",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "https:\/\/spb.kido.ru\/places\/morkaya.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.312014, 59.933149]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КИDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 16\/14",
                "opening_hours": "Mo-Fr 12:00-24:00; Sa-Su 13:00-24:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "https:\/\/spb.kido.ru\/places\/karavannaya.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.341749, 59.93571]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КИDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "https:\/\/spb.kido.ru\/places\/polustrovskiy.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КИDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 51",
                "opening_hours": "Mo-Fr 12:00-23:00; Sa-Su 13:00-23:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "https:\/\/kido.ru\/places\/suvorovskiy.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.378714, 59.940163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КИDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 14",
                "opening_hours": "Mo-Fr 12:00-00:00; Sa-Su 13:00-00:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "http:\/\/www.kido.ru\/places\/pavlova.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.307271, 59.976188]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КИDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 32",
                "opening_hours": "Mo-Fr 12:00-23:00; Sa-Su 13:00-23:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "https:\/\/spb.kido.ru\/places\/savushkina.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.288443, 59.985143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клёво",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 113к1",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 5114911;+7 962 3444555",
                "website": "http:\/\/klevo.me\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325633, 60.037836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кобулети",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Белградская улица, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365716, 59.857107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коза Дереза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 43",
                "opening_hours": "Su-We 12:00-00:00; Th-Sa 12:00-01:00",
                "contact_phone": "+7 812 4402040",
                "website": "http:\/\/kozaderezapub.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.390608, 59.858042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кококо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 6",
                "opening_hours": "Mo-Su 07:00-01:00",
                "contact_phone": "+7 812 4182060",
                "website": "https:\/\/www.kokoko.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308897, 59.934934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колесо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 2",
                "opening_hours": "http:\/\/kolesorest.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308924, 59.935543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Компания",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ординарная улица, 19",
                "opening_hours": "+7 812 9971234",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305385, 59.967579]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Конд",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 16к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385389, 59.848547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Конфуций",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 15",
                "opening_hours": "11:30-23:00",
                "contact_phone": "+7 812 7852256",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.273593, 59.903977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Конь в пальто",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Российский проспект, 8",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470639, 59.926666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кореана",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 17\/56",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "http:\/\/koreana-spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.316102, 59.932171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корейка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 92",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 9190809",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.32645, 59.924334]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корейко",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колокольная улица, 16",
                "opening_hours": "http:\/\/koreiko.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352295, 59.928573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корейский ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Беговая улица, 3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205546, 59.986133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Король Гамбринус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 66к1",
                "opening_hours": "00:00-06:00,12:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396061, 60.013883]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Король Гамбринус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 34\/3",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426945, 59.932166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Король Гамбринус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7",
                "opening_hours": "12:00-6:00",
                "contact_phone": "+7 812 9522208",
                "website": "http:\/\/www.king-gambrinus.ru\/gam_pioner.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.296635, 60.003984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корона",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Гостилицкая улица, 2Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.860759, 59.878929]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корона",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Русановская улица, 1к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.495262, 59.862361]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корсаков",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 18",
                "opening_hours": "Mo-Fr 12:00-20:00; Sa-Su 12:00-16:00",
                "contact_phone": "http:\/\/korsakovspb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.305861, 59.923942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корчма",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 83",
                "opening_hours": "Mo-Th 12:00-02:00; Fr-Sa 12:00-03:00; Su 12:00-24:00",
                "contact_phone": "+7 812 5542960",
                "website": "http:\/\/korchma-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319955, 60.023875]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корчма Сало",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 36",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_phone": "+7 812 5792402",
                "website": "http:\/\/korchma-salo.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.348764, 59.939424]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корюшка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, ​Петропавловская Крепость, 13в",
                "opening_hours": "12:00-01:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/korushka"
            },
            "geometry": {"type": "Point", "coordinates": [30.315877, 59.95015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корюшка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 10",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417897, 59.725666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Косуля",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 24-26",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341416, 59.934308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня \"Аддис\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронверкская улица, 29\/37Б",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "+7 911 8343125",
                "website": "http:\/\/vk.com\/coffeeaddis"
            },
            "geometry": {"type": "Point", "coordinates": [30.310074, 59.962219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня №1",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 167",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9471274",
                "website": "http:\/\/delmar-spb.ru\/restaurants\/coffee1\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.863884]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крапива",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургское шоссе, 111П",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.979308, 59.867595]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Красный кабачок",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургское шоссе, 134А",
                "opening_hours": "+7 812 4505224",
                "contact_phone": "http:\/\/www.redkabak.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.9493, 59.873832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Красный Лис",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 50",
                "opening_hours": "Mo-Fr 10:00-23:00, Sa 13:30-23:00, Su 16:30-23:00",
                "contact_phone": "+7 812 2754214",
                "website": "http:\/\/rfjc.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.35516, 59.942732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кремлёвский",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Галерная улица, 1",
                "opening_hours": "Mo-Sa 08:00-18:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300956, 59.93548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крыша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 18",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334409, 59.959269]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кузнечный двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 79к1",
                "opening_hours": "+7 812 3276160",
                "contact_phone": "http:\/\/www.kuzdvor.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.260137, 59.938022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кузня",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2М",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 911 9211669",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.288838, 59.929502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Купола",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 10к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284903, 60.015934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Куракина дача",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Леснозаводская улица, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457299, 59.870063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Куранты",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60",
                "opening_hours": "http:\/\/www.kurants.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.890081, 59.882322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кухня на углях",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 63К71",
                "opening_hours": "+7 (905) 209-56-56",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412608, 59.993473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кэт",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 24-26",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 3153800",
                "website": "https:\/\/cat-restoran.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341416, 59.934308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ла Маре",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 34",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378166, 59.940506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лаванда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60Ю",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.894672, 59.88151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лагидзе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Белинского, 3",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_phone": "+7 812 5791104",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345198, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лайма",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное, Приморское шоссе, 376А",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 812 9279494",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.923427, 60.150308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ламантин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 2к3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406391, 59.959597]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ламантин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 271",
                "opening_hours": "+7 812 3138020; +7 921 1821332",
                "contact_phone": "https:\/\/lamantin-kafe.ru\/restoran-lamantin-obuhov\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.479254, 59.851245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ланкастер корт",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Фокина, 3к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338874, 59.966484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лапландия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 5-я Советская улица, 44",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9480099",
                "website": "http:\/\/www.laplandrest.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.375633, 59.932739]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лапша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 15",
                "opening_hours": "https:\/\/chinacook.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309077, 59.916275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лапша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 58",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417876, 59.727276]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лапшичная",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 4к5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387599, 59.706582]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ларисуваннухочу",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Науки, 14к1",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb"
            },
            "geometry": {"type": "Point", "coordinates": [30.402169, 60.012413]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЛЕОН",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 91к1",
                "opening_hours": "http:\/\/restoran-leon.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.448522, 59.870696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Леонардо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 11",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 5722628",
                "website": "https:\/\/leonardo-restoran.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344237, 59.929903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лесная сказка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 149Ф",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438416, 59.883584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лесной",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 48",
                "opening_hours": "+7 812 3098500",
                "contact_phone": "http:\/\/www.restoran-lesnoy.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.342054, 59.98169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летний дворец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургское шоссе, 130к7",
                "opening_hours": "http:\/\/www.summerpalace.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.976886, 59.866517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лето на Поляне",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 44Д",
                "opening_hours": "9:00-18:00",
                "contact_phone": "+7 812 6333434;+7 921 5957393",
                "website": "polyana.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.402106, 59.961953]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летучий голландец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Мытнинская набережная, 6",
                "opening_hours": "https:\/\/www.gollandec.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305717, 59.947798]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лехаим",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 2",
                "opening_hours": "Su-Th 10:00-22:00; Fr 10:00-22:00, sunset-24:00 off",
                "contact_phone": "+7 812 5725616",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.291748, 59.924727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лилак",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московское шоссе, 6",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9030701",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.328822, 59.840261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лимончелло",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 40",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/limoncello.su\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.348719, 59.938554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лис и Лев",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 5",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "https:\/\/www.lis-i-lev.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322605, 59.92447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лисья нора",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос, Ленинградское шоссе, 38",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.996828, 60.028217]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Литературное кафе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 57",
                "opening_hours": "http:\/\/litcafe.su\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319156, 59.936539]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лоло и Пепе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вербная улица, 27",
                "opening_hours": "http:\/\/restoran-lolopepe.ru",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298611, 60.023506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лужайка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 16Д",
                "opening_hours": "Mo-Su 12:00-1:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/luzhaika"
            },
            "geometry": {"type": "Point", "coordinates": [30.319551, 59.973248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лучший шашлык на углях",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Туристская улица, 35к4",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.213595, 60.009314]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Любимый",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 40к1",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 2444448",
                "website": "lubimrest.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.466713, 59.954547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Любимый Хабиб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 30",
                "opening_hours": "http:\/\/habib-cafe.ru\/restorany\/stachek\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272515, 59.888844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ля Мур",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 4к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302007, 60.001973]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ля Русс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 37",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 5717591",
                "website": "https:\/\/www.laruss-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318976, 59.940533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магия Востока",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 29к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27221, 60.007452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мадлен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 35",
                "opening_hours": "+7 812 9221512",
                "contact_phone": "http:\/\/www.canolle.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.278786, 59.888866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мадлоба",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Артиллерийский переулок, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34889, 59.941272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мадридский двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 14",
                "opening_hours": "+7 812 2712094",
                "contact_phone": "http:\/\/www.madrid-restoran.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.372705, 59.937229]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маймун",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 14",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/maimoon"
            },
            "geometry": {"type": "Point", "coordinates": [30.214385, 59.93873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Макао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Короленко, 14",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350085, 59.942543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Макаронники",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 16к2",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 6776088",
                "website": "http:\/\/makaronniki.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.294928, 59.950181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Серпуховская улица, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321833, 59.91632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МаккоЛи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 25",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362652, 59.934461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МаккоЛи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 9\/6",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "https:\/\/makkoli-spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.29191, 59.923915]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Макрель",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.76691, 60.18119]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Малика, Восточное кафе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27Д",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327241, 60.007866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мама на даче",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 88",
                "opening_hours": "09:00-02:00",
                "contact_phone": "+7 812 9266149",
                "website": "http:\/\/mamanadache.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.351801, 59.932464]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мама на даче",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 17",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423944, 59.895687]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мамалыга",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 2",
                "opening_hours": "https:\/\/ginza.ru\/spb\/restaurant\/mamaliga_na_kazanskoy",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323162, 59.933681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мамалыга",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 48",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301621, 59.959724]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мамалыга",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27АЕ",
                "opening_hours": "12:00-01:00",
                "contact_phone": "+7 812 3053399",
                "website": "http:\/\/www.mamaliga-mamaliga.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327672, 60.007177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мамо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "+7 812 3255550",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мангал-сити",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ванеева, 11",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449906, 59.927743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Манилов",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 6-8",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315895, 59.911543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мансарда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 3-5",
                "opening_hours": "http:\/\/ginza.ru\/spb\/restaurant\/mansarda",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303561, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мари Vanna",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Мытнинская набережная, 3",
                "opening_hours": "12:00-01:00",
                "contact_phone": "http:\/\/www.marivanna.ru\/spb\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.306301, 59.948771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марсельеза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 16",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260451, 59.974518]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 812 9860605",
                "website": "http:\/\/www.marcellis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 120",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "http:\/\/www.marcellis.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.258969, 59.852335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 205",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": "+7 812 9867882;+7 962 6867882",
                "website": "http:\/\/www.marcellis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320539, 59.845731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 21",
                "opening_hours": "Su-Th 09:00-00:00, Fr-Sa 09:00-01:00",
                "contact_phone": "http:\/\/www.marcellis.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322111, 59.935615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 43",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.marcellis.ru\/restaurants\/Nevskiy-43\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345674, 59.932662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Науки, 14А",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 965 7568867",
                "website": "http:\/\/www.marcellis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.399941, 60.012962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 15",
                "opening_hours": "Mo-Su 10:00-24:00",
                "contact_phone": "http:\/\/www.marcellis.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360541, 59.934641]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 34",
                "opening_hours": "Fr-Sa 10:00-02:00; Su-Th 10:00-00:00",
                "contact_phone": "+7 812 9833663",
                "website": "http:\/\/marcellis.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.232873, 59.947946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марчелли`s",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 72к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197919, 60.005931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Махраса",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 12",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/mahrasa-1.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.344524, 59.926684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мацони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 25к1",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/maconi.info\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.269605, 60.019194]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мацони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 98к1",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9643065",
                "website": "http:\/\/maconi.info"
            },
            "geometry": {"type": "Point", "coordinates": [30.399744, 60.029431]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маша и Медведь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 1\/25",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/www.traktirmashaandbear.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.337482, 59.93529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Машита",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 38\/96",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 3462485",
                "website": "http:\/\/mashita-ramen.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311188, 59.965903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мё",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новгородская улица, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390491, 59.940799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мей Ши Дзя",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321707, 59.91494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Менуа",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Инженерная улица, 6",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-24:00",
                "contact_phone": "+7 812 5954042",
                "website": "http:\/\/menua.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.337509, 59.937964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Меркурий",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Таврическая улица, 39Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378526, 59.947915]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мерула",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Яхтенная улица, 24к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217979, 59.998175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Место",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 59",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30702, 59.95461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Метрополис",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Глиняная улица, 5к1",
                "opening_hours": "10:00-00:00",
                "contact_phone": "+7 812 9858204",
                "website": "https:\/\/www.metropolis-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.392081, 59.916406]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Метрополь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 22\/2",
                "opening_hours": "Su-Th 07:00-23:30; Fr-Sa 07:00+",
                "contact_phone": "+7 812 5718888;+7 812 9947606",
                "website": "http:\/\/demetropole.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.333538, 59.933122]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечта Молоховецъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Радищева, 10",
                "opening_hours": "Mo-Fr 12:00-23:00; Sa-Su 14:00-23:00",
                "contact_phone": "http:\/\/molokhovets.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.362365, 59.937116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мики",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 70к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.458224, 59.876698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мимино. Хачапури и Вино",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Конюшенная улица, 39",
                "opening_hours": "Su-Th 12:00-23:00; Fr,Sa 12:00-24:00; PH 12:00-24:00",
                "contact_phone": "+7 812 6789893",
                "website": "https:\/\/mimin.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.416363, 59.721408]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Миндаль",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 30",
                "opening_hours": "12:00+",
                "contact_phone": "http:\/\/mindalcafe.ru\/restorany\/mindal_na_lermontovskom\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.297201, 59.917835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МирЭль",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 58",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469121, 59.883476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мисо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект, 33",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318716, 59.911353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мисо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Наличная улица, 15",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 13:00-23:00",
                "contact_phone": "+7 812 3565401",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.236565, 59.932608]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мисо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 15",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371258, 59.934885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мистер Икс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 30к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483646, 59.923955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моко бургер",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 13",
                "opening_hours": "+7 812 6401616",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282262, 59.940343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моменты",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 115Б",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 6700804",
                "website": "http:\/\/www.moments.rest"
            },
            "geometry": {"type": "Point", "coordinates": [30.249294, 59.851313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мона",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 47",
                "opening_hours": "09:00-00:00",
                "contact_phone": "+7 921 9935047",
                "website": "http:\/\/mona.rest\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308655, 59.969205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Монтана",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 19",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "http:\/\/triton-restoran.ru\/restaurants\/montana\/about",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.306957, 59.91203]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Монтана",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 20",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 2727035",
                "website": "http:\/\/triton-restoran.ru\/restaurants\/montana\/about"
            },
            "geometry": {"type": "Point", "coordinates": [30.356167, 59.944161]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Москва",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "10:00-01:00",
                "contact_phone": "+7 812 9376497;+7 812 9376361",
                "website": "https:\/\/ginza.ru\/spb\/restaurant\/moskva"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Музей для друзей",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Леонтьевская улица, 18",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 4145442",
                "website": "http:\/\/museumforfriends.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.40324, 59.719646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Муксун Гастробар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 24",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 9820589",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.329909, 59.956345]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мы же на ты",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 10",
                "opening_hours": "Su-Th 09:00-23:00; Fr-Sa 09:00-01:00",
                "contact_phone": "+7 812 4564474",
                "website": "https:\/\/vk.com\/myzhenaty"
            },
            "geometry": {"type": "Point", "coordinates": [30.35499, 59.929912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мюнхель",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 42к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238002, 60.024702]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясная лавка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Белинского, 6",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346941, 59.938405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясная лавка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Белинского, 6",
                "opening_hours": "https:\/\/m-lvk.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346941, 59.938405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясной клуб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Культуры проспект, 2а",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/meatclub.spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.369156, 60.053238]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясной клуб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 74к2Б",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/meatclub.spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.389018, 60.04423]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясонская Ложа",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рылеева, 23",
                "opening_hours": "Mo-Th 13:00-23:00; Fr 13:00-01:00; Sa 14:00-01:00; Su 14:00-00:00",
                "contact_phone": "http:\/\/meatlodge.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.358358, 59.94225]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясорубка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе",
                "opening_hours": "+7 911 9273044",
                "contact_phone": "http:\/\/myasorubka.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.866424, 60.166201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясорубка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "Mo-Th 10:00-23:00; Fr 10:00-01:00; Sa 11:00-01:00; Su 11:00-23:00",
                "contact_phone": "+78129204237",
                "website": "https:\/\/www.myasorubka.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На здоровье!",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295261, 59.955183]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 24",
                "opening_hours": "+7 812 4058957",
                "contact_phone": "http:\/\/www.naparah.ru",
                "website": "cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.282172, 59.939658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "http:\/\/www.elki-palki.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Зверинская улица, 1\/3",
                "opening_hours": "24\/7",
                "contact_phone": "+7 (812) 640-05-06",
                "website": "cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.292602, 59.953322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к1",
                "opening_hours": "+7 812 3006701",
                "contact_phone": "http:\/\/www.naparah.ru",
                "website": "cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.299878, 60.000425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный просп., 63",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 5721001",
                "website": "http:\/\/www.naparah.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347579, 59.93319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 63",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 5790018",
                "website": "http:\/\/www.naparah.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347579, 59.93319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Парах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 26Б",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/напарах.рф\/",
                "website": "cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.332262, 59.932563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На речке",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ольгина улица, 8",
                "opening_hours": "Mo-Fr 11:00-01:00; Sa-Su 12:00-01:00",
                "contact_phone": "http:\/\/ginza.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.278426, 59.973937]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Навина",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 3",
                "opening_hours": "Sa-We 12:00-01:00; Th-Fr 12:00-03:00",
                "contact_phone": "+78129472400",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322417, 59.933816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Навруз",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 125",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 3721121",
                "website": "http:\/\/www.navruz.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.262742, 59.851399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нагасаки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 27к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248773, 60.018228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Накатика",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тучков переулок, 11\/5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288829, 59.944914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Находка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 18",
                "opening_hours": "http:\/\/nakhodkaseafood.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31029, 59.934231]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наш Питер",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Блохина, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297336, 59.952326]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша Dacha",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 448",
                "opening_hours": "Mo-Th 12:00-23:00; Fr 12:00-01:00; Sa-Su 10:00-23:00",
                "contact_phone": "+7 812 6401616",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.822929, 60.167468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наше Место",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Алтайская улица, 12",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325139, 59.850048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Не горюй",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 28",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 6791119",
                "website": "http:\/\/www.negoryui.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.341353, 59.933893]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Не Горюй!",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Небеса",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Небесный дворец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 37",
                "opening_hours": "12:00-23:30",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278049, 59.938239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невская жемчужина",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 26",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414925, 59.908502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невская трапеза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гжатская улица, 9",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38105, 59.999786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невский причал",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 108к4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.465348, 59.86799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невский рай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 14",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387886, 59.927585]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Никольский",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 34",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.91028, 59.879197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нихао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 22",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/www.nihaorest.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.32972, 59.926796]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нихао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 112",
                "opening_hours": "Su-Th 11:00-23:00, Fr-Sa 11:00-01:00",
                "contact_phone": "+7 812 6128503",
                "website": "http:\/\/nihaorest.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358224, 59.931688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нихао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 160",
                "opening_hours": "http:\/\/www.nihaorest.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376289, 59.92713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Новый свет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Хошимина, 16",
                "opening_hours": "09:00-23:00",
                "contact_phone": "http:\/\/www.hoshimina16.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.32486, 60.053696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нож справа, вилка слева",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 44",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": "http:\/\/nsvs.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360478, 59.943431]}
        },
        {"type": "Feature",
            "properties": {
                "name": "О! Cuba",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": "+7 812 3128892",
                "contact_phone": "http:\/\/www.o-cuba.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 33",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 5605028",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.429999, 59.889972]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оба два",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 44",
                "opening_hours": "Mo-Su 13:00-23:00",
                "contact_phone": "+7 812 5791789",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.362967, 59.938833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество чистых тарелок",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 15",
                "opening_hours": "https:\/\/www.cleanplatescafe.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347309, 59.929276]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество чистых тарелок",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 13",
                "opening_hours": "11:00-01:00",
                "contact_phone": "+7 812 9349764",
                "website": "https:\/\/www.cleanplatescafe.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.313946, 59.933636]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОгоХого",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 32",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": "+7 812 963-54-44",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.287877, 59.943422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Одесса",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 7А",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9010808",
                "website": "http:\/\/bashnya-pushkin.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.399233, 59.717762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Одесса-мама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 5",
                "opening_hours": "Mo-Th 12:00-00:00; Fr-Su 12:00-02:00",
                "contact_phone": "+7 812 3121136;+7 962 7189817",
                "website": "http:\/\/www.odessa-mama.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.34103, 59.934907]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оджах",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 126",
                "opening_hours": "+7 931 3610101;+7 981 7110110",
                "contact_phone": "http:\/\/продукты-из-армении.рф",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.306589, 60.057101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оджахури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 28",
                "opening_hours": "Mo-Sa 11:00-23:00; Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309239, 59.911448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оджахури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 16\/25",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373765, 59.936945]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Олень",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пражская улица, 26к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392, 59.872656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Олиус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 26",
                "opening_hours": "Mo-Fr 12:00-18:00",
                "contact_phone": "http:\/\/olius.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.296653, 59.918404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оллис Клаб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 12к1",
                "opening_hours": "Mo-Fr 12:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376468, 59.856171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Омманэ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 3",
                "opening_hours": "http:\/\/ommane.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31064, 59.93584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Осака",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 2к2Щ",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa, Su 10:00-20:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405313, 59.958606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Особняк Нейдгарта",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 31",
                "opening_hours": "http:\/\/www.hozyainbarin.ru\/osobnyak-nejdgarta",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361359, 59.947545]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отто",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146257, 59.853401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Охота",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ломоносов, Первомайская улица, 3Б",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763381, 59.916068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Охотничий клуб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новгородская улица, 27",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 952 3571111",
                "website": "https:\/\/www.ohota.rest\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.392297, 59.942264]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Охотничья усадьба",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 93",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397977, 59.733403]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Павлин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 8",
                "opening_hours": "Mo-Fr 09:00-21:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319093, 59.971037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Павлин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 10",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359715, 59.947343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пагода Мот Кот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 50",
                "opening_hours": "https:\/\/vk.com\/restoran_pagoda",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3632, 59.946635]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Палкинъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 47",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/www.palkin.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.347381, 59.932441]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панорама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 428Д",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 812 9255014",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.852481, 60.16154]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панорама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малоохтинский проспект, 64В",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 812 9844354",
                "website": "https:\/\/vk.com\/zal_panorama"
            },
            "geometry": {"type": "Point", "coordinates": [30.403157, 59.932175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панорамика",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 2\/5",
                "opening_hours": "+7 911 9250705",
                "contact_phone": "http:\/\/panoramikarest.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.28873, 59.953335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папа джонс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 10к1",
                "opening_hours": "+7 812 4906640",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350902, 59.836147]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папаша Клаусс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "Mo-Sa 10:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПаратовЪ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 9",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/паратовъ-спб.рф\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.929637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парк",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 6",
                "opening_hours": "Mo-Fr 12:00-23:00; Sa-Su 12:00-00:00",
                "contact_phone": "+7 (812) 959-57-00",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.407092, 59.961796]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парк Джузеппе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 2В",
                "opening_hours": "Su-Th 11:00-00:00, Fr-Sa 11:00-01:00",
                "contact_phone": "+7 812 5717309",
                "website": "https:\/\/park-restaurant.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.330277, 59.941092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парковый",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, улица Аврова, 14",
                "opening_hours": "Mo-Fr 00:00-01:00,11:00-24:00; Sa,Su 00:00-06:00,11:00-24:00",
                "contact_phone": "+7 812 4505405",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.922549, 59.875737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парма суши",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 5",
                "opening_hours": "Mo-Fr 09:00-01:00; Sa-Su 10:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317692, 59.977481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Паруса",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петровская коса, 9",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23917, 59.965615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Паруса на крыше",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 9",
                "opening_hours": "Mo-We,Su,PH 12:00-01:00; Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 2105794",
                "website": "https:\/\/nakryshe.parusa-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.31567, 59.965124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПАСТА",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 86",
                "opening_hours": "24\/7",
                "contact_phone": "+79218665599",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.309598, 59.964485]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 33",
                "opening_hours": "http:\/\/ibtspb.ru\/ibt_restaurants_obwestvennoe_pitanie\/restoran_pekin\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304954, 59.921692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 7к1",
                "opening_hours": "+7 812 4505506",
                "contact_phone": "https:\/\/vk.com\/pekin_petergof",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.902674, 59.882056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекин-Токио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 52",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38979, 59.97459]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмения",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 55",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 4154184",
                "website": "http:\/\/pelmenya.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.307801, 59.955237]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмения",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 25",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 4154185",
                "website": "http:\/\/pelmenya.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.342782, 59.93419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмения",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 10\/15",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/пельмения.рф\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345027, 59.925259]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмения",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 8",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/пельмения.рф\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.355232, 59.930201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмешъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 76",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272722, 59.947775]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмешъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60",
                "opening_hours": "8:00-22:00",
                "contact_phone": "+7 921 4006336",
                "website": "https:\/\/delivery.pelmesh-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.891281, 59.882197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пенаты",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 411А",
                "opening_hours": "Mo-Su 10:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.89334, 60.157381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пеперони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/peperoni.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пеперони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 22",
                "opening_hours": "http:\/\/peperoni.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312239, 60.015152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Перец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 25",
                "opening_hours": "09:00-23:00",
                "contact_phone": "+7 812 9002560",
                "website": "https:\/\/perezz.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.312958, 59.934168]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Персия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 65",
                "opening_hours": "http:\/\/persiaspb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30481, 59.922116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Персоны",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 11-я Красноармейская улица, 1",
                "opening_hours": "https:\/\/personyrest.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306822, 59.912513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербург",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 11",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315248, 59.868984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петергоф лофт",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, холм Троицкая Гора, 33",
                "opening_hours": "https:\/\/marinohall.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.835771, 59.867664]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петруша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский переулок, 2",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 921 4146765",
                "website": "http:\/\/petrusharest.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.296321, 59.932036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петруша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский переулок, 2",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 921 4146765",
                "website": "http:\/\/www.petrusharest.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.296321, 59.932036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 137",
                "opening_hours": "Mo-We 12:00-23:00; Th-Sa 12:00-01:00; Su 12:00-23:00",
                "contact_phone": "+7 812 4495695;+7 812 9394310",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.314673, 59.881719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Александра Невского, 2Е",
                "opening_hours": "http:\/\/pivorama.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38697, 59.924794]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "opening_hours": "Mo 13:00-00:00; Tu-We 13:00-01:00; Th 13:00-02:00; Fr 13:00-03:00; Sa 12:00-03:00; Su 12:00-00:00",
                "contact_phone": "+7 812 9076191",
                "website": "http:\/\/www.pivorama.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "+7 812 9542884",
                "contact_phone": "http:\/\/www.pivorama.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 120",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321204, 60.036537]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиворама",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 108к1",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 3835817",
                "website": "http:\/\/pirogov-dvorik.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.206175, 59.832756]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 24к2",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa,Su 09:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475589, 59.921741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиросмани",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 14",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 2354666",
                "website": "http:\/\/www.pirosmani.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.293222, 59.954502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пицца",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Наставников, 36к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482982, 59.952804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пицца 22 см",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 45",
                "opening_hours": "Mo-Th 12:00-23:00; Fr 12:00-23:30; Sa 12:00-23:30; Su 13:00-23:00",
                "contact_phone": "+7 812 9453124",
                "website": "http:\/\/pizza22cm.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35826, 59.935696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пицца Хат",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиццерия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 116",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218365, 59.985998]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Планета Суши",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "+7 812 3335264",
                "contact_phone": "http:\/\/planetsushi.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Планета Суши",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "opening_hours": "+7 965 0902253",
                "contact_phone": "http:\/\/planetsushi.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 9к2",
                "opening_hours": "24\/7",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263559, 60.004307]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Погриб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Чебоксарский переулок, 1\/6",
                "opening_hours": "+7 812 406 90 06",
                "contact_phone": "http:\/\/am-pir.ru\/banketnyj-restoran-ampir\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.326378, 59.937562]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подворье",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Тярлево, Фильтровское шоссе, 16",
                "opening_hours": "+7 812 4668544",
                "contact_phone": "http:\/\/www.podvorye.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.438386, 59.696285]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Поднебесная",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Уточкина, 9",
                "opening_hours": "00:00-06:00,14:00-24:00",
                "contact_phone": "+7 812 3098977",
                "website": "http:\/\/skyspace.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.26497, 60.012588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Полный Балет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31117, 59.936043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Поляна",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 89",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324905, 60.026046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Порто Мальтезе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 57",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 5703451",
                "website": "http:\/\/portomaltese.spb.ru\/fontanka\/menu\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325821, 59.925719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Портъ Артуръ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 12\/17",
                "opening_hours": "Su-Th 11:00-23:00, Fr-Sa 11:00-01:00",
                "contact_phone": "http:\/\/portartur-spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.338021, 59.921435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Посиделки",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 115к1",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 9469026",
                "website": "http:\/\/vk.com\/posidelki.rest"
            },
            "geometry": {"type": "Point", "coordinates": [30.40313, 60.043777]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Премьера",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 9-13",
                "opening_hours": "Su-Th 11:00-24:00, Fr-Sa 11:00+",
                "contact_phone": "http:\/\/artpremiera.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.372022, 59.936994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пристань",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Гаванная улица, 2А",
                "opening_hours": "+78126110305",
                "contact_phone": "https:\/\/yct.su\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.702031, 60.187867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Причал 122",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 122",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 240-36-00",
                "website": "https:\/\/nevskybereg.ru\/ru\/nebesnyj-portal\/restoran-nevskaya-volna"
            },
            "geometry": {"type": "Point", "coordinates": [30.364215, 59.930818]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Провинция",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 106",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313308, 59.967565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Проспект",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 21",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/restaurant-prospekt.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.309751, 59.934659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пряности & Радости",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 13",
                "opening_hours": "Mo-Sa 10:00-01:00; Su 10:00-23:00",
                "contact_phone": "+7 812 6401616",
                "website": "https:\/\/ginza.ru\/spb\/restaurant\/pryanosti_i_radosti_vasiljevsky"
            },
            "geometry": {"type": "Point", "coordinates": [30.282262, 59.940343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пряности & Радости",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 3",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3334633",
                "website": "http:\/\/ginzaproject.ru\/SPB\/Restaurants\/pryanostiradosti2\/About"
            },
            "geometry": {"type": "Point", "coordinates": [30.320872, 59.957642]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пряности & радости",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 191",
                "opening_hours": "Mo-Su 06:00-23:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/pryanosti_i_radosti_moskivsky"
            },
            "geometry": {"type": "Point", "coordinates": [30.320396, 59.85275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пряности & Радости",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Белинского, 5",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6401616",
                "website": "https:\/\/ginza.ru\/spb\/restaurant\/pryanosti_i_radosti_belinskogo"
            },
            "geometry": {"type": "Point", "coordinates": [30.345818, 59.938072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Птичий двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 126",
                "opening_hours": "+7 812 9342020",
                "contact_phone": "https:\/\/www.ptichiydvor.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.204342, 59.987407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Птички и Ягоды",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Приморское шоссе, 572",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 921 9570092",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.654105, 60.191612]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 17к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479236, 59.943823]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пури Оджахури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 29",
                "opening_hours": "11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35216, 59.926801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пхали-Хинкали",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 27",
                "opening_hours": "Mo-Fr 08:00+; Sa-Su 12:00+",
                "contact_phone": "+7 812 9200012",
                "website": "https:\/\/phali-hinkali.ru\/morskaya.php"
            },
            "geometry": {"type": "Point", "coordinates": [30.312428, 59.933992]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пхали-Хинкали",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 32-34Б",
                "opening_hours": "https:\/\/phali-hinkali.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328768, 59.936377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пхали-Хинкали",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 71",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9060648",
                "website": "https:\/\/phali-hinkali.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.337131, 59.840239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пять Шампуров",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Крыленко, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477682, 59.897176]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рада и К",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гатчинская улица, 26",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296357, 59.963084]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Раковая",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 16",
                "opening_hours": "Mo-Th, Su 12:00-01:00; Fr, Sa 12:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290643, 59.954791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рандеву",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 4к5",
                "opening_hours": "Mo-Th 13:00-02:00; Fr-Sa 13:00-04:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364754, 60.035688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рестобар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 8\/2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347983, 59.926472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк, Разводная, 2а",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.913653, 59.88451]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 121к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.946793, 59.87526]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Солнечное, Приморское шоссе, 371",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.938261, 60.148293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 116",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218365, 59.985998]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан \"Максим\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, Приморское шоссе, 440",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 4321332",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.832387, 60.166095]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан «Ферма Бенуа»",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 17Г",
                "opening_hours": "12:00-24:00",
                "contact_phone": "https:\/\/benua1890.ru\/benua-farm",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.370387, 60.019388]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан 48\/26",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Красноборский переулок, 4",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+79117374826",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.371887, 59.934799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Нахимова, 26",
                "opening_hours": "+78126020930",
                "contact_phone": "https:\/\/tokyo-city.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.221311, 59.93882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторанъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Университетская набережная, 5Б",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 3260137",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.302133, 59.941398]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ретро",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Красное Село, улица Лермонтова, 21",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9347670",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.081415, 59.734041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ретрохолл",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Зайцева, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267314, 59.872453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рибай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 3",
                "opening_hours": "https:\/\/ginza.ru\/spb\/restaurant\/ribai",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322417, 59.933816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рим",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 69",
                "opening_hours": "http:\/\/www.restorimspb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313946, 59.926336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Родня",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Крупской, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432407, 59.890478]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Розарио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265877, 60.001073]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Розарио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 5В",
                "opening_hours": "Mo-Th 10:00-01:00; Fr 10:00-03:00; Sa 12:00-03:00; Su 12:00-01:00",
                "contact_phone": "+7 812 2447401",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.314548, 59.969547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Розарио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 5",
                "opening_hours": "Mo-Fr 10:00-01:00; Sa-Su 12:00-01:00",
                "contact_phone": "http:\/\/wasabicobanket.ru\/restorants\/japan-italian\/na-yaroslava-gasheka-5",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.830676]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ротонда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Школьная улица, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40545, 59.729619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рубинштейн",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": "Mo-Su 00:00-02:00,10:00-24:00",
                "contact_phone": "+7 960 2598393",
                "website": "https:\/\/www.caferubinstein.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рукола",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 56",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322264, 60.014661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рулька",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 46к1",
                "opening_hours": "Fr-Sa 12:00-06:00; Su-Th 12:00-02:00",
                "contact_phone": "+7 812 9274888",
                "website": "www.rulka-spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.206022, 60.001086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русская рыбалка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 452А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.813292, 60.170663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русская рыбалка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Южная дорога, 11",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233259, 59.969326]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русская Рюмочная №1",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 4",
                "opening_hours": "https:\/\/www.vodkaroom.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298171, 59.932698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русский Ампиръ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 17",
                "opening_hours": "17:00+",
                "contact_phone": "http:\/\/russianampir.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русский Дворик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русский чай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Радищева, 2\/10",
                "opening_hours": "12:00-22:00",
                "contact_phone": "+7 812 4654824",
                "website": "http:\/\/www.russian-tea.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.407007, 59.711063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русское застолье",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 97",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320324, 60.025992]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рыба на Даче",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 319",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/probka.org\/restaurants\/ryba_na_dache",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.984349, 60.124983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рыбный ресторан",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 24к6",
                "opening_hours": "24\/7",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414, 59.909445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рэро",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ленина, 41",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 2301278",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.298935, 59.964525]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сабатони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Правды, 8",
                "opening_hours": "Mo-Su 11:00-23:59",
                "contact_phone": "+7 960 2674749",
                "website": "https:\/\/sabatoni.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.343698, 59.923771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Садко",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Глинки, 2",
                "opening_hours": "12:00-01:00",
                "contact_phone": "+7 812 903-23-73",
                "website": "http:\/\/www.sadko-rst.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296159, 59.927473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сакартвело",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 12-я линия Васильевского острова, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274267, 59.938482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самсон",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 44А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.90787, 59.879792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сандал",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 5",
                "opening_hours": "12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327654, 59.939762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санторини",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 137к1",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 812 9707979",
                "website": "http:\/\/santorinispb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.216146, 59.983531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сацебели",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 79",
                "opening_hours": "+7 812 9039395",
                "contact_phone": "http:\/\/sacebeli.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.334769, 59.98864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сациви",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322327, 59.911881]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свадебный зал \"Праздник\"",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 14к1",
                "opening_hours": "http:\/\/www.gagarina14.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338281, 59.861824]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свет востока",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 12, подъезд 3",
                "opening_hours": "11:30-23:30",
                "contact_phone": "https:\/\/svet-vostoka.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.369686, 59.931526]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свет Востока",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 36-38",
                "opening_hours": "11:30-23:30",
                "contact_phone": "https:\/\/svet-vostoka.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.277133, 59.911502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свет Востока",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 17к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.187113, 59.847706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Северный модерн",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лодейнопольская улица, 5",
                "opening_hours": "http:\/\/n-modern.ru",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289359, 59.963787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Северянин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Столярный переулок, 18\/69",
                "opening_hours": "+79219516396",
                "contact_phone": "https:\/\/www.severyanin.me\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.313281, 59.92639]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Семейный очаг",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 32к2",
                "opening_hours": "Mo-Sa 11:00-23:59",
                "contact_phone": "+7 812 364-67-98",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.270431, 59.848439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Семейный уют",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Типанова, 29",
                "opening_hours": "Mo-Su 12:00-23:30",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35728, 59.85397]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Семь красавиц",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 35к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469866, 59.954232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сеновал",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 13",
                "opening_hours": "11:30-23:30",
                "contact_phone": "https:\/\/www.senoval-traktir.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.303184, 59.92832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сеул",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 8-10",
                "opening_hours": "11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322049, 59.932509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Симпозиум",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Достоевского, 19\/21М",
                "opening_hours": "http:\/\/www.symposium.rest\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346555, 59.923311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Син Хуа",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Университетская набережная, 25",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287086, 59.936746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 415",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_phone": "+7 812 4321251",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.890851, 60.158719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказки Шахерезады",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 193",
                "opening_hours": "Mo-Su 00:00-01:00,12:00-24:00",
                "contact_phone": "+7 812 3731927",
                "website": "http:\/\/www.skazki-shaherezady.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320485, 59.851245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Склад",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 104",
                "opening_hours": "Su-Th 14:00-01:00; Fr-Sa 14:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226881, 59.852624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Склад 22",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 22",
                "opening_hours": "Mo-Fr 10:00-22:00; Sa-Su 11:00-23:00",
                "contact_phone": "+78129007222",
                "website": "https:\/\/sklad22.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.409506, 59.718886]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сокол",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Красное Село, улица Первого Мая, 15",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.096522, 59.729763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Соленья-Варенья",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Средняя улица, 2А",
                "opening_hours": "http:\/\/www.restaurantpushkin.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396684, 59.71947]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Солнечная Долина",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Полевая улица, 12",
                "opening_hours": "Mo-Th 13:00-01:00; Fr-Su 13:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.564555, 59.814429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сорбет",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 45\/20",
                "opening_hours": "11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410659, 59.718081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Соседи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 6710003",
                "website": "http:\/\/ресторан-соседи.рф\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сочи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 7А",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9010808",
                "website": "http:\/\/bashnya-pushkin.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.398978, 59.71784]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Станица",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 67к1",
                "opening_hours": "Mo-Th 11:00-23:00; Fr-Su 11:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25852, 59.872638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старая Деревня",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 72",
                "opening_hours": "Mo-Su 13:00-22:00",
                "contact_phone": "+7 812 4310000",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.271833, 59.983779]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старая Крепость",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Восстания, 13",
                "opening_hours": "Mo-Fr 12:00-00:00; Sa-Su 12:00-03:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.768658, 60.001505]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старая таможня",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Биржевой проезд, 2",
                "opening_hours": "+7 812 3278980",
                "contact_phone": "http:\/\/oldcustom.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.30375, 59.942056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старгород",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7",
                "opening_hours": "http:\/\/www.stargorod.net\/sp\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321042, 59.932378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый Биджо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 8",
                "opening_hours": "https:\/\/oldbidjo.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357792, 59.929912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый город",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 21",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 3661922",
                "website": "http:\/\/staryj-gorod.obiz.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.402017, 59.841523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый Пловдив",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 140Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.16885, 59.832236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый Тбилиси",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 25",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240401, 59.932207]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый Тбилиси",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 91",
                "opening_hours": "https:\/\/www.hachopuri.ru\/restorany\/nevskij-d-91.html",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365284, 59.930327]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Статус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новорощинская улица, 4",
                "opening_hours": "+7 812 6767717",
                "contact_phone": "http:\/\/www.restoran-status.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.326729, 59.884591]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стейк Давай!",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 157",
                "opening_hours": "12:00-05:00",
                "contact_phone": "+7 812 3885855",
                "website": "http:\/\/mex-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319722, 59.870619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стрельна",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 58А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.060864, 59.851107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Строганов Стейк Хаус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Якубовича, 3",
                "opening_hours": "Mo-Fr 08:00-24:00; Sa-Su 10:00-24:00",
                "contact_phone": "+7 812 3145514",
                "website": "https:\/\/stroganoffsteakhouse.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.30039, 59.933208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сулико",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 6",
                "opening_hours": "https:\/\/www.sulikospb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322605, 59.933054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сулико",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 7",
                "opening_hours": "11:00-24:00",
                "contact_phone": "https:\/\/sulikospb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360407, 59.933127]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Суп харчо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 55",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348082, 59.922445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "суши wok",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 270",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.956309, 60.08593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Счастье",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 15-17",
                "opening_hours": "Mo-Th 09:00-23:00, Fr 09:00-01:00, Sa 10:00-01:00, Su 10:00-23:00",
                "contact_phone": "+7 812 5722675",
                "website": "https:\/\/schastye.com\/restaurants\/rubinsteina"
            },
            "geometry": {"type": "Point", "coordinates": [30.343671, 59.92892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Счастье есть",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Клинский проспект, 9",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 3160158",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.325255, 59.915869]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Счастье есть",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос, Приморское шоссе, 44",
                "opening_hours": "+7 812 9529997",
                "contact_phone": "http:\/\/happy-hall.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.010464, 60.014452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сыроварня",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ковенский переулок, 5Б",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-01:30",
                "contact_phone": "+7 812 2070100",
                "website": "http:\/\/syrovarnya-kafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.356921, 59.936873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сытинъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 2",
                "opening_hours": "12:00+",
                "contact_phone": "http:\/\/sitinrestoran.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.308161, 59.91802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сян Лунь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Седова, 26",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 3651841",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.413533, 59.894405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Таверна Гролле",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 20",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283035, 59.938694]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Таверна на карпинского",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Карпинского, 16А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419974, 60.004816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тайвань",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297201, 59.917835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТаймАут",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 305",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.97727, 60.104587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тайна",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронштадт, территория 7-й Северный форт",
                "opening_hours": "+7 965 0458809",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.919546, 60.031946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Такао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 106",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 2732966;+7 963 2460830",
                "website": "http:\/\/takao.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.356912, 59.931846]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Такао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 50\/48",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6775665",
                "website": "http:\/\/www.takao.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.404253, 59.863274]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тако",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 10к2",
                "opening_hours": "+7 812 4310507",
                "contact_phone": "http:\/\/takorg.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.297713, 59.985953]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Талион",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 59",
                "opening_hours": "https:\/\/www.taleonimperialhotel.com\/restaurant\/st-petersburg-restaurant-taleon\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318563, 59.935764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 19",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283987, 59.940078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 144",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 7768935",
                "website": "http:\/\/tang-ren.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.419677, 59.833836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 48",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/www.tang-ren.ru\/page\/restoran_na_gorohovoj.htm",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.32557, 59.926323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 48",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/www.tang-ren.ru\/page\/restoran_na_kurljandskoj.htm",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.267314, 59.91111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Моховая улица, 43",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 2731098",
                "website": "http:\/\/www.tang-ren.ru\/page\/_restoran_na_mohovoj.htm"
            },
            "geometry": {"type": "Point", "coordinates": [30.34474, 59.93909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 74-76Г",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 812 2729017",
                "website": "http:\/\/www.tang-ren.ru\/page\/restoran_na_nevskom.htm"
            },
            "geometry": {"type": "Point", "coordinates": [30.346806, 59.93309]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 3",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 6178609",
                "website": "http:\/\/www.tang-ren.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.228381, 59.847358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фонарный переулок, 7",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 5700056",
                "website": "http:\/\/www.tang-ren.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.304963, 59.928068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан Чао",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30719, 59.918453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тан-Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 160",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 6128692",
                "website": "http:\/\/www.tang-ren.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.301055, 59.852384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 37Щ",
                "opening_hours": "Mo-Fr 08:00-19:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299941, 59.971564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тартарбар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Виленский переулок, 15",
                "opening_hours": "Mo-Su 13:00-24:00",
                "contact_phone": "+7 911 9225606",
                "website": "http:\/\/tartarbar.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.365231, 59.939965]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тархун",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 14",
                "opening_hours": "http:\/\/www.restorantarkhun.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341856, 59.935976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тбилисити",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 10",
                "opening_hours": "11:00-00:00",
                "contact_phone": "+7 812 9500356",
                "website": "http:\/\/tbilicity.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349178, 59.946018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тбилисо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 10",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 812 2329391",
                "website": "http:\/\/triton-restoran.ru\/restaurants\/tbiliso\/about"
            },
            "geometry": {"type": "Point", "coordinates": [30.307774, 59.957723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Театральная трапеза",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 35\/75",
                "opening_hours": "Mo-Su 11:00-23:30",
                "contact_phone": "+7 812 3462273",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.312859, 59.965809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Текила-Бум",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 42к3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346339, 60.050785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Телепицца",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 91к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212598, 59.83376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Телепицца",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 138к2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "https:\/\/telepizza-russia.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.484715, 59.845785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тепло",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 45",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30543, 59.931995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тёплые края",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 48к2",
                "opening_hours": "Mo-Th 11:00-23:00; Fr 11:00-23:30; Sa-Su 11:00-00:00",
                "contact_phone": "+7 812 2447320",
                "website": "http:\/\/restk.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.26187, 59.94321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тесто",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 5",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/www.testogastronomica.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.313703, 59.929741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тётя Аида",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 6",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/aidafamily.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.294857, 59.931837]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 4-й Верхний переулок, 19",
                "opening_hours": "Mo-Su 12:00-03:00; Fr,Sa 12:00-06:00",
                "contact_phone": "+7 812 6128192",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341376, 60.073207]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 4",
                "opening_hours": "Su-Th 11:00-03:00; Fr-Sa 11:00-06:00",
                "contact_phone": "+7 812 3947545",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.291084, 59.999354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 55к1",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6775597",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.213891, 60.00715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 31",
                "opening_hours": "Su-Th 11:00-03:00; Fr-Sa 11:00-06:00",
                "contact_phone": "+7 812 6777062",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311394, 59.933658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 30",
                "opening_hours": "Su-Th 11:00-01:00; Fr-Sa 11:00-03:00",
                "contact_phone": "+7 812 2307320",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.297785, 59.957246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 72к1",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6773121",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.378283, 59.874024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 9к1",
                "opening_hours": "Mo-Th 12:00-01:00; Fr,Sa 12:00-03:00, Su 12:00-01:00",
                "contact_phone": "+7 812 677-76-28",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.438066, 59.912202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дачный проспект, 18",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6127506",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.255043, 59.8424]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 26\/24",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6777970",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.4739, 59.947946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 25к1",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6779807",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.250848, 60.016249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное, Приморское шоссе, 388А",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-03:00",
                "contact_phone": "+7 812 6778892",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.900637, 60.153422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Народная улица, 2",
                "opening_hours": "Su-Th 12:00-24:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 6779578",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.456913, 59.879895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 60",
                "opening_hours": "Su-Th 11:00-03:00, Fr-Sa 11:00-06:00",
                "contact_phone": "+7 812 6122490",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33917, 59.93406]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 90-92",
                "opening_hours": "Su-Th 11:00-03:00; Fr-Sa 11:00-06:00",
                "contact_phone": "+7 812 6124794",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.352403, 59.932383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 16",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6772984",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.409589, 59.967799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 9",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Sa 12:00-03:00; Su 12:00-01:00",
                "contact_phone": "+7 812 2075307",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.273495, 59.899925]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 101Бс1",
                "opening_hours": "Fr-Sa 11:00-03:00; Su-Th 11:00-01:00",
                "contact_phone": "+7 812 6775201",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.015178, 59.85628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 101Бс1",
                "opening_hours": "Fr-Sa 11:00-03:00; Su-Th 11:00-01:00",
                "contact_phone": "+7 812 6775201",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.015626, 59.856197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Прибрежная улица, 20",
                "opening_hours": "https:\/\/www.tokyo-city.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.505125, 59.831752]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 9к1",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6772986",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.474457, 59.914263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 114к1",
                "opening_hours": "Mo-Th 11:00-03:00; Fr 11:00-06:00; Sa 12:00-06:00; Su 12:00-03:00",
                "contact_phone": "+7 812 6773600",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.198404, 59.832543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Науки, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402124, 60.010807]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 48",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6773130",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353831, 60.049761]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 72",
                "opening_hours": "Sa-Th 12:00-03:00;Fr-Su 12:00-06:00",
                "contact_phone": "+7 812 6778087",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.384544, 60.043228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 15",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-04:00",
                "contact_phone": "+7 812 6128053",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.374501, 59.854788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 46",
                "opening_hours": "Mo-Th 11:00-00:00; Fr 11:00-01:00; Sa 12:00-01:00; Su 12:00-00:00",
                "contact_phone": "+7 812 3343628",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.267898, 59.886036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 133к1",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "https:\/\/www.tokyo-city.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.333394, 60.048804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 7",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-03:00",
                "contact_phone": "https:\/\/www.tokyo-city.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317773, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 34",
                "opening_hours": "Su-Th 11:00-01:00; Fr-Sa 11:00-06:00",
                "contact_phone": "https:\/\/www.tokyo-city.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.27698, 59.942444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Доблести, 9",
                "opening_hours": "Su-Th 12:00-03:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 6772300",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.17971, 59.863292]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-06:00",
                "contact_phone": "https:\/\/www.tokyo-city.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ольги Берггольц, 8",
                "opening_hours": "Mo-Th 11:00-23:00; Fr 11:00-01:00; Sa 12:00-01:00; Su 12:00-23:00",
                "contact_phone": "https:\/\/www.tokyo-city.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.430071, 59.896012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "Mo-Th 12:00-03:00; Fr 12:00-06:00; Sa 11:00-06:00; Su 11:00-03:00",
                "contact_phone": "+7 812 6774460",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Токио-City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 18",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "+7 812 3053565",
                "website": "https:\/\/www.tokyo-city.ru\/adress\/tc_spb\/ul-chajkovskogo-18.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.350831, 59.946788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТокиоСити",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 2",
                "opening_hours": "Mo-Su 00:00-01:00,11:00-24:00; Th,Fr 00:00-03:00,11:00-24:00",
                "contact_phone": "+7 812 2451425",
                "website": "https:\/\/www.tokyo-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.925044, 59.877451]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Три башни",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 35к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199572, 59.995638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трибуна",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 100Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403804, 60.023978]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкий мост",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 25",
                "opening_hours": "Mo-Su 08:30-22:30",
                "contact_phone": "http:\/\/t-most.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.321222, 59.94275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тройка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Джамбула, 21",
                "opening_hours": "12:00-00:30",
                "contact_phone": "http:\/\/www.troika-spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.337086, 59.924393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трюфельный дом Bruno",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский проспект, 10",
                "opening_hours": "http:\/\/dombruno.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309122, 59.93575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тянь-цзинь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 75",
                "opening_hours": "11:30-23:30",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364593, 59.946198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Горчакова",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 17-19Б",
                "opening_hours": "+7 921 3244559",
                "contact_phone": "http:\/\/ygorchakova.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.319713, 59.961746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У камина",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.818896, 60.168739]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Нарвских ворот",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 1",
                "opening_hours": "+7 812 7868910",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274231, 59.898896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У пруда",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 40к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.142987, 59.787578]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Узбегим",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Товарищеский проспект, 20\/27Б",
                "opening_hours": "24\/7",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486458, 59.914552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уно Моменто",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колпино, улица Анисимова, 5к5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.605468, 59.733114]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Усадьба",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Мосина, 28",
                "opening_hours": "11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.967292, 60.087981]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ф.М. Достоевский",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 9А",
                "opening_hours": "http:\/\/www.dostoevskyfm.com\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347381, 59.93062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фаворит",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Стремянная улица, 18",
                "opening_hours": "https:\/\/favorit-restoran.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353265, 59.930886]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фаджоли",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лыжный переулок, 4к1",
                "opening_hours": "Mo-Th 12:00-00:00; Fr-Sa 12:00-02:00; Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199949, 59.998247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фарт",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кронштадт, Коммунистическая улица, 3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.770644, 59.989729]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Феникс",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 18\/2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290931, 59.940781]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ферма",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 22",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387841, 59.92901]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ферма",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Финляндский проспект, 1",
                "opening_hours": "http:\/\/www.ferma-restaurant.ru",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342081, 59.958381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фламбе",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 164",
                "opening_hours": "http:\/\/www.фламбе.рф\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285245, 59.919292]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Франсманни",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Батайский переулок, 3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320638, 59.913541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фу Ченг",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 34к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.50349, 59.946247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ханбай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Народная улица, 87Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488057, 59.884415]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хаочи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Английский проспект, 48",
                "opening_hours": "11:30-00:00",
                "contact_phone": "http:\/\/hao-chi.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.288326, 59.919662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хаочи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Друскеникский переулок, 4",
                "opening_hours": "Mo-Su 11:00-23:30",
                "contact_phone": "http:\/\/hao-chi.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.351891, 59.945513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харбин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 91",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206642, 59.85463]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харбин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 54",
                "opening_hours": "https:\/\/harbin-spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315994, 59.932757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харбин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Науки, 19к3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393779, 60.013681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харбин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 33к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344533, 60.050273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харбин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Радищева, 2",
                "opening_hours": "12:00-23:00",
                "contact_phone": "https:\/\/harbin-spb.ru\/kitajskij-restoran-na-ulitse-zhukovskogo-d-34-2-v-tsentralnom-rajone-g-sankt-peterburga\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.361853, 59.936021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харвест",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 11",
                "opening_hours": "+79119222746",
                "contact_phone": "http:\/\/harvestduo.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.301585, 59.949605]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харчевня XVI век",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кропоткина, 19\/8",
                "opening_hours": "Mo-Fr 11:00-00:00; Sa-Su 12:00-00:00",
                "contact_phone": "https:\/\/vk.com\/club79814308",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.306813, 59.958706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 88",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204477, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "хачапури и вино",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299429, 60.001523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури и вино",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 9",
                "opening_hours": "09:00-03:00",
                "contact_phone": "+7 921 5571899",
                "website": "http:\/\/hachapuriivino.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.324528, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури и вино",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 10\/9",
                "opening_hours": "Su-Th 11:30-00:00; Fr-Sa 11:30-01:00",
                "contact_phone": "+7 952 2322543",
                "website": "http:\/\/hachapuriivino.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341677, 59.923383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури тетушки Марико",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 5",
                "opening_hours": "Mo-Th, Su 12:00-00:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 911 9205989",
                "website": "https:\/\/vhachapuri.ru\/spb"
            },
            "geometry": {"type": "Point", "coordinates": [30.318096, 59.927658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури тётушки Марико",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 5",
                "opening_hours": "+7 911 920 7290",
                "contact_phone": "https:\/\/vhachapuri.ru\/spb",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.318096, 59.927658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачо и Пури",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 29",
                "opening_hours": "12:00-23:00",
                "contact_phone": "https:\/\/hachoipuri.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.362077, 59.933573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хванчкара",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 14",
                "opening_hours": "10:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344012, 59.926512]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хванчкара",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Репина, 45",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285442, 59.944643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хижина",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Правды, 1\/11А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344426, 59.924785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная на Неве",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 13-15\/50",
                "opening_hours": "http:\/\/hinkaly.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335074, 59.934898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная на Неве",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 34",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378166, 59.940506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная Сабатони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 60к3",
                "opening_hours": "Mo-Su 11:00-23:59",
                "contact_phone": "+7 960 2674749",
                "website": "https:\/\/sabatoni.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.365581, 60.046922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хозяин-Барин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 24",
                "opening_hours": "https:\/\/www.kochubeyclub.com\/restoran",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355178, 59.945472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Холст Масло",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 4",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 5704069",
                "website": "http:\/\/holstmaslo.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318096, 59.937432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хорошее место",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Планерная улица, 59",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225291, 60.02222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу кебаб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371905, 59.935471]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу харчо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 39-41",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6401616",
                "website": "https:\/\/ginza.ru\/spb\/restaurant\/hochu_harcho"
            },
            "geometry": {"type": "Point", "coordinates": [30.315895, 59.926197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу шашлык",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Бринько, 6",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315482, 59.925331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу шашлык",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 33",
                "opening_hours": "http:\/\/xochu-shashlik.ru\/hochu-shashlyik-na-razezzhey-33\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350552, 59.922318]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу шашлык",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 6",
                "opening_hours": "http:\/\/www.xochu-shashlik.ru\/hochu-shashlyik-na-pestelya-6\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342764, 59.942746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хуа Жен",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тамбовская улица, 70",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 3256500",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.351891, 59.908759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хуан Гун",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Конюшенная улица, 9\/38",
                "opening_hours": "Mo-Th 12:00-00:00;Fr-Su 12:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408845, 59.715799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царский двор",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 61к2",
                "opening_hours": "10:00-02:00",
                "contact_phone": "+7 812 2439899",
                "website": "https:\/\/carskiy-dvor.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.335874, 59.864137]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 12",
                "opening_hours": "+7 812 6401616",
                "contact_phone": "http:\/\/www.tsar-project.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.336116, 59.935534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цезарь",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378364, 59.845428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральная столовая",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Красного Текстильщика, 10-12Д",
                "opening_hours": "Mo 09:00-19:00; Tu 09:00-20:00; We 09:00-19:00; Th 09:00-20:00; Fr 09:00-19:00; Sa 09:00-19:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396456, 59.941452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральный",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 62",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388739, 59.945851]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цзао Ван",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 28",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 5716921",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.309005, 59.926896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цзао Ван",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 114",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 9521925",
                "website": "https:\/\/chinacook.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.242934, 59.852447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цзао Ван",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Перекопская улица, 5",
                "opening_hours": "+7 812 7852739",
                "contact_phone": "https:\/\/chinacook.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.902221]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цзао Ван",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 115",
                "opening_hours": "+7 812 5405669",
                "contact_phone": "https:\/\/chinacook.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.387688, 59.974667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Циркус",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 41",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 6034141",
                "website": "http:\/\/circus41.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.218563, 59.85111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чабрец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 93",
                "opening_hours": "Mo-Th, Su 11:00-02:00; Fr, Sa 11:00-04:00",
                "contact_phone": "http:\/\/chegroup.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.210208, 59.85393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чабрец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 161",
                "opening_hours": "Mo-Su,PH 09:00-06:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319884, 59.867999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чабрец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 9к1",
                "opening_hours": "Su-Th 11:00-02:00, Fr-Sa 11:00-06:00",
                "contact_phone": "+7 812 9832183",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.474457, 59.914263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайка",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, территория Острова",
                "opening_hours": "Mo-Th 12:00-24:00; Fr-Sa 12:00-01:00; Su 12:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258645, 59.974374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайный Дом по-восточному",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Александра Невского, 9",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385488, 59.926395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ольховая улица, 18",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25463, 60.016447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 43",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366048, 60.031589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана восток",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Лизы Чайкиной, 18",
                "opening_hours": "+7 911 1666664",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302528, 59.955439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Кошер",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 8\/10А",
                "opening_hours": "Mo-Th 12:00-23:00; Fr 10:00-sunset; Su 10:00-00:00",
                "contact_phone": "+7 812 7707770",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.292117, 59.923482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Пахвала",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "opening_hours": "cafe",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Пиала",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 5В",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-03:00",
                "contact_phone": "+7 812 2447301",
                "website": "http:\/\/chaihanapiala.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.314548, 59.969547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Самарканд",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 72А",
                "opening_hours": "cafe",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398055, 60.013924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Хива",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 91",
                "opening_hours": "24\/7",
                "contact_phone": "cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291416, 59.919103]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Чабрец",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Морской переулок, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.970741, 59.87096]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чаплин-клуб",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 59",
                "opening_hours": "Tu,Th 18:00-20:00; Sa[2],Su[4] 12:00-14:00",
                "contact_phone": "+7 812 2726649",
                "website": "http:\/\/www.chaplin-club.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.36011, 59.946266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Челентано",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Октябрьский бульвар, 5",
                "opening_hours": "+7 812 4665743",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403113, 59.725952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чеми",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 200",
                "opening_hours": "Su-Th 12:00-24:00, Fr,Sa 12:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321599, 59.860414]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чердак художника",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 30-32Т",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/glassdesign.ru\/cherdak",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.326872, 59.932279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Черемша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное, 41-й километр Приморского шоссе, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.941045, 60.13505]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Четыре сыра",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34103, 59.934907]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чеховъ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 79",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 2344511",
                "website": "http:\/\/restaurant-chekhov.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.313811, 59.966457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чечил",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дегтярный переулок, 2",
                "opening_hours": "https:\/\/ginza.ru\/spb\/restaurant\/chechil",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378103, 59.938175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чили Пицца",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 8",
                "opening_hours": "Mo-Th 09:00-23:00; Fr 09:00+; Sa 10:00+; Su 10:00-23:00",
                "contact_phone": "+7 812 3333323",
                "website": "https:\/\/www.chilipizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.303022, 59.949177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 37",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 9739697",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317782, 59.864589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 71",
                "opening_hours": "http:\/\/chitogvrito.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310559, 59.964593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 53",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/chitogvrito.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.324357, 59.926693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 41-43",
                "opening_hours": "http:\/\/chitogvrito.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330699, 59.921651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кузнецовская улица, 38",
                "opening_hours": "http:\/\/chitogvrito.ru",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32575, 59.872046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 64\/13",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300767, 59.962836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 145А",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 9477108",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317755, 59.877357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 198",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/chitogvrito.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322812, 59.861476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 10",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 9192070",
                "website": "http:\/\/chitogvrito.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316524, 59.867651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 109-111",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/chitogvrito.ru\/contacts\/item\/rimskogo-korsakova-pr-109.html",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.280007, 59.91816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 49",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/chitogvrito.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.294452, 59.922693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 68",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/chitogvrito.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.265347, 59.939072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 20",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330304, 59.853329]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9442040",
                "website": "http:\/\/www.chitogvrito.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.345593, 59.921498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чичи-Бичи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 18",
                "opening_hours": "12:00-24:00",
                "contact_phone": "https:\/\/www.chichi-bichi.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.31029, 59.934231]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чичи-Бичи",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 25",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/www.chichi-bichi.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.324779, 59.932779]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чуансян",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 33\/5",
                "opening_hours": "11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311718, 59.929227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шалаш",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, дорога к Шалашу Ленина, 1Е",
                "opening_hours": "Mo-Su 13:00-23:00",
                "contact_phone": "+7 812 4376055",
                "website": "www.shalash.org"
            },
            "geometry": {"type": "Point", "coordinates": [30.028866, 60.079115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаляпин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Нагорная улица, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.871299, 60.171291]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаляпин",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тверская улица, 12\/15Б",
                "opening_hours": "+7 812 2753210",
                "contact_phone": "http:\/\/www.shalyapinspb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.383179, 59.947054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шампанерия",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дибуновская улица, 18\/11",
                "opening_hours": "Tu-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277699, 59.985575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шанхай",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298171, 59.932698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шарден",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 111Б",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-00:00",
                "contact_phone": "+7 (812) 906-97-69",
                "website": "http:\/\/www.sharden-spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.452978, 59.864435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Швабский домик",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 19",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 5282211",
                "website": "http:\/\/schwabskidomik.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.413793, 59.929082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шелест",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Приморское шоссе, 572А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.648332, 60.191097]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шервуд",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 31к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270512, 60.007492]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ШИ КЭ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289278, 59.985791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шикари",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 30",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3121633",
                "website": "https:\/\/spb.shikary.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.32804, 59.935606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ширван",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 142к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419022, 59.834727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штакеншнейдер",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 10",
                "opening_hours": "https:\/\/ekaterinahotel.com\/ru\/restoran-i-kafe\/restoran-gostanaya-shtakenshnejder.html",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32398, 59.943381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штандарт",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк, 3В",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.908562, 59.887919]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 105-107",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su 09:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318806, 59.890749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шурпа",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27АЕ",
                "opening_hours": "12:00-01:00",
                "contact_phone": "+7 812 3315525",
                "website": "http:\/\/ginzaproject.ru\/SPB\/Restaurants\/Shurpa\/About"
            },
            "geometry": {"type": "Point", "coordinates": [30.327672, 60.007177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шуша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 17Д",
                "opening_hours": "11:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484338, 59.868342]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эль Греко",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 40к1",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398837, 59.861376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эль и вино",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 10",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408853, 59.928695]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эмираты",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 48к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266254, 59.883462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эрарта",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 29-я линия В.О., 2",
                "opening_hours": "Su-Th 12:00-22:00; Fr-Sa 12:00-23:00",
                "contact_phone": "+7 812 3346896",
                "website": "https:\/\/restaurant.erarta.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.251621, 59.931896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эривань",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 51-53",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/erivan.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.337392, 59.9297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эрисиони",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 33\/8",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334373, 59.957638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эскалибур",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Сикейроса, 1",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 911 0164098;+7 812 4956717",
                "website": "http:\/\/www.excaliburspb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322668, 60.034038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Этаж 41",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Конституции, 3к2",
                "opening_hours": "Su-We 11:30-01:00; Th-Sa 11:30-03:00",
                "contact_phone": "+7 812 9374141",
                "website": "https:\/\/41floor.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.304738, 59.850454]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Этнос",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 6",
                "opening_hours": "Mo-Fr 11:00-24:00, Sa-Su 11:00-01:00",
                "contact_phone": "+7 812 3138382",
                "website": "http:\/\/ethnos-cafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350453, 59.92763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ю-ми",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 10\/15",
                "opening_hours": "Mo-Su 11:30-23:30",
                "contact_phone": "https:\/\/www.yumichinese.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.345027, 59.925259]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Юнга",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 23",
                "opening_hours": "Su-Th 12:00-23:30, Fr-Sa 12:00-01:00",
                "contact_phone": "http:\/\/www.youngaseafood.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.343051, 59.927991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Юность",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 21",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/younost"
            },
            "geometry": {"type": "Point", "coordinates": [30.285828, 59.986079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Я ем Мясо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 24А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375759, 60.048436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Я люблю хинкали",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 56",
                "opening_hours": "09:30+",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307271, 59.922219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Якитория",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Крылова, 7",
                "opening_hours": "Mo-Th 08:00-23:00; Fr 08:00-01:00; Sa 11:00-01:00; Su 11:00-23:00",
                "contact_phone": "+7 812 3158343",
                "website": "http:\/\/yakitoriya.spb.ru\/restaurants\/ostrovsky\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.335038, 59.932243]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Якитория",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петровская набережная, 4",
                "opening_hours": "11:00-06:00",
                "contact_phone": "+7 812 9704858",
                "website": "https:\/\/yakitoriya.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.330394, 59.953759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Якитория",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 11",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315248, 59.868984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ялта",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, территория Павильон Урицкого, 1В",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38182, 59.733565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ямамото",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 66к1",
                "opening_hours": "Tu-Fr 00:00-02:00,12:00-24:00; Sa-Mo 00:00-06:00,12:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396061, 60.013883]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ян Гон",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 198",
                "opening_hours": "+7 812 3719250",
                "contact_phone": "http:\/\/yangon.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.322812, 59.861476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Япоша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 17к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255591, 60.012539]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Япоша",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 175",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": "http:\/\/www.yaposhaspb.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.383574, 59.923942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ять",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 16",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9570023",
                "website": "http:\/\/eatinyat.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320369, 59.940713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "All Seasons",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 34",
                "opening_hours": "Mo-Su 12:45-22:45",
                "contact_phone": "+7 812 2400508",
                "website": "https:\/\/instagram.com\/allseasonsrest"
            },
            "geometry": {"type": "Point", "coordinates": [30.312086, 59.93337]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Alt Sherbet",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Басков переулок, 31",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359984, 59.939343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Alt Velvet",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 57",
                "opening_hours": "Mo-Th 11:00-01:00, Fr 11:00-02:30, Sa 12:00-02:30, Su 12:00-01:00",
                "contact_phone": "altvelvet.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.256543, 59.87575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Amarcord",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 49",
                "opening_hours": "Su-Th 11:00-00:00, Fr-Sa 11:00-01:00",
                "contact_phone": "http:\/\/www.amarcordcafe.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.359185, 59.935669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Amo cucinare",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 5",
                "opening_hours": "11:00-22:00",
                "contact_phone": "https:\/\/www.amo-cucinare.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.323863, 59.940294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Angle Vert",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 56",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": "+7 812 2748231",
                "website": "http:\/\/anglevert.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.386152, 59.944508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Animals",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74Д",
                "opening_hours": "Tu-Fr 12:00-22:30; Sa-Su 12:00-23:00",
                "contact_phone": "+7 950 0410071",
                "website": "https:\/\/vk.com\/veganstreetfood"
            },
            "geometry": {"type": "Point", "coordinates": [30.358242, 59.921746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ANY.pasta.pizza.bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 11\/2",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "http:\/\/anypasta.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.936404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Arcobaleno",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 54",
                "opening_hours": "Mo-Su 07:00-11:00, 12:00-23:00",
                "contact_phone": "+7 812 3851292",
                "website": "https:\/\/spb.dominarussia.com\/restaurant-and-bar\/arcobaleno-restaurant\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.304127, 59.931301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Arizona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 9",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.47734, 59.938135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Astoria Restaurant",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 39",
                "opening_hours": "https:\/\/www.roccofortehotels.com\/hotels-and-resorts\/hotel-astoria\/restaurants-and-bars\/astoria-cafe\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309239, 59.932901]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Atrio",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 25",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/www.atrio.club\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.323279, 59.935457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Azia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, Михайловская улица, 1\/7",
                "opening_hours": "Th-Sa 12:00-01:00, Su-We 12:00-00:00",
                "contact_phone": "https:\/\/www.belmond.com\/hotels\/europe\/russia\/st-petersburg\/belmond-grand-hotel-europe\/azia",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.330574, 59.935949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bab Jib",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 7",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 2304574",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.327906, 59.954926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BabJib",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 1\/96",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 2738803",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.354693, 59.932874]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bahroma",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 4к2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345459, 59.832634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bali",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 98",
                "opening_hours": "Mo-Su 11:00-02:00",
                "contact_phone": "+7 812 3315669",
                "website": "main.balispb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.254513, 59.983878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BanketBar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 31\/2Л",
                "opening_hours": "http:\/\/banketbar.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27574, 59.905664]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Barashki",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BarBQ Inn",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 5\/27",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 981 7181224",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.286763, 59.945765]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Basil",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 14",
                "opening_hours": "http:\/\/basil.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338479, 59.927874]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BBQ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 42",
                "opening_hours": "Mo-Th,Su 12:00-01:00; Fr,Sa 12:00-03:00",
                "contact_phone": "+7 812 3237660",
                "website": "http:\/\/reca.rest\/restaurants\/bbqrest"
            },
            "geometry": {"type": "Point", "coordinates": [30.279423, 59.941993]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BBQ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Нахимова, 20",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-03:00",
                "contact_phone": "+78129228020",
                "website": "http:\/\/reca.rest\/restaurants\/bbqnahimova"
            },
            "geometry": {"type": "Point", "coordinates": [30.223467, 59.940366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BBQ Beer",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 80к3",
                "opening_hours": "+7 812 9101213",
                "contact_phone": "https:\/\/www.munhell.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.408044, 60.038506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BeefZavod",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 2",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 4560695",
                "website": "http:\/\/beefzavod.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.317764, 59.96866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Beer Family",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 47",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-02:30",
                "contact_phone": "http:\/\/beerfamily.ru\/ru\/restaurants\/beer-family-restaurant",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.347381, 59.932441]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Beer Hall",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 34к1",
                "opening_hours": "http:\/\/vk.com\/beerhallspb",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215841, 59.999813]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BeerBurger",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 3",
                "opening_hours": "Mo-Th 11:00-23:00; Fr-Su 12:00-02:00",
                "contact_phone": "+7 812 9564842",
                "website": "https:\/\/beerburgerbar.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.291919, 59.953083]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BEERstudia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Рыбацкий проспект, 39",
                "opening_hours": "Fr-Sa 14:00-02:00; Su-Th 14:00-01:00",
                "contact_phone": "+7 921 7552510",
                "website": "http:\/\/beerstudia.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.51021, 59.8389]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bel Canto",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 9\/2",
                "opening_hours": "Mo-Fr 11:00-19:00; Sa-Su 12:00-18:00",
                "contact_phone": "http:\/\/www.belcanto-spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.307065, 59.915607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bellini",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Университетская набережная, 13",
                "opening_hours": "12:00-00:00",
                "contact_phone": "+7 812 3311001",
                "website": "http:\/\/elbagroup.ru\/bellini\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.298243, 59.939383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BIP",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Мариинская улица, 4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443594, 59.68081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Birch",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 3",
                "opening_hours": "Su-Th 13:00-00:00, Fr-Sa 13:00-01:00",
                "contact_phone": "+7 812 9203165",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.350831, 59.943854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Biscuit Banquet",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 26-28Б",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/biscuit-banquet.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.923631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bjork",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 40",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 5012906",
                "website": "http:\/\/bjork-kafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.27468, 59.941772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bollywood Nights",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 15",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302636, 59.957859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bollywood Nights",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 37",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 3839383",
                "website": "http:\/\/bollywoodnights.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.351271, 59.922048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 2",
                "opening_hours": "Su-Th 11:00-24:00; Fr-Sa 11:00-02:00",
                "contact_phone": "+7 812 9617313",
                "website": "http:\/\/bona-capona.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.265877, 60.001073]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 179",
                "opening_hours": "Mo-Th 11:00-00:00; Sa-Su 11:00-02:00",
                "contact_phone": "+7 812 9053538",
                "website": "http:\/\/www.bona-capona.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319875, 59.858358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 105",
                "opening_hours": "+7 812 9112030",
                "contact_phone": "http:\/\/www.bona-capona.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.201809, 59.83357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 43",
                "opening_hours": "Su-Th 10:30-23:00; Fr-Sa 10:30-01:00",
                "contact_phone": "+7 812 9479040",
                "website": "http:\/\/bona-capona.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.390608, 59.858042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Октябрьский бульвар, 33",
                "opening_hours": "+7 812 9300963",
                "contact_phone": "http:\/\/www.bona-capona.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.413037, 59.722742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Boncafé",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малоохтинский проспект, 64",
                "opening_hours": "Mo-Fr 11:00-22:00",
                "contact_phone": "+7 812 9334933",
                "website": "http:\/\/boncafespb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.402996, 59.931675]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Borsalino",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 24",
                "opening_hours": "http:\/\/www.hotelangleterre.ru\/borsalino-restaurant",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308771, 59.933915]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bourgeois Bohemians",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Виленский переулок, 15",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365231, 59.939965]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Brasserie Repin",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 11-13",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": "+7 812 3352290",
                "website": "http:\/\/www.sokosrestaurants.ru\/brasserie-repin.html"
            },
            "geometry": {"type": "Point", "coordinates": [30.282406, 59.937301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bravo Bro",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Заставская улица, 44",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316695, 59.888415]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bretzel",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 4литЗ",
                "opening_hours": "Mo-Th 12:00-23:00, Fr 12:00-01:00, Sa 13:00-01:00, Su 14:00-23:00",
                "contact_phone": "+7 812 9360646",
                "website": "http:\/\/bretzel-restaurant.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.343114, 59.946833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Brixton",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 33",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356409, 59.935723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Brücke bier & steak",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 33",
                "opening_hours": "Mo-Th,Su 16:00-00:00; Fr-Sa 16:00-03:00",
                "contact_phone": "+7 812 6566000",
                "website": "http:\/\/www.brucke.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.264431, 60.008266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Brugge",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 22",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_phone": "http:\/\/belgapub.inbrugge.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.284939, 59.947311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Buddha-Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 78",
                "opening_hours": "http:\/\/www.buddha-bar.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396537, 59.940956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bueno",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Парковая улица, 14к1",
                "opening_hours": "Su-Th 11:00-01:00; Fr-Sa 11:00-03:00",
                "contact_phone": "+7 812 9223015",
                "website": "https:\/\/vk.com\/buenorest"
            },
            "geometry": {"type": "Point", "coordinates": [29.937716, 59.860488]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Burger & Crab",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 11",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-03:00",
                "contact_phone": "+7 812 9062406",
                "website": "http:\/\/www.burgerandcrab.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.342952, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe del Mar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово, Приморское шоссе, 496",
                "opening_hours": "+7 921 9979796, +7 812 7773435",
                "contact_phone": "http:\/\/delmar-spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.766226, 60.181347]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe Grand",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 12",
                "opening_hours": "http:\/\/www.hon.ru\/ru\/cafegrand\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323064, 59.936913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe Normandie",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 133",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319228, 59.883273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Camorra Isola",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "Su-Th 12:00-23:00, Fr,Sa 12:00-24:00",
                "contact_phone": "https:\/\/vk.com\/camorrapizza",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Casa Мясо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Биржевой проезд, 6",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 3209746",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.301235, 59.943746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ChaCha",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 8\/1",
                "opening_hours": "+7 931 9650605",
                "contact_phone": "http:\/\/chacha.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.327636, 59.936792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chaplin",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 38-40\/73",
                "opening_hours": "Mo-Su 07:30-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265553, 59.944247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Charlie",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 54",
                "opening_hours": "Mo-Th 09:00-23:00; Fr 09:00-02:00; Sa 10:00-02:00; Su 10:00-23:00",
                "contact_phone": "+7 911 9260012",
                "website": "https:\/\/www.charlie-charlie.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315841, 59.926981]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Che-Dor",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 190",
                "opening_hours": "11:00-06:00",
                "contact_phone": "+7 812 6124364",
                "website": "https:\/\/chegroup.ru\/restaurants\/chedor\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321411, 59.864652]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cheer Duck",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Нахимова, 5к1",
                "opening_hours": "Mo-Tu,Su 12:00-01:00; Fr-Sa 12:00-04:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225534, 59.942128]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chicago Burgers",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 17",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa, Su 11:00-03:00",
                "contact_phone": "+7 812 9207385",
                "website": "http:\/\/chicagoburgers.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316919, 59.960683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 100к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.220036, 59.853265]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili Pizza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "Su-Th 10:00-22:00; Fr-Sa 10:00-01:00",
                "contact_phone": "+7 812 2443296",
                "website": "https:\/\/www.chilipizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili Pizza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 10к1",
                "opening_hours": "Su-Th 10:00-23:00; Fr-Sa 10:00-00:00",
                "contact_phone": "cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261457, 60.005167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili Pizza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "Su-Th 10:00-01:00, Fr-Sa 10:00-03:00",
                "contact_phone": "http:\/\/griboedova.chiligroup.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili Pizza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "10:00-22:40 \"До последнего гостя\"",
                "contact_phone": "+7 812 2446322",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili Pizza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Типанова, 27\/39",
                "opening_hours": "Mo-Th 10:00-23:00; Fr 10:00-24:00; Sa 00:00-01:00,10:00-24:00; Su 00:00-01:00,10:00-23:00",
                "contact_phone": "https:\/\/www.chilipizza.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.349699, 59.853812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chili Trattoria",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 9",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 2443240",
                "website": "https:\/\/www.chilipizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.288559, 59.943647]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chipollino",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оружейника Фёдорова, 1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "http:\/\/www.chipollino-fontanka.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.338712, 59.945256]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chorizo pizza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 8к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6799961",
                "website": "http:\/\/chorizopizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314817, 59.977197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chuck",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 41",
                "opening_hours": "Mo-Th 15:00-00:00, Fr 15:00-01:00, Sa 13:00-01:00, Su 13:00-00:00",
                "contact_phone": "+7 921 5849394",
                "website": "http:\/\/chuckfamily.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321177, 59.928816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "City Grill Express",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 1Т",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 9408445",
                "website": "http:\/\/citygrillexpress.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.360371, 59.932256]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Claret",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 11",
                "opening_hours": "https:\/\/claretcafe.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354002, 59.929574]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Conchita Bonita",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 39",
                "opening_hours": "http:\/\/conchita-bonita.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32053, 59.92924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Crab Story",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 12",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 921 9080288",
                "website": "https:\/\/crabstory.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.345863, 59.924975]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Craft Brew Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 15",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-03:00",
                "contact_phone": "+7 812 9389193",
                "website": "http:\/\/reca.rest\/restaurants\/craftbrewcafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.311116, 59.935169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Crystal",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 10",
                "opening_hours": "+7 (812) 207-10-00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389638, 59.925511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Curry House",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 8",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/curryhousespb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.351343, 59.939185]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Daily Delicatessen",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 64\/78",
                "opening_hours": "Mo-Th 08:00-23:00, Fr 08:00-02:00, Sa 09:00-02:00, Su 09:00-23:00",
                "contact_phone": "http:\/\/dailydelicatessen.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.348423, 59.933099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Daniel",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Дворцовая улица, 3",
                "opening_hours": "http:\/\/www.daniel-restaurant.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396257, 59.719627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Del Mar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 49",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/nevsky\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.392602, 59.857861]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Del Mar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 52",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/nevsky\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.335775, 59.934515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Del Mar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Шлиссельбургский проспект, 7к2",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/del-mar-shlisselburgskiy-pr-7\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.491884, 59.840451]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Delmar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Туристская улица, 34",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9442727",
                "website": "http:\/\/delmar-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.211798, 60.007672]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Demidoff",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 14",
                "opening_hours": "http:\/\/demidoff-kafe.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339664, 59.941804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ditay Chinese Restaurant",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 4",
                "opening_hours": "+7 812 5426613",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351989, 59.961773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Dizzy",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Москательный переулок, 1",
                "opening_hours": "Mo-Fr 10:00+; Sa-Su 11:00+",
                "contact_phone": "https:\/\/spbdizzy.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.321932, 59.930863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Do Eat",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 54-56",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 921 3792427",
                "website": "https:\/\/www.doeat.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.298243, 59.961503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Don Bosco",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новгородская улица, 12",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 (999) 231-99-95",
                "website": "https:\/\/donbosco.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.38502, 59.936079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Du Nord 1834",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 26",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "http:\/\/www.dunord.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.358619, 59.944134]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Du Nord 1834",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 41\/83",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 5781245",
                "website": "http:\/\/www.dunord.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360308, 59.930683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Duck & Drake",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 34",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 3191010",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.910842, 59.879047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "DuderHofff",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Катерников, 5к1",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-03:00",
                "contact_phone": "http:\/\/duderhoff.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.154782, 59.853356]}
        },
        {"type": "Feature",
            "properties": {
                "name": "DUO гастробар",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 8Б",
                "opening_hours": "Mo-Su 13:00-00:00",
                "contact_phone": "+7 921 9945443",
                "website": "http:\/\/www.duobar.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.351226, 59.94421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Duo Asia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 20",
                "opening_hours": "Mo-Su 18:00-24:00",
                "contact_phone": "+7 911 9288189",
                "website": "http:\/\/duoasia.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.344974, 59.930038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "EinSteinBar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 3к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408637, 59.95866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Entrée",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 125",
                "opening_hours": "11:00-24:00",
                "contact_phone": "http:\/\/restaurant-entree.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.303022, 59.922603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Eristov",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 5П",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31735, 59.968322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ezo Izakaya",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Волынский переулок, 8",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322462, 59.938531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fairytale",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Костромской проспект, 10",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318428, 60.013024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Family",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 7к1",
                "opening_hours": "+7 812 7482674",
                "contact_phone": "http:\/\/family-peterhof.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.902218, 59.882115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Farш",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 38\/4",
                "opening_hours": "http:\/\/farshburger.ru\/",
                "contact_phone": "cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331274, 59.935232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Farfalla",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fern",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 19к1",
                "opening_hours": "Mo-Th, Su 12:00-23:00; Fr, Sa 12:00-02:00",
                "contact_phone": "+78129073819",
                "website": "https:\/\/fern.rest\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344856, 60.078838]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Finnegan's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Талалихина, 5\/15",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 3375755",
                "website": "http:\/\/www.finnegans.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.297525, 59.951511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fiolet",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Ломоносова, 4",
                "opening_hours": "Mo-Su 07:30-00:30",
                "contact_phone": "http:\/\/www.fioletspb.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.335712, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fort Maverick",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Парадная улица, 3к2",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-24:00",
                "contact_phone": "+7 812 6080082",
                "website": "http:\/\/www.fortmaverick.bar"
            },
            "geometry": {"type": "Point", "coordinates": [30.367261, 59.942079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Four Hands",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 63к1",
                "opening_hours": "Mo-Su 16:00-00:00",
                "contact_phone": "+7 812 9246388",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.357388, 59.838602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Francesco",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 47",
                "opening_hours": "https:\/\/ginza.ru\/spb\/restaurant\/Francesco",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377924, 59.939708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Frank",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 175",
                "opening_hours": "+7 (812) 602-52-62",
                "contact_phone": "https:\/\/frankmeat.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.320324, 59.860152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Frank",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 28",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-03:00",
                "contact_phone": "https:\/\/frankmeat.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.341767, 59.926648]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fratelli",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fromage",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ленина, 28",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302016, 59.962679]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gastroli",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 7",
                "opening_hours": "Su-Th 12:00-24:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 9190708",
                "website": "https:\/\/gastrolirest.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341308, 59.942196]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Georgian's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Колокольная улица, 6",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350076, 59.928866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gianni",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Спасский переулок, 9\/24",
                "opening_hours": "Mo-Su 08:30-00:00",
                "contact_phone": "+7 921 3047744",
                "website": "https:\/\/www.gianniresto.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318321, 59.92842]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ginza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 16Д",
                "opening_hours": "Mo-Su 12:00-1:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/ginza"
            },
            "geometry": {"type": "Point", "coordinates": [30.319551, 59.973248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Giulietta",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Инженерная улица, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340392, 59.937698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gloss cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 17",
                "opening_hours": "http:\/\/www.gloss-cafe.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gogi",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "opening_hours": "Fr-Sa 12:00-01:00, Su-Th 11:00-24:00",
                "contact_phone": "https:\/\/khinkalnayagogi.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Goose Goose",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 27",
                "opening_hours": "Mo-Th 09:00-00:00, Fr 09:00-01:00, Sa 10:00-01:00, Su 10:00-00:00",
                "contact_phone": "+7 812 9283727",
                "website": "https:\/\/italy-group.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322363, 59.936999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Graf-in",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Конногвардейский переулок, 2",
                "opening_hours": "Mo-Th 11:00-02:00, Fr-Sa 11:00-04:00, Su 11:00-00:00",
                "contact_phone": "http:\/\/graf-in.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.296321, 59.932036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grand Империал",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Смоленская улица, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.903733]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grand Peterhof Spa Hotel",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Гофмейстерская улица, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.992207, 59.860083]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gräs x Madbaren",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 8\/7",
                "opening_hours": "Su-Th 13:00-23:00; Fr-Sa 13:00-01:00",
                "contact_phone": "+7 812 9281818",
                "website": "http:\/\/grasmadbaren.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336592, 59.937008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grebeshki",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Арсенальная набережная, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371806, 59.954728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Инструментальная улица, 3Б",
                "opening_hours": "Mo-Fr 08:00-22:00, Sa-Su 10:00-18:00",
                "contact_phone": "+7 911 0049781",
                "website": "http:\/\/greenboxes.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.324267, 59.974369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greneta",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Радищева, 34",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "http:\/\/www.grenetabar.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.363398, 59.941322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grill Station",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 139к1",
                "opening_hours": "Mo-Su,PH 11:00-24:00",
                "contact_phone": "+7 (812) 955-98-00",
                "website": "https:\/\/grillstation.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318527, 59.879864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grizzly Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 96",
                "opening_hours": "Su-Th 12:00-02:00, Fr-Sa 12:00-03:00",
                "contact_phone": "http:\/\/grizzly-rus.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.354568, 59.932112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "GuttenBear",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236565, 59.860093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "GyroGrill",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 43",
                "opening_hours": "http:\/\/gyrogrill.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347866, 59.939113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "GyroGrill",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 45к1",
                "opening_hours": "Mo-Su 12:00-23:00; Fr-Sa 12:00-01:00",
                "contact_phone": "+7 812 9005498",
                "website": "http:\/\/gyrogrill.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.205681, 60.002324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hamlet + Jacks",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Волынский переулок, 2",
                "opening_hours": "Su-Th 13:00-00:00, Fr-Sa 13:00-02:00",
                "contact_phone": "+7 812 9070735",
                "website": "http:\/\/hamletandjacks.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.32098, 59.938464]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hanoi City",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 36",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287068, 59.944098]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hard Rock Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 62",
                "opening_hours": "Fr, Sa 12:00-02:00; Mo-Th, Su 12:00-00:00",
                "contact_phone": "https:\/\/hardrockcafespb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.302384, 59.921155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hitch",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 179",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319875, 59.858358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hitch",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 10к1",
                "opening_hours": "Su-Th 9:00-0:00, Fr-Sa 9:00-1:00",
                "contact_phone": "+7 812 9430110",
                "website": "https:\/\/italy-group.ru\/restaurants\/Hitch-new\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316335, 59.974707]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Homutov",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 55",
                "opening_hours": "Mo-Th 11:00-23:00, Fr 11:00-01:00, Sa 12:00-01:00, Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318006, 59.910871]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hunt",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 13",
                "opening_hours": "https:\/\/hunt.restaurant\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343949, 59.929407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "+7 812 4494346",
                "contact_phone": "http:\/\/ilpatio.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 30",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3121633",
                "website": "https:\/\/spb.ilpatio.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.32804, 59.935606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": "+7 800 7006600",
                "contact_phone": "http:\/\/spb.ilpatio.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "+7 812 3335264",
                "contact_phone": "http:\/\/ilpatio.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 37к1",
                "opening_hours": "+7 965 0902253",
                "contact_phone": "http:\/\/ilpatio.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.323567, 59.791662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "+7 921 8963783",
                "contact_phone": "http:\/\/spb.ilpatio.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "IL Патио",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "+7 921 8963783",
                "contact_phone": "http:\/\/spb.ilpatio.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "il Konti",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 17-я линия Васильевского острова, 4-6Е",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273045, 59.932996]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Il Lago dei Cigni",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 21Б",
                "opening_hours": "http:\/\/www.illago.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234633, 59.973847]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Il Milanese",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 7",
                "opening_hours": "Mo-Fr 11:30-23:00; Sa-Su 12:30-23:00",
                "contact_phone": "http:\/\/www.ilmilanese.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.348405, 59.945982]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Il Patio",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 182",
                "opening_hours": "Mo-Fr 11:00-23:00, Sa,Su 12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383421, 59.924591]}
        },
        {"type": "Feature",
            "properties": {
                "name": "In Georgia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 24",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353418, 59.946748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "In Georgia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 35",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363353, 59.9449]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Italiani",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 65",
                "opening_hours": "Mo-Th 09:00-23:00, Fr 09:00-01:00, Sa 11:00-01:00, Su 11:00-23:00",
                "contact_phone": "https:\/\/italy-group.ru\/restaurants\/italiani\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.353391, 59.931724]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Italy",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 14",
                "opening_hours": "09:00-00:00",
                "contact_phone": "https:\/\/italy-group.ru\/restaurants\/italy-bolshoy-morskoy\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.317674, 59.935958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Italy",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 29",
                "opening_hours": "09:00-24:00",
                "contact_phone": "https:\/\/italy-group.ru\/restaurants\/italy-engels\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.326917, 60.008626]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Italy на Московском",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 11",
                "opening_hours": "09:00-00:00",
                "contact_phone": "+7 812 9006444; +7 911 9263327",
                "website": "https:\/\/italy-group.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315248, 59.868984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Janno",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 14",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 3140663",
                "website": "https:\/\/pushkainn.ru\/restaurant\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320683, 59.940947]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Jazz Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 23к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312544, 59.870651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Jérôme",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 25",
                "opening_hours": "Mo-Su 09:00-24:00",
                "contact_phone": "+7 (812) 314-64-43",
                "website": "http:\/\/probka.org\/restaurants\/spb\/jerome"
            },
            "geometry": {"type": "Point", "coordinates": [30.312958, 59.934168]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Joly Woo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 12",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/jolywoo.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.36002, 59.936246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Josh",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334293, 59.936133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Juno",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 46",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310909, 59.928681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Katyusha",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 22-24",
                "opening_hours": "12:00-01:00",
                "contact_phone": "+7 812 6401616",
                "website": "http:\/\/ginza.ru\/spb\/restaurant\/katyusha"
            },
            "geometry": {"type": "Point", "coordinates": [30.324097, 59.935944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kazan-Mangal",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 13",
                "opening_hours": "Su-Th 11:00-01:00, Fr-Sa 11:00-03:00",
                "contact_phone": "http:\/\/reca.rest\/restaurants\/kazanmangal",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.312185, 59.93561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kazan-Mangal",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 20\/16",
                "opening_hours": "12:00-01:00",
                "contact_phone": "http:\/\/reca.rest\/restaurants\/kazanmangal_vosstaniya",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360065, 59.93763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ketch Up Burgers",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 29",
                "opening_hours": "Fr-Sa 11:00-01:00; Su-Th 11:00-23:00",
                "contact_phone": "+7 800 4442444",
                "website": "http:\/\/www.ketchupburgers.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.279388, 59.942845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ketch Up Burgers",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 28",
                "opening_hours": "Mo-Th 11:00-23:00; Fr,Sa 11:00-01:00; Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279325, 59.943129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "KIDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 31",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 812 561 25 88",
                "website": "https:\/\/kido.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.286251, 59.964386]}
        },
        {"type": "Feature",
            "properties": {
                "name": "KIT",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 16",
                "opening_hours": "http:\/\/www.kitbistro.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345342, 59.930764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Klassik",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, улица Аврова, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.924134, 59.879229]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kontora",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Орловская улица, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390096, 59.951731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Korean BBQ гриль",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 27А",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/koreanbbq.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.274303, 59.907699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "kriek",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 64",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340419, 59.933911]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kroo Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 27",
                "opening_hours": "Mo-Th 08:00-00:00, Fr 08:00-01:00, Sa 09:30-01:00, Su 10:00-00:00",
                "contact_phone": "+7 906 2731111",
                "website": "http:\/\/www.kroocafe.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.374016, 59.937387]}
        },
        {"type": "Feature",
            "properties": {
                "name": "L & A",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 29",
                "opening_hours": "Mo-Sa 08:00-20:00, 09:00-18:00; Su 08:00-19:00",
                "contact_phone": "fast_food",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313155, 59.964584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lампа",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 150к1",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 5157850",
                "website": "http:\/\/restoran-lampa.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336089, 60.056018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Cantine",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7Ф",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa, Su 09:00-19:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260056, 59.990804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Casa",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 35",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27477, 59.911787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Fabrik",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 13",
                "opening_hours": "Mo-Fr 10:00-23:30; Sa-Su 12:00-23:30",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286772, 59.966768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Izvor",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 23",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305385, 59.959566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Mia Firenze",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 9",
                "opening_hours": "Mo-Fr 11:00-24:00, Sa,Su 12:00-24:00",
                "contact_phone": "http:\/\/myflorence.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.358951, 59.947009]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Palla",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 45к2Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261286, 59.886934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Perla Fish House",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 54\/34",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350633, 59.922995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La presse",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 69",
                "opening_hours": "07:30-24:00",
                "contact_phone": "+7 812 5710061",
                "website": "https:\/\/www.lapresse.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354397, 59.931607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Soirée",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 28",
                "opening_hours": "http:\/\/spbbar.net\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359697, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Vue",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пироговская набережная, 5\/2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341353, 59.956989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Laardo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 133А",
                "opening_hours": "http:\/\/laardo.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296761, 59.921493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Laboratoria Distilita",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Laffa",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Народная улица, 43к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473424, 59.882139]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lale",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 91",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365284, 59.930327]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lambicus",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Радищева, 32",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363281, 59.940627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Le Boat",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 22",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387841, 59.92901]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Le Cristal",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 92",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244209, 59.977143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Le Glamour",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 44-46",
                "opening_hours": "11:00+",
                "contact_phone": "http:\/\/le-glamoure.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.308313, 59.921439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Legran",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 4\/1Б",
                "opening_hours": "https:\/\/www.legran-rest.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326522, 59.943458]}
        },
        {"type": "Feature",
            "properties": {
                "name": "LEO Гриль & Пицца",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 25",
                "opening_hours": "Su-Th 11:00-23:00, Fr, Sa 11:00-24:00",
                "contact_phone": "+7 812 4073800",
                "website": "https:\/\/leogrillpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.404066, 59.719845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Liberté Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 4к1",
                "opening_hours": "Su-Th 12:00-1:00, Fr-Sa 12:00-5:00",
                "contact_phone": "+7 921 9444454",
                "website": "https:\/\/libertecafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314691, 59.972249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "LIDO",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 69А",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466543, 59.962075]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Liman",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 27",
                "opening_hours": "Mo-Fr 10:00-24:00; Sa-Su 11:00-24:00",
                "contact_phone": "+7 812 5795477",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360398, 59.947554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Liman",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 67\/17",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34403, 59.921299]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Linea",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 46",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.90699, 59.879974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Little Italy",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 10\/5",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 5712350",
                "website": "http:\/\/littleitalytrattoria.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.335227, 59.936377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Little Sicily",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 13",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35384, 59.929321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lodka",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 41",
                "opening_hours": "Su-Th 13:00-23:00, Fr-Sa 13:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357604, 59.935701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lounge Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lounge cafe PAR",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Полковая улица, 1\/25",
                "opening_hours": "Sa-Tu 12:00-23:30; Fr-Su 12:00-02:00",
                "contact_phone": "+7 812 9850003;+7 812 9855030;+7 800 7707034",
                "website": "https:\/\/www.alkalyans.ru\/shops\/par-v-pushkine\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.381499, 59.702453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Luce",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 15",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333646, 59.936215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lugana",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 37",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406463, 59.912328]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mяsoet",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 42",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_phone": "http:\/\/myasoet.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.279423, 59.941993]}
        },
        {"type": "Feature",
            "properties": {
                "name": "MA",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 7Б",
                "opening_hours": "12:00+",
                "contact_phone": "https:\/\/ma-rest.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.344929, 59.931048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Macarena",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 206",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322291, 59.857301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Madagascar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 12",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/barmadagascar\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.336116, 59.935534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Made in",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Школьная улица, 14",
                "opening_hours": "12:00-23:00; 2019 May Mo off",
                "contact_phone": "http:\/\/madeinbar.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.287616, 59.987875]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Made In China",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 35",
                "opening_hours": "Mo-Th 09:00-00:00; Fr 09:00-02:00; Sa 12:00-02:00; Su 12:00-00:00",
                "contact_phone": "+7 812 9055135",
                "website": "https:\/\/madeinchina-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.310433, 59.933307]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Madlobeli",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Саблинская улица, 3",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 921 7410421",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.305223, 59.958859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Majestic",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, Приморское шоссе, 428",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.850115, 60.161726]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mamá",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310308, 59.936052]}
        },
        {"type": "Feature",
            "properties": {
                "name": "MAMALЫGA",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 84к1",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 6401616",
                "website": "https:\/\/ginza.ru\/spb\/restaurant\/mamaliga_na_leninskom"
            },
            "geometry": {"type": "Point", "coordinates": [30.199177, 59.857649]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mamma mia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 13",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367072, 59.955156]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Maneki",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 13\/11",
                "opening_hours": "http:\/\/manekicafe.ru\/",
                "contact_phone": "cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316569, 59.927301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mangal Grill",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 51к1",
                "opening_hours": "Mo-Su 11:00-12:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196958, 60.003408]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mangal Hоuse",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "opening_hours": "http:\/\/mangal-house-kafe.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mapuche",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 34",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358987, 59.938933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marani",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 256",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+78129246677",
                "website": "https:\/\/marani-rest.ru\/v-sankt-peterburge\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.974833, 60.058219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marcelli's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Типанова, 27\/39",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349699, 59.853812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mario",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский проспект, 8",
                "opening_hours": "08:00-23:00",
                "contact_phone": "https:\/\/www.mariotrattoria.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.309751, 59.935976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Markus",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 10-я линия Васильевского острова, 59",
                "opening_hours": "10:00-19:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267395, 59.947338]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Matisov",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Maximilian",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": "Su-Th 12:00-01:00, Fr-Sa 12:00-06:00",
                "contact_phone": "+7 921 9380858",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Maximilian Brauhaus",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "http:\/\/www.maxbier.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Maximilian Hall",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": "Mo-Tu 12:00-00:00; We-Fr 00:00-02:00,12:00-00:00; Sa-Su 00:00-04:00,12:00-00:00",
                "contact_phone": "http:\/\/www.maxbier.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "MaxRestoGroup",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Чистяковская улица, 2В",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298001, 60.039485]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Meat Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 22",
                "opening_hours": "Su-Th 12:00-23:00, Fr-Sa 12:00-02:00",
                "contact_phone": "http:\/\/meat-bar.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.357828, 59.936079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Meierhold",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Марсово поле, 7",
                "opening_hours": "http:\/\/zl-group.ru\/gastro-rest\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32946, 59.942354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mein Herz",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Университетская набережная, 15",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9280328",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.295961, 59.938811]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Melanzana",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 45к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205681, 60.002324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Memet & Pir",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 10Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369551, 59.931688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Metamorfos",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 19",
                "opening_hours": "Mo-Su,PH 09:00-01:00",
                "contact_phone": "+7 812 4060006",
                "website": "https:\/\/www.radissonblu.com\/sonyahotel-stpetersburg\/restaurants"
            },
            "geometry": {"type": "Point", "coordinates": [30.348172, 59.946369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mickey & Monkeys",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 27",
                "opening_hours": "Su-Th 10:00-23:00, Fr-Sa 10:00-01:00",
                "contact_phone": "+7 812 9202386",
                "website": "https:\/\/www.coffeeroomspb.com\/mickey-and-monkeys"
            },
            "geometry": {"type": "Point", "coordinates": [30.318392, 59.93062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Millano",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Латышских Стрелков, 1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470315, 59.927171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mingle",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 36",
                "opening_hours": "Mo-Fr 11:00-00:00; Sa-Su 12:00-00:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Molto Buono",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 10",
                "opening_hours": "https:\/\/moltobuono.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352376, 59.936124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mon Amour",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 83",
                "opening_hours": "Mo-Sa 10:00-21:00; Su 11:00-21:00",
                "contact_phone": "http:\/\/monamour.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.292835, 59.919432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Morskoj",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 7-9",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351531, 59.935773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mozzarella Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 4",
                "opening_hours": "10:00-00:00",
                "contact_phone": "+7 812 9551097",
                "website": "http:\/\/mozzarellabar.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296078, 59.955111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr. Bo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Манежный переулок, 2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350795, 59.943395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Munhell",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 55к1",
                "opening_hours": "Mo-Th 12:00-01:00, Fr-Sa 12:00-03:00, Su 12:00-24:00",
                "contact_phone": "+7 812 9008180",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.213891, 60.00715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Munhell",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4799, 59.908164]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mur_mur",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 1\/47А",
                "opening_hours": "12:00+",
                "contact_phone": "http:\/\/myasoet.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.34606, 59.932126]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Must",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 54",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302968, 59.960503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mźe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Октябрьский бульвар, 7\/29",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408526, 59.724108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "NAMA",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ковенский переулок, 5",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7812 4254001",
                "website": "https:\/\/instagram.com\/namaspb?igshid=8vg4go4krk6w"
            },
            "geometry": {"type": "Point", "coordinates": [30.356382, 59.936931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Namaste",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 7",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 5706886;+7 812 9415946",
                "website": "http:\/\/namaste.ru.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.324699, 59.936746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Natakhtari",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 11\/57",
                "opening_hours": "Mo-Sa 11:00-24:00; Su 12:00-24:00",
                "contact_phone": "https:\/\/natakhtari.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.946058]}
        },
        {"type": "Feature",
            "properties": {
                "name": "New-York City Cafe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 21",
                "opening_hours": "+7 812 2975300",
                "contact_phone": "http:\/\/nycc.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.36055, 60.00285]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Next Door",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 82",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 9880180",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.308987, 59.964129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Niagara",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 14",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9815022;+7 812 9815094",
                "website": "http:\/\/niagarahall.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.302555, 59.924037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Offside",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 32к3",
                "opening_hours": "http:\/\/iq230.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214709, 59.953358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Oh! Mumbai",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 62\/2",
                "opening_hours": "http:\/\/ohmumbai.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312787, 59.932085]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Old Whale English Pub",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 21к1",
                "opening_hours": "Mo-Fr 13:00-23:59; Sa,Su 00:00-02:00, 13:00-23:59",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344032, 60.079765]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Olivetto",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 61",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358574, 59.927937]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ollis Club",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 42",
                "opening_hours": "24\/7",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403732, 60.010659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Oscar's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 26-я линия Васильевского острова, 15Б",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "https:\/\/vk.com\/oskarsrestaurant",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.255888, 59.932743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Palermo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 50",
                "opening_hours": "12:00-00:00",
                "contact_phone": "https:\/\/www.palermo-spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.342072, 59.930719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paloma Mexican Cantina",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 8\/7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336592, 59.937008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paninaro",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 28",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+7 911 9271533",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.285873, 59.964124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Papa Pizziano",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 41\/14",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-01:00",
                "contact_phone": "+7 812 4446510",
                "website": "http:\/\/papapizziano.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.410757, 59.929705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Par",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Манежный переулок, 19\/40",
                "opening_hours": "11:00-02:00",
                "contact_phone": "+7 812 9850074",
                "website": "https:\/\/www.spbpar.ru\/vosstaniya"
            },
            "geometry": {"type": "Point", "coordinates": [30.360137, 59.942949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Par",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 75",
                "opening_hours": "Su-Th 11:00-23:30, Fr-Sa 11:00-06:00",
                "contact_phone": "https:\/\/www.spbpar.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.335165, 59.838774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PAR - kitchen & shishas",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 75",
                "opening_hours": "12:00-02:00",
                "contact_phone": "+7 812 4501410",
                "website": "https:\/\/www.spbpar.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.335406, 59.838869]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ParkKing",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Александровский парк",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+78129236707;+78124980606",
                "website": "https:\/\/parkking.ru\/restoranyi\/parkking\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317494, 59.956043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pasta и vino",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 54",
                "opening_hours": "12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329064, 59.924086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paulaner",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 89",
                "opening_hours": "https:\/\/brauhaus.spb.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364638, 59.930399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paulaner Brauhaus",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, площадь Победы, 1",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 7403932",
                "website": "http:\/\/www.paulaner-brauhaus.com\/stpetersburg"
            },
            "geometry": {"type": "Point", "coordinates": [30.318518, 59.842689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Perbacco",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 51",
                "opening_hours": "11:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310667, 59.924037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Percorso",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 1",
                "opening_hours": "https:\/\/www.percorso.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308143, 59.934948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Phali Hinkali",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329738, 59.936674]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Philibert",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Коломенская улица, 29",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/philibert.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.352798, 59.923017]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pizza Italiano",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 9",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267054, 59.852479]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pizzaroni",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Глухарская улица, 9",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 3437029",
                "website": "http:\/\/pizzaroni.ru\/pizzerias\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.218419, 60.030043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pizzeria Castelsardo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 10-я линия Васильевского острова, 17",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275947, 59.940001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Polenta",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293464, 59.954047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Port",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 16",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 9970102",
                "website": "http:\/\/www.port2.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319758, 59.962242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PortWein",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 2",
                "opening_hours": "12:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349357, 59.944215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Probka",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Зоологический переулок, 2-4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305277, 59.948875]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PROPAGANDA",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 33к1",
                "opening_hours": "+7 812 6034950",
                "contact_phone": "http:\/\/propaganda.group\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.326298, 60.009602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Provence",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Боровая улица, 11-13",
                "opening_hours": "12:00-20:00",
                "contact_phone": "http:\/\/provencebanket.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.920902]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Puberty",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Выборгская набережная, 47",
                "opening_hours": "Mo-Th 12:00-01:00; Fr 12:00-03:00; Sa 13:00-03:00; Su 13:00-01:00",
                "contact_phone": "http:\/\/puberty-spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.334023, 59.973473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pubthepub",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 17к1",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 921 9467521",
                "website": "http:\/\/pubthepub.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.310568, 60.054554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Quisisana",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Невский проспект, 74-76Б",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34633, 59.933753]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rыба",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317692, 59.977481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ra Family",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 6",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/rafamilygroup.com\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.350453, 59.92763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "RED Steak&Wine",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ленина, 9",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9274664",
                "website": "http:\/\/redrestaurant.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.307271, 59.961354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Regatta Club",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Аптекарская набережная, 12",
                "opening_hours": "Mo-Su 14:00-01:00",
                "contact_phone": "+7 812 9824556",
                "website": "https:\/\/regatta.tastetoeat.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322713, 59.976436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rich & lucky",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 58Б",
                "opening_hours": "+7 812 9070009",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366317, 59.94563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Robata",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 13",
                "opening_hours": "12:00-02:00",
                "contact_phone": "+7 812 9150013",
                "website": "http:\/\/robatabar.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341407, 59.925885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Romeo's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 43",
                "opening_hours": "+7 812 5725448",
                "contact_phone": "https:\/\/romeos.rest\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.295988, 59.922995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rose Mary",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 14Б",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30914, 59.957944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Runo.ru",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 422А",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_phone": "+7 812 9222220",
                "website": "http:\/\/runo.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.860797, 60.164069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rustaveli",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 9к1",
                "opening_hours": "+7 921 5981622",
                "contact_phone": "http:\/\/rustaveli.spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.325336, 59.942611]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rustaveli",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Подрезова улица, 1",
                "opening_hours": "+7 952 266-12-56",
                "contact_phone": "http:\/\/rustaveli.spb.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.306939, 59.963363]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Safe",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский район, Авиационная улица, 7",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322282, 59.855191]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Saltan",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Карпинского, 38к1",
                "opening_hours": "Su-Th 11:30-01:00; Fr-Sa 11:30-03:00",
                "contact_phone": "+7 812 2998857",
                "website": "http:\/\/www.saltan.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.429379, 60.013011]}
        },
        {"type": "Feature",
            "properties": {
                "name": "San Pizzerino",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пушкин, Железнодорожная улица, 56",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/www.san-pizzerino.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.420785, 59.734141]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SanDay Ginza",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Южная дорога, 4к2",
                "opening_hours": "Mo-Su 11:00-22:45",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24756, 59.971591]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sasha's bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211214, 59.952718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sasha's Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Марата, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354415, 59.930201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sasha's bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Типанова, 27\/39",
                "opening_hours": "00:00-05:00,10:00-24:00",
                "contact_phone": "http:\/\/www.sashasbar.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.349699, 59.853812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Schengen",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 5Г",
                "opening_hours": "Mo-Fr 09:00-00:00; Sa-Su 11:00-00:00",
                "contact_phone": "+7 812 9221197",
                "website": "http:\/\/schengen.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353355, 59.943782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sea Paradise",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 127",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 921 9072669",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.307065, 59.919725]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Serbish",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 8\/36",
                "opening_hours": "Mo-Fr 12:00-24:00; Sa,Su 00:00-02:00,12:00-24:00",
                "contact_phone": "+7 812 9043844",
                "website": "http:\/\/serbish.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344183, 59.942836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shana",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Караванная улица, 8",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341982, 59.936819]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Siegfried",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Глинки, 11",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297857, 59.924609]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sintoho",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 1",
                "opening_hours": "https:\/\/www.sintoho.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308143, 59.934948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Smoke BBQ",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 11",
                "opening_hours": "Mo-Th 12:00-00:00; Fr 12:00-02:00; Sa 10:00-02:00; Su 10:00-00:00",
                "contact_phone": "+7 812 9055372",
                "website": "https:\/\/smokebbqshop.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344237, 59.929903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Smokey Bones",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 204",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321743, 59.858674]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Social Club",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-03:00",
                "contact_phone": "https:\/\/www.socialclub.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sodexo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 40к4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327897, 59.818124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sofa Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 81к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.198854, 59.856252]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Soko",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 15к1",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9237586",
                "website": "http:\/\/www.sokocafe.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.37071, 59.866215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Solnce",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 7\/2",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-01:00",
                "contact_phone": "+7 812 4919090",
                "website": "http:\/\/www.solncebar.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.302555, 59.949551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Steak Grill Бельведер",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Дачный проспект, 2к2",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 3724343",
                "website": "http:\/\/steakgrill.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.242593, 59.849903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Steak House",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 186",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320953, 59.872326]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Stroganoff Bar&Grill",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 418",
                "opening_hours": "+7 812 4320575",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.863872, 60.166051]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Subzero",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 9076438",
                "website": "https:\/\/subzerosushi.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sud & Cie",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 54",
                "opening_hours": "https:\/\/restaurants.accorhotels.com\/ru\/restaurant-6157_R001-ibis-kitchen-saint-petersburg.shtml",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.924627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sunny Georgia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 48",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364521, 59.938757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sushi Lounge",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 23",
                "opening_hours": "11:00-23:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321626, 59.94289]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SushiMarketWok",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Гжатская улица, 22к4",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9521199",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.388281, 60.01365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SushiWhite",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 55",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 981 7737057",
                "website": "http:\/\/sushiwhite.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.235927, 59.839769]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Takao",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 8к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.476756, 59.914277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tandoor",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский проспект, 10",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 3123886",
                "website": "https:\/\/tandoor-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309122, 59.93575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tandoori Nights",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 4",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 3128772",
                "website": "http:\/\/www.tandoorinightsspb.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.308897, 59.935227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tefsi",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Замятин переулок, 4",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296671, 59.933618]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tempo",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 5",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327654, 59.939762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tequila-Boom",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 57",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_phone": "http:\/\/tequilaboom.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.307613, 59.920073]}
        },
        {"type": "Feature",
            "properties": {
                "name": "terrassa",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 3",
                "opening_hours": "https:\/\/ginza.ru\/spb\/restaurant\/terrassa",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322417, 59.933816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "TGI Friday's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "+7 812 4487437",
                "contact_phone": "http:\/\/www.tgifridays.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "TGI Friday's",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "contact_phone": "+7 963 2460952; +7 800 7006600",
                "website": "http:\/\/spb.tgifridays.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Corner Pub",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "opening_hours": "Mo-Th 12:00-00:00; Fr 12:00-03:00; Sa 14:00-02:00; Su 14:00-00:00",
                "contact_phone": "+7 812 3181724",
                "website": "http:\/\/cornerspb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The idiot",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 82",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303364, 59.930223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Kitchen",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 73",
                "opening_hours": "Mo-Th 11:00-01:00; Fr 11:00-02:00; Sa 12:00-02:00; Su 12:00-01:00",
                "contact_phone": "+7 812 9528585",
                "website": "http:\/\/www.thekitchen.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317934, 59.905001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Ogorod",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 19",
                "opening_hours": "+7 921 8703030",
                "contact_phone": "https:\/\/theogorod.ru\/index.php\/spb",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.334948, 59.93607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Place",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Восстания, 35",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-24:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360883, 59.939077]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Répa",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 10",
                "opening_hours": "http:\/\/ginza.ru\/spb\/restaurant\/therepa",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296617, 59.924384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Sizzle",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 23",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-01:00",
                "contact_phone": "https:\/\/sizzle.rest\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.343051, 59.927991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tiramisu Milk",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Галерная улица, 20-22",
                "opening_hours": "11:00-22:00",
                "contact_phone": "+7 812 4497200",
                "website": "http:\/\/www.tiramisu.ru\/restoran\/tiramisu-milk\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.29712, 59.933983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tone",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Московский проспект, 62",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319255, 59.90815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tootsie",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 30",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277555, 59.9407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "TopEr",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 21",
                "opening_hours": "https:\/\/toperspb.ru\/izmajlovskij",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306948, 59.911836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "TopEr",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 27",
                "opening_hours": "Mo-Su 12:00-23:30",
                "contact_phone": "https:\/\/toperspb.ru\/dekabristov",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.295467, 59.926584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Toscana grill",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 64",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/toscanagrill.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.313523, 59.92552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Trattoria Stefano",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 6",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 3146844",
                "website": "http:\/\/trattoria-stefano.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314143, 59.935836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Travels",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 42",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403732, 60.010659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tres Amigos",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 25",
                "opening_hours": "Mo-Fr 11:00-24:00; Sa,Su 12:00-24:00",
                "contact_phone": "+7 812 5722685",
                "website": "https:\/\/tres-amigos.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.3428, 59.927432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Un Bon",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 37\/20",
                "opening_hours": "http:\/\/unbon.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362949, 59.938531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "UNA",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 21-23",
                "opening_hours": "Su-Th 12:00-23:30, Fr-Sa 12:00-01:00",
                "contact_phone": "http:\/\/www.unarestaurant.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.32221, 59.937923]}
        },
        {"type": "Feature",
            "properties": {
                "name": "United Butchers",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 46к1",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+78126002155",
                "website": "https:\/\/unitedbutchers.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.352358, 60.049918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Unity",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 4",
                "opening_hours": "http:\/\/unity-sennaya-centr.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313119, 59.931513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "VAN",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 44",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380942, 59.942002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Via D`argento",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Серебристый бульвар, 29к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28555, 60.011621]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Villa Aston",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 5",
                "opening_hours": "11:00-23:00",
                "contact_phone": "https:\/\/cityhotelgroup.ru\/restaurant\/4",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.347516, 59.931188]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Villaggio",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Тучков переулок, 11\/5",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6474547",
                "website": "http:\/\/villaggio.pro"
            },
            "geometry": {"type": "Point", "coordinates": [30.288829, 59.944914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vincent",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Театральная площадь, 16",
                "opening_hours": "http:\/\/vin-cent.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297381, 59.924776]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vino di Vino",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 39",
                "opening_hours": "+7 (812) 388-87-00",
                "contact_phone": "https:\/\/vinodivino.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.321312, 59.875555]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vittoria",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 24",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273863, 59.90214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vox",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Соляной переулок, 16",
                "opening_hours": "http:\/\/www.voxresto.ru\/",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341874, 59.94289]}
        },
        {"type": "Feature",
            "properties": {
                "name": "VSЁ Хорошо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 8к1",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243545, 60.011855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vsёхорошо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Садовая улица, 27",
                "opening_hours": "Su-Th 12:00-02:00; Fr-Sa 12:00-06:00",
                "contact_phone": "https:\/\/barhorosho.ru\/restaurants\/addresses\/sadovaya-27\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.323162, 59.929285]}
        },
        {"type": "Feature",
            "properties": {
                "name": "White Night",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 59",
                "opening_hours": "Mo-Fr 12:00-00:00; Sa-Su 14:00-00:00",
                "contact_phone": "https:\/\/whitenight.spb.ru\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.332271, 59.927757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wine Bar",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 47",
                "opening_hours": "Su-Th 17:00-00:00; Fr-Sa 17:00-01:00",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27848, 59.946558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wong Kar Wine",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 14",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 9431930",
                "website": "https:\/\/wongkarwine.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.339664, 59.941804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Woodstock",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Ивана Черных, 10Б",
                "opening_hours": "+7 812 4247847",
                "contact_phone": "http:\/\/shar-shara.ru",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.280789, 59.901215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "xren",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 13",
                "opening_hours": "http:\/\/www.5ugol.ru\/hotel\/facilities.php",
                "contact_phone": "restaurant",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341407, 59.925885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "YASUMI",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Красное Село, улица Суворова, 9",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "7 (812) 749‒90‒20",
                "website": "yasumisushi.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.086963, 59.734142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Yimone",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, улица Трефолева, 18",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272533, 59.889589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Yumm",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 24",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482371, 59.945513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Yumm",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, проспект Славы, 15",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-00:00",
                "contact_phone": "+7 812 6004004",
                "website": "https:\/\/www.yummpizza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.374501, 59.854788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ZимаЛеtо",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Южная дорога, 8",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23696, 59.968272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Za.Live",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 422Б",
                "opening_hours": "+7 812 9034884",
                "contact_phone": "http:\/\/zalive.su",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [29.860728, 60.163487]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Zaferan",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ярославская улица, 4",
                "opening_hours": "10:00-01:00",
                "contact_phone": "http:\/\/zaferan.su\/",
                "website": "restaurant"
            },
            "geometry": {"type": "Point", "coordinates": [30.389485, 59.94449]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Zbiten",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 5\/2",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342458, 59.937675]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ZimaLeto Asia",
                "city_function": "Питание",
                "type": "Ресторан",
                "address": "Россия, Санкт-Петербург, Ижорская улица, 3",
                "opening_hours": "restaurant",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299159, 59.958602]}
        }
    ]
}
