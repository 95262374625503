export const swimming_pools = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {
            "type": "Feature",
            "properties": {"name": "Бассейн СКА", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.252034, 59.936746]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Грифон", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.326837, 59.927734]}
        },
        {
            "type": "Feature",
            "properties": {"name": "СШ №2", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.427304, 59.955728]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "детский бассейн \"Утёнок\" (ГБОУ СОШ № 21)",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [30.283987, 59.940078]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Сокол", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.308888, 59.878184]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Атлантика", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.251962, 59.867511]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Екатерининский", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.438937, 59.977877]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "СПА-комплекс Загородного Клуба \"Терийоки\"",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [29.700675, 60.187951]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Президент-Отель", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [29.653118, 60.191199]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн ИЗ", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.579488, 59.733296]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Спартак", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.270297, 59.973136]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Спортивное сооружение", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.067089, 59.724192]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Фитнес Хаус", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.146042, 59.994063]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Альфа Велнес клуб", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.199177, 59.857649]}
        },
        {
            "type": "Feature",
            "properties": {"name": "бассейн Звезда", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.454011, 59.868817]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Лиговский", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.363694, 59.922603]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Военный Институт Физической Культуры", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.342458, 59.976909]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Атлантика", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.461341, 59.877321]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Аква-Рай", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.329576, 59.936508]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн межвузовского центра", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.30799, 59.868726]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Нептун", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.563713, 59.813129]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Форт-Р", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.274995, 60.089508]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Спортивное сооружение", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [29.456639, 60.177137]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Динамо", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.263281, 59.968025]}
        },
        {
            "type": "Feature",
            "properties": {"name": "СК Премиум-спорт", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.312526, 60.001401]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Бассейн СДЮШОР Приморского района СПб",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [30.277699, 60.012674]}
        },
        {
            "type": "Feature",
            "properties": {"name": "H2o", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.485668, 59.854242]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Beauty Point", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.483215, 59.843019]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Школа синхронного плавания Synchroswimspb",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [30.331175, 60.003957]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Школа плавания in Swim", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.328022, 60.005324]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Курсы для беременных. Школа Вербочка.",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [30.411988, 59.872408]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн Горного университета", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.258349, 59.935854]}
        },
        {
            "type": "Feature",
            "properties": {"name": "АКВАДЕТИ", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.205681, 60.002324]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Атлантика", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.471681, 59.938752]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн СПбГУ", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.297659, 59.94056]}
        },
        {
            "type": "Feature",
            "properties": {"name": "СДЮСШОР №3", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.406733, 60.034232]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Атлантика", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.366659, 59.888284]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Юность России", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.238191, 59.940384]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн матери и ребенка", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.37725, 59.998454]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Прибой", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.217826, 59.90358]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Ижорец", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.610417, 59.736439]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Дом Физической Культуры", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.324133, 59.956913]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Центр морской и физической подготовки",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [29.780248, 59.987816]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Sportlife", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Дворец спорта “Волна”", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.321752, 59.885029]}
        },
        {
            "type": "Feature",
            "properties": {"name": "SportLife", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн школы №639", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.45738, 59.925466]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн школы №235", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.280501, 59.926242]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Атлантика", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.108088, 59.854233]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Наташа", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.38547, 59.924997]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн «Экран»", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.362068, 60.004762]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Спортивный комплекс", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.369084, 60.002733]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Морская школа ДОСААФ", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.24482, 59.95243]}
        },
        {
            "type": "Feature",
            "properties": {"name": "ФОК Фламинго", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.182594, 59.852737]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Спорткомплекс \"Красный Октябрь\"", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.375337, 59.997721]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Золотой ручей", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [29.972424, 60.121699]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Клуб моржей \"Озерки\"", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.305879, 60.034214]}
        },
        {
            "type": "Feature",
            "properties": {"name": "ВУЗ", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.376603, 60.013047]}
        },
        {
            "type": "Feature",
            "properties": {"name": "СК Газпром", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.327429, 60.050412]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Океаниум", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.316191, 59.840759]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Кафедра плавания", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.292341, 59.925029]}
        },
        {
            "type": "Feature",
            "properties": {"name": "ДЮСШ «Дельфин»", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.292745, 59.924546]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Невская волна", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.453382, 59.920587]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Океаниум", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.317099, 60.047232]}
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Физкультурно оздоровительный центр Локомотив",
                "city_function": "Спорт",
                "type": "Бассейн"
            },
            "geometry": {"type": "Point", "coordinates": [30.344237, 59.918738]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Бассейн", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.329585, 59.966318]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Петроградец", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.325723, 59.964462]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Таеквондо2000", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.277666, 59.890932]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Центр плавания", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.378768, 60.000866]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Старт", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.373243, 60.003084]}
        },
        {
            "type": "Feature",
            "properties": {"name": "Волна", "city_function": "Спорт", "type": "Бассейн"},
            "geometry": {"type": "Point", "coordinates": [30.40101, 59.911854]}
        }
    ]
}
