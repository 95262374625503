export const bridges = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Балканский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.38286236729083, 59.828206481499159]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "20-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.285192563587174, 59.981486013516751]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "19-й Каменностровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.293737175329301, 59.981775242592256]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.293211927448638, 59.981584107216641]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "3-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.291447530507167, 59.981542777891363]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "4-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.287600616788811, 59.981458403671816]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "5-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.286228488620328, 59.981428244672713]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "6-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.285865144415684, 59.981040114049513]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "7-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.286343363003311, 59.980760271161813]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "9-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.286446586564107, 59.979862035401567]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "11-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.283303286476894, 59.978451386231768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "14-й Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.277822162886498, 59.976026231113195]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Каменноостровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.301453232910891, 59.977473582426434]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большеохтинский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.401190221737988, 59.942657636107953]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большеохтинский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.404551221953874, 59.942561813836129]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Дворцовый мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.308074348456593, 59.941194229978336]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Благовещенский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.289585904252718, 59.934790971723409]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Старо-Калинкин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.279398192520667, 59.916411457170497]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Зимний мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.319548102647889, 59.940902010387951]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Эрмитажный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.316951390603599, 59.942621672001863]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Зимний мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.318703543243888, 59.941469253134116]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Восточный Артиллерийский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.318535546261323, 59.953080362192971]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Западный Артиллерийский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.311560225864643, 59.95179909029315]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Казанский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.326270264746363, 59.935155337373615]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Синий мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.308963422740533, 59.931677358691218]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Певческий мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.319418817264751, 59.939989134305556]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Гренадерский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.334606584584769, 59.967957414876231]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Обуховский мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.498117768696432, 59.854306852425111]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Горсткин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.323996618720148, 59.923980416967652]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Каменный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.319077270755784, 59.930458538756938]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Аптекарский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.332322347374845, 59.967357812559932]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Петропавловский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.314118472386465, 59.967938571232139]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Прачечный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.336470490164647, 59.947832385702448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Верхний Лебяжий мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.332157888610663, 59.946875139679271]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Силин мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.307754783667697, 59.96975486555327]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Геслеровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.304237712073093, 59.969409972508039]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Карповский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.301280817814341, 59.970129576207768]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Молодёжный мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.28284446559902, 59.967934844316403]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Шлиссельбургский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.397272653065556, 59.919335235669294]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Биржевой мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.303372309822841, 59.946285819521556]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Тучков мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.285523570368348, 59.948962233847574]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Монастырский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.390183045698972, 59.923015239980742]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Обуховской Обороны",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.391944350948968, 59.923277913624389]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ушаковский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.300207510620684, 59.982578482651668]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Крестовский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.280414029087765, 59.967017788030525]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Лазаревский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.273818580779739, 59.965058876064788]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Петровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.254892016535429, 59.964938303961077]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Египетский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.297264804631908, 59.916919188986618]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мало-Калинкин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.281247673982424, 59.916727790188339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Инженерный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.337780139994731, 59.94177427443222]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Пантелеймоновский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.338307920636183, 59.942137681162912]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Садовый мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.335427331899741, 59.941053696914238]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Нижний Лебяжий мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.335939687139273, 59.94143259470809]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Садовый мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.330196714573503, 59.941488611010094]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Театральный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.328857613644225, 59.941562132190192]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мало-Конюшенный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.328932594337822, 59.941730753534912]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Конюшенный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.328512792072026, 59.940710329095509]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Конюшенный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.323100574917969, 59.942043385640609]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Зелёный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.319676207672654, 59.936169268528054]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Итальянский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.327121083806961, 59.937271804405391]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Аничков мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.343405476810851, 59.933257471581427]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Демидов мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.315466562618802, 59.928708110114762]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Красный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.315333458052134, 59.932983519164246]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Матвеев мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.293812145601866, 59.927692035011489]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Краснофлотский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.294100274819442, 59.927949007470694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Поцелуев мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.294995620652223, 59.928176698406595]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Почтамтский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.300813935981385, 59.930496174082116]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Фонарный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.303884972241679, 59.930607657909306]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й мост Круштейна",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.28655094355523, 59.929602098113364]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й мост Круштейна",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.290603366663465, 59.930915426591191]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Храповицкий мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.285288571083502, 59.928987243219041]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Кронверкский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.309231301573316, 59.949720292995345]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Иоанновский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.322336815815618, 59.952091138270525]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Декабристов",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.294713612343326, 59.926203079793019]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Матисов мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.278936608855545, 59.927047630063448]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Уральский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.268511976404266, 59.948807473539013]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Смоленский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.256713944707727, 59.947189727220241]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Наличный мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.231458763607979, 59.94775510790771]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Кораблестроителей",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.216572387171812, 59.949904698416638]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Шкиперский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.225914035008081, 59.93269909896555]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Ломоносова",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.335732349206847, 59.928466350904799]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Белинского",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.343209933129504, 59.938076466210411]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Лештуков мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.33176503627514, 59.926938817574431]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Семёновский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.327034855839461, 59.925151536936319]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Головинский мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.317201664792478, 59.981056482605432]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ланской мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.301169489379973, 59.989554244785971]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Карельский мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.294007591678934, 59.989867886981443]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Обуховский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.317784275439273, 59.921640708595788]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Измайловский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.30784173908641, 59.919348885104789]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Предтеченский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.355185875892172, 59.914546624655991]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Каретный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.359797738315638, 59.914032549201721]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Кирпичный мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.361838935358829, 59.913634493719378]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Адмиралтейский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.283845526133277, 59.930272742587455]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Банный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.281105432758171, 59.923178654223683]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Кокушкин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.313635069605631, 59.925820319528164]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Шаумяна",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.415494280789741, 59.934112214501489]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Энергетиков",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.435253538360719, 59.936584263395339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Торговый мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.29573590816775, 59.924573760712477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Кашин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.297015594001177, 59.923042804706547]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Пикалов мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.298423160053051, 59.920902900585261]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Старо-Никольский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.299034487245923, 59.920624756592609]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Красногвардейский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.299201901898105, 59.921089582770485]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Никольский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.303025317015937, 59.921994361217465]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Аларчин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.287173430461248, 59.920634825189445]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Могилёвский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.293372527790048, 59.921621277560085]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Смежный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.301066972756086, 59.918189869422982]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Красноармейский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.301821483483685, 59.917954402864098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Английский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.291221225683039, 59.915960343472214]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Коломенский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.284675883469085, 59.919550377100343]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Харламов мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.303033364274423, 59.924336855025075]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Львиный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.301436874787203, 59.926909054726856]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Подьяческий мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.305685043766463, 59.926787322330142]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Вознесенский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.308162373385958, 59.926473223477231]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Сенной мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.315192688288541, 59.926519958709726]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мучной мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.321038063757399, 59.931038945954782]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Банковский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.324900704409426, 59.932182021290288]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Гутуевский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.259451900659435, 59.909033312616323]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Степана Разина",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.264254428085085, 59.909233273261421]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Варшавский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.307290359901987, 59.908796241737882]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Митрофаньевский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.30064290871746, 59.908895889325194]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Балтийский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.300385290823119, 59.908897910513602]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Можайский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.331181399537414, 59.912575850060911]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Масляный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.324613380409371, 59.910187963426544]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Газовый мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.327420287023308, 59.911208806706355]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ипподромный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.336753087532305, 59.914574940099698]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Боровой мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.344778871406174, 59.916094862462984]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Калинкин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.275193877830052, 59.909157343787534]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Борисов мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.282110014328115, 59.909102334392436]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Таракановский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.287818854093505, 59.909057179984167]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Краснооктябрьский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.294202689378018, 59.908983575917873]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Троицкий мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.327481697133368, 59.948731145688249]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Московский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.318405358663046, 59.908626434046901]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Ильинский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.470411604890163, 59.964836659582907]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост через Петровский фарватер",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.216435439707698, 59.966491462201553]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост в Любашинском саду",
                "district": "Калининский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.398180350852783, 59.970008320010102]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост в Любашинском саду",
                "district": "Калининский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.397973105939034, 59.969780834403885]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Российский мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.471015320410103, 59.921680045253389]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Товарищеский мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.488582566600449, 59.911391985664793]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост через р. Дачная",
                "district": "Кировский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.249683931745473, 59.835814581323255]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Александра Невского",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.395287587521153, 59.92564319113567]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Сампсониевский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.337260753159747, 59.957990000624854]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Чернореченский мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.302634495156546, 59.986336595238861]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Витебский мост",
                "district": "Московский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.379313589186438, 59.821919664820527]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Володарский мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.45437232149385, 59.877999913363134]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Объездной мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.455691280589086, 59.954142871857165]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "3-й Смоленский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.209021323985262, 59.950148624875915]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "4-й Смоленский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.209932959754973, 59.95173322940628]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Кантемировский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.321811522418066, 59.978495913602707]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Литейный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.34949207808874, 59.951840999534824]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ждановский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.286523449136322, 59.953546958522431]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Красного Курсанта",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.280958366431637, 59.955252339818735]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "4-й Ждановский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.277029666335022, 59.958306539318635]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мало-Петровский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.271600534392832, 59.961068033072038]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Молвинский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.257283504309196, 59.900441610029006]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Бумажный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.261983998969317, 59.906063836970631]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Алмазный мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.35315675143454, 59.879020297386283]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Народного Ополчения",
                "district": "Кировский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.186749817146499, 59.823771710870851]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Рузовский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.332575427738117, 59.913068056365212]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Грааповский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.359194383879128, 59.910896254618777]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Казачий мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.382326178039193, 59.916649583372475]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Лаврский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.384663766889229, 59.921677540659999]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Авангардный мост",
                "district": "Красносельский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.186165936038094, 59.827638976734164]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Барочный мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.288782334328214, 59.968646633888646]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Елагин мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.2580989873831, 59.975561574829271]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "3-й Елагин мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.254271969453772, 59.982467116832339]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мало-Крестовский мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.28237343561829, 59.973274517540624]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Елагин мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.271610715007323, 59.97748325656945]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Атаманский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.374660404167898, 59.914905679252776]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Лахтинский мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.1788617482222, 59.989364047537897]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Долгоруков мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.45029102950588, 59.927282480324891]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "3-й Парковый мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.251399948302286, 59.968355015562629]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Малоохтинский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.40626644711352, 59.946556085183694]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Комаровский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.410772349718865, 59.942599554805142]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Бердов мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.277117628129098, 59.921176731249858]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Андреевский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.238100186905495, 59.946823187235637]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Екатерингофский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.261939936663445, 59.912770317126999]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Сутугин мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.269102402300916, 59.902407844962291]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Лаврский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.387276557137788, 59.92246394059309]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Каменный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.350630010189569, 59.915242557675228]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "4-й Волковский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.366545224395146, 59.851856026540723]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Бетанкура",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.266565494622032, 59.956944022403903]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Дыбенко",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.496086450225903, 59.909863326749964]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Старообрядческий мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.364106812444987, 59.904738393964642]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Ахмата Кадырова",
                "district": "Красносельский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.156378706197973, 59.859256258677441]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Волковский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.365285013474008, 59.854170583694042]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Смоленский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.208079946437831, 59.952001311713097]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "1-й Смоленский мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.207157444110294, 59.9504125093355]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Коллонтай",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.473347799182342, 59.920456495083151]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Подвойского",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.482483754608285, 59.914576865202527]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Рыбацкий мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.520781761814938, 59.834482811726453]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Яхтенный мост",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.213845205260988, 59.977888330600024]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Уткин мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.422886287121301, 59.933942513611392]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Заневский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.428152352491452, 59.931990185867981]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ново-Петергофский мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.297710850214116, 59.908951601764777]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Галерный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.269277558144108, 59.915477206064743]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Белградский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.355702029763538, 59.874286681496287]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Инженерный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.339893279400442, 59.940175034624289]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Трёхчастный мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.338123616834245, 59.939831199551342]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Ледовый мост",
                "district": "Невский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.468042173422461, 59.922484390546799]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Яблоновский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.436449936440386, 59.927281948557813]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Серный мост",
                "district": "Василеостровский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.2631003338658, 59.955321864334877]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Американский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.367778691960826, 59.914033187774308]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Американский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.367472497229986, 59.914021405486572]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Американский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.367102882388089, 59.914008674861499]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Американский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.366280779171483, 59.914001842751453]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Американский мост",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.363500503599489, 59.913805477642263]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Заповедный мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.270181628843275, 60.046729957095963]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Гражданский мост",
                "district": "Калининский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.40893759665207, 60.024257012521787]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Корабельный мост",
                "district": "Адмиралтейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.275432335060458, 59.926612475369161]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Новый мост",
                "district": "Кировский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.210608821658909, 59.833293677721279]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Ветеранов",
                "district": "Красносельский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.186781504216139, 59.833066005260022]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост в квартале №7 \"Ульянка\"",
                "district": "Кировский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.247174500077421, 59.839038500366151]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Рябовский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.504545886205158, 59.971405763452324]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Каплюсьный мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.49443813551656, 59.982942726629233]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Строгановский мост",
                "district": "Приморский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.310242547992029, 59.98416745148868]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Волковский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.353078714488682, 59.889771183684985]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "3-й Волковский мост",
                "district": "Фрунзенский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.353144269942458, 59.886408043235477]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост через новое русло р. Дудергофки",
                "district": "Красносельский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.196944602426349, 59.845110434677395]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост вблизи Атаманской улицы",
                "district": "Центральный",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.381445445261097, 59.917891995357799]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Яблоновский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.429744302348364, 59.929829649976256]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост у павильона Каменноостровского моста",
                "district": "Петроградский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.302890889595325, 59.976814879797907]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "2-й Красненький мост",
                "district": "Кировский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.256938494897927, 59.863392353910122]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "3-й Ржевский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.501591296142902, 59.976957927775373]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "мост Бурцева",
                "district": "Кировский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.212340414633189, 59.836487097891705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост по мемориальной аллее",
                "district": "Красносельский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.180440125743754, 59.845420874093016]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Всеволжский",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.52372673552032, 59.9782683309855]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Жерновский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.494410290917475, 59.966392815606994]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Камышинский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.514115743405036, 59.97545510612224]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Лупповский мост",
                "district": "Красногвардейский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.476219333905139, 59.960350270183319]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Чухонский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.5945549388621, 59.738020534871282]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Макаровский мост",
                "district": "Кронштадский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [29.775668482142652, 59.990692710521934]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост у Ольгина пруда",
                "district": "Петродворцовый",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [29.903803771959861, 59.875304507755708]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Горбатый мост",
                "district": "Пушкинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.444459160605646, 59.682169403388976]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Думский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.605249769328871, 59.747059329255684]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Никольский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.601536734120042, 59.746808573216306]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Тверской мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.610380480921901, 59.747392146059482]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Харламов мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.615105382716308, 59.747737468660098]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Большой Ижорский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.597349699988431, 59.740605608722248]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Оборонный мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.602794608369454, 59.730656524234149]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Адмиралтейский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.595977596913322, 59.7470966809705]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Вознесенский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.59446398858724, 59.749277894439686]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Малый Чухонский мост",
                "district": "Колпинский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.596527594309695, 59.734821568163717]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Парусный мост",
                "district": "Кронштадтский",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [29.78226233173088, 59.992107498666378]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост в луговом парке",
                "district": "Петродворцовый",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [29.892852515105293, 59.864797805537023]}
        },
        {"type": "Feature",
            "properties": {
                "type": "Мост",
                "name": "Мост по ул. Грибоедова",
                "district": "Петродворцовый",
                "city_function": "Достопримечательность"
            },
            "geometry": {"type": "Point", "coordinates": [30.023695049990845, 59.849590855568792]}
        }
    ]
}
