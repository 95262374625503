export const industrial_enterprises_1 = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "ООО \"Пискаревский молзавод\"",
                "organisation": "Общество с ограниченной ответственностью \"Санкт-Петербургский молочный завод \"Пискаревский\"",
                "type": "Молочный завод",
                "category": 1,
                "address": "д, Лапинский пр., 3, Санкт-Петербург, 195253"
            },
            "geometry": {"type": "Point", "coordinates": [30.449346, 59.979701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка природоохранного назначения",
                "organisation": "Санкт-Петербургское многопрофильное природоохранное государственное унитарное предприятие \"Экострой\" ",
                "type": "Природоохранное предприятие",
                "category": 1,
                "address": "Волхонское шоссе дом 116, корпус, Санкт-Петербург, 198323"
            },
            "geometry": {"type": "Point", "coordinates": [30.330466, 59.73827]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральная станция аэрации",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 1,
                "address": "198184, Остров Белый, Санкт-Петербург"
            },
            "geometry": {"type": "Point", "coordinates": [30.216437, 59.912012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория филиала ООО \"Юнилевер Русь\" г. Санкт-Петербург",
                "organisation": "ФИЛИАЛ ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"ЮНИЛЕВЕР РУСЬ\" Г. САНКТ-ПЕТЕРБУРГ",
                "type": "Другое",
                "category": 1,
                "address": "Прогонная ул., 1, Санкт-Петербург, 192102"
            },
            "geometry": {"type": "Point", "coordinates": [30.363432, 59.893638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филиал ООО «Текнос» в городе Санкт-Петербург",
                "organisation": "Филиал Общества с ограниченной ответственностью «Текнос» в городе Санкт-Петербург",
                "type": "Другое",
                "category": 1,
                "address": "ул. Новые Заводы, 56 к.3, Петергоф, Санкт-Петербург, 198517"
            },
            "geometry": {"type": "Point", "coordinates": [29.961591, 59.837912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Юго-Западные очистные сооружения",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 1,
                "address": "Волхонское ш., 123 к.2, Александровская, Санкт-Петербург, 196140"
            },
            "geometry": {"type": "Point", "coordinates": [30.118992, 59.819516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения г. Петродворец",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 1,
                "address": "Петергоф, Санкт-Петербург, 198504"
            },
            "geometry": {"type": "Point", "coordinates": [29.878666, 59.89306]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Северная станция аэрации",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 1,
                "address": "Коннолахтинский пр., 12, к. 2, Санкт-Петербург, 197229"
            },
            "geometry": {"type": "Point", "coordinates": [30.120898, 60.000834]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филиал СПб ГУП \"Завод МПБО-2\" \"ОЗ МПБО\"",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие \"Завод по механизированной переработке бытовых отходов\"",
                "type": "Завод по переработке отходов",
                "category": 1,
                "address": "198323, Санкт-Петербург, Волхонское шоссе, дом 116"
            },
            "geometry": {"type": "Point", "coordinates": [30.1375, 59.802151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филиал СПб ГУП \"Завод МПБО-2\" \"ПТО Новоселки\"",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие \"Завод по механизированной переработке бытовых отходов\"",
                "type": "Завод по переработке отходов",
                "category": 1,
                "address": "новосёлки, 18, к. 5, Левашово, Санкт-Петербург, 194361"
            },
            "geometry": {"type": "Point", "coordinates": [30.157989, 60.067238]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения г. Колпино ",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 1,
                "address": "ул. Севастьянова, 20, Колпино, Санкт-Петербург, 196655"
            },
            "geometry": {"type": "Point", "coordinates": [30.609045, 59.76676]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения г. Пушкин",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 1,
                "address": "Фильтровское ш., 7, Пушкин, Санкт-Петербург, 196625"
            },
            "geometry": {"type": "Point", "coordinates": [30.442815, 59.706968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО «РОСБИО» по адресу: 192019, г. Санкт–Петербург, ул. Мельничная, 12, лит. А",
                "organisation": "Общество с ограниченной ответственностью «РОСБИО» ",
                "type": "Другое",
                "category": 1,
                "address": "192019, г. Санкт–Петербург, ул. Мельничная, 12, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.393223, 59.914362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка № 4  ",
                "organisation": "Акционерное общество \"Технопарк Санкт-Петербурга\"",
                "type": "Другое",
                "category": 1,
                "address": "пр. Медиков, 3, лит. А, Санкт-Петербург, 197022"
            },
            "geometry": {"type": "Point", "coordinates": [30.315224, 59.969306]}
        }
    ]
}
