export const aqua_parks = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Родео Драйв",
                "city_function": "Спорт",
                "type": "Аквапарк",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 9:00-23:00",
                "contact_website": "https:\/\/www.aqua-rodeo.ru\/",
                "contact_phone": "+7 812 6777055"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Piterland Surfing",
                "city_function": "Спорт",
                "type": "Аквапарк",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "Mo 15:00-22:00; Tu-Su 10:30-22:30",
                "contact_website": "https:\/\/surfpiter.land\/",
                "contact_phone": "7 (812) 449-52-09"
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старт",
                "city_function": "Спорт",
                "type": "Аквапарк",
                "address": "Россия, Санкт-Петербург,проспект Энгельса, 154",
                "opening_hours": "10:00-21:00",
                "contact_website": "https:\/\/startacademy.club\/",
                "contact_phone": "7 (911) 115-76-38"
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fitness House",
                "city_function": "Спорт",
                "type": "Аквапарк",
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 24",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-22:00",
                "contact_website": "https:\/\/www.fitnesshouse.ru\/fh-na-krestovskom.html",
                "contact_phone": "7 (812) 610-06-06"
            },
            "geometry": {"type": "Point", "coordinates": [30.262339, 59.967746]}
        }
    ]
}
