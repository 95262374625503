export const industrial_enterprises_4 = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Производственный участок \"Кронштадт\" района электрических сетей \"Санкт-Петербург\"",
                "organisation": "Филиал «Северо-Западный» акционерного общества «Оборонэнерго»",
                "type": "Другое",
                "category": 4,
                "address": "Петровская улица, 6Б\nКронштадт, Санкт-Петербург, Россия, 197762"
            },
            "geometry": {"type": "Point", "coordinates": [29.779852, 59.986538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Университет  ИТМО (199034, г. Санкт-Петербург, Кадетская линия, д. 3, к. 2)",
                "organisation": "Университет  ИТМО (199034, г. Санкт-Петербург, Кадетская линия, д. 3, к. 2)",
                "type": "Другое",
                "category": 4,
                "address": "199034, г. Санкт-Петербург, Кадетская линия, д. 3, к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.290356, 59.942678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Газовая котельная",
                "organisation": "Федеральное государственное казенное военное образовательное учреждение высшего образования Санкт-Петербургский военный институт войск национальной гвардии Российской Федерации",
                "type": "Газовая котельная",
                "category": 4,
                "address": "улица Лётчика Пилютова, 1В\nСанкт-Петербург, Россия, 198206"
            },
            "geometry": {"type": "Point", "coordinates": [30.138129, 59.84436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПАО «Отель Санкт-Петербург»",
                "organisation": "Публичное акционерное общество «Отель Санкт-Петербург»",
                "type": "Другое",
                "category": 4,
                "address": "Пироговская набережная, 5\/2\nСанкт-Петербург, Россия, 194044"
            },
            "geometry": {"type": "Point", "coordinates": [30.341951, 59.956933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 110\/10 кВ №87 \"Обухово\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Электрическая подстанция",
                "category": 4,
                "address": "Прогонная улица, 13\nтерритория Мурзинка, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.470833, 59.850278]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Образовательное учреждение ",
                "organisation": "Учреждение дополнительного профессионального образования Санкт-Петербургский Международный Институт Менеджемента ИМИСП",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "9-я линия Васильевского острова, 50\nСанкт-Петербург, Россия, 199004"
            },
            "geometry": {"type": "Point", "coordinates": [30.273452, 59.944388]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория - Майков пер., 9",
                "organisation": "Санкт-Петербургское Государственное унитарное дорожное предприятие \"Путь\"",
                "type": "Другое",
                "category": 4,
                "address": "Майков переулок, 9\nСанкт-Петербург, Россия, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.284876, 59.897239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория завод \"Полимерстройматериалы\"",
                "organisation": "Открытое акционерное общество \"Санкт-Петербургский завод \"Полимерстройматериалы\"",
                "type": "Другое",
                "category": 4,
                "address": "Ириновский проспект, 1Ж\nСанкт-Петербург, Россия, 195248"
            },
            "geometry": {"type": "Point", "coordinates": [30.448441, 59.952714]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок ПЛП \"Невельская\", Санкт-Петербург, Кировский район, Невельская ул.,д.14, корп.1, литра А",
                "organisation": "Открытое акционерное общество \"ПСКОВВТОРМЕТ\"",
                "type": "Другое",
                "category": 4,
                "address": "Невельская улица, 14к1\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.242916, 59.90242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок \"Парнас\", Санкт-Петербург, 5-й Верхний переулок, д.19, литера Б.",
                "organisation": "Открытое акционерное общество \"ПСКОВВТОРМЕТ\"",
                "type": "Другое",
                "category": 4,
                "address": "5-й Верхний переулок, 19Б\nСанкт-Петербург, Россия, 194292"
            },
            "geometry": {"type": "Point", "coordinates": [30.356454, 60.0756]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Автобаза СПб ГУП \"Ритуальные услуги\"",
                "organisation": "Санкт-Петербургское Государственное унитарное предприятие \"Ритуальные услуги\"",
                "type": "Другое",
                "category": 4,
                "address": "1-я Советская улица, 8\nСанкт-Петербург, Россия, 191036"
            },
            "geometry": {"type": "Point", "coordinates": [30.363777, 59.931526]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1-й эксплуатационный район",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Другое",
                "category": 4,
                "address": "проспект Стачек, 114\nСанкт-Петербург, Россия, 198207"
            },
            "geometry": {"type": "Point", "coordinates": [30.257567, 59.858286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "2-й эксплуатационный район",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Другое",
                "category": 4,
                "address": "Атаманская улица, 5А\nСанкт-Петербург, Россия, 191167"
            },
            "geometry": {"type": "Point", "coordinates": [30.372381, 59.916568]}
        },
        {"type": "Feature",
            "properties": {
                "name": "3-й эксплуатационный район",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Другое",
                "category": 4,
                "address": "Бассейная улица, 73к2\nСанкт-Петербург, Россия, 196211"
            },
            "geometry": {"type": "Point", "coordinates": [30.342225, 59.863473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "4-й эксплуатационный район",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Другое",
                "category": 4,
                "address": "Шкиперский проток, 14к19литЗ\nСанкт-Петербург, Россия, 199106"
            },
            "geometry": {"type": "Point", "coordinates": [30.225641, 59.93025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "5-й эксплуатационный район",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Другое",
                "category": 4,
                "address": "улица Шкапина, 14\nСанкт-Петербург, Россия, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.294353, 59.906837]}
        },
        {"type": "Feature",
            "properties": {
                "name": "6-й эксплуатационный район",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Другое",
                "category": 4,
                "address": "улица Дыбенко, 31\nСанкт-Петербург, Россия, 193232"
            },
            "geometry": {"type": "Point", "coordinates": [30.487617, 59.907234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО АЦ Петербург",
                "organisation": "Общество с ограниченной ответственностью  \"АЦ Петербург\"",
                "type": "Другое",
                "category": 4,
                "address": "проспект Стачек, 106\nСанкт-Петербург, Россия, 198207"
            },
            "geometry": {"type": "Point", "coordinates": [30.262364, 59.860916]}
        },
        {"type": "Feature",
            "properties": {
                "name": "РТРС, г. Санкт-Петербург, ул. Чапаева, д. 26",
                "organisation": "Федеральное государственное унитарное предприятие \"Российская телевизинная и радиовещательная сеть\"",
                "type": "Другое",
                "category": 4,
                "address": "улица Чапаева, 26\nСанкт-Петербург, Россия, 197101"
            },
            "geometry": {"type": "Point", "coordinates": [30.329594, 59.964895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "198035, г.Санкт-Петербург, Межевой канал 5",
                "organisation": "Открытое акционерное общество \"ПСКОВВТОРМЕТ\"",
                "type": "Другое",
                "category": 4,
                "address": "198035, г.Санкт-Петербург, Межевой канал 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.25074, 59.911214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственный участок \"Ломоносов\" района электрических сетей \"Санкт-Петербург\"",
                "organisation": "Филиал «Северо-Западный» акционерного общества «Оборонэнерго»",
                "type": "Другое",
                "category": 4,
                "address": "Гаванский переулок, 2А\nЛомоносов, Санкт-Петербург, Россия, 198412"
            },
            "geometry": {"type": "Point", "coordinates": [29.766494, 59.92175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Склад",
                "organisation": "Санкт-Петербургский филиал акционерного общества \"РЕТАЛ\"",
                "type": "Склад",
                "category": 4,
                "address": "Химический переулок, 1к2К\nСанкт-Петербург, Россия, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.282145, 59.890844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"АЛ-КО Ст.Петербург\"",
                "organisation": "Общество с ограниченной ответственностью \"АЛ-КО Ст.Петербург\"",
                "type": "Другое",
                "category": 4,
                "address": "улица Крыленко, 3Б\nСанкт-Петербург, Россия, 193230"
            },
            "geometry": {"type": "Point", "coordinates": [30.458745, 59.893299]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 58 Колпинского района СПб",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 58 Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Красных Партизан, 12\nКолпино, Санкт-Петербург, Россия, 196657"
            },
            "geometry": {"type": "Point", "coordinates": [30.576818, 59.744487]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 55 Колпинского района СПб",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 55 комбинированного вида Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Павловская улица, 39\nКолпино, Санкт-Петербург, Россия, 196650"
            },
            "geometry": {"type": "Point", "coordinates": [30.575419, 59.748905]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Седова ул., д.113, лит. А  Диспетчерская станция с отстойно-разворотной площадкой ГПТ",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Организатор перевозок\"",
                "type": "Отстойно-разворотная площадка",
                "category": 4,
                "address": "улица Седова, 113\nСанкт-Петербург, Россия, 192174"
            },
            "geometry": {"type": "Point", "coordinates": [30.449187, 59.861092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Арсенальная ул., д.21",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 4,
                "address": "Арсенальная улица, 21\nСанкт-Петербург, Россия, 195009"
            },
            "geometry": {"type": "Point", "coordinates": [30.369596, 59.963953]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Кингесеппское шоссе, д. 49, корп. 3, лит. А",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 4,
                "address": "Кингисеппское шоссе, 49\nКрасное Село, Санкт-Петербург, Россия, 198320"
            },
            "geometry": {"type": "Point", "coordinates": [30.062193, 59.723598]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГУДСП \"Курортное\" г. Санкт-Петербург, г. Сестрорецк, ул. Транспортная, д. 8-а",
                "organisation": "Санкт – Петербургское государственное унитарное дорожное специализированное предприятие «Курортное» ",
                "type": "Дорожное Специализированное Предприятие",
                "category": 4,
                "address": "г. Санкт-Петербург, г. Сестрорецк, ул. Транспортная, д. 8-а"
            },
            "geometry": {"type": "Point", "coordinates": [29.946957, 60.083683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГУДСП \"Курортное\" г. Санкт-Петербург, пос. Белоостров, Новое шоссе, д.51",
                "organisation": "Санкт – Петербургское государственное унитарное дорожное специализированное предприятие «Курортное» ",
                "type": "Дорожное Специализированное Предприятие",
                "category": 4,
                "address": "г. Санкт-Петербург, пос. Белоостров, Новое шоссе, д.51"
            },
            "geometry": {"type": "Point", "coordinates": [30.022784, 60.136484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "отделение г. Санкт- Петербург, Северо-Западный участок эксплуатации транспорта",
                "organisation": "Акционерное общество \"Газпромнефть-Транспорт\"",
                "type": "Другое",
                "category": 4,
                "address": "улица Салова, 34\nСанкт-Петербург, Россия, 192102"
            },
            "geometry": {"type": "Point", "coordinates": [30.377978, 59.897311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Волхонская -6\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Волхонское шоссе, 125к6\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.118386, 59.822508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Центральная\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Волхонское шоссе, 125к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.110774, 59.817151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Маяк\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "улица Кораблестроителей, 12к1\nСанкт-Петербург, Россия, 199226"
            },
            "geometry": {"type": "Point", "coordinates": [30.216111, 59.934167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Приморская\" ",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "проспект Ветеранов, 184А\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.12556, 59.833163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Лигово\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Станционный посёлок, 12, Санкт-Петербург, 198205"
            },
            "geometry": {"type": "Point", "coordinates": [30.155337, 59.821365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Красносельская\" ",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Пушкинское шоссе, 3\nКрасное Село, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.109035, 59.732169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Сосновая Поляна\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "проспект Ветеранов, 184\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.124463, 59.833783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС «Старо – Паново 1» ",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": " г. пос. Старопаново, ул. Красная, д.14"
            },
            "geometry": {"type": "Point", "coordinates": [30.185554, 59.816316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Старо – Паново 2\" ",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": " г. пос. Старопаново, ул. Красная, д.14"
            },
            "geometry": {"type": "Point", "coordinates": [30.185554, 59.816316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Старо – Паново 3\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": " г. пос. Старопаново, ул. Красная, д.14"
            },
            "geometry": {"type": "Point", "coordinates": [30.185554, 59.816316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Строитель\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "город Красное Село, Геологическая улица, д.75"
            },
            "geometry": {"type": "Point", "coordinates": [30.090858, 59.747951]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Волхонская -1\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Волхонское шоссе, 125к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.110774, 59.817151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Волхонская -2\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Волхонское шоссе, 125к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.110774, 59.817151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Волхонская -5\"",
                "organisation": "Красносельская районная общественная организация Санкт – Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Волхонское шоссе, 125к6\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.112804, 59.818594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 51 Колпинского района СПб",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 51 общеразвивающего вида с приоритетным осуществлением художественно-эстетического развития детей Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Раумская улица, 17\nКолпино, Санкт-Петербург, Россия, 196657"
            },
            "geometry": {"type": "Point", "coordinates": [30.58866, 59.736728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гараж № 1",
                "organisation": "филиал \"ПОСММ\" Государственного унитарного предприятия \"Топливно-энергетический Комплекс Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "Рощинская улица, 21\nСанкт-Петербург, Россия, 196105"
            },
            "geometry": {"type": "Point", "coordinates": [30.343249, 59.880478]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 52 Колпинского района СПб",
                "organisation": "Государственное бюджетное  дошкольное  образовательное   учреждение детский сад № 52  Колпинского района Санкт-Петербурга    ",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Раумская улица, 3\nКолпино, Санкт-Петербург, Россия, 196657"
            },
            "geometry": {"type": "Point", "coordinates": [30.582733, 59.737242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад №54 Колпинского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение центр развития ребенка – детский сад №54 Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Богайчука, 18\nпосёлок Металлострой, Колпинский район, Санкт-Петербург, Россия, 196641"
            },
            "geometry": {"type": "Point", "coordinates": [30.563214, 59.815386]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 18 Колпинского района СПб",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 18 комбинированного вида Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "бульвар Трудящихся, 25к2\nКолпино, Санкт-Петербург, Россия, 196657"
            },
            "geometry": {"type": "Point", "coordinates": [30.575753, 59.73447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБОУ ДОД СДЮСШОР по конькобежному спорту Колпинского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное образовательное учреждение дополнительного образования детей специализированная детско-юношеская спортивная школа олимпийского резерва по конькобежному спорту Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Анисимова, 3\nКолпино, Санкт-Петербург, Россия, 196655"
            },
            "geometry": {"type": "Point", "coordinates": [30.605315, 59.734567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБП ОУ «ИПЛ»",
                "organisation": "Санкт-Петербургское государственное бюджетное профессиональное образовательное учреждение «Ижорский политехнический лицей»",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "бульвар Трудящихся, 29\nКолпино, Санкт-Петербург, Россия, 196657"
            },
            "geometry": {"type": "Point", "coordinates": [30.580691, 59.734729]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Территория предприятия",
                "organisation": "Общество с ограниченной ответственностью \"Управляющая компания \"Петербургский квартал\"",
                "type": "Другое",
                "category": 4,
                "address": "Лабораторный проспект, 18к5\nСанкт-Петербург, Россия, 195197"
            },
            "geometry": {"type": "Point", "coordinates": [30.382891, 59.982464]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Исполкомская ул., д.16, лит.А  Офисные помещения",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Организатор перевозок\"",
                "type": "Другое",
                "category": 4,
                "address": "Исполкомская улица, 16\nСанкт-Петербург, Россия, 191024"
            },
            "geometry": {"type": "Point", "coordinates": [30.382119, 59.929371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 53 Колпинского района СПб",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 53 Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Пролетарская улица, 111к2\nКолпино, Санкт-Петербург, Россия, 196650"
            },
            "geometry": {"type": "Point", "coordinates": [30.568909, 59.745362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка склад Оптиков, 20",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 4,
                "address": "улица Оптиков, 20\nСанкт-Петербург, Россия, 197345"
            },
            "geometry": {"type": "Point", "coordinates": [30.229549, 59.997964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "проспект Дальневосточный, д. 51",
                "organisation": "Санкт-Петербургское Государственное бюджетное профессиональное образовательное учреждение \"Российский колледж традиционной культуры\"",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "проспект Дальневосточный, д. 51"
            },
            "geometry": {"type": "Point", "coordinates": [30.463668, 59.887607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ул.Ворошилова",
                "organisation": "Санкт-Петербургское Государственное бюджетное профессиональное образовательное учреждение \"Российский колледж традиционной культуры\"",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Ворошилова, 7\nСанкт-Петербург, Россия, 193318"
            },
            "geometry": {"type": "Point", "coordinates": [30.446611, 59.923462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Крапивный пер., д. 10, лит. А (УРС-422)",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 4,
                "address": "Крапивный пер., д. 10, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.337625, 59.969168]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Автостоянка (Воскресенская)",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие по обслуживанию иностранных представительств «Инпредсервис» ",
                "type": "Автостоянка",
                "category": 4,
                "address": "Воскресенская набережная, 6-8\nСанкт-Петербург, Россия, 191015"
            },
            "geometry": {"type": "Point", "coordinates": [30.369695, 59.950046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Автостоянка (Ленинский)",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие по обслуживанию иностранных представительств «Инпредсервис» ",
                "type": "Автостоянка",
                "category": 4,
                "address": "Ленинский проспект, 115\nСанкт-Петербург, Россия, 198207"
            },
            "geometry": {"type": "Point", "coordinates": [30.247542, 59.851223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стоянка (Нахимова)",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие по обслуживанию иностранных представительств «Инпредсервис» ",
                "type": "Автостоянка",
                "category": 4,
                "address": "улица Нахимова, 7к1\nСанкт-Петербург, Россия, 199226"
            },
            "geometry": {"type": "Point", "coordinates": [30.221419, 59.942286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБУ ДЮЦ Московского района Санкт-Петербурга \"ЦФКСиЗ\"",
                "organisation": "Государственное бюджетное учреждение дополнительного образования детско-юношеский центр Московского района Санкт-Петербурга «Центр физической культуры, спорта и здоровья»",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "проспект Космонавтов, 47\nСанкт-Петербург, Россия, 196233"
            },
            "geometry": {"type": "Point", "coordinates": [30.352184, 59.850439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория 1",
                "organisation": "Филиал ООО \"Торговая Компания \"Мираторг\" в Санкт-Петербурге",
                "type": "Другое",
                "category": 4,
                "address": "Октябрьская набережная, 38АБ\nСанкт-Петербург, Россия, 193091"
            },
            "geometry": {"type": "Point", "coordinates": [30.44474, 59.908033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЗАО «СПб МФТЦ»",
                "organisation": "Закрытое акционерное общество \"Санкт-Петербургский Мировой Финансовый и Торговый Центр\"",
                "type": "Другое",
                "category": 4,
                "address": "Белоостровская улица, 28\nСанкт-Петербург, Россия, 197342"
            },
            "geometry": {"type": "Point", "coordinates": [30.309996, 59.987158]}
        },
        {"type": "Feature",
            "properties": {
                "name": " Пожарно-спасательная часть №42",
                "organisation": "Санк-Петербургское государственное казенное учереждение \"Пожарно-спасательный отряд противопожарной службы Санкт-Петербурга по Кировскому району Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "территория Элеваторная Площадка, 22Л\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.181286, 59.88115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОАО \"Фармбаза\"",
                "organisation": "Открытое акционерное общество \"Центральная фармацевтическая база Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "5-й Предпортовый проезд, 19\nСанкт-Петербург, Россия, 196240"
            },
            "geometry": {"type": "Point", "coordinates": [30.310676, 59.825728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Учебный корпус №3",
                "organisation": "Федеральное государственное бюджетное образовательное учреждение высшего образования \"Санкт-Петербургский государственный институт культуры\"",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Миллионная улица, 7\nСанкт-Петербург, Россия, 191186"
            },
            "geometry": {"type": "Point", "coordinates": [30.325705, 59.944521]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО «ЭНКА» (198096, Санкт-Петербург, ул. Дорога на Турухтанные острова, д. 10, корп. 2, лит. А)",
                "organisation": "Общество с ограниченной ответственностью «ЭНКА» ",
                "type": "Другое",
                "category": 4,
                "address": "198096, Санкт-Петербург, ул. Дорога на Турухтанные острова, д. 10, корп. 2, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.250354, 59.867611]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЧОУ ВО «Санкт-Петербургский университет технологии управления и экономики»",
                "organisation": "Частное образовательное учреждение высшего образования «Санкт-Петербургский университет технологии управления и экономики»",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Лермонтовский проспект, 44\nСанкт-Петербург, Россия, 190103"
            },
            "geometry": {"type": "Point", "coordinates": [30.297694, 59.913824]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кортъярд Санкт-Петербург Васильевский",
                "organisation": "Общество с ограниченной  ответственностью « Санкт-Петербург Си-Уай Отель Лизинг»",
                "type": "Другое",
                "category": 4,
                "address": "199178, г Санкт-Петербург, линия 2-Я В.о., 61\/30 ЛИТЕР А"
            },
            "geometry": {"type": "Point", "coordinates": [30.279091, 59.948834]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБНОУ «СПБ ГДТЮ»",
                "organisation": "1.Государственное бюджетное нетиповое образовательное учреждение «Санкт-Петербургский городской Дворец творчества юных» ",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Невский проспект, 39К\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.339406, 59.931957]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 35кВ №33 \"Понтонная\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Электрическая подстанция",
                "category": 4,
                "address": "Понтонный, Санкт-Петербург, 196643"
            },
            "geometry": {"type": "Point", "coordinates": [30.628056, 59.780833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 110кВ №39 \"Ломоносовская\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Электрическая подстанция",
                "category": 4,
                "address": "Михайловская улица, 52А\nЛомоносов, Санкт-Петербург, Россия, 198412"
            },
            "geometry": {"type": "Point", "coordinates": [29.760556, 59.891389]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ФБУ \"СПбНИИЛХ\"",
                "organisation": "Федеральное бюджетное учреждение \"Санкт-Петербургский научно-исследовательский институт лесного хозяйства\"",
                "type": "Другое",
                "category": 4,
                "address": "Институтский проспект, 21\nСанкт-Петербург, Россия, 194021"
            },
            "geometry": {"type": "Point", "coordinates": [30.350599, 60.003383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Склад",
                "organisation": "Санкт-Петербургское управление-филиал АО \"Электроцентромонтаж\"",
                "type": "Склад",
                "category": 4,
                "address": "196128, г.Санкт-Петербург, ул. Кузнецовская, дом 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.317503, 59.87145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка начальной школы ГБОУ гимназия № 498 Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное общеобразовательное учреждение гимназия № 498 Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Новосёлов, 21\nСанкт-Петербург, Россия, 193079"
            },
            "geometry": {"type": "Point", "coordinates": [30.463034, 59.882591]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Административный корпус",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Комплексный центр социального обслуживания населения Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "набережная реки Фонтанки, 152А\nСанкт-Петербург, Россия, 190103"
            },
            "geometry": {"type": "Point", "coordinates": [30.28449, 59.914958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Социально-досуговое отделение граждан пожилого возраста ",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Комплексный центр социального обслуживания населения Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "набережная реки Фонтанки, 152А\nСанкт-Петербург, Россия, 190103"
            },
            "geometry": {"type": "Point", "coordinates": [30.28449, 59.914958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отделение по обслуживанию жителей дома системы социального обслуживания населения, социально-реабилитационное отделение для граждан пожилого возраста (с деменцией)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Комплексный центр социального обслуживания населения Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Гражданская улица, 6\nСанкт-Петербург, Россия, 190031"
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.928203]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отделение по обслуживанию жителей дома системы социального обслуживания населения",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Комплексный центр социального обслуживания населения Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Можайская улица, 38\nСанкт-Петербург, Россия, 190013"
            },
            "geometry": {"type": "Point", "coordinates": [30.329522, 59.914529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отделение социальной помощи лицам без определенного места жительства и лицам, осво-бодившимся из мест лишения свободы, включающее ночное пребывание ",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Комплексный центр социального обслуживания населения Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "набережная Обводного канала, 177\nСанкт-Петербург, Россия, 190103"
            },
            "geometry": {"type": "Point", "coordinates": [30.29597, 59.909617]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отделение по обслуживанию жителей дома системы социального обслуживания населения, отделение дневного пребывания для граждан пожилого возраста, отделение временного проживания граждан пожилого возраста и инвалидов ",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Комплексный центр социального обслуживания населения Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "3-я Красноармейская улица, 4\nСанкт-Петербург, Россия, 190005"
            },
            "geometry": {"type": "Point", "coordinates": [30.316443, 59.914457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка №2 (Социально-реабилитационное отделение, отделение приема и консультации, ОСЗН Адмиралтейского района, Отделение Центр семейного консультирования «Радуга»)",
                "organisation": "Санкт-петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Садовая улица, 55-57\nСанкт-Петербург, Россия, 190068"
            },
            "geometry": {"type": "Point", "coordinates": [30.30878, 59.923261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 6 (Отделение дневного пребывания «Родничок»)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Московский проспект, 57литЗ\nСанкт-Петербург, Россия, 190005"
            },
            "geometry": {"type": "Point", "coordinates": [30.317009, 59.910505]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 5 (Отделение адаптивной физической культуры,  Отделение социально-медицинского сопровождения, Отделение раннего вмешательства для детей в возрасте до 3-х лет )",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Большой Казачий переулок, 1\nСанкт-Петербург, Россия, 191180"
            },
            "geometry": {"type": "Point", "coordinates": [30.328516, 59.923789]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 1 (Организационно-методическое отделение)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Курляндская улица, 32\nСанкт-Петербург, Россия, 190020"
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 59.91157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 4 (Отделение дневного пребывания «Родничок»)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Угловой пер., 5, Санкт-Петербург, 190005"
            },
            "geometry": {"type": "Point", "coordinates": [30.316686, 59.909815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 7 (Отделение профессиональной реабилитации инвалидов трудоспособного возраста и профессиональной ориентации детей инвалидов )",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Курляндская улица, 32\nСанкт-Петербург, Россия, 190020"
            },
            "geometry": {"type": "Point", "coordinates": [30.27866, 59.91157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 3 (Отделения временного проживания для инвалидов с ограниченными умственными возможностями «Островок», «Карлсон»)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения «Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга»",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "улица Декабристов, 57\nСанкт-Петербург, Россия, 190121"
            },
            "geometry": {"type": "Point", "coordinates": [30.282963, 59.923757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 8 (Социально-реабилитационное отделение)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения \"Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга\"",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Садовая улица, 55-57\nСанкт-Петербург, Россия, 190068"
            },
            "geometry": {"type": "Point", "coordinates": [30.30878, 59.923261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 9 (Социально-трудовое реабилитационное отделение для инвалидов с ограниченными умственными возможностями - \"Минипрачечная\")",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение социального обслуживания населения \"Центр социальной реабилитации инвалидов и детей-инвалидов Адмиралтейского района Санкт-Петербурга\"",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Санкт-Петербург , ул. Можайская д. 38"
            },
            "geometry": {"type": "Point", "coordinates": [30.329522, 59.914529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крытый ледовый каток им. В. Боброва",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение \"Центр физической культуры, спорта и здоровья Курортного  района Санкт-Петербурга\"",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "наб. реки Сестры, 12, Санкт-Петербург, 197706"
            },
            "geometry": {"type": "Point", "coordinates": [29.968337, 60.098911]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Централизованная бухгалтерия администрации Василеостровского района Санкт-Петербурга",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Централизованная бухгалтерия администрации Василеостровского района Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "10-я линия Васильевского острова, 37\nСанкт-Петербург, Россия, 199178"
            },
            "geometry": {"type": "Point", "coordinates": [30.271583, 59.943556]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Волхонская-4\"",
                "organisation": "Василеостровская районная общественная организация Санкт-Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Волхонское шоссе, 125к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.110774, 59.817151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Женская консультация № 31",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Городская поликлиника №6\"",
                "type": "Медицинское учреждение ",
                "category": 4,
                "address": "переулок Ногина, 4\nСанкт-Петербург, Россия, 192029"
            },
            "geometry": {"type": "Point", "coordinates": [30.426415, 59.899249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Врачебно-физкультурное отделение",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Городская поликлиника №6\"",
                "type": "Медицинское учреждение ",
                "category": 4,
                "address": "проспект Елизарова, 32к2\nСанкт-Петербург, Россия, 192148"
            },
            "geometry": {"type": "Point", "coordinates": [30.40958, 59.892766]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гериатрическое отделение",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Городская поликлиника №6\"",
                "type": "Медицинское учреждение ",
                "category": 4,
                "address": "улица Седова, 35\nСанкт-Петербург, Россия, 192148"
            },
            "geometry": {"type": "Point", "coordinates": [30.420074, 59.888715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУДО ДТЦ \"Театральная семья\" Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение дополнительного образования \"Детский творческий центр \"Театральная семья\" Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "проспект Обуховской Обороны, 121А\nСанкт-Петербург, Россия, 192029"
            },
            "geometry": {"type": "Point", "coordinates": [30.43835, 59.892014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"Отель Санкт-Петербург\"",
                "organisation": "Общество с ограниченной ответственностью «Отель Санкт-Петербург»",
                "type": "Другое",
                "category": 4,
                "address": "Пироговская наб., 5\/2, Санкт-Петербург, 194044"
            },
            "geometry": {"type": "Point", "coordinates": [30.341353, 59.956989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центр физической культуры, спорта и здоровья Петроградского района Санкт-Петербурга",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение \"Центр физической культуры, спорта и здоровья Петроградского района Санкт-Петербурга\"",
                "type": "Учреждение социального обслуживания",
                "category": 4,
                "address": "Каменноостровский проспект, 26-28\nСанкт-Петербург, Россия, 197101"
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ СШОР №2",
                "organisation": "Государственное бюджетное учреждение спортивная школа олимпийского резерва №2 Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Подвойского, 31к3\nСанкт-Петербург, Россия, 193231"
            },
            "geometry": {"type": "Point", "coordinates": [30.49248, 59.91401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ ДО ЦГПВДИМ \"Взлет\" Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение дополнительного образования \"Центр гражданского и патриотического воспитания детей и молодежи \"Взлет\" Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Товарищеский проспект, 28к2\nСанкт-Петербург, Россия, 193231"
            },
            "geometry": {"type": "Point", "coordinates": [30.491916, 59.912802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБПОУ «ПК №4 СПб» 1",
                "organisation": "Государственное бюджетное профессиональное образовательное учреждение «Педагогический колледж №4 Санкт-Петербурга» ",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Костромской проспект, 46\nСанкт-Петербург, Россия, 194214"
            },
            "geometry": {"type": "Point", "coordinates": [30.314976, 60.021335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБПОУ «ПК №4 СПб» 2",
                "organisation": "Государственное бюджетное профессиональное образовательное учреждение «Педагогический колледж №4 Санкт-Петербурга» ",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Россия, 195221, г. Санкт-Петербург,\nЗамшина ул., д. 17, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.401873, 59.974306]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ ДО \"ПДДТ\" Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение дополнительного образования \"Правобережный дом детского творчества\" Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Новосёлов, 59\nСанкт-Петербург, Россия, 193315"
            },
            "geometry": {"type": "Point", "coordinates": [30.485885, 59.887016]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ \"Информационно-методический центр\" Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение дополнительного профессионального педагогического образования Центр повышения квалификации специалистов \"Информационно-методический центр\" Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Бабушкина, 42к4\nСанкт-Петербург, Россия, 192171"
            },
            "geometry": {"type": "Point", "coordinates": [30.445726, 59.877812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центр дополнительного образования детей ГБОУ гимназия № 330 Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное общеобразовательное учреждение гимназия № 330 Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "Хрустальная улица, 12\nСанкт-Петербург, Россия, 192019"
            },
            "geometry": {"type": "Point", "coordinates": [30.402672, 59.911651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ СШОР по легкой атлетике № 1 Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение спортивная школа олимпийского резерва по легкой атлетике № 1 Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Ольги Берггольц, 15\nСанкт-Петербург, Россия, 192029"
            },
            "geometry": {"type": "Point", "coordinates": [30.423746, 59.894978]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ ДО \"Дом детского творчества \"Левобережный\" Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение дополнительного образования \"Дом детского творчества \"Левобережный\" Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Бабушкина, 56к2\nСанкт-Петербург, Россия, 192131"
            },
            "geometry": {"type": "Point", "coordinates": [30.452076, 59.869997]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ГБУ ДО ЦППМСП Невского района Санкт-Петербурга",
                "organisation": "Государственное бюджетное учреждение дополнительного образования Центр психолого-педагогической, медицинской и социальной помощи Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение ",
                "category": 4,
                "address": "улица Новосёлов, 11\nСанкт-Петербург, Россия, 193079"
            },
            "geometry": {"type": "Point", "coordinates": [30.457586, 59.881923]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория №2  ",
                "organisation": "филиал Общества с Ограниченной Ответственностью \"РесурсТранс\" в горде Санкт-Петербурге",
                "type": "Другое",
                "category": 4,
                "address": "улица Связи, 1\nЛомоносов, Санкт-Петербург, Россия, 198411"
            },
            "geometry": {"type": "Point", "coordinates": [29.808846, 59.898725]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, Октябрьская наб., д. 102, лит. Т",
                "organisation": "Санкт-Петербургское Государственное бюджетное учреждение здравоохранения \"Медицинский санитарный транспорт\"",
                "type": "Медицинское учреждение ",
                "category": 4,
                "address": "Захарьевская улица, 8\nСанкт-Петербург, Россия, 191123"
            },
            "geometry": {"type": "Point", "coordinates": [30.354487, 59.948023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Шторм\"",
                "organisation": "Общество с ограниченной ответственностью «КАБЕЛЬ ГРУПП»",
                "type": "Другое",
                "category": 4,
                "address": "г. Санкт-Петербург, Октябрьская наб., д. 102, лит. Т"
            },
            "geometry": {"type": "Point", "coordinates": [30.483691, 59.86982]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Полюс\"",
                "organisation": "Василеостровская районная общественная организация Санкт-Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "проспект КИМа, 19\nСанкт-Петербург, Россия, 199155"
            },
            "geometry": {"type": "Point", "coordinates": [30.243006, 59.955552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПО КАС \"Морская\"",
                "organisation": "Василеостровская районная общественная организация Санкт-Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "Морская наб., д. 28"
            },
            "geometry": {"type": "Point", "coordinates": [30.21438, 59.958638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка № 2 ГБОУ лицей № 344 Невского района Санкт-Петербурга",
                "organisation": "Василеостровская районная общественная организация Санкт-Петербурга - местное отделение Всероссийского общества автомобилистов",
                "type": "Другое",
                "category": 4,
                "address": "улица Тельмана, 47\nСанкт-Петербург, Россия, 193315"
            },
            "geometry": {"type": "Point", "coordinates": [30.486815, 59.891192]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ПСЧ №53",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Пожарно-спасательный отряд противопожарной службы Санкт-Петербурга по Приморскому району Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "ул. Ильюшина, 22, Санкт-Петербург, 197372"
            },
            "geometry": {"type": "Point", "coordinates": [30.239134, 60.008977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ПСЧ №76",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Пожарно-спасательный отряд противопожарной службы Санкт-Петербурга по Приморскому району Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "Морская улица, 3\nСанкт-Петербург, Россия, 199226"
            },
            "geometry": {"type": "Point", "coordinates": [30.159642, 59.990359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ПСЧ №23",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Пожарно-спасательный отряд противопожарной службы Санкт-Петербурга по Приморскому району Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "Долгоозёрная улица, 28\nСанкт-Петербург, Россия, 197371"
            },
            "geometry": {"type": "Point", "coordinates": [30.265185, 60.022108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка ПСЧ №59",
                "organisation": "Санкт-Петербургское государственное казенное учреждение \"Пожарно-спасательный отряд противопожарной службы Санкт-Петербурга по Приморскому району Санкт-Петербурга\"",
                "type": "Другое",
                "category": 4,
                "address": "Высокая улица, 7\nпосёлок Лисий Нос, Санкт-Петербург, Россия, 197755"
            },
            "geometry": {"type": "Point", "coordinates": [30.00842, 60.021285]}
        }
    ]
}
