//https://t.me/elemnt3 Y:2021
import React from 'react';
import 'antd/dist/antd.css';
import {Menu, Select, message} from 'antd';

function DropDownMenu_(props) {
    function onChange(name) {
        // console.log(`selected ${value}`);
        // console.log(name)
        message.info(`Выбранная тематика: ${name}`);
    }

    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    const {Option} = Select;
    return (
        <>
            <Select
                showSearch
                style={{width: 200}}
                placeholder="Вид досуга"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={onChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
            >
                {props.items.map((name, index) => (
                    <Option key={index} value={name}>{name}</Option>
                ))}
            </Select>
        </>
    )
}

export default React.memo(DropDownMenu_);



