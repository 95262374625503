export const viewpoints = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Аптекарский проспект, 4",
                "city_function": "Достопримечательности",
                "name": "Крыша Hi-Hat",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.318146, 59.969322]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, ​Петропавловская Крепость, 3 к3 лит Д",
                "city_function": "Достопримечательности",
                "name": "Невская панорама",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.320645, 59.950075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Лиговский пр., 74",
                "city_function": "Достопримечательности",
                "name": "Пространство «Крыша» в лофт-проекте «Этажи»",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.356604, 59.921776]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Исакиевская площадь, 4",
                "city_function": "Достопримечательности",
                "name": "Колоннада Исаакиевского собора",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.306046, 59.933908]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, улица Декабристов, 34",
                "city_function": "Достопримечательности",
                "name": "Панорамная терраса на крыше второй сцены Мариинского театра",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.293996, 59.925475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Лиговский пр., 65",
                "city_function": "Достопримечательности",
                "name": "Крыша на Лиговском проспекте, 65",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.35813, 59.927242]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, пл. Растрелли, д. 1",
                "city_function": "Достопримечательности",
                "name": "Звонница Смольного собора",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.395277, 59.948832]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Лиговский просп., д. 30А",
                "city_function": "Достопримечательности",
                "name": "Крыша «Галереи»",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.359456, 59.927186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Петропавловская крепость, д. 3",
                "city_function": "Достопримечательности",
                "name": "Колокольня Петропавловского собора",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.316579, 59.950226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Невский просп., д. 54",
                "city_function": "Достопримечательности",
                "name": "Музей-фотосалон Карла Буллы ",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.336363, 59.934443]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, пл. Конституции, д. 3\/2",
                "city_function": "Достопримечательности",
                "name": "Смотровая площадка Leader Tower",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.308132, 59.85109]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Санкт-Петербург, Наб. реки Фонтанки, 49а ",
                "city_function": "Достопримечательности",
                "name": "Крыша на Новой сцене Александринского театра",
                "type": "Видовая точка"
            },
            "geometry": {"type": "Point", "coordinates": [30.337539, 59.930389]}
        }
    ]
}
