export const bowling_billiards = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "MazaPark",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Бухарестская, 30",
                "building": "ТРК Континент",
                "opening_hours": "11:00-06:00",
                "contact_phone": "+7 (812) 643-33-33",
                "contact_website": "https:\/\/mazapark.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.369407, 59.883709]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bowling Piter",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Ефимова, 3С",
                "building": "ТРК Сенная",
                "opening_hours": "11:00-06:00",
                "contact_phone": "7 (812) 380-30-90",
                "contact_website": "http:\/\/pitergame.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320511, 59.924903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bowling show",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "building": "ТРЦ Июнь",
                "opening_hours": "Mo-Fr 15:00-06:00; Sa-Su 12:00-06:00",
                "contact_phone": "7 (812) 635-65-25",
                "contact_website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.474341, 59.945964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bowling show",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Набережная обводного канала, 118 лит С",
                "building": "ТРК Варшавский экспресс",
                "opening_hours": "Mo-Fr 15:00-06:00; Sa-Su 12:00-06:00",
                "contact_phone": "7 (812) 380-30-05",
                "contact_website": "http:\/\/bowling-show.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.307371, 59.90803]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нептун",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Набережная обводного канала, 93А",
                "building": "МДЦ Нептун",
                "opening_hours": "Mo-Fr 15:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "+7 (812) 324-46-93",
                "contact_website": "http:\/\/bowl.neptun.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.339179, 59.916063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Альмак",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Новолитовская, 15а",
                "building": "Развлекательный центр",
                "opening_hours": "24\/7",
                "contact_phone": "7 (812) 327-47-07",
                "contact_website": "http:\/\/www.almakclub.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.355821, 59.980971]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Боулинг",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "building": "ТРК Питер Радуга",
                "opening_hours": "7 (812) 448‒98‒81",
                "contact_phone": null,
                "contact_website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349288, 59.868311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "М-111 Ультра",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, проспект Непокоренных, 49",
                "building": "БЦ Деловой комплекс",
                "opening_hours": "Fr 18:00-23:00 Sa-Su 12:00-23:00",
                "contact_phone": "7 (812) 622-42-49",
                "contact_website": "https:\/\/www.m111-ultra.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.387079, 59.994393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Formula City",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Тверская, 58\/6",
                "building": "Развлекательный комплекс",
                "opening_hours": "Mo-Fr 17:00-02:00; Sa-Su 13:00-05:00",
                "contact_phone": "7 (812) 716-55-88",
                "contact_website": "http:\/\/formula-city.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.612778, 59.7349]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Боулинг",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Типанова, 21",
                "building": "ТК Питер",
                "opening_hours": "11:00-23:00",
                "contact_phone": "7 (812) 335-68-35",
                "contact_website": "http:\/\/pitergame.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.339646, 59.853659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Golden Strike",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "building": "ТРК Гулливер",
                "opening_hours": "Mo-Th,Su 11:00-01:00; Fr 11:00-02:00; Sa 11:00-03:00",
                "contact_phone": "+7 812 4412424",
                "contact_website": "http:\/\/www.golden-strike.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.256516, 59.99023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Play Off",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Фучика, 2",
                "building": "ТРК Рио",
                "opening_hours": "Mo-Fr 10:00-22:00; Sa-Su 10:00-00:00",
                "contact_phone": "7 (812) 414-30-80",
                "contact_website": "http:\/\/play-off-kafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360472, 59.876277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русское поле",
                "city_function": "Культура, развлечения, творчество",
                "type": "Боулинг\/Бильярд",
                "address": "Россия, Санкт-Петербург, Шереметьевская, 15",
                "building": "ТРЦ Пулково-3",
                "opening_hours": "Mo-Fr 11:00-03:00; Sa-Su 11:00-06:00",
                "contact_phone": "7 (812) 615-11-12",
                "contact_website": "http:\/\/russkoepole.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320584, 59.805912]}
        }
    ]
}
