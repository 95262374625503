import React from "react";

import Hero from '../components/home/Hero';
import About from '../components/home/About'
import Routes from '../components/home/Routes';
import Map from "../components/home/map/entities/app";
import DownloadFilesSection from "../components/home/dashComponent/DownloadFilesSection";

function NHome() {
    return (
        <div className='main'>
            <Hero/>
            <DownloadFilesSection/>
            <Routes/>
            <Map/>
            <About/>
        </div>
    );
}

export default NHome;
