//https://t.me/elemnt3 Y:2021
import React, { useEffect } from 'react';
import './App.css';
import 'antd/dist/antd.css';
import {Layout} from 'antd';
import NHeader from './components/common/Header'
import NFooter from './components/common/Footer'
import NHome from './views/Home'
import { API_BASE_URL } from "./config";

const {Header, Content, Footer} = Layout

function App() {
    // useEffect(async () => {
    //     async function getMunicipalities() {
    //         const url = `${API_BASE_URL}municipalities/`
    //         const response = await fetch(url)
    //         return  response.json()
    //     }
    //
    //     await getMunicipalities();
    // });

    return (
        <Layout className="mainLayout">
            <Header>
                <NHeader/>
            </Header>
            <Content>
                <NHome/>
            </Content>
            <Footer>
                <NFooter/>
            </Footer>
        </Layout>
    );
}

export default App;
