export const religious_buildings = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Исаакиевский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Исаакиевская площадь, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.306242, 59.934138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Воскресенский Смольный собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, площадь Расстрелли, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.395277, 59.948856]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Собор Воскресения Христова",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, набережная канала Грибоедова, д. 2Б лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.328628, 59.940044]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Казанский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Казанская площадь, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.32465, 59.934375]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петропавловский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Петропавловская крепость"
            },
            "geometry": {"type": "Point", "coordinates": [30.316032, 59.950124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Соборная мечеть",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Кронверкский проспект, д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.323893, 59.955257]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Николо-Богоявленский Морской собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Никольская площадь, д. 1 к. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.300128, 59.922516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свято-Троицкая Александро-Невская лавра",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Монастырки, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.388226, 59.921035]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Часовня святой блаженной Ксении",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Камская улица, д. 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.251345, 59.944335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сампсониевский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Большой Сампсониевский проспект, д. 41"
            },
            "geometry": {"type": "Point", "coordinates": [30.342837, 59.968393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свято-Троицкая Сергиева Приморская пустынь",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Стрельна, Санкт-Петербургское шоссе, д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.089849, 59.854533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Троицы Живоначальной",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, проспект Обуховской Обороны, д. 235"
            },
            "geometry": {"type": "Point", "coordinates": [30.472551, 59.863417]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Благовещения Пресвятой Богородицы",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, 7-я линия В.О., д. 68"
            },
            "geometry": {"type": "Point", "coordinates": [30.274371, 59.945829]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Собор Владимирской Иконы Божией Матери",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Владимирский проспект, д. 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.348477, 59.928173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Феодоровский Государев собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Академический проспект, д. 34"
            },
            "geometry": {"type": "Point", "coordinates": [30.390667, 59.725828]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Собор Святых Петра и Павла",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, д. 32"
            },
            "geometry": {"type": "Point", "coordinates": [29.912438, 59.878732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Святой Екатерины",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 40-42"
            },
            "geometry": {"type": "Point", "coordinates": [30.332298, 59.935387]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троице-Измайловский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Измайловский проспект, д. 7А"
            },
            "geometry": {"type": "Point", "coordinates": [30.305845, 59.916407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Святого Воскресения",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Смоленки, д. 29"
            },
            "geometry": {"type": "Point", "coordinates": [30.253821, 59.947949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дацан Гунзэчойнэй",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Приморский проспект, д. 91"
            },
            "geometry": {"type": "Point", "coordinates": [30.256028, 59.983566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Большая хоральная синагога",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Лермонтовский проспект, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.291885, 59.924747]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Базилика Святой Екатерины Александрийской",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, 32-34"
            },
            "geometry": {"type": "Point", "coordinates": [30.329149, 59.935681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Лурдской Божией Матери",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Ковенский переулок, д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.356882, 59.937126]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Святого Станислава",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, улица Союза Печатников, д. 22"
            },
            "geometry": {"type": "Point", "coordinates": [30.288246, 59.922688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Святейшего Сердца Иисуса",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, улица Бабушкина, д. 57"
            },
            "geometry": {"type": "Point", "coordinates": [30.436659, 59.880602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Рождества Иоанна Предтечи",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Каменноостровский проспект, д. 83"
            },
            "geometry": {"type": "Point", "coordinates": [30.300414, 59.980909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Софийский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Софийская площадь, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.393788, 59.705319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крестовоздвиженский казачий собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Лиговский проспект, д. 128"
            },
            "geometry": {"type": "Point", "coordinates": [30.353361, 59.916281]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Спаса Нерукотворного Образа на Конюшенной площади",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Конюшенная площадь, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.326242, 59.94139]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Святого великомученика и целителя Пантелеимона",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, улица Пестеля, д. 2А"
            },
            "geometry": {"type": "Point", "coordinates": [30.341102, 59.942535]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свято-Иоанновский ставропигиальный женский монастырь",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, набережная реки Карповки, д. 45"
            },
            "geometry": {"type": "Point", "coordinates": [30.30014, 59.970766]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Скорбященская церковь",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, проспект Чернышевского, д. 3А"
            },
            "geometry": {"type": "Point", "coordinates": [30.358942, 59.948764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Трех Святителей Вселенских",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, 6-я линия Васильевского острова, д. 11Б"
            },
            "geometry": {"type": "Point", "coordinates": [30.282867, 59.940105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Успения Пресвятой Богородицы",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, набережная Лейтенанта Шмидта, д. 27"
            },
            "geometry": {"type": "Point", "coordinates": [30.275513, 59.933465]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Иконы Божией Матери Всех Скорбящих Радость",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, проспект Обуховской Обороны, д. 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.40721, 59.912507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкая часовня",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Троицкая площадь"
            },
            "geometry": {"type": "Point", "coordinates": [30.325743, 59.951735]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чесменская церковь",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, улица Ленсовета, д. 12"
            },
            "geometry": {"type": "Point", "coordinates": [30.330865, 59.856981]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Никольский морской собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Кронштадт, Якорная площадь, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [29.777653, 59.991728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Воскресенский Новодевичий монастырь",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Московский проспект, д. 100"
            },
            "geometry": {"type": "Point", "coordinates": [30.321348, 59.898602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лютеранская церковь Петра и Павла",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Невский проспект, д. 22-24"
            },
            "geometry": {"type": "Point", "coordinates": [30.323914, 59.936824]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Святой Марии",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Большая Конюшенная улица, д. 8а"
            },
            "geometry": {"type": "Point", "coordinates": [30.324104, 59.93819]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лютеранская церковь Святой Анны",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Кирочная улица, д. 8В"
            },
            "geometry": {"type": "Point", "coordinates": [30.352053, 59.944575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эстонская церковь Святого Иоанна",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, улица Декабристов, д. 54А"
            },
            "geometry": {"type": "Point", "coordinates": [30.286645, 59.924047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лютеранская церковь святого Михаила",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Средний проспект В.О., д. 18Б"
            },
            "geometry": {"type": "Point", "coordinates": [30.283559, 59.944332]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Андреевский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, 6-я линия В.О., д. 11"
            },
            "geometry": {"type": "Point", "coordinates": [30.283216, 59.939781]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Святых Праведных Симеона Богоприимца и Анны Пророчицы",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Моховая улица, д. 48"
            },
            "geometry": {"type": "Point", "coordinates": [30.345719, 59.938547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Евангелическо-лютеранский Храм Святой Екатерины",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Большой проспект В.О., д. 1а"
            },
            "geometry": {"type": "Point", "coordinates": [30.289365, 59.941709]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Собор Феодоровской иконы Божией Матери",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Миргородская улица, д. 1B, литер A"
            },
            "geometry": {"type": "Point", "coordinates": [30.368554, 59.926078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шведская лютеранско-евангелическая церковь святой Екатерины",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Малая Конюшенная улица, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.32546, 59.938454]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Казанской иконы Божией Матери",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Зеленогорск, Приморское шоссе, д. 547"
            },
            "geometry": {"type": "Point", "coordinates": [29.706206, 60.192295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Преподобного Серафима Саровского",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Песочный, Ленинградская улица, д. 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.160163, 60.125759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Святого Великомученика и Целителя Пантелеимона",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Сестрорецк, Тарховский проспект, д. 32"
            },
            "geometry": {"type": "Point", "coordinates": [29.973736, 60.069295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь Преображения Господня",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Зеленогорск, проспект Ленина, д. 13а"
            },
            "geometry": {"type": "Point", "coordinates": [29.706722, 60.195847]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм Сергия Радонежского",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Фуражный переулок, д. 4\/8, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.381227, 59.707457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Собор святой великомученицы Екатерины",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Соборная площадь, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.407149, 59.720398]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Часовня Святителя Спиридона Тримифунтского",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Большой проспект В.О., д. 67а"
            },
            "geometry": {"type": "Point", "coordinates": [30.267438, 59.934824]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Князь-Владимирский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, улица Блохина, д. 26"
            },
            "geometry": {"type": "Point", "coordinates": [30.295737, 59.951702]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Храм святой благоверной Великой княгини инокини Анны Кашинской Чудотворицы",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Большой Сампсониевский проспект, д. 53"
            },
            "geometry": {"type": "Point", "coordinates": [30.340806, 59.971966]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спасо-Преображенский собор",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Преображенская площадь, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.352504, 59.94303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковь святого мученика Иулиана Тарсийского",
                "city_function": "Достопримечательности",
                "type": "Религииозное сооружение",
                "address": "г. Санкт-Петербург, Пушкин, Кадетский бульвар, д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.403985, 59.706635]}
        }
    ]
}
