//https://t.me/elemnt3 Y:2021
import React from "react";
import {BackTop} from 'antd';
import {UpCircleOutlined} from '@ant-design/icons';
import main_logo from '../../assets/images/main_logo.png';
import nccr_logo from '../../assets/images/nccr_logo.svg';

const NFooter = () =>{
    const style = {
        fontSize:40,
        color: '#D62346',
        textAlign: 'center',
    };
    return (
        <div className='container-fluid'>
            <div className='footer'>
                <div className='logo'>
                    <a style={{paddingRight: '5px'}} href="https://actcognitive.org/"
                       title='Перейти на сайт "НЦКР"'>
                        <img style={{height: '100px', width: '100px'}} src={nccr_logo} alt=""/>
                    </a>
                    <a style={{paddingLeft: '5px'}} href="https://idu.itmo.ru/ru/"
                       title='Перейти на сайт "Институт дизайна и урбанистики Университета ИТМО"'>
                        <img src={main_logo} alt=""/>
                    </a>
                </div>
                <BackTop style={{zIndex: 400}}>
                    <UpCircleOutlined style={style}/>
                </BackTop>
            </div>
        </div>
    )
}

export default NFooter
