//https://t.me/elemnt3 Y:2021

import * as React from 'react';

function CityInfo(props) {
    const {info} = props;

    const website = info?.website ?? info?.contact_website
    const address = info?.address
    const city_function = info?.city_function
    const name = info?.name
        // const name_2 =? info.const
    const opening_hours = info?.opening_hours
    const contact_vk = info?.contact_vk
    const contact_instagram = info?.contact_instagram
    const contact_phone = info?.contact_phone
    const barrier = info?.barrier
    const description = info?.description
    const level = info?.level
    const wheelchair = info?.wheelchair
    const type = info?.type
    const precision = info?.precision
    const outdoor_seating = info?.outdoor_seating
    const building = info?.building
    const district = info?.district
    const cinema_3d = info?.cinema_3d
    const screen = info?.screen
    const building_levels = info?.building_levels
    const capacity = info?.capacity
    const construction_year = info?.construction_year
    const organisation = info?.organisation
    const category = info?.category
    const landuse = info?.landuse
    const architect = info?.architect
    const start_date = info?.start_date
    const building_architecture = info?.building_architecture
    const swimming_pool = info?.swimming_pool
    const amenity = info?.amenity
    const sport_type = info?.sport_type
    const access = info?.access
    const yand_rating = info?.yand_rating
    // const gis_rating = info?['2gis_rating?']
    const short_name = info?.short_name
    const number_of_places = info?.number_of_places
    const ref_mkrf_theaters = info?.ref_mkrf_theaters
    const note = info?.note

    return (
        <div style={{width: '15rem'}}>
            <div style={{'display': 'block', 'textAlign': 'center'}}>
                <span style={{'fontWeight': 'bold'}}>{name}</span><br/>
                <div style={{fontSize: '12px', color: '#808080'}}>{city_function}</div>
            </div>

            <div style={{'display': 'block', paddingTop: '5px'}}>
                {type &&
                    <div style={{ fontSize: '13px' }}><span style={{ fontWeight: 600 }}>Тип:</span> {type}</div>
                }

                {address &&
                    <div style={{ fontSize: '13px' }}><span style={{ fontWeight: 600 }}>Адрес:</span> {address}</div>
                }

                {contact_vk &&
                    <div style={{ fontSize: '13px' }}><span style={{ fontWeight: 600 }}>VK:</span> {contact_vk}</div>
                }

                {contact_instagram &&
                    <div style={{ fontSize: '13px' }}><span style={{ fontWeight: 600 }}>Instagram:</span> {contact_instagram}</div>
                }

                {contact_phone &&
                    <div style={{ fontSize: '13px' }}><span style={{ fontWeight: 600 }}>Телефон:</span> {contact_phone}</div>
                }

                {district &&
                    <div style={{ fontSize: '13px' }}><span style={{ fontWeight: 600 }}>Район:</span> {district}</div>
                }

                {website &&
                    <div style={{ fontSize: '13px' }}>
                        <span style={{ fontWeight: 600 }}>
                            <a href={website} target='_blank'>{website}</a>
                        </span>
                    </div>
                }
            </div>
        </div>
    );
}

export default React.memo(CityInfo);
