export const sports_grounds = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.13978, 59.851033]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.356748, 60.054616]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.20072, 59.936685]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281982, 59.850568]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419476, 59.835726]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.292456, 59.811412]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.292349, 59.811296]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.31726, 59.987688]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.212432, 59.989622]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.422542, 59.970133]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.204013, 59.849684]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.274205, 59.897494]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.279111, 59.900215]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.280239, 59.899277]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.312181, 59.957501]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327508, 59.841984]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.214434, 59.995525]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Biathlon Range",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "biathlon"
            },
            "geometry": {"type": "Point", "coordinates": [30.41708, 60.022345]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482875, 60.01591]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "workout"
            },
            "geometry": {"type": "Point", "coordinates": [30.246403, 59.979239]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer,basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.331979, 59.827465]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.307562, 60.046625]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.352519, 60.034969]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.341712, 59.820774]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.339326, 59.820161]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer; basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.554154, 59.827808]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28107, 59.939955]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.361637, 59.846135]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball; soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.253891, 60.041031]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.365988, 59.988999]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.354554, 59.983096]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423777, 59.928023]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.411753, 59.949045]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.361704, 59.846846]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.361991, 59.84622]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.175297, 59.865516]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.485103, 59.850275]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.450882, 59.891762]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.404712, 60.042707]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328831, 59.876436]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.55604, 59.827003]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.174888, 59.865608]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.175087, 59.865562]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball; soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.24763, 60.028362]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252653, 59.900844]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.328537, 59.821546]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323581, 59.875128]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.352602, 60.037243]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.342305, 60.040074]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.34135, 60.039399]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.337132, 60.040725]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.121614, 59.848516]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.267121, 60.017766]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.265744, 60.017905]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.421265, 59.836185]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270998, 59.931312]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.270712, 59.970415]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.270327, 59.970476]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.315551, 59.752328]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.276399, 59.994889]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.447787, 59.902861]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.452215, 59.898678]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.452105, 59.898793]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.271067, 59.970211]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.142572, 59.843564]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.130973, 59.829289]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.468245, 59.897857]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450249, 59.903226]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323952, 60.008917]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.280316, 59.969467]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.280204, 59.969933]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.141646, 59.829178]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383512, 59.838319]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329444, 59.99528]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324648, 60.008193]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.499434, 59.934422]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.242396, 59.967471]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.332823, 59.826708]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.274319, 59.897183]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.525256, 59.985231]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.134967, 60.005328]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482561, 60.008579]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.251258, 60.029193]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.399776, 60.047823]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.388916, 59.856141]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360909, 60.049931]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Круг катания",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308307, 59.951044]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "f",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.177434, 59.836741]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.107697, 59.840896]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.107646, 59.840626]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.46902, 59.855936]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.224205, 59.983294]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403199, 59.829885]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40304, 59.830046]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.178647, 59.838537]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.25504, 59.935609]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.414898, 59.905768]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "canoe"
            },
            "geometry": {"type": "Point", "coordinates": [30.278739, 59.975092]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "canoe_polo"
            },
            "geometry": {"type": "Point", "coordinates": [30.278428, 59.975214]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.493384, 59.918219]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.49293, 59.918108]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311033, 59.898064]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311358, 59.903915]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.315395, 60.061127]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.275647, 59.974909]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.337438, 59.986848]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355634, 59.83889]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.204037, 59.937677]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.348438, 60.056009]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.380462, 60.038359]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.352686, 60.051585]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.234909, 59.970816]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.18072, 59.836334]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.372266, 59.998581]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.326858, 59.85714]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.190402, 59.858485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": "Автодром",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "motor"
            },
            "geometry": {"type": "Point", "coordinates": [30.445853, 59.954195]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая площадь",
                "name": "Большая площадь",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Зимой это каток",
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.266595, 59.980718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Кронверкское",
                "name": "Круг катания",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308307, 59.951044]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта",
                "name": "Лахта-Теннис",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.157457, 59.993768]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровский остров",
                "name": "Локомотив",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.270177, 59.958143]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Эриванская улица, 5к2",
                "name": "Озерки",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "06:00-24:00",
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.30788, 60.040922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, парк Собственная дача",
                "name": "Открытый манеж",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Открытый манеж",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.850953, 59.891898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Труда, 8",
                "name": "Спортзал ГУАП",
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.295001, 59.930318]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": "Спортивное поле",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371754, 59.70548]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": "Стрелковый клуб \"Северянин\"",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [30.65469, 59.766278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 15\/17М",
                "name": "Тир ДОСААФ",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "Mo-Su 09:00-21:00",
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [30.317499, 59.972755]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": "Трасса кросс-кантри",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.967514, 60.11798]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Горелово",
                "name": "Хели-Драйв",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "motocross"
            },
            "geometry": {"type": "Point", "coordinates": [30.246441, 59.826861]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": "Центр развития пляжных видов спорта",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "beachvolleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.245382, 59.979196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Петро-Славянка",
                "name": "Boom",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "paintball"
            },
            "geometry": {"type": "Point", "coordinates": [30.513751, 59.788895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк имени 300-летия Санкт-Петербурга",
                "name": "FK-ramps",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.197601, 59.98314]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево, Нововестинская улица",
                "name": "SportLife",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459062, 59.703408]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": "Tree to Tree",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "Tu-Fr 15:00-20:00; Sa,Su 10:00-20:00; Mo off",
                "description": "Верёвочный парк",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.873378, 60.164442]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85В",
                "name": "X-House",
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "11:00-20:00",
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "lasertag"
            },
            "geometry": {"type": "Point", "coordinates": [30.145995, 59.994025]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": "permissive",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.400715, 59.830945]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": "permissive",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.400698, 59.830964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Ржевка, жилой комплекс Нью-Тон",
                "name": null,
                "type": "Спортивная площадка",
                "access": "permissive",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.479843, 59.960059]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": "permissive",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.481114, 59.960609]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.301305, 60.013305]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299519, 60.018212]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шуваловский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305672, 60.077663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22038, 59.859431]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.325788, 59.862463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317073, 59.877068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.267585, 60.109992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.984342, 60.092243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.225279, 59.939031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.259778, 59.951903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Уральская улица, 6",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.264318, 59.951278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средняя улица, 3В",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361368, 59.898797]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.324791, 59.862637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.325171, 59.862641]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255075, 59.95412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": "private",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.255379, 59.954192]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большеохтинский проспект, 1к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.410813, 59.946244]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Введенская улица, 11",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.302989, 59.956094]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.433521, 60.008103]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.155831, 59.995107]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.427795, 60.008164]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.427537, 60.008108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.290184, 59.961343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;running"
            },
            "geometry": {"type": "Point", "coordinates": [30.1456, 59.785339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.156361, 59.995086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.327043, 59.862413]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Черокова, 18к1В",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140963, 59.854004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.372289, 60.022807]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.323163, 59.87743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.322258, 59.878411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.399794, 60.047823]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.178261, 59.853768]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.181722, 59.855001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.171463, 59.858451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26752, 59.840887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271322, 59.843112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Калейдоскоп",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378144, 59.985446]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259986, 59.836078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.148545, 59.785134]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зверинская улица, 33",
                "name": null,
                "type": "Спортивная площадка",
                "access": "yes",
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.303682, 59.951698]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.425486, 59.928711]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.288783, 60.021585]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 60.034219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.274522, 59.849415]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.425078, 59.897969]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.425446, 59.898126]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.425515, 59.898155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.401234, 59.84963]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 369к5",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skiing"
            },
            "geometry": {"type": "Point", "coordinates": [30.276671, 60.075903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.027175, 59.84943]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, парк Александрия",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.944939, 59.876863]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.416358, 59.96051]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Таврический переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379634, 59.94999]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 33\/40",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.351636, 59.922909]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425295, 59.989806]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.306838, 60.011348]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.214474, 59.995527]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214729, 59.998658]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.213184, 59.998874]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Маршала Блюхера, 8к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.371577, 59.985251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.641949, 59.782279]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ping_pong"
            },
            "geometry": {"type": "Point", "coordinates": [30.182698, 59.861758]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323119, 59.965947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "crossfit"
            },
            "geometry": {"type": "Point", "coordinates": [30.387471, 59.868408]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.305816, 60.010944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.230591, 59.840274]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.231186, 59.8405]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.231807, 59.840722]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шуваловский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298956, 60.069339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Разлив",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.947781, 60.086091]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.30277, 60.012672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342338, 59.841884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.349479, 60.016813]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.349016, 60.01692]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Серово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.519933, 60.229685]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.826839, 59.873812]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.826636, 59.873621]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.313346, 59.846095]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.262385, 59.982126]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Е",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.263831, 59.98211]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новоорловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271286, 60.05766]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.325025, 60.033959]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новоорловский государственный природный заказник регионального значения",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281398, 60.051709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.611922, 59.787417]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.619139, 59.784348]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.620562, 59.784465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новоорловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27246, 60.056706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Пулковское, жилой комплекс Солнцепарк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370191, 59.752621]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Йога",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233949, 60.031419]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.378688, 59.840807]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.495617, 59.841037]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.780042, 59.90376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Дворцовый проспект, 37А",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.775678, 59.91559]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Тарховка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "24\/7",
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [29.968412, 60.073205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.24093, 59.924235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "streetball"
            },
            "geometry": {"type": "Point", "coordinates": [30.401466, 59.83125]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новоорловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272002, 60.056626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.322992, 60.003543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.321563, 60.004132]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новоорловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27351, 60.057465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.273678, 59.849446]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, жилой комплекс Цивилизация",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450772, 59.900403]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Швейцарская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "gorodki"
            },
            "geometry": {"type": "Point", "coordinates": [29.765282, 59.907016]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Северная дорога, 12",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.227351, 59.974623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Никольская площадь",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300951, 59.922952]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.405479, 59.855335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.404381, 59.856081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.403704, 59.857321]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.403762, 59.857267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.412201, 59.8595]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.41214, 59.859553]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Михайловская улица, 29соор1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.764813, 59.907846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [29.765248, 59.908957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.354116, 60.016553]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388329, 60.016433]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.770658, 59.909814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763931, 59.910498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397249, 59.830233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.398387, 59.83138]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.269411, 59.838781]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266566, 59.84053]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264627, 59.840299]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.264059, 59.840343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.264237, 59.84045]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Лизы Чайкиной, 10",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300987, 59.955678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.307184, 60.064386]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.56681, 59.811918]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.248306, 59.848547]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.248998, 59.848549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.248702, 59.849504]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.232879, 59.982377]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.350197, 59.96421]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.20669, 59.858031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровский остров, 2",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.286298, 59.951964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416149, 60.033568]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.918204, 59.871156]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.313412, 59.818701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360909, 60.049931]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401593, 59.971473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.353862, 59.859478]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.334547, 59.982819]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30593, 59.84793]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, территория Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.978843, 60.055694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.508411, 59.831205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.494453, 59.891341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.390482, 59.840706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.896978, 59.8827]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малоохтинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.412959, 59.932882]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390767, 59.854575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.390887, 59.85426]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.390751, 59.85457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.387596, 59.851923]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.388916, 59.856141]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.388918, 59.856141]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38872, 59.856532]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.77296, 59.904371]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer; basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.772592, 59.904694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.401753, 59.830966]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3981, 59.842025]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778406, 59.910851]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.401422, 59.838815]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.40356, 59.827614]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.44696, 59.868214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27029, 59.911686]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.396125, 59.836219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400835, 59.841757]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.395043, 59.843562]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401259, 59.829416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.427601, 59.832012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.426021, 59.833493]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.423318, 59.832491]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236755, 59.952061]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.412485, 59.843807]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.77545, 59.911416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.775287, 59.911457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361445, 59.884149]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343467, 59.860599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411952, 59.859416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [29.780042, 59.90376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.351951, 59.84969]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.351395, 59.849634]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351847, 59.849116]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "futsal"
            },
            "geometry": {"type": "Point", "coordinates": [30.353099, 59.848878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "futsal"
            },
            "geometry": {"type": "Point", "coordinates": [30.352928, 59.849283]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "obstacle_course"
            },
            "geometry": {"type": "Point", "coordinates": [30.352287, 59.848278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.390695, 60.00892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.253347, 59.868034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.781105, 59.905312]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.919349, 59.876593]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.91946, 59.876731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Дегтярёва",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.764542, 59.909106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409597, 59.929515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.407273, 59.929587]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Подрезова улица, 16А",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.302676, 59.96471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey;volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.408759, 59.84565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.328154, 59.829209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 90к5",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.332987, 59.923912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.39802, 59.831695]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Казачий переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.329419, 59.922159]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Рылеево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.357932, 59.87536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Семенцы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.32252, 59.912747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Батайский переулок, 12",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.322052, 59.912614]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.326604, 59.871284]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.31555, 59.867047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43908, 59.874563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский, жилой комплекс Балканы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness_station"
            },
            "geometry": {"type": "Point", "coordinates": [30.402328, 59.834841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.022572, 59.852593]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.431471, 59.95441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.492517, 59.937626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Павловский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.449489, 59.688987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344393, 59.923038]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.344444, 59.923171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.344658, 59.92309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475193, 59.858526]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.740728, 59.995882]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, исторический район Матросская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475185, 59.684773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.412919, 59.857889]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413389, 59.858633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.39943, 59.853898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.382182, 60.003457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371156, 60.00242]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.437611, 60.009604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21И",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.409412, 60.00321]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21О",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.407539, 60.003432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21Н",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.406355, 60.003801]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21М",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.405283, 60.002941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.404312, 60.014285]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.334967, 60.034549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.331059, 60.05579]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.331331, 60.056491]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314484, 60.061311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.326298, 60.050184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.324977, 60.047388]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.417303, 59.967266]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.416546, 59.966738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.409726, 59.981551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.400624, 59.981014]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.406467, 59.966982]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.278696, 60.003351]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.274874, 60.014996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.248606, 60.0197]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.259832, 60.012034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.256229, 60.014744]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.229596, 60.006355]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.215095, 60.00792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "motor"
            },
            "geometry": {"type": "Point", "coordinates": [30.331464, 60.025598]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302772, 60.012669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.442375, 59.95694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.28275, 60.003042]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.285472, 60.001838]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.349161, 59.982883]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.293984, 59.941006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.42597, 59.971318]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.407138, 59.894162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Некрасовский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.366915, 59.936843]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Добровольцев, 54Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.165583, 59.827539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.335587, 59.870539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.335559, 59.87117]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 50А",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.239507, 59.983031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.230351, 59.952741]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.420077, 59.881317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 21к4Д",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.250196, 59.850276]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24877, 59.849384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.136493, 59.842389]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Михайловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.240465, 60.081993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331354, 59.86341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 16М",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.340287, 59.928064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.478191, 59.967553]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.329657, 59.876973]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.381144, 60.004266]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.962661, 60.095609]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Есенина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.484689, 59.914023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442225, 59.873489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.563807, 59.817064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.185363, 59.858093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.189047, 59.856663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.605975, 59.73921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.259042, 59.836127]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.262305, 59.840107]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473831, 59.903645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.340776, 60.018527]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.18091, 59.851724]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Михайловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251246, 60.084154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.341596, 59.974986]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.18714, 59.849325]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.244168, 60.014218]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.23376, 60.014668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.230474, 60.02153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.352545, 60.051387]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387614, 59.851922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.437181, 59.88376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281239, 59.956514]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.21093, 59.851006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.214129, 59.856175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.254589, 59.909759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.232882, 59.853738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.241589, 59.857842]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24307, 59.857678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.349943, 59.848918]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Крытый всесезонный стадион",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.327014, 59.856028]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.472323, 59.945964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.296121, 59.811129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.294613, 59.811531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.433408, 59.961297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, исторический район 2-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.27714, 60.077536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.301465, 59.873365]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.480171, 59.882612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.350736, 59.850158]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359546, 59.856565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.463262, 59.915213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, сквер Героев-Ижорцев",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.582903, 59.746791]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.605023, 59.740374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, улица Ремизова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.615769, 59.741486]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.608909, 59.739527]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.608104, 59.738909]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.467857, 59.915339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.467737, 59.915219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.926741, 59.876366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.895264, 59.875031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.896775, 59.871727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.493829, 59.962015]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.564936, 59.735288]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.613747, 59.732024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.904185, 59.863547]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Карташихина улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.240234, 59.935644]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, улица Веденеева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [29.864625, 59.876258]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.380909, 59.851233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.372223, 59.849628]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.3792, 59.849798]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.273084, 59.854739]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.384897, 59.845522]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.380987, 59.838804]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409568, 59.934463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419273, 59.835991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426905, 59.83001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.422471, 59.837462]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.420179, 59.836967]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.494521, 59.898678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.398056, 59.861883]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.785678, 59.910343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778741, 59.903108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.244329, 60.02405]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350208, 60.051488]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350328, 60.051669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.333761, 59.846974]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216258, 59.900146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.296305, 59.912572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.798096, 59.9869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.490414, 59.882978]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.491867, 59.881795]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ординарная улица, 5",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.308411, 59.965695]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, территория Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.966906, 60.048328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.455185, 59.919884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.241134, 59.940376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.08778, 59.74637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405699, 59.732398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.325281, 59.846982]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Театральная аллея, 18",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283471, 59.980309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.787569, 59.987541]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.611862, 59.787508]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.023128, 59.845313]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Красные Зори",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [29.949944, 59.856645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.516851, 59.832075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.859816, 59.886548]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skating;football"
            },
            "geometry": {"type": "Point", "coordinates": [30.238097, 59.930539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Гавань, жилой комплекс Суоми",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.250443, 59.936293]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259095, 59.935162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416011, 59.720848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.247311, 59.930117]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.236603, 59.934662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.417903, 59.734384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415027, 59.73044]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416945, 59.730553]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.584726, 59.743141]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.284886, 59.956864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284894, 59.956863]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.409959, 59.725598]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Карташихина улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "hockey;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.240057, 59.936006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.771052, 59.90674]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.474322, 59.907696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.97701, 60.098645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.404076, 59.727696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.408028, 59.729089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.33874, 59.923613]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322665, 60.042763]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.377346, 59.931453]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.377499, 59.931102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.377697, 59.930623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Павильон Урицкого",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.385381, 59.730966]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Павильон Урицкого",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.386053, 59.730274]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Павильон Урицкого",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.38542, 59.730721]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404644, 59.736627]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397633, 59.73712]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.860718, 59.868223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.31206, 59.922436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309153, 59.925584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38042, 59.943551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.769822, 60.000196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running;soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.210863, 59.825789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.395542, 59.864225]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.416648, 59.740028]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [29.770322, 59.996871]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.281306, 59.860489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287857, 59.81901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.787322, 59.987271]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.799621, 59.985924]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.79811, 59.986908]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.150959, 59.83256]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 56к2",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.234855, 59.982645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.366148, 60.052834]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.311821, 59.837911]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.312818, 59.837551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378507, 59.808473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.379149, 59.80844]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.375659, 59.808591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378028, 59.809328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.383445, 59.80433]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.486397, 59.918817]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.486389, 59.918817]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463343, 59.897869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.48214, 59.925033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.482118, 59.925018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.49328, 59.913334]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.477909, 59.908515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477928, 59.908519]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.314484, 60.061306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.307188, 60.064388]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.348983, 60.055889]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3475, 60.056379]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.373068, 60.049875]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.34309, 60.043622]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.327706, 60.040051]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343087, 60.043617]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.327697, 60.040061]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.392062, 60.037861]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.405169, 60.041138]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.42511, 60.027511]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.427293, 60.035433]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.213853, 60.026359]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151566, 59.832169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.152363, 59.832155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.468135, 59.900719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.230493, 60.021522]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.241272, 60.020856]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer, hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.241839, 60.018479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.417822, 59.920133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.278246, 60.004626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.238491, 60.011607]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer, hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.238494, 60.011607]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.278895, 60.009479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.275992, 60.009071]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.275981, 60.009071]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.281887, 60.013978]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287841, 60.011669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.413125, 59.924747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.238335, 59.999766]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.238324, 59.999776]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.229591, 60.006366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4655, 59.900742]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.393115, 59.941877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467676, 59.902024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340652, 59.835959]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346154, 59.837148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Таврический сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.376009, 59.944595]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365468, 60.053691]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.291887, 59.998333]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.291881, 59.998339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40Е",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.253037, 59.974656]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40Г",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.252051, 59.974638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40Ж",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.253376, 59.974591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.252424, 59.974869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.252087, 59.974936]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40Д",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.252704, 59.974717]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 40З",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.253714, 59.974524]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324708, 60.033338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Павловский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.445286, 59.689878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.327164, 59.846846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306696, 59.968224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.379503, 59.864239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.373323, 60.015218]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.268186, 59.966929]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Спортивная улица, 8АГ",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.264377, 59.968167]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Мира, 4",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31281, 59.959578]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Пушкарская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.307948, 59.95992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.567745, 59.811578]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.565941, 59.81226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Садовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.568479, 59.811438]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.223333, 60.008138]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Садовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.568644, 59.811544]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.384102, 59.946367]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.291873, 60.001119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.608602, 59.73728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.604668, 59.734882]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467212, 59.851997]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.385034, 59.833118]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.411899, 59.828335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.402712, 59.826432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.369627, 59.862547]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.366477, 59.86489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.418696, 59.841656]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.32027, 60.0538]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.466859, 59.853931]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.413911, 59.841353]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.334558, 59.846907]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.33458, 59.847128]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.334555, 59.847315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.592337, 59.775242]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.247067, 59.83681]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.255692, 59.833628]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.497419, 59.912349]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Объездная улица, 7соор1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.717516, 60.195125]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.346137, 59.837151]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [29.914107, 59.867805]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Крытый всесезонный стадион",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.327638, 59.85592]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.327034, 59.856501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.300818, 59.914245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.303156, 59.913368]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.301305, 59.913187]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.947884, 60.125714]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.329526, 60.014508]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.310112, 59.845826]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.328444, 59.829343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, территория Хвойный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.075896, 59.67196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.242805, 59.8397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.957191, 60.091457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.462313, 59.883201]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239248, 60.057957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.227142, 59.959419]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.226138, 59.958603]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.315307, 59.910796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473374, 59.939781]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.500028, 59.94852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;football"
            },
            "geometry": {"type": "Point", "coordinates": [30.499607, 59.938886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.489137, 59.954387]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488849, 59.958027]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.493407, 59.946774]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.217805, 59.95659]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215519, 59.956485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217788, 59.956589]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.221664, 59.952232]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.215509, 59.956464]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.779608, 59.908187]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.313521, 59.909572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.26044, 60.001217]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763031, 59.913173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Удельный парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.311161, 60.006865]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лидинская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.327341, 60.01229]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323795, 60.016144]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.330794, 60.012865]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, исторический район 2-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skiing"
            },
            "geometry": {"type": "Point", "coordinates": [30.278336, 60.075534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.371028, 59.853162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.372629, 59.852042]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Якобштадтский переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.305777, 59.914922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.334507, 60.013416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.315093, 59.992134]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ярославский проспект",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320286, 60.016314]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.353907, 59.858253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.278951, 59.852844]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334536, 60.017738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.274651, 59.853347]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.264246, 59.867603]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.264857, 59.86771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Берёзовый сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31594, 60.022522]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.383296, 59.705585]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.862495, 59.88823]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.465952, 59.930215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.471587, 59.931944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.261882, 59.903032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.353157, 60.016869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.411405, 60.039631]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319739, 60.014492]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Нежинская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322159, 60.008598]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 19В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275928, 59.912394]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.285036, 59.911447]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.282793, 59.911232]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297679, 59.998127]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297663, 59.998298]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gaelic_football"
            },
            "geometry": {"type": "Point", "coordinates": [30.614466, 59.73707]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.207928, 59.852673]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.487877, 59.891]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.291545, 59.91089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.28639, 59.912754]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.241051, 59.839751]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.308858, 59.843442]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27568, 59.905311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.374286, 60.039384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.353557, 60.016638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 66",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.331788, 60.024072]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329375, 60.018569]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322444, 60.024112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Крытый всесезонный стадион",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.26458, 59.899824]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.608034, 59.740291]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 2-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.613862, 59.74475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 2-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.613694, 59.744718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.595821, 59.745112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339007, 60.005651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.355436, 60.007497]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.350641, 60.009795]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Торики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.110699, 59.782866]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363813, 60.018294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.828832, 59.873401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 2-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.61078, 59.743339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.617375, 59.738231]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 31",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.283981, 59.921894]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.640536, 59.782257]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.37209, 59.856884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.308927, 60.040531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.318539, 60.033855]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.317854, 60.033836]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.318138, 60.03384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390394, 59.867073]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390911, 59.867049]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.273225, 59.912537]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.383105, 59.860365]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.384393, 59.859898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.595468, 59.774857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.595965, 59.77294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Казанский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324071, 59.937235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.289133, 60.021622]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.58505, 59.731373]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.567135, 59.738672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.579577, 59.732349]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 1-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.570171, 59.746366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball;athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.485685, 59.924659]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Знаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.955124, 59.865906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Знаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.953553, 59.865766]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Знаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.95419, 59.865765]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Знаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.953003, 59.865831]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342607, 60.00011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.334104, 60.004688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.332236, 60.004403]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340332, 60.006121]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.264511, 60.011346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309449, 59.872963]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Ольгино, проспект Ленина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.141315, 60.000592]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Ольгино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.138455, 60.000824]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.502511, 59.839572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.41662, 59.698003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369813, 60.027622]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.389144, 59.855817]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.360908, 59.851684]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.272187, 60.009361]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.361845, 59.846536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363173, 60.013958]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.357182, 60.010225]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.161224, 59.842992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.161251, 59.84299]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.291302, 60.02631]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Земский переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.290326, 60.026196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.287586, 60.025902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.750811, 59.993945]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.751658, 59.99397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.753751, 59.994986]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.754754, 59.994976]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489179, 59.920286]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.852833, 59.870751]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.152557, 59.8362]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324523, 60.004278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.190287, 59.849062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.186803, 59.852265]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.188527, 59.853123]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.188277, 59.84868]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.180697, 59.847824]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.173374, 59.840121]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.169222, 59.841906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.207067, 59.837509]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.207464, 59.837937]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2056, 59.835528]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206365, 59.839174]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389911, 59.880261]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.481811, 59.882225]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463483, 59.87753]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.459607, 59.876495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482712, 59.882313]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327357, 59.850233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.327361, 59.850099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.237941, 59.986]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 102",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293418, 59.927238]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "спортплощадка школы №162",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.431102, 60.008551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Кооперативная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324996, 60.078875]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Торфяное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322521, 60.074354]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.433681, 60.007726]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400515, 59.735359]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.649117, 59.778892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410098, 59.740328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.414061, 59.736454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.852071, 59.870927]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.163687, 59.849472]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.1636, 59.849798]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.167391, 59.852691]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.163684, 59.8496]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полежаевский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Самодельная трасса с дёртами",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "cycling"
            },
            "geometry": {"type": "Point", "coordinates": [30.195793, 59.843534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.316242, 59.848066]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.215894, 59.856849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.318197, 59.847751]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.403208, 59.826558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.402245, 59.826309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371765, 59.705471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380823, 59.943224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.565232, 59.809594]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.222794, 59.944088]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.430623, 60.009031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.211371, 60.00955]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.427909, 60.008294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.478688, 59.951632]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.485845, 59.948256]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "equestrian"
            },
            "geometry": {"type": "Point", "coordinates": [30.110945, 59.994074]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Александровский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.386938, 59.715558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.485451, 59.94716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.081457, 59.746323]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.081177, 59.746008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225656, 59.850003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480729, 59.947278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421993, 59.923339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 12к2Н",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.311007, 59.970519]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.34605, 59.994035]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.346373, 59.99362]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480554, 59.943381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2375, 59.942764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236942, 59.940143]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237318, 59.940353]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.259987, 60.01292]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Новознаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.151804, 59.843564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.362525, 59.849052]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375203, 59.810146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471259, 59.948023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469967, 59.948716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Малиновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472756, 59.948244]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.259407, 59.981471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395301, 59.847874]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390523, 59.846535]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392225, 59.846768]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480381, 59.954449]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424384, 59.830485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.86866, 60.167183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.409112, 59.865068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.301835, 59.99207]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309742, 59.99062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386445, 59.840671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.365904, 59.837964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352069, 59.907789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Озёрная улица, 56",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305019, 60.037269]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304171, 60.036859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Никольская площадь",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "gymnastics;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.300979, 59.922964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-й Адмиралтейский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289068, 59.93126]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.329622, 60.014756]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.329356, 60.014722]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300524, 60.029642]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300435, 60.027164]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301244, 60.028473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.388126, 59.861416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.49306, 59.953591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.073613, 59.737894]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.079013, 59.731536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.079482, 59.731399]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.078519, 59.731637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.079006, 59.731515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Смолячково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.460638, 60.176737]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.252645, 60.003799]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Смолячково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.465284, 60.176398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Смолячково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.485327, 60.179435]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Смолячково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.50054, 60.186571]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Смолячково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.503367, 60.187024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Смолячково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.500997, 60.187001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.401009, 59.98332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.85639, 59.869675]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное, коттеджный посёлок Нирвана",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.509093, 60.182583]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.507576, 60.190629]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.518275, 60.187702]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное, мыс Лаутаранта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.525964, 60.185087]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное, мыс Лаутаранта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.526423, 60.185856]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.523316, 60.189806]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Серово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.553354, 60.197688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.286666, 60.009118]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.51108, 60.194559]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Молодёжное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.532388, 60.204056]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Серово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.55138, 60.204563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Канонерка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.943959, 60.095785]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304476, 60.066174]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Серово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.570515, 60.201604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Серово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.569586, 60.201169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.304293, 59.845838]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Ушково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.634877, 60.200461]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.639907, 60.198694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.345837, 60.007575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.582446, 59.808255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.338737, 60.018855]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.356489, 60.016689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.348269, 60.038432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.350431, 59.998914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.354414, 60.004033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197031, 59.828734]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.318808, 59.997108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.319335, 59.998506]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Мурзинка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.485158, 59.851632]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.320244, 59.848346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38547, 59.975469]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.305957, 59.99395]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.313083, 59.995828]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.312733, 59.995716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.305712, 59.993883]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.312445, 59.996002]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "sports_trainers"
            },
            "geometry": {"type": "Point", "coordinates": [30.495272, 59.94434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.496009, 59.942304]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271295, 59.885511]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Петровский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.291582, 59.95747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.24211, 60.011872]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.247556, 60.012032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.319511, 59.960846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.290946, 60.008152]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.36118, 59.867892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435524, 59.99646]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.433374, 59.995718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.259541, 59.835837]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.258507, 59.836422]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.251178, 59.840482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.180214, 59.826684]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.170254, 59.8301]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412547, 59.95484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28218, 59.924772]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347804, 60.019183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348867, 60.019186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347722, 60.014413]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388341, 59.833873]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.404231, 60.024379]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Алексеевский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283902, 59.927385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414502, 59.871497]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.033762, 59.852155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Свечной переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.352563, 59.923973]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Сан-Галли",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359587, 59.922392]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.761998, 59.995416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Посадская улица, 24",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.762796, 59.996497]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.371188, 60.002404]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.37567, 60.001059]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.513693, 59.832383]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.775346, 59.989228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.775491, 59.989415]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Яблоневый сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365827, 59.861171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.755946, 60.002185]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.755889, 60.002358]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.755419, 60.002245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [29.756179, 60.002352]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.431612, 59.971091]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.403899, 60.001538]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.403023, 60.001282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.37122, 59.860227]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Аммермана, 13к2",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.788533, 59.991541]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Кронштадтская улица, 9СП",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.771484, 59.999034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.762393, 59.998208]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457811, 59.680271]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457601, 59.680131]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459067, 59.680125]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.458078, 59.680049]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, улица Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457982, 59.680492]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Пролетарская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.789863, 59.991727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.236753, 59.952052]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.876051, 59.890372]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.787771, 59.987183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338939, 60.034812]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево, Колхозная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450841, 59.703412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.684492, 60.025062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.219848, 59.944907]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.758152, 59.997777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.760126, 59.995317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Велещинского, 15СП",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.759137, 59.994565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Фейгина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.759491, 59.99614]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Карла Либкнехта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [29.78246, 59.993082]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.304813, 59.91792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.395753, 59.870592]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309391, 59.918203]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.353235, 59.998706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423101, 60.025071]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422569, 60.024184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426736, 60.025498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399512, 60.006503]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397514, 60.007346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392247, 60.001854]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.299012, 60.013519]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.275267, 59.847412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Забайкальская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317453, 60.020835]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37975, 60.021728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.378504, 60.021362]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.374008, 60.024957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369075, 60.023368]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363955, 60.040689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3669, 60.039929]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.367519, 60.04074]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.367229, 60.040345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.375976, 59.873469]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.364133, 59.870278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.350871, 59.987226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "archery"
            },
            "geometry": {"type": "Point", "coordinates": [30.269066, 59.968847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Газовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.294845, 59.967046]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.267996, 59.968662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вязовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.274856, 59.968018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.355319, 59.832792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.213871, 60.026354]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 130Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.17295, 59.832181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.167624, 59.829097]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.354046, 59.836669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Левашово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196444, 60.102153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.160884, 59.859552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.161787, 59.859187]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215789, 59.851433]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297908, 60.017614]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 3-я линия 1-й половины",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297581, 60.016757]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277709, 60.020212]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268711, 59.96814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.31777, 59.863262]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.315757, 59.864198]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334418, 59.993866]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.269157, 59.866404]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.378561, 59.886895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39996, 60.020047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392288, 60.016213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 162Д",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.286246, 59.919199]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.16515, 60.120935]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.325124, 59.905258]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, улица Победы, 10",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.316105, 59.860205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375159, 59.983878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397841, 60.03218]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390855, 60.032902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380057, 60.038439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349085, 60.046003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335723, 60.04758]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396937, 60.034485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.215111, 60.004344]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, жилой комплекс Северная Долина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332285, 60.07194]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434147, 59.892807]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419787, 59.89221]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.415467, 59.894594]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349974, 60.035883]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341457, 60.054479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356578, 60.054344]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366575, 60.053276]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323372, 60.058709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 22к2Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.320561, 60.056962]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322546, 60.060919]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357616, 59.889279]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Каток с искуственным льдом",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.253481, 59.9803]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Роллердром",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "roller_skating;skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.25289, 59.980369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339035, 60.039076]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310049, 59.928188]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.313571, 59.910897]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.772806, 59.989989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Серпуховская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.324815, 59.913383]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.325077, 60.03641]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.321495, 60.045603]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.503153, 59.838734]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.513351, 59.836133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.169272, 59.860845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37748, 59.80531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.383474, 59.931674]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435731, 59.684631]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.167778, 59.858493]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.075776, 59.718898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.075321, 59.721225]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.213723, 59.998391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.49944, 59.934433]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.5003, 59.934243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.250304, 59.937728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.472005, 59.893023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.245086, 59.861753]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.461816, 59.903466]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.468171, 59.913376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.457759, 59.920517]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.457744, 59.920506]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468151, 59.913381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.470829, 59.906903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470818, 59.906893]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.481029, 59.90489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46181, 59.903476]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.481017, 59.904882]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.333353, 59.993248]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "karting"
            },
            "geometry": {"type": "Point", "coordinates": [30.332856, 59.992989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Аккуратова, 7В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.301162, 60.013747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.303543, 60.012228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.174034, 59.865401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.288262, 59.926062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Павловский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.445623, 59.689189]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.312399, 59.878987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.344021, 60.049709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer; basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.376365, 59.99749]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.368161, 60.002075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.372772, 59.998497]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479883, 59.94185]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369244, 60.004052]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.294854, 59.854321]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298796, 59.862871]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.298535, 59.861115]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301881, 59.856439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.394656, 59.825816]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407505, 59.824922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Пулковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.36209, 59.760409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Южная приграничная территория",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.440306, 59.678011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, переулок Мичурина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.428512, 59.681846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, переулок Мичурина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428524, 59.681849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Ленсоветовский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470367, 59.758329]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.351628, 59.986886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.575577, 59.751706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.281481, 59.859507]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269491, 59.89001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Детскосельский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468524, 59.72996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сквер Академика Лихачёва",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.357409, 59.999905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.303652, 59.863734]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.231688, 60.027558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "futsal"
            },
            "geometry": {"type": "Point", "coordinates": [30.349975, 59.849665]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.348404, 60.017243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.348628, 60.017204]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.34818, 60.017282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 56к2П",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.234749, 59.982332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.235067, 59.982306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.235171, 59.982619]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396651, 60.00414]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385863, 60.002904]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.771927, 59.897354]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391085, 60.008603]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410643, 60.007004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407285, 60.007242]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.242106, 59.95158]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Каховского",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.242322, 59.951989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350296, 60.009997]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.311549, 59.850602]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.311576, 59.850249]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339689, 60.005729]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.215685, 59.936867]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.238732, 59.94884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball;athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.477609, 59.91706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Тихий Отдых",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.282568, 59.980612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Тихий Отдых",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.28439, 59.980852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Тихий Отдых",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.28243, 59.98014]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "Shot put"
            },
            "geometry": {"type": "Point", "coordinates": [30.362586, 60.012224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торжковская улица, 30Ак1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.317031, 59.992948]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322342, 60.007848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.311228, 59.984489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.239586, 59.951167]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407454, 60.012657]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball;field_hockey;volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.41933, 60.010151]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ключевой проспект",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.145924, 60.000884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.449111, 59.921829]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449128, 59.921825]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.498955, 59.919284]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.498017, 59.923504]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.49799, 59.92349]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.493054, 59.922727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488197, 59.922968]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.480645, 59.927426]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.479722, 59.940541]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48459, 59.937709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.484601, 59.937703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479723, 59.940534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.48121, 59.937866]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.484991, 59.896471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484997, 59.896485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479682, 59.897691]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479345, 59.898989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479709, 59.923383]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.455529, 59.911862]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484077, 59.896311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420742, 60.016236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415439, 60.011278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483277, 59.919857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.49524, 59.915626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.47981, 59.901827]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.482981, 59.898572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.251697, 59.854953]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362676, 59.841651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.464199, 59.91467]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471035, 59.91676]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.459173, 59.882032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.45917, 59.882044]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.38359, 59.838445]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.392301, 60.016205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.232559, 59.957024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470336, 59.916825]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.477609, 59.917067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35723, 59.93467]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357136, 59.934264]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423106, 59.976912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.424323, 59.977064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.414309, 59.930321]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.418643, 59.973244]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Репинский сквер",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.862429, 60.167434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.009123, 60.014392]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.32527, 59.998093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.352356, 59.862499]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.385583, 60.033834]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.378823, 60.040866]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383, 60.042086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382048, 60.039078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374347, 60.038463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.372316, 60.037765]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос, территория Дубки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.006247, 60.008343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38756, 59.997638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389283, 60.001831]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.262016, 59.986376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362904, 60.00177]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос, Морской проспект",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.996497, 60.016461]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Торфяное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319698, 60.07591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.430519, 59.687925]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 26М",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.354389, 59.938097]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.403989, 60.024971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Семенцы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331108, 59.913705]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, Санаторная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.9814, 60.059495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Пригородный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238148, 60.073856]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260655, 59.853377]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.310615, 59.861499]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.608132, 59.733509]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251011, 60.111656]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254621, 59.8713]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.25787, 59.870526]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.348142, 59.732389]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.34855, 59.732001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348179, 59.732366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.316951, 59.903425]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317092, 59.901988]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210183, 59.82977]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.219631, 59.827905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217147, 59.832137]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "exercise"
            },
            "geometry": {"type": "Point", "coordinates": [30.372212, 60.003436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.407132, 60.002102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21Л",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.406468, 60.002572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.393948, 59.830679]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.223605, 59.832618]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218695, 59.835346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Козлова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22991, 59.828241]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.223407, 59.828753]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225721, 59.828153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.227914, 59.828148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.222965, 59.830246]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224572, 59.826693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.145113, 59.829004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.195757, 59.827011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194274, 59.830436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197602, 59.831413]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196388, 59.825903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.28623, 59.927022]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.279339, 59.843751]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281212, 59.927456]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Английский проспект",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.281775, 59.927169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244124, 59.907895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255979, 59.907389]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.481764, 59.895466]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, исторический район Колтовская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.283323, 59.963381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, исторический район Колтовская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.283669, 59.963545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.322831, 60.040972]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.383192, 59.705678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.384007, 59.705767]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.383124, 59.705338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.38384, 59.705965]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146883, 59.789378]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.384275, 59.701496]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384282, 59.701809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.410482, 60.039843]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.316797, 59.873509]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314949, 59.873782]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442068, 59.701756]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное, жилой комплекс Оллила",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.927213, 60.160054]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393839, 59.971457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.393431, 59.971588]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39381, 59.971588]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Федосеенко",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.393196, 59.971661]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.393012, 59.971171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405139, 59.985106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398638, 59.964633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402549, 59.97385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414189, 59.964478]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432295, 59.967905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443254, 59.953958]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.136985, 59.840006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.381875, 59.923688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.433165, 59.687071]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.354959, 59.919828]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Дворцовый",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.334404, 59.937106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.43368, 59.958367]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148787, 59.837778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.414074, 59.957203]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.270456, 59.842833]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, исторический район Матросская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.479075, 59.684491]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Жерновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.464981, 59.953622]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полежаевский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.185894, 59.835144]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полежаевский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.185883, 59.835141]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.224823, 59.844369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.126087, 59.837914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.132523, 59.836939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.132513, 59.836923]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.240207, 59.84828]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.377208, 59.929034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434807, 59.678254]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.266108, 59.847116]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.351849, 60.010961]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351951, 60.010923]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420644, 59.98636]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327071, 59.958269]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Певческий переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327371, 59.958454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326257, 59.958864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41403, 59.870622]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Теннисный стол и турники",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.382955, 59.804335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.226528, 60.022776]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430937, 59.879473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Семенцы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325735, 59.911903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Берёзовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42231, 59.685226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214434, 59.897928]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.249726, 59.861723]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.573424, 59.739616]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.281279, 59.956507]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Детскосельский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46361, 59.727794]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.562232, 59.813439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.562286, 59.813512]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.406795, 59.868089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.409399, 59.868533]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.409892, 59.874538]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Ушково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.615788, 60.201068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Двинский переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290034, 59.943073]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.298459, 59.859345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево, Новая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.447541, 59.706204]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48522, 59.963643]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386528, 60.021024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.393245, 59.873026]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320098, 59.900906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210874, 59.898034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424223, 59.956385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.201079, 60.006259]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.365302, 59.870471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316135, 59.850176]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, 19-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.731833, 60.006213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423327, 59.814398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.619003, 59.784372]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Понтонный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.62045, 59.78447]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, исторический район Колтовская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273164, 59.962814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269367, 59.883309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.403874, 59.826294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.386, 59.830245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.407954, 59.832566]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398363, 59.825475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435709, 60.013489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.263158, 59.843529]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254139, 59.848213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Турухтанные Острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25243, 59.873236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Круговая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.140234, 59.994891]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.140688, 59.995004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.139798, 59.995012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.404543, 59.838787]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Паровозный сквер",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.711863, 60.202947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368975, 60.003567]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.212327, 59.940739]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.2115, 59.942796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.204378, 59.999832]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.38794, 59.876297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.388218, 59.827125]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.412655, 59.891557]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 87к2Г1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.249259, 59.938583]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.312901, 59.969339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242983, 59.846122]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.44754, 59.874367]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки, жилой комплекс Новая Скандинавия",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290245, 60.060649]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381892, 59.980768]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, территория Красавица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.758097, 60.239102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, территория Красавица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.752989, 60.240814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "sports_trainers"
            },
            "geometry": {"type": "Point", "coordinates": [30.498424, 59.94321]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323401, 59.966034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.43631, 60.013647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.436402, 60.013345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254685, 60.022451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.500738, 59.944228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, исторический район Старожиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273448, 60.087275]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Вологодско-Ямская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.274658, 59.866451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 74",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.29434, 59.91917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Знаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.953309, 59.866112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Знаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.954162, 59.866182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.310706, 59.854808]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30916, 59.856059]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309257, 59.859899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407141, 59.830885]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40691, 59.830773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Ржевка, территория Ржевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.512683, 59.971967]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309606, 59.923969]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Ржевка, территория Ржевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.515453, 59.978223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Ржевка, территория Ржевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.50796, 59.976102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439094, 59.878369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424272, 60.031238]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.86266, 59.867814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.382633, 59.878082]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273759, 59.894711]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265598, 59.897315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Промышленная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268291, 59.898112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258035, 59.895119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.256604, 59.894848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274284, 59.89736]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полюстровский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420437, 59.964895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.702005, 60.194894]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.702436, 60.194778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425631, 59.952575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416669, 59.958542]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411938, 59.949223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.339781, 59.998383]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Орбели",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.339122, 59.998659]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.339277, 59.998191]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396176, 60.017989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395095, 60.021446]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388524, 60.015895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384186, 60.017421]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409873, 60.018128]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.409186, 60.017792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.402959, 60.015838]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404561, 60.013775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411061, 60.014789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404929, 60.014134]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403494, 60.016065]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.962211, 60.112009]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.970805, 60.090005]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.51791, 59.830406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Съезжинский переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290201, 59.955327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Прибрежная улица, 8к5",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.510191, 59.8339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.173355, 59.836974]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.471414, 59.955645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.472674, 59.9527]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335612, 60.051109]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.232541, 59.943943]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235919, 59.940703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432721, 59.969557]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430494, 59.97092]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473658, 59.92222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473997, 59.881532]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.491798, 59.839299]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football, hokey"
            },
            "geometry": {"type": "Point", "coordinates": [30.237679, 60.015739]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.243134, 60.001117]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 137",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.303124, 59.919456]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.505021, 59.835748]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.325087, 60.012307]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.132202, 59.844847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.258005, 59.845676]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.255696, 59.845406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358339, 60.0458]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.635025, 59.760532]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.357773, 60.047248]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24531, 59.935876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417144, 59.945438]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.494507, 59.891409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.491899, 59.88179]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.494106, 59.890954]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Народный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.494476, 59.89093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Волково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.36877, 59.899673]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 170В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.284098, 59.918042]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.232353, 59.855366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238304, 59.856323]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.225403, 59.857116]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Лабутина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.287799, 59.917228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240837, 59.858982]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.311538, 59.922745]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Сапёрный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.657627, 59.781852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259412, 59.866129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323977, 59.95818]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496542, 59.838222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Введенский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.293455, 59.952945]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.191095, 59.855715]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373527, 60.047133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402453, 60.001054]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294487, 59.940207]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293994, 59.940995]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.227143, 59.95959]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.226412, 59.959055]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.940687, 60.091145]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.941694, 60.091944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Введенский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.302229, 59.954609]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Введенский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.301744, 59.954651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Съезжинская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.303633, 59.953901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369913, 60.047996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267901, 59.944693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391595, 59.981717]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399554, 59.968066]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.400309, 59.967879]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.39897, 59.968805]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408835, 59.972877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400737, 59.96837]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409102, 59.972753]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409683, 59.972564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25094, 59.937084]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346948, 60.001703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Орбели",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338586, 59.997881]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.215828, 59.84152]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.45204, 59.870484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.266965, 59.877117]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.269482, 59.871549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Пулковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337942, 59.769048]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.198369, 59.831027]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.173221, 59.827752]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 12",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354529, 59.944302]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.355914, 59.889173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362188, 59.88588]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.431706, 59.879254]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432111, 59.879467]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444149, 59.874295]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262932, 59.870011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275698, 59.84709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274971, 59.847087]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Девятого Января",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278968, 59.891994]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тихомировская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277445, 59.890297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Девятого Января",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27863, 59.891604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271998, 59.892564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265451, 59.888536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280548, 59.897057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зверинская улица, 21-23",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.301742, 59.952157]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263183, 59.877567]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 2",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260524, 59.87628]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272507, 59.879692]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263682, 59.877484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260771, 59.876213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.163299, 59.827148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.163883, 59.826521]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275613, 59.888549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277371, 59.898898]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.878326, 60.166214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.877701, 60.166503]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.265783, 59.907544]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276845, 59.904585]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375705, 60.001124]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.332376, 59.839089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.325292, 59.846691]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.325986, 59.846836]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326492, 59.873733]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326481, 59.873911]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.323643, 59.873633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332516, 59.87843]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332492, 59.878641]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333034, 59.878359]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.222055, 59.858245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202185, 59.848892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234409, 59.981964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.213486, 59.99783]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.213605, 59.998059]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353607, 60.043808]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.194544, 59.98832]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.194242, 59.987967]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312545, 59.878596]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.425225, 59.89803]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.305845, 59.989023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212532, 59.898955]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.342588, 59.857347]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics;basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.211189, 59.989349]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.21022, 59.985464]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218523, 59.987886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209057, 59.985069]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.272986, 60.010277]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417066, 59.715463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417635, 59.715168]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.279506, 60.003441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.280182, 59.999914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.28367, 60.001113]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.287485, 60.009095]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.29227, 60.011451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410033, 60.029488]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298871, 60.029975]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300015, 60.029728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.30121, 60.028251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.299472, 60.025912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287729, 60.024532]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.293418, 60.024671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.297299, 60.022402]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.293992, 60.024781]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Главная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.297502, 60.022551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Мартыновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.293248, 60.029046]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.286695, 60.024127]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.288327, 60.024519]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.234693, 59.986939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.415906, 60.014849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427563, 60.011841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427792, 60.012043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417302, 60.017618]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41572, 60.014569]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.415838, 60.015808]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423291, 60.012997]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Усть-Ижора, Шлиссельбургское шоссе, 3К",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.568696, 59.82075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Пязелево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410549, 59.664631]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.265897, 60.012211]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, улица Веденеева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [29.863744, 59.876165]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257086, 59.903043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25673, 59.902516]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427368, 60.002364]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.407198, 60.006841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.425097, 60.003281]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414104, 60.006211]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.406795, 60.007023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.424949, 60.005626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41315, 60.006396]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41544, 60.005787]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.320012, 59.990217]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.312492, 59.988569]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.351366, 60.034324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330999, 60.033572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "equestrian"
            },
            "geometry": {"type": "Point", "coordinates": [30.262497, 59.901201]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Екатерингоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.261319, 59.903206]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334446, 60.035861]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339288, 60.040473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35594, 60.039842]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34647, 60.04193]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338233, 60.043791]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.344257, 60.043606]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336988, 60.04385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337675, 60.042607]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355807, 60.040178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340589, 60.044867]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356837, 60.039683]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Поэтический бульвар",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339949, 60.04508]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Северная дорога, 12",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.227161, 59.973682]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.301309, 59.992173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.305141, 59.987993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.305987, 59.990324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33262, 60.047209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332697, 60.0436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333562, 60.044486]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.421232, 59.8947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348491, 60.046829]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344769, 60.045084]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350067, 60.051356]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323376, 59.859589]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288216, 59.986061]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298408, 59.989569]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Большая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.420958, 59.958435]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Новая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260234, 59.985778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.288397, 60.006422]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.286167, 60.006174]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.28599, 60.00584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.410673, 60.034665]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.423924, 59.833454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.313165, 59.837039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.313769, 59.837018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320391, 59.928763]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264571, 60.106732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Шишкина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287624, 60.088526]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374729, 59.939645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.318953, 59.932766]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.319306, 59.933005]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.319133, 59.932856]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247138, 60.109924]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246741, 60.11023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Смольнинское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362335, 59.934902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269938, 60.020148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.266529, 60.020944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Пригородный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237926, 60.074704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.56736, 59.809255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.567681, 59.809475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.575158, 59.813646]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.499252, 59.938926]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боткинская улица, 21соор1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350731, 59.959713]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402957, 60.015549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414623, 60.018386]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.184512, 59.857727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344153, 59.972163]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.34281, 59.97567]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.284575, 60.024344]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391766, 60.015915]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Голицынский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.904921, 59.8797]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.363912, 59.840657]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Комиссара Смирнова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345003, 59.962148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.192046, 59.857078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.198778, 59.8477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.205566, 59.848652]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "bodybuilding"
            },
            "geometry": {"type": "Point", "coordinates": [30.205346, 59.848858]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.205544, 59.848262]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449962, 59.889619]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное, жилой комплекс Монплезир",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.244891, 59.852858]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Юбилейный Квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.239268, 60.030324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419468, 59.985602]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.291214, 60.008119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.23729, 59.952126]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Сенной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.309064, 59.923799]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.401711, 59.71884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Торики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.115595, 59.780008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Торики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.1156, 59.780022]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.380851, 59.998383]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.76341, 59.910584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.763936, 59.910506]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.343756, 59.919172]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242728, 60.024417]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 5",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.145659, 59.853146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.145735, 59.851435]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 13Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.30494, 59.924996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410662, 59.707749]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Перекопская улица, 6-8К",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.269348, 59.900868]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.984837, 60.007802]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251094, 59.854669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.029495, 59.850692]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.322044, 59.896412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246923, 60.02471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "beachvolleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.270791, 59.958901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Русановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.502818, 59.862038]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тележная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.3817, 59.923579]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.400509, 59.73535]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Авиаторов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.296934, 59.866649]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325241, 59.87504]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, исторический район 2-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Склон для сноуборда",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "mountin_ski"
            },
            "geometry": {"type": "Point", "coordinates": [30.274876, 60.077842]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248227, 59.853941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.200476, 59.850941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.217149, 59.855456]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.183265, 59.852814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.183119, 59.85264]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.179905, 59.851871]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.576381, 59.73908]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486081, 59.886565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326258, 60.045004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.292342, 59.962846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.292162, 59.962741]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "australian_football"
            },
            "geometry": {"type": "Point", "coordinates": [30.291985, 59.962906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.471434, 59.884598]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.472008, 59.884708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482895, 59.882156]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.480491, 59.882403]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район Фридентальская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.430686, 59.713615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.459094, 59.904105]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.240839, 60.010237]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251779, 60.01057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.251778, 60.010561]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 52Г",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282433, 59.946119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Науки, 79к3Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Спортплощадка на крыше паркинга с 1-этажной надземной частью",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432516, 60.002722]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469844, 59.925736]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.204989, 59.852282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.394958, 59.843921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.395492, 59.843911]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469303, 59.925204]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469583, 59.925479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.411097, 59.97175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353867, 59.859474]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пинегина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419162, 59.893235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.58146, 59.749093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.41224, 59.891444]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.413081, 59.891674]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.412803, 59.891747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Тулонская аллея, 11Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball;tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.799108, 59.985448]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.424421, 59.892972]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.423367, 59.892641]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Смольнинское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.360682, 59.944523]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.163624, 59.840932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.414348, 59.895394]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.415546, 59.894771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.3652, 59.837874]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365042, 59.838217]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365382, 59.837531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маркина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306707, 59.955899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.39846, 59.841766]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.398014, 59.841669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.863761, 59.890599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Зеленогорский парк культуры и отдыха",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.704259, 60.189141]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Зеленогорский парк культуры и отдыха",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.70338, 60.188813]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Зеленогорский парк культуры и отдыха",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.701208, 60.189001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Зеленогорский парк культуры и отдыха",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.700631, 60.188833]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Гаванная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.701719, 60.18904]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Зеленогорский парк культуры и отдыха",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.700566, 60.188693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38717, 60.0111]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.369192, 60.001816]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.383677, 60.04509]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.385349, 60.045371]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.324512, 60.04746]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.489839, 59.912599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.490398, 59.912617]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.490032, 59.912861]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.407276, 59.847215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.397992, 59.831737]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.211423, 59.858827]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна, Вокзальная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.031289, 59.847017]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.228162, 59.951279]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.128173, 59.84411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298904, 59.814079]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.262324, 59.840102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26194, 59.839913]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246695, 60.023658]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.266434, 59.840465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.266992, 59.840708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Кооперативная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.849206, 59.882101]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382729, 59.940847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383628, 59.944591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.307257, 60.010453]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.309363, 60.011393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.302952, 60.010599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371059, 59.930378]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.280858, 59.920505]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Лесное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258847, 59.666703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Лесное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.25799, 59.666846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409731, 59.981549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.4665, 59.928747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38392, 59.949888]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22184, 59.854509]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Морская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.160503, 59.990261]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "weight_training"
            },
            "geometry": {"type": "Point", "coordinates": [30.42002, 59.892043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211894, 59.897731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333778, 59.84697]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.413162, 60.0323]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218998, 59.903526]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244152, 59.908152]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гутуевский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247335, 59.906661]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.457262, 59.912411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.332789, 59.855769]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.241097, 60.006234]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.318332, 59.846934]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355717, 59.925567]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.210194, 59.829774]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.217146, 59.832146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.197053, 59.828726]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.1964, 59.825899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24923, 59.850349]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 21к4Д",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.250149, 59.849905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.262167, 59.849423]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.899998, 59.876356]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [29.894661, 59.875144]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.862325, 59.870605]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.375911, 59.976771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заячий остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gorodki"
            },
            "geometry": {"type": "Point", "coordinates": [30.31053, 59.949435]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ № 78",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.334087, 59.930498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238068, 59.940669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237078, 59.940654]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Новознаменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152828, 59.845504]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.332719, 59.853523]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.33303, 59.853531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.33285, 59.853758]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425032, 59.889786]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.339755, 59.842383]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Яблоновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.431334, 59.934667]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.270172, 59.958746]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Турухтанные Острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252502, 59.873018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Турухтанные Острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252357, 59.873462]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Турухтанные Острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252428, 59.873236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.403513, 60.041535]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.354345, 59.83668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.266637, 59.848807]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152717, 59.852501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Рублёвики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.415448, 59.967856]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 28соор1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.417441, 59.948039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Симбирский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.359215, 59.955674]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349057, 59.907729]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.317566, 59.850878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.317762, 59.850623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.378232, 59.841971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.380133, 59.838663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.380396, 59.838732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.380433, 59.838534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.325273, 60.051477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.326035, 60.050487]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.326702, 60.051575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.392976, 60.01604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.391608, 60.016374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.493719, 59.902672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.18078, 59.835559]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.279792, 59.997293]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.278985, 59.997036]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324815, 59.998689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.566706, 59.745283]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196735, 59.825494]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197102, 59.825432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.466381, 59.734487]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.184932, 60.118255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.413353, 60.035543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "streetball"
            },
            "geometry": {"type": "Point", "coordinates": [30.424494, 60.031309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Морская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.160555, 59.990395]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.44782, 59.867186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.282279, 59.952718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333714, 60.056096]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331205, 60.054685]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.426366, 60.033716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.860287, 59.888612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294598, 59.995067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бестужевская улица, 12",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.395708, 59.986155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Фермский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.394262, 59.723872]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.160306, 59.828625]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.170571, 59.825638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.494514, 59.921082]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Северный Версаль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.156527, 60.002928]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.251865, 59.840568]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.492598, 59.964481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.918205, 59.871163]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.248681, 59.846562]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.477968, 59.926478]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Даниила Гранина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.342108, 59.944148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.188761, 59.861775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка, жилой комплекс Калина-парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370654, 59.987583]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.263765, 59.869338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.27101, 59.881086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.373085, 60.049876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна, жилой комплекс Нойдорф-Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.009536, 59.840223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.005892, 59.837719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466355, 59.739444]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46411, 59.742078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Витебская Сортировочная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350117, 59.889243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.499495, 59.949019]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Суворовский Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.924594, 59.859852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Суворовский Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.931249, 59.858505]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.485783, 59.889728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305146, 60.065793]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236347, 59.970513]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235839, 59.970661]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236152, 59.970267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.207986, 59.849406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.318919, 60.053075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.319361, 60.053175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.318295, 60.053138]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.218787, 59.999216]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329692, 60.057553]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331848, 60.054554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325169, 60.061233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.324967, 60.061388]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Шувалово-Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325096, 60.060863]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378072, 60.042901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374881, 60.020468]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385905, 60.019724]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376482, 60.017966]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.354534, 60.055115]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.343107, 60.054709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356518, 60.055543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357415, 60.055345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное, жилой комплекс Вива",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.335251, 59.832071]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358447, 59.96099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244571, 60.115865]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.168149, 59.860684]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.313335, 60.060777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30531, 60.065618]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Спортплощадка школы №195",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.493544, 59.950173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359855, 60.05347]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35967, 60.048849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.3772, 60.050988]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.941335, 60.140677]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.50096, 59.934071]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.25381, 60.0233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.263954, 60.00968]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245014, 60.014586]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.256705, 60.022563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.251362, 60.009835]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25534, 60.022637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.261984, 60.110112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.260981, 60.109984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.266577, 60.108385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.266635, 60.108236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.26675, 60.10808]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266053, 60.108363]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266101, 60.108202]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266523, 60.1078]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Осиновая Роща",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266459, 60.107878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241705, 60.016491]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235875, 60.02061]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247033, 60.020224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234126, 60.022439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246934, 60.02031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.245538, 60.018534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237647, 60.022148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280924, 60.018219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.294231, 60.005668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.433815, 59.995591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421849, 60.023445]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429368, 60.010787]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428448, 59.989819]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434197, 59.999306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.431505, 60.001461]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.433403, 59.998978]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335005, 59.992973]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397565, 59.973937]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395387, 59.973235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Полюстрово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403212, 59.966979]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.49826, 59.937643]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.498182, 59.937819]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.426852, 60.037659]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426508, 60.035435]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 99В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42923, 60.032401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.21446, 59.857802]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28882, 59.811835]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288906, 59.813063]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292066, 59.814719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.264368, 60.021872]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.964472, 60.112174]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "parkour"
            },
            "geometry": {"type": "Point", "coordinates": [29.975382, 60.101247]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.386407, 59.99646]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кушелевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38769, 59.996409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331215, 59.986328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389802, 60.00172]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.267714, 59.936863]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.270149, 59.937669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.270455, 59.937427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.273954, 59.939629]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 21-я линия Васильевского острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265896, 59.933215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236095, 60.015427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236738, 60.010773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312515, 60.024851]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312612, 60.024634]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ярославский проспект",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320444, 60.01398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Джамбула, 13",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334727, 59.92469]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.213213, 59.998126]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.213141, 59.99789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.860966, 59.888662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.860772, 59.888403]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.180306, 59.827366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Тренажёры",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212436, 60.027137]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Каменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224983, 60.02553]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.294431, 60.005187]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 15Ж",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297695, 59.962468]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337379, 60.025845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.336635, 60.025579]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "24\/7",
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.487185, 59.841224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.268441, 59.869086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321633, 59.88781]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321269, 59.887829]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.481733, 59.929204]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, жилой комплекс Северная Долина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.338825, 60.081406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лигово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.180274, 59.827393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вяземский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.298932, 59.973345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 27",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306586, 59.960042]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383432, 60.04624]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38372, 60.046585]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадтская улица, 3к2",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252556, 59.874696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Торики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.110128, 59.782482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151329, 59.828208]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Вологодско-Ямская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.275226, 59.868789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202002, 59.825178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.240049, 59.951289]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Детскосельский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.461391, 59.727792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное, жилой комплекс Вива",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331797, 59.832106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262669, 59.836366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.405967, 59.873846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45467, 60.030706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.347152, 60.015899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.346713, 60.014505]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.346981, 60.014275]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.346692, 60.015835]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.348181, 60.016058]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.347736, 60.015987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.346805, 60.014073]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Литейный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357719, 59.933132]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.37757, 59.843619]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, исторический район Старая Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.250546, 59.983096]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.188957, 59.98836]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258413, 60.002391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.086679, 59.720302]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34294, 59.930758]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.363838, 59.833983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.364414, 59.834072]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.387683, 59.830533]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.146869, 60.123529]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный, исторический район Дибуны",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.141395, 60.124759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 149В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.436798, 59.884011]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.585489, 59.73651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.390905, 59.854838]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.39061, 59.854318]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.796655, 59.986947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.798435, 59.986057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.799511, 59.986507]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Флотская улица, 2",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.776733, 59.994182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.778571, 59.995133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.781394, 59.993074]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.332425, 59.862671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.332828, 59.862678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407333, 60.007782]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.182081, 59.862146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.348133, 59.914273]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.951112, 60.090136]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.947722, 60.111425]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Жерновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467326, 59.951992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.79962, 59.986237]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.798603, 59.987876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Казанская улица, 27",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314812, 59.930294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.197323, 59.860139]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.822993, 60.183738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.31585, 59.860595]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.315585, 59.860745]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.317214, 59.86694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.324655, 59.860665]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324666, 59.861018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.132215, 59.836482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.132543, 59.836484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.497703, 59.912157]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.497524, 59.91209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.497189, 59.912088]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [29.766685, 59.897311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "beachvolleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.764025, 59.900561]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261029, 59.889514]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.424163, 59.928193]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.040127, 59.718575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево, Садовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.450817, 59.69908]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.33865, 59.724352]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Александровская, Аграрная улица, 20",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.343377, 59.725098]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.423555, 59.923902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472416, 59.744964]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.294731, 59.810926]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.474914, 59.894688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Юннатов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316202, 60.027032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.185869, 59.862703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.220113, 59.988221]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.977557, 60.105661]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.329985, 59.872409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Средняя Рогатка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.316959, 59.84338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.325631, 59.856983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Новая Голландия",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": "Mo-Fr 11:00-21:00; Sa-Su 11:00-22:30",
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.290141, 59.930072]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.200278, 59.83518]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район Белозёрки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415808, 59.701196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.304246, 59.847149]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.302757, 59.974458]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.477648, 59.875211]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.477255, 59.875233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.389104, 59.980993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.469058, 59.925612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.563043, 59.744601]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.581203, 59.736918]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.496525, 59.914727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Разлив",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.963493, 60.082097]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Разлив",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.963477, 60.083112]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Разлив",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.962252, 60.083857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.186622, 59.862927]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Торики",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "bmx"
            },
            "geometry": {"type": "Point", "coordinates": [30.106187, 59.779865]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, территория Старо-Паново",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.173506, 59.816449]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362544, 59.847186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Суворовский Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.931043, 59.85812]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.897147, 59.882702]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.186526, 59.862724]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.31751, 60.033886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294772, 59.972182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296511, 59.97282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.452481, 59.870528]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.913018, 59.868488]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гранитная улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.416137, 59.917892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.305131, 60.057794]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.436291, 59.883684]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272411, 59.886695]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тихомировская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276644, 59.890536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.325466, 59.876816]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.3248, 59.87681]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.308459, 59.85606]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.307361, 59.856083]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Футбольная аллея, 1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.220508, 59.97299]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.47686, 59.739066]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436219, 59.68116]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422369, 59.681569]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.252788, 59.952052]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Аптекарская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.86838, 60.167481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Аптекарская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.868098, 60.167342]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.873948, 60.165817]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.873852, 60.16573]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 2-я Никитинская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.281722, 60.019214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Пулковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.365824, 59.759623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368702, 59.812204]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.387595, 59.852251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.387831, 59.851686]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.387329, 59.852173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.948656, 60.137985]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Солнечное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.94628, 60.139948]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Белоостров, исторический район Дюны",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.999107, 60.143921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Белоостров, исторический район Дюны",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.995364, 60.144696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.396806, 59.852716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.22049, 59.957344]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.220356, 59.957366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Финляндский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.399291, 59.965237]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496365, 59.951813]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389626, 59.855965]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390115, 59.856004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.390494, 59.85572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.389801, 59.856129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.388291, 59.856007]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.38954, 59.856274]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38996, 59.878529]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.391325, 59.880437]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жукова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.393799, 59.965131]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, Дубковское шоссе, 44литЗ",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [29.933444, 60.089256]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488369, 59.954106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Малиновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.472477, 59.945542]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.220716, 59.957311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.379687, 59.851476]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.286077, 60.001755]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Комендантский Аэродром",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.285952, 60.002117]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Сапёрный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.69192, 59.777617]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Сапёрный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.689452, 59.77835]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.262458, 59.889549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Волынкина Деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.262753, 59.889783]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.395199, 59.843786]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.420317, 59.924549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.231196, 59.840493]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.230614, 59.840274]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.23182, 59.840716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.225689, 59.840668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.398897, 59.841884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.409451, 59.849091]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.409969, 59.849008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.411844, 59.847143]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.411911, 59.847398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406636, 59.932793]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.427422, 59.95635]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424327, 59.844701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.231796, 59.954476]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.228361, 59.95542]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.226077, 59.952948]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.225151, 59.952731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.230989, 59.954236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.225394, 59.95971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.247744, 59.953718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.220388, 59.946981]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237099, 59.936079]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.237436, 59.932647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254451, 60.015707]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.27144, 59.85539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.408066, 59.855832]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.409637, 59.857263]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Удельная",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329105, 60.019188]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.226554, 59.959189]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22627, 59.953277]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.141781, 59.785784]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.089115, 59.744611]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Наличная улица, 44к3",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.229299, 59.952626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2307, 59.953282]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.230006, 59.952192]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.226431, 59.952712]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Наличная улица, 44к6К",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.226846, 59.952662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.226924, 59.953098]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.215663, 59.956721]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.217661, 59.956328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.218001, 59.95683]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.226292, 59.958283]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.226296, 59.958861]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.225983, 59.958345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226167, 59.958604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боцманская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.211715, 59.942564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212298, 59.940748]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.223013, 59.944438]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.222669, 59.944581]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.222575, 59.943743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.222795, 59.944105]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.219701, 59.944651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.220003, 59.945172]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.219883, 59.944916]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.23019, 59.953501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151965, 59.852813]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151875, 59.852619]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.140945, 59.850647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский, жилой комплекс Балтийская Жемчужина, жилой комплекс Море",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.137203, 59.850657]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "volleyball;basketball;table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.212902, 59.95247]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.09331, 59.730846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.092493, 59.738556]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Новая Ижора",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.566057, 59.72228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Новая Ижора",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.565382, 59.723341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Новая Ижора",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.564845, 59.723542]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.236227, 59.951983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный, Военный городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.171364, 60.131079]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское, жилой комплекс Квартет",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey;football"
            },
            "geometry": {"type": "Point", "coordinates": [30.369884, 59.830886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.452508, 59.86864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.390527, 59.830218]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.408588, 59.864799]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410008, 59.865484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404353, 59.865852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366415, 59.856403]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.370801, 59.857668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.397179, 59.865249]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.377239, 59.862014]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.385373, 59.864186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.583914, 59.731243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Екатерининский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435261, 59.979006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Екатерининский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43504, 59.978231]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Александровское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.466927, 59.863066]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Венгерка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.474014, 59.861831]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Венгерка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.474038, 59.861571]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.388137, 59.852209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.392498, 59.847638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413431, 59.841592]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Спартак",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.483732, 59.849511]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363179, 59.835501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37731, 60.013906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374707, 60.012789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Русановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.500736, 59.862915]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387709, 59.832775]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.380552, 59.83842]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.413995, 59.906268]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.205181, 60.010038]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шуваловский проспект",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.19885, 60.007841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308846, 59.972671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.203585, 60.009315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.440164, 59.864108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.477458, 59.918678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.946714, 60.125035]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.411911, 60.039543]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.400235, 60.036378]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.408275, 60.032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.86948, 59.889392]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.869, 59.889472]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.868671, 59.889167]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.861443, 59.884032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.346812, 60.007832]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.171728, 59.860849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.172208, 59.860731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.267767, 59.875915]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26672, 59.873615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.28583, 59.914903]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.323074, 59.893268]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.322898, 59.893417]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322656, 59.893357]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372346, 60.023771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.183172, 59.859741]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.196688, 59.855541]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.367559, 59.864623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.242396, 59.967471]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371636, 59.871732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis;athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.369109, 59.872465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics;table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.382937, 59.877789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.381915, 59.872922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Екатерингофский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.268895, 59.908458]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391429, 59.870319]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Красные Зори",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.947338, 59.856726]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387372, 59.868495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.132861, 59.841183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.33192, 59.955458]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.457487, 59.920269]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.458, 59.920778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Посадский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.319758, 59.960891]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317239, 59.866623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дегтярный переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381755, 59.940217]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.388693, 59.876886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сампсониевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346839, 59.988415]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.418151, 59.926411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.342569, 59.860403]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.342504, 59.860716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.343107, 59.8609]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.343462, 59.860885]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.343252, 59.860283]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.343481, 59.860589]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.384808, 60.051143]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.211839, 59.940627]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.212825, 59.940849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Купчино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.359498, 59.871653]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 11\/7с1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "field_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.29008, 59.967043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 11\/7с1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "field_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.290072, 59.968169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 11\/7с1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "field_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.290092, 59.967608]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.361712, 59.883852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.361257, 59.884454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.361479, 59.884157]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.35742, 59.889542]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.357611, 59.889278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, исторический район Волково Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.357795, 59.889026]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Александровская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316808, 59.852901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365543, 59.928684]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365697, 59.928814]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.260264, 59.952332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.733214, 60.006591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.733018, 60.00643]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422326, 59.834761]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356393, 59.857272]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.388947, 59.871545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.389893, 59.871665]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388793, 59.871301]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39154, 59.86928]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.391738, 59.869108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391931, 59.868953]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.392308, 59.869468]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 72",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404689, 59.871428]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.458301, 59.925057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 37к4Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.229172, 59.954137]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.412554, 59.827992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.412124, 59.828249]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.412301, 59.82844]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi;tennis;basketball;volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.47799, 59.928728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино, жилой комплекс Юнтолово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.148019, 60.030318]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 60к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.222809, 60.035042]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22581, 60.038177]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.342334, 59.84188]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Канцевская сторона",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418049, 59.941657]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, улица Спирина, 1к3",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.082108, 59.745954]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276629, 59.889982]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.37529, 59.851364]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.375071, 59.851615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778051, 59.901827]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.777368, 59.902268]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335489, 59.87829]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.853192, 59.870662]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.838365, 59.882833]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.158893, 59.831765]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.158273, 59.833147]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.159882, 59.82916]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.161004, 59.82718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.351087, 59.8502]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.372143, 59.872995]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.371516, 59.872852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.372746, 59.87313]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gorodki"
            },
            "geometry": {"type": "Point", "coordinates": [30.34915, 59.849579]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gorodki"
            },
            "geometry": {"type": "Point", "coordinates": [30.34939, 59.849603]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344244, 60.043597]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.348531, 60.034759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.135515, 59.840599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.143006, 59.836459]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Старый Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.843068, 59.872039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.379491, 59.877474]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315187, 59.982152]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 3к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315493, 59.982082]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Сергиевка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.846506, 59.886401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.573265, 59.73501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Посёлок Михаила Архангела",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "football;baseball;volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.427662, 59.887306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.455493, 60.027598]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.911977, 59.880048]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.912043, 59.88008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Канонерский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "equestrian"
            },
            "geometry": {"type": "Point", "coordinates": [30.213982, 59.899384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 12В",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.310223, 60.013216]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.918674, 59.871092]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.917774, 59.871231]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.907815, 59.87184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.907891, 59.872169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [29.908292, 59.871645]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.908583, 59.87165]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.908623, 59.87192]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.908664, 59.872191]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.916923, 59.872141]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пионерский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415719, 59.983573]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 102к4",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "futsal"
            },
            "geometry": {"type": "Point", "coordinates": [30.365371, 59.831125]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.369963, 59.834457]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.369669, 59.834421]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Суворовская улица, 1АК",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.863526, 59.885612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.369851, 59.834395]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.359012, 59.850868]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское, жилой комплекс Квартет",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.367022, 59.829221]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское, жилой комплекс Квартет",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "futsal"
            },
            "geometry": {"type": "Point", "coordinates": [30.367085, 59.829087]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, 19-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.72323, 60.008032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, 19-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.723557, 60.008137]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.208319, 59.989693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey;multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.213202, 59.986262]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey;multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.210346, 59.987045]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480279, 59.742875]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.361394, 59.851743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.396175, 59.836269]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.781308, 59.905129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.357666, 59.843562]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коммуны, 39Ж",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "field_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.496918, 59.955735]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "field_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.49578, 59.956388]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 18-я линия Васильевского острова, 37",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264531, 59.939922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463823, 59.882428]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.326785, 59.846848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Гагаринское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.361178, 59.846363]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 30А",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34368, 59.917953]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.325062, 59.845008]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 88Б",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323791, 59.908738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Смоленское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.435639, 59.894939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Татарский переулок, 3-5",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.305082, 59.953596]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265144, 59.936048]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, загородный клуб Золотой Ручей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.970601, 60.122549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Овражный парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.777812, 59.989607]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Новоизмайловское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.301475, 59.873094]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382956, 60.034782]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382596, 60.03459]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396381, 60.048254]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.397383, 60.047993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.397828, 60.047895]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 121к3",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.408617, 60.046381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.382989, 60.034505]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.418702, 60.041686]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418016, 60.041846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419416, 60.04157]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.395674, 60.042965]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.396789, 60.042739]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.418969, 60.029103]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.416363, 60.039091]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.416857, 60.038991]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.410665, 60.04434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.411291, 60.044194]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.410074, 60.044459]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.383017, 60.047075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ № 21",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.416268, 60.026315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.271709, 59.852585]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.633946, 59.73801]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.196428, 59.860743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.19729, 59.860546]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.197022, 59.860608]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.196698, 59.860682]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Северный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.378615, 60.03648]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.19723, 59.860038]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.197137, 59.859935]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Южно-Приморский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.197416, 59.86024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.335649, 60.004826]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.413926, 60.043601]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.414309, 60.043441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.40773, 60.002576]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Гражданка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.407502, 60.002396]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.222406, 60.041169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225507, 60.038458]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.941867, 60.091335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Новоорловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.272961, 60.056372]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392883, 60.035997]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 17",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283975, 59.942529]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 62",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339007, 59.928537]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.459613, 59.87649]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.462314, 59.883199]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Малая Рыбацкая",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.460774, 59.881539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Ивановский, территория Щемиловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.447555, 59.874367]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305588, 60.037304]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.312389, 59.87921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.312409, 59.87874]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.461918, 59.887892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.461368, 59.887851]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.940988, 60.092454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.940659, 60.091736]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.090406, 59.736214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.079491, 59.738414]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.867725, 60.174162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.40186, 59.91226]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.226829, 60.041577]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Арцеуловская аллея, 23к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.226988, 60.041696]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное, жилой комплекс 4Ю",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "baseball"
            },
            "geometry": {"type": "Point", "coordinates": [30.337923, 59.82434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, территория Михайловка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239439, 60.081708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.223023, 59.969678]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221279, 60.038013]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217965, 60.041374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Воронцовский сквер",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": "Нарвская застава",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.23766, 59.844963]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.577937, 59.747421]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.421274, 59.856869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Авиагородок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.295778, 59.810738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградский район, муниципальный округ Аптекарский Остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.326105, 59.96798]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242633, 59.967259]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251151, 60.035412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251544, 60.035364]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254929, 60.036572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254039, 60.036944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252896, 60.039935]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253136, 60.040014]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252198, 60.038575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252307, 60.038439]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252189, 60.038332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251563, 60.037613]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251342, 60.037693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25131, 60.037212]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Каменка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23788, 60.036732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249898, 60.0361]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249966, 60.036223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tenis;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.39125, 60.013571]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Академическое",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.393374, 60.012362]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, исторический район Разлив",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.963626, 60.079501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Весенняя улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball;gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.405832, 59.935973]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Сосновая Поляна, жилой комплекс Солнечный Город",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.107057, 59.840972]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.341941, 60.032309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сосновское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.341113, 60.032316]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Граффити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.251249, 60.029116]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Парфёновская улица, 9к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30997, 59.901178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.159609, 59.84341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.149441, 59.834673]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.157876, 59.829917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Сосновая Поляна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.157946, 59.830283]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Ивановка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.158726, 59.836315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс На Королёва",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234874, 60.035552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.381566, 59.987396]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.382095, 59.987737]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.491858, 59.941151]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.500667, 59.943996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.500806, 59.944462]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.500156, 59.944161]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.238545, 59.949059]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.785755, 60.184727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Большая Охта, жилой комплекс Зималето",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball;running"
            },
            "geometry": {"type": "Point", "coordinates": [30.438073, 59.938402]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 1-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.569386, 59.74668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393338, 59.9839]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.425514, 59.928708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425831, 59.924922]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.414321, 59.930329]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.313452, 60.061501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427284, 59.954237]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413705, 59.931188]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, территория Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468116, 59.872133]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.201173, 60.006035]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "towns"
            },
            "geometry": {"type": "Point", "coordinates": [30.606094, 59.740473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.594216, 59.733123]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.594518, 59.733122]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.594228, 59.733385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.59482, 59.733309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.59349, 59.73325]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.465046, 59.740634]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466679, 59.743663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468588, 59.742764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469614, 59.741108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468977, 59.73816]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473132, 59.736869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.47045, 59.745523]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка, Ростовская улица, 26к1",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477732, 59.746121]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480602, 59.742529]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480995, 59.742757]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.481015, 59.741392]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44925, 59.890053]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Волково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365963, 59.900306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.393401, 59.902558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Парнас",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.339521, 60.066338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.400121, 59.854184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.35503, 60.003698]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.353141, 60.009243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Воронцов переулок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.352744, 60.009514]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Молодёжный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468727, 59.866388]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270128, 59.934293]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Йога",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233963, 60.031409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Автово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.282857, 59.867337]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский, жилой комплекс Аврора",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450834, 59.913689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.398302, 59.831593]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Балканский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.397964, 59.831508]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408675, 59.717448]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.414082, 59.717536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, жилой комплекс Северная Долина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.340269, 60.077492]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торговый переулок, 2А",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.332807, 59.929429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.659794, 60.194626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.660567, 60.195094]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.413484, 59.858104]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.407629, 59.857239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.418804, 59.850658]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.358346, 59.995314]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Светлановское, территория Лесной",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358065, 59.995484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426856, 59.686801]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430472, 59.68448]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.390477, 59.840713]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Мартыновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285538, 60.029573]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42926, 59.678127]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434933, 59.67778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432319, 59.677333]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, исторический район Мясокомбинат",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.361701, 59.827053]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.377831, 59.842219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.378063, 59.84228]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.385376, 59.845646]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.38442, 59.845399]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.390004, 59.840591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, улица Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457384, 59.680298]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.457732, 59.67968]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459021, 59.680531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, улица Декабристов",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459078, 59.681443]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459908, 59.680315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.45852, 59.680311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Зверинец",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.455517, 59.677589]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44968, 60.034287]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, улица Ломоносова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414919, 59.693132]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.432704, 59.687187]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440394, 59.683755]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262668, 59.840309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252356, 59.83287]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.261813, 59.846208]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Ульянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.223275, 59.831119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Невский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.474429, 59.905872]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Новосёлки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403043, 59.700847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, территория Новосёлки",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405743, 59.700755]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Динамо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401125, 59.654234]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, территория Пязелево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412816, 59.665104]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Петро-Славянка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.511909, 59.803028]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.235403, 59.844063]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239176, 59.843828]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422707, 59.728778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239934, 59.849065]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.239646, 59.849238]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.230799, 59.84818]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.235637, 59.853773]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.23548, 59.855616]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Красненькая Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.231522, 59.852887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Юго-Запад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.207569, 59.849482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Грин Сити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.456571, 59.861255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Грин Сити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.45687, 59.861417]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Грин Сити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.457084, 59.861617]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Грин Сити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.457299, 59.861823]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Грин Сити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.45724, 59.861487]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Обуховский, жилой комплекс Грин Сити",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "long_jump"
            },
            "geometry": {"type": "Point", "coordinates": [30.456095, 59.861324]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449918, 59.863477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Белевское Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.444361, 59.8625]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Московская Застава",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317724, 59.880453]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness_station"
            },
            "geometry": {"type": "Point", "coordinates": [30.428365, 59.830468]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225343, 59.993672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Северо-Приморская часть",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225234, 59.993473]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.218137, 60.037638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Чистое Небо",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217868, 60.038137]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.45739, 59.868084]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.449806, 59.866327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Моховая улица, 14",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.3462, 59.944575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.499076, 59.933999]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.234411, 59.938811]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Опочинина улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.235587, 59.936879]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239135, 59.936258]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 25-я линия Васильевского острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252636, 59.938906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.584227, 59.743346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.584191, 59.7432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.58524, 59.743084]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Горелово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.14064, 59.769552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.60987, 59.733369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.594822, 59.733451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Колпинский парк",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.594557, 59.733386]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Никольская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.606585, 59.743917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Никольская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.60749, 59.743981]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Никольская Слобода",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.607046, 59.743947]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 2-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.614719, 59.744706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 2-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.614883, 59.744247]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.026956, 59.85454]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.608207, 59.738118]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.608363, 59.738168]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.608309, 59.738401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.614898, 59.738198]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.615797, 59.738421]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.615326, 59.738335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.61678, 59.738391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 16-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.617167, 59.738532]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.589688, 59.751623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288309, 60.080481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.460988, 59.92053]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.460877, 59.920264]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.460814, 59.920406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.46122, 59.920406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.571884, 59.731627]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.566436, 59.734497]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, 1-й квартал",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.564363, 59.74676]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.477287, 59.916822]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.417698, 59.926268]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Зыбуля",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420598, 59.924333]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "long_jump"
            },
            "geometry": {"type": "Point", "coordinates": [30.584923, 59.736522]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.585401, 59.736479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.585917, 59.736436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.491762, 59.93854]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.486151, 59.944107]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475394, 59.950012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Жерновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.463047, 59.951955]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Жерновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.465404, 59.950766]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.490185, 59.950651]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.490336, 59.950506]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.431818, 59.95441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.431118, 59.95423]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.441514, 59.956805]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42436, 59.976653]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Леснозаводская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44772, 59.865822]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.390363, 59.88041]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.389474, 59.880101]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.389919, 59.880256]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Васильевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.259003, 59.9463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470726, 59.928864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ № 54",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.493392, 59.903477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.799508, 59.986772]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Прометей",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393965, 60.0493]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey;basketball;volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [29.897754, 59.875033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пески",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380869, 59.926579]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.614345, 59.732216]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.613516, 59.732235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, исторический район Нижне-Ижорская Колония",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.613812, 59.732024]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.895435, 59.875369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.895219, 59.874539]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.30144, 59.991949]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Чёрная Речка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.302221, 59.992193]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.79812, 60.202545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.458004, 60.028447]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.457683, 60.028673]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, аллея Евгения Шварца",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.458334, 60.035744]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Коломяги",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281, 60.018374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball;volleyball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.458468, 60.034284]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.45899, 60.032663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Оккервиль",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.501777, 59.923169]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Полюстрово, жилой комплекс Новая Охта",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.455321, 60.029621]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.422776, 59.724389]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.423263, 59.723892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Лахта-Ольгино",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "equestrian"
            },
            "geometry": {"type": "Point", "coordinates": [30.112044, 59.993859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, коттеджный посёлок Консульская деревня",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.849689, 60.163393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Калининский район, муниципальный округ Пискарёвка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.424167, 59.991248]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.35478, 59.840939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "long_jump"
            },
            "geometry": {"type": "Point", "coordinates": [30.354832, 59.840706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.355168, 59.840817]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Звёздное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.355071, 59.841051]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк имени Бабушкина",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "paintball"
            },
            "geometry": {"type": "Point", "coordinates": [30.434728, 59.883704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.15126, 59.787906]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, исторический район Сосновка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "badminton"
            },
            "geometry": {"type": "Point", "coordinates": [30.150616, 59.789118]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Горелово, жилой комплекс Дудергофская Линия 3",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.153786, 59.794245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, территория Решетниково",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.696656, 60.237544]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Новая Голландия",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290745, 59.930409]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Тентелева",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283774, 59.898032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево, Садовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.451056, 59.699054]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Тярлево, Садовая улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.450528, 59.699108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.491274, 59.936856]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.492991, 59.937575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, муниципальный округ Пороховые",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "table_tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.491234, 59.937214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.407708, 59.857147]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.407466, 59.857371]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ № 75, жилой комплекс София",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.405339, 59.855278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ Морской, жилой комплекс Тихая Гавань",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball; basketball; soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.197568, 59.939086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401335, 59.912348]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402149, 59.912005]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "long_jump"
            },
            "geometry": {"type": "Point", "coordinates": [30.401501, 59.912165]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.401669, 59.912436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.402035, 59.912101]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, исторический район Стеклянный Городок",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "beachvolleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.393993, 59.902436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.338671, 59.923627]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 7\/11А",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.340285, 59.92317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Лиговка-Ямская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355341, 59.919694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, муниципальный округ Правобережный",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer;athletics;gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.437122, 59.916184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.470186, 59.855391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.468379, 59.855604]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.468222, 59.855451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.468298, 59.855528]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.468621, 59.85537]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.471602, 59.857255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Троицкое Поле",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.472889, 59.857265]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Обухово",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.440349, 59.841405]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 137",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball; volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.477192, 59.859278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.342734, 59.924786]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 24",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.342507, 59.924887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Покровский остров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.285814, 59.919093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "running"
            },
            "geometry": {"type": "Point", "coordinates": [30.340547, 59.922921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, муниципальный округ Измайловское, жилой комплекс Галактика",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308081, 59.899467]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.741178, 59.996181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.74152, 59.996099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Северная дорога, 12",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.227218, 59.974]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 21-я линия Васильевского острова",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.263673, 59.935184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зверинская улица, 3",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.293194, 59.952995]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, старая часть города Кронштадт",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.753931, 59.995391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Красного Курсанта, 18",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28217, 59.957564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264357, 59.936779]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, жилой комплекс Цивилизация",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.447929, 59.900969]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский район, жилой комплекс Цивилизация",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.447223, 59.90148]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256362, 60.041193]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.258094, 60.040629]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258048, 60.040546]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Коломяги, жилой комплекс Шуваловский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.256888, 60.0394]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Сосновая Поляна, жилой комплекс Солнечный Город",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.118003, 59.839626]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Сосновая Поляна, жилой комплекс Солнечный Город",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.117721, 59.840278]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красносельский район, муниципальный округ Сосновая Поляна, жилой комплекс Солнечный Город",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.117604, 59.840039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Василеостровский район, муниципальный округ № 7",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282393, 59.943682]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, 1-я Краснофлотская улица",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.465833, 59.683298]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малый Ильинский сад",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471886, 59.963524]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Центральный район, муниципальный округ Владимирский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.344613, 59.917188]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.344915, 59.909243]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, муниципальный округ Дачное",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.247956, 59.834957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.276998, 59.853393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кировский район, исторический район Княжево",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.278951, 59.852827]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, мост Бетанкура",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.266395, 59.958182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, мост Бетанкура",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "street",
                "city_function": "Спорт",
                "sport_type": "skateboard"
            },
            "geometry": {"type": "Point", "coordinates": [30.265907, 59.958047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Белоостров",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.007492, 60.145423]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Полис на Комендантском",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215494, 60.035215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский район, муниципальный округ Юнтолово, жилой комплекс Полис на Комендантском",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216145, 60.034959]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.023089, 59.85246]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "basketball"
            },
            "geometry": {"type": "Point", "coordinates": [30.021921, 59.852642]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.022521, 59.852554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Георгиевский",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.388718, 59.856533]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский район, исторический район Исаковка",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.431386, 59.954406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, территория Александровская",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.978667, 60.055671]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ № 15",
                "name": null,
                "type": "Спортивная площадка",
                "access": null,
                "opening_hours": null,
                "description": null,
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.36089, 60.04992]}
        }
    ]
}
