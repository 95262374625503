export const art_spaces = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейская площадь, 3Е",
                "city_function": "Культура, развлечения, творчество",
                "name": "ARTPLAY SPb",
                "type": "Арт-пространство",
                "opening_hours": "10:00-21:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.409661, 59.941596]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "city_function": "Культура, развлечения, творчество",
                "name": "BERTHOLD CENTRE",
                "type": "Арт-пространство",
                "opening_hours": "10:00-23:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гусарская улица, 4Щ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Gora Centre & Gallery",
                "type": "Арт-пространство",
                "opening_hours": "11:00-20:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.387554, 59.705486]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 13-я линия Васильевского острова, 70",
                "city_function": "Культура, развлечения, творчество",
                "name": "АРТМУЗА",
                "type": "Арт-пространство",
                "opening_hours": "11:00-22:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.264745, 59.945878]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 17Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Бенуа 1890",
                "type": "Арт-пространство",
                "opening_hours": "19:00-18:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.371231, 60.019738]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кадетская линия, 5, корп. 2Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Вавилов Лофт",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290986, 59.943046]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Громова, дом 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "ДК Громов",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411516, 59.921801]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Царицынская улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каскад",
                "type": "Арт-пространство",
                "opening_hours": "09:00-22:00"
            },
            "geometry": {"type": "Point", "coordinates": [29.916163, 59.876219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Биржевая линия, 12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Культурный центр Сердце",
                "type": "Арт-пространство",
                "opening_hours": "19:00-23:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.296015, 59.943643]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "city_function": "Культура, развлечения, творчество",
                "name": "Лофт-проект Этажи",
                "type": "Арт-пространство",
                "opening_hours": "9:00-23:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Набережная Обводного канала, 74",
                "city_function": "Культура, развлечения, творчество",
                "name": "Люмьер-Холл",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329027, 59.910407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, остров Новая Голландия",
                "city_function": "Культура, развлечения, творчество",
                "name": "Новая Голландия",
                "type": "Арт-пространство",
                "opening_hours": "пн-чт: 10:30-22:00, пт-вс: 10:30-23:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.289105, 59.929913]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Брантовская дорога, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Охта LAB",
                "type": "Арт-пространство",
                "opening_hours": "10:00-22:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.419384, 59.940641]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 173",
                "city_function": "Культура, развлечения, творчество",
                "name": "Патефон",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383221, 59.92407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Третий Кластер",
                "type": "Арт-пространство",
                "opening_hours": "Круглосуточно"
            },
            "geometry": {"type": "Point", "coordinates": [30.370414, 59.938018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Пушкинская-10",
                "type": "Арт-пространство",
                "opening_hours": "ср-пт с 16.00 до 20.00, сб-вс с 12:00 до 20:00"
            },
            "geometry": {"type": "Point", "coordinates": [30.357559, 59.929515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40",
                "city_function": "Культура, развлечения, творчество",
                "name": "Севкабель порт",
                "type": "Арт-пространство",
                "opening_hours": "Круглосуточно"
            },
            "geometry": {"type": "Point", "coordinates": [30.242036, 59.924397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 24\/27Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Флигель",
                "type": "Арт-пространство",
                "opening_hours": "Круглосуточно"
            },
            "geometry": {"type": "Point", "coordinates": [30.358799, 59.938094]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Проспект Кима, д. 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Эко-лофт More Place",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245913, 59.950864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Проспект Стачек, д. 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом Культуры",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262532, 59.878481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мультиплейс 17\/26",
                "type": "Арт-пространство",
                "opening_hours": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358871, 59.944627]}
        }
    ]
}
