//https://t.me/elemnt3 Y:2021
import React from "react";
import {Col, Row} from 'antd';
// import EntitiesPieA from "./EntitiesPieA";
import EntitiesPieB from "./EntitiesPieB";
import EntitiesPieC from "./EntitiesPieC";
import EntitiesPieD from "./EntitiesPieD";
import EntitiesPieE from "./EntitiesPieE";


function EntitiesBase() {
    return (
        <>
            <div style={{ marginBottom: '30px', fontSize: '16px', textAlign: 'center' }}>
                Рейтинги показывают 10 лучших муниципальных образований, по количеству различных сервисов для
                детей и подростков четырех возрастных групп.
            </div>
            <Row gutter={[48, 1]} style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
                <Col xs={{span:12}} sm={{span: 12}} md={{span: 12}}>
                    <EntitiesPieB/>
                </Col>
                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}}>
                    <EntitiesPieC/>
                </Col>
            </Row>

            <Row gutter={[48, 24]} style={{ display: 'flex', justifyContent: 'center' }}>
                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}}>
                    <EntitiesPieD/>
                </Col>
                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}}>
                    <EntitiesPieE/>
                </Col>
            </Row>
        </>
    )
}

export default EntitiesBase;
