export const industrial_enterprises_3 = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Горная, д.12а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Горная, д.12а"
            },
            "geometry": {"type": "Point", "coordinates": [30.413021, 59.666605]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Елизаветинская, д.21 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Елизаветинская, д.21"
            },
            "geometry": {"type": "Point", "coordinates": [30.43038, 59.67671]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Павловск, ул. Обороны, д.6а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Павловск, ул. Обороны, д.6а"
            },
            "geometry": {"type": "Point", "coordinates": [30.45575, 59.677412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Звериницкая, д.23а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Звериницкая, д.23а"
            },
            "geometry": {"type": "Point", "coordinates": [30.46315, 59.682195]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Павловская ветеринарная лечебница",
                "organisation": "Ветеринарная станция Колпинского и Пушкинского районов Санкт-Петербурга",
                "type": "Ветеринарная станция",
                "category": 3,
                "address": "пер. Мичурина, 9, Санкт-Петербург, 196620"
            },
            "geometry": {"type": "Point", "coordinates": [30.428062, 59.682514]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Васенко, д.32 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Васенко, д.32"
            },
            "geometry": {"type": "Point", "coordinates": [30.434519, 59.682564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом ветеранов войны и труда №1",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Дом ветеранов войны и труда №1\"",
                "type": "учреждение социального обслуживания",
                "category": 3,
                "address": "Павловск, Пушкинский район, Санкт-Петербург, Россия, 196621, Садовая улица, 49В"
            },
            "geometry": {"type": "Point", "coordinates": [30.476012, 59.683072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Желябова, д.6\/1 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Желябова, д.6\/1"
            },
            "geometry": {"type": "Point", "coordinates": [30.460987, 59.684405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Садовая, д.17а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Садовая, д.17а"
            },
            "geometry": {"type": "Point", "coordinates": [30.445591, 59.685464]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Березовая, д.18а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Березовая, д.18а"
            },
            "geometry": {"type": "Point", "coordinates": [30.427494, 59.685972]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, Песчаный пер., д.4 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, Песчаный пер., д.4"
            },
            "geometry": {"type": "Point", "coordinates": [30.43876, 59.686585]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, г. Красное Село, Можайский, ул. 25 Октября, д.105",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Красное Село, Можайский, ул. 25 Октября, д.105"
            },
            "geometry": {"type": "Point", "coordinates": [30.119908, 59.697771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Красное Село, Можайский, Театральная ул.,  д. 15",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Красное Село, Можайский, Театральная ул.,  д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.122658, 59.698]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, Павловское ш., д.69а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, Павловское ш., д.69а"
            },
            "geometry": {"type": "Point", "coordinates": [30.424, 59.698334]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, п. Новокондакопшино, д.67 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, п. Новокондакопшино, д.67"
            },
            "geometry": {"type": "Point", "coordinates": [30.282052, 59.699015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, ул. Садовая, д.53 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, ул. Садовая, д.53"
            },
            "geometry": {"type": "Point", "coordinates": [30.458145, 59.699636]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, ул. Саперная, д.49 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, ул. Саперная, д.49"
            },
            "geometry": {"type": "Point", "coordinates": [30.406204, 59.700892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "п. Тярлево, ул. Спортивная, д.20 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "п. Тярлево, ул. Спортивная, д.20"
            },
            "geometry": {"type": "Point", "coordinates": [30.45151, 59.701957]}
        },
        {"type": "Feature",
            "properties": {
                "name": "п. Тярлево, ул. Большая,д.3 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "п. Тярлево, ул. Большая,д.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.441209, 59.702722]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, Кадетский б-р, д.26а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, Кадетский б-р, д.26а"
            },
            "geometry": {"type": "Point", "coordinates": [30.406669, 59.70468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, Кадетский б-р, д.15 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, Кадетский б-р, д.15"
            },
            "geometry": {"type": "Point", "coordinates": [30.405842, 59.705823]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, Красносельское ш., д.39а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, Красносельское ш., д.39а"
            },
            "geometry": {"type": "Point", "coordinates": [30.367498, 59.706103]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, Красносельское ш., д.7а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, Красносельское ш., д.7а"
            },
            "geometry": {"type": "Point", "coordinates": [30.378174, 59.70843]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, ул. Захаржевская, д.3 ",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, ул. Захаржевская, д.3 "
            },
            "geometry": {"type": "Point", "coordinates": [30.410648, 59.709007]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, Кадетский б-р, д.8а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, Кадетский б-р, д.8а"
            },
            "geometry": {"type": "Point", "coordinates": [30.403725, 59.710061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Павловск, п. Динамо, Павловское ш., д.3 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Павловск, п. Динамо, Павловское ш., д.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.411394, 59.711749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт – Петербург, г. Пушкин, Тиньков пер., д7 лит. Д; ",
                "organisation": "Общество с ограниченной  ответственностью «Группа компаний «Дарница»",
                "type": "Другое ",
                "category": 3,
                "address": "Санкт – Петербург, г. Пушкин, Тиньков пер., д7 лит. Д; "
            },
            "geometry": {"type": "Point", "coordinates": [30.366144, 59.714548]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, ул. Малая, д.52а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, ул. Малая, д.52а"
            },
            "geometry": {"type": "Point", "coordinates": [30.406602, 59.71822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, г. Красное Село, Кингисеппское шоссе д.55",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Красное Село, Кингисеппское шоссе д.55"
            },
            "geometry": {"type": "Point", "coordinates": [30.054297, 59.720788]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, ул. Малая, д.14а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, ул. Малая, д.14а"
            },
            "geometry": {"type": "Point", "coordinates": [30.401041, 59.720876]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, ул. Железнодорожная, д.18 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, ул. Железнодорожная, д.18"
            },
            "geometry": {"type": "Point", "coordinates": [30.428162, 59.721478]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, ул. Пушкинская, д.14а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, ул. Пушкинская, д.14а"
            },
            "geometry": {"type": "Point", "coordinates": [30.403996, 59.72316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС 548 ",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Детскосельский, Колпинское шоссе, 117, справа"
            },
            "geometry": {"type": "Point", "coordinates": [30.457275, 59.727039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка ул. Киевская, д. 13",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "ул. Киевская, д. 13"
            },
            "geometry": {"type": "Point", "coordinates": [30.068311, 59.728014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "198320, Санкт-Петербург, г. Красное Село, ул. Первого мая, д.2 ",
                "organisation": "ОТКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО \"КРАСНОГОРОДСКАЯ ЭКСПЕРИМЕНТАЛЬНАЯ БУМАЖНАЯ ФАБРИКА\"",
                "type": "Фабрика",
                "category": 3,
                "address": "198320, Санкт-Петербург, г. Красное Село, ул. Первого мая, д.2 "
            },
            "geometry": {"type": "Point", "coordinates": [30.100841, 59.728837]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Пушкин, Автомобильная, д. 7",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "г. Пушкин, Автомобильная, д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.441772, 59.729888]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 110\/6\/10 кВ № 185 \"Пушкин-Южная\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Энергоснабжение",
                "category": 3,
                "address": "Санкт-Петербург, г. Пушкин, ул. Сетевая, д. 22"
            },
            "geometry": {"type": "Point", "coordinates": [30.453427, 59.730587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "п. Александровская, Волхонское ш., д.33 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "п. Александровская, Волхонское ш., д.33"
            },
            "geometry": {"type": "Point", "coordinates": [30.347361, 59.731165]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, ул. Автомобильная, д.4, к.2 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, ул. Автомобильная, д.4, к.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.444698, 59.732522]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, г. Красное село, ул. Юных Пионеров, дом 9, лит. А.",
                "organisation": "Открытое Акционерное Общество \"Красносельские бани\"",
                "type": "Бани",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Красное село, ул. Юных Пионеров, дом 9, лит. А."
            },
            "geometry": {"type": "Point", "coordinates": [30.083639, 59.732914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "п. Александровская, Волхонское ш., д.32а - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "п. Александровская, Волхонское ш., д.32а"
            },
            "geometry": {"type": "Point", "coordinates": [30.348348, 59.733512]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Пушкин, Кузьминское ш., д.64 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Пушкин, Кузьминское ш., д.64"
            },
            "geometry": {"type": "Point", "coordinates": [30.380651, 59.733517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный пункт Волхонское шоссе, д.123, к.2",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Волхонское ш., 123, Санкт-Петербург, 196140"
            },
            "geometry": {"type": "Point", "coordinates": [30.328386, 59.738053]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МТБ \"Красное Село\"",
                "organisation": "Филиал ООО \"Газпром трансгаз Санкт-Петербург\" Управление материально технического снабжения и комплектации.",
                "type": "Нефтегазовая компания",
                "category": 3,
                "address": "Гвардейская ул., 30, Санкт-Петербург, 198320"
            },
            "geometry": {"type": "Point", "coordinates": [30.069892, 59.73859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, г. Колпино, Заводской пр., д. 1 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Колпино, Заводской пр., д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.556785, 59.739983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Красное Село, ул. Лермонтова д. 5",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Красное Село, ул. Лермонтова д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.084616, 59.740916]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад №7",
                "organisation": "Государственного бюджетного дошкольного образовательного учреждения центр развития ребенка - детский сад № 7 Колпинского района Санкт – Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "Пролетарская ул., 58, корп. 2, Санкт-Петербург, 196650"
            },
            "geometry": {"type": "Point", "coordinates": [30.566275, 59.741112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК Красное Село",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "г. Красное село, ул. Спирина, д. 10, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.086314, 59.742742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС № 519",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пос. Шушары, Московское ш., д. 18"
            },
            "geometry": {"type": "Point", "coordinates": [30.497048, 59.7488]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБДОУ детский сад № 10 Колпинского района СПб",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 10 для детей раннего возраста Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "Павловская ул., 59, Санкт-Петербург, Ленинградская обл., 196650"
            },
            "geometry": {"type": "Point", "coordinates": [30.567414, 59.749305]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗК №532",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Пулковское шоссе, д. 109, лит.а"
            },
            "geometry": {"type": "Point", "coordinates": [30.313138, 59.74996]}
        },
        {"type": "Feature",
            "properties": {
                "name": " ГБУ \"Санкт-Петербургская горветстанция\" по адресу: 189630, Санкт-Петербург, г. Колпино, ул. Колпинская, д. 6, лит. А, лит. Б",
                "organisation": "ГБУ \"Санкт-Петербургская горветстанция\" по адресу: 189630, Санкт-Петербург, г. Колпино, ул. Колпинская, д. 6, лит. А, лит. Б",
                "type": "Ветеринарная станция",
                "category": 3,
                "address": "Колпинская ул., 6, Санкт-Петербург, 196655"
            },
            "geometry": {"type": "Point", "coordinates": [30.619422, 59.750667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка детского сада №2",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 1 Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Карла Маркса, 10, Санкт-Петербург, Ленинградская обл., 196653"
            },
            "geometry": {"type": "Point", "coordinates": [30.590213, 59.750892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, г. Красное Село, Геологическая ул., д. 79 корп.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Красное Село, Геологическая ул., д. 79 корп.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.072895, 59.75095]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка детского сада №1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 1 Колпинского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "пр. Ленина, 27-а, Санкт-Петербург, 196650"
            },
            "geometry": {"type": "Point", "coordinates": [30.577913, 59.751536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производство железобетонных изделий",
                "organisation": "Общество с ограниченной ответственностью  \"БЕТОНЕКС - Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Финляндская, 36, Санкт-Петербург, 196650"
            },
            "geometry": {"type": "Point", "coordinates": [30.537373, 59.753473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Пулковское шоссе, д. 89 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Пулковское шоссе, д. 89 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.315215, 59.754564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный пункт г. Колпино",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Колпино, ул. Севастьянова, д. 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.609866, 59.760259]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводные очистные сооружения г. Колпино",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": " г. Санкт-Петербург, г. Колпино, ул. Севастьянова, д. 20;"
            },
            "geometry": {"type": "Point", "coordinates": [30.609187, 59.761495]}
        },
        {"type": "Feature",
            "properties": {
                "name": "196627, г. Санкт-Петербург, п. Шушары, Московское шоссе, д. 233 «А»",
                "organisation": "Общество с ограниченной ответственностью \"ИВ-Сервис\"",
                "type": "Другое",
                "category": 3,
                "address": "196627, г. Санкт-Петербург, п. Шушары, Московское шоссе, д. 233 «А»"
            },
            "geometry": {"type": "Point", "coordinates": [30.483795, 59.763991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, Горелово, ул. Генерала Лагуткина, д.16",
                "organisation": "Общество с ограниченной ответственностью \"Минимакс\"",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, Горелово, ул. Генерала Лагуткина, д.16"
            },
            "geometry": {"type": "Point", "coordinates": [30.110609, 59.772743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС-100 ООО \"ПТК\" , 78:40:0008611:14",
                "organisation": "Общество с ограниченной ответственностью «Петербургская топливная компания»",
                "type": "Котельная",
                "category": 3,
                "address": "Горелово, Красносельское шоссе, 1, корп.2, лит. \"А\""
            },
            "geometry": {"type": "Point", "coordinates": [30.125336, 59.772761]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Саперный, ул. Дорожная, д. 13, кор. 2,3 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Саперный, ул. Дорожная, д. 13, кор. 2,3"
            },
            "geometry": {"type": "Point", "coordinates": [30.689428, 59.777507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Понтонный, ул. Южная, д. 5, корп. 2 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Понтонный, ул. Южная, д. 5, корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.653756, 59.77851]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Торики, ул. Политрука Пасечника д. 16, корп. 4",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Торики, ул. Политрука Пасечника д. 16, корп. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.102919, 59.779841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин Магнит \"Барбасена\"",
                "organisation": "Филиал акционерного общества \"Тандер\" в г. Санкт-Петербург",
                "type": "Другое",
                "category": 3,
                "address": "198323 г. Санкт-Петербург, Горелово, Красносельское шоссе, д. 28, корп. 2, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.136295, 59.781579]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Понтонный, ул. Южная, д. 25 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Понтонный, ул. Южная, д. 25"
            },
            "geometry": {"type": "Point", "coordinates": [30.641858, 59.781925]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №536",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пос. Шушары, Пулковское ш., д. 72"
            },
            "geometry": {"type": "Point", "coordinates": [30.326415, 59.782377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Понтонный, ул. Александра Товпеко, д. 12, кор. 2 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Понтонный, ул. Александра Товпеко, д. 12, кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.625391, 59.784692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Понтонный, Шлиссельбургское ш., д. 79 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Понтонный, Шлиссельбургское ш., д. 79"
            },
            "geometry": {"type": "Point", "coordinates": [30.661261, 59.786428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Понтонный, ул. Заводская, д. 15, кор. 2 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Понтонный, ул. Заводская, д. 15, кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.626489, 59.787582]}
        },
        {"type": "Feature",
            "properties": {
                "name": "филиал ОАО \"ЕВРАЗ Металл Инпром\" в г.Санкт-Петербург",
                "organisation": "ОАО \"ЕВРАЗ Металл Инпром\" филиал в г.Санкт-Петербург",
                "type": "Другое",
                "category": 3,
                "address": "дор. на Металлострой, 5, лит. АВ, пос. Металлострой, Санкт-Петербург, 196641"
            },
            "geometry": {"type": "Point", "coordinates": [30.557737, 59.787984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС 547 ",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Витебский, д. 139"
            },
            "geometry": {"type": "Point", "coordinates": [30.39374, 59.788354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦАКС Санкт-Петербургского центра обслуживания воздушного движения",
                "organisation": "Филиал «Аэронавигация Северо-Запада» Федерального государственного унитарного предприятия «Государственная корпорация по организации воздушного движения в Российской Федерации»",
                "type": "Другое",
                "category": 3,
                "address": "Пулковское шоссе, 37, кор.5, лит.А"
            },
            "geometry": {"type": "Point", "coordinates": [30.288703, 59.797669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОП АО \"ЛОНМАДИ\" \"ПАРНАС\" в г.Санкт-Петербурге",
                "organisation": "Обособленное подразделение АО \"ЛОНМАДИ\" \"ПАРНАС\" в г.Санкт-Петербурге",
                "type": "Другое",
                "category": 3,
                "address": "Пулковское ш., 72Б, Санкт-Петербург, 196140"
            },
            "geometry": {"type": "Point", "coordinates": [30.321724, 59.798347]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Станция наполнения баллонов и транспортных емкостей сжатыми и сниженными газами",
                "organisation": "Филиал АО «Линде Газ Рус» - «Линде Газ Санкт-Петербург»",
                "type": "Другое",
                "category": 3,
                "address": "196641, г. Санкт-Петербург пос. Металлострой, дорога на Металлострой, 5, стр. 29"
            },
            "geometry": {"type": "Point", "coordinates": [30.541834, 59.798635]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1",
                "organisation": "Общество с ограниченной ответственностью \"Пулково Аэропорт Отель Санкт - Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Пулковское ш., д. 41, лит. ЗИ, Санкт-Петербург, 196140"
            },
            "geometry": {"type": "Point", "coordinates": [30.275735, 59.800495]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЗАО \"ППИ ЗАО\"",
                "organisation": "Закрытое акционерное общество \"Петербург Продактс Интернешнл ЗАО\"",
                "type": "Другое",
                "category": 3,
                "address": "Пулковское ш., 54, Санкт-Петербург, 196140"
            },
            "geometry": {"type": "Point", "coordinates": [30.334082, 59.802041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Петро-Славянка, ул. Коммунаров, д. 2 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Петро-Славянка, ул. Коммунаров, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.512676, 59.803961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г.  Санкт-Петербург, п. Усть-Ижора, ул. Комсомола, д. 6 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г.  Санкт-Петербург, п. Усть-Ижора, ул. Комсомола, д. 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.583183, 59.804962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС № 526",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "ш.Таллинское, д. 161 к3"
            },
            "geometry": {"type": "Point", "coordinates": [30.161356, 59.806146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС № 538",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Пос. Петро-Славянка, Софийская ул., д 127, к.1"
            },
            "geometry": {"type": "Point", "coordinates": [30.469122, 59.807798]}
        },
        {"type": "Feature",
            "properties": {
                "name": " г. Санкт-Петербург, п. Петро-Славянка, ул. Клубная, д. 1, кор. 5 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Санкт-Петербург, п. Петро-Славянка, ул. Клубная, д. 1, кор. 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.51105, 59.808105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория №1",
                "organisation": "Закрытое акционерное общество «Аэромар»,  «Аэромар – Санкт-Петербург»",
                "type": "Производство продуктов питания",
                "category": 3,
                "address": "корп. 4, ул. Пилотов, 18, Санкт-Петербург, 196210"
            },
            "geometry": {"type": "Point", "coordinates": [30.311854, 59.808876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\" ОП \"РОЛЬФ Пулково\"",
                "organisation": "Общество с ограниченной ответственностью \"РОЛЬФ Эстейт Санкт-Петербург\" Обособленное подразделение \"РОЛЬФ Пулково\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, ул. Стартовая дом 4 лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.319351, 59.809547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Металлострой, ул. Пионерская, д. 7а - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Металлострой, ул. Пионерская, д. 7а"
            },
            "geometry": {"type": "Point", "coordinates": [30.569408, 59.811885]}
        },
        {"type": "Feature",
            "properties": {
                "name": " п. Шушары, ул. Школьная, д.56 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " п. Шушары, ул. Школьная, д.56"
            },
            "geometry": {"type": "Point", "coordinates": [30.373132, 59.81242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Штурманская ул., д. 8",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Штурманская ул., д. 8"
            },
            "geometry": {"type": "Point", "coordinates": [30.306044, 59.812818]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завод",
                "organisation": "Общество с ограниченной ответственностью «Тойота Мотор» (Филиал Общества с ограниченной ответственностью «Тойота Мотор» в Санкт-Петербурге)",
                "type": "Автосалон",
                "category": 3,
                "address": "196626, Санкт-Петербург, поселок Шушары, Софийская ул., д. 115 литера А"
            },
            "geometry": {"type": "Point", "coordinates": [30.438858, 59.814928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "п. Шушары, Пулковское ш., д.42 - котельная",
                "organisation": "Государственное унитарное предприятие \"Топливно - энергетический комплекс Санкт - Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "п. Шушары, Пулковское ш., д.42"
            },
            "geometry": {"type": "Point", "coordinates": [30.340971, 59.815434]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Металлострой, ул. Богайчука, д. 3 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Металлострой, ул. Богайчука, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.568716, 59.817494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бизнес-Центр. 1 здание",
                "organisation": "Общество с ограниченной ответственностью \"Технополис-Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Пулковское шоссе, 40 корпус 4, Санкт-Петербург"
            },
            "geometry": {"type": "Point", "coordinates": [30.327499, 59.818264]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №300",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Пулковское шоссе, д. 38"
            },
            "geometry": {"type": "Point", "coordinates": [30.324896, 59.820535]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Усть-Ижора, Шлиссельбургское ш., д. 3 - котельная",
                "organisation": "Государственное Унитарное Предприятие \"Топливно-Энергетический Комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Усть-Ижора, Шлиссельбургское ш., д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.568613, 59.821857]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Малая Балканская, ул., д. 39 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Малая Балканская, ул., д. 39 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.403203, 59.825233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Публичное акционерное общество \"Петербургский мельничный комбинат\"",
                "organisation": "Публичное акционерное общество \"Петербургский мельничный комбинат\"",
                "type": "Комбинат ",
                "category": 3,
                "address": "4-й Предпортовый пр-д, 5, Санкт-Петербург, 196240"
            },
            "geometry": {"type": "Point", "coordinates": [30.307389, 59.829419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0546-СПБ - г. Санкт-Петербург, ул. Малая Балканская, 27, литер А",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0546-СПБ - г. Санкт-Петербург, ул. Малая Балканская, 27, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.383007, 59.829469]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка №1",
                "organisation": "ООО \"Санкт-Петербургское учебно-реабилитационное предприятие \"Свет\"",
                "type": "учреждение социального обслуживания",
                "category": 3,
                "address": "5-й Предпортовый пр-д, д. 3, Санкт-Петербург, 196240"
            },
            "geometry": {"type": "Point", "coordinates": [30.311971, 59.830505]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Загребский бульвар, д. 42",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Загребский бульвар, д. 42"
            },
            "geometry": {"type": "Point", "coordinates": [30.416958, 59.830576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №305",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Дунайский пр., д.19"
            },
            "geometry": {"type": "Point", "coordinates": [30.347364, 59.831038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СО \"Луч\"",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "территория Усть-Славянка, Санкт-Петербург, 192076, Лесная улица, 8"
            },
            "geometry": {"type": "Point", "coordinates": [30.549607, 59.831461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0575-СПб - г. Санкт-Петербург, ул Ленсовета 101 литер А, ТЦ Континент",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0575-СПб - г. Санкт-Петербург, ул Ленсовета 101 литер А, ТЦ Континент"
            },
            "geometry": {"type": "Point", "coordinates": [30.346084, 59.831805]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка №2  - Санкт-Петербург, ул. Предпортовая д.1, корп.4",
                "organisation": "Санкт-Петербургский институт по проектированию инженерных сооружений и промышленных предприятий путевого хозяйства и геологическим изысканиям \"Ленгипротранспуть\" - филиал АО \"Росжелдорпроект\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, ул. Предпортовая д.1, корп.4"
            },
            "geometry": {"type": "Point", "coordinates": [30.297383, 59.831982]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0541-СПб -196158, г. Санкт-Петербург, Дунайский пр-кт, 27, к. 1, лит. Б",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0541-СПб -196158, г. Санкт-Петербург, Дунайский пр-кт, 27, к. 1, лит. Б"
            },
            "geometry": {"type": "Point", "coordinates": [30.363159, 59.832089]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Предпортовая ул., д. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Предпортовая ул., д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.301722, 59.833159]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, п. Володарский, ул. Российский бульвар, дом 6, лит. А.",
                "organisation": "Открытое Акционерное Общество \"Красносельские бани\"",
                "type": "Бани",
                "category": 3,
                "address": "г. Санкт-Петербург, п. Володарский, ул. Российский бульвар, дом 6, лит. А."
            },
            "geometry": {"type": "Point", "coordinates": [30.087138, 59.833309]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивный комплекс Московское шоссе",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "Московское ш., 3, корпус 3, Санкт-Петербург, 196158"
            },
            "geometry": {"type": "Point", "coordinates": [30.334536, 59.833945]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АГНКС-3 г. Санкт-Петербург",
                "organisation": "Общество с ограниченной ответственностью \"Газпром газомоторное топливо\"",
                "type": "Котельная",
                "category": 3,
                "address": "Московский, Санкт-Петербург, 196240"
            },
            "geometry": {"type": "Point", "coordinates": [30.308881, 59.834378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "196240, г. Санкт-Петербург, 7-й Предпортовый проезд, д. 8 литер Б",
                "organisation": "Акционерное общество \"Автопарк № 1 \"Спецтранс\"",
                "type": "Другое",
                "category": 3,
                "address": "196240, г. Санкт-Петербург, 7-й Предпортовый проезд, д. 8 литер Б"
            },
            "geometry": {"type": "Point", "coordinates": [30.295505, 59.834563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Психоневрологический интернат №7\"",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Психоневрологический интернат №7\"",
                "type": "учреждение социального обслуживания",
                "category": 3,
                "address": "проспект Ветеранов, 180"
            },
            "geometry": {"type": "Point", "coordinates": [30.130454, 59.834667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 435",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Караваевская ул., д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.495771, 59.835959]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ул. Авангардная, д.12",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Авангардная, д.12"
            },
            "geometry": {"type": "Point", "coordinates": [30.183217, 59.836419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, ул. 2-я Комсомольская, дом 27, корп. 3, лит. А.",
                "organisation": "Открытое Акционерное Общество \"Красносельские бани\"",
                "type": "Бани",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. 2-я Комсомольская, дом 27, корп. 3, лит. А."
            },
            "geometry": {"type": "Point", "coordinates": [30.141783, 59.837056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивный комплекс Ветеранов 58",
                "organisation": "Санкт-Петербургское государственное автномное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "пр-т. Ветеранов, 58, Санкт-Петербург, 198255"
            },
            "geometry": {"type": "Point", "coordinates": [30.234477, 59.837269]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Авангардная ул., д.17",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Авангардная ул., д.17"
            },
            "geometry": {"type": "Point", "coordinates": [30.182345, 59.838316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, ул. Тамбасова. д.16",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Тамбасова. д.16"
            },
            "geometry": {"type": "Point", "coordinates": [30.159418, 59.838724]}
        },
        {"type": "Feature",
            "properties": {
                "name": "вспомогательное производство г. Санкт-Петербург, Дачный пр., д. 23, корп. 8",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Дачный пр., д. 23, корп. 8"
            },
            "geometry": {"type": "Point", "coordinates": [30.256833, 59.838754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Бухарестская ул., д. 134",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Бухарестская ул., д. 134"
            },
            "geometry": {"type": "Point", "coordinates": [30.416025, 59.839051]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество с ограниченной ответственностью «Научно-технический центр комплексных проблем «Механотроника» (198206, г. Санкт-Петербург, ул. Пионерстроя, д. 23, лит. А)",
                "organisation": "Общество с ограниченной ответственностью «Научно-технический центр комплексных проблем «Механотроника» (198206, г. Санкт-Петербург, ул. Пионерстроя, д. 23, лит. А)",
                "type": "Другое",
                "category": 3,
                "address": "198206, г. Санкт-Петербург, ул. Пионерстроя, д. 23, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.128863, 59.839931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка",
                "organisation": "Федеральное казенное учреждение «Исправительная колония №6 Управления Федеральной службы исполнения наказаний по г. Санкт-Петербургу и Ленинградской области»",
                "type": "Учреждение ФСИН",
                "category": 3,
                "address": "Санкт-Петербург, Россия, 192288, Грузовой проезд, 7Л"
            },
            "geometry": {"type": "Point", "coordinates": [30.437328, 59.841448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество с ограниченной ответственностью «Научно-технический центр комплексных проблем «Механотроника» (198206, г. Санкт-Петербург, 2-я Комсомольская ул., д. 13, к. 3, лит. А)",
                "organisation": "Общество с ограниченной ответственностью «Научно-технический центр комплексных проблем «Механотроника» (198206, г. Санкт-Петербург, 2-я Комсомольская ул., д. 13, к. 3, лит. А)",
                "type": "Другое",
                "category": 3,
                "address": "198206, г. Санкт-Петербург, 2-я Комсомольская ул., д. 13, к. 3, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.142417, 59.841783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, ул. Пионерстроя, д. 19",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Пионерстроя, д. 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.123138, 59.842169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС № 511",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "ул.Партизана Германа, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.176546, 59.843681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК Дунайский, 58",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": " г. Санкт-Петербург, Дунайский пр. д. 58 к. 3, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.414613, 59.84435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "192289, Санкт-Петербург, Гаражный проезд, д.1",
                "organisation": "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"ЛЕНТЕЛЕФОНСТРОЙ-ОПЫТНЫЙ ЗАВОД\"",
                "type": "Завод",
                "category": 3,
                "address": "192289, Санкт-Петербург, Гаражный проезд, д.1"
            },
            "geometry": {"type": "Point", "coordinates": [30.444145, 59.846255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "офисные и производственные помещения",
                "organisation": "Открытое акционерное общество \"Санкт-Петербургское грузовое автотранспортное предприятие № 43\" ",
                "type": "Другое",
                "category": 3,
                "address": "Г САНКТ-ПЕТЕРБУРГ,ПРОСП ДЕВЯТОГО ЯНВАРЯ, 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.44537, 59.846968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС№ 549",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Кубинская ул., д.74"
            },
            "geometry": {"type": "Point", "coordinates": [30.292303, 59.848442]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Акционерное общество «Особая экономическая зона «Санкт-Петербург»",
                "organisation": "Акционерное общество «Особая экономическая зона «Санкт-Петербург»",
                "type": "Другое",
                "category": 3,
                "address": "ул. Связи, 34, Санкт-Петербург, 198515"
            },
            "geometry": {"type": "Point", "coordinates": [30.003995, 59.849173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводная насосная станция п. Стрельна",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г. Санкт-Петербург, пос. Стрельна, Санкт-Петербургское шоссе, д. 76;"
            },
            "geometry": {"type": "Point", "coordinates": [30.045908, 59.850516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Печатное производство.",
                "organisation": "Акционерное общество «Ордена Октябрьской Революции, Ордена Трудового Красного Знамени «Первая Образцовая типография» филиал «Санкт-Петербургский газетный комплекс» ",
                "type": "Другое",
                "category": 3,
                "address": "Ленинский пр., 139, Санкт-Петербург, 198216"
            },
            "geometry": {"type": "Point", "coordinates": [30.284511, 59.851277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральный офис",
                "organisation": "Общество с ограниченной ответственностью \"Газпром межрегионгаз Санкт-Петербург\"",
                "type": "Другое ",
                "category": 3,
                "address": "Ленинский пр., д.137, Санкт-Петербург, 198216"
            },
            "geometry": {"type": "Point", "coordinates": [30.278512, 59.851369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ремонтно-производственный комплекс (РПК)",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Котельная",
                "category": 3,
                "address": "Бухарестская ул., 116к2, Санкт-Петербург, Ленинградская область, 192288"
            },
            "geometry": {"type": "Point", "coordinates": [30.407069, 59.851496]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Петергофское шоссе, д. 27",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Петергофское шоссе, д. 27"
            },
            "geometry": {"type": "Point", "coordinates": [30.174328, 59.851621]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №529",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Петергофское ш., д. 98, к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.091447, 59.851988]}
        },
        {"type": "Feature",
            "properties": {
                "name": "строительства многоквартирных жилых домов со встроенными помещениями обслуживания, встроено-пристроенными гаражами, объектом дошкольного образования на 250 мест по адресу: г. Санкт-Петербург, ул. Бабушкина (кадастровый номер участка 78:12:0007125:228) (комплекс демонтажно-строительных работ) ",
                "organisation": "Общество с Ограниченной Ответственностью \"Сэтл Сити\"",
                "type": "Другое",
                "category": 3,
                "address": "Московский пр-т., 212, Санкт-Петербург, 196066"
            },
            "geometry": {"type": "Point", "coordinates": [30.326953, 59.852322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивный комплекс в Стрельне",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "Заводская дор., 8, Санкт-Петербург, 198515"
            },
            "geometry": {"type": "Point", "coordinates": [30.00644, 59.853106]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, пр. Стачек, д. 170",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Стачек, д. 170"
            },
            "geometry": {"type": "Point", "coordinates": [30.245974, 59.853719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПЭУ №2 (Обслуживание магистральных трубопроводов не осуществляется)",
                "organisation": "Общество с ограниченной ответственностью \"ПетербургГаз\"",
                "type": "Другое",
                "category": 3,
                "address": "198510, г. Петергоф, Прогонная ул, д.29"
            },
            "geometry": {"type": "Point", "coordinates": [29.913038, 59.855848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦСМВС \"Жесть\" ",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "проспект Космонавтов, 38к3"
            },
            "geometry": {"type": "Point", "coordinates": [30.355214, 59.855899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБСУ СО «Психоневрологический интернат № 2»",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания «Психоневрологический интернат № 2» ",
                "type": "учреждение социального обслуживания",
                "category": 3,
                "address": "Петергоф, Санкт-Петербург, Россия, 198510, Санкт-Петербургское шоссе, 130"
            },
            "geometry": {"type": "Point", "coordinates": [30.005488, 59.856543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Трамвайный пр, д. 8.",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Трамвайный пр, д. 8."
            },
            "geometry": {"type": "Point", "coordinates": [30.261435, 59.856918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Петергофское шоссе, д.77",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Петергофское шоссе, д.77"
            },
            "geometry": {"type": "Point", "coordinates": [30.115925, 59.857049]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, пр. Стачек, д.121",
                "organisation": "ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО \"СЕВЕРНОЕ\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, пр. Стачек, д.121"
            },
            "geometry": {"type": "Point", "coordinates": [30.251923, 59.857288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивный комплекс Доблести",
                "organisation": "Санкт-Петербургское государственное автномное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "ул. Доблести, 15, Санкт-Петербург, 198332"
            },
            "geometry": {"type": "Point", "coordinates": [30.176484, 59.859686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный пункт Краснопутиловская ул., 69",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Краснопутиловская ул., д. 69, лит. А "
            },
            "geometry": {"type": "Point", "coordinates": [30.287643, 59.859998]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерское производство",
                "organisation": "Открытое Акционерное Общество \"Петербургская коллекция\"",
                "type": "Другое",
                "category": 3,
                "address": "Белградская ул., 26, Санкт-Петербург, 192212"
            },
            "geometry": {"type": "Point", "coordinates": [30.363722, 59.860399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка г.Петродворец, Суворовцев, 7",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г.Петродворец, Суворовцев, 7"
            },
            "geometry": {"type": "Point", "coordinates": [29.926144, 59.860864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вспомогательное производство - г. Санкт-Петербург, ул. Шелгунова, д. 32",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Шелгунова, д. 32"
            },
            "geometry": {"type": "Point", "coordinates": [30.441472, 59.862831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный пункт  пр. Стачек, 83а",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "пр. Стачек, 83а"
            },
            "geometry": {"type": "Point", "coordinates": [30.25615, 59.862899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ветеринарная станция Петродворцового района",
                "organisation": "Ветеринарная станция Кировского, Красносельского и Петродворцового районов Санкт-Петербурга",
                "type": "Ветеринаная станция ",
                "category": 3,
                "address": "ул. Демьяна Бедного, 8\/5, Санкт-Петербург, 198517"
            },
            "geometry": {"type": "Point", "coordinates": [29.94774, 59.863093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Софийская ул., д. 54 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Софийская ул., д. 54 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.41832, 59.863773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Бассейная ул., д. 59 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Бассейная ул., д. 59 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.333305, 59.864385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС №416",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "ул. Шелгунова, д. 12, к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.453961, 59.866118]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургский филиал ФГКУ Росгранстрой МАПП Светогорск",
                "organisation": " филиал ФГКУ \"Дирекция по строительству и эксплуатации объектов Росграницы\" в г. Санкт-Петербурге МАПП Светогорск",
                "type": "Другое",
                "category": 3,
                "address": "Российская Федерация, 196070, Санкт-Петербург, УЛ БАССЕЙНАЯ, ДОМ 12\/ЛИТ. А, ПОМЕЩЕНИЕ 37-Н"
            },
            "geometry": {"type": "Point", "coordinates": [30.315564, 59.86643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вагонное ремонтное депо Санкт-Петербург-Московский-Сортировочный",
                "organisation": "Вагонное ремонтное депо Санкт-Петербург-Московский-Сортировочный - обособленное структурное подразделение  АО \"ВРК-1\"",
                "type": "Ремонтное депо",
                "category": 3,
                "address": "Санкт-Петербург, 192174"
            },
            "geometry": {"type": "Point", "coordinates": [30.42337, 59.868001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "пр. Обуховской Обороны, д .108, корп. 2",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Обуховской Обороны, д .108, корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.46723, 59.868642]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Установка резервуарная",
                "organisation": "Общество с ограниченной ответсвенностью \"Мерктранс Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Автовская ул., 17, Санкт-Петербург, 198152"
            },
            "geometry": {"type": "Point", "coordinates": [30.269691, 59.868705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производственная площадка",
                "organisation": "Филиал «АВТОДОМ Санкт-Петербург» Акционерного общества «АВТОДОМ»",
                "type": "Автосалон",
                "category": 3,
                "address": "проспект Славы, 70"
            },
            "geometry": {"type": "Point", "coordinates": [30.421378, 59.868783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\"",
                "organisation": "Общество с ограниченной ответственностью \"РОЛЬФ Эстейт Санкт-Петербург\"",
                "type": "Автосалон",
                "category": 3,
                "address": "Санкт-Петербург, Витебский проспект, д. 17, корп. 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.354062, 59.868903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Леснозаводская ул., д.4 кор. 1",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Леснозаводская ул., д.4 кор. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.456119, 59.869061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"ОРИМИ\" (193079, Санкт-Петербург, Октябрьская наб., д. 104, корп. 2, лит. А)",
                "organisation": "Общество с ограниченной ответственностью \"ОРИМИ\"",
                "type": "Другое",
                "category": 3,
                "address": "193079, Санкт-Петербург, Октябрьская наб., д. 104, корп. 2, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.484916, 59.869308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводная станция г.Петродворец",
                "organisation": "Государственное унитарное предприятие  \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г. Санкт-Петербург, Петродворец, Заячий Ремиз, Заячий пр., д.1;"
            },
            "geometry": {"type": "Point", "coordinates": [29.887299, 59.869725]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБСУ СО \"Психоневрологический интернат №3\"",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Психоневрологический интернат №3\"",
                "type": "учреждение социального обслуживания",
                "category": 3,
                "address": "Петергоф, Санкт-Петербург, Россия, 198504, Заячий проспект, 3"
            },
            "geometry": {"type": "Point", "coordinates": [29.878278, 59.870144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, Витебский проспект, д.17",
                "organisation": "ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО \"СЕВЕРНОЕ\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, Витебский проспект, д.17"
            },
            "geometry": {"type": "Point", "coordinates": [30.352434, 59.870483]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Ивановская ул., д.36а",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Ивановская ул., д.36а"
            },
            "geometry": {"type": "Point", "coordinates": [30.432731, 59.871233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Кузнецовская ул., д. 52 корп. 10",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Кузнецовская ул., д. 52 корп. 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.339666, 59.874603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество с ограниченной ответственностью \"Петродворцовое оптово-розничное объединение\" (198510, г. Санкт-Петербург, г. Петродворец, Торговая пл., д. 8)",
                "organisation": "Общество с ограниченной ответственностью \"Петродворцовое оптово-розничное объединение\" (198510, г. Санкт-Петербург, г. Петродворец, Торговая пл., д. 8)",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "198510, г. Санкт-Петербург, г. Петродворец, Торговая пл., д. 8"
            },
            "geometry": {"type": "Point", "coordinates": [29.915004, 59.87773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория ООО \"СПБ Институт Теплоэнергетики\"",
                "organisation": "Общество с ограниченной ответсвенностью \"Санкт-Петербургский Институт Теплоэнергетики\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Решетникова, 15, Санкт-Петербург, 196105"
            },
            "geometry": {"type": "Point", "coordinates": [30.329625, 59.878898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество с ограниченной ответственностью \"Петродворцовое оптово-розничное объединение\" (198504, г. Санкт-Петербург, г. Петродворец, б-р Красных Курсантов, д. 63)",
                "organisation": "Общество с ограниченной ответственностью \"Петродворцовое оптово-розничное объединение\" (198504, г. Санкт-Петербург, г. Петродворец, б-р Красных Курсантов, д. 63)",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "198504, г. Санкт-Петербург, г. Петродворец, б-р Красных Курсантов, д. 63"
            },
            "geometry": {"type": "Point", "coordinates": [29.863609, 59.878984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ул. Возрождения, д.39",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Возрождения, д.39"
            },
            "geometry": {"type": "Point", "coordinates": [30.281295, 59.87932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Университет",
                "organisation": "Санкт-Петербургский Гуманитарный университет профсоюзов",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Фучика, 15, Санкт-Петербург, 192236"
            },
            "geometry": {"type": "Point", "coordinates": [30.377363, 59.879634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПС-8",
                "organisation": "филиал \"ПОСММ\" Государственного унитарного предприятия \"Топливно-энергетический Комплекс Санкт-Петербурга\"",
                "type": "Котельная",
                "category": 3,
                "address": "196105, Санкт-Петербург, ул. Рощинская, д.21"
            },
            "geometry": {"type": "Point", "coordinates": [30.343058, 59.88003]}
        },
        {"type": "Feature",
            "properties": {
                "name": " Производственная территория базы по централизованному обслуживанию (БЦО) Газораспределительной станции \"Шоссейная\" Филиала ООО \"Газпром трансгаз Санкт-Петербург\" Инженерно-технический центр",
                "organisation": "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"ГАЗПРОМ ТРАНСГАЗ САНКТ-ПЕТЕРБУРГ\"",
                "type": "Другое",
                "category": 3,
                "address": "Варшавская ул., 3, к.2, Санкт-Петербург, 196128"
            },
            "geometry": {"type": "Point", "coordinates": [30.30696, 59.881194]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ремонтно-эксплуатационная база (РЭБ)",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Котельная",
                "category": 3,
                "address": "Кубинская ул., 7, Санкт-Петербург, 196128"
            },
            "geometry": {"type": "Point", "coordinates": [30.297345, 59.881319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗК №343",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Московский пр., д. 156а"
            },
            "geometry": {"type": "Point", "coordinates": [30.320854, 59.882019]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЗАО \"Гигровата- СПб\"",
                "organisation": "Акционерное общество \"Гигровата - Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "192171, Санкт-Петербург, Железнодорожный пр., 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.427319, 59.882829]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0524-СПБ -192102, г. Санкт-Петербург, Бухарестская ул, 30, литер А",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0524-СПБ -192102, г. Санкт-Петербург, Бухарестская ул, 30, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.369621, 59.883153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "НАРОДНАЯ УЛ. - ГБДОУ Детский сад № 86 Невского района Санкт-Петербурга ",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад  № 86 общеразвивающего вида с приоритетным осуществлением деятельности по физическому развитию детей  Невского  района Санкт-Петербурга ",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Народная, 38, Санкт-Петербург, 193315"
            },
            "geometry": {"type": "Point", "coordinates": [30.470393, 59.883304]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Цимбалина, д. 58",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Цимбалина, д. 58"
            },
            "geometry": {"type": "Point", "coordinates": [30.415295, 59.88339]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Общество с ограниченной ответственностью \"Петродворцовое оптово-розничное объединение\" (198504, г. Санкт-Петербург, г. Петродворец,  ул. 1-го Мая, д.89)",
                "organisation": "Общество с ограниченной ответственностью \"Петродворцовое оптово-розничное объединение\" (198504, г. Санкт-Петербург, г. Петродворец,  ул. 1-го Мая, д.89)",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "198504, г. Санкт-Петербург, г. Петродворец,  ул. 1-го Мая, д.89"
            },
            "geometry": {"type": "Point", "coordinates": [29.836048, 59.883971]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производственная площадка",
                "organisation": "Акционерное общество \"Петербургский тракторный завод\"",
                "type": "Завод",
                "category": 3,
                "address": "198097, РОССИЯ, г. Санкт-Петербург, пр. Стачек, 47"
            },
            "geometry": {"type": "Point", "coordinates": [30.264247, 59.884083]}
        },
        {"type": "Feature",
            "properties": {
                "name": "196105, г. Санкт-Петербург, Люботинский пр., д. 7",
                "organisation": "Акционерное общество \"Автопарк № 1 \"Спецтранс\"",
                "type": "Другое",
                "category": 3,
                "address": "196105, г. Санкт-Петербург, Люботинский пр., д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.341396, 59.884185]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка АТК Витебский",
                "organisation": "Акционерное общество \"Совавто-С.Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "196006, город Санкт-Петербург, Витебский проспект, дом 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.343659, 59.885258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "НОВОСЁЛОВ УЛ. - ГБДОУ Детский сад № 86 Невского района Санкт-Петербурга - ",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад  № 86 общеразвивающего вида с приоритетным осуществлением деятельности по физическому развитию детей  Невского  района Санкт-Петербурга ",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Новосёлов, 53, Санкт-Петербург, 193315"
            },
            "geometry": {"type": "Point", "coordinates": [30.480954, 59.886627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "вспомогательное производство г. Санкт-Петербург, Бухарестская ул., д. 24, корп. 3, 4.",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": " г. Санкт-Петербург, Бухарестская ул., д. 24, корп. 3, 4."
            },
            "geometry": {"type": "Point", "coordinates": [30.36573, 59.886881]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 138 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 138 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.321653, 59.886993]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 125 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 125 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.318893, 59.887025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Обуховской Обороны, д. 133",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Обуховской Обороны, д. 133"
            },
            "geometry": {"type": "Point", "coordinates": [30.441784, 59.887081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Дудко, д. 31 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Дудко, д. 31 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.426586, 59.88715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "196084, г. Санкт-Петербург, ул. Заставская, д. 1",
                "organisation": "Общество с ограниченной  ответственностью «Заготовительное предприятие «Застава»",
                "type": "Другое",
                "category": 3,
                "address": "196084, г. Санкт-Петербург, ул. Заставская, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.341694, 59.888233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, ул.Салова, д. 55 корп. 4",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул.Салова, д. 55 корп. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.376573, 59.889063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка",
                "organisation": "Санкт-Петербургский филиал общества с ограниченной ответственностью \"ОТИС Лифт\"",
                "type": "Другое",
                "category": 3,
                "address": "Химический пер., 14, Санкт-Петербург, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.292213, 59.889622]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 436",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "ул. Крупской, д. 43, к. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.421861, 59.889664]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Большевиков, д.38 кор.4",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Большевиков, д.38 кор.4"
            },
            "geometry": {"type": "Point", "coordinates": [30.493903, 59.890025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обособленное структурное подразделениее общества ООО «Северсталь-Вторчермет» -Санкт-Петербургский территориальный филиал ООО «Северсталь-Вторчермет»",
                "organisation": "Санкт-Петербургский территориальный филиал общества с ограниченной ответственностью «Северсталь-Вторчермет»",
                "type": "Другое",
                "category": 3,
                "address": "Химический пер., 4, Санкт-Петербург, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.287695, 59.890184]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Зубковская ул., д. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Зубковская ул., д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.417272, 59.890263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 124 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 124 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.318631, 59.890739]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, ул. Коли Томчака, д. 14 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Коли Томчака, д. 14 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.326402, 59.890899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Ткачей, д.70 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Ткачей, д.70 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.421962, 59.891499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "196084, Санкт-Петербург, ул.  Парковая, д.4, лит. В, лит. Е",
                "organisation": "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"КОНСАЛ РЕКЛАМА\"",
                "type": "Другое",
                "category": 3,
                "address": "196084, Санкт-Петербург, ул.  Парковая, д.4, лит. В, лит. Е"
            },
            "geometry": {"type": "Point", "coordinates": [30.3371, 59.89157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филиал \"ДиПОС-СПБ\"  ООО \"ПКФ \"ДиПОС\"",
                "organisation": "Филиал \"ДиПОС-Санкт-Петербург \" ООО \"Производственно-коммерческая фирма\" \"ДиПОС\"",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "Химический переулок, 4Д"
            },
            "geometry": {"type": "Point", "coordinates": [30.283541, 59.89171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Бабушкина, д. 29 кор. 4",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Бабушкина, д. 29 кор. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.426585, 59.891748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Ткачей, д.17",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Ткачей, д.17"
            },
            "geometry": {"type": "Point", "coordinates": [30.418627, 59.892548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивная рота",
                "organisation": "Филиал Федерального автономного учреждения Министерства обороны Российской Федерации «Центральный спортивный клуб Армии» (СКА, г. Санкт-Петербург)",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "Ломаная ул., 8\/4, Санкт-Петербург, 196084"
            },
            "geometry": {"type": "Point", "coordinates": [30.325731, 59.892589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Станция технического обслуживания автомобилей по адресу: г. Санкт-Петербург, ул. Маршала Говорова, д. 37, лит. А ",
                "organisation": "Филиал Общества с ограниченной ответственностью «Рускон» в г. Санкт-Петербург",
                "type": "Другое",
                "category": 3,
                "address": "ул. Маршала Говорова, 37, лит. А, Санкт-Петербург, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.282544, 59.892873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Елизарова, д.32а",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Елизарова, д.32а"
            },
            "geometry": {"type": "Point", "coordinates": [30.409935, 59.892958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Ломаная, ул., д. 6",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Ломаная, ул., д. 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.323941, 59.893071]}
        },
        {"type": "Feature",
            "properties": {
                "name": "196084, г. Санкт-Петербург, ул. Ташкентская, д. 1",
                "organisation": "Акционерное общество \"Автопарк № 1 \"Спецтранс\"",
                "type": "Другое",
                "category": 3,
                "address": "196084, г. Санкт-Петербург, ул. Ташкентская, д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.316616, 59.893074]}
        },
        {"type": "Feature",
            "properties": {
                "name": " 192102, Санкт-Петербург, Прогонная улица, дом 3 литера а , б, в, помещения 6,7,8в5н, 3,6,12в7н ",
                "organisation": "Акционерное общество \"Фирма \"Северная\"",
                "type": "Другое",
                "category": 3,
                "address": " 192102, Санкт-Петербург, Прогонная улица, дом 3 литера а , б, в"
            },
            "geometry": {"type": "Point", "coordinates": [30.366089, 59.893271]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория №1 г. Санкт-Петербург",
                "organisation": "ОАО  \"Механический завод\"",
                "type": "Завод ",
                "category": 3,
                "address": "Парковая ул., 6, Санкт-Петербург, 196084"
            },
            "geometry": {"type": "Point", "coordinates": [30.336648, 59.893635]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Елизарова, д.41А",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Елизарова, д.41А"
            },
            "geometry": {"type": "Point", "coordinates": [30.407516, 59.893992]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Обуховской Обороны,  д. 109",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Обуховской Обороны,  д. 109"
            },
            "geometry": {"type": "Point", "coordinates": [30.436133, 59.894432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Детский пер., д. 4 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Детский пер., д. 4 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.313721, 59.894674]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Крыленко, д.4",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Крыленко, д.4"
            },
            "geometry": {"type": "Point", "coordinates": [30.454041, 59.894764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 106",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 106"
            },
            "geometry": {"type": "Point", "coordinates": [30.320007, 59.895406]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 10 компенсирующего вида Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "пр. Елизарова, 21, корп. 2, Санкт-Петербург, 192029"
            },
            "geometry": {"type": "Point", "coordinates": [30.418188, 59.89576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК в г. Ломоносов, Ораниенбаумский пр., д. 40, лит. А",
                "organisation": "Санкт-Петербургское государственное автономное учреждение «Дирекция по управлению спортивными сооружениями»",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "г. Ломоносов, Ораниенбаумский пр., д. 40, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [29.777547, 59.896065]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Канонерский остров, д. 5",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 3,
                "address": "Канонерский остров, д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.210963, 59.896071]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 104",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 104"
            },
            "geometry": {"type": "Point", "coordinates": [30.321647, 59.896313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завод ТЭМП",
                "organisation": "Открытое акционерное общество  «Санкт-Петербургский Завод Точных Электромеханических Приборов»",
                "type": "Завод",
                "category": 3,
                "address": "ул. Швецова, 23, Санкт-Петербург, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.284743, 59.896567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственное здание",
                "organisation": "Общество с ограниченной ответственностью \"Петербургская скульптура\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Салова, 29, Санкт-Петербург, 192102"
            },
            "geometry": {"type": "Point", "coordinates": [30.374211, 59.897228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Ольминского, д. 25",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Ольминского, д. 25"
            },
            "geometry": {"type": "Point", "coordinates": [30.416192, 59.897762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №3",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Московский пр., д.102а"
            },
            "geometry": {"type": "Point", "coordinates": [30.319473, 59.897829]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Грузинская ул., д. 10 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Грузинская ул., д. 10 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.361732, 59.898575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Научно-Исследовательский Изыскательский Институт",
                "organisation": "Акционерное общество \"Санкт-Петербургский Научно-Исследовательский Изыскательский Институт \"Энергоизыскания\"",
                "type": "Другое",
                "category": 3,
                "address": "192029, город Санкт-Петербург, улица Бабушкина, 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.418446, 59.898785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Воздухоплавательная ул., д. 13",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Воздухоплавательная ул., д. 13"
            },
            "geometry": {"type": "Point", "coordinates": [30.340806, 59.899585]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, ул. Салова, д. 20",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Салова, д. 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.366452, 59.899737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производственная площадка",
                "organisation": "Санкт-Петербургский филиал \"Ленинградское отделение научно-исследователького института радио\" ",
                "type": "Другое",
                "category": 3,
                "address": "Большой Смоленский пр., 4, Санкт-Петербург, 192029"
            },
            "geometry": {"type": "Point", "coordinates": [30.421101, 59.900273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Многоквартирный дом со встроенно-пристроенными помещениями, встроенно-пристроенным объектом дошкольного образования, встроенно-пристроенным подземным гаражом, расположенный по строительному адресу: г. Санкт-Петербург, Дальневосточный пр., дом 15, литера А, участок 1",
                "organisation": "Общество с ограниченной ответственностью «ГСК Групп»",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, Дальневосточный пр., дом 15, литера А, участок 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.458314, 59.90038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "192007, Санкт-Петербург,ул.Камчатская,дом 12,литер А ",
                "organisation": "общество с ограниченной ответственностью\"СТРОИТЕЛЬНОЕ УПРАВЛЕНИЕ №1011 СПб\"",
                "type": "Другое",
                "category": 3,
                "address": "192007, Санкт-Петербург,ул.Камчатская,дом 12,литер А "
            },
            "geometry": {"type": "Point", "coordinates": [30.352447, 59.900387]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Проектный институт",
                "organisation": "Открытое акционерное общество по изысканиям и проектированию сооружений связи \"ГИПРОСВЯЗЬ-Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Большой Смоленский пр., д. 2, Санкт-Петербург, 192029"
            },
            "geometry": {"type": "Point", "coordinates": [30.421639, 59.900829]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка № 2",
                "organisation": "Филиал Российской академии художеств в г.Санкт-Петербурге \"Творческая мастерская \"Литейный двор\"",
                "type": "Другое",
                "category": 3,
                "address": "Расстанный проезд, 1, г. Санкт-Петербург, 192007"
            },
            "geometry": {"type": "Point", "coordinates": [30.355636, 59.901411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, ул. Метростроевцев., д 14",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Метростроевцев., д 14"
            },
            "geometry": {"type": "Point", "coordinates": [30.285775, 59.902269]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 94-96",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 94-96"
            },
            "geometry": {"type": "Point", "coordinates": [30.319282, 59.902561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Обуховской Обороны, д.56А",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Обуховской Обороны, д.56А"
            },
            "geometry": {"type": "Point", "coordinates": [30.425606, 59.903097]}
        },
        {"type": "Feature",
            "properties": {
                "name": "институт ФГБУ «СПб НИПНИ им. В. М. Бехтерева» ",
                "organisation": "«Санкт-Петербургский научно-исследовательский психоневрологический институт им. В. М. Бехтерева» Министерства здравоохранения и социального развития России",
                "type": "медицинское учреждение ",
                "category": 3,
                "address": "192019, Санкт-Петербург, ул.Бехтерева, 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.404316, 59.903139]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Седова, д. 5а",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Седова, д. 5а"
            },
            "geometry": {"type": "Point", "coordinates": [30.386556, 59.903143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка",
                "organisation": "Акционерное общество «Петербургский фасовочный комбинат»",
                "type": "Комбинат ",
                "category": 3,
                "address": "Киевская ул., 5, Санкт-Петербург, 196084"
            },
            "geometry": {"type": "Point", "coordinates": [30.330821, 59.903645]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Киевская ул., д. 16 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Киевская ул., д. 16 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.326077, 59.904077]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Бехтерева, д. 1 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Бехтерева, д. 1 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.405347, 59.904132]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, улица Книпович,14",
                "organisation": "Филиал \"Гаражный комплекс в г. Санкт-Петербурге \" Федеральное государственное бюджетное учереждение \"Автотранспортный комбинат\" ",
                "type": "Комбинат ",
                "category": 3,
                "address": "Санкт-Петербург, улица Книпович,14"
            },
            "geometry": {"type": "Point", "coordinates": [30.390949, 59.904193]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 2 – 192007, г. Санкт–Петербург, ул. Тосина, д.3, литера А",
                "organisation": "Общество с ограниченной ответственностью «ЛИГА»",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт–Петербург, ул. Тосина, д.3, литера А"
            },
            "geometry": {"type": "Point", "coordinates": [30.343942, 59.904378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Смоленская ул., д. 7",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Смоленская ул., д. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.320995, 59.904657]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Лиговский пр., д. 275 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Лиговский пр., д. 275 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.341039, 59.904914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Расстанная ул., д. 25 корп. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Расстанная ул., д. 25 корп. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.356098, 59.905459]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 434",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Обуховской Обороны, д. 49, к. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.418752, 59.905872]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК Антонова-Овсеенко",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "улица Антонова-Овсеенко, 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.456047, 59.906012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, наб. реки Волковки, д. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, наб. реки Волковки, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.365352, 59.906055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка ООО «Продмастер» по адресу 192019, г. Санкт-Петербург, ул. Книпович, д. 12",
                "organisation": "Общество с ограниченной ответственностью «Продмастер»",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Книпович, д. 12"
            },
            "geometry": {"type": "Point", "coordinates": [30.393163, 59.90628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 1 – 192007, г. Санкт–Петербург, Лиговский пр., д.232, литера А",
                "organisation": "Общество с ограниченной ответственностью «ЛИГА»",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт–Петербург, Лиговский пр., д.232, литера А"
            },
            "geometry": {"type": "Point", "coordinates": [30.344976, 59.906439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Заозерная ул., д. 14",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Заозерная ул., д. 14"
            },
            "geometry": {"type": "Point", "coordinates": [30.324974, 59.906811]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 74 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 74 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.318535, 59.906864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Открытое акционерное общество \"Масложировой комбинат Санкт-Петербурга\"",
                "organisation": "Открытое акционерное общество \"Масложировой комбинат Санкт-Петербурга\"",
                "type": "Комбинат ",
                "category": 3,
                "address": "Масляный пер., 8, Санкт-Петербург, 196084"
            },
            "geometry": {"type": "Point", "coordinates": [30.329414, 59.907491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0460-СПБ - 198098, г. Санкт-Петербург, наб. Обводного канала, 120, литер А",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0460-СПБ - 198098, г. Санкт-Петербург, наб. Обводного канала, 120, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.297514, 59.907496]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Причал, швартовка судов (г. Санкт-Петербург, Петергофское шоссе, д. 77, корп. 4, лит. А)",
                "organisation": "ООО \"СПРУТ\"",
                "type": "Другое",
                "category": 3,
                "address": "Центр делового сотрудничества, Двинская ул., 10\/3, офис 922, Санкт-Петербург, 198035"
            },
            "geometry": {"type": "Point", "coordinates": [30.247505, 59.907646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Московский пр., д. 66 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Московский пр., д. 66 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.320332, 59.908309]}
        },
        {"type": "Feature",
            "properties": {
                "name": "вспомогательное производство г. Санкт-Петербург, ул. Расстанная, д. 10 корп. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Расстанная, д. 10 корп. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.34742, 59.908454]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 437",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Наб. Обводного канала д. 118, к.8"
            },
            "geometry": {"type": "Point", "coordinates": [30.303783, 59.908666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0587-СПБ - г. Санкт-Петербург, наб. Обводного канала 118 литер С, ТЦ Варшавский экспресс 2 этаж",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0587-СПБ - г. Санкт-Петербург, наб. Обводного канала 118 литер С, ТЦ Варшавский экспресс 2 этаж"
            },
            "geometry": {"type": "Point", "coordinates": [30.307154, 59.908953]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, наб. Обводного канала, д. 90",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, наб. Обводного канала, д. 90"
            },
            "geometry": {"type": "Point", "coordinates": [30.322455, 59.909645]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Двинская ул., д. 14 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Двинская ул., д. 14 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.246016, 59.909754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок \"ПЛП\", Санкт-Петербург, Кировский район, Вольный о-в, д. 4, литера Л, часть земельного участка, кадастровый  №78:15:8112:59",
                "organisation": "Открытое акционерное общество \"ПСКОВВТОРМЕТ\"",
                "type": "Другое",
                "category": 3,
                "address": "Межевой кан., 5, Санкт-Петербург, 198035"
            },
            "geometry": {"type": "Point", "coordinates": [30.250748, 59.911041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка №1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение  детский сад № 104 комбинированного вида Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "193231, Санкт-Петербург, ул. Дыбенко, 36, корпус 2, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.495075, 59.911378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 23 Петродворцового района Санкт-Петербурга",
                "organisation": " ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ ДОШКОЛЬНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ДЕТСКИЙ САД № 23 ПЕТРОДВОРЦОВОГО РАЙОНА САНКТ-ПЕТЕРБУРГА",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Сафронова, 3Б, Санкт-Петербург, 198412"
            },
            "geometry": {"type": "Point", "coordinates": [29.781277, 59.911802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Лиговский пр., д. 170 корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Лиговский пр., д. 170 корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.349082, 59.911876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №303",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Октябрьская наб., д. 12а"
            },
            "geometry": {"type": "Point", "coordinates": [30.423336, 59.912145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральный район теплоснабжения",
                "organisation": "Общество с ограниченной ответственностью \"Петербургтеплоэнерго\"",
                "type": "Другое",
                "category": 3,
                "address": "Дровяная ул., 6-8, Санкт-Петербург, 190103"
            },
            "geometry": {"type": "Point", "coordinates": [30.29278, 59.912421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "192007.г. Санкт-Петербург,  улица  Курская, дом № 21",
                "organisation": "Закрытое  Акционерное Общество  \"Объединение  Союз\"",
                "type": "Другое",
                "category": 3,
                "address": "192007.г. Санкт-Петербург,  улица  Курская, дом № 21"
            },
            "geometry": {"type": "Point", "coordinates": [30.350849, 59.912509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\", филиал \"Октябрьская\"",
                "organisation": "Общество с ограниченной ответственностью \"РОЛЬФ Эстейт Санкт-Петербург\", Филиал \"Октябрьская\"",
                "type": "Автосалон",
                "category": 3,
                "address": "Санкт-Петербург, Октябрьская набережная 8, корпус 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.42003, 59.912517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\", филиал \"Нева\"",
                "organisation": "Общество с ограниченной ответственностью  \"РОЛЬФ Эстейт Санкт-Петербург\", филиал «Нева» ",
                "type": "Автосалон",
                "category": 3,
                "address": "Г. САНКТ-ПЕТЕРБУРГ, ОКТЯБРЬСКАЯ НАБ., Д. 8, КОРП. 3, ЛИТ. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.421881, 59.912678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный  пункт Рыбинская ул.,  д.2",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Рыбинская ул.,  д.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.334761, 59.912709]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный пункт Рижский пр., д.45, лит.А",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Рижский пр., д.45, лит.А"
            },
            "geometry": {"type": "Point", "coordinates": [30.271539, 59.913248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Обуховской Обороны, д.33а",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Обуховской Обороны, д.33а"
            },
            "geometry": {"type": "Point", "coordinates": [30.405672, 59.913572]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Тамбовская ул., д. 14",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Тамбовская ул., д. 14"
            },
            "geometry": {"type": "Point", "coordinates": [30.354313, 59.913683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 1 комбинированного вида Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "Искровский пр., 6-7, Санкт-Петербург, 193313"
            },
            "geometry": {"type": "Point", "coordinates": [30.463847, 59.914599]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургская бамажная фабрика - филиал АО \"Гознак\" (складская база)",
                "organisation": "Санкт-Петербургская бумажная фабрика - филиал акционерного общества \"Гознак\"",
                "type": "Фабрика",
                "category": 3,
                "address": "набережная реки Фонтанки, 144, Санкт-Петербург, 190121"
            },
            "geometry": {"type": "Point", "coordinates": [30.291065, 59.915399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Обуховской Обороны,  д. 23",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Обуховской Обороны,  д. 23"
            },
            "geometry": {"type": "Point", "coordinates": [30.402977, 59.915759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кафе \"Хачапури&Хинкали\", 193168, г. Санкт- Петербург, проспект Большевиков, д. 17, лит. Ч, пом. 31Н, 32Н",
                "organisation": "Общество с ограниченной ответсвенностью \"Колхида\"",
                "type": "Другое",
                "category": 3,
                "address": "кафе \"Хачапури&Хинкали\", 193168, г. Санкт- Петербург, проспект Большевиков, д. 17, лит. Ч, пом. 31Н, 32Н"
            },
            "geometry": {"type": "Point", "coordinates": [30.476839, 59.916542]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стационарный снегоплавильный  пункт Октябрьская набережная, д.2",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Октябрьская набережная, д.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.416993, 59.916806]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС № 350",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Большевиков д.  6"
            },
            "geometry": {"type": "Point", "coordinates": [30.475166, 59.916954]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Профессора Качалова, д. 4",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Профессора Качалова, д. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.399965, 59.917393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственной площадки ул. Зольная, д. 2в (шахта № 441)",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 3,
                "address": "ул. Зольная, д. 2в (шахта № 441)"
            },
            "geometry": {"type": "Point", "coordinates": [30.416696, 59.917468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка по адресу: 193230, г. Санкт-Петербург, пр. Дальневосточный,  дом 3 лит. А",
                "organisation": "Акционерное общество \"Автопарк №7 Спецтранс\"",
                "type": "Другое",
                "category": 3,
                "address": "193230, г. Санкт-Петербург, пр. Дальневосточный,  дом 3 лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.423735, 59.917627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПЭУ №1 (Обслуживание магистральных трубопроводов не осуществляется)",
                "organisation": "Общество с ограниченной ответственностью \"ПетербургГаз\"",
                "type": "Другое",
                "category": 3,
                "address": "191119, Санкт-Петербург, Лиговский проспект, д.124"
            },
            "geometry": {"type": "Point", "coordinates": [30.35254, 59.918452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПЭУ №5 (Обслуживание магистральных трубопроводов не осуществляется)",
                "organisation": "Общество с ограниченной ответственностью \"ПетербургГаз\"",
                "type": "Другое",
                "category": 3,
                "address": "191119, Санкт-Петербург, ул. Роменская д. 9а"
            },
            "geometry": {"type": "Point", "coordinates": [30.36018, 59.918723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 1",
                "organisation": "Гогсударственное бюджетное дошкольное образовательное  учреждение детский сад № 101 общеразвивающего вида с приоритетным осуществлением деятельности по художественно-эстетическому развитию детей Невского района Санкт-Петербурга ",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Антонова-Овсеенко, 25, корп.2А, Санкт-Петербург, 193168"
            },
            "geometry": {"type": "Point", "coordinates": [30.474815, 59.919474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Гранитная ул., д.12",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Гранитная ул., д.12"
            },
            "geometry": {"type": "Point", "coordinates": [30.418995, 59.92039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивно-оздоровительный комплекс",
                "organisation": "Филиал Федерального автономного учреждения Министерства обороны Российской Федерации «Центральный спортивный клуб Армии» (СКА, г. Санкт-Петербург)",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "Санкт-Петербург, ул. Звенигородская, 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.337995, 59.920634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "197198, Санкт-Петербург, ул. Константина Заслонова, дом 7",
                "organisation": "Общество с ограниченной ответственностью «Балт-Клин-Комплект»",
                "type": "Другое",
                "category": 3,
                "address": "197198, Санкт-Петербург, ул. Константина Заслонова, дом 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.350618, 59.920692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦВВС \"Невская волна\"",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "улица Джона Рида, 8к2"
            },
            "geometry": {"type": "Point", "coordinates": [30.453334, 59.920878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кафе \"Хачапури&Хинкали\", 191036, г. Санкт- Петербург, ул. Коллонтай, д. 12, кор. 1, лит. А, пом. 2Н",
                "organisation": "Общество с ограниченной ответсвенностью \"Колхида\"",
                "type": "Другое",
                "category": 3,
                "address": "кафе \"Хачапури&Хинкали\", 191036, г. Санкт- Петербург, ул. Коллонтай, д. 12, кор. 1, лит. А, пом. 2Н"
            },
            "geometry": {"type": "Point", "coordinates": [30.456925, 59.921691]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка №1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение  детский сад № 109 комбинированного вида Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "ул. Подвойского, 48, к. 4, Санкт-Петербург, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.497758, 59.922311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ФОК Юность",
                "organisation": "Региональное общественное учреждение \"Санкт-Петербургский физкультурно-оздоровительный комплекс \"Юность\"",
                "type": "Спортивное объединение",
                "category": 3,
                "address": "ул. Правды, 11, Санкт-Петербург, 191119"
            },
            "geometry": {"type": "Point", "coordinates": [30.339812, 59.923106]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Служба электрохозяйства (СЭХ)",
                "organisation": "Акционерное общество «Теплосеть Санкт-Петербурга»",
                "type": "Котельная",
                "category": 3,
                "address": "191119, Санкт-Петербург, ул. Черняховского, д. 36"
            },
            "geometry": {"type": "Point", "coordinates": [30.358115, 59.923175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка №1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение  детский сад № 23 комбинированного вида Невского района Санкт-Петербурга ",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "пр. Солидарности, 8, к. 2, Санкт-Петербург, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.492991, 59.923848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "188512, Санкт-Петербург, г.Ломоносов, ул.Черникова, д.32, Лит.А5",
                "organisation": "Закрытое акционерное общество \"Лендорстройтехсервис\"",
                "type": "Другое",
                "category": 3,
                "address": "188512, Санкт-Петербург, г.Ломоносов, ул.Черникова, д.32, Лит.А5"
            },
            "geometry": {"type": "Point", "coordinates": [29.690722, 59.92389]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка  Угольная ул., д. 1",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 3,
                "address": "Угольная ул., д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [29.776187, 59.924177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская «Буше» Разъезжая",
                "organisation": "Общество с ограниченной ответственностью «Петербургский коммерческий дом «Венец»",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Разъезжая ул., 13"
            },
            "geometry": {"type": "Point", "coordinates": [30.345465, 59.924752]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка №1",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение  детский сад №37 общеразвивающего вида с приоритетным осуществлением деятельности по физическому развитию детей Невского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "Товарищеский пр., 2, Санкт-Петербург, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.480172, 59.924773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 110\/35\/6 №36 \"Бородинская\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Электрическая подстанция",
                "category": 3,
                "address": "Санкт-Петербург, ул. Бородинская, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.331936, 59.925155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 2 (195213 ,г. Санкт-Петербург ,ул. Гранитная , дом 51)",
                "organisation": "Открытое Акционерное Общество \"Юпитер-Н\"",
                "type": "Другое",
                "category": 3,
                "address": "195213 ,г. Санкт-Петербург ,ул. Гранитная , дом 51"
            },
            "geometry": {"type": "Point", "coordinates": [30.430808, 59.925359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская \"Буше\" Пик Сенная",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский коммерческий дом \"Венец\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "ул. Ефимова, 2, Санкт-Петербург, 190031"
            },
            "geometry": {"type": "Point", "coordinates": [30.320089, 59.926208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центр  социальной реабилитации ",
                "organisation": "Санкт-петербургское государственное автономное стационарное учреждение социального обслуживания \"Психоневрологический интернат № 10\" им.  В.Г.Горденчука",
                "type": "Учреждение социального обслуживания",
                "category": 3,
                "address": "ул. Коллонтай, 36, Санкт-Петербург, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.493028, 59.927078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Ванеева, д.3",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Ванеева, д.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.444203, 59.92777]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производство ООО \"ИСС\", г. Санкт-Петербург, ул. Ванеева, 6",
                "organisation": "Общество с ограниченной ответственностью \"Известковые строительные смеси\"",
                "type": "Другое",
                "category": 3,
                "address": "производство ООО \"ИСС\", г. Санкт-Петербург, ул. Ванеева, 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.440385, 59.92829]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка 1",
                "organisation": "Санкт-Петербургское бюджетное автотранспортное учреждение «Смольнинское»",
                "type": "Другое",
                "category": 3,
                "address": "Херсонский пр-д, 2, 345, Санкт-Петербург, 191167"
            },
            "geometry": {"type": "Point", "coordinates": [30.383806, 59.928373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Новочеркасский пр., д.41\/14",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Новочеркасский пр., д.41\/14"
            },
            "geometry": {"type": "Point", "coordinates": [30.410375, 59.929977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Стахановцев, д.2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Стахановцев, д.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.406353, 59.930401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"Медси Санкт-Петербург\"",
                "organisation": "Общество с ограниченной ответственностью \"Медси Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Марата, 6 лит. А, Санкт-Петербург, 191025"
            },
            "geometry": {"type": "Point", "coordinates": [30.35507, 59.930563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Стахановцев, д.4а",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Стахановцев, д.4а"
            },
            "geometry": {"type": "Point", "coordinates": [30.407394, 59.930586]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №344",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Санкт-Петербург, Малоохтинский проспект, 59а"
            },
            "geometry": {"type": "Point", "coordinates": [30.399776, 59.930699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская \"Буше\" Марата",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский коммерческий дом \"Венец\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Марата ул., д.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.354707, 59.931072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Новочеркасский пр., д.37 кор. 1",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Новочеркасский пр., д.37 кор. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.408862, 59.931145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Банк",
                "organisation": "Публичное  акционерное общество \"Банк \"Санкт-Петербург\"",
                "type": "Банк",
                "category": 3,
                "address": "Малоохтинский пр., 64А, Санкт-Петербург, 195112"
            },
            "geometry": {"type": "Point", "coordinates": [30.403075, 59.931585]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Имущественный комплекс",
                "organisation": "Общество с ограниченной ответственностью \"Бонава Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Невский пр., 114-116, лит. А, Санкт-Петербург, 191025"
            },
            "geometry": {"type": "Point", "coordinates": [30.35918, 59.931906]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ФКУ «Исправительная колония № 7» УФСИН по г.Санкт-Петербургу и Ленинградской области",
                "organisation": "Федеральное казенное учреждение \"Исправительная колония № 7 Федеральной службы исполнения наказаний по г.Санкт-Петербургу и Ленинградской области\"",
                "type": "Учреждение ФСИН",
                "category": 3,
                "address": "улица Латышских Стрелков, 22Р"
            },
            "geometry": {"type": "Point", "coordinates": [30.448039, 59.932061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЛФП",
                "organisation": "Общественная организация Межрегиональное Санкт – Петербурга и Ленинградской области объединение организаций профсоюзов «Ленинградская Федерация Профсоюзов»",
                "type": "Другое",
                "category": 3,
                "address": "пл. Труда, 4, Санкт-Петербург, 190098"
            },
            "geometry": {"type": "Point", "coordinates": [30.292816, 59.932534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филиал АО \"СЖС Восток Лимитед\" в г. Санкт-Петербурге",
                "organisation": "Акционерное Общество \"СЖС Восток Лимитед\" ",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. 5-я Советская, д.44"
            },
            "geometry": {"type": "Point", "coordinates": [30.375521, 59.932959]}
        },
        {"type": "Feature",
            "properties": {
                "name": "«Реконструкция Центра современного искусства им. С. Курёхина» по адресу: г. Санкт-Петербург, Васильевский остров, Средний пр., д 93",
                "organisation": "Общество с ограниченной ответсвенностью «ЯРОС Групп»",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, Васильевский остров, Средний пр., д 93"
            },
            "geometry": {"type": "Point", "coordinates": [30.24581, 59.934034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Адмиралтейский район теплоснабжения",
                "organisation": "Общество с ограниченной ответственностью \"Петербургтеплоэнерго\"",
                "type": "Другое",
                "category": 3,
                "address": "Галерная ул., дом 20-22, Санкт-Петербург, 190000"
            },
            "geometry": {"type": "Point", "coordinates": [30.296702, 59.934078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Уткин пр., д.13 кор.5",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Уткин пр., д.13 кор.5"
            },
            "geometry": {"type": "Point", "coordinates": [30.433627, 59.934528]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Республиканская, д.18 кор. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Республиканская, д.18 кор. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.4108, 59.934622]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Новочеркасский пр., д.29 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Новочеркасский пр., д.29 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.406626, 59.93476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "площадка АО \"Гипроспецгаз\" по адресу: Санкт-Петербург, Суворовский проспект, д.16\/13",
                "organisation": "Акционерное общество \"Гипроспецгаз\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, Суворовский проспект, д.16\/13"
            },
            "geometry": {"type": "Point", "coordinates": [30.370484, 59.935072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Поликлиника №1 ",
                "organisation": "Федеральное казенное учреждение здравоохранения «Медико-санитарная часть Министерства внутренних дел Российской Федерации по г. Санкт-Петербургу и Ленинградской области»",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "Малая Морская улица, 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.312647, 59.935395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №534",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Средний пр. В.О.,д. 91, к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.249994, 59.935808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская «Буше» Малая Морская",
                "organisation": "Общество с ограниченной ответственностью «Петербургский коммерческий дом «Венец»",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Малая Морская ул., 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.313316, 59.936034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "БТиО \"Президент Отель\"",
                "organisation": "Открытое акционерное общество \"Совет по туризму и экскурсиям Санкт-Петербурга\"",
                "type": "Другое",
                "category": 3,
                "address": "Итальянская ул., 3, Санкт-Петербург, 191186"
            },
            "geometry": {"type": "Point", "coordinates": [30.328936, 59.93684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная",
                "organisation": "Санкт-Петербургская таможня",
                "type": "Котельная",
                "category": 3,
                "address": "9-я лин. B.O., 10, Санкт-Петербург, 199034"
            },
            "geometry": {"type": "Point", "coordinates": [30.281506, 59.937411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "195027, Санкт-Петербург, Магнитогорская ул., д.17",
                "organisation": "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬ \"МАГИСТРАЛЬ\"",
                "type": "Другое",
                "category": 3,
                "address": "195027, Санкт-Петербург, Магнитогорская ул., д.17"
            },
            "geometry": {"type": "Point", "coordinates": [30.42479, 59.937809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Малоохтинский пр., д.12 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Малоохтинский пр., д.12 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.405464, 59.938294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Малоохтинский пр., д.55 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Малоохтинский пр., д.55 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.405464, 59.938294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цирк",
                "organisation": "Филиал Федерального казенного предприятия «Российская государственная цирковая компания» «Большой Санкт-Петербургский государственный цирк»",
                "type": "Другое",
                "category": 3,
                "address": "набережная реки Фонтанки, 3, лит.А, Санкт-Петербург, 191028"
            },
            "geometry": {"type": "Point", "coordinates": [30.341173, 59.938575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "образовательное производство",
                "organisation": "Санкт-Петербургское государственное автономное образовательное учреждение высшего образования \"Санкт-Петербургский государственный институт психологии и социальной работы\"",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "12-я лин. В.О., 13 лит. А, Санкт-Петербург, 199178"
            },
            "geometry": {"type": "Point", "coordinates": [30.274533, 59.938631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кафе Хачапури&Хинкали 191036, г. Санкт- Петербург, Суворовский проспект, д. 1\/8, лит. А, пом. 8-Н",
                "organisation": "Общество с ограниченной ответсвенностью \"Колхида\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "кафе Хачапури&Хинкали 191036, г. Санкт- Петербург, Суворовский проспект, д. 1\/8, лит. А, пом. 8-Н"
            },
            "geometry": {"type": "Point", "coordinates": [30.365603, 59.939938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0539-СПБ -195027, г. Санкт-Петербург, Брантовская дор., 3",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0539-СПБ -195027, г. Санкт-Петербург, Брантовская дор., 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.416349, 59.939957]}
        },
        {"type": "Feature",
            "properties": {
                "name": "191014, г. Санкт-Петербург, ул. Парадная д. 8",
                "organisation": "Акционерное общество \"Центральный Научно-Исследовательский Институт Материалов\"",
                "type": "Другое",
                "category": 3,
                "address": "191014, г. Санкт-Петербург, ул. Парадная д. 8"
            },
            "geometry": {"type": "Point", "coordinates": [30.371106, 59.94015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Закрытый плавательный бассейн на Гаванской",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "Гаванская улица, 53"
            },
            "geometry": {"type": "Point", "coordinates": [30.238284, 59.940371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская «Буше» Парадный квартал",
                "organisation": "Общество с ограниченной ответственностью «Петербургский коммерческий дом «Венец»",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Парадная ул., 3 к.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.366371, 59.941482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская «Буше» Средний пр.",
                "organisation": "Общество с ограниченной ответственностью «Петербургский коммерческий дом «Венец»",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Средний пр. В.О., 33"
            },
            "geometry": {"type": "Point", "coordinates": [30.277573, 59.943079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ледовая арена",
                "organisation": "Санкт-Петербургское государственное автномное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Передовиков, 14к2, Санкт-Петербург, 195279"
            },
            "geometry": {"type": "Point", "coordinates": [30.459176, 59.944797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОАО \"Петрохимоптторг\" (Обособленное подразделение ОАО \"Петрохимоптторг\", адрес: Россия, 195248, г. Санкт-Петербург, пр. Энергетиков, д. 19, лит. А)",
                "organisation": "Открытое акционерное общество по поставкам химической и полимерной продукции \"Петрохимоптторг\"",
                "type": "Другое",
                "category": 3,
                "address": "195248, г. Санкт-Петербург, пр. Энергетиков, д. 19, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.436519, 59.945787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Передающий радиоцентр (ПРЦ) Санкт-Петербургского центра обслуживания воздушного движения",
                "organisation": "Филиал «Аэронавигация Северо-Запада» Федерального государственного унитарного предприятия «Государственная корпорация по организации воздушного движения в Российской Федерации»",
                "type": "Другое",
                "category": 3,
                "address": "10 линия В.О., д. 53 А"
            },
            "geometry": {"type": "Point", "coordinates": [30.268737, 59.946031]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС№510",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Косыгина д. 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.479927, 59.946242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Среднеохтинский пр., д.2б",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Среднеохтинский пр., д.2б"
            },
            "geometry": {"type": "Point", "coordinates": [30.412325, 59.946274]}
        },
        {"type": "Feature",
            "properties": {
                "name": "досугово-развлекательный и спортивно-оздоровительный комплекс \"Июнь\"",
                "organisation": "общество с ограниченной ответственностью \"ТРК-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "195279, г. Санкт-Петербург, Индустриальный пр-кт, д. 24, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.474108, 59.946318]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Территория № 1",
                "organisation": "Администрация Губернатора Санкт - Петербурга",
                "type": "Другое",
                "category": 3,
                "address": "191060, Санкт-Петербург, Смольный"
            },
            "geometry": {"type": "Point", "coordinates": [30.396506, 59.946439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Новомалиновская дорога, 20А",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Другое",
                "category": 3,
                "address": "Новомалиновская дорога, 20А"
            },
            "geometry": {"type": "Point", "coordinates": [30.453171, 59.946501]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиница Radisson Cоня, С.-Петербург",
                "organisation": "Общество с ограниченной ответственностью \"Литейный 5\"",
                "type": "Другое",
                "category": 3,
                "address": "Литейный пр., 5\/19, Санкт-Петербург, 191187"
            },
            "geometry": {"type": "Point", "coordinates": [30.348167, 59.946502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка 2. 199004, Санкт-Петербург, В.О., 7-я линия, д. 70",
                "organisation": "Акционерное общество \"СПБВЕРГАЗ\"",
                "type": "Другое",
                "category": 3,
                "address": "199004, Санкт-Петербург, В.О., 7-я линия, д. 70"
            },
            "geometry": {"type": "Point", "coordinates": [30.274048, 59.946789]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Многофункциональный развлекательный комплекс \"Таврический сад\"",
                "organisation": "Общество с ограниченной ответственностью \"Петербургрегионгаз-Спорт\"",
                "type": "Другое",
                "category": 3,
                "address": "Потёмкинская ул., 4А, Санкт-Петербург, 191123"
            },
            "geometry": {"type": "Point", "coordinates": [30.368971, 59.947101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Конторская ул., д.18 ",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Конторская ул., д.18 "
            },
            "geometry": {"type": "Point", "coordinates": [30.412353, 59.947419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Энтузиастов, д.33, кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Энтузиастов, д.33, кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.479618, 59.949042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Санкт-Петербург, наб. р. Смоленки д.1",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Санкт-Петербург, наб. р. Смоленки д.1"
            },
            "geometry": {"type": "Point", "coordinates": [30.265642, 59.949144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Монетный двор",
                "organisation": "Санкт-Петербургский монетный двор – филиал акционерного общества «Гознак»",
                "type": "Другое",
                "category": 3,
                "address": "Петропавловская крепость, 6, Санкт-Петербург, 197046"
            },
            "geometry": {"type": "Point", "coordinates": [30.313972, 59.949277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "197198, Санкт-Петербург, пр. Добролюбова, дом 16, корп. 2 литер «А», пом.28-Н",
                "organisation": "Общество с ограниченной ответственностью «Балт-Клин-Комплект»",
                "type": "Другое",
                "category": 3,
                "address": "197198, Санкт-Петербург, пр. Добролюбова, дом 16, корп. 2 литер «А», пом.28-Н"
            },
            "geometry": {"type": "Point", "coordinates": [30.294969, 59.950071]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кафе \"Хачапури&Хинкали\", 195297, г. Санкт- Петербург, проспект Энтузиастов, д.33, корп. 1, лит. А, пом. 1Н",
                "organisation": "Общество с ограниченной ответсвенностью \"Колхида\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "кафе \"Хачапури&Хинкали\", 195297, г. Санкт- Петербург, проспект Энтузиастов, д.33, корп. 1, лит. А, пом. 1Н"
            },
            "geometry": {"type": "Point", "coordinates": [30.478726, 59.950157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория № 1 - Обособленное подразделение - административно-лаборатороное здание, г. Санкт-Петербург",
                "organisation": "Федеральное казенное учреждение \"Российская государственная пробирная палата при министерстве финансов Российской Федерации\" (Северо-Западная государственная инспекция пробирного надзора\") ",
                "type": "Другое",
                "category": 3,
                "address": "улица Яблочкова, 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.299506, 59.951264]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Главная водопроводная станция",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 3,
                "address": "Кавалергардская ул., 42, Санкт-Петербург, 191015"
            },
            "geometry": {"type": "Point", "coordinates": [30.382519, 59.951298]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Среднеохтинский пр., д.22",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Среднеохтинский пр., д.22"
            },
            "geometry": {"type": "Point", "coordinates": [30.414415, 59.951693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Футбольный стадион",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "Петровский остров, 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.286234, 59.952194]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пер. Декабристов, 10 к.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пер. Декабристов, 10 к.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.248735, 59.952223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивный комплекс \"Петровский\"",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, 197110"
            },
            "geometry": {"type": "Point", "coordinates": [30.285406, 59.952267]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г.Санкт-Петербург, ул. Железноводская.д.26 к2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г.Санкт-Петербург, ул. Железноводская.д.26 к2"
            },
            "geometry": {"type": "Point", "coordinates": [30.252654, 59.952606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г.Санкт-Петербург, ул. Одоевского,д.23 к.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г.Санкт-Петербург, ул. Одоевского,д.23 к.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.256305, 59.952606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Среднеохтинский пр., д.28\/17",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Среднеохтинский пр., д.28\/17"
            },
            "geometry": {"type": "Point", "coordinates": [30.41442, 59.952696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"Пластик, г. Санкт-Петербург, Ириновский пр., д. 1",
                "organisation": "Общество с органиченной ответственностью \"Пластик\"",
                "type": "Другое",
                "category": 3,
                "address": " г. Санкт-Петербург, Ириновский пр., д. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.448048, 59.95276]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 432",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Большая Пороховская ул., д. 53"
            },
            "geometry": {"type": "Point", "coordinates": [30.43539, 59.953266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. КИМа, д.11 к.3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. КИМа, д.11 к.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.244992, 59.954018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская \"Буше\" Комсомола ",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский коммерческий дом \"Венец\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Комсомола ул., 16"
            },
            "geometry": {"type": "Point", "coordinates": [30.357676, 59.954921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург,ул. Уральская, д.17 корп.3 лит. Е",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург,ул. Уральская, д.17 корп.3 лит. Е"
            },
            "geometry": {"type": "Point", "coordinates": [30.258813, 59.955017]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГАУК \"Театр \"Мюзик-Холл\"",
                "organisation": "Санкт-Петербургское государственное автономное учреждение культуры\"Театр \"Мюзик-Холл\"",
                "type": "Другое",
                "category": 3,
                "address": "Александровский парк, 4М"
            },
            "geometry": {"type": "Point", "coordinates": [30.310271, 59.955189]}
        },
        {"type": "Feature",
            "properties": {
                "name": " Площадка №1 (197198 , г. Санкт-Петербург , ул. Маркина , дом 3 ) ",
                "organisation": "Открытое Акционерное Общество \"Юпитер-Н\"",
                "type": "Другое",
                "category": 3,
                "address": "197198 , г. Санкт-Петербург , ул. Маркина , дом 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.307146, 59.956602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завод прецизионного станкостроения",
                "organisation": "Общество с ограниченной ответственностью \"Санкт-Петербургский завод прецизионного станкостроения\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Комсомола, 1-3 лит Д, Санкт-Петербург, 197342"
            },
            "geometry": {"type": "Point", "coordinates": [30.372366, 59.956786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, пр. Металлистов, д. 60",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, пр. Металлистов, д. 60"
            },
            "geometry": {"type": "Point", "coordinates": [30.420985, 59.957136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "195027, Санкт-Петербург, ул. Синявинская, д. 3, лит «А»",
                "organisation": "Общество с ограниченной ответственностью «Балт-Клин-Комплект»",
                "type": "Другое",
                "category": 3,
                "address": "195027, Санкт-Петербург, ул. Синявинская, д. 3, лит «А»"
            },
            "geometry": {"type": "Point", "coordinates": [30.41282, 59.957272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Финский пер. д. 7 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Финский пер. д. 7 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.353732, 59.957282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Санкт-Петербург, Финский пер. д. 4 а",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Санкт-Петербург, Финский пер. д. 4 а"
            },
            "geometry": {"type": "Point", "coordinates": [30.353644, 59.957911]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК ул. Коммуны",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": " г. Санкт-Петербург, ул. Коммуны, д.47, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.493505, 59.958645]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Боткинская ул. д. 3 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Боткинская ул. д. 3 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.355275, 59.959336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№430",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Ириновский пр., д. 26"
            },
            "geometry": {"type": "Point", "coordinates": [30.476069, 59.959417]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Свердловская наб. д. 14 к. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Свердловская наб. д. 14 к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.380987, 59.959681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "197046, г. Санкт-Петербург, ул., Большая Посадская, дом 16",
                "organisation": "Открытое акционерное общество \"Научно-исследовательский и проектный институт по жилищно-гражданскому строительству-ЛЕННИИПРОЕКТ\"",
                "type": "Другое",
                "category": 3,
                "address": "197046, г. Санкт-Петербург, ул., Большая Посадская, дом 16"
            },
            "geometry": {"type": "Point", "coordinates": [30.325664, 59.959712]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г Астраханская ул., дом 18;   ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г Астраханская ул., дом 18;   "
            },
            "geometry": {"type": "Point", "coordinates": [30.341384, 59.959949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Свердловская наб. д. 38 кор 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Свердловская наб. д. 38 кор 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.401012, 59.959953]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, пр. Мечникова д. 40, лит. А",
                "organisation": "Закрытое акционерное общество \"Фирма \"Модуль-3\"",
                "type": "Другое",
                "category": 3,
                "address": "Пионерская ул., 51, Санкт-Петербург, 197110"
            },
            "geometry": {"type": "Point", "coordinates": [30.284034, 59.960402]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, ул. Пионерская, д. 51 литера А ",
                "organisation": "Общество с ограниченной ответственностью \"Кронверк\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, ул. Пионерская, д. 51 литера А "
            },
            "geometry": {"type": "Point", "coordinates": [30.283172, 59.960563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "195253, г. Санкт-Петербург, шоссе Революции, д. 58",
                "organisation": "Акционерное общество \"Центральный Научно-Исследовательский Институт Материалов\"",
                "type": "Другое",
                "category": 3,
                "address": "195253, г. Санкт-Петербург, шоссе Революции, д. 58"
            },
            "geometry": {"type": "Point", "coordinates": [30.441513, 59.960996]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, шоссе Революции, д. 51",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, шоссе Революции, д. 51"
            },
            "geometry": {"type": "Point", "coordinates": [30.4495, 59.960999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Ириновский пр., д.41, кор. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Ириновский пр., д.41, кор. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.487195, 59.961655]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  ул. Комиссара Смирнова д. 4 в",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Комиссара Смирнова д. 4 в"
            },
            "geometry": {"type": "Point", "coordinates": [30.354047, 59.963158]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Территория Яхт-клуба",
                "organisation": "Частное учреждение ЛФП и ФНПР \"Санкт-Петербургский Речной Яхт-клуб профсоюзов\"",
                "type": "Другое",
                "category": 3,
                "address": "Петровская коса, 9, 2 этаж, Санкт-Петербург, 197110"
            },
            "geometry": {"type": "Point", "coordinates": [30.241417, 59.964019]}
        },
        {"type": "Feature",
            "properties": {
                "name": "195248, Cанкт-Петербург, Уманский пер., д. 73, корп. 3",
                "organisation": "Общество с ограниченной ответственностью \"ЭталонАктив\"",
                "type": "Другое",
                "category": 3,
                "address": "195248, Cанкт-Петербург, Уманский пер., д. 73, корп. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.451247, 59.965206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская «Буше» Льва Толстого",
                "organisation": "Общество с ограниченной ответственностью «Петербургский коммерческий дом «Венец»",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Льва Толстого ул., 1-3"
            },
            "geometry": {"type": "Point", "coordinates": [30.312715, 59.965412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Бобруйская ул. д.13 к. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Бобруйская ул. д.13 к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.352959, 59.965742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "195030,Санкт-Петербург. ул.Химиков, дом 2",
                "organisation": "Общество с ограниченной ответственностью \"АТП Треста \"Сантехмонтаж-62\"",
                "type": "Другое",
                "category": 3,
                "address": "195030,Санкт-Петербург. ул.Химиков, дом 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.468773, 59.966035]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-петербург, Уманский пер.д.70., лит.А",
                "organisation": "Общество с ограниченной ответственностью \"Фортрент\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-петербург, Уманский пер.д.70., лит.А"
            },
            "geometry": {"type": "Point", "coordinates": [30.449962, 59.966063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Пороховые, ул. Красина, д.4",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Пороховые, ул. Красина, д.4"
            },
            "geometry": {"type": "Point", "coordinates": [30.48588, 59.96727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Выборгская наб.,  дом 25 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Выборгская наб.,  дом 25 "
            },
            "geometry": {"type": "Point", "coordinates": [30.338083, 59.967633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Нейшлотский пер., дом 5 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Нейшлотский пер., дом 5 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.345378, 59.967653]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная № - г. Санкт-Петербург, Пороховые, Красина, д.10",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Пороховые, Красина, д.10"
            },
            "geometry": {"type": "Point", "coordinates": [30.488888, 59.967719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Нейшлотский пер., дом 15 б",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Нейшлотский пер., дом 15 б"
            },
            "geometry": {"type": "Point", "coordinates": [30.348382, 59.967961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 35\/6 кВ №11 \"Выборгская\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Электрическая подстанция",
                "category": 3,
                "address": "Санкт-Петербург, Лесной пр., д. 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.348893, 59.968061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 433",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Нейшлотскийпр., д.12"
            },
            "geometry": {"type": "Point", "coordinates": [30.350281, 59.968553]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подстанция 110\/6-10 кВ №24 \"Ржевка\" филиала ПАО \"Ленэнерго\" \"Санкт-Петербургские высоковольтные электрические сети\"",
                "organisation": "Публичное акционерное общество энергетики и электрификации \"Ленэнерго\" ",
                "type": "Электрическая подстанция",
                "category": 3,
                "address": "ул. Красина, 18, Санкт-Петербург, 195043"
            },
            "geometry": {"type": "Point", "coordinates": [30.493909, 59.968706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, ул. Смолячкова, д.4\/2",
                "organisation": "Открытое акционерное общество по переработке пластмасс имени «Комсомольской правды»",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Смолячкова, д.4\/2"
            },
            "geometry": {"type": "Point", "coordinates": [30.337623, 59.970448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  ул. Смолячкова,  дом 5   ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Смолячкова,  дом 5   "
            },
            "geometry": {"type": "Point", "coordinates": [30.339179, 59.971025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петроградский район теплоснабжения",
                "organisation": "Общество с ограниченной ответственностью \"Петербургтеплоэнерго\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Профессора Попова, 33, Санкт-Петербург, 197022"
            },
            "geometry": {"type": "Point", "coordinates": [30.303322, 59.971602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Лесопарковая, д. 14 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Лесопарковая, д. 14 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.514684, 59.97228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Б.Сампсониевский пр., дом 52",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 52"
            },
            "geometry": {"type": "Point", "coordinates": [30.33948, 59.972941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ул. Братская, д. 5",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Братская, д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [30.515785, 59.973467]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Тобольская ул., дом 4  кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Тобольская ул., дом 4  кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.340678, 59.973474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стадион \"Санкт-Петербург\"",
                "organisation": "Общество с ограниченной ответственностью «Зенит-Арена»",
                "type": "Другое",
                "category": 3,
                "address": "Футбольная ал., 1, Санкт-Петербург, 197110"
            },
            "geometry": {"type": "Point", "coordinates": [30.221277, 59.973509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Б.Сампсониевский пр., дом 56 кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 56 кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.339535, 59.973537]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Лесной пр., дом 36  кор.3 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лесной пр., дом 36  кор.3 "
            },
            "geometry": {"type": "Point", "coordinates": [30.344076, 59.974078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербургский теннисный клуб им. В.И.Никифорова",
                "organisation": "Открытое акционерное общество \"Совет по туризму и экскурсиям Санкт-Петербурга\"",
                "type": "Другое",
                "category": 3,
                "address": "наб. Мартынова, 40, Санкт-Петербург, 197110"
            },
            "geometry": {"type": "Point", "coordinates": [30.254174, 59.974346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка 1. 195197, Санкт-Петербург, Полюстровский пр., д. 50",
                "organisation": "Акционерное общество \"СПБВЕРГАЗ\"",
                "type": "Другое",
                "category": 3,
                "address": " 195197, Санкт-Петербург, Полюстровский пр., д. 50"
            },
            "geometry": {"type": "Point", "coordinates": [30.374897, 59.974909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пр.Полюстровский д.71 к. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "пр.Полюстровский д.71 к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.371783, 59.977704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Ковалевская ул., д. 14 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Ковалевская ул., д. 14 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.509556, 59.978127]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Ново-Ковалево, 2-я Поперечная ул., д.32 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Ново-Ковалево, 2-я Поперечная ул., д.32 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.552604, 59.978987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Промплощадка ЗАО «Ржевка-Хлеб» по адресу: 195043, Санкт-Петербург, ул. Челябинская, д. 2.",
                "organisation": "Закрытое акционерное общество «РЖЕВКА-ХЛЕБ»",
                "type": "Другое",
                "category": 3,
                "address": "195043, Санкт-Петербург, ул. Челябинская, д. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.501564, 59.979008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 440",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Лабораторная ул., д. 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.382516, 59.97917]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Литовская ул., дом 2 кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Литовская ул., дом 2 кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.341384, 59.979377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Выборгская наб., дом 59, кор.1",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Выборгская наб., дом 59, кор.1"
            },
            "geometry": {"type": "Point", "coordinates": [30.324462, 59.97992]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Лесной пр.,  дом 37 кор.4  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лесной пр.,  дом 37 кор.4  "
            },
            "geometry": {"type": "Point", "coordinates": [30.346954, 59.980398]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, ул. Ржевская, д.18 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, ул. Ржевская, д.18 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.502823, 59.980579]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин \"Магнит\" Канижа",
                "organisation": "Филиал акционерного общества \"Тандер\" в г. Санкт-Петербург",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "195271, Санкт-Петербург г, Кондратьевский пр-кт, 62, кор. 11"
            },
            "geometry": {"type": "Point", "coordinates": [30.385757, 59.98101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спортивная школа",
                "organisation": "Санкт-Петербургское государственное бюджетное образовательное учреждение дополнительного образования детей специализированная детско-юношеская спортивная школа олимпийского резерва «Комплексная школа высшего спортивного мастерства» ",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "Каменноостровский проспект, 68"
            },
            "geometry": {"type": "Point", "coordinates": [30.297857, 59.981337]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\", филиал \"Автопрайм\"",
                "organisation": "Общество с ограниченной ответственностью \"РОЛЬФ Эстейт Санкт-Петербург\", филиал \"Автопрайм\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, Полюстровский проспект, д. 68А"
            },
            "geometry": {"type": "Point", "coordinates": [30.364036, 59.981979]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Красногвардейский пер. 12 (шахта №415)",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "Красногвардейский пер. 12"
            },
            "geometry": {"type": "Point", "coordinates": [30.323, 59.982002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория № 2",
                "organisation": "Закрытое акционерное общество \"Контейнершипс Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Ушаковская наб., 5, офис 301, Санкт-Петербург, 197342"
            },
            "geometry": {"type": "Point", "coordinates": [30.312409, 59.982193]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК \"Приморец\"",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "территория Старая Деревня"
            },
            "geometry": {"type": "Point", "coordinates": [30.232894, 59.982419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Екатерининский пр., д.7",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Екатерининский пр., д.7"
            },
            "geometry": {"type": "Point", "coordinates": [30.440321, 59.982458]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Б.Сампсониевский пр., дом 72 кор.3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 72 кор.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.334847, 59.98247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Ушаковская наб., дом 7 кор. 4",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Ушаковская наб., дом 7 кор. 4"
            },
            "geometry": {"type": "Point", "coordinates": [30.310976, 59.982731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Санкт-Петербург, Лабораторный пр. д. 18 к. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Санкт-Петербург, Лабораторный пр. д. 18 к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.384891, 59.982846]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Рябовское шоссе, д. 111",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Рябовское шоссе, д. 111"
            },
            "geometry": {"type": "Point", "coordinates": [30.51275, 59.982896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0574-СПБ - г. Санкт-Петербург, Приморский пр-кт, 72, литер А",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0574-СПБ - г. Санкт-Петербург, Приморский пр-кт, 72, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.262808, 59.982905]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  , Лесной пр.,  дом 39  кор.5  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лесной пр.,  дом 39  кор.5  "
            },
            "geometry": {"type": "Point", "coordinates": [30.347885, 59.983152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панельное производство",
                "organisation": "Акционерное общество \"ЮИТ Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Приморский пр., 54, лит. А, Санкт-Петербург, 197374"
            },
            "geometry": {"type": "Point", "coordinates": [30.234617, 59.984093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственно-торговое предприятие",
                "organisation": "Акционерное общество \"Санкт-Петербургское производственно-торговое предприятие \"Медтехника\"",
                "type": "Другое",
                "category": 3,
                "address": "197183, город Санкт-Петербург, улица Савушкина, дом 55 литер а"
            },
            "geometry": {"type": "Point", "coordinates": [30.271993, 59.984352]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производство абразивных изделий",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский абразивный завод \"Ильич\"",
                "type": "Другое",
                "category": 3,
                "address": "Красногвардейский пер., 23, Санкт-Петербург, 197342"
            },
            "geometry": {"type": "Point", "coordinates": [30.324094, 59.984421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Лесной пр.,  дом 59  кор.7  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лесной пр.,  дом 59  кор.7  "
            },
            "geometry": {"type": "Point", "coordinates": [30.345308, 59.984494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторан Бургер Кинг 0492-СПБ - 197374, г. Санкт-Петербург, Приморский пр-кт, 97,  литер А",
                "organisation": "Общество с ограниченной ответственностью \"БУРГЕР РУС\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "Ресторан Бургер Кинг 0492-СПБ - 197374, г. Санкт-Петербург, Приморский пр-кт, 97,  литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.237818, 59.984682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Лесной пр.,  дом 61  кор.3  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лесной пр.,  дом 61  кор.3  "
            },
            "geometry": {"type": "Point", "coordinates": [30.344845, 59.984976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\", Филиал \"Лахта Рено\"",
                "organisation": "Общество с ограниченной ответсвенностью \"РОЛЬФ Эстейт Санкт-Петербург\", Филиал \"Лахта Рено\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Савушкина, 103Б, Санкт-Петербург, 197374"
            },
            "geometry": {"type": "Point", "coordinates": [30.245613, 59.985005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"РОЛЬФ Эстейт Санкт-Петербург\", Филиал \"Лахта\"",
                "organisation": "Общество с ограниченной ответственностью \"РОЛЬФ Эстейт Санкт-Петербург\", филиал \"Лахта\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, ул.Савушкина д.103"
            },
            "geometry": {"type": "Point", "coordinates": [30.245591, 59.985044]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №535 ",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Лабораторный пр., д. 97, лит.А"
            },
            "geometry": {"type": "Point", "coordinates": [30.390321, 59.985704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Б.Сампсониевский пр., дом 75",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 75"
            },
            "geometry": {"type": "Point", "coordinates": [30.335927, 59.986198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка 2 - ООО \"РОЛЬФ Эстейт Санкт-Петербург\", Филиал \"Лахта\"",
                "organisation": "Общество с ограниченной ответственностью \"РОЛЬФ Эстейт Санкт-Петербург\", филиал \"Лахта\"",
                "type": "Другое",
                "category": 3,
                "address": "Стародеревенская ул., д.3, Санкт-Петербург, 197374"
            },
            "geometry": {"type": "Point", "coordinates": [30.244204, 59.986453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №352",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Маршала Блюхера, д. 2, к.7"
            },
            "geometry": {"type": "Point", "coordinates": [30.362111, 59.987096]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ветеринарная станция Приморского района Санкт-Петербурга",
                "organisation": "Ветеринарная станция Кронштадтского, Курортного и Приморского районов",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "Школьная ул., 32, Санкт-Петербург, 197183"
            },
            "geometry": {"type": "Point", "coordinates": [30.278744, 59.987213]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Б.Сампсониевский пр., дом 86 кор.3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 86 кор.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.331942, 59.987305]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"Торговый дом \"Росан СПб\"",
                "organisation": "Общество с ограниченной отвтственностью \"Торговый дом \"Росан Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "Планерная ул., 7, Санкт-Петербург, 197374"
            },
            "geometry": {"type": "Point", "coordinates": [30.233046, 59.987385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №301",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Школьная ул., д. 77"
            },
            "geometry": {"type": "Point", "coordinates": [30.256661, 59.987823]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  ул. Капитана Воронина дом 8  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Капитана Воронина дом 8  "
            },
            "geometry": {"type": "Point", "coordinates": [30.345215, 59.988315]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Липовая аллея, дом 17",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Липовая аллея, дом 17"
            },
            "geometry": {"type": "Point", "coordinates": [30.257092, 59.988547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  1-й Муринский пр., дом 19  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "1-й Муринский пр., дом 19  "
            },
            "geometry": {"type": "Point", "coordinates": [30.344999, 59.989845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Земледельческая ул., дом 2 кор.3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Земледельческая ул., дом 2 кор.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.33006, 59.990063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная наб. Черной речки, дом 14",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "наб. Черной речки, дом 14"
            },
            "geometry": {"type": "Point", "coordinates": [30.29705, 59.990532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная ул. Сабировская дом 41 кор. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Сабировская дом 41 кор. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.280641, 59.990883]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Шафировский пр д. 10",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Шафировский пр д. 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.451865, 59.990951]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Б.Сампсониевский пр., дом 85 кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 85 кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.333968, 59.99109]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория Закрытое акционерное общество \"Санкт-петербургская Образцовая Типография\"",
                "organisation": "Закрытое акционерное общество \"Санкт-Петербургская Образцовая Типография\"",
                "type": "Другое",
                "category": 3,
                "address": "3а 197334 3А, ул. Мебельная, Санкт-Петербург, 197374"
            },
            "geometry": {"type": "Point", "coordinates": [30.244704, 59.991152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Лахтинский пр., д. 98",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лахтинский пр., д. 98"
            },
            "geometry": {"type": "Point", "coordinates": [30.145075, 59.992187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, 2-ой Верхний пер., уч.5 (севернее д.8, лит.М)",
                "organisation": "Закрытое акционерной общество \"Беатон\"",
                "type": "Другое",
                "category": 3,
                "address": "Политехническая ул., 9Б, Санкт-Петербург, 194021"
            },
            "geometry": {"type": "Point", "coordinates": [30.35758, 59.992465]}
        },
        {"type": "Feature",
            "properties": {
                "name": "столовая",
                "organisation": "Государственное бюджетное дошкольное образовательное учреждение детский сад № 1 общеразвивающего вида с приоритетным осуществлением деятельности по познавательно-речевому развитию детей Кронштадтского района Санкт-Петербурга",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "Посадская ул., 43, г. Санкт-Петербург, г. Кронштадт, Санкт-Петербург, 197760"
            },
            "geometry": {"type": "Point", "coordinates": [29.75807, 59.992527]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Лахта, Приморская ул., д. 48а",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лахта, Приморская ул., д. 48а"
            },
            "geometry": {"type": "Point", "coordinates": [30.155874, 59.993139]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Полевая Сабировская ул., дом 42а вспомогательное производство",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Полевая Сабировская ул., дом 42а"
            },
            "geometry": {"type": "Point", "coordinates": [30.275505, 59.993524]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Санкт-Петербург, пр. Непокоренных д. 17  к. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Санкт-Петербург, пр. Непокоренных д. 17  к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.38003, 59.994588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Б.Сампсониевский пр., дом 97",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Б.Сампсониевский пр., дом 97"
            },
            "geometry": {"type": "Point", "coordinates": [30.332766, 59.994747]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Кронштадт, Цитадельское шоссе, д. 5",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Кронштадт, Цитадельское шоссе, д. 5"
            },
            "geometry": {"type": "Point", "coordinates": [29.750886, 59.995525]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка г.Санкт-Петербург, пр.Непокоренных, д.17, Литер А.",
                "organisation": "АКЦИОНЕРНОЕ ОБЩЕСТВО \"Спектр\"",
                "type": "Другое",
                "category": 3,
                "address": "г.Санкт-Петербург, пр.Непокоренных, д.17, Литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.38232, 59.99557]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Сердобольская ул., дом 1 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Сердобольская ул., дом 1 "
            },
            "geometry": {"type": "Point", "coordinates": [30.328883, 59.995661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Лахтинский пр., дом 118 кор.3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Лахтинский пр., дом 118 кор.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.113656, 59.995922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№415",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Непокоренных, д.62"
            },
            "geometry": {"type": "Point", "coordinates": [30.406463, 59.995929]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургский филиал АНО ДПО \"Техническая академия Росатома\"",
                "organisation": "Санкт-Петербургский филиал автономной некоммерческой организации  дополнительного профессионального образования \"Техническая академия Росатома\"",
                "type": "Другое",
                "category": 3,
                "address": "Аэродромная ул., 4, Санкт-Петербург, 197348"
            },
            "geometry": {"type": "Point", "coordinates": [30.295834, 59.996208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "магазин \"Магнит\" Пегения",
                "organisation": "Филиал акционерного общества \"Тандер\" в г. Санкт-Петербург",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "197229, Санкт-Петербург г, Коннолахтинский пр-кт, 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.131169, 59.996384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная   ул.Оптиков, дом 6",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": " ул.Оптиков, дом 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.246101, 59.996573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г. Санкт-Петербург, Андреевская ул., д. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Андреевская ул., д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [29.763844, 59.996888]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводная станция г.Кронштадт",
                "organisation": "Государственное унитарное предприятие  \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Кронштадт, ул. Ленинградская, д.1"
            },
            "geometry": {"type": "Point", "coordinates": [29.785897, 59.997748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка: Бизнес-Центр «Эталон», расположенная по адресу: 197348, Санкт-Петербург, Богатырский проспект, 2, литер А",
                "organisation": "Общество с ограниченной ответственностью \"ЭталонАктив\"",
                "type": "Другое",
                "category": 3,
                "address": "197348, Санкт-Петербург, Богатырский проспект, 2, литер А"
            },
            "geometry": {"type": "Point", "coordinates": [30.294988, 59.999801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пр. Энгельса, дом 4 кор.4",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Энгельса, дом 4 кор.4"
            },
            "geometry": {"type": "Point", "coordinates": [30.326691, 60.000491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Международная гимназия Ольгино",
                "organisation": "Санкт-Петербургский Гуманитарный университет профсоюзов",
                "type": "Другое",
                "category": 3,
                "address": "Хвойная ул., 13 б, г. Санкт-Петербург, пос. Ольгино, Санкт-Петербург, 197229"
            },
            "geometry": {"type": "Point", "coordinates": [30.146105, 60.000595]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК \"Центр плавания\"",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "улица Хлопина, 10Д"
            },
            "geometry": {"type": "Point", "coordinates": [30.378878, 60.000899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК на пр. Испытателей",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "пр. Испытателей, д. 2, к. 3, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.312526, 60.001401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК в г.Кронштадте",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "г. Кронштадт, Цитадельское шоссе, д.28, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [29.736102, 60.001746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пр. Энгельса, дом 17 кор. 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Энгельса, дом 17 кор. 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.330545, 60.001767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Ольгино, Лесная ул., д. 44 лит. В",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Ольгино, Лесная ул., д. 44 лит. В"
            },
            "geometry": {"type": "Point", "coordinates": [30.13906, 60.002061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №442",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Испытателей д. 2а"
            },
            "geometry": {"type": "Point", "coordinates": [30.303938, 60.002222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Учебно-производственная база Филиала ФГУП АТЦ СПб ЦАСПТР \"ЭПРОН\"",
                "organisation": "филиал Федерального государственного унитарного предприятия Аварийно-технический центр Минатома России (г.Санкт-Петербург) Центр Аварийно-спасательных подводно-технических работ \"ЭПРОН\"",
                "type": "Другое",
                "category": 3,
                "address": "Болотная ул., 18, Санкт-Петербург, 194021"
            },
            "geometry": {"type": "Point", "coordinates": [30.354171, 60.002732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пр. Энгельса, дом 22 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Энгельса, дом 22 "
            },
            "geometry": {"type": "Point", "coordinates": [30.324938, 60.003369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Детский санаторий Аврора\"",
                "organisation": "Санкт- Петербургское государственное бюджетное учреждение здравоохранения  \"Детский санаторий  \"Аврора\"",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "Цитадельское ш., 30, Санкт-Петербург, 197760"
            },
            "geometry": {"type": "Point", "coordinates": [29.735675, 60.003533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская \"Буше\" Оптиков",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский коммерческий дом \"Венец\"",
                "type": "Предприятие общественного питания",
                "category": 3,
                "address": "ул. Оптиков, 51к1, Санкт-Петербург, 197082"
            },
            "geometry": {"type": "Point", "coordinates": [30.196162, 60.004233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Санкт-Петербург, Пискаревский пр., д.155 кор.3",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Санкт-Петербург, Пискаревский пр., д.155 кор.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.44882, 60.004924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пр. Энгельса, дом 28 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Энгельса, дом 28 "
            },
            "geometry": {"type": "Point", "coordinates": [30.326338, 60.005249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос. Ольгино, ул. Граничная, д. 21",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Ольгино, ул. Граничная, д. 21"
            },
            "geometry": {"type": "Point", "coordinates": [30.129936, 60.00632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГУДСП \"Курортное\" г. Санкт-Петербург, г. Кронштадт, Кронштадтское шоссе, д.11, лит. Б ",
                "organisation": "Санкт – Петербургское государственное унитарное дорожное специализированное предприятие «Курортное» ",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Кронштадт, Кронштадтское шоссе, д.11, лит. Б "
            },
            "geometry": {"type": "Point", "coordinates": [29.743627, 60.007758]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пр. Тореза, дом 42 кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Тореза, дом 42 кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.348836, 60.007903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "База отдела главного механика",
                "organisation": "Закрытое Акционерное Общество \"Союзтеплострой-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "ул. Бутлерова, 13, Санкт-Петербург, 195256"
            },
            "geometry": {"type": "Point", "coordinates": [30.405798, 60.009241]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Кронштадт, Кронштадтское шоссе, уч. 3 ( у дома 15, лит. И)",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "г. Кронштадт, Кронштадтское шоссе, уч. 3 ( у дома 15, лит. И)"
            },
            "geometry": {"type": "Point", "coordinates": [29.734083, 60.009798]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная - г. Кронштадт, Кронштадское шоссе, д. 15",
                "organisation": "Государственное унитарное предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "г. Кронштадт, Кронштадское шоссе, д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [29.734126, 60.009841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гараж № 2",
                "organisation": "филиал \"ПОСММ\" Государственного унитарного предприятия \"Топливно-энергетический Комплекс Санкт-Петербурга\"",
                "type": "Другое",
                "category": 3,
                "address": "Гжатская улица, 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.382833, 60.010754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос.Лисий Нос, ул.Электропередач, д. 20",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос.Лисий Нос, ул.Электропередач, д. 20"
            },
            "geometry": {"type": "Point", "coordinates": [30.006644, 60.011485]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №386",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "пр. Сизова, д. 19"
            },
            "geometry": {"type": "Point", "coordinates": [30.2764, 60.012007]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Манчестерская ул., дом 14",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Манчестерская ул., дом 14"
            },
            "geometry": {"type": "Point", "coordinates": [30.335181, 60.013467]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная, ул. Гжатская д.24",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "ул. Гжатская д.24"
            },
            "geometry": {"type": "Point", "coordinates": [30.389542, 60.014216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос. Лисий Нос, Большой  пр, д. 19, корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Лисий Нос, Большой  пр, д. 19, корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.019, 60.01441]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос. Лисий Нос, Деловая ул., д.3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Лисий Нос, Деловая ул., д.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.004882, 60.014493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Костромской  пр., дом 11 кор.2 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Костромской  пр., дом 11 кор.2 "
            },
            "geometry": {"type": "Point", "coordinates": [30.318538, 60.015153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№  403",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Северный пр., д.97"
            },
            "geometry": {"type": "Point", "coordinates": [30.432746, 60.015202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос. Лисий Нос, Зеленый пр., д. 47а",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Лисий Нос, Зеленый пр., д. 47а"
            },
            "geometry": {"type": "Point", "coordinates": [30.026197, 60.016468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБСУ СО \"Дом-интернат для престарелых и инвалидов\"",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Дом-интернат для  престарелых и инвалидов\"",
                "type": "Учреждение социального обслуживания",
                "category": 3,
                "address": "Поклонногорская улица, 52"
            },
            "geometry": {"type": "Point", "coordinates": [30.301967, 60.020548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС № 517",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Планерная ул., д. 57, к.1"
            },
            "geometry": {"type": "Point", "coordinates": [30.224717, 60.02203]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК на ул.Главная",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "Главная улица, 24"
            },
            "geometry": {"type": "Point", "coordinates": [30.295232, 60.022212]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, пр. Королева, д. 48, корп. 7",
                "organisation": "Общество с ограниченной ответственностью \"Новая клиника \"АБИА\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, пр. Королева, д. 48, корп. 7"
            },
            "geometry": {"type": "Point", "coordinates": [30.247368, 60.023028]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №351",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Гражданский пр., д. 93"
            },
            "geometry": {"type": "Point", "coordinates": [30.409155, 60.023086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК на ул.Руставели",
                "organisation": "Санкт-Петербургское государственное автономное учреждение \"Дирекция по управлению спортивными сооружениями\"",
                "type": "Другое",
                "category": 3,
                "address": "улица Руставели, 51"
            },
            "geometry": {"type": "Point", "coordinates": [30.435269, 60.023487]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Помещения СПб ГБПОУ \"ПКГХ\"",
                "organisation": "Санкт- Петербургское государственное бюджетное профессиональное образовательное учреждение \"Политехнический колледж городского хозяйства\"",
                "type": "Образовательное учреждение",
                "category": 3,
                "address": "пр. Авиаконструкторов, 28, лит. А, Санкт-Петербург, 197373"
            },
            "geometry": {"type": "Point", "coordinates": [30.228825, 60.023904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Удельный пр., д. 30",
                "organisation": "Государственное Унитарное Предприятие \"Топливно энергетический комплекс Санкт-Петербурга\" Филиал Тепловых сетей",
                "type": "Котельная",
                "category": 3,
                "address": "Удельный пр., д. 30"
            },
            "geometry": {"type": "Point", "coordinates": [30.310929, 60.024365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пр. Тореза, дом 93 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пр. Тореза, дом 93 "
            },
            "geometry": {"type": "Point", "coordinates": [30.324063, 60.027442]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г.Санкт-Петербург,  Автобусная ул., д.9",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г.Санкт-Петербург,  Автобусная ул., д.9"
            },
            "geometry": {"type": "Point", "coordinates": [30.261621, 60.030478]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Ракитовская ул., дом 29  ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Ракитовская ул., дом 29  "
            },
            "geometry": {"type": "Point", "coordinates": [30.307608, 60.030592]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г.Санкт-Петербург, ул. Ново-Никитинская, д .22 (Производственно-складская база)",
                "organisation": "Общество с ограниченной ответственностью \"Капитель\"",
                "type": "Другое",
                "category": 3,
                "address": "г.Санкт-Петербург, ул. Ново-Никитинская, д .22 (Производственно-складская база)"
            },
            "geometry": {"type": "Point", "coordinates": [30.252923, 60.032395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"АБЗ-ВАД\" ОП-Санкт-Петербург ",
                "organisation": "Общество с ограниченной ответственностью \"Асфальтобетонный завод - ВАД\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, ул. Репищева, д. 14, лит. АР"
            },
            "geometry": {"type": "Point", "coordinates": [30.276818, 60.033198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №  306",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Северный пр., д. 32"
            },
            "geometry": {"type": "Point", "coordinates": [30.362603, 60.033475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клинический госпиталь",
                "organisation": "Федеральное казенное учреждение здравоохранения «Медико-санитарная часть Министерства внутренних дел Российской Федерации по г. Санкт-Петербургу и Ленинградской области»",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "проспект Культуры, 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.36089, 60.034494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводная насосная станция пос. Горская",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Сестрорецк, ул. Писемского, д. 8;"
            },
            "geometry": {"type": "Point", "coordinates": [29.988099, 60.035533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  ул., Большая Озерная.,  дом 60",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "ул., Большая Озерная.,  дом 60"
            },
            "geometry": {"type": "Point", "coordinates": [30.304234, 60.038908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Онежский проезд, дом 5 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Онежский проезд, дом 5 "
            },
            "geometry": {"type": "Point", "coordinates": [30.306022, 60.040669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "194356, Санкт-Петербург, Выборгское шоссе, д. 40, лит. А",
                "organisation": "Общество с ограниченной ответственностью \"АГАТ\"",
                "type": "Другое",
                "category": 3,
                "address": "194356, Санкт-Петербург, Выборгское шоссе, д. 40, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.315436, 60.042085]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г.Санкт-Петербург, ул.Заповедная, д.62",
                "organisation": "Открытое акционерное общество \"Резервхлеб\"",
                "type": "Другое",
                "category": 3,
                "address": "г.Санкт-Петербург, ул.Заповедная, д.62"
            },
            "geometry": {"type": "Point", "coordinates": [30.273584, 60.043584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Большая Озерная ул., дом 92",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Большая Озерная ул., дом 92"
            },
            "geometry": {"type": "Point", "coordinates": [30.299668, 60.045219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Заповедная ул., д. 35, корп. 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Заповедная ул., д. 35, корп. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.274609, 60.046893]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СК на ул. Асафьева, д.10, к. 2, лит. А",
                "organisation": "Санкт-Петербургское государственное автономное учреждение «Дирекция по управлению спортивными сооружениями»",
                "type": "Другое",
                "category": 3,
                "address": "ул. Асафьева, д.10, к. 2, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.327484, 60.050439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  Петровская ул., дом 10",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Петровская ул., дом 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.303045, 60.050682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Ново-Александровская  ул., д. 60 кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "Ново-Александровская  ул., д. 60 кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.289561, 60.052754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОАО \"Петрохимоптторг\"(Обособленное подразделение ОАО \"Петрохимоптторг\", адрес: Россия,194292, г. Санкт-Петербург, 1-й Верхний пер., д. 10, корп. 3, лит. А)",
                "organisation": "Открытое акционерное общество по поставкам химической и полимерной продукции \"Петрохимоптторг\"",
                "type": "Другое",
                "category": 3,
                "address": "194292, г. Санкт-Петербург, 1-й Верхний пер., д. 10, корп. 3, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.382088, 60.057444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "194292, г. Санкт-Петербург, 1-й Верхний переулок, д. 6",
                "organisation": "Общество с ограниченной ответственностью \"ГУММИЛАТ\"",
                "type": "Другое",
                "category": 3,
                "address": "194292, г. Санкт-Петербург, 1-й Верхний переулок, д. 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.38005, 60.058059]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №302",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Выборгское шоссе , Д.21"
            },
            "geometry": {"type": "Point", "coordinates": [30.308165, 60.05838]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нововоронежский филиал ФГУП \"Аварийно-технический центр Минатома России\" (г. Санкт-Петербург)",
                "organisation": "Нововоронежский филиал ФГУП \"Аварийно-технический центр Минатома России\" (г. Санкт-Петербург)",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, 3-ий Верхний переулок, д. 2, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.363266, 60.061086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная 3-й Верхний пер., дом 10",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "3-й Верхний пер., дом 10"
            },
            "geometry": {"type": "Point", "coordinates": [30.352365, 60.063427]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОАО \"Петрохимоптторг\" (Обособленное подразделение ОАО \"Петрохимоптторг\", адрес: Россия, 194292, г. Санкт-Петербург, улица Домостроительная, дом №12, литера А)",
                "organisation": "Открытое акционерное общество по поставкам химической и полимерной продукции \"Петрохимоптторг\"",
                "type": "Другое",
                "category": 3,
                "address": "194292, г. Санкт-Петербург, улица Домостроительная, дом №12, литера А"
            },
            "geometry": {"type": "Point", "coordinates": [30.368767, 60.069399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обособленное подразделение ООО \"ЮНГХАЙНРИХ подъемно-погрузочная техника\" в г. Санкт-Петербурге (п. Левашово)",
                "organisation": "Общество с ограниченной ответственностью \"ЮНГХАЙНРИХ подъемно-погрузочная техника\"",
                "type": "Другое",
                "category": 3,
                "address": "Горское шоссе, 171, лит.А"
            },
            "geometry": {"type": "Point", "coordinates": [30.149436, 60.069921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"ЛСР. Строительство-СЗ\" (194292, г. Санкт-Петербург, 5-ый Верхний переулок, дом 12)",
                "organisation": "Общество с ограниченной ответственностью \"ЛСР. Строительство-Северо-Запад\"",
                "type": "Другое",
                "category": 3,
                "address": "194292, г. Санкт-Петербург, 5-ый Верхний переулок, дом 12"
            },
            "geometry": {"type": "Point", "coordinates": [30.364458, 60.070151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"Городская поликлиника №52\"",
                "organisation": "Санкт-Петербургское  государственное бюджетное учреждение здравоохранения \"Городская поликлиника №52\"",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "ул. 1 Мая, 107, корп. 5 г. Санкт-Петербург, Санкт-Петербург, 194294"
            },
            "geometry": {"type": "Point", "coordinates": [30.238026, 60.072704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка: 194292, Санкт-Петербург, ул. Домостроительная, д. 15, лит. А; 194292, Санкт-Петербург, ул. Домостроительная, д. 17, лит. А; 194292, Санкт-Петербург, пр. Культуры, участок 2 (северо-восточнее дома 42, литера Н)",
                "organisation": "Акционерное общество \"ИВ-ПАРНАС\"",
                "type": "Другое",
                "category": 3,
                "address": "194292, город Санкт-Петербург, проспект Культуры, дом 44 литер а, офис 203"
            },
            "geometry": {"type": "Point", "coordinates": [30.378275, 60.072904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос. Парголово, ул.Первого Мая, дом 91 кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, ул.Первого Мая, дом 91 кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.236878, 60.075345]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка в г. Санкт-Петербург",
                "organisation": "Общество с ограниченной ответственностью \"Пивоваренная компания \"Балтика\" завод \"Балтика Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "6-й Верхний пер., 3, Санкт-Петербург, 194292"
            },
            "geometry": {"type": "Point", "coordinates": [30.36535, 60.075999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗС №525",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "Выборгское шоссе д.216, к.3"
            },
            "geometry": {"type": "Point", "coordinates": [30.272352, 60.076495]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производство солода",
                "organisation": "Закрытое акционерное общество \"Солодовенный завод Суффле Санкт-Петербург\"",
                "type": "Другое",
                "category": 3,
                "address": "6-й Верхний пер., 7, Санкт-Петербург, 194292"
            },
            "geometry": {"type": "Point", "coordinates": [30.360092, 60.077263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово, Выборгское шоссе, дом 369б",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, Выборгское шоссе, дом 369б"
            },
            "geometry": {"type": "Point", "coordinates": [30.274279, 60.078014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная пос. Парголово,  ул.,  Вологдина, дом 2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово,  ул.,  Вологдина, дом 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.323402, 60.07899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово, ул., Ломоносова, дом 78 кор.2 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, ул., Ломоносова, дом 78 кор.2 "
            },
            "geometry": {"type": "Point", "coordinates": [30.281371, 60.079399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГУДСП \"Курортное\" г. Санкт – Петербург, г. Сестрорецк, ул. Инструментальщиков, д. 3",
                "organisation": "Санкт – Петербургское государственное унитарное дорожное специализированное предприятие «Курортное» ",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт – Петербург, г. Сестрорецк, ул. Инструментальщиков, д. 3"
            },
            "geometry": {"type": "Point", "coordinates": [29.954093, 60.080634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная 8-й Верхний пер., дом 6",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "8-й Верхний пер., дом 6"
            },
            "geometry": {"type": "Point", "coordinates": [30.367042, 60.080713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово Михайловка, Торфяная  ул., дом 25 кор.2 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово Михайловка, Торфяная  ул., дом 25 кор.2 "
            },
            "geometry": {"type": "Point", "coordinates": [30.242291, 60.081968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПЭУ №4 (Обслуживание магистральных трубопроводов не осуществляется) Сестрорецк",
                "organisation": "Общество с ограниченной ответственностью \"ПетербургГаз\"",
                "type": "Другое",
                "category": 3,
                "address": "197706, г. Сестрорецк, Транспортный переулок д.6\/5"
            },
            "geometry": {"type": "Point", "coordinates": [29.949348, 60.082285]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово, ул. Ломоносова, дом 17",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, ул. Ломоносова, дом 17"
            },
            "geometry": {"type": "Point", "coordinates": [30.266784, 60.082521]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводная станция г.Сестрорецк",
                "organisation": "Государственное унитарное предприятие  \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Сестрорецк, пос. Разлив, ул. Граничная, д. 18;"
            },
            "geometry": {"type": "Point", "coordinates": [29.978745, 60.082772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ААЗС№ 439",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "г. Сестрорецк, Приморское ш., д. 266а"
            },
            "geometry": {"type": "Point", "coordinates": [29.956339, 60.0836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово, Михайловка, ул. Ленина, дом 42, кор.2",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, Михайловка, ул. Ленина, дом 42, кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.249929, 60.085066]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОП АО \"ЛОНМАДИ\" в г. Санк-Петербурге",
                "organisation": "обособленное подразделение закрытое акционерное общество \"ЛОНМАДИ\" в г. Санкт-Петербурге",
                "type": "Другое",
                "category": 3,
                "address": "ул. Михаила Дудина, д.17, Санкт-Петербург, 194292"
            },
            "geometry": {"type": "Point", "coordinates": [30.336831, 60.08518]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово, ул., Шишкина, дом 30 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, ул., Шишкина, дом 30 "
            },
            "geometry": {"type": "Point", "coordinates": [30.270548, 60.08924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин \"Магнит\" Олкуш",
                "organisation": "Филиал акционерного общества \"Тандер\" в г. Санкт-Петербург",
                "type": "Предприятие торговли",
                "category": 3,
                "address": "197701, Санкт-Петербург г, Сестрорецк г, Мосина ул, дом № 61"
            },
            "geometry": {"type": "Point", "coordinates": [29.965059, 60.089265]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, Выборгское шоссе, д.350",
                "organisation": "ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО \"СЕВЕРНОЕ\"",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, Выборгское шоссе, д.350"
            },
            "geometry": {"type": "Point", "coordinates": [30.255943, 60.093278]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"Осиновая роща\" , производственная площадка, Санкт-Петербург, п. Парголово, Пригородный, зем. участок 36, кад. номер 78:1322601:11; земельный участок 37, кад. номер 78:36:1322601:12; земельный участок 39, кад.номер: 78:36:1322601:15",
                "organisation": "Общество с ограниченной ответственностью \"Осиновая роща\"",
                "type": "Другое",
                "category": 3,
                "address": "194362, САНКТ-ПЕТЕРБУРГ Г, ПАРГОЛОВО П, ГОРСКОЕ Ш, ДОМ 6, ЛИТЕР Б"
            },
            "geometry": {"type": "Point", "coordinates": [30.247314, 60.09392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная г.Санкт-Петербург, пос. Левашово, Горское ш., дом 152 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "г.Санкт-Петербург, пос. Левашово, Горское ш., дом 152 "
            },
            "geometry": {"type": "Point", "coordinates": [30.190891, 60.094034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Левашово,  ул. Володарского, дом 72-74 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Левашово,  ул. Володарского, дом 72-74 "
            },
            "geometry": {"type": "Point", "coordinates": [30.203365, 60.097479]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Левашово, ул., Мира, дом 26",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Левашово, ул., Мира, дом 26"
            },
            "geometry": {"type": "Point", "coordinates": [30.212165, 60.100476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "197706, Санкт-Петербург, г. Сестрорецк, наб. Реки Сестры, д. 17 ",
                "organisation": "АКЦИОНЕРНОЕ ОБЩЕСТВО \"СЕСТРОРЕЦКИЙ ХЛЕБОЗАВОД\"",
                "type": "Другое",
                "category": 3,
                "address": "197706, Санкт-Петербург, г. Сестрорецк, наб. Реки Сестры, д. 17 "
            },
            "geometry": {"type": "Point", "coordinates": [29.968612, 60.100481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Левашово,  Первомайская ул.,  дом 37 кор. 2 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Левашово,  Первомайская ул.,  дом 37 кор. 2 "
            },
            "geometry": {"type": "Point", "coordinates": [30.195877, 60.102638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГБУ \"Санкт-Петербургская горветстанция\" по адресу: 198320, Санкт-Петербург, пос. Володарский, ул. Володарского, д.5",
                "organisation": "ГБУ \"Санкт-Петербургская горветстанция\" Володарский ветеринарный участок, 198320, Санкт-Петербург, пос. Володарский, ул. Володарского, д.5",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "ул. Володарского, 5, Санкт-Петербург, 194361"
            },
            "geometry": {"type": "Point", "coordinates": [30.213563, 60.103482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Левашово,  Железнодорожная ул., дом 46",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Левашово,  Железнодорожная ул., дом 46"
            },
            "geometry": {"type": "Point", "coordinates": [30.214103, 60.105385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная  пос. Парголово, Осиновая Роща, Школьный пер., дом 10 ",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников ",
                "type": "Котельная",
                "category": 3,
                "address": "пос. Парголово, Осиновая Роща, Школьный пер., дом 10 "
            },
            "geometry": {"type": "Point", "coordinates": [30.249956, 60.111621]}
        },
        {"type": "Feature",
            "properties": {
                "name": "194362, Санкт-Петербург, пос. Парголово, ул. Ленинградская, д. 99, лит. А",
                "organisation": "Общество с ограниченной ответственностью \"Группа Компаний Испытатель\"",
                "type": "Другое",
                "category": 3,
                "address": "194362, Санкт-Петербург, пос. Парголово, ул. Ленинградская, д. 99, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.211419, 60.11613]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АЗК № 512 ",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Автомобильная заправочная станция",
                "category": 3,
                "address": "г. Сестрорецк, Приморское ш., д. 315"
            },
            "geometry": {"type": "Point", "coordinates": [29.976321, 60.122136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Курортный район теплоснабжения",
                "organisation": "Общество с ограниченной ответственностью \"Петербургтеплоэнерго\"",
                "type": "Другое",
                "category": 3,
                "address": "пос, Восточная ул., 6, Санкт-Петербург, 197730"
            },
            "geometry": {"type": "Point", "coordinates": [29.998225, 60.145216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка по адресу: Санкт-Петербург, Курортный район поселок Солнечное, Приморское  шоссе  д. 376 лит. П",
                "organisation": "Общество с ограниченной ответственностью «Виктория»",
                "type": "Другое",
                "category": 3,
                "address": "Санкт-Петербург, Курортный район поселок Солнечное, Приморское  шоссе  д. 376 лит. П"
            },
            "geometry": {"type": "Point", "coordinates": [29.920758, 60.149751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиница \"Репинская\"",
                "organisation": "Открытое акционерное общество \"Совет по туризму и экскурсиям Санкт-Петербурга\"",
                "type": "Другое",
                "category": 3,
                "address": "пос, Приморское ш., 428, Санкт-Петербург, 197738"
            },
            "geometry": {"type": "Point", "coordinates": [29.85552, 60.162927]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная Санкт-Петербург, Большой пр. д. 7\/4 кор 3",
                "organisation": "Государственное унитарное предприятие \"Топливно-энергетический комплекс Санкт-Петербурга\" Филиал энергетических источников",
                "type": "Котельная",
                "category": 3,
                "address": "Санкт-Петербург, Большой пр. д. 7\/4 кор 3"
            },
            "geometry": {"type": "Point", "coordinates": [29.810315, 60.180174]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПбГБСУСО \"Дом-интернат \"Красная Звезда\"",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Дом-интернат ветеранов войны и труда \"Красная Звезда\"",
                "type": "Учреждение социального обслуживания",
                "category": 3,
                "address": "посёлок Смолячково, Курортный район, Санкт-Петербург, Россия, 197729, Приморское шоссе, 676"
            },
            "geometry": {"type": "Point", "coordinates": [29.50098, 60.181614]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка ООО «Лайф Энерджи Санкт-Петербург»",
                "organisation": "Общество с ограниченной ответственностью «Лайф Энерджи Санкт-Петербург»",
                "type": "Другое",
                "category": 3,
                "address": "ЗЕЛЕНОГОРСК, ШОССЕ ПРИМОРСКОЕ, ДОМ 502А, ЛИТЕР В"
            },
            "geometry": {"type": "Point", "coordinates": [29.753995, 60.184573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отдел вневедомственной охраны",
                "organisation": "Отдел вневедомственной охраны по Курортному району г. Санкт-Петербурга – филиал федерального государственного казенного учреждения «Управление вневедомственной охраны войск национальной гвардии Российской Федерации по г. Санкт-Петербургу и Ленинградской области",
                "type": "Другое",
                "category": 3,
                "address": "ул. Пушкина, 10, пос. Комарово, Санкт-Петербург, 197733"
            },
            "geometry": {"type": "Point", "coordinates": [29.808123, 60.187914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГУДСП \"Курортное\" г. Санкт-Петербург, г. Зеленогорск, Морская ул., д.10 , литера А",
                "organisation": "Санкт – Петербургское государственное унитарное дорожное специализированное предприятие «Курортное» ",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт-Петербург, г. Зеленогорск, Морская ул., д.10 , литера А"
            },
            "geometry": {"type": "Point", "coordinates": [29.719306, 60.188602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопроводная станция г. Зеленогорск",
                "organisation": "Государственное унитарное предприятие  \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 3,
                "address": "г. Санкт-Петербург ,г. Зеленогорск, ул. Кавалерийская, д.4;"
            },
            "geometry": {"type": "Point", "coordinates": [29.694101, 60.193142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Психоневрологический интернат № 1",
                "organisation": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания \"Психоневрологический интернат №1\"",
                "type": "Медицинское учреждение",
                "category": 3,
                "address": "Зеленогорск, Санкт-Петербург, Россия, 197720, улица Мира, 6"
            },
            "geometry": {"type": "Point", "coordinates": [29.68956, 60.199316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГУДСП \"Курортное\" г. Санкт – Петербург, г. Зеленогорск, ул. Конная, д. 18",
                "organisation": "Санкт – Петербургское государственное унитарное дорожное специализированное предприятие «Курортное» ",
                "type": "Другое",
                "category": 3,
                "address": "г. Санкт – Петербург, г. Зеленогорск, ул. Конная, д. 18"
            },
            "geometry": {"type": "Point", "coordinates": [29.696017, 60.202876]}
        }
    ]
}
