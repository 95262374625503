export const stadiums = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Футбольная аллея, 1",
                "name": "Газпром Арена",
                "yand_rating": null,
                "2gis_rating": 3.7,
                "opening_hours": null,
                "contact_website": "gazprom-arena.com",
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.219218, 59.973149]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Футбольная аллея, 8",
                "name": "СИБУР АРЕНА",
                "yand_rating": null,
                "2gis_rating": 4.3,
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_website": "siburarena.com",
                "contact_phone": "+7 (812) 456?08?00",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.226603, 59.971317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "улица Бутлерова, 9",
                "name": "Зенит",
                "yand_rating": null,
                "2gis_rating": 5.0,
                "opening_hours": "Mo-Su 08:30-24:00",
                "contact_website": null,
                "contact_phone": "+7 (812) 535?01?71",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.399717, 60.001721]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Петровский остров, 2",
                "name": "Петровский",
                "yand_rating": null,
                "2gis_rating": 3.9,
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_website": null,
                "contact_phone": "+7 (812) 232?16?22",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.285505, 59.952281]}
        },
        {"type": "Feature",
            "properties": {
                "address": "проспект Динамо, 44А",
                "name": "Динамо",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": "+7 (812) 235?30?65",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.26541, 59.968254]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Левашовский проспект, 11\/7",
                "name": "Метрострой",
                "yand_rating": null,
                "2gis_rating": 4.0,
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_website": "npskmetro.business.site",
                "contact_phone": "+7 (812) 454?04?96",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.289997, 59.966615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Политехническая, 27",
                "name": "Политехник",
                "yand_rating": null,
                "2gis_rating": 4.3,
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_website": "sport.spbstu.ru",
                "contact_phone": "+7 (812) 244?64?04",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.369084, 60.002733]}
        },
        {"type": "Feature",
            "properties": {
                "address": "улица Жака Дюкло, 66",
                "name": "Озерки Арена",
                "yand_rating": null,
                "2gis_rating": 5.0,
                "opening_hours": "24.7",
                "contact_website": null,
                "contact_phone": "+7 (812) 952?99?19",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.334733, 60.024859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "улица Латышских Стрелков, 19Д",
                "name": "F-Base",
                "yand_rating": 4.5,
                "2gis_rating": 4.7,
                "opening_hours": "Mo-Su 07:00-24:00",
                "contact_website": "www.f-base.ru",
                "contact_phone": "+7?953?144?00?11",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.451147, 59.931587]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Обуховской Обороны проспект, 105в",
                "name": "Форвард",
                "yand_rating": 4.3,
                "2gis_rating": 5.0,
                "opening_hours": "Mo-Su 09:00-03:00",
                "contact_website": "centerfootballspb.ru",
                "contact_phone": "+7 (812) 365?41?31",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.435501, 59.894921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Перекопская улица, 6-8",
                "name": "Кировец",
                "yand_rating": null,
                "2gis_rating": 4.5,
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_website": "www.stdkirovets.ru",
                "contact_phone": "+7 (812) 401?46?20",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.268177, 59.900416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Малый проспект Васильевского острова, 66",
                "name": "Центр физической культуры, спорта и здоровья Василеостровского района",
                "yand_rating": 4.4,
                "2gis_rating": 3.0,
                "opening_hours": "Mo-Fr 09:00-13:00 14:00-18:00",
                "contact_website": "www.centrsporta.ru",
                "contact_phone": "+7 (812) 322?69?65",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.248497, 59.9391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Жака Дюкло, 20г",
                "name": "Сосновка Парк",
                "yand_rating": null,
                "2gis_rating": 3.5,
                "opening_hours": null,
                "contact_website": "sosnovkaschool.ru",
                "contact_phone": "+7?931?005?32?81",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.335951, 60.025585]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Киевская улица, 5БА",
                "name": "Комфорт",
                "yand_rating": null,
                "2gis_rating": 5.0,
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "komfortarena.ru",
                "contact_phone": "+7 (812) 493?40?69",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.329918, 59.89934]}
        },
        {"type": "Feature",
            "properties": {
                "address": "улица Шкапина, 52",
                "name": "Лига5",
                "yand_rating": 4.2,
                "2gis_rating": null,
                "opening_hours": "Mo-Su 09:00-24:00",
                "contact_website": "liga5football.ru",
                "contact_phone": "+7 (812) 920?22?67",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.292014, 59.90216]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Выборгское шоссе, 132",
                "name": "Зима-Лето",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": "Mo-Su 10:00-24:00",
                "contact_website": "zimaletoarena.ru",
                "contact_phone": "+7 (812) 244?16?04",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.305618, 60.058354]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Моисеенко, 39 лит Б",
                "name": "Ангар",
                "yand_rating": null,
                "2gis_rating": 3.0,
                "opening_hours": "24.7",
                "contact_website": "angarfutzal.com",
                "contact_phone": "+7?931?308?36?39",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.391183, 59.936147]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Металлистов проспект, 28",
                "name": "Стадион",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": "zenit.kfis.spb.ru",
                "contact_phone": "+7?905?252?19?19",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.41877, 59.94718]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Тулонская аллея, 11",
                "name": "Кронштадтский городской стадион",
                "yand_rating": null,
                "2gis_rating": 4.7,
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_website": null,
                "contact_phone": "+7 (812) 311?43?82",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.798744, 59.985548]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Садовая, 4а",
                "name": "Искра",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": "Mo-Fr 06:00-22:00",
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.337221, 59.938586]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Объездная, 8а",
                "name": "Спартак, стадион",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.717378, 60.195248]}
        },
        {"type": "Feature",
            "properties": {
                "address": "?Лесная, 9",
                "name": "Луч",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": "24.7",
                "contact_website": null,
                "contact_phone": "+7 (812) 433?45?04",
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.337221, 59.938586]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.281242, 59.956531]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.284801, 59.956861]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Монолит",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.350212, 59.964189]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Малая спортивная арена «Петровский»",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.282222, 59.952704]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Химик",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.494786, 59.962746]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Московская застава",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.351797, 59.849133]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Динамо",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.268422, 59.966758]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Арсенал",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.415467, 59.967881]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Турбостроитель",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.418492, 59.947919]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Красный Октябрь",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.376484, 59.998061]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Кировец",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.269257, 59.900882]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Адмиралтеец",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.256612, 59.894835]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.494949, 59.96288]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.495665, 59.956539]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Приморец",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.233534, 59.982306]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Московский",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.322043, 59.896415]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.230224, 59.952772]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Алмаз-Антей",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.483873, 59.849518]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Спорткомплекс имени В.И. Алексеева",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.359772, 60.012855]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Коломяги-спорт",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.299426, 60.01438]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Шторм",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.278111, 59.84285]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Красный треугольник",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.25667, 59.902364]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Локомотив",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.343693, 59.919137]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Азбука спорта",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.250127, 59.948627]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Мототрек",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.331758, 60.0253]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.378635, 60.036501]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.335666, 60.004827]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.40953, 59.957315]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Ракета",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.924246, 59.874025]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.871048, 59.8872]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.869108, 59.889138]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Спартак",
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.764767, 59.90816]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [29.777362, 59.902298]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.067966, 59.723522]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.445309, 59.689886]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "yand_rating": null,
                "2gis_rating": null,
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null,
                "type": "Стадион",
                "city_function": "Спорт"
            },
            "geometry": {"type": "Point", "coordinates": [30.438342, 59.681234]}
        }
    ]
}
