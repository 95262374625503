//https://t.me/elemnt3 Y:2021
import React, {useState} from 'react';
import {WordCloud} from '@ant-design/charts';

const cloudy = [
  {
    "x": "эрмитаж",
    "value": 14060
  },
  {
    "x": "исаакиевский собор",
    "value": 6594
  },
  {
    "x": "петропавловский крепость",
    "value": 3709
  },
  {
    "x": "новый голландия",
    "value": 3022
  },
  {
    "x": "русский музей",
    "value": 2557
  },
  {
    "x": "эрарт",
    "value": 2269
  },
  {
    "x": "мариинский театр",
    "value": 2112
  },
  {
    "x": "ботанический сад",
    "value": 2021
  },
  {
    "x": "крейсер аврора",
    "value": 1706
  },
  {
    "x": "казанский собор",
    "value": 1628
  },
  {
    "x": "зимний дворец",
    "value": 1523
  },
  {
    "x": "дворцовый площадь",
    "value": 1425
  },
  {
    "x": "елагин остров",
    "value": 1329
  },
  {
    "x": "бдт",
    "value": 1309
  },
  {
    "x": "длт",
    "value": 1253
  },
  {
    "x": "летний сад",
    "value": 1230
  },
  {
    "x": "севкабель",
    "value": 1174
  },
  {
    "x": "музей фабержа",
    "value": 1158
  },
  {
    "x": "манеж",
    "value": 1142
  },
  {
    "x": "михайловский замок",
    "value": 982
  },
  {
    "x": "лахта центр",
    "value": 976
  },
  {
    "x": "анненкирха",
    "value": 934
  },
  {
    "x": "михайловский театр",
    "value": 882
  },
  {
    "x": "витебский вокзал",
    "value": 837
  },
  {
    "x": "смольный собор",
    "value": 789
  },
  {
    "x": "таврический сад",
    "value": 776
  },
  {
    "x": "александринский театр",
    "value": 746
  },
  {
    "x": "бкз октябрьский",
    "value": 726
  },
  {
    "x": "гранд макет россия",
    "value": 703
  },
  {
    "x": "главный штаб",
    "value": 675
  },
  {
    "x": "диво остров",
    "value": 662
  },
  {
    "x": "трк сити молл",
    "value": 651
  },
  {
    "x": "океанариум",
    "value": 642
  },
  {
    "x": "заячий остров",
    "value": 631
  },
  {
    "x": "юсуповский дворец",
    "value": 628
  },
  {
    "x": "цпкио",
    "value": 612
  },
  {
    "x": "спасти на кровь",
    "value": 606
  },
  {
    "x": "кунсткамера",
    "value": 576
  },
  {
    "x": "газпром арена",
    "value": 558
  },
  {
    "x": "манежный площадь",
    "value": 547
  },
  {
    "x": "ледовый дворец",
    "value": 530
  },
  {
    "x": "петропавловский собор",
    "value": 520
  },
  {
    "x": "этаж",
    "value": 488
  },
  {
    "x": "парка 300-летие",
    "value": 488
  },
  {
    "x": "дворцовый мост",
    "value": 478
  },
  {
    "x": "парк победа",
    "value": 473
  },
  {
    "x": "александро-невский лавр",
    "value": 454
  },
  {
    "x": "юбилейный",
    "value": 448
  },
  {
    "x": "зоологический музей",
    "value": 441
  },
  {
    "x": "медный всадник",
    "value": 430
  },
  {
    "x": "планетарий",
    "value": 429
  },
  {
    "x": "трц галерея",
    "value": 423
  },
  {
    "x": "сибур арена",
    "value": 378
  },
  {
    "x": "академия художество",
    "value": 366
  },
  {
    "x": "дом зингер",
    "value": 365
  },
  {
    "x": "ленэкспо",
    "value": 364
  },
  {
    "x": "академия штиглица",
    "value": 361
  },
  {
    "x": "мег дыбенко",
    "value": 350
  },
  {
    "x": "музей железный дорога россия",
    "value": 344
  },
  {
    "x": "охт молл",
    "value": 343
  },
  {
    "x": "смольный",
    "value": 342
  },
  {
    "x": "исаакиевский площадь",
    "value": 341
  },
  {
    "x": "аничков мост",
    "value": 338
  },
  {
    "x": "театр им ленсовет",
    "value": 336
  },
  {
    "x": "ресторан благодатъ",
    "value": 331
  },
  {
    "x": "михайловский сад",
    "value": 327
  },
  {
    "x": "ленфильм",
    "value": 323
  },
  {
    "x": "ленинградский зоопарк",
    "value": 323
  },
  {
    "x": "филармония",
    "value": 315
  },
  {
    "x": "царский село",
    "value": 314
  },
  {
    "x": "александровский парка",
    "value": 292
  },
  {
    "x": "политех",
    "value": 292
  },
  {
    "x": "трц монпансье",
    "value": 291
  },
  {
    "x": "дк ленсовет",
    "value": 288
  },
  {
    "x": "стрелка васильевский остров",
    "value": 285
  },
  {
    "x": "банковский мост",
    "value": 283
  },
  {
    "x": "большеохтинский мост",
    "value": 283
  },
  {
    "x": "артмуза",
    "value": 280
  },
  {
    "x": "люмьер-холл",
    "value": 278
  },
  {
    "x": "дворец бракосочетание 2",
    "value": 275
  },
  {
    "x": "василеостровский рынок",
    "value": 268
  },
  {
    "x": "юсуповский сад",
    "value": 267
  },
  {
    "x": "площадь восстание",
    "value": 264
  },
  {
    "x": "гм карусель",
    "value": 258
  },
  {
    "x": "альпенхаус",
    "value": 255
  },
  {
    "x": "марсовый поле",
    "value": 255
  },
  {
    "x": "тц южный",
    "value": 254
  },
  {
    "x": "долгоозёрный рынок",
    "value": 251
  },
  {
    "x": "трк юбилейный",
    "value": 247
  },
  {
    "x": "дк выборгский",
    "value": 244
  },
  {
    "x": "дк выборгский",
    "value": 244
  },
  {
    "x": "московский вокзал",
    "value": 242
  },
  {
    "x": "эрмитажный театр",
    "value": 235
  },
  {
    "x": "мраморный дворец",
    "value": 235
  },
  {
    "x": "московский ворота",
    "value": 232
  },
  {
    "x": "хоккейный город",
    "value": 223
  },
  {
    "x": "трк континент",
    "value": 220
  },
  {
    "x": "канонерский остров",
    "value": 219
  },
  {
    "x": "клуб космонавт",
    "value": 217
  },
  {
    "x": "театр лдм",
    "value": 215
  },
  {
    "x": "музей анна ахматов",
    "value": 207
  },
  {
    "x": "тк невский центр",
    "value": 203
  },
  {
    "x": "муринский парк",
    "value": 202
  },
  {
    "x": "скк",
    "value": 200
  },
  {
    "x": "парка сосновка",
    "value": 197
  },
  {
    "x": "камчатка",
    "value": 193
  },
  {
    "x": "ленинград центр",
    "value": 191
  },
  {
    "x": "театр музыкальный комедия",
    "value": 191
  },
  {
    "x": "удельный парка",
    "value": 191
  },
  {
    "x": "театр мюзик-холл",
    "value": 188
  },
  {
    "x": "адмиралтейство",
    "value": 186
  },
  {
    "x": "мц богатырь",
    "value": 185
  },
  {
    "x": "екатерининский дворец",
    "value": 177
  },
  {
    "x": "фонтанный дом",
    "value": 176
  },
  {
    "x": "троицкий мост",
    "value": 173
  },
  {
    "x": "тц адмиралтейский",
    "value": 173
  },
  {
    "x": "жемчужный плаз",
    "value": 173
  },
  {
    "x": "трк пик",
    "value": 172
  },
  {
    "x": "пискарёвский мемориальный кладбище",
    "value": 171
  },
  {
    "x": "приморский парк победа",
    "value": 170
  },
  {
    "x": "балтийский дом",
    "value": 169
  },
  {
    "x": "звёздный",
    "value": 168
  },
  {
    "x": "рыбацкий",
    "value": 168
  },
  {
    "x": "южный рынок",
    "value": 165
  },
  {
    "x": "таврический дворец",
    "value": 162
  },
  {
    "x": "тюз",
    "value": 160
  },
  {
    "x": "екатерининский парк",
    "value": 159
  },
  {
    "x": "жк солнечный город",
    "value": 159
  },
  {
    "x": "жк солнечный город",
    "value": 159
  },
  {
    "x": "союз художник",
    "value": 158
  },
  {
    "x": "ленрезерв",
    "value": 152
  },
  {
    "x": "измайловский сад",
    "value": 152
  },
  {
    "x": "петровский коса",
    "value": 151
  },
  {
    "x": "молодёжный театр на фонтанка",
    "value": 146
  },
  {
    "x": "никольский собор",
    "value": 146
  },
  {
    "x": "ледокол красин",
    "value": 145
  },
  {
    "x": "этобар",
    "value": 141
  },
  {
    "x": "бц миллер",
    "value": 140
  },
  {
    "x": "клуб малевич",
    "value": 140
  },
  {
    "x": "сенатский площадь",
    "value": 139
  },
  {
    "x": "арсенал",
    "value": 137
  },
  {
    "x": "арсенал",
    "value": 137
  },
  {
    "x": "мозаичный дворик",
    "value": 136
  },
  {
    "x": "красный мост",
    "value": 132
  },
  {
    "x": "а2",
    "value": 132
  },
  {
    "x": "дом великан",
    "value": 132
  },
  {
    "x": "соборный мечеть",
    "value": 131
  },
  {
    "x": "театр мастерская",
    "value": 131
  },
  {
    "x": "площадь ленин",
    "value": 129
  },
  {
    "x": "форт константин",
    "value": 125
  },
  {
    "x": "отель астория",
    "value": 125
  },
  {
    "x": "новый оккервиль",
    "value": 124
  },
  {
    "x": "театр комедия им акимов",
    "value": 123
  },
  {
    "x": "ресторан ирис",
    "value": 123
  },
  {
    "x": "универмаг московский",
    "value": 123
  },
  {
    "x": "финляндский вокзал",
    "value": 122
  },
  {
    "x": "театр санктъ-петербургъ опера",
    "value": 122
  },
  {
    "x": "сенной площадь",
    "value": 122
  },
  {
    "x": "тц торговый двор",
    "value": 121
  },
  {
    "x": "кидбург",
    "value": 121
  },
  {
    "x": "троицкий собор",
    "value": 119
  },
  {
    "x": "цифербург",
    "value": 118
  },
  {
    "x": "балтийский жемчужина",
    "value": 118
  },
  {
    "x": "магазин купец елисеев",
    "value": 117
  },
  {
    "x": "театр на литейный",
    "value": 117
  },
  {
    "x": "ресторан гимназия",
    "value": 117
  },
  {
    "x": "чернышевский",
    "value": 116
  },
  {
    "x": "чкаловский",
    "value": 116
  },
  {
    "x": "тинькофф арена",
    "value": 116
  },
  {
    "x": "сад дружба",
    "value": 114
  },
  {
    "x": "трк планета нептун",
    "value": 114
  },
  {
    "x": "нарвский",
    "value": 113
  },
  {
    "x": "трубецкой бастион",
    "value": 112
  },
  {
    "x": "гостиный двор",
    "value": 112
  },
  {
    "x": "смоленский кладбище",
    "value": 112
  },
  {
    "x": "театр им в ф комиссаржевский",
    "value": 112
  },
  {
    "x": "кировский завод",
    "value": 111
  },
  {
    "x": "трц рио",
    "value": 111
  },
  {
    "x": "лендок",
    "value": 111
  },
  {
    "x": "елагин дворец",
    "value": 110
  },
  {
    "x": "театр буфф",
    "value": 110
  },
  {
    "x": "пионерский",
    "value": 109
  },
  {
    "x": "пулково",
    "value": 109
  },
  {
    "x": "сити центр",
    "value": 109
  },
  {
    "x": "театр эстрада им а райкин",
    "value": 109
  },
  {
    "x": "красный треугольник",
    "value": 108
  },
  {
    "x": "музей иллюзия",
    "value": 108
  },
  {
    "x": "капелла",
    "value": 107
  },
  {
    "x": "бертгольд центр",
    "value": 106
  },
  {
    "x": "отель дворец трезиня",
    "value": 101
  },
  {
    "x": "яхтенный мост",
    "value": 100
  },
  {
    "x": "ресторан метрополь",
    "value": 100
  },
  {
    "x": "новочеркасский",
    "value": 100
  },
  {
    "x": "автодом пулково",
    "value": 99
  },
  {
    "x": "садовый",
    "value": 99
  },
  {
    "x": "малый садовый",
    "value": 98
  },
  {
    "x": "академический",
    "value": 97
  },
  {
    "x": "сатурн",
    "value": 97
  },
  {
    "x": "мдт",
    "value": 96
  },
  {
    "x": "голицын лофт",
    "value": 95
  },
  {
    "x": "южно-приморский парка",
    "value": 94
  },
  {
    "x": "двор помидор",
    "value": 92
  },
  {
    "x": "дк им горький",
    "value": 91
  },
  {
    "x": "лондон молл",
    "value": 91
  },
  {
    "x": "гатчинский дворец",
    "value": 91
  },
  {
    "x": "николаевский дворец",
    "value": 90
  },
  {
    "x": "тк балканский-3",
    "value": 89
  },
  {
    "x": "трк меркурий",
    "value": 88
  },
  {
    "x": "нарышкин бастион",
    "value": 87
  },
  {
    "x": "владимирский пассаж",
    "value": 87
  },
  {
    "x": "учебный театр",
    "value": 87
  },
  {
    "x": "отель лахта плаз",
    "value": 87
  },
  {
    "x": "сестрорецкий болото",
    "value": 87
  },
  {
    "x": "театр имя андрей миронов",
    "value": 86
  },
  {
    "x": "дацан гунзэчойнэя",
    "value": 85
  },
  {
    "x": "музей стрит",
    "value": 85
  },
  {
    "x": "красногвардейский пл",
    "value": 85
  },
  {
    "x": "трк родео драйв",
    "value": 84
  },
  {
    "x": "трц июнь",
    "value": 84
  },
  {
    "x": "ладожский вокзал",
    "value": 84
  },
  {
    "x": "александровский сад",
    "value": 83
  },
  {
    "x": "озеро долгий",
    "value": 82
  },
  {
    "x": "ферма бенуа",
    "value": 82
  },
  {
    "x": "трк южный полюс",
    "value": 82
  },
  {
    "x": "парка малиновка",
    "value": 82
  },
  {
    "x": "благовещенский мост",
    "value": 81
  },
  {
    "x": "ауди центр витебский",
    "value": 81
  },
  {
    "x": "цирк на фонтанка",
    "value": 81
  },
  {
    "x": "чесменский церковь",
    "value": 81
  },
  {
    "x": "дворец белосельский-белозерский",
    "value": 81
  },
  {
    "x": "гранд отель европа",
    "value": 80
  },
  {
    "x": "дом кино",
    "value": 80
  },
  {
    "x": "музей достоевский",
    "value": 79
  },
  {
    "x": "феодоровский собор",
    "value": 79
  },
  {
    "x": "музей вода",
    "value": 78
  },
  {
    "x": "театр приют комедиант",
    "value": 78
  },
  {
    "x": "университет итмый",
    "value": 78
  },
  {
    "x": "музей история религия",
    "value": 78
  },
  {
    "x": "ресторан мансарда",
    "value": 77
  },
  {
    "x": "музей советский игровой автомат",
    "value": 77
  },
  {
    "x": "мег парка",
    "value": 77
  },
  {
    "x": "ткач",
    "value": 77
  },
  {
    "x": "ладожский",
    "value": 76
  },
  {
    "x": "магазин книгиподарок",
    "value": 76
  },
  {
    "x": "литейный мост",
    "value": 76
  },
  {
    "x": "яани кирика",
    "value": 76
  },
  {
    "x": "мисп",
    "value": 76
  },
  {
    "x": "дворец олимпий",
    "value": 73
  },
  {
    "x": "галерея свиной рыло",
    "value": 72
  },
  {
    "x": "каменноостровский театр",
    "value": 72
  },
  {
    "x": "иоанновский мост",
    "value": 72
  },
  {
    "x": "парка интернационалист",
    "value": 72
  },
  {
    "x": "балтийский вокзал",
    "value": 72
  },
  {
    "x": "императорский фарфоровый завод",
    "value": 72
  },
  {
    "x": "ресторан беринг",
    "value": 72
  },
  {
    "x": "школа бенуа",
    "value": 72
  },
  {
    "x": "кинотеатр аврора",
    "value": 72
  },
  {
    "x": "ресторан бричмул",
    "value": 71
  },
  {
    "x": "тц атлантика сити",
    "value": 71
  },
  {
    "x": "площадь островский",
    "value": 71
  },
  {
    "x": "культурный центр сердце",
    "value": 71
  },
  {
    "x": "колизей",
    "value": 71
  },
  {
    "x": "ргпу им а и герцен",
    "value": 71
  },
  {
    "x": "кафе зингер",
    "value": 71
  },
  {
    "x": "трк адмирал",
    "value": 70
  },
  {
    "x": "тк космос",
    "value": 70
  },
  {
    "x": "ресторан парус",
    "value": 70
  },
  {
    "x": "театр поколение",
    "value": 70
  },
  {
    "x": "витебский",
    "value": 69
  },
  {
    "x": "львиный мост",
    "value": 68
  },
  {
    "x": "ресторан москва",
    "value": 68
  },
  {
    "x": "домашний садик смайлик",
    "value": 68
  },
  {
    "x": "сад бенуа",
    "value": 67
  },
  {
    "x": "гатчинский парк",
    "value": 67
  },
  {
    "x": "музей политический история",
    "value": 66
  },
  {
    "x": "ресторан баклажан",
    "value": 66
  },
  {
    "x": "мост бетанкур",
    "value": 66
  },
  {
    "x": "сад сновидение",
    "value": 66
  },
  {
    "x": "ресторан мамалыга",
    "value": 65
  },
  {
    "x": "тк озерко",
    "value": 65
  },
  {
    "x": "балтийский завод",
    "value": 65
  },
  {
    "x": "малый академия искусство",
    "value": 64
  },
  {
    "x": "московский площадь",
    "value": 63
  },
  {
    "x": "театр лицедей",
    "value": 63
  },
  {
    "x": "ресторан юность",
    "value": 62
  },
  {
    "x": "московский парк победа",
    "value": 62
  },
  {
    "x": "ресторан карл и фридрих",
    "value": 62
  },
  {
    "x": "цхг жемчужина",
    "value": 62
  },
  {
    "x": "трк заневский каскад",
    "value": 62
  },
  {
    "x": "оранжерея таврический сад",
    "value": 62
  },
  {
    "x": "ленполиграфмаш",
    "value": 62
  },
  {
    "x": "голицын холл",
    "value": 61
  },
  {
    "x": "ресторан рибай",
    "value": 61
  },
  {
    "x": "пулковский парка",
    "value": 61
  },
  {
    "x": "владимирский собор",
    "value": 60
  },
  {
    "x": "питерлэнд",
    "value": 59
  },
  {
    "x": "пролетарский",
    "value": 59
  },
  {
    "x": "большой обуховский мост",
    "value": 59
  },
  {
    "x": "президентский библиотека",
    "value": 58
  },
  {
    "x": "клуб грибоедов",
    "value": 57
  },
  {
    "x": "военно-морской музей",
    "value": 57
  },
  {
    "x": "россия-мой история",
    "value": 57
  },
  {
    "x": "петрикирха",
    "value": 57
  },
  {
    "x": "жк граф орлов",
    "value": 57
  },
  {
    "x": "клуб морзе",
    "value": 57
  },
  {
    "x": "кококо",
    "value": 57
  },
  {
    "x": "трк купеческий двор",
    "value": 56
  },
  {
    "x": "семимостье",
    "value": 55
  },
  {
    "x": "монумент героический защитник ленинград",
    "value": 55
  },
  {
    "x": "технологический институт",
    "value": 55
  },
  {
    "x": "монетный двор",
    "value": 54
  },
  {
    "x": "трк порт находка",
    "value": 54
  },
  {
    "x": "ресторан ларисуваннухотать",
    "value": 54
  },
  {
    "x": "чаплин холл",
    "value": 53
  },
  {
    "x": "трк гулливер",
    "value": 53
  },
  {
    "x": "мост ломоносов",
    "value": 53
  },
  {
    "x": "академия зенит",
    "value": 53
  },
  {
    "x": "ресторан цинь",
    "value": 52
  },
  {
    "x": "мариинском-2",
    "value": 52
  },
  {
    "x": "квартира кость кроец",
    "value": 52
  },
  {
    "x": "володарский мост",
    "value": 52
  },
  {
    "x": "преображенский собор",
    "value": 52
  },
  {
    "x": "невский лесопарк",
    "value": 51
  },
  {
    "x": "трк невский",
    "value": 51
  },
  {
    "x": "шоурум сорок",
    "value": 51
  },
  {
    "x": "тк капитолий",
    "value": 51
  },
  {
    "x": "англетереть",
    "value": 51
  },
  {
    "x": "искра фонд",
    "value": 51
  },
  {
    "x": "ресторан плюшкина",
    "value": 51
  },
  {
    "x": "ресторан сулить гуля",
    "value": 51
  },
  {
    "x": "театр на васильевский",
    "value": 51
  },
  {
    "x": "парк екатерингоф",
    "value": 50
  },
  {
    "x": "дюц красногвардеец",
    "value": 50
  },
  {
    "x": "невский жемчужина",
    "value": 49
  },
  {
    "x": "горный университет",
    "value": 49
  },
  {
    "x": "старый деревня",
    "value": 49
  },
  {
    "x": "площадь искусство",
    "value": 49
  },
  {
    "x": "елизаровский",
    "value": 48
  },
  {
    "x": "гарден сити",
    "value": 48
  },
  {
    "x": "ростральный колонна",
    "value": 48
  },
  {
    "x": "бродячий собака",
    "value": 48
  },
  {
    "x": "дк громов",
    "value": 47
  },
  {
    "x": "лесной",
    "value": 47
  },
  {
    "x": "юнтоловский заказник",
    "value": 47
  },
  {
    "x": "юнона",
    "value": 47
  },
  {
    "x": "жк привилегия",
    "value": 46
  },
  {
    "x": "мастерская аникушин",
    "value": 46
  },
  {
    "x": "площадь александр невский",
    "value": 44
  },
  {
    "x": "театр алеко",
    "value": 44
  },
  {
    "x": "отель санкт-петербург",
    "value": 44
  },
  {
    "x": "горьковский",
    "value": 43
  },
  {
    "x": "трк лиговъ",
    "value": 42
  },
  {
    "x": "ломоносовский",
    "value": 42
  },
  {
    "x": "дк вкус",
    "value": 42
  },
  {
    "x": "дк крупский",
    "value": 41
  },
  {
    "x": "ресторан хотеть харчо",
    "value": 41
  },
  {
    "x": "жк чистый небо",
    "value": 41
  },
  {
    "x": "новый музей",
    "value": 41
  },
  {
    "x": "особняк румянцев",
    "value": 40
  },
  {
    "x": "академия талант",
    "value": 40
  },
  {
    "x": "балканский пл",
    "value": 39
  },
  {
    "x": "пушкинский",
    "value": 39
  },
  {
    "x": "порше центр невский",
    "value": 39
  },
  {
    "x": "центр хоккейный развитие",
    "value": 39
  },
  {
    "x": "магазин бюргер",
    "value": 39
  },
  {
    "x": "театр особняк",
    "value": 38
  },
  {
    "x": "дом журналист",
    "value": 38
  },
  {
    "x": "дк им газ",
    "value": 38
  },
  {
    "x": "площадь победа",
    "value": 38
  },
  {
    "x": "невский ратуша",
    "value": 38
  },
  {
    "x": "академия смысл",
    "value": 37
  },
  {
    "x": "нарвский ворота",
    "value": 37
  },
  {
    "x": "невский берег",
    "value": 37
  },
  {
    "x": "библиотека гоголь",
    "value": 37
  },
  {
    "x": "метрополис арена",
    "value": 37
  },
  {
    "x": "больший хоральный синагога",
    "value": 36
  },
  {
    "x": "яблоневый сад",
    "value": 36
  },
  {
    "x": "мег парнас",
    "value": 36
  },
  {
    "x": "дворец малютка",
    "value": 35
  },
  {
    "x": "ресторан блок",
    "value": 35
  },
  {
    "x": "храм святой станислав",
    "value": 35
  },
  {
    "x": "музей городской электрический транспорт",
    "value": 35
  },
  {
    "x": "полежаевский парка",
    "value": 35
  },
  {
    "x": "конюшенный площадь",
    "value": 35
  },
  {
    "x": "софийский собор",
    "value": 35
  },
  {
    "x": "российский национальный библиотека",
    "value": 35
  },
  {
    "x": "ресторан шала",
    "value": 35
  },
  {
    "x": "трк атмосфера",
    "value": 34
  },
  {
    "x": "тц кировский универмаг",
    "value": 34
  },
  {
    "x": "трк лето",
    "value": 34
  },
  {
    "x": "кдц московский",
    "value": 34
  },
  {
    "x": "парк авиатор",
    "value": 34
  },
  {
    "x": "большой меншиковский дворец",
    "value": 34
  },
  {
    "x": "ресторан гастрономик",
    "value": 34
  },
  {
    "x": "тк академический",
    "value": 34
  },
  {
    "x": "ресторан на речка",
    "value": 34
  },
  {
    "x": "академия наука",
    "value": 33
  },
  {
    "x": "адмиралтейский верфь",
    "value": 33
  },
  {
    "x": "музей артиллерия",
    "value": 33
  },
  {
    "x": "трк европолис",
    "value": 33
  },
  {
    "x": "спасский",
    "value": 32
  },
  {
    "x": "летний дворец",
    "value": 32
  },
  {
    "x": "отель москва",
    "value": 32
  },
  {
    "x": "отель гамма",
    "value": 32
  },
  {
    "x": "университет",
    "value": 32
  },
  {
    "x": "китайский дворец",
    "value": 32
  },
  {
    "x": "жк юность",
    "value": 32
  },
  {
    "x": "ресторан специя",
    "value": 31
  },
  {
    "x": "кц троицкий",
    "value": 31
  },
  {
    "x": "аксель-моторс север",
    "value": 31
  },
  {
    "x": "жк новый охт",
    "value": 31
  },
  {
    "x": "звенигородский",
    "value": 31
  },
  {
    "x": "клуб идея",
    "value": 31
  },
  {
    "x": "иоанновский монастырь",
    "value": 31
  },
  {
    "x": "жк огонь залив",
    "value": 31
  },
  {
    "x": "жк царский столица",
    "value": 30
  },
  {
    "x": "двор капелла",
    "value": 30
  },
  {
    "x": "летучий голландец",
    "value": 30
  },
  {
    "x": "пышечка",
    "value": 30
  },
  {
    "x": "детский центр развитие антошка",
    "value": 29
  },
  {
    "x": "парк есенин",
    "value": 29
  },
  {
    "x": "отель гельвеция",
    "value": 29
  },
  {
    "x": "муринский ручей",
    "value": 29
  },
  {
    "x": "ресторан сосед",
    "value": 29
  },
  {
    "x": "центр алмазов",
    "value": 29
  },
  {
    "x": "жк я-романтик",
    "value": 28
  },
  {
    "x": "жк васильевский квартал",
    "value": 28
  },
  {
    "x": "екатерининский сквер",
    "value": 27
  },
  {
    "x": "тц рубикон",
    "value": 27
  },
  {
    "x": "театр суббота",
    "value": 27
  },
  {
    "x": "лесотехнический академия",
    "value": 27
  },
  {
    "x": "жк пять звезда",
    "value": 27
  },
  {
    "x": "ранхигс",
    "value": 27
  },
  {
    "x": "тк солнечный",
    "value": 27
  },
  {
    "x": "бц долина роза",
    "value": 26
  },
  {
    "x": "любашинский сад",
    "value": 26
  },
  {
    "x": "экзоопарк",
    "value": 26
  },
  {
    "x": "бегов",
    "value": 26
  },
  {
    "x": "старгород",
    "value": 26
  },
  {
    "x": "нахимовский училище",
    "value": 26
  },
  {
    "x": "невский волна",
    "value": 26
  },
  {
    "x": "арт-центр пушкинский",
    "value": 25
  },
  {
    "x": "театр цехъ",
    "value": 25
  },
  {
    "x": "клиника пирогов",
    "value": 25
  },
  {
    "x": "международный школа дизайн",
    "value": 25
  },
  {
    "x": "ошо центр",
    "value": 25
  },
  {
    "x": "цси им сергей курёхин",
    "value": 25
  },
  {
    "x": "музей-усадьба г р державин",
    "value": 25
  },
  {
    "x": "парк герой",
    "value": 25
  },
  {
    "x": "отель амбассадор",
    "value": 25
  },
  {
    "x": "ресторан маймуна",
    "value": 25
  },
  {
    "x": "площадь мужество",
    "value": 25
  },
  {
    "x": "кондитерский анна красовский",
    "value": 24
  },
  {
    "x": "академия русский балет имя а я ваганов",
    "value": 24
  },
  {
    "x": "стокманна",
    "value": 24
  },
  {
    "x": "прибалтийский",
    "value": 24
  },
  {
    "x": "ресторан чечить",
    "value": 24
  },
  {
    "x": "пикаловый мост",
    "value": 24
  },
  {
    "x": "школа лидер",
    "value": 24
  },
  {
    "x": "такой театр",
    "value": 24
  },
  {
    "x": "больница святой георгий",
    "value": 23
  },
  {
    "x": "музей шаляпин",
    "value": 23
  },
  {
    "x": "шуваловский парка",
    "value": 23
  },
  {
    "x": "жк шуваловский",
    "value": 23
  },
  {
    "x": "цветной город",
    "value": 23
  },
  {
    "x": "тк хасанский",
    "value": 23
  },
  {
    "x": "театр на коломенский",
    "value": 23
  },
  {
    "x": "австрийский площадь",
    "value": 23
  },
  {
    "x": "кафе бонжур цып",
    "value": 23
  },
  {
    "x": "приморский баня",
    "value": 23
  },
  {
    "x": "каменноостровский дворец",
    "value": 23
  },
  {
    "x": "единый центр предпринимательство",
    "value": 23
  },
  {
    "x": "александровский колонна",
    "value": 23
  },
  {
    "x": "ресторан крыша 18",
    "value": 22
  },
  {
    "x": "театр будущее",
    "value": 22
  },
  {
    "x": "музей звук",
    "value": 22
  },
  {
    "x": "чижик",
    "value": 22
  },
  {
    "x": "театр синий птица",
    "value": 22
  },
  {
    "x": "малый зал филармония",
    "value": 22
  },
  {
    "x": "театр сказка",
    "value": 22
  },
  {
    "x": "варшавский вокзал",
    "value": 21
  },
  {
    "x": "отель азимут",
    "value": 21
  },
  {
    "x": "сад куракина дача",
    "value": 21
  },
  {
    "x": "площадь стачка",
    "value": 21
  },
  {
    "x": "французский институт",
    "value": 20
  },
  {
    "x": "нижний парк",
    "value": 20
  },
  {
    "x": "трк пять озеро",
    "value": 20
  },
  {
    "x": "центр евросиб лахта",
    "value": 20
  },
  {
    "x": "галерея мольберт",
    "value": 20
  },
  {
    "x": "лазаревский мост",
    "value": 20
  },
  {
    "x": "красногвардейский мост",
    "value": 20
  },
  {
    "x": "аничков дворец",
    "value": 20
  },
  {
    "x": "площадь тургенев",
    "value": 20
  },
  {
    "x": "отель введенский",
    "value": 20
  },
  {
    "x": "музей л н бенуа",
    "value": 20
  },
  {
    "x": "бекицер",
    "value": 20
  },
  {
    "x": "ресторан такао",
    "value": 20
  },
  {
    "x": "ресторан русский рыбалка",
    "value": 20
  },
  {
    "x": "печатный двор",
    "value": 20
  },
  {
    "x": "жк смольный парк",
    "value": 20
  },
  {
    "x": "ресторан есть рука",
    "value": 20
  },
  {
    "x": "пл чернышевский",
    "value": 20
  },
  {
    "x": "петровский акватория",
    "value": 19
  },
  {
    "x": "жк парус",
    "value": 19
  },
  {
    "x": "жк самоцвет",
    "value": 19
  },
  {
    "x": "ресторан поляна",
    "value": 19
  },
  {
    "x": "стадион динамо",
    "value": 19
  },
  {
    "x": "музей титикака",
    "value": 18
  },
  {
    "x": "жк леонтьевский мыс",
    "value": 18
  },
  {
    "x": "ресторан симпозиум",
    "value": 18
  },
  {
    "x": "спорткомплекс им в и алексеев",
    "value": 18
  },
  {
    "x": "ауди центр лахта",
    "value": 18
  },
  {
    "x": "кафе италия",
    "value": 18
  },
  {
    "x": "клуб гравитация",
    "value": 18
  },
  {
    "x": "жк москва",
    "value": 18
  },
  {
    "x": "особняк путилов",
    "value": 18
  },
  {
    "x": "пл карла фабержа",
    "value": 18
  },
  {
    "x": "тц электра",
    "value": 18
  },
  {
    "x": "воскресенский новодевичий монастырь",
    "value": 18
  },
  {
    "x": "фц сосновка парк",
    "value": 17
  },
  {
    "x": "школа мозаика",
    "value": 17
  },
  {
    "x": "жк ренессанс",
    "value": 17
  },
  {
    "x": "ресторан старый пловдив",
    "value": 17
  },
  {
    "x": "дом молодёжь квадрат",
    "value": 17
  },
  {
    "x": "графский пруд",
    "value": 17
  },
  {
    "x": "тк индустриальный",
    "value": 17
  },
  {
    "x": "военный академия связь",
    "value": 17
  },
  {
    "x": "дц эрудит",
    "value": 17
  },
  {
    "x": "пл балканский",
    "value": 17
  },
  {
    "x": "ресторан северянин",
    "value": 17
  },
  {
    "x": "военно-медицинский академия",
    "value": 17
  },
  {
    "x": "площадь лев толстой",
    "value": 17
  },
  {
    "x": "армянский апостольский церковь святой екатерина",
    "value": 17
  },
  {
    "x": "почтамтский мост",
    "value": 17
  },
  {
    "x": "спб тпп",
    "value": 17
  },
  {
    "x": "пионерский площадь",
    "value": 16
  },
  {
    "x": "музей киногерой",
    "value": 16
  },
  {
    "x": "сенат",
    "value": 16
  },
  {
    "x": "жк зима лето",
    "value": 16
  },
  {
    "x": "жк ласточкин гнездо",
    "value": 16
  },
  {
    "x": "тк шкиперский молл",
    "value": 16
  },
  {
    "x": "английский миля",
    "value": 16
  },
  {
    "x": "центральный яхт-клуб",
    "value": 16
  },
  {
    "x": "кз у финляндский",
    "value": 16
  },
  {
    "x": "академия цифровой технология",
    "value": 16
  },
  {
    "x": "жк князь александр невский",
    "value": 16
  },
  {
    "x": "театр дождь",
    "value": 16
  },
  {
    "x": "жк -лесной",
    "value": 15
  },
  {
    "x": "бар небо и вино",
    "value": 15
  },
  {
    "x": "мариинский дворец",
    "value": 15
  },
  {
    "x": "спбгасу",
    "value": 15
  },
  {
    "x": "эко лофт стрекоза",
    "value": 15
  },
  {
    "x": "бар личфилд",
    "value": 15
  },
  {
    "x": "отель коринтия санкт-петербург",
    "value": 15
  },
  {
    "x": "никольский кладбище",
    "value": 15
  },
  {
    "x": "забыть сахар",
    "value": 15
  },
  {
    "x": "польский сад",
    "value": 15
  },
  {
    "x": "жк софия",
    "value": 15
  },
  {
    "x": "богословский кладбище",
    "value": 15
  },
  {
    "x": "малоохтинский парка",
    "value": 15
  },
  {
    "x": "жк граффити",
    "value": 15
  },
  {
    "x": "большой петергофский дворец",
    "value": 14
  },
  {
    "x": "новодевичий кладбище",
    "value": 14
  },
  {
    "x": "ресторан терраса",
    "value": 14
  },
  {
    "x": "ресторан кукумбер",
    "value": 14
  },
  {
    "x": "бц практика",
    "value": 14
  },
  {
    "x": "ресторан шаляпин",
    "value": 14
  },
  {
    "x": "тк перинный ряд",
    "value": 14
  },
  {
    "x": "пспбгма им акад",
    "value": 14
  },
  {
    "x": "александровский больница",
    "value": 14
  },
  {
    "x": "гостиница октябрьский",
    "value": 14
  },
  {
    "x": "жк палацио",
    "value": 14
  },
  {
    "x": "парк оккервиль",
    "value": 14
  },
  {
    "x": "биржевой площадь",
    "value": 13
  },
  {
    "x": "новокрестовский",
    "value": 13
  },
  {
    "x": "отель кемпинск",
    "value": 13
  },
  {
    "x": "музей нонконформистский искусство",
    "value": 13
  },
  {
    "x": "музей маячный служба",
    "value": 13
  },
  {
    "x": "чернышёв мост",
    "value": 13
  },
  {
    "x": "бц сенная-4",
    "value": 13
  },
  {
    "x": "рггма",
    "value": 13
  },
  {
    "x": "стадион кировец",
    "value": 13
  },
  {
    "x": "стадион петровский",
    "value": 13
  },
  {
    "x": "жк время год",
    "value": 13
  },
  {
    "x": "ресторан лето",
    "value": 13
  },
  {
    "x": "тк вояж",
    "value": 13
  },
  {
    "x": "детский клуб тоторо",
    "value": 13
  },
  {
    "x": "сзгма им и и мечников",
    "value": 13
  },
  {
    "x": "ресторан палкинъ",
    "value": 13
  },
  {
    "x": "дельфинарий",
    "value": 12
  },
  {
    "x": "парк им бабушкин",
    "value": 12
  },
  {
    "x": "горилла парк",
    "value": 12
  },
  {
    "x": "ресторан гречка",
    "value": 12
  },
  {
    "x": "санкт-петербургский центр судебный экспертиза",
    "value": 12
  },
  {
    "x": "хоровой училище",
    "value": 12
  },
  {
    "x": "зоологический институт ран",
    "value": 12
  },
  {
    "x": "жк живить в рыбацкий",
    "value": 12
  },
  {
    "x": "цнии ртк",
    "value": 12
  },
  {
    "x": "клуб папанин",
    "value": 12
  },
  {
    "x": "отель индиго",
    "value": 12
  },
  {
    "x": "жк цивилизация",
    "value": 12
  },
  {
    "x": "баболовский парк",
    "value": 12
  },
  {
    "x": "елизаветинский больница",
    "value": 12
  },
  {
    "x": "нарвский застава",
    "value": 12
  },
  {
    "x": "клуб аврора",
    "value": 12
  },
  {
    "x": "жк европа сити",
    "value": 11
  },
  {
    "x": "театр карамболь",
    "value": 11
  },
  {
    "x": "ладожский парк",
    "value": 11
  },
  {
    "x": "оптина пустынь",
    "value": 11
  },
  {
    "x": "артплей",
    "value": 11
  },
  {
    "x": "клуб москва",
    "value": 11
  },
  {
    "x": "тк ланский",
    "value": 11
  },
  {
    "x": "концертный зал мариинк",
    "value": 11
  },
  {
    "x": "гостиница охтинский",
    "value": 11
  },
  {
    "x": "тц модный променад",
    "value": 11
  },
  {
    "x": "мсг арена",
    "value": 11
  },
  {
    "x": "культурный центр елена образцов",
    "value": 11
  },
  {
    "x": "порше центр приморский",
    "value": 11
  },
  {
    "x": "владимирский площадь",
    "value": 11
  },
  {
    "x": "музей русский водка",
    "value": 11
  },
  {
    "x": "бин ран",
    "value": 11
  },
  {
    "x": "ресторан я любить",
    "value": 11
  },
  {
    "x": "магазин порядок слово",
    "value": 11
  },
  {
    "x": "ресторан царский двор",
    "value": 11
  },
  {
    "x": "жк два столица",
    "value": 10
  },
  {
    "x": "великан парка",
    "value": 10
  },
  {
    "x": "бутербродский",
    "value": 10
  },
  {
    "x": "талион",
    "value": 10
  },
  {
    "x": "красногвардейский площадь",
    "value": 10
  },
  {
    "x": "жк аврора",
    "value": 10
  },
  {
    "x": "театр ахе",
    "value": 10
  },
  {
    "x": "театр тричетырь",
    "value": 10
  },
  {
    "x": "европейский школа",
    "value": 10
  },
  {
    "x": "музей история профобразование",
    "value": 10
  },
  {
    "x": "мост пётр великий",
    "value": 10
  },
  {
    "x": "пенаты",
    "value": 10
  },
  {
    "x": "высокий школа менеджмент",
    "value": 10
  },
  {
    "x": "особняк брюллов",
    "value": 10
  },
  {
    "x": "петербургский теннисный клуб им в и никифоров",
    "value": 10
  },
  {
    "x": "боткинский больница",
    "value": 9
  },
  {
    "x": "ресторан икорный бар",
    "value": 9
  },
  {
    "x": "полтора комната",
    "value": 9
  },
  {
    "x": "толстовский дом",
    "value": 9
  },
  {
    "x": "гранд отель эмеральда",
    "value": 9
  },
  {
    "x": "тучков мост",
    "value": 9
  },
  {
    "x": "ресторан чайка",
    "value": 9
  },
  {
    "x": "ниу вшэ",
    "value": 9
  },
  {
    "x": "сампсониевский сад",
    "value": 9
  },
  {
    "x": "педиатрический университет",
    "value": 9
  },
  {
    "x": "никольский ряд",
    "value": 9
  },
  {
    "x": "суздальский озеро",
    "value": 9
  },
  {
    "x": "ресторан брускетта",
    "value": 8
  },
  {
    "x": "выборгский рынок",
    "value": 8
  },
  {
    "x": "жк космос",
    "value": 8
  },
  {
    "x": "зелёный мост",
    "value": 8
  },
  {
    "x": "нии джанелидзе",
    "value": 8
  },
  {
    "x": "памятник николай",
    "value": 8
  },
  {
    "x": "жк приневский",
    "value": 8
  },
  {
    "x": "ресторан золотой двор",
    "value": 8
  },
  {
    "x": "бц золотой долина",
    "value": 8
  },
  {
    "x": "кантемировский мост",
    "value": 8
  },
  {
    "x": "институт им отт",
    "value": 8
  },
  {
    "x": "музей суворов",
    "value": 8
  },
  {
    "x": "тк китай",
    "value": 8
  },
  {
    "x": "ресторан охотничий домик",
    "value": 7
  },
  {
    "x": "бц фернан леже",
    "value": 7
  },
  {
    "x": "ресторан сыроварня",
    "value": 7
  },
  {
    "x": "ресторан библиотека",
    "value": 7
  },
  {
    "x": "александрино",
    "value": 7
  },
  {
    "x": "музей оптика",
    "value": 7
  },
  {
    "x": "библиотека им даниил гранин",
    "value": 7
  },
  {
    "x": "памятник екатерина",
    "value": 7
  },
  {
    "x": "отель эрмитаж",
    "value": 7
  },
  {
    "x": "галерный гавань",
    "value": 7
  },
  {
    "x": "театральный музей",
    "value": 7
  },
  {
    "x": "площадь труд",
    "value": 7
  },
  {
    "x": "гранд каньон",
    "value": 7
  },
  {
    "x": "церковь святой георгий победоносец",
    "value": 6
  },
  {
    "x": "ресторан птичий двор",
    "value": 6
  },
  {
    "x": "спортивный центр динамит",
    "value": 6
  },
  {
    "x": "ск легкоатлетический манеж",
    "value": 6
  },
  {
    "x": "парк дубка",
    "value": 6
  },
  {
    "x": "крестовоздвиженский собор",
    "value": 6
  },
  {
    "x": "площадь конституция",
    "value": 6
  },
  {
    "x": "парк серебряный пруд",
    "value": 6
  },
  {
    "x": "жк кантемировский",
    "value": 6
  },
  {
    "x": "кофейня лакомый кусочек",
    "value": 6
  },
  {
    "x": "академия фигурный катание",
    "value": 6
  },
  {
    "x": "памятник корабль полтава",
    "value": 6
  },
  {
    "x": "отель пулковский",
    "value": 6
  },
  {
    "x": "санкт-петербургский консерватория",
    "value": 6
  },
  {
    "x": "жк южный акватория",
    "value": 6
  },
  {
    "x": "площадь ломоносов",
    "value": 5
  },
  {
    "x": "парка строитель",
    "value": 5
  },
  {
    "x": "жк молодёжный",
    "value": 5
  },
  {
    "x": "жк кремлёвский звезда",
    "value": 5
  },
  {
    "x": "кинотеатр художественный",
    "value": 5
  },
  {
    "x": "соборный площадь",
    "value": 5
  },
  {
    "x": "клинический больница святитель лука",
    "value": 5
  },
  {
    "x": "кондитерский фабрика нева",
    "value": 5
  },
  {
    "x": "клуб невский",
    "value": 5
  },
  {
    "x": "жк полюстровый",
    "value": 5
  },
  {
    "x": "циклорама-лофт",
    "value": 5
  },
  {
    "x": "ушаковский мост",
    "value": 5
  },
  {
    "x": "бц санкт-петербург плаз",
    "value": 5
  },
  {
    "x": "дк железнодорожник",
    "value": 5
  },
  {
    "x": "клиника доктор коренченко",
    "value": 5
  },
  {
    "x": "заневский площадь",
    "value": 5
  },
  {
    "x": "жк калина-парк",
    "value": 5
  },
  {
    "x": "комитет по природопользование",
    "value": 4
  },
  {
    "x": "дц пушкинский",
    "value": 4
  },
  {
    "x": "ветеринарный академия",
    "value": 4
  },
  {
    "x": "отель сокос олимпий гарден",
    "value": 4
  },
  {
    "x": "петербургский тракторный завод",
    "value": 4
  },
  {
    "x": "имч ран",
    "value": 4
  },
  {
    "x": "благовещенский площадь",
    "value": 4
  },
  {
    "x": "ресторан баден баден",
    "value": 3
  },
  {
    "x": "тц зебра",
    "value": 3
  },
  {
    "x": "жк гатчина",
    "value": 3
  },
  {
    "x": "музей сладость",
    "value": 3
  },
  {
    "x": "икеа дыбенко",
    "value": 3
  },
  {
    "x": "библиотека им далее с лихачёв",
    "value": 3
  },
  {
    "x": "варшавский экспресс",
    "value": 3
  },
  {
    "x": "дк кировец",
    "value": 3
  },
  {
    "x": "ресторан пасторский озеро",
    "value": 3
  },
  {
    "x": "этюд-театр",
    "value": 3
  },
  {
    "x": "гагарин парк",
    "value": 3
  }
]
const MakeWordCloud = () => {
  const [data, setData] = useState(cloudy);
  // useEffect(() => {
  //   asyncFetch();
  // }, []);
  // const asyncFetch = () => {
  //   fetch('http://127.0.0.1:5000')
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       console.log('fetch data failed', error);
  //     });
  // };
  let config = {
    data: data,
    wordField: 'x',
    weightField: 'value',
    // color: '#3E6DDF',
    colorField: 'value',
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [24, 80],
    },
    interactions: [{ type: 'element-active' }],
    state: { active: { style: { lineWidth: 3 } } },
  };
    return (
            <WordCloud {...config} />
    )
};

export default MakeWordCloud;
