export const sports_centers = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Солидарности, 11к1",
                "name": "Одиннадцать",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "10pin"
            },
            "geometry": {"type": "Point", "coordinates": [30.491857, 59.920708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Каштановая аллея, 9к1",
                "name": "Айкидо",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 9443374",
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "единоборства"
            },
            "geometry": {"type": "Point", "coordinates": [30.393626, 59.844325]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "name": "AeroDream",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Tu-Th, Sa, Su 10:00-22:00",
                "contact_phone": "+7 812 3857775",
                "contact_website": "https:\/\/aerodream.spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "aerodynamic_tube"
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лабораторная улица, 17",
                "name": "Genbukan Dojo",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "aikido"
            },
            "geometry": {"type": "Point", "coordinates": [30.384176, 59.977359]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Кржижановского, 5к1",
                "name": "Федерация Ки-Айкидо Восточной Европы и России",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 951 665 79 63",
                "contact_website": "http:\/\/ki-aikido.spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "aikido"
            },
            "geometry": {"type": "Point", "coordinates": [30.470567, 59.93213]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 16",
                "name": "SportLife",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 812 3225858",
                "contact_website": "https:\/\/www.sportlifeclub.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.319551, 59.973248]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дрезденская улица, 14к2",
                "name": "СДЮШОР ВоВиС (водных и других видов спорта)",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.330717, 60.014527]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Караваевская улица, 32к2",
                "name": "Лидер",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.51233, 59.831865]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Каштановая аллея, 7",
                "name": "Sculptors",
                "building": "yes",
                "building_levels": 4.0,
                "level": null,
                "type": "Спортивный центр",
                "description": "Фитнес-клуб с бассейном",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/fitnes1.ru\/clubs\/Sculptors-kupchino.6.html",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.392647, 59.844032]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Манежная площадь, 2",
                "name": "Зимний стадион",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/alaspb05.wixsite.com\/ala2005",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.339314, 59.936841]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 11",
                "name": "Сфера фитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.315248, 59.868984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Тярлево, Спортивная улица, 1",
                "name": "Атлетический клуб им. В.Ф.Краевского",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.450202, 59.699548]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 138к2",
                "name": "Fitness Family",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.484715, 59.845785]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Славы, 50\/48",
                "name": "ExtraSport",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su,PH 09:00-22:00",
                "contact_phone": "+7 812 9648383",
                "contact_website": "http:\/\/www.extrasport.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.404253, 59.863274]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 9",
                "name": "Планета",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.skplaneta.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.453948, 59.915224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 32Бк2",
                "name": "X-fit",
                "building": "commercial",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/www.xfit.ru\/club\/x-fit-morskoy-fasad\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.215167, 59.953633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ольги Берггольц, 15",
                "name": "Школа олимпийского резерва по лёгкой атлетике № 1",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.423603, 59.894924]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Оптиков, 32",
                "name": "Приморская Жемчужина",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": "Многофункциональный спортивный комплекс",
                "opening_hours": "Mo-Fr 07:30-00:00; Sa-Su 08:00-23:30",
                "contact_phone": "+7 921 6474028",
                "contact_website": "https:\/\/www.primorye-pearl.com\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.218158, 59.999431]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Теннисная аллея, 3",
                "name": "Легкоатлетический манеж",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Легкоатлетический манеж",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics;multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.230133, 59.971163]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics;swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новорощинская улица, 4",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "athletics;swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.326729, 59.884591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 50БА",
                "name": "СШОР №1 \"Орлёнок\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 2515797",
                "contact_website": "http:\/\/shor1adm.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "basketball;soccer;athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.311044, 59.923162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пионерская улица, 21",
                "name": "Алиби",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "billiards"
            },
            "geometry": {"type": "Point", "coordinates": [30.290922, 59.958043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Альпийский переулок, 30",
                "name": "Фрунзенец",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "box"
            },
            "geometry": {"type": "Point", "coordinates": [30.387437, 59.854327]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гаванская улица, 10",
                "name": "Спортивный клуб Синергия",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 9846163",
                "contact_website": "https:\/\/sportsynergy.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "boxing;crossfit;karate"
            },
            "geometry": {"type": "Point", "coordinates": [30.240697, 59.930286]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 32 к2В",
                "name": "Bumperball SPB",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Прием звонков: ежедневно 11:00-21:00 Просмотр клуба: Пн-Пт 17:00-20:00; Сб-Вс 11:00-20:00",
                "opening_hours": null,
                "contact_phone": "+7 965 7865309",
                "contact_website": "http:\/\/bumper-ball.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "bumperball"
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вязовая улица, 4В",
                "name": "Sup Spot",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "canoe"
            },
            "geometry": {"type": "Point", "coordinates": [30.279459, 59.969317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Динамо, 44Б",
                "name": "Учебно-спортивная база гребли",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 10:00-23:30; Tu,Th 08:30-23:30",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "canoe"
            },
            "geometry": {"type": "Point", "coordinates": [30.266596, 59.965822]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 24",
                "name": "Скалолазный центр Трамонтана",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 09:00-23:30",
                "contact_phone": "+7(812)640-38-44",
                "contact_website": "http:\/\/climbingcenter.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.32389, 59.913988]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дивенская улица, 4",
                "name": "Скалодром СКА",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/skalodromska.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.318877, 59.95985]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ольховая улица, 18",
                "name": "Скалодром Танрэн",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/vk.com\/tanren",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.25463, 60.016447]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Динамо, 44Б",
                "name": "Скалодром Луч",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7(812) 931-77-16",
                "contact_website": "http:\/\/lu4.su",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.266596, 59.965822]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Комсомола, 2В",
                "name": "El Capitan",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Sa 10:00-23:00",
                "contact_phone": "+7 812 3478400",
                "contact_website": "https:\/\/climbcap.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.373675, 59.954795]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Матроса Железняка, 57",
                "name": "Неолит",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 921 650-04-31",
                "contact_website": "http:\/\/www.neolit.club",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.316245, 60.000285]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Черняховского, 49",
                "name": "Скалодром Балтийский Берег",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7(812)764-04-00",
                "contact_website": "http:\/\/www.balticbereg.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.360101, 59.919477]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Софийская улица, 14",
                "name": "Энергия Высоты",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Крытый веревочный парк, 3 скалодрома, батуты, детские и взрослые спортивные секции; проведение дней рождения (с возможностью аренды комнаты).",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 3356555",
                "contact_website": "http:\/\/xn--b1abfnwkklk1gdn5a.xn--p1ai\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "climbing;climbing_adventure;gymnastics;aikido;"
            },
            "geometry": {"type": "Point", "coordinates": [30.39757, 59.88115]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Ботаническая улица, 70к1",
                "name": "Бильярдный зал",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "cue sports"
            },
            "geometry": {"type": "Point", "coordinates": [29.826798, 59.875989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Северная дорога, 12",
                "name": "Велотрек",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "cycling"
            },
            "geometry": {"type": "Point", "coordinates": [30.227285, 59.97445]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "name": "Beauty-dance",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "dance"
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чайковского, 63",
                "name": "Балтийские клинки",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fencing"
            },
            "geometry": {"type": "Point", "coordinates": [30.361673, 59.945842]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 27-я линия Васильевского острова, 16",
                "name": "Alex Fitness",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "contact_website": "https:\/\/alexfitness.ru\/finansist\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.254226, 59.932279]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 35Б",
                "name": "Тонус-lime",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "женский фитнес",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.37654, 59.935209]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 60",
                "name": "Sorbatti Fitness Lab",
                "building": null,
                "building_levels": null,
                "level": 3.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 3131815;+7 812 2431813",
                "contact_website": "https:\/\/www.sorbatti.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.339063, 59.974261]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгская набережная, 61",
                "name": "СитиФитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.322471, 59.980682]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "name": "Active Fitness Club",
                "building": null,
                "building_levels": null,
                "level": 2.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 812 6550990",
                "contact_website": "https:\/\/activefitnessclub.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 38",
                "name": "Всемфитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.239925, 60.022247]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "name": "Alex Fitness",
                "building": null,
                "building_levels": null,
                "level": 5.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 981 7186506",
                "contact_website": "https:\/\/spb.alexfitness.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Вологодская улица, 30",
                "name": "Aurum",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.251091, 60.075927]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 6",
                "name": "Fitness One Путиловский",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.282029, 59.852859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 53Ек1",
                "name": "iLove Sport",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 3135525",
                "contact_website": "http:\/\/www.isportspb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.377978, 60.04352]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ильюшина, 5к2",
                "name": "Fitness Land",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 3006868",
                "contact_website": "http:\/\/fitnessland.spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.251737, 60.008968]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 7:00-23:00; Sa-Su 9:00-23:00",
                "contact_phone": null,
                "contact_website": "https:\/\/sportlifeclub.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ушинского, 19к2",
                "name": "Alex Fitness",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7770909",
                "contact_website": "https:\/\/www.alexfitness.ru\/spb",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.411242, 60.0338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ново-Рыбинская улица, 19-21",
                "name": "Фитнес 24",
                "building": null,
                "building_levels": null,
                "level": 4.0,
                "type": "Спортивный центр",
                "description": "Имеется бассейн. Залы для групповых занятий. Зал для единоборств. Финская сауна и турецкая парная.",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3375838",
                "contact_website": "http:\/\/www.fitnessclub24.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness,_swimming_pool,_boxing"
            },
            "geometry": {"type": "Point", "coordinates": [30.337275, 59.902686]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Политехническая улица, 17к2",
                "name": "Fitness House",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-22:00",
                "contact_phone": "+7 812 6100606",
                "contact_website": "http:\/\/www.fitnesshouse.ru\/club\/65.html",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness;gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.367153, 59.999413]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "name": "Spasso",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Спортивно-оздоровительный центр",
                "opening_hours": null,
                "contact_phone": "+7 812 3354790",
                "contact_website": "http:\/\/www.spasso-spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "fitness;gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Варшавская улица, 5АЖ",
                "name": "ФОК Сокол",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gym"
            },
            "geometry": {"type": "Point", "coordinates": [30.308888, 59.878184]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Возрождения, 20А",
                "name": "Graffiti Gym",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/graffiti-gym.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gym"
            },
            "geometry": {"type": "Point", "coordinates": [30.273917, 59.881638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург,  ул. Маршала Захарова, 52",
                "name": "Хоккейная площадка",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "тренажеры под открытым небом",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.206759, 59.852393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дивенская улица, 4",
                "name": "Мундо Капоэйра",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.318877, 59.95985]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 15к2",
                "name": "Fitness Family",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.38211, 59.963647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "name": "Фитнес-клуб С.О.К.",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровский проспект, 16",
                "name": "СДЮСШОР «Центр художественной гимнастики \"Жемчужина”»",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.gimnastika-spb.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.271994, 59.958557]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Науки, 10к1",
                "name": "Академия",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 2947924;+7 812 9736878",
                "contact_website": "http:\/\/www.fitness-academia.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.391048, 60.015062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Науки, 10к1",
                "name": "ЕвроФитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 9557790;+7 812 9558757",
                "contact_website": "http:\/\/eurofitclub.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.391048, 60.015062]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 18",
                "name": "Дворец спорта «Юбилейный»",
                "building": null,
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "hockey"
            },
            "geometry": {"type": "Point", "coordinates": [30.292305, 59.950582]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург,  посёлок Понтонный, Волховстроевская улица, 1",
                "name": "Каприоль",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/vk.com\/club37314194",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "horse_racing"
            },
            "geometry": {"type": "Point", "coordinates": [30.627458, 59.780201]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Димитрова, 3к3",
                "name": "Ледовая арена «Купчино»",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "07:00-24:00",
                "contact_phone": "+7 812 9035763",
                "contact_website": "http:\/\/arenakupchino.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_hockey;ice_skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.392171, 59.840573]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Анисимова, 3",
                "name": "Школа конькобежного спорта",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "ice_skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.61083, 59.732888]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рузовская улица, 25",
                "name": "Подростково-молодёжный клуб «Славяне»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/admiralteisky.spb.ru\/%D0%BA%D0%BB%D1%83%D0%B1%D1%8B\/%D0%BF%D0%BC%D0%BA-%D1%81%D0%BB%D0%B0%D0%B2%D1%8F%D0%BD%D0%B5\/",
                "contact_vk": "https:\/\/vk.com\/club58070262",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "judo"
            },
            "geometry": {"type": "Point", "coordinates": [30.329585, 59.915359]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 21",
                "name": "Джокер Карт",
                "building": null,
                "building_levels": null,
                "level": 3.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "+7 812 9959583",
                "contact_website": "http:\/\/www.j-kart.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "karting"
            },
            "geometry": {"type": "Point", "coordinates": [30.256301, 60.00002]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Магнитогорская улица, 51Ю",
                "name": "Primo",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 448-84-48;+7 812 931-20-33;+7 812 931-20-25",
                "contact_website": "https:\/\/primokarting.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "karting"
            },
            "geometry": {"type": "Point", "coordinates": [30.430861, 59.93758]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, 41-й километр Приморского шоссе, 1С",
                "name": "FreshWind Дюны",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 921 9005805",
                "contact_website": "http:\/\/surfingclub.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "kitesurfing"
            },
            "geometry": {"type": "Point", "coordinates": [29.939375, 60.128601]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 73к5",
                "name": "Range",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/rangemma.ru\/",
                "contact_vk": "https:\/\/vk.com\/rangemma",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "martial_arts"
            },
            "geometry": {"type": "Point", "coordinates": [30.314808, 59.904189]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, аллея Поликарпова, 1",
                "name": "Клуб айкидо \"Тен-чи\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 3939624",
                "contact_website": "http:\/\/www.tenchi.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.293716, 60.007461]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 83",
                "name": "Спорткомплекс ДК им.Кирова",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.dkkirova.spb.ru\/sportkompleks",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.254001, 59.933577]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Варшавская улица, 5АлитЗ",
                "name": "Йога Айенгара",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.309068, 59.87869]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Главная улица, 24",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/building\/ulglavnaya_d24a",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.295315, 60.022018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 100",
                "name": "Futsal Junior",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.406382, 60.024482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 58к3",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/building\/dunayskiy",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.414467, 59.844488]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4 литАЕ",
                "name": "Зимний каток",
                "building": "pavilion",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": "Прокат спорт инвентаря",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.264772, 59.980709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 28",
                "name": "спортивный центр \"Комета\"",
                "building": "public",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.410488, 59.835763]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, улица Спирина, 10",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/building\/pos_krasnoe_selo_ulspirina_10",
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.086331, 59.742706]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Цитадельское шоссе, 28",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/gor_kronshtadt_tsitadelskoe_sh_d_28",
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.73579, 60.001802]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Ораниенбаумский проспект, 40",
                "name": "Cпортивный комплекс «Ломоносов»",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "форма относительно точная, положение примерное",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/lomonosov",
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.777732, 59.895967]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 102к2",
                "name": "ДЮСШ",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.320485, 59.896251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 80",
                "name": "Учебно-оздоровительный центр СГПУ им. Герцена",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.319291, 59.905132]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московское шоссе, 3к3",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 640-25-10",
                "contact_website": "http:\/\/www.directory.spb.ru\/fok\/fok1",
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.334616, 59.833764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Муринский парк",
                "name": "Nova Arena",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 7750055",
                "contact_website": "https:\/\/www.novaarena.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.398369, 60.027022]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к6",
                "name": "Межвузовский учебно-спортивный центр",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.30799, 59.868726]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Ботаническая улица, 66к4",
                "name": "Тренажёрный зал",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [29.827131, 59.874309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Песочный, 8-й квартал, 140",
                "name": "Качалка",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo, We, Fr 18:00-22:00",
                "contact_phone": "+7 812 5967377",
                "contact_website": "http:\/\/vk.com\/kachalkadk",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.171464, 60.122192]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Центральная улица, 6",
                "name": "Детскосельский, спортивно-оздоровительный центр",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.462473, 59.727177]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 58",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/fok_veteranov",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.234544, 59.837286]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 2к3",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Sa 06:45-23:00; Su 09:00-22:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/pr_ispyitateley_d2_k3",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.312526, 60.001401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 9к3",
                "name": "Таурас-Фитнес",
                "building": "commercial",
                "building_levels": 5.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00, Sa,Su,PH 09:00-22:00",
                "contact_phone": null,
                "contact_website": "taurasfitness.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.262409, 59.99066]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Антонова-Овсеенко, 2",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/fok\/fok2",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.455924, 59.906075]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Асафьева, 10к2",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/asafeva_d10_k2",
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.327429, 60.050412]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Доблести, 15",
                "name": "Спортивный комплекс \"Газпром\"",
                "building": "sports_centre",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/uldoblesti_15a",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.17653, 59.859578]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коммуны, 47",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": 5.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/ul_kommunyi_d47",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.493717, 59.958647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коммуны, 47",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.493717, 59.958647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Композиторов, 6",
                "name": "Океаниум",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 6768088;+7 812 9083908",
                "contact_website": "http:\/\/ozerki.oceanium.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.317099, 60.047232]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Захарова, 14к1",
                "name": "Sportlife",
                "building": "public",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 812 3330095",
                "contact_website": "https:\/\/www.sportlifeclub.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.163667, 59.858096]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ольминского, 26",
                "name": "Подростковый клуб \"Ленинградец\"",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.415734, 59.89552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Руставели, 51",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/building\/ul_rustaveli_d51",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.435182, 60.023529]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Фучика, 2А",
                "name": "Пляж",
                "building": "hangar",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.356894, 59.876003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Заводская дорога, 8",
                "name": "Cпортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.directory.spb.ru\/building\/strelna_zavodskaya_doroga_d8",
                "contact_vk": null,
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "multi;swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.006578, 59.85308]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 68В",
                "name": "клуб экстремальных видов спорта c9h13no3",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/c9h13no3.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "parkour"
            },
            "geometry": {"type": "Point", "coordinates": [30.316299, 59.985332]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Ивана Фомина, 6А",
                "name": "SkateTown",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9244768",
                "contact_website": "http:\/\/skatetown.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "roller_skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853478]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Гребного канала, 10",
                "name": "Гребной клуб \"Стрела\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "rowing"
            },
            "geometry": {"type": "Point", "coordinates": [30.217287, 59.976517]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Металлострой, Пушкинская улица, 3",
                "name": "Ижорец",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "school"
            },
            "geometry": {"type": "Point", "coordinates": [30.577475, 59.80655]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Октябрьская улица, 8",
                "name": "Стрелковый клуб \"Стреляный воробей\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/vorobey-club.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [30.622948, 59.738956]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "name": "Золотая пуля",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 911 9073786",
                "contact_website": "http:\/\/goldbullet.ru",
                "contact_vk": "https:\/\/vk.com\/tirgoldbullet",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Жака Дюкло, 62А",
                "name": "Спортивно-стрелковый клуб «Олимпиец»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.olympiec.com\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Александра Блока, 7В",
                "name": "Балтийский стрелковый центр",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 7141001",
                "contact_website": "http:\/\/www.tirspb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "shooting"
            },
            "geometry": {"type": "Point", "coordinates": [30.277205, 59.92479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Ш",
                "name": "Прокат финских саней",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.249411, 59.980776]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красногвардейский переулок, 15Д",
                "name": "Спортивный центр Дениса Басова",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 4072778",
                "contact_website": "http:\/\/ice-hall.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.325794, 59.981929]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мебельная улица, 33А",
                "name": "Ледовый зал «Олимпийские надежды»",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 3011733",
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.205088, 59.99436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120К",
                "name": "Империя",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.472903, 59.865384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Суздальский проспект, 29",
                "name": "Ледовая арена \"Гранд Каньон Айс\"",
                "building": "hangar",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 3322322",
                "contact_website": "http:\/\/grand-ice.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.338533, 60.060572]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Туполевская улица, 4",
                "name": "СДЮШОР фигурного катания на коньках",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "здание сложной формы, высота указана в среднем",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.262185, 60.003088]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, проспект Ленина, 59Ф",
                "name": "Лыжная база \"Прибой\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skiing"
            },
            "geometry": {"type": "Point", "coordinates": [29.720698, 60.208751]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, территория Пухтолова Гора, 1",
                "name": "Горнолыжный курорт \"Пухтолова гора\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/www.puhgora.spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "skiing"
            },
            "geometry": {"type": "Point", "coordinates": [29.683778, 60.232289]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Глиняная улица, 5к1",
                "name": "Метрополис Арена",
                "building": "industrial",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 9857498",
                "contact_website": "https:\/\/metropolis-arena.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.392081, 59.916406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Флотская улица, 12А",
                "name": "Котлин",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [29.770842, 59.997257]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 149В",
                "name": "Футбольный комплекс «Федерация»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9198615",
                "contact_website": "http:\/\/fc-federation.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.437042, 59.884018]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21",
                "name": "Спортшкола «Смена-Зенит»",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.410622, 60.003939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Верности, 21",
                "name": "СДЮСШОР «Смена»",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "soccer"
            },
            "geometry": {"type": "Point", "coordinates": [30.411934, 60.003174]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ушинского, 12Н",
                "name": "Пандора",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su,PH 09:00-23:00",
                "contact_phone": "+7 812 2905988",
                "contact_website": "http:\/\/sc-pandora.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "squash"
            },
            "geometry": {"type": "Point", "coordinates": [30.408736, 60.035445]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": 3.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": "+7 812 3351888",
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "swimming,_gym,_fitness,_crossfit"
            },
            "geometry": {"type": "Point", "coordinates": [30.397812, 60.011536]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "swimming;athletics;fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.379244, 59.869987]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 71",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": "https:\/\/sportlifeclub.ru\/clubs\/13",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "swimming;athletics;table_tennis;fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.337131, 59.840239]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "name": "Спортивный-клуб Action-force",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.action-force.net\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "taekwondo"
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 6",
                "name": "Теннисный клуб",
                "building": "pavilion",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.261161, 59.98178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский парк Победы",
                "name": "Gloria",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 3883410",
                "contact_website": "https:\/\/gloria-tennis.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, наб. Мартынова, 40\/38",
                "name": "Петербургский теннисный клуб имени В. И. Никифорова",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.253229, 59.974968]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Наличная улица, 44к6К",
                "name": "Tennis Style",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/tennis-style.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.227043, 59.95284]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 24А",
                "name": "Чемпион",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.276037, 59.84108]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рюхина улица, 9Д",
                "name": "Теннисный клуб Крестовский",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.256831, 59.970335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Академика Вернова, 1",
                "name": "Школа тенниса Вадима Давлетшина",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [29.986555, 60.058785]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Тухачевского, 14В",
                "name": "Лаун-теннис",
                "building": "hangar",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.422552, 59.969322]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южная дорога, 4к3",
                "name": "Детский Теннисный центр",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.241974, 59.967549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вязовая улица, 10",
                "name": "Академия волейбола Платонова",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "volleyball"
            },
            "geometry": {"type": "Point", "coordinates": [30.273611, 59.96716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Ульяновская улица, 3",
                "name": "Спортзал физического факультета",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "volleyball,basketball"
            },
            "geometry": {"type": "Point", "coordinates": [29.8236, 59.88157]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Героев-Пожарных",
                "name": "Вейк парк Купчино",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "water_ski"
            },
            "geometry": {"type": "Point", "coordinates": [30.410003, 59.854106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Короленко, 10\/3",
                "name": "Подростково-молодежный клуб «Атлант»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "нет на perspektiva-klab.ru",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club102352436",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "weightlifting"
            },
            "geometry": {"type": "Point", "coordinates": [30.35004, 59.94106]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта, Береговая улица, 19",
                "name": "Твой ветер",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "11:00-20:00 open; 20:00-22:00 unknown \"till the last customer\"",
                "contact_phone": "+7 812 9636222",
                "contact_website": "http:\/\/xn--b1aajar3asjf.xn--p1ai\/",
                "contact_vk": "https:\/\/vk.com\/windsurfingspb",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": "windsurfing"
            },
            "geometry": {"type": "Point", "coordinates": [30.158124, 59.99211]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лесной проспект, 59к7",
                "name": "Northern Storm Wrestling",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "wrestling"
            },
            "geometry": {"type": "Point", "coordinates": [30.345468, 59.983743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 35",
                "name": "Зелёная Тара",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 921 6596059",
                "contact_website": "https:\/\/z-tara.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "yoga"
            },
            "geometry": {"type": "Point", "coordinates": [30.314395, 59.957151]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский район, улица Победы, 5",
                "name": "Exhale",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "09:00-21:00 open",
                "contact_phone": "+7 812 2456220",
                "contact_website": "http:\/\/exhalespb.com\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": "yoga"
            },
            "geometry": {"type": "Point", "coordinates": [30.316569, 59.858566]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург,  Московский просп., 102, корп. 2",
                "name": "Детско-юношеская спортивная школа \"Московская застава\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 3-й Верхний переулок, 15к1Б",
                "name": "CrossFit Record",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 9820062",
                "contact_website": "https:\/\/crossfitrekord.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33997, 60.067716]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 33",
                "name": "Танцевальная студия",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27919, 59.94102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Автовская улица, 31Б",
                "name": "Батутный клуб \"Flipster\"",
                "building": null,
                "building_levels": null,
                "level": 3.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 11:00-23:00; Sa,Su 10:00-23:00",
                "contact_phone": "+7(812)921-39-69",
                "contact_website": "http:\/\/www.flipster.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276935, 59.874286]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 7:00-23:00; Sa,Su 9:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Благодатная улица, 51",
                "name": "Клуб \"Спутник\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330187, 59.875682]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Пороховская улица, 38к2",
                "name": "ГБУ Спортивная школа № 2, отделение плавания",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427304, 59.955728]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Разночинная улица, 25",
                "name": "Fitness house",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 4482256",
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286897, 59.961489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большеохтинский проспект, 11к2",
                "name": "Детско-Юношеский Центр \"Красногвардеец\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411413, 59.949253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 63Д",
                "name": "Стадион ВИФК",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340293, 59.975747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Боровая улица, 11-13",
                "name": "Монолит",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/athleticclubmonolith",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.920902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бородинская улица, 8-10",
                "name": "СОШ №308",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33423, 59.924524]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Будапештская улица, 44",
                "name": "Риф",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378984, 59.858363]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Будапештская улица, 4к2",
                "name": "Спортивно-оздоровительный комплекс",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371402, 59.874219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 19",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346932, 59.928519]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 14",
                "name": "Олимп",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.448037, 59.910537]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 33к1",
                "name": "Спортивный клуб Артемикс",
                "building": null,
                "building_levels": null,
                "level": 4.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410254, 59.833289]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 71к1",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440222, 59.933302]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "name": "ExstraSport",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 38к3",
                "name": "Фитнес \"Индустриальный\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467692, 59.95338]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Инженерная улица, 13",
                "name": "Спортивный комплекс ЦСКА «Инженерная»",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/cska.ru\/objects\/46",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": "no",
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340392, 59.937698]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 68",
                "name": "Комплексная школа высшего спортивного мастерства",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 2346342",
                "contact_website": "https:\/\/www.kshwsm.spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299168, 59.981235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирилловская улица, 7-9",
                "name": "Детско-юношеская спортивная школа Центрального района",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/centralsportschool.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38273, 59.934285]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 50",
                "name": "Центр спорта Центрального района СПб",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7-960-2328010",
                "contact_website": "http:\/\/cfks-centr.ru",
                "contact_vk": "kir50",
                "swimming_pool": null,
                "wheelchair": "limited",
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376711, 59.944932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Тверская улица, 25",
                "name": "СОК \"Ижорец\"",
                "building": "yes",
                "building_levels": 4.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 4692982",
                "contact_website": "https:\/\/www.sok-izhorets.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.609105, 59.738366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, улица Танкистов, 26к2",
                "name": "ДЮСШ имени Олимпийского чемпиона Николая Дроздецкого",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.567459, 59.74534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 21А",
                "name": "Фитнес Палас",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 3369033",
                "contact_website": "https:\/\/fitpalace.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294749, 59.932924]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "name": "Gym on Blood",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/www.gymonblood.com\/",
                "contact_vk": "https:\/\/vk.com\/gymonblood",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Корабельная улица, 6к3",
                "name": "Северные тигры",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243679, 59.877335]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, Гвардейская улица, 25к2Б",
                "name": "Спортивно-патриотический клуб \"Радогор\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.075362, 59.73877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 2",
                "name": "Санкт-Петербургский центр физической культуры и спорта",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261349, 59.875998]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 56",
                "name": "Матрица",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29686, 59.857129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 24",
                "name": "Fitnesshouse Аквапарк",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26214, 59.967795]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Кронштадтское шоссе, 43В",
                "name": "Спортивный лагерь \"Старт\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.683616, 60.025781]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Гидростроителей, 7",
                "name": "Центр единоборств",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.714042, 60.008522]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Литке, 10",
                "name": "СПОРТИВНАЯ ШКОЛА КРОНШТАДТСКОГО РАЙОНА САНКТ-ПЕТЕРБУРГА",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.728271, 60.007191]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 30к3",
                "name": "Лидер",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85литВ",
                "name": "Фитнес Хаус",
                "building": null,
                "building_levels": null,
                "level": 0.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146042, 59.994063]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 11\/7с1",
                "name": "Метрострой",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290122, 59.967462]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 160",
                "name": "Фитнес Хаус",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.fitnesshouse.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": "no",
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301055, 59.852384]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 161к3",
                "name": "СОШ №489",
                "building": null,
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318294, 59.851182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 84к1",
                "name": "Велнес клуб \"Альфа\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00, Sa-Su 08:00-23:00",
                "contact_phone": "+7 812 6953684",
                "contact_website": "http:\/\/www.alfa-wellness.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199177, 59.857649]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Леснозаводская улица, 3",
                "name": "СОК Звезда",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/www.sok-zvezda.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.454011, 59.868817]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лесной проспект, 16",
                "name": "Формула энергии",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:30-21:00",
                "contact_phone": "+7 812 5420044",
                "contact_website": "http:\/\/www.fenergy.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350058, 59.965003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Манежная улица, 3",
                "name": "ДЮСШ \"Манеж\"",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/manezh-spb.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763898, 59.913528]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Михайловская улица, 29с3",
                "name": "Центр физической культуры, спорта и здоровья Петродворцового района",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763467, 59.907591]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мебельная ул., 25, корп. 1",
                "name": "Син",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211322, 59.995561]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мебельная улица, 1к2",
                "name": "Fitness House",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249015, 59.990994]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 203А",
                "name": "СОШ №489",
                "building": null,
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318294, 59.846405]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московское шоссе, 7",
                "name": "SKYLIFE",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/skylife-center.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339215, 59.832317]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Моховая улица, 26Б",
                "name": "Спортивный клуб",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346914, 59.941971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 7",
                "name": "brazil-fight.ru",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/brazil-fight.ru\/",
                "contact_vk": "https:\/\/vk.com\/spb.fight.edinoborstva",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327367, 59.939068]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 123Б",
                "name": "Ленкай",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/lenkai.spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322614, 59.910916]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 203",
                "name": "Red Devil",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282532, 59.910587]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 51-53",
                "name": "Центр боевых искусств Сергия Радонежского",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.martialartscenter.ru\/",
                "contact_vk": "https:\/\/vk.com\/russianmartialart",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337392, 59.9297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Народная улица, 1",
                "name": "Лидер-Фит",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.459365, 59.8778]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Нижне-Каменская улица, 1",
                "name": "Спортивный комплекс \"Газпром\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23051, 60.029926]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 57к3",
                "name": "Рубин",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419561, 59.920735]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Орловский переулок, 1\/4В",
                "name": "Bodyflex Group",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363416, 59.931945]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Каховского, 1",
                "name": "Морская школа ДОСААФ",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244883, 59.952186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Челиева, 13В",
                "name": "Спортивный клуб \"Динамит\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477655, 59.889178]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Зверинская улица, 6",
                "name": "Капоэйра Cordao de Ouro",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.926565, 59.875705]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Озерковая улица, 23А",
                "name": "Cпортивная школа олимпийского резерва Петродворцового района",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/sportpetergof.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.913207, 59.868686]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Прогонная улица, 29Б",
                "name": "Спортивный комплекс",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.911267, 59.855421]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 15Ж",
                "name": "Спорткомплекс",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.895888, 59.879995]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 27Б",
                "name": "Детско-юношеский центр \"Петергоф\"",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.895564, 59.87425]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Суворовская улица, 1АШ",
                "name": "спорткомплекс",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.868516, 59.886483]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, улица Халтурина, 15к4",
                "name": "Тайфун",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [29.859784, 59.883385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 18",
                "name": "Hard Candy",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 6:30-00:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334409, 59.959269]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна, Заводская дорога, 8",
                "name": "Спортивно-оздоровительный комплекс Стрельна",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.006578, 59.85308]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 52",
                "name": "Клуб \"Дружба\" спортивный зал",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427187, 59.991592]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Александра Невского, 2Б",
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": "+7 812 6760220",
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386323, 59.925168]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Карла Фаберже, 8Е",
                "name": "Fitness House",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439692, 59.935205]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Металлострой, Садовая улица, 4Б",
                "name": "Спорткомплекс",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.56498, 59.812396]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8",
                "name": "Susanin fitness",
                "building": null,
                "building_levels": null,
                "level": 2.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-00:00; Sa-Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": "https:\/\/susaninfitness.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338317, 60.073759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Песочный, Школьная улица, 16",
                "name": "Детско-юношеская школа олимпийского резерва имени Владимира Коренькова",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151809, 60.122111]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Санкт-Петербургское шоссе, 128",
                "name": "Стрельна Арена",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.008527, 59.856153]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Пулковское, 34а",
                "name": "Высота",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359754, 59.760047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 121",
                "name": "Фитнес Хаус",
                "building": "public",
                "building_levels": 4.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.fitnesshouse.ru\/fh-na-veteranov.html",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.171895, 59.83413]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 16",
                "name": "Физкультурно-оздоровительный центр",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254118, 59.845596]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Динамо, 44А",
                "name": "Центр Sport Plus",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26541, 59.968254]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Динамо, 44К",
                "name": "Секция самбо ФСО «Динамо»",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": "Зал самбо, бассейн",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263281, 59.968025]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 38к3",
                "name": "ЖЕSТЬ",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Центр современных молодежных видов спорта",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355735, 59.855882]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Маршала Блюхера, 9к3",
                "name": "Fitness house",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373351, 59.97812]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 24Б",
                "name": "Шторм",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277034, 59.841581]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Наставников, 24к1",
                "name": "Fitness House",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489243, 59.945779]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 13к1",
                "name": "Школа танцев \"Танденс\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379415, 59.995984]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 130",
                "name": "Дворец Спорта \"Алмаз-Антей\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483161, 59.852005]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Пятилеток, 1",
                "name": "Ледовый дворец",
                "building": "yes",
                "building_levels": 1.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46789, 59.921534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Раевского, 14",
                "name": "Спортивная детско-юношеская школа олимпийского резерва",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36294, 60.013573]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Раевского, 16",
                "name": "Мужество",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Пристройка, нарисовано на глаз.",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361305, 60.012939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Раевского, 16",
                "name": "Спорткомлекс им. В.И. Алексеева",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361305, 60.012939]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Славы, 32",
                "name": "Jump",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388218, 59.859162]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 170В",
                "name": "Рус-Фитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245934, 59.853306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Тореза, 114к2",
                "name": "Спортивный клуб OLYMP - Велотрек",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/spb.olympclubs.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325184, 60.023502]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Тореза, 35к3",
                "name": "Спортзал",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355376, 60.009179]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": 0.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.307119, 59.906287]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 23Б",
                "name": "Спорткомплекс Радиополитехникума",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331175, 60.003957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 97",
                "name": "Спортивно-оздоровительный центр",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320324, 60.025992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Горная улица, 2",
                "name": "Центр детского досуга \"Каскад\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.kaskad-club.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415428, 59.693155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Горная улица, 2",
                "name": "Центр детского досуга \"Каскад\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.kaskad-club.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415428, 59.693155]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Клубная площадь, 1",
                "name": "«Павловчанин» молодежно-подростковый клуб",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401819, 59.65408]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20Б",
                "name": "Центр физической культуры, спорта и здоровья \"Царское село\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.445971, 59.688868]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Российский проспект, 6",
                "name": "Хоккейный город (База хоккейного клуба СКА)",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46975, 59.923838]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рюхина улица, 9",
                "name": "Лапландия Парк",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255097, 59.969731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, сад Спартак",
                "name": "Спорткомплекс \"Обуховец\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486207, 59.852429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 62",
                "name": "Фитнесс-центр \"Newton\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.newtonefitness.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408341, 59.952236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецкая улица, 4",
                "name": "Shaping",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292736, 59.986821]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 87к2",
                "name": "Центр спорта Василеостровского района, ледовая арена ХК Нева",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.250031, 59.938788]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 4",
                "name": "СДЮСШОР №1 Адмиралтейского района",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278345, 59.913866]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Пороховые",
                "name": "Арена",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 5290870;+7 812 5294789",
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482838, 59.952398]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 3БЗ",
                "name": "Спортзал ВАС имени С.М. Буденного",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377609, 60.013348]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торжковская улица, 30Ак1",
                "name": "Сет",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316434, 59.992789]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 30",
                "name": "Ледовая арена",
                "building": "yes",
                "building_levels": 4.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432416, 59.887142]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 82",
                "name": "спортивный центр \"Звезда\"",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4633, 59.864001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Брянцева, 5к3",
                "name": "СДЮСШОР №3",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405259, 60.033796]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 9",
                "name": "Дворец спортивных игр «Зенит»",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399717, 60.001721]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 6",
                "name": "Sportlife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 7:00-23:00; Sa-Su 9:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265616, 59.879918]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Грибалёвой, 9к2",
                "name": "Шанс-арена",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355466, 59.984544]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 35Д",
                "name": "СДЮШОР 1 Центрального района",
                "building": "yes",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289691, 59.926345]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Демьяна Бедного, 9",
                "name": "Центр физической культуры, спорта и здоровья Калининского района",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386233, 60.038034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Доблести, 9",
                "name": "Olympic Touch",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/olympic-touch.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.17971, 59.863292]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 20Б",
                "name": "ФОЦ \"Сосновка Парк\"",
                "building": "yes",
                "building_levels": 2.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/vk.com\/sosnovkapark",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345701, 60.028191]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 8к1",
                "name": "Спортивный клуб Black Belt",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Бальные танцы, Taekwon-do",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356454, 60.011064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Здоровцева, 8",
                "name": "ЦФКСиЗ Красносельского района",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.163738, 59.840135]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 21к4",
                "name": "Детско-юношеская спортивная школа олимпийского резерва Кировского района",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2511, 59.849677]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ивана Фомина, 14к1",
                "name": "Империя Фитнеса",
                "building": "store",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34615, 60.04942]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коммуны, 39",
                "name": "Училище олимпийского резерва",
                "building": "school",
                "building_levels": 4.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.497292, 59.955183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Комсомола, 17",
                "name": "Fitness Olympia",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36585, 59.95498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Кржижановского, 8",
                "name": "Еврофитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46524, 59.931603]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленина, 9",
                "name": "Анима",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Женский спортивный клуб",
                "opening_hours": "Mo-Fr 10:00-22:00; Sa-Su 10:00-17:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306409, 59.961647]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 11",
                "name": "Теннисно-футбольный клуб “Волна”",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "не надо исключать парковки из территории спортцентра, если они действительно принадлежат ему",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333331, 59.832037]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 35к4",
                "name": "центр игровых видов спорта PRO",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "07:00-00:00;Sa-Su 9:00-23:00;PH 9:00-23:00",
                "contact_phone": "+7 812 3334720",
                "contact_website": "https:\/\/www.spb-pro.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279828, 59.888803]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Одоевского, 28Б",
                "name": "Sparta",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.spartaclub.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241676, 59.947005]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Олеко Дундича, 32",
                "name": "Дом молодёжи \"Купчино\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416767, 59.832909]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 36к1",
                "name": "Подростковый клуб «Ракета» подростково-молодёжного центра «Лигово»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.147919, 59.832475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Подковырова, 6\/55",
                "name": "Рив Гош Фитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Женский спортивный клуб",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307523, 59.962115]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Правды, 11Б",
                "name": "ФОК «Юность»",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/unost-spb.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339638, 59.922981]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119к3",
                "name": "Фитнес Хаус",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 10:00-22:00; Sa-Su 11:00-21:00",
                "contact_phone": "+7 812 6100606",
                "contact_website": "http:\/\/www.fitnesshouse.ru\/fh-na-savushkina.html",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226099, 59.988946]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Салова, 55к3Н",
                "name": "Центр пляжных видов спорта «Цунами»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Th 09:00-23:30; Fr 09:00-23:00; Sa-Su 09:00-21:00",
                "contact_phone": "+7 812 7485568",
                "contact_website": "https:\/\/tsunamisport.ru",
                "contact_vk": "https:\/\/vk.com\/sportbeachtsunami",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376792, 59.890175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Солдата Корзуна, 1к2",
                "name": "Fitness 24",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.fitnessclub24.ru\/clubs\/3-veteranov\/about\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216101, 59.834067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Солдата Корзуна, 40",
                "name": "Фитнес клуб",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221231, 59.831074]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Софьи Ковалевской, 3к1",
                "name": "Фитнес",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411377, 60.012431]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 23",
                "name": "Арс",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287895, 59.923004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 27\/39",
                "name": "Fitness Family",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-00:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 2444333",
                "contact_website": "https:\/\/fitness-family.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349699, 59.853812]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Фучика, 10к2В",
                "name": "Спорткомплекс учебного центра ГПС ГУ МЧС России по Санкт-Петербургу",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380502, 59.88521]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Зал единоборств",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.330869, 60.014525]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Университетская набережная, 7-9-11О",
                "name": "Спортивный зал СПбГУ",
                "building": "university",
                "building_levels": 3.0,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": "limited",
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298611, 59.940916]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фрунзенский район, муниципальный округ Волковское",
                "name": "Автодром",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Автодром и спортивная площадка",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "other",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366605, 59.892067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южная дорога, 9",
                "name": "Sport Palace",
                "building": "yes",
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "sports_centre",
                "precision": "exact",
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237787, 59.969024]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318515, 59.996859]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Динамит",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": "+7 812 5650111",
                "contact_website": "https:\/\/www.dynamitsport.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477533, 59.889228]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Арс",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288037, 59.923018]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Beauty-dance",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "dance"
            },
            "geometry": {"type": "Point", "coordinates": [30.309232, 59.993928]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Фитнес-клуб С.О.К.",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "gymnastics"
            },
            "geometry": {"type": "Point", "coordinates": [30.310536, 59.993372]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Планета",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics"
            },
            "geometry": {"type": "Point", "coordinates": [30.454112, 59.915096]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Спортзал",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35538, 60.009194]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Одиннадцать",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "10pin"
            },
            "geometry": {"type": "Point", "coordinates": [30.49195, 59.920432]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Прибой",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.218049, 59.903666]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Подростковый клуб «Ракета» подростково-молодёжного центра «Лигово»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148148, 59.832535]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.242074, 59.87839]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Детско-Юношеский Центр \"Красногвардеец\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411389, 59.949192]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244057, 59.877192]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Бассейн «Экран»",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.361906, 60.005111]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Fitness House",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00;Sa-Su 08:00-22:00",
                "contact_phone": "+7 812 6100606",
                "contact_website": "https:\/\/www.fitnesshouse.ru\/",
                "contact_vk": "https:\/\/vk.com\/fitnesshouse",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489435, 59.945648]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Бассейн школы №235",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.280528, 59.9272]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Sportlife",
                "building": null,
                "building_levels": null,
                "level": 6.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.349717, 59.914782]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-24:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics;boxing;running;weightlifting;swimming;fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.247442, 59.858601]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Школа танцев \"Танденс\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379268, 59.995959]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "athletics;swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.379926, 59.828619]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "wrestling"
            },
            "geometry": {"type": "Point", "coordinates": [30.345431, 59.98359]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Рубин",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "multi"
            },
            "geometry": {"type": "Point", "coordinates": [30.419656, 59.920797]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Фитнес \"Индустриальный\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467931, 59.95336]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Alex Fitness",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.411279, 60.03383]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Тонус-lime",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "женский фитнес",
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.376548, 59.935217]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "10pin"
            },
            "geometry": {"type": "Point", "coordinates": [30.352454, 59.867144]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "iLove Sport",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.377955, 60.043628]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Матрица",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296606, 59.857078]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Батутный клуб \"Flipster\"",
                "building": null,
                "building_levels": null,
                "level": 3.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 11:00-23:00; Sa,Su 10:00-23:00",
                "contact_phone": "+7 812 9213969",
                "contact_website": "http:\/\/www.flipster.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277284, 59.874972]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Graffiti Gym",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "gym"
            },
            "geometry": {"type": "Point", "coordinates": [30.274468, 59.881498]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Active Fitness Club",
                "building": null,
                "building_levels": null,
                "level": 2.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-24:00; Sa-Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.420673, 60.033996]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Fitness Land",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "fitness"
            },
            "geometry": {"type": "Point", "coordinates": [30.251768, 60.008835]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Клуб \"Спутник\"",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330189, 59.875636]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.374798, 60.002922]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Фитнес-центр",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324195, 60.041353]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 7:00-23:00; Sa,Su 9:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272934, 60.002173]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "SportLife",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Fr 07:00-23:00; Sa,Su 09:00-23:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324863, 60.053911]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245954, 59.853377]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Genbukan Dojo",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "aikido"
            },
            "geometry": {"type": "Point", "coordinates": [30.384511, 59.97726]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Gym Equipment",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3341, 60.031457]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.329107, 59.966195]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Olympic Touch",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.179815, 59.863317]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": null,
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "roller_skating"
            },
            "geometry": {"type": "Point", "coordinates": [30.323496, 60.052808]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Bumperball SPB",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": "Прием звонков: ежедневно 11:00-21:00 Просмотр клуба: Пн-Пт 17:00-20:00; Сб-Вс 11:00-20:00 (или по предварительному звонку) Проведение игр и мероприятий: ежедневно по предварительной брони",
                "opening_hours": null,
                "contact_phone": "+7 965 7865309",
                "contact_website": "http:\/\/bumper-ball.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "bumperball"
            },
            "geometry": {"type": "Point", "coordinates": [30.401637, 59.881645]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Exhale",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "09:00-21:00 open",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "yoga"
            },
            "geometry": {"type": "Point", "coordinates": [30.316642, 59.8587]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Балтийский Берег",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 812 7640400",
                "contact_website": "http:\/\/www.balticbereg.ru",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "climbing"
            },
            "geometry": {"type": "Point", "coordinates": [30.360165, 59.919511]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Артемикс",
                "building": null,
                "building_levels": null,
                "level": 4.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410118, 59.833234]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Федерация Ки-Айкидо Восточной Европы и России",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "dojo",
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "aikido"
            },
            "geometry": {"type": "Point", "coordinates": [30.470655, 59.932091]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Буль-Буль",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": "+7 999 0206230",
                "contact_website": "http:\/\/bulbulcentr.ru\/spbpushkin",
                "contact_vk": "https:\/\/vk.com\/bulbulspb",
                "swimming_pool": "yes",
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "swimming"
            },
            "geometry": {"type": "Point", "coordinates": [30.478179, 59.884714]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "J-Pro",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 911 0112677",
                "contact_website": "https:\/\/www.jprotennis.ru\/",
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "tennis"
            },
            "geometry": {"type": "Point", "coordinates": [30.500264, 59.934714]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "До Дянг",
                "building": null,
                "building_levels": null,
                "level": 1.0,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": "dojo",
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "taekwondo"
            },
            "geometry": {"type": "Point", "coordinates": [30.310837, 59.874321]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Лиговский",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "24\/7",
                "contact_phone": "+7 921 3179040;+7 921 5575535",
                "contact_website": "https:\/\/ligovsky.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/ligovsky_spb",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338561, 59.898972]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Гребная база СПбГМТУ",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "rowing"
            },
            "geometry": {"type": "Point", "coordinates": [30.278496, 59.974014]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Футбольная школа Санкт-Петербург на Пролетарской",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463076, 59.864055]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Футбольная школа Санкт-Петербург на проспекте КИМа",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249119, 59.949285]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Футбольная школа Санкт-Петербург в Выборгском районе",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335167, 60.025758]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Футбольная школа Санкт-Петербург в Невском районе",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.450394, 59.932514]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Футбольная школа Санкт-Петербург в Центральном районе",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32666, 59.927568]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Футбольная школа Санкт-Петербург в Красносельском районе",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.138999, 59.832498]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Детско-юношеская футбольная школа Санкт-Петербург в Приморском районе",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241782, 60.027337]}
        },
        {"type": "Feature",
            "properties": {
                "address": null,
                "name": "Детско-юношеская футбольная школа",
                "building": null,
                "building_levels": null,
                "level": null,
                "type": "Спортивный центр",
                "description": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/fcspbclub",
                "swimming_pool": null,
                "wheelchair": null,
                "amenity": null,
                "precision": null,
                "city_function": "Спорт",
                "sport_type": "football"
            },
            "geometry": {"type": "Point", "coordinates": [30.329625, 59.899357]}
        }
    ]
}
