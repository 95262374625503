//https://t.me/elemnt3 Y:2021
import React from "react";
import {Col, Row} from 'antd';
import EntitiesPieA from "./EntitiesPieA";


function GreenZones() {
    return (
        <>
            <Row gutter={[24, 12]} style={{ display: 'flex', justifyContent: 'center',  width: '100%' }}>
                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}}>
                    <EntitiesPieA style={{ width: '1200px' }} />
                </Col>
            </Row>
            {/*<Row gutter={[24, 12]} style={{ display: 'flex', justifyContent: 'center'}}>*/}
            {/*    <Col span={16}><EntitiesPieA /></Col>*/}
            {/*</Row>*/}
        </>
    )
}

export default GreenZones;
