export const shop_souvenirs = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "city_function": "Товары для туристов",
                "name": "Marmalato",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/marmalato.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 25",
                "city_function": "Товары для туристов",
                "name": "Glamour",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.280268, 59.942043]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 38",
                "city_function": "Товары для туристов",
                "name": "Магазин Императорского фарфорового завода",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.441722, 59.878401]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 13к1",
                "city_function": "Товары для туристов",
                "name": "Пестрая лента",
                "opening_hours": "Mo-Fr 10:00-18:00",
                "level": -1.0,
                "contact_website": "http:\/\/plenta.spb.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 491 10 44",
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.331445, 60.042487]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Рылеева, 4",
                "city_function": "Товары для туристов",
                "name": "Офис-экспресс",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "level": null,
                "contact_website": "http:\/\/ofxp.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.354882, 59.942615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 21",
                "city_function": "Товары для туристов",
                "name": "Кардамон",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.348189, 59.943255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 22Б",
                "city_function": "Товары для туристов",
                "name": "Матрёна",
                "opening_hours": "Mo-Sa 10:00-19:00; Su 10:00-18:00",
                "level": null,
                "contact_website": "http:\/\/www.matrena-magazin.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3720429",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.280142, 59.844402]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 33",
                "city_function": "Товары для туристов",
                "name": "Центр продажи монет",
                "opening_hours": "Mo-Sa 09:00-21:00; Su 11:00-17:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329505, 59.934668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 17-19",
                "city_function": "Товары для туристов",
                "name": "Надо же!",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.348046, 59.943629]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Разночинная улица, 14",
                "city_function": "Товары для туристов",
                "name": "Пункт самовывоза интернет магазинов Qnits, офис 418",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.291973, 59.95876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 15",
                "city_function": "Товары для туристов",
                "name": "Giftsburg",
                "opening_hours": null,
                "level": 0.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309077, 59.916275]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 19",
                "city_function": "Товары для туристов",
                "name": "Northern Venice",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club94019156",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.297686, 59.921385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "city_function": "Товары для туристов",
                "name": "Rediska Project",
                "opening_hours": "12:00-21:00",
                "level": 0.0,
                "contact_website": "https:\/\/www.rediskaproject.ru\/",
                "contact_vk": "https:\/\/vk.com\/rediskashop",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 5",
                "city_function": "Товары для туристов",
                "name": "UNION ART",
                "opening_hours": null,
                "level": -1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300794, 59.934416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 80к1Б",
                "city_function": "Товары для туристов",
                "name": "Страна фотокниг",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.374708, 60.035989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 2",
                "city_function": "Товары для туристов",
                "name": "Слонопотамия.рф",
                "opening_hours": "Mo-Fr 09:00-20:00",
                "level": null,
                "contact_website": "slonopotamia.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310658, 59.92424]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровская набережная, 4",
                "city_function": "Товары для туристов",
                "name": "Базар",
                "opening_hours": "09:00-20:00",
                "level": null,
                "contact_website": "http:\/\/souvenirboutique.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4495047",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330394, 59.953759]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 73",
                "city_function": "Товары для туристов",
                "name": "БронзаМания",
                "opening_hours": null,
                "level": null,
                "contact_website": "https:\/\/bronzamania.ru\/",
                "contact_vk": "https:\/\/vk.com\/bronzamania",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.328427, 59.926255]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 2",
                "city_function": "Товары для туристов",
                "name": "Мир Сувенир",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/souvenirboutique.com\/",
                "contact_vk": "https:\/\/vk.com\/club88224934",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.939555]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 28",
                "city_function": "Товары для туристов",
                "name": "Полянка",
                "opening_hours": "10:00-22:00",
                "level": 0.0,
                "contact_website": "http:\/\/poliankaspb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 931 9604236",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.325866, 59.93584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки",
                "city_function": "Товары для туристов",
                "name": "Пунто",
                "opening_hours": "10:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/punto_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.307675, 59.931797]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 40",
                "city_function": "Товары для туристов",
                "name": "Закка",
                "opening_hours": null,
                "level": null,
                "contact_website": "https:\/\/zakka.ru\/",
                "contact_vk": "https:\/\/vk.com\/zakka_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318563, 59.914493]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 59",
                "city_function": "Товары для туристов",
                "name": "Ганг",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": "https:\/\/www.gangshop.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/gangmag",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.347112, 59.92217]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 42",
                "city_function": "Товары для туристов",
                "name": "Кошер",
                "opening_hours": "Su-Th 09:00-21:00; Fr 09:00-21:00, sunset-24:00 off",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/kosher_spb",
                "contact_instagram": null,
                "contact_phone": "+7 812 5753859",
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.290895, 59.925101]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Караванная улица, 11\/64",
                "city_function": "Товары для туристов",
                "name": "Lilkko",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/www.lilkko.ru\/",
                "contact_vk": "https:\/\/vk.com\/lilkko",
                "contact_instagram": null,
                "contact_phone": "+7 800 5058964",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.340895, 59.934069]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 60",
                "city_function": "Товары для туристов",
                "name": "Taste of Russia",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "http:\/\/spb-souvenirs.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.33917, 59.93406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 10",
                "city_function": "Товары для туристов",
                "name": "Сувенирная лавка",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327501, 59.936444]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 79",
                "city_function": "Товары для туристов",
                "name": "Taste of Russia",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": "http:\/\/spb-souvenirs.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.3587, 59.931057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 2",
                "city_function": "Товары для туристов",
                "name": "Гжель",
                "opening_hours": "Mo-Fr 10:00-19:00; Sa 11:00-18:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.297911, 59.965854]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "city_function": "Товары для туристов",
                "name": "Императорский фарфор",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Императорский фарфор",
                "opening_hours": "24\/7",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Художников, 11",
                "city_function": "Товары для туристов",
                "name": "Магазин подарков",
                "opening_hours": "Mo-Su 11:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.345468, 60.036119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Ч",
                "city_function": "Товары для туристов",
                "name": "Магазин эксклюзивных сувениров",
                "opening_hours": "Mo-Su 10:00-18:30",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.315392, 59.95033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Эрмитаж - Музейный магазин",
                "opening_hours": "24\/7",
                "level": 1.0,
                "contact_website": "https:\/\/www.hermitagemuseum.org\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 46",
                "city_function": "Товары для туристов",
                "name": "Русский янтарь (Russian Amber)",
                "opening_hours": "09:00-22:00",
                "level": null,
                "contact_website": "http:\/\/souvenirboutique.com",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4244328",
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.333565, 59.934777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3И",
                "city_function": "Товары для туристов",
                "name": "Санкт-Петербургские сувениры",
                "opening_hours": "Mo-Su 10:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.322022, 59.950853]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Троицкая площадь Петроградской стороны, 1",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327843, 59.952921]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 17к3",
                "city_function": "Товары для туристов",
                "name": "Табак и Подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316263, 59.827415]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "city_function": "Товары для туристов",
                "name": "Alessandro Frenza",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Славы, 16",
                "city_function": "Товары для туристов",
                "name": "artpolka",
                "opening_hours": "Mo-Su 11:00-20:00",
                "level": null,
                "contact_website": "http:\/\/www.artpolka.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.382074, 59.857206]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Postulat",
                "opening_hours": "24\/7",
                "level": null,
                "contact_website": "https:\/\/postulat.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Замятин переулок, 4",
                "city_function": "Товары для туристов",
                "name": "Букетная лавка № 1",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.296671, 59.933618]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар",
                "city_function": "Товары для туристов",
                "name": "Сувениры со всего мира",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.297776, 59.933298]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 22",
                "city_function": "Товары для туристов",
                "name": "Магазин №66",
                "opening_hours": "Mo-Su 09:00-22:00",
                "level": null,
                "contact_website": "https:\/\/instagram.com\/chemodan_dvoih",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 952 3868044",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309068, 59.910099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "city_function": "Товары для туристов",
                "name": "Мишки из роз",
                "opening_hours": null,
                "level": null,
                "contact_website": "https:\/\/xn-----6kc3abbcnq1av3g3b.xn--p1ai\/",
                "contact_vk": null,
                "contact_instagram": "https:\/\/www.instagram.com\/mishki_iz_roz_vbg\/",
                "contact_phone": "+7 931 000 29 25",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург,  набережная Обводного канала 134 ",
                "city_function": "Товары для туристов",
                "name": "ООО \"СОМС\"",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "level": null,
                "contact_website": "http:\/\/www.dom-monet.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7(965)0562024",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.2768, 59.908597]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 30к1",
                "city_function": "Товары для туристов",
                "name": "Подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327672, 60.054837]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 7\/9",
                "city_function": "Товары для туристов",
                "name": "Магия пряжи",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.35181, 59.95612]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Почтамтский переулок, 4",
                "city_function": "Товары для туристов",
                "name": "Марка",
                "opening_hours": "Mo-Su 10:00-20:00",
                "level": null,
                "contact_website": "http:\/\/rusmarka.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3207307",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.301881, 59.932531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "Зенит",
                "opening_hours": null,
                "level": null,
                "contact_website": "https:\/\/www.shop.fc-zenit.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4380838",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362131, 59.929479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 4",
                "city_function": "Товары для туристов",
                "name": "ArtSpace",
                "opening_hours": "Mo-Su 08:00-22:00",
                "level": null,
                "contact_website": "http:\/\/souvenirboutique.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4560668",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.298171, 59.932698]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 23",
                "city_function": "Товары для туристов",
                "name": "Matryoshka Studio",
                "opening_hours": null,
                "level": 0.0,
                "contact_website": "http:\/\/www.stories.ru.com",
                "contact_vk": "https:\/\/vk.com\/spb_stories",
                "contact_instagram": null,
                "contact_phone": "+7 921 8993535",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309068, 59.934429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 5",
                "city_function": "Товары для туристов",
                "name": "Arts Square",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/artsquarestore.com\/",
                "contact_vk": "https:\/\/vk.com\/arts_square_gifts_store",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329738, 59.936674]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 1\/8",
                "city_function": "Товары для туристов",
                "name": "Amber & Art",
                "opening_hours": "09:00-20:00",
                "level": null,
                "contact_website": "http:\/\/belugadeluxe.com\/stores\/amber-art\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "limited",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329145, 59.942088]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 4",
                "city_function": "Товары для туристов",
                "name": "Beluga Deluxe",
                "opening_hours": "09:00-20:00",
                "level": null,
                "contact_website": "http:\/\/belugadeluxe.com\/stores\/beluga\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3258264",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.328507, 59.937175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Труда, 2",
                "city_function": "Товары для туристов",
                "name": "Northway",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/belugadeluxe.com\/stores\/northway\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3122062",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.291712, 59.932996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 9",
                "city_function": "Товары для туристов",
                "name": "Алхимия",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": "http:\/\/ethnopiter.ru\/alquimia.html",
                "contact_vk": "https:\/\/vk.com\/alquimia",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.929637]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 73к5",
                "city_function": "Товары для туристов",
                "name": "Товары СССР",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/magazinsssr.ru\/",
                "contact_vk": "https:\/\/vk.com\/club161134849",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.314808, 59.904189]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Крылова, 1Ж",
                "city_function": "Товары для туристов",
                "name": "Milo",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": "http:\/\/mi-lo.ru\/",
                "contact_vk": "https:\/\/vk.com\/milospb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.332352, 59.932703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 70\/1",
                "city_function": "Товары для туристов",
                "name": "Мир праздника",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/mirparty.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9872005;+7 812 9872006",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320899, 60.018857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 35",
                "city_function": "Товары для туристов",
                "name": "Monarch",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/monarch.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.319183, 59.940722]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 33",
                "city_function": "Товары для туристов",
                "name": "Parivar",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/parivar-shop.ru\/",
                "contact_vk": "https:\/\/vk.com\/parivar",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.319848, 59.929705]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 10",
                "city_function": "Товары для туристов",
                "name": "Презент",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": "http:\/\/prezent.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.359715, 59.947343]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 79",
                "city_function": "Товары для туристов",
                "name": "Прокреатиф",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/prokreatif.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316919, 59.900877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Труда, 6",
                "city_function": "Товары для туристов",
                "name": "Red October",
                "opening_hours": "Mo-Su 10:00-19:00",
                "level": null,
                "contact_website": "http:\/\/redoctoberstore.com",
                "contact_vk": "https:\/\/vk.com\/redoctoberstore",
                "contact_instagram": null,
                "contact_phone": "+7 812 3120604",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293832, 59.93131]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Миллионная улица, 11",
                "city_function": "Товары для туристов",
                "name": "Русский век",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": "http:\/\/russvek.ru\/",
                "contact_vk": "https:\/\/vk.com\/russvek",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324896, 59.944197]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 19",
                "city_function": "Товары для туристов",
                "name": "Amber",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": "http:\/\/souvenirboutique.com\/",
                "contact_vk": "https:\/\/vk.com\/souvenirboutiquecom",
                "contact_instagram": null,
                "contact_phone": "+7 812 3121744",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310245, 59.934853]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 44",
                "city_function": "Товары для туристов",
                "name": "Solo",
                "opening_hours": null,
                "level": 0.0,
                "contact_website": "http:\/\/souvenirboutique.com\/",
                "contact_vk": "https:\/\/vk.com\/souvenirboutiquecom",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320387, 59.936444]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 8",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "http:\/\/tfsouvenir.ru\/magaziny\/8",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313577, 59.935633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 2",
                "city_function": "Товары для туристов",
                "name": "Подарки",
                "opening_hours": "Mo-Fr 11:00-20:00; Sa-Su 11:00-19:00",
                "level": null,
                "contact_website": "http:\/\/www.design-gallery.ru\/studios\/podarki",
                "contact_vk": "https:\/\/vk.com\/gd_bulthaup",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324447, 59.939555]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белинского, 8",
                "city_function": "Товары для туристов",
                "name": "Галерея солнца",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/www.ezopiter.ru\/index.html?id=\/vip\/dikovinka",
                "contact_vk": "https:\/\/vk.com\/dikovinnye",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.347318, 59.938432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маяковского, 2\/94",
                "city_function": "Товары для туристов",
                "name": "Ростовская финифть",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "http:\/\/www.finift-nhp.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.35384, 59.932599]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 32-34",
                "city_function": "Товары для туристов",
                "name": "Лавка Аладдина",
                "opening_hours": "Tu-Su 13:00-20:00",
                "level": null,
                "contact_website": "http:\/\/www.lavka-aladdina.ru\/",
                "contact_vk": "https:\/\/vk.com\/lavka_aladdina",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329037, 59.935511]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 10",
                "city_function": "Товары для туристов",
                "name": "Магазин сувениров",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/www.lenfilm.ru\/service\/Suveniryi\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316982, 59.958273]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 81",
                "city_function": "Товары для туристов",
                "name": "Мерси",
                "opening_hours": "12:00-19:00",
                "level": null,
                "contact_website": "http:\/\/www.merci.gift\/",
                "contact_vk": "https:\/\/vk.com\/merci.gift",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.306849, 59.926918]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 47",
                "city_function": "Товары для туристов",
                "name": "Наш дом",
                "opening_hours": "Mo-Sa 11:00-20:00",
                "level": null,
                "contact_website": "http:\/\/www.nashdom.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.358628, 59.935701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "city_function": "Товары для туристов",
                "name": "P.S.",
                "opening_hours": "Mo-Fr 11:00-21:00; Sa-Su 11:00-20:00",
                "level": null,
                "contact_website": "http:\/\/www.pitersouvenir.ru\/",
                "contact_vk": "https:\/\/vk.com\/salon_podarkov",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гжатская улица, 22к1",
                "city_function": "Товары для туристов",
                "name": "Радость дарить",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/www.raddarit.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.388488, 60.011725]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 48",
                "city_function": "Товары для туристов",
                "name": "Роза Мира",
                "opening_hours": "10:00-20:00",
                "level": null,
                "contact_website": "http:\/\/www.rozamiraspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/rozamiraspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313847, 59.924857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "city_function": "Товары для туристов",
                "name": "Жёлтый куб",
                "opening_hours": null,
                "level": null,
                "contact_website": "http:\/\/www.ycube.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 800 2008809",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Новаторов бульвар, 112 к2",
                "city_function": "Товары для туристов",
                "name": "Bonfutur",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": "https:\/\/bonfutur.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.313146, 59.878351]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, переулок Крылова, 2",
                "city_function": "Товары для туристов",
                "name": "Дарград",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": "https:\/\/dargrad.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.333745, 59.932847]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 14\/1",
                "city_function": "Товары для туристов",
                "name": "Nagual",
                "opening_hours": "Mo-Fr 11:00-21:00, Sa,Su 12:00-20:00",
                "level": null,
                "contact_website": "https:\/\/fazanspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/fazanspb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.346555, 59.92552]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Манежный переулок, 6",
                "city_function": "Товары для туристов",
                "name": "Мам, купи!",
                "opening_hours": "10:00-20:00",
                "level": null,
                "contact_website": "https:\/\/mamcupy.com\/",
                "contact_vk": "https:\/\/vk.com\/mam_cupy",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.353732, 59.943404]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 105",
                "city_function": "Товары для туристов",
                "name": "MiriShop",
                "opening_hours": null,
                "level": null,
                "contact_website": "https:\/\/mirishop.ru\/",
                "contact_vk": "https:\/\/vk.com\/mirishop",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.368473, 59.929443]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 30",
                "city_function": "Товары для туристов",
                "name": "My-shop.ru",
                "opening_hours": "Mo-Fr 09:00-20:00; Sa-Su 10:00-19:00",
                "level": 0.0,
                "contact_website": "https:\/\/my-shop.ru\/my\/helper_70\/point_2019",
                "contact_vk": "https:\/\/vk.com\/my_shop_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339503, 59.924745]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 69",
                "city_function": "Товары для туристов",
                "name": "Тульские самовары",
                "opening_hours": "Mo-Su 10:00-20:00",
                "level": null,
                "contact_website": "https:\/\/samovary.ru\/",
                "contact_vk": "https:\/\/vk.com\/samovary",
                "contact_instagram": null,
                "contact_phone": "+7 812 3132450",
                "wheelchair": "limited",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329927, 59.922833]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "city_function": "Товары для туристов",
                "name": "Ferrari Store",
                "opening_hours": null,
                "level": null,
                "contact_website": "https:\/\/store.ferrari.com\/ru_ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 3",
                "city_function": "Товары для туристов",
                "name": "tabakpodarki",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": "https:\/\/tabakpodarki.ru\/",
                "contact_vk": "https:\/\/vk.com\/tabakpodarki_shop",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.343231, 59.925606]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 34",
                "city_function": "Товары для туристов",
                "name": "Подарок на горошине",
                "opening_hours": "Mo-Sa 11:00-21:00; Su 11:00-20:00",
                "level": null,
                "contact_website": "https:\/\/vk.com\/podaroknagoroshine",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 3108506;+7 812 9822650",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.32151, 59.929082]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 29",
                "city_function": "Товары для туристов",
                "name": "Полка room",
                "opening_hours": "11:00-20:00; Sa-Su, PH 11:00-19:00",
                "level": null,
                "contact_website": "https:\/\/vk.com\/svoyapolkaspb",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313155, 59.964584]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 32",
                "city_function": "Товары для туристов",
                "name": "Вятские промыслы",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": "https:\/\/vk.com\/viatskiepromisli",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.929434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 30",
                "city_function": "Товары для туристов",
                "name": "Другие подарки",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/www.drugiepodarki.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 800 5556300",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.36956, 59.883611]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 30",
                "city_function": "Товары для туристов",
                "name": "lilkko",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/www.lilkko.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "limited",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.281112, 59.94051]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 106",
                "city_function": "Товары для туристов",
                "name": "Lilkko",
                "opening_hours": "10:00-23:00",
                "level": null,
                "contact_website": "https:\/\/www.lilkko.ru\/",
                "contact_vk": "https:\/\/vk.com\/lilkko",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.356912, 59.931846]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 39-41",
                "city_function": "Товары для туристов",
                "name": "Lilkko",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/www.lilkko.ru\/",
                "contact_vk": "https:\/\/vk.com\/lilkko",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.315895, 59.926197]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 16",
                "city_function": "Товары для туристов",
                "name": "Наследие",
                "opening_hours": "09:00-23:00",
                "level": null,
                "contact_website": "https:\/\/www.present.ru\/salony\/magazin-salon-nasledie-16\/",
                "contact_vk": "https:\/\/vk.com\/present_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327232, 59.935687]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 22-24",
                "city_function": "Товары для туристов",
                "name": "Наследие",
                "opening_hours": "09:00-23:00",
                "level": null,
                "contact_website": "https:\/\/www.present.ru\/salony\/magazin-salon-nasledie-nevskii-22\/",
                "contact_vk": "https:\/\/vk.com\/present_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324097, 59.935944]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 27",
                "city_function": "Товары для туристов",
                "name": "Наследие",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/www.present.ru\/salony\/sankt-peterburg-nevskiy-pr-29\/",
                "contact_vk": "https:\/\/vk.com\/present_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327052, 59.93502]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 32-34",
                "city_function": "Товары для туристов",
                "name": "Наследие",
                "opening_hours": "09:00-24:00",
                "level": null,
                "contact_website": "https:\/\/www.present.ru\/salony\/sankt-peterburg-nevskiy-pr-t-32\/",
                "contact_vk": "https:\/\/vk.com\/present_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329037, 59.935511]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 31",
                "city_function": "Товары для туристов",
                "name": "Красный куб",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": "https:\/\/www.redcube.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300902, 59.958679]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Зенит",
                "opening_hours": "24\/7",
                "level": 1.0,
                "contact_website": "https:\/\/www.shop.fc-zenit.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2440040",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 59",
                "city_function": "Товары для туристов",
                "name": "Симфония Камня",
                "opening_hours": "Mo-Fr 10:00-20:00; Sa 11:00-20:00; Su 11:00-19:00",
                "level": null,
                "contact_website": "https:\/\/www.symphgem.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.347615, 59.934042]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119к3",
                "city_function": "Товары для туристов",
                "name": "tabakpodarki",
                "opening_hours": null,
                "level": 0.0,
                "contact_website": "https:\/\/www.tabakpodarki.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.226099, 59.988946]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 66",
                "city_function": "Товары для туристов",
                "name": "Счастливый случай",
                "opening_hours": "09:00-21:00",
                "level": null,
                "contact_website": "https:\/\/www.telegraf-spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321842, 59.917709]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 74Ц",
                "city_function": "Товары для туристов",
                "name": "Владелец - Андреева Юлия Андреевна",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.331086, 59.911624]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 35",
                "city_function": "Товары для туристов",
                "name": "Невский символ",
                "opening_hours": "09:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.332631, 59.934172]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Щепяной переулок, 1В",
                "city_function": "Товары для туристов",
                "name": "Troika Art Store",
                "opening_hours": "09:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.301783, 59.920411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 57\/1",
                "city_function": "Товары для туристов",
                "name": "Imperial",
                "opening_hours": "Mo-Su 11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.307406, 59.96262]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Балтийского вокзала, 1",
                "city_function": "Товары для туристов",
                "name": "Цветовик",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299456, 59.907207]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 26",
                "city_function": "Товары для туристов",
                "name": "Симфония камня",
                "opening_hours": "Mo-Sa 11:00-20:00; Su 11:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.281723, 59.939996]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "city_function": "Товары для туристов",
                "name": "1+1",
                "opening_hours": null,
                "level": -1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Б",
                "city_function": "Товары для туристов",
                "name": "Магазин художественного стекла",
                "opening_hours": "Th-Su 11:00-17:30; We 13:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268509, 59.980267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Лейтенанта Шмидта, 33",
                "city_function": "Товары для туристов",
                "name": "Бабушка",
                "opening_hours": "Mo-Su 09:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.274267, 59.932788]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 151",
                "city_function": "Товары для туристов",
                "name": "Магазин Императорского фарфорового завода",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.446672, 59.879809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Т",
                "city_function": "Товары для туристов",
                "name": "Монетный двор",
                "opening_hours": "Mo-Su 10:00-18:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316452, 59.94864]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 83",
                "city_function": "Товары для туристов",
                "name": "Matryoshka",
                "opening_hours": "Mo-Su 08:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.254001, 59.933577]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 28",
                "city_function": "Товары для туристов",
                "name": "Красный куб",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.279325, 59.943129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронверкская улица, 8",
                "city_function": "Товары для туристов",
                "name": "Оригинальные подарки",
                "opening_hours": "Mo-Su 10:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.312158, 59.958516]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 7",
                "city_function": "Товары для туристов",
                "name": "Ну купи",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/clubnukupi",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.353786, 59.943809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 47",
                "city_function": "Товары для туристов",
                "name": "Люстры и подарки",
                "opening_hours": "Mo-Sa 11:00-20:00; Su 11:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323926, 60.014023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 98",
                "city_function": "Товары для туристов",
                "name": "Русский сувенир",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.355115, 59.932045]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 88",
                "city_function": "Товары для туристов",
                "name": "Сувенир",
                "opening_hours": null,
                "level": -1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.351801, 59.932464]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мытнинская набережная, 7\/5",
                "city_function": "Товары для туристов",
                "name": "Museum",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.304199, 59.948429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 122",
                "city_function": "Товары для туристов",
                "name": "Gentle Flowers",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.306795, 59.918521]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пестеля, 11",
                "city_function": "Товары для туристов",
                "name": "Шафран",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/dikovinnye",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.34235, 59.942426]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 65к1",
                "city_function": "Товары для туристов",
                "name": "Цветочный Город",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.432802, 59.93021]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 7",
                "city_function": "Товары для туристов",
                "name": "Эрос",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.285218, 59.945265]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 10",
                "city_function": "Товары для туристов",
                "name": "Яркие подарки",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 5540104",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318671, 60.016483]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Скобелевский проспект, 17",
                "city_function": "Товары для туристов",
                "name": null,
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.3162, 60.01821]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Тореза, 44к2",
                "city_function": "Товары для туристов",
                "name": null,
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.347309, 60.008315]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 31к1",
                "city_function": "Товары для туристов",
                "name": "Солнечный круг",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.22866, 59.858634]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 29к2",
                "city_function": "Товары для туристов",
                "name": "Souvenir Shop",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.497418, 59.859854]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "city_function": "Товары для туристов",
                "name": "Art & Shock",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Балканская улица, 27",
                "city_function": "Товары для туристов",
                "name": "tabakpodarki",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.382909, 59.829554]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 158",
                "city_function": "Товары для туристов",
                "name": "Arabeski",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.349375, 59.912368]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "city_function": "Товары для туристов",
                "name": "Планета подарков",
                "opening_hours": null,
                "level": 0.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Седова, 82",
                "city_function": "Товары для туристов",
                "name": "Ника",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.434787, 59.875714]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 8к1",
                "city_function": "Товары для туристов",
                "name": "Найс",
                "opening_hours": "Mo-Sa 10:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.426594, 59.897158]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 26К",
                "city_function": "Товары для туристов",
                "name": "Каледонский Лес",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293159, 59.963201]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "city_function": "Товары для туристов",
                "name": "Цветы-Подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 73-75",
                "city_function": "Товары для туристов",
                "name": "Я в Питере!",
                "opening_hours": "10:00-23:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.356176, 59.931404]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Мичуринская улица, 12",
                "city_function": "Товары для туристов",
                "name": "Кот Готье",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327402, 59.95566]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 126к1",
                "city_function": "Товары для туристов",
                "name": "Podarkoff",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.32424, 60.041094]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 4",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.338101, 59.93507]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Hockey club",
                "opening_hours": null,
                "level": 1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Hockey Club",
                "opening_hours": "24\/7",
                "level": 1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 56АТ",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.375399, 59.950064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Тамбасова, 4к1",
                "city_function": "Товары для туристов",
                "name": "Планета подарков",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.158762, 59.841672]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 5",
                "city_function": "Товары для туристов",
                "name": "Чудесное рядом",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club144216822",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313326, 59.936719]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "city_function": "Товары для туристов",
                "name": "Сувениры СПб",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 18",
                "city_function": "Товары для туристов",
                "name": "Подарки и сувениры",
                "opening_hours": "10:00-23:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318249, 59.936557]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Летний сад, литВ",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "We-Mo 10:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.33564, 59.94636]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 84к1",
                "city_function": "Товары для туристов",
                "name": "Flake",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.415572, 60.036852]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Глинки, 3-5-7",
                "city_function": "Товары для туристов",
                "name": "Магнолия",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.29562, 59.926959]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 2",
                "city_function": "Товары для туристов",
                "name": "Сувениры (ФГБНУ Центральный музей почвоведения имени В.В. Докучаева)",
                "opening_hours": "Mo-Su 09:00-18:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.303139, 59.944485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 119-121",
                "city_function": "Товары для туристов",
                "name": "Кладенец",
                "opening_hours": null,
                "level": -1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.303247, 59.923743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 90",
                "city_function": "Товары для туристов",
                "name": "Распутин",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300534, 59.930074]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 15",
                "city_function": "Товары для туристов",
                "name": "Сувениры (Сладкий музей)",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318204, 59.936138]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Миллионная улица, 12",
                "city_function": "Товары для туристов",
                "name": "Миллионная, 12",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/millionn12",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323531, 59.943183]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 11\/2",
                "city_function": "Товары для туристов",
                "name": "Янтарь",
                "opening_hours": "09:00-23:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.936404]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 50",
                "city_function": "Товары для туристов",
                "name": "Hand Land",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/handland_spb",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.325947, 59.926048]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 14",
                "city_function": "Товары для туристов",
                "name": "Сувениры СПб",
                "opening_hours": "10:00-23:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327286, 59.936034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 12",
                "city_function": "Товары для туристов",
                "name": "Промыслы России",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.327411, 59.936287]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 5",
                "city_function": "Товары для туристов",
                "name": "Подарки для души и красоты",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club18585096",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.357209, 59.93034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 5",
                "city_function": "Товары для туристов",
                "name": "Rainbow Shop",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/rainbow_shop14",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329738, 59.936674]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 32-34",
                "city_function": "Товары для туристов",
                "name": "Цветок Папоротника",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club12837115",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329037, 59.935511]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 2",
                "city_function": "Товары для туристов",
                "name": "Re'Marka",
                "opening_hours": "Mo-Sa 12:00-20:00; Su 12:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323162, 59.933681]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 23",
                "city_function": "Товары для туристов",
                "name": "Бирюльки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club150841319",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.325022, 59.933266]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 18",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "10:00-23:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318249, 59.936557]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 35",
                "city_function": "Товары для туристов",
                "name": "СССР",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.319183, 59.940722]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 16",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310649, 59.934538]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 20",
                "city_function": "Товары для туристов",
                "name": "Сувениры в Музее советской эпохи",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 9482597",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.349097, 59.943701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Восстания, 13",
                "city_function": "Товары для туристов",
                "name": "Сувенирная лавка",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.360478, 59.934001]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "city_function": "Товары для туристов",
                "name": "Сувенирная лавка",
                "opening_hours": "07:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 6",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.314476, 59.937012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 1",
                "city_function": "Товары для туристов",
                "name": "Секрет",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/secret_taro",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.34271, 59.925845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 32",
                "city_function": "Товары для туристов",
                "name": "Дом Лаванды",
                "opening_hours": "10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.929434]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 40АлитЗ",
                "city_function": "Товары для туристов",
                "name": "Art House",
                "opening_hours": "09:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.361997, 59.949474]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 26\/16В",
                "city_function": "Товары для туристов",
                "name": "Vape shop папироска",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.234454, 59.859176]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1",
                "city_function": "Товары для туристов",
                "name": "Церковная лавка",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.24871, 59.860093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 4",
                "city_function": "Товары для туристов",
                "name": "Beluga deluxe",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.328507, 59.937175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Писарева, 20",
                "city_function": "Товары для туристов",
                "name": "Сувениры в Мариинском театре",
                "opening_hours": null,
                "level": 1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.286763, 59.925182]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 1",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324151, 59.941029]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 131",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.884347]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "city_function": "Товары для туристов",
                "name": "Подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 45",
                "city_function": "Товары для туристов",
                "name": "Самовар",
                "opening_hours": "10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.350139, 59.851214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 31к1",
                "city_function": "Товары для туристов",
                "name": "Vape club",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.22866, 59.858634]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дворцовая набережная, 38",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313721, 59.941173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "city_function": "Товары для туристов",
                "name": "Ганеш",
                "opening_hours": "11:00-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/club54974331",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 23",
                "city_function": "Товары для туристов",
                "name": "Украшения и подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.304199, 59.924564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 89",
                "city_function": "Товары для туристов",
                "name": "Подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.414054, 59.833027]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 15",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "08:00-00:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.307487, 59.991192]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 69",
                "city_function": "Товары для туристов",
                "name": "T.Best",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309966, 59.964223]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Клинский проспект, 11",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324887, 59.915747]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Восстания, 2",
                "city_function": "Товары для туристов",
                "name": "Невский символ",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.360541, 59.931571]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Витебский проспект, 108",
                "city_function": "Товары для туристов",
                "name": "Невский символ",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.375633, 59.829545]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 12",
                "city_function": "Товары для туристов",
                "name": "Остров сокровищ",
                "opening_hours": null,
                "level": -1.0,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/treasure_island_ru",
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.352151, 59.945517]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362131, 59.929479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "UNO amber gifts",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362131, 59.929479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362131, 59.929479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "city_function": "Товары для туристов",
                "name": "Fan Shop",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "city_function": "Товары для туристов",
                "name": "Spirit Of St. Petersburg",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "city_function": "Товары для туристов",
                "name": "Деревяшки",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "11:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "city_function": "Товары для туристов",
                "name": "Центр уникальных товаров",
                "opening_hours": "11:00-21:00",
                "level": 2.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7",
                "city_function": "Товары для туристов",
                "name": "Калейдоскоп подарков",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.257325, 59.9894]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 74",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": 1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.391515, 60.042029]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гороховая улица, 19",
                "city_function": "Товары для туристов",
                "name": "Сима-ленд",
                "opening_hours": "Mo-Su 10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316991, 59.931607]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7Б",
                "city_function": "Товары для туристов",
                "name": "Арт-студия",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.258987, 59.989319]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к2Ж",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.253498, 59.988694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Английская набережная, 54",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.287508, 59.932311]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 9",
                "city_function": "Товары для туристов",
                "name": "Сувенирная лавка",
                "opening_hours": "09:00-21:30",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.354226, 59.929804]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "city_function": "Товары для туристов",
                "name": "Сувениры СПБ",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "city_function": "Товары для туристов",
                "name": "Сувениры СПБ",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "Зенит",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362131, 59.929479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "Монетка78",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.362131, 59.929479]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 85З",
                "city_function": "Товары для туристов",
                "name": "Сувениры (Москвоский возказ)",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.372965, 59.921096]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Клинский проспект, 21",
                "city_function": "Товары для туристов",
                "name": "Fast Anime Studio",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321186, 59.914669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Балтийского вокзала, 1",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299456, 59.907207]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 10к1",
                "city_function": "Товары для туристов",
                "name": "Postulat",
                "opening_hours": "Mo-Th 10:00-22:00; Fr-Sa 10:00-23:00; Su 10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.480125, 59.912003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 13\/9",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": "Mo-Su 10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316757, 59.9363]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Екатерининский парк, ​Садовая, 7а",
                "city_function": "Товары для туристов",
                "name": "сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.397526, 59.71683]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф",
                "city_function": "Товары для туристов",
                "name": "сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.90304, 59.885253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 7",
                "city_function": "Товары для туристов",
                "name": "Амброссиа",
                "opening_hours": "Mo-Su 11:00-23:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.399097, 59.717937]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Коммунистическая улица, 2",
                "city_function": "Товары для туристов",
                "name": "Форт",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.769969, 59.987941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Металлострой, Школьная улица, 14",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.569349, 59.810272]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Коммунистическая улица, 2",
                "city_function": "Товары для туристов",
                "name": "Сувенир Кронштадта",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.770113, 59.987887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Советская улица, 13",
                "city_function": "Товары для туристов",
                "name": "Островок",
                "opening_hours": "Mo-Sa 09:00-22:00; Su 12:30-20:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.782936, 59.992601]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 55",
                "city_function": "Товары для туристов",
                "name": "Узорочье",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.416453, 59.725346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Победы, 21",
                "city_function": "Товары для туристов",
                "name": "Визит",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.769685, 59.903724]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Церковная улица, 5",
                "city_function": "Товары для туристов",
                "name": "Pushkin's fairy tales- SOUVENIRS",
                "opening_hours": "Mo-Su 09:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 4516411",
                "wheelchair": "no",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.399497, 59.72039]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Hockey club",
                "opening_hours": null,
                "level": 1.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268477, 59.797877]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Зенит",
                "opening_hours": "24\/7",
                "level": 1.0,
                "contact_website": "https:\/\/www.shop.fc-zenit.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 812 2440040",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268436, 59.797808]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Эрмитаж",
                "opening_hours": "24\/7",
                "level": 1.0,
                "contact_website": "https:\/\/www.hermitagemuseum.org\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268237, 59.797489]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 39",
                "city_function": "Товары для туристов",
                "name": "Галерея Акаса",
                "opening_hours": "Tu-Sa 11:00-18:00",
                "level": null,
                "contact_website": "https:\/\/akasa.gallery\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": "+7 981 1513939",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.409395, 59.71785]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Рошаля, 10",
                "city_function": "Товары для туристов",
                "name": "Музей тельняшки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.778616, 59.993957]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Императорский фарфор",
                "opening_hours": "24\/7",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.267864, 59.798142]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "city_function": "Товары для туристов",
                "name": "Postulat",
                "opening_hours": "24\/7",
                "level": null,
                "contact_website": "https:\/\/postulat.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.267177, 59.796724]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 17к3",
                "city_function": "Товары для туристов",
                "name": "Табак и Подарки",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317024, 59.827491]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Красная улица, 6",
                "city_function": "Товары для туристов",
                "name": "Сувениры Подарки на Красной",
                "opening_hours": "Mo-Su 10:00-21:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.773979, 59.988369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Гражданская улица, 17",
                "city_function": "Товары для туристов",
                "name": "Магазин антикварианта",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.763236, 59.998586]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "city_function": "Товары для туристов",
                "name": "Fan Shop",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.269981, 59.800575]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "city_function": "Товары для туристов",
                "name": "Spirit Of St. Petersburg",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.269671, 59.80049]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "city_function": "Товары для туристов",
                "name": "Императорский фарфор",
                "opening_hours": null,
                "level": 3.0,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": "yes",
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.270264, 59.800336]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Коммунистическая улица, 3",
                "city_function": "Товары для туристов",
                "name": "Жемчужина",
                "opening_hours": "Mo-Su 10:00-19:00",
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.770775, 59.989855]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Конюшенная улица, 1\/18",
                "city_function": "Товары для туристов",
                "name": "Сувениры",
                "opening_hours": null,
                "level": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "contact_phone": null,
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.407111, 59.714304]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "city_function": "Товары для туристов",
                "name": "Banka_home",
                "opening_hours": "Mo-Su 10:00-22:00",
                "level": null,
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/banka_home",
                "contact_instagram": "https:\/\/instagram.com\/banka_home",
                "contact_phone": "+7 999 2009178",
                "wheelchair": null,
                "type": "Магазин сувениров",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318393, 59.818965]}
        }
    ]
}
