export const museums = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 18-я линия Васильевского острова, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-институт семьи Рерихов",
                "building_levels": "1-3",
                "architect": "Бруни А. К.",
                "start_date": "1883",
                "building_architecture": "барокко",
                "opening_hours": "We 14:00-21:00; Th-Su 11:00-18:00; Th[-1] off",
                "contact_phone": "+7 812 3273506",
                "contact_website": "http:\/\/roerich.spb.ru",
                "contact_vk": "https:\/\/vk.com\/roerich",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.271985, 59.931688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3И",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей космонавтики и ракетной техники",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Tu 11:00-19:00; We off",
                "contact_phone": "+7 812 2300332",
                "contact_website": "http:\/\/www.museum.ru\/M223",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.322022, 59.950853]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Достоевского, 2\/5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Федора Михайловича Достоевского",
                "building_levels": "1-2",
                "architect": "Пономарев С.\nВитт фон В. В.",
                "start_date": "1859",
                "building_architecture": "эклектика",
                "opening_hours": "Tu,Th-Su 11:00-18:00; We 13:00-20:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.md.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/dostoevskymuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.34774, 59.92373]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 5Щ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира А. С. Попова",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-16:30",
                "contact_phone": "+7 812 234 59 00",
                "contact_website": "http:\/\/www.eltech.ru\/ru\/universitet\/istoriya-universiteta\/memorialnyy-muzey-as-popova",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.322686, 59.971551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 83к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей подводных сил РФ",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 10:00-18:00",
                "contact_phone": "7 (812) 543-61-91",
                "contact_website": "http:\/\/xn--80ajbfhekjdmntqs.xn--p1ai\/",
                "contact_vk": "https:\/\/vk.com\/clubmipsrm",
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.398684, 59.982694]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Наличная улица, 21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Галерея Академии Художеств",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "contact_phone": "7 (812) 355-12-74",
                "contact_website": "http:\/\/www.akademart.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.235442, 59.935277]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 50",
                "city_function": "Культура, развлечения, творчество",
                "name": "Центральный музей железнодорожного транспорта",
                "building_levels": "1-2",
                "architect": "Купинский П. С. Баумгартен фон Е. Е.",
                "start_date": "1901",
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:30-17:30",
                "contact_phone": "+7 812 3151476",
                "contact_website": "http:\/\/cmzt.narod.ru\/",
                "contact_vk": "https:\/\/vk.com\/cmrt1813",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313658, 59.924406]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 103",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей искусства Санкт-Петербурга XX-XXI веков (Малый Манеж)",
                "building_levels": "1-2",
                "architect": "Краснопевков З. Ф. Фиалковский А. Д. Максимов А. П.",
                "start_date": "1890",
                "building_architecture": "эклектика",
                "opening_hours": "Tu,Th,Fr 14:00-20:00; We 14:00-21:00; Sa,Su 12:00-20:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.mispxx-xxi.ru\/",
                "contact_vk": "https:\/\/vk.com\/museumxx_xxi",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300022, 59.926445]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 65к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Пулковской обсерватории",
                "building_levels": "1-4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 08:20-13:00, 14:00-17:00",
                "contact_phone": "7 (812) 363-72-07",
                "contact_website": "http:\/\/www.gaoran.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326217, 59.771771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ботанический музей БИН",
                "building_levels": "1-3",
                "architect": "Дитрих А. И.",
                "start_date": "1912-1914",
                "building_architecture": "Неоклассицизм",
                "opening_hours": "We-Su 12:00-19:00",
                "contact_phone": "+7 812 2340673",
                "contact_website": "http:\/\/www.binran.ru\/botmus\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.322857, 59.971253]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 28",
                "city_function": "Культура, развлечения, творчество",
                "name": "Новый музей",
                "building_levels": "3-4",
                "architect": "Лаутер И. К. Иванов А. В.",
                "start_date": "1826",
                "building_architecture": "эклектика",
                "opening_hours": "We-Fr 11:00-19:00; Sa-Su 12:00-20:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.279325, 59.943129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Уткин проспект, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Уткина дача (филиал музея городской скульптуры)",
                "building_levels": "1-2",
                "architect": "Львов Н. А.",
                "start_date": "1790",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Su 9:30-17:00",
                "contact_phone": "7 (812) 271-27-04",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/gmgs_ru",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.422148, 59.934664]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лазаретный переулок, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Военно-медицинский музей",
                "building_levels": "1-4",
                "architect": "Демерцов Ф. И.  Волков Ф. И.",
                "start_date": "1799-1800",
                "building_architecture": "Классицизм",
                "opening_hours": "We 12:00-20:00; Th-Tu 11:00-18:00",
                "contact_phone": null,
                "contact_website": "http:\/\/milmed.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/milmed_spb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.325336, 59.921137]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 100",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музейно-выставочный центр Петербургский Художник",
                "building_levels": "1",
                "architect": "Андерсон К. К.",
                "start_date": "1855-1856",
                "building_architecture": "эклектика",
                "opening_hours": "Mo-Su 11:00-20:00",
                "contact_phone": "7 (812) 314-06-09",
                "contact_website": "http:\/\/www.piter-art.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.294623, 59.927676]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 179\/2А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный музей городской скульптуры",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 09:30-17:00",
                "contact_phone": "+7 812 2742635",
                "contact_website": "http:\/\/www.gmgs.ru",
                "contact_vk": "https:\/\/vk.com\/gmgs_ru",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.384751, 59.92281]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 23В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории фотографии",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Sa 13:00-17:00",
                "contact_phone": "+7 812 3461850;+7 911 7229217",
                "contact_website": "http:\/\/www.photohismus.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31267, 59.971244]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Политехническая улица, 29к2Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Историко-технический Музей",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-17:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.378148, 60.004915]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Коломенская улица, 1\/15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира Л.Н.Гумилева",
                "building_levels": "2",
                "architect": "Гольм А. Л",
                "start_date": "1876-1877",
                "building_architecture": "эклектика",
                "opening_hours": "Tu-Su 11:00-18:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.gumilev-museum.ru\/",
                "contact_vk": "https:\/\/vk.com\/lngumilevmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.355089, 59.92653]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории религии",
                "building_levels": "1-5",
                "architect": "Львов Н. А. Соколов Е. Т. Кавос А. К.",
                "start_date": "1782-1785",
                "building_architecture": "Классицизм",
                "opening_hours": "Th-Mo 11:00-18:00; Tu 13:00-21:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.gmir.ru\/",
                "contact_vk": "https:\/\/vk.com\/museumreligion",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.301127, 59.931688]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Декабристов, 57",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира А. А. Блока",
                "building_levels": "1-4",
                "architect": "Петерсон М. Ф",
                "start_date": "1874-1876",
                "building_architecture": "Эклектика",
                "opening_hours": "Th-Mo 11:00-18:00; Tu 11:00-17:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.spbmuseum.ru\/themuseum\/museum_complex\/blok_museum\/",
                "contact_vk": "https:\/\/vk.com\/club81419567",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.282963, 59.923757]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 118",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Г. Р. Державина",
                "building_levels": "1-4",
                "architect": "Пильников Г. П. Львов Н. А. Горностаев А. М.",
                "start_date": "1791-1794",
                "building_architecture": "Классицизм",
                "opening_hours": "We-Mo 10:30-18:00",
                "contact_phone": "7 (812) 713-07-17",
                "contact_website": "http:\/\/www.museumpushkin.ru\/",
                "contact_vk": "https:\/\/vk.com\/museumpushkinspb",
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.309912, 59.919031]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Александровский парк, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Военно-исторический музей артиллерии, инженерных войск и войск связи",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-18:00",
                "contact_phone": "+7 812 232 02 96",
                "contact_website": "http:\/\/www.artillery-museum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.313982, 59.953669]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 47",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей В.В. Набокова",
                "building_levels": "1",
                "architect": "Гейслер М. Ф. Гуслистый Б. Ф.",
                "start_date": "1897",
                "building_architecture": "Модерн",
                "opening_hours": "Tu-Fr 11:00-18:00; Sa 12:00-17:00",
                "contact_phone": "+7 812 3154713",
                "contact_website": "http:\/\/nabokov.museums.spbu.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.304927, 59.931887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей тюрьмы Трубецкого бастиона",
                "building_levels": "1-2",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-19:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей почвоведения имени В. В. Докучаева",
                "building_levels": "1-3",
                "architect": "Захаров А. Д. Лукини И. Ф.",
                "start_date": "1826-1832",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": "+7 812 3285402",
                "contact_website": "http:\/\/xn----dtbaibdbjqd4avhbm3d3d0h.xn--p1ai",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.303139, 59.944485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Победы площадь, 1а",
                "city_function": "Культура, развлечения, творчество",
                "name": "Монумент героическим защитникам Ленинграда",
                "building_levels": "1",
                "architect": "М. К. Аникушин, В. А. Каменский,С. Б. Сперанский",
                "start_date": "1974—1975",
                "building_architecture": null,
                "opening_hours": "Th-Tu 10:00-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.321968, 59.843091]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 25",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей гигиены",
                "building_levels": "1-3",
                "architect": "Чевакинский С. И.",
                "start_date": "1753-1755",
                "building_architecture": "Барокко",
                "opening_hours": "Tu-Sa 11:00-19:30",
                "contact_phone": "+7 812 5958908",
                "contact_website": "http:\/\/gcmp.ru\/museum\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.337293, 59.9358]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 18А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Сновидений Фрейда",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu, Sa, Su 12:00-17:00",
                "contact_phone": "7 (911) 784-21-17",
                "contact_website": "https:\/\/freud.ru\/",
                "contact_vk": "https:\/\/vk.com\/club148386",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.294713, 59.955309]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Одоевского, 29",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Петербургского метрополитена",
                "building_levels": "1",
                "architect": "Стародубов М. И.",
                "start_date": "1980-1985",
                "building_architecture": null,
                "opening_hours": "Mo-Sa 10:00-11:45; 12:30-17:00",
                "contact_phone": "+7 812 3019833;+7 812 4045920;+7 921 7941626",
                "contact_website": "http:\/\/dosug.metro.spb.ru\/metro-museum\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.234876, 59.948235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Биржевая линия, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей оптики",
                "building_levels": "1",
                "architect": "Бруни А. К. Барановский Г. В.",
                "start_date": "1884",
                "building_architecture": "Эклектика",
                "opening_hours": "Tu-Th 11:00-20:00; Fr-Su 11:00-21:00",
                "contact_phone": "+7 812 4571779",
                "contact_website": "http:\/\/optimus.ifmo.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.29544, 59.944134]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 64",
                "city_function": "Культура, развлечения, творчество",
                "name": "Культурно-просветительский центр «Марата 64»",
                "building_levels": "1-2",
                "architect": "Тимофеев И.",
                "start_date": "1873",
                "building_architecture": null,
                "opening_hours": "We-Fr 14:00-20:00; Sa 14:00-18:00",
                "contact_phone": "7 (812) 315-03-72",
                "contact_website": "https:\/\/www.spbstu.ru\/structure\/muzey_istorii_professionalnogo_obrazovaniya\/",
                "contact_vk": "https:\/\/vk.com\/marata64",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.346635, 59.921281]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 28Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира Н. А. Римского-Корсакова",
                "building_levels": "3",
                "architect": "Зайцев Д. Д.",
                "start_date": "1898",
                "building_architecture": "Эклектика",
                "opening_hours": "We 13:00-21:00; Th-Su 11:00-18:00; Fr[-1] off",
                "contact_phone": null,
                "contact_website": "http:\/\/rkorsakov.ru\/",
                "contact_vk": "https:\/\/vk.com\/rkmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.341084, 59.924605]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей художественного стекла",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu, Th-Su 10:00-18:30; We 13:00-21:00",
                "contact_phone": "+7 812 4309784",
                "contact_website": "https:\/\/elaginpark.org\/glass",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268509, 59.980267]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Труда, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Центральный военно-морской музей",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-18:00",
                "contact_phone": "+7 812 3038513",
                "contact_website": "http:\/\/navalmuseum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293716, 59.929993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 87",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей варежки",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-19:00",
                "contact_phone": null,
                "contact_website": "http:\/\/музей-варежки.рф\/",
                "contact_vk": "https:\/\/vk.com\/varezhkaspb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310388, 59.932369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 35",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный музейно-выставочный центр РОСФОТО",
                "building_levels": "2-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-19:00",
                "contact_phone": "7 (812) 314-12-14",
                "contact_website": "https:\/\/rosphoto.org\/",
                "contact_vk": "https:\/\/vk.com\/rosphoto.museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310433, 59.933307]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей циркового искусства",
                "building_levels": "3",
                "architect": "Кенель В. А.",
                "start_date": "1876-1877",
                "building_architecture": "Эклектика",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": "7 (812) 570-54-13",
                "contact_website": "http:\/\/www.circus.spb.ru\/ru\/muzej\/",
                "contact_vk": "https:\/\/vk.com\/circusspb",
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.341335, 59.938685]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 71Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории института экспериментальной медицины",
                "building_levels": "6",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": "7 (812) 499-49-59",
                "contact_website": "http:\/\/iemrams.spb.ru\/russian\/museumru.htm",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.305834, 59.97495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 43-45",
                "city_function": "Культура, развлечения, творчество",
                "name": "МузЭрос",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9050394",
                "contact_website": "https:\/\/museros.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.35993, 59.930196]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 40АЕ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Кофе",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-20:00",
                "contact_phone": "+7 812 2758739",
                "contact_website": "http:\/\/www.mcof.ru",
                "contact_vk": "https:\/\/vk.com\/coffee_museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.3625, 59.949469]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Якубовича, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Республика кошек",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 3120487",
                "contact_website": "https:\/\/www.catsrepublic.ru\/",
                "contact_vk": "https:\/\/vk.com\/cats_republic",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.301253, 59.933054]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Искусств, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира И.И. Бродского",
                "building_levels": "1-2",
                "architect": "Росси К. И. Бенуа Н. Л.",
                "start_date": "1822",
                "building_architecture": "Классицизм",
                "opening_hours": "We,Sa,Su 12:00-18:00; Th,Fr 14:00-20:00",
                "contact_phone": "+7 812 3143658",
                "contact_website": "http:\/\/www.nimrah.ru\/musbrod\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329316, 59.937531]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей советских игровых автоматов",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-20:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.15kop.ru\/museum\/saint_petersburg\/",
                "contact_vk": "https:\/\/vk.com\/15kop",
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Всеволода Вишневского, 22",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский Музей Игрушки",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 11:00-18:00",
                "contact_phone": "+7 (812) 234-43-12",
                "contact_website": "http:\/\/www.spbtoymuseum.com",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300723, 59.969781]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Графтио, 2Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом-музей Ф. И. Шаляпина",
                "building_levels": "1-2",
                "architect": "Рябов Д. П.",
                "start_date": "1901",
                "building_architecture": "Ретроспективизм",
                "opening_hours": "We 13:00-21:00; Th-Su 12:00-19:00",
                "contact_phone": "+7 812 2341056",
                "contact_website": "http:\/\/www.theatremuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.305573, 59.974171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "ЛабиринтУм",
                "building_levels": "6",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-19:00",
                "contact_phone": "+7 812 3280008",
                "contact_website": "https:\/\/www.labirint-um.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31567, 59.965124]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 34",
                "city_function": "Культура, развлечения, творчество",
                "name": "Белый зал Шереметевского дворца",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Mo 11:00-18:00",
                "contact_phone": "7 (812) 272-44-41",
                "contact_website": "https:\/\/theatremuseum.ru\/filial\/sheremetevskiy_dvorec_muzey_muzyki",
                "contact_vk": "https:\/\/vk.com\/theatre_museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.345459, 59.936449]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 74",
                "city_function": "Культура, развлечения, творчество",
                "name": "Центральный научно-исследовательский геологоразведочный музей им. ак. Ф.Н. Чернышева",
                "building_levels": "1-4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-16:00",
                "contact_phone": "+7 812 3215399",
                "contact_website": "http:\/\/www.vsegei.com\/ru\/structure\/information\/museum\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.262427, 59.938099]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей книги блокадного города (Библиотека №5)",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-22:00; Su 11:00-18:00; Sa off",
                "contact_phone": "+7 812 3735160",
                "contact_website": "http:\/\/www.cbs-msk.ru\/library\/29\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.33485, 59.863089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 31",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музейная экспозиция «Три века Петергофской дороги»",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-20:00; Sa-Su 10:00-18:00",
                "contact_phone": "7 (812) 752-25-11",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.229693, 59.841907]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Автовская улица, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Анна Ахматова. Серебряный век.",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Th 10:00-18:00; Fr 10:00-17:00; Sa 10:00-16:30",
                "contact_phone": "+7 812 7850442",
                "contact_website": "https:\/\/akhmatova-museum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.268168, 59.868333]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории и техники Акционерного общества \"Кировский завод\"",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": "+7 812 7842233",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.262859, 59.878382]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 45",
                "city_function": "Культура, развлечения, творчество",
                "name": "Усадьба «Кирьяново»",
                "building_levels": "3",
                "architect": "Кваренги Д.",
                "start_date": "1783-1784",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Th 10:00-17:00; Fr 10:00-16:00",
                "contact_phone": "+7 812 3008329",
                "contact_website": "http:\/\/narvskaya-zastava.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.265212, 59.8859]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 22",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей отечественной киноаппаратуры",
                "building_levels": "4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 9:00-17:00",
                "contact_phone": "+7 812 2693232",
                "contact_website": "https:\/\/www.gukit.ru\/fma\/kafedry\/kva\/muzey",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.364674, 59.887905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ольги Берггольц, 27",
                "city_function": "Культура, развлечения, творчество",
                "name": "Истоки жизни-Невская застава",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 9:00-18:00",
                "contact_phone": "+7 812 5600563;+7 812 5602990",
                "contact_website": "https:\/\/school340.jimdo.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.413183, 59.891308]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 151",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Императорского фарфорового завода",
                "building_levels": "4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-19:00",
                "contact_phone": "+7 812 3261743",
                "contact_website": "http:\/\/www.ipm.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.446672, 59.879809]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей нонконформистского искусства",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Fr 16:00-20:00; Sa, Su 12:00-20:00",
                "contact_phone": "+7 812 7644852",
                "contact_website": "http:\/\/nonmuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.357559, 59.929515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Церковно-археологический музей",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Sa 15:00-15:00; Su 13:00-13:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.383826, 59.917763]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Харьковская улица, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории милиции Культурного центра ГУ МВД",
                "building_levels": "4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.kcgumvdspb.ru\/#!museum\/c1ttk",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.371213, 59.92708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Стремянная улица, 8",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира актеров Самойловых",
                "building_levels": "2",
                "architect": "Цим И. И.",
                "start_date": "1858",
                "building_architecture": null,
                "opening_hours": "We 13:00-21:00; Th-Su 12:00-19:00",
                "contact_phone": null,
                "contact_website": "http:\/\/theatremuseum.ru\/filial\/memorialnyy_muzey_kvartira_akterov_samoylovyh",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.350732, 59.931219]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 41",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей становления демократии в современной России имени Анатолия Собчака",
                "building_levels": "1",
                "architect": "Штакеншнейдер А. И.",
                "start_date": "1846-1848",
                "building_architecture": "Необарокко",
                "opening_hours": "Mo-Fr 11:00-15:00",
                "contact_phone": "+7 812 5711706",
                "contact_website": "http:\/\/www.museum.ru\/m3231",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.344848, 59.93277]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 35В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей восковых фигур",
                "building_levels": "1",
                "architect": "Валлен-Деламот Ж.-Б.",
                "start_date": "1776-1785",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Su 10:00-21:30",
                "contact_phone": "+7 952 3744078",
                "contact_website": "http:\/\/www.wax.ru\/",
                "contact_vk": "https:\/\/vk.com\/wax_figures",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.331526, 59.933974]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-фотосалон Карла Буллы",
                "building_levels": "4",
                "architect": "Трезини П. А. Сюзор П. Ю.",
                "start_date": "1740",
                "building_architecture": "Эклектика",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": "+7 812 3143301;+7 812 5710838",
                "contact_website": "http:\/\/www.bullafond.ru",
                "contact_vk": "https:\/\/vk.com\/bullafondart",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская, 35",
                "city_function": "Культура, развлечения, творчество",
                "name": "Русский Левша",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "contact_phone": "+7 812 3128897;+7 812 3128809",
                "contact_website": "http:\/\/www.russian-master.com\/mgm\/micro",
                "contact_vk": "https:\/\/vk.com\/russkii_levsha",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.340886, 59.935236]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Жуковского, 37Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Трикстер",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Sa 13:00-19:00",
                "contact_phone": "+7 911 2773264",
                "contact_website": "https:\/\/www.gidspb.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.356795, 59.935484]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 10-я Советская улица, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира Аллилуевых",
                "building_levels": "6",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We 13:00-21:00; Th-Su 10:00-18:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.museum.ru\/M177",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.381481, 59.936129]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Смольный проезд, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Историко-мемориальный музей \"Смольный\"",
                "building_levels": "1-3",
                "architect": "Кваренги Д.",
                "start_date": "1806-1808",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Th 10:00-18:00; Fr 15:00-18:00",
                "contact_phone": "+7 812 5767746;+7 812 5767461",
                "contact_website": "http:\/\/www.museum.ru\/M109",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.396537, 59.946306]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Смольный проспект, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей путешественника П. К. Козлова",
                "building_levels": "3",
                "architect": "Красовский А. Ф.",
                "start_date": "1901-1903",
                "building_architecture": null,
                "opening_hours": "Th 11:00-18:00",
                "contact_phone": "+7 812 7100350;+7 812 5771243",
                "contact_website": "http:\/\/www.kozlov-museum.ru",
                "contact_vk": "https:\/\/vk.com\/kozlovmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.394444, 59.944729]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 47",
                "city_function": "Культура, развлечения, творчество",
                "name": "Центр истории парламентаризма",
                "building_levels": "1-4",
                "architect": "Старов И. Е",
                "start_date": "1783-1789",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Fr 10:00-16:00",
                "contact_phone": null,
                "contact_website": "http:\/\/parlament-history.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.374483, 59.948523]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 36",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира Н.А. Некрасова",
                "building_levels": "2",
                "architect": "Петцольд А. В. Докушевский А. А.",
                "start_date": "1781-1782",
                "building_architecture": null,
                "opening_hours": "We-Su 10:30-18:00; Fr[-1] off",
                "contact_phone": "+7 812 2720165",
                "contact_website": "http:\/\/www.museumpushkin.ru\/vserossijskij_muzej_a._s._pushkina\/muzej-kvartira_n.a.nekrasova.html",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.348764, 59.939424]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 4\/2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира М.М. Зощенко",
                "building_levels": null,
                "architect": "Буржуа А. И.",
                "start_date": "1838",
                "building_architecture": "Классицизм",
                "opening_hours": "Tu,Th-Su 10:30-18:30; We 12:00-20:00; Fr[-1] off",
                "contact_phone": "+7 812 5717819",
                "contact_website": "http:\/\/museum-xxvek.ru\/",
                "contact_vk": "https:\/\/vk.com\/glmxxvek",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326289, 59.937941]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей печати",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-18:00; Tu 11:00-17:00; We off",
                "contact_phone": "+7 812 5710270",
                "contact_website": "https:\/\/www.spbmuseum.ru\/themuseum\/museum_complex\/museum_of_printing\/",
                "contact_vk": "https:\/\/vk.com\/musey_pechati",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.32062, 59.938432]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 4\/1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Петровская Акватория",
                "building_levels": "5",
                "architect": "Верёвкин Н. Н. Рубаненко Б. Р. Фомин И. И.",
                "start_date": "1913",
                "building_architecture": "Неоклассицизм",
                "opening_hours": "Mo-Su 10:00-21:30",
                "contact_phone": "+7 812 9334152",
                "contact_website": "http:\/\/www.peteraqua.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.314637, 59.936034]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский проспект, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей политической истории России",
                "building_levels": "1-4",
                "architect": "Кваренги Д.",
                "start_date": "1788-1790",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": "+7 812 3122742",
                "contact_website": "http:\/\/www.polithistory.ru\/",
                "contact_vk": "https:\/\/vk.com\/polithistorymuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.311017, 59.936548]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 35",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Г.В. Старовойтовой",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We, Sa 11:00-16:00",
                "contact_phone": "+7 812 3143728",
                "contact_website": "http:\/\/starovoitova.ru\/?p=149",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310433, 59.933307]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей русской водки",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 12:00-19:00",
                "contact_phone": "+7 812 9431431",
                "contact_website": "https:\/\/www.vodkamuseum.su\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.298171, 59.932698]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 1\/21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей БГТУ «Военмех»",
                "building_levels": "3",
                "architect": "Малов А. В.",
                "start_date": "1893-1895",
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-16:00",
                "contact_phone": null,
                "contact_website": "https:\/\/www.voenmeh.ru\/museum",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.315751, 59.916361]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Казачий переулок, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Выставочный зал музея Разночинный Петербург",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Sa 11:00-17:00; Th[-1] off",
                "contact_phone": "+7 812 4075220",
                "contact_website": "http:\/\/spbmmrp.ru\/",
                "contact_vk": "https:\/\/vk.com\/spbmmrp",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326854, 59.92336]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 144",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории государственных бумаг",
                "building_levels": "1-4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:30-18:00",
                "contact_phone": "+7 812 5751472",
                "contact_website": "http:\/\/spbf.goznak.ru\/about\/today\/museum\/",
                "contact_vk": "https:\/\/vk.com\/club64518962",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293195, 59.915201]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира А. И. Куинджи",
                "building_levels": "4",
                "architect": "Пель А. Х. Гребёнка Н. П. Шперер Л. Ф.",
                "start_date": "1842",
                "building_architecture": "Эклектика",
                "opening_hours": "We,Fr,Sa 12:00-17:00",
                "contact_phone": "+7 812 3233133",
                "contact_website": "http:\/\/nimrah.ru\/muskuin\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.293761, 59.945472]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Университетская набережная, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Научно-исследовательский музей Российской академии художеств",
                "building_levels": "2",
                "architect": "Валлен-Деламот Ж.-Б.",
                "start_date": "1764-1788",
                "building_architecture": "Классицизм",
                "opening_hours": "We 11:00-18:00; Th 12:00-19:00; Fr-Su 11:00-18:00",
                "contact_phone": "+7 999 0344553",
                "contact_website": "http:\/\/artsacademymuseum.org",
                "contact_vk": "https:\/\/vk.com\/academyoffineartsmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.290257, 59.937495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Менделеевская линия, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Палеонтолого-стратиграфический музей",
                "building_levels": "-1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": "+7 812 3289480",
                "contact_website": "http:\/\/www.paleostratmuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299312, 59.941605]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Макарова, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Литературный музей",
                "building_levels": "2",
                "architect": "Лукини И. Ф.",
                "start_date": "1829-1832",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Fr 11:00-16:00",
                "contact_phone": "+7 812 3280502",
                "contact_website": "http:\/\/www.pushkinskijdom.ru\/Default.aspx?tabid=64",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.301396, 59.94481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 50",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей парфюмерии",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "contact_phone": "+7 911 7424502",
                "contact_website": "http:\/\/parfmuzey.ru\/",
                "contact_vk": "https:\/\/vk.com\/spb_parfmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.284957, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей повседневной культуры Ленинграда 1945-1965 гг.",
                "building_levels": "5",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Fr 11:00-19:00; Su 11:00-19:00",
                "contact_phone": "+7 911 7472301;+7 911 7472402",
                "contact_website": "http:\/\/1945-1965.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.283223, 59.942417]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 14-я линия Васильевского острова, 39",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей школы Карла Мая",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-18:00",
                "contact_phone": "+7 812 3283311",
                "contact_website": "http:\/\/www.kmay.ru\/",
                "contact_vk": "https:\/\/vk.com\/karlmay",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.269389, 59.939843]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 26-28",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей С. М. Кирова",
                "building_levels": "5",
                "architect": "Бенуа Ю. Ю.",
                "start_date": "1912-1914",
                "building_architecture": "Неоклассицизм",
                "opening_hours": "Mo-Su 11:00-17:30; We off",
                "contact_phone": "+7 812 3460289",
                "contact_website": "http:\/\/www.kirovmuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленина, 52",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира Елизаровых",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Sa 10:00-18:00; We, Su off",
                "contact_phone": "+7 812 2353778",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.295279, 59.965566]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 73-75",
                "city_function": "Культура, развлечения, творчество",
                "name": "Искусство веера",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 11:00-19:30",
                "contact_phone": "+7 812 4994959",
                "contact_website": "http:\/\/www.mus.rusampir.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.303876, 59.975279]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шамшева улица, 8",
                "city_function": "Культура, развлечения, творчество",
                "name": "Народный музей истории Санкт-Петербургской региональной организации Всероссийского общества слепых",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Sa 10:00-16:30",
                "contact_phone": "+7 812 2371790",
                "contact_website": "http:\/\/www.spbvos.ru\/history\/museum.php",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.300848, 59.960336]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Пархоменко, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей ГАИ",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-17:00",
                "contact_phone": "+7 812 3019245",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.33591, 60.000884]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Фаберже",
                "building_levels": "1-3",
                "architect": "Симон де Б. Ефимов Н. Е.",
                "start_date": "1790",
                "building_architecture": null,
                "opening_hours": "10:00-20:45",
                "contact_phone": "+7 812 3332655",
                "contact_website": "https:\/\/fabergemuseum.ru\/",
                "contact_vk": "https:\/\/vk.com\/fabergemuseum",
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.342997, 59.934867]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Елагин дворец",
                "building_levels": "1-4",
                "architect": "Д. Кваренги, К. Росси",
                "start_date": "1785—1790",
                "building_architecture": "Классицизм",
                "opening_hours": "10:00-18:30",
                "contact_phone": "7 (812) 430-09-11",
                "contact_website": "https:\/\/elaginpark.org\/",
                "contact_vk": "https:\/\/vk.com\/club37845993",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.270018, 59.979322]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Лахта, Береговая улица, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Историческая верфь \"Полтава\"",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-18:00",
                "contact_phone": "+7 812 2449833;+7 965 0082451",
                "contact_website": "http:\/\/poltava1712.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.16673, 59.986583]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 56Е",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мир воды Санкт-Петербурга",
                "building_levels": "1-6",
                "architect": "Мерц И. А. Шуберский Э. Г.",
                "start_date": "1859-1863",
                "building_architecture": null,
                "opening_hours": "11:00-20:00",
                "contact_phone": "7 (812) 438-43-01",
                "contact_website": "http:\/\/www.vodokanal-museum.ru\/ekspozicii\/vselennaya_vody\/",
                "contact_vk": "https:\/\/vk.com\/vodokanal_spb_museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.375956, 59.949596]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 56Е",
                "city_function": "Культура, развлечения, творчество",
                "name": "Подземный мир Санкт-Петербурга",
                "building_levels": "1-6",
                "architect": "Мерц И. А. Шуберский Э. Г.",
                "start_date": "1859-1863",
                "building_architecture": null,
                "opening_hours": "We-Su 10:00-19:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.375956, 59.949596]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 56АТ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Вселенная воды",
                "building_levels": "1-6",
                "architect": "Мерц И. А. Шуберский Э. Г.",
                "start_date": "1859-1863",
                "building_architecture": null,
                "opening_hours": "We-Su 10:00-19:00",
                "contact_phone": "+7 812 4384375",
                "contact_website": "http:\/\/vodokanal-museum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.375399, 59.950064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Зодчего Росси, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский государственный музей театрального и музыкального искусства",
                "building_levels": "2-5",
                "architect": "Росси К. И.",
                "start_date": "1828-1834",
                "building_architecture": "Классицизм",
                "opening_hours": "Th-Mo 11:00-19:00; We 13:00-20:00; Tu off; Fr[-1] off",
                "contact_phone": "+7 812 3101029",
                "contact_website": "https:\/\/theatremuseum.ru\/filial\/muzey_teatralnogo_i_muzykalnogo_iskusstva",
                "contact_vk": "https:\/\/vk.com\/theatre_museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.336071, 59.930534]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Орудия пыток средневековья",
                "building_levels": "1",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Правды, 12\/12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Тропический комплекс \"Миндо\" (Музей живых бабочек)",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-20:00",
                "contact_phone": "+7 812 5757070;+7 921 9383550",
                "contact_website": "http:\/\/www.sadbabochek.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.342584, 59.923103]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 13-я линия Васильевского острова, 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "творческий кластер Артмуза",
                "building_levels": "1-4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 3134703",
                "contact_website": "https:\/\/artmuza.spb.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.264125, 59.946369]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Летний сад, литИ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории Летнего сада",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Mo 10:00-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.334293, 59.945977]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Одесская улица, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-театр анимации \"Тарабум\"",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Fr 11:00-19:00; Tu, Sa-Su 10:00-20:00; Mo 11:00-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.38767, 59.946297]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 16к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Истории энергетики Северо-Запада России",
                "building_levels": "6",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 4943548",
                "contact_website": "http:\/\/energomuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.294928, 59.950181]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Возрождения, 20А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей старинного инструмента",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Sa 09:00-18:00; Su off",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.273917, 59.881638]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 3-5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей иллюзий",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 9385506",
                "contact_website": "https:\/\/bm5spb.ru\/ru\/spb\/bestmuseum.ru",
                "contact_vk": "https:\/\/vk.com\/bm5spb",
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317287, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Пряжки, 4-6",
                "city_function": "Культура, развлечения, творчество",
                "name": "А музы не молчали…",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": "7 (812) 572-58-87",
                "contact_website": "http:\/\/spbmbmus.ru\/",
                "contact_vk": "https:\/\/vk.com\/spbmbmus",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.280501, 59.926242]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Рижский проспект, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей христианской культуры в Санкт-Петербурге",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": "7 (981) 862 9453",
                "contact_website": "http:\/\/christianculture.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.289718, 59.913866]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Эмоций",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 7151235",
                "contact_website": "https:\/\/museumofemotions.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.328013, 59.936886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 30",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей логистики",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Sa-Su 12:00-17:00",
                "contact_phone": "+7 812 9449009",
                "contact_website": "http:\/\/www.logistics-museum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.25004, 59.922134]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Подольская улица, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Разночинный Петербург (Общегородской центр по теории и практике музейного дела; Выставочный зал)",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-17:00",
                "contact_phone": "7 (812) 407-52-20",
                "contact_website": "http:\/\/www.spbmmrp.ru\/",
                "contact_vk": "https:\/\/vk.com\/spbmmrp",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323944, 59.916573]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой Казачий переулок, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Разночинный Петербург",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Sa 11:00-17:00",
                "contact_phone": "+7 812 4075220",
                "contact_website": "http:\/\/www.spbmmrp.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.32769, 59.923329]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Степана Разина, 9Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории пивоварения",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We,Fr 11:00-17:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.heinekenrussia.ru\/beer\/museum_of_history_of_the_russian_beer\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.265922, 59.911908]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Alex Show",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-22:00",
                "contact_phone": "7 962 685-45-75",
                "contact_website": "http:\/\/www.wax.ru\/",
                "contact_vk": "https:\/\/vk.com\/wax_figures",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31806, 59.937206]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 3-5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Весёлая Наука",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 9385506",
                "contact_website": "https:\/\/bm5spb.ru\/ru\/spb\/naukabm5.ru",
                "contact_vk": "https:\/\/vk.com\/bm5spb",
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317287, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 3-5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Внутри человека",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 9385506",
                "contact_website": "https:\/\/bm5spb.ru\/ru\/spb\/vnutri.me",
                "contact_vk": "https:\/\/vk.com\/bm5spb",
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317287, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дворцовая набережная, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Зимний дворец Петра I",
                "building_levels": "1-3",
                "architect": "Маттарнови Г. Трезини Д.",
                "start_date": "1716-1723",
                "building_architecture": "Классицизм",
                "opening_hours": "Tu-Su 10:30-17:00",
                "contact_phone": "7 (812) 710-90-79",
                "contact_website": "https:\/\/www.hermitagemuseum.org\/wps\/portal\/hermitage\/explore\/buildings\/locations\/complex\/BC3?lng=ru",
                "contact_vk": "https:\/\/vk.com\/hermitage_museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317826, 59.942777]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей социалистического быта",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "contact_website": "https:\/\/sovietlife.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/sovlifestylespb",
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326316, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Выставка восковых фигур",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-20:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Литейный проспект, 20",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории войск Ленинградского военного округа",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-19:00",
                "contact_phone": "7 (812) 275-18-74",
                "contact_website": "http:\/\/dozvo.ru\/",
                "contact_vk": "https:\/\/vk.com\/zvodo",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.349097, 59.943701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Титикака",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-19:00",
                "contact_phone": "7 (812) 982-29-36",
                "contact_website": "https:\/\/titiqaqa.ru\/",
                "contact_vk": "https:\/\/vk.com\/titiqaqa",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321042, 59.932378]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ленфильм",
                "building_levels": "1",
                "architect": "Малов А. В. Крыжановский Д. А. Мульханов П. М.",
                "start_date": "1880",
                "building_architecture": "Модерн",
                "opening_hours": "11:00-19:00",
                "contact_phone": "+7 812 6066503",
                "contact_website": "http:\/\/www.lenfilm.ru\/service\/Ekskursii-lenfilm\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316982, 59.958273]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 2АЛ",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей камня",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-17:30; We[3] off",
                "contact_phone": null,
                "contact_website": "http:\/\/www.cathedral.ru\/news\/581",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329271, 59.940853]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Route 66",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 12:00-21:00; Sa-Su 11:00-21:00",
                "contact_phone": null,
                "contact_website": "http:\/\/museumroute66.ru\/",
                "contact_vk": "https:\/\/vk.com\/mr_66",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Восковые фигуры",
                "building_levels": "1",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Mo-Sa 10:30-19:00",
                "contact_phone": "7 (812) 984-60-66",
                "contact_website": "https:\/\/www.spbmuseum.ru\/exhibits_and_exhibitions\/92\/46754\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 35Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории купечества Санкт-Петербурга и России",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-17:00 ",
                "contact_phone": "+7 812 6300044",
                "contact_website": "http:\/\/bgd.ru\/muzey-istorii-kupechestva",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330987, 59.933564]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 86",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мозг - вселенная",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": "+7 981 7853036",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339072, 59.918886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 74Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Люмьер холл",
                "building_levels": "1",
                "architect": "Бернгард Р. Б.",
                "start_date": "1884",
                "building_architecture": null,
                "opening_hours": "11:00-23:00",
                "contact_phone": "7 (812) 407-17-31",
                "contact_website": "https:\/\/www.lumierehall.ru\/spb",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329082, 59.910429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 34",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей неправды",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-21:00",
                "contact_phone": "7 (969) 211-66-77",
                "contact_website": "https:\/\/музейнеправды.рф\/",
                "contact_vk": "https:\/\/vk.com\/untruthmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323037, 59.928758]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чайковского, 2\/7В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербург Олимп культуры",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-22:00",
                "contact_phone": "7 (921) 334-48-56",
                "contact_website": "http:\/\/vladimir-lubo.ru\/fotoarhiv\/n_olimp-kulturi-vladimira-lubenko\/",
                "contact_vk": "https:\/\/vk.com\/club92830292",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.338748, 59.94764]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Загородный проспект, 42В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей из Лего Polekon",
                "building_levels": "-1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-18:00",
                "contact_phone": "7 (900) 652-00-32",
                "contact_website": "http:\/\/polekon.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.337302, 59.922927]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Теней",
                "building_levels": "-1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "contact_website": "https:\/\/shadowmuseum.ru\/",
                "contact_vk": "https:\/\/vk.com\/shadowmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.323863, 59.940294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 52",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом архитектора (Особняк Половцева)",
                "building_levels": "1-4",
                "architect": "Пель А. Х. Эппингер Ф. И.",
                "start_date": "1835-1836",
                "building_architecture": "Классицизм",
                "opening_hours": "Sa 11:00-12:00; Su 12:00-13:00",
                "contact_phone": "+7 962 7237179",
                "contact_website": "http:\/\/www.arcunionspb.ru\/o-nas\/dom-arhitektora\/",
                "contact_vk": "https:\/\/vk.com\/dom_arhitektora_spb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.304612, 59.931427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Арт-центр Пушкинская 10",
                "building_levels": "1-6",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Fr 16:00-20:00; Sa-Su 12:00-20:00",
                "contact_phone": "+78127645371",
                "contact_website": "http:\/\/www.p-10.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.357559, 59.929515]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 47",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей граммофонов",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-16:00",
                "contact_phone": "+7 812 346-26-71",
                "contact_website": "http:\/\/www.grammofon.spb",
                "contact_vk": "https:\/\/vk.com\/grammofonspb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.31258, 59.964048]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Kunsthalle nummer sieben",
                "building_levels": "-1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Sa 14:00-20:00",
                "contact_phone": "7 (911) 929-77-75",
                "contact_website": "http:\/\/north-7.tilda.ws\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.289673, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Камская улица, 22",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей св. Ксении Петербургской",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-17:00",
                "contact_phone": "7 (812) 305-01-26",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/smolhramspb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.253336, 59.945968]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Феодосийская улица, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ленрезерв",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": "+7 812 6551945;+7 921 5552542",
                "contact_website": "http:\/\/lenrezerv.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.395944, 59.962115]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Квесты в реальности \"Взаперти\"",
                "building_levels": "-1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "+79119299522",
                "contact_website": "http:\/\/vzaperti.net",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321042, 59.932378]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дворцовая набережная, 26",
                "city_function": "Культура, развлечения, творчество",
                "name": "Русские Музыкальные Сезоны",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Sa 12:00-21:30",
                "contact_phone": "+79117537569",
                "contact_website": "https:\/\/rmseasons.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.319668, 59.943404]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Комендантский дом (Петропавловская крепость)",
                "building_levels": "1",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "7 (812) 230-64-31",
                "contact_website": "http:\/\/www.spbmuseum.ru\/themuseum\/museum_complex\/peterpaul_fortress\/",
                "contact_vk": "https:\/\/vk.com\/club31149",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316641, 59.949551]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Михайловский замок (Инженерный замок)",
                "building_levels": "3",
                "architect": "Растрелли Ф.-Б",
                "start_date": "1742-1745",
                "building_architecture": "Барокко",
                "opening_hours": "Mo,We,Fr-Su 10:00-18:00; Th 13:00-21:00; Tu off",
                "contact_phone": "+7 812 5954248",
                "contact_website": "http:\/\/www.rusmuseum.ru\/info\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.337059, 59.940235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Миллионная, 5\/1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мраморный дворец",
                "building_levels": "4",
                "architect": "Ринальди А.",
                "start_date": "1768-1785",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo, Th, Fr-Sa 10:00-18:00; Th 13:00-21:00; Tu off",
                "contact_phone": "+7 812 5954248",
                "contact_website": "http:\/\/www.rusmuseum.ru\/marble-palace\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326468, 59.945346]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Строгановский дворец",
                "building_levels": "3",
                "architect": "Растрелли Ф.-Б.",
                "start_date": "1752-1754",
                "building_architecture": "Барокко",
                "opening_hours": "Mo,We,Fr-Su 10:00-18:00; Th 13:00-21:00; Tu off",
                "contact_phone": "+7 812 5954248",
                "contact_website": "http:\/\/www.rusmuseum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Соляной переулок, 13-15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей прикладного искусства СПБГХПА им. А. Л. Штиглица",
                "building_levels": "1-3",
                "architect": "Месмахер М. Е.",
                "start_date": "1885-1895",
                "building_architecture": null,
                "opening_hours": "Tu-Sa 11:00-17:00",
                "contact_phone": "+7 812 2733258",
                "contact_website": "http:\/\/www.stieglitzmuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.341012, 59.943368]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Английская набережная, 44",
                "city_function": "Культура, развлечения, творчество",
                "name": "Особняк Н. П. Румянцева (Е. Л. Кочубея)",
                "building_levels": "3",
                "architect": "Василий Алексеевич Глинка",
                "start_date": "~1730",
                "building_architecture": null,
                "opening_hours": "11:00-18:00; We off",
                "contact_phone": null,
                "contact_website": "https:\/\/www.spbmuseum.ru\/themuseum\/museum_complex\/rumyantsev_mansion\/",
                "contact_vk": "https:\/\/vk.com\/club31752242",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.289934, 59.932207]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Расстанная ул., 30",
                "city_function": "Культура, развлечения, творчество",
                "name": "Литераторские мостки",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Fr-We 11:00-19:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.360626, 59.904621]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 77",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей городского электрического транспорта Санкт-Петербурга",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 10:00-18:00",
                "contact_phone": "+7 812 3219891",
                "contact_website": "http:\/\/getmuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.260146, 59.938779]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Великокняжеская усыпальница",
                "building_levels": "1-4",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Th-Tu 10:00-17:30",
                "contact_phone": "7 (812) 230-64-31",
                "contact_website": "http:\/\/www.spbmuseum.ru\/exhibits_and_exhibitions\/92\/1316\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317278, 59.950655]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Санкт-Петербургский монетный двор",
                "building_levels": "1-2",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Mo-Th 09:00-18:00; Fr 09:00-16:45",
                "contact_phone": null,
                "contact_website": "http:\/\/spmd.goznak.ru\/",
                "contact_vk": "https:\/\/vk.com\/gz_official",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Ж",
                "city_function": "Культура, развлечения, творчество",
                "name": "Петропавловский собор",
                "building_levels": "1-10",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "Th-Mo 10:00-18:00; Tu 10:00-17:00",
                "contact_phone": "7 (812) 498-05-26",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316623, 59.950235]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Инженерная улица, 2-4Г",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный Русский музей",
                "building_levels": "1-3",
                "architect": "Бенуа Л. Н. Овсянников С. О.",
                "start_date": "1914-1919",
                "building_architecture": "Неоклассицизм",
                "opening_hours": "Mo,We,Fr-Su 10:00-18:00; Th 13:00-21:00; Tu off",
                "contact_phone": "+7 812 5954248",
                "contact_website": "http:\/\/www.rusmuseum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330439, 59.938252]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Инженерная улица, 2-4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный Русский музей, Корпус Бенуа",
                "building_levels": "1-3",
                "architect": "Бенуа Л. Н. Овсянников С. О.",
                "start_date": "1914-1919",
                "building_architecture": "Неоклассицизм",
                "opening_hours": "We, Fr, Sa, Su 10:00-18:00",
                "contact_phone": "7 (812) 595-42-48",
                "contact_website": "http:\/\/rusmuseum.ru\/",
                "contact_vk": "https:\/\/vk.com\/rusmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.328975, 59.938707]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Цветочная улица, 16Л",
                "city_function": "Культура, развлечения, творчество",
                "name": "Гранд Макет Россия",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-20:00",
                "contact_phone": "+7 812 4955465",
                "contact_website": "https:\/\/grandmaket.ru\/",
                "contact_vk": "https:\/\/vk.com\/grand.maket",
                "contact_instagram": "https:\/\/www.instagram.com\/grandmaket\/",
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330681, 59.888045]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Болотная улица, 13",
                "city_function": "Культура, развлечения, творчество",
                "name": "Детский музейный центр исторического воспитания",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:00-18:00; Th off",
                "contact_phone": "+7 812 2974259",
                "contact_website": "http:\/\/www.polithistory.ru\/bolotnaya-13-detskiy-muzeynyy-centr-istoricheskogo-vospitaniya",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.354568, 60.001131]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный музей истории Санкт-Петербурга",
                "building_levels": "1-3",
                "architect": "Доменико Трезини",
                "start_date": "1703",
                "building_architecture": null,
                "opening_hours": "10:00-18:00",
                "contact_phone": "+7 812 2306431",
                "contact_website": "http:\/\/www.spbmuseum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Исаакиевская площадь, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Исаакиевский собор",
                "building_levels": "1-8",
                "architect": "Монферран О.",
                "start_date": "1818-1858",
                "building_architecture": "Неоклассицизм",
                "opening_hours": "10:30-18:00; We off",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.306274, 59.934069]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Петроградская наб., 2-4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Крейсер Аврора",
                "building_levels": null,
                "architect": null,
                "start_date": "1900",
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-18:00",
                "contact_phone": "+7 812 3038513",
                "contact_website": "http:\/\/www.aurora.org.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.337842, 59.95549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​23-я линия В.О., 2 лит Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ледокол «Красин»",
                "building_levels": null,
                "architect": null,
                "start_date": "1916",
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-18:00",
                "contact_phone": "+7 812 3253547",
                "contact_website": "http:\/\/www.krassin.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.269109, 59.927792]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Университетская набережная, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей антропологии и этнографии имени Петра Великого Российской Академии наук (Кунсткамера)",
                "building_levels": "5",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 11:00-18:00;Mo off;Tu[-1] off",
                "contact_phone": "+7 812 3281412",
                "contact_website": "http:\/\/www.kunstkamera.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "no",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.3049, 59.941727]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Садовая улица, 48",
                "city_function": "Культура, развлечения, творчество",
                "name": "Площадка натурных экспонатов ЦМЖТ",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo, Tu, Th 09:00-18:00; We 09:00-19:00; Fr-Sa 09:00-17:00",
                "contact_phone": "7 (812) 777-91-38",
                "contact_website": "https:\/\/guion.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/guion",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313847, 59.924857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 34",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Анны Ахматовой в Фонтанном доме",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 11:00-18:00",
                "contact_phone": "7 (812) 272-22-11",
                "contact_website": "https:\/\/akhmatova.spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/akhmatova.museum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.345459, 59.936449]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Библиотечный переулок, 4к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей железных дорог России",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Tu 10:30-18:00; We 10:30-21:00; Fr-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": "https:\/\/rzd-museum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.297605, 59.905339]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дворцовая набережная, 38",
                "city_function": "Культура, развлечения, творчество",
                "name": "Главный музейный комплекс Государственного Эрмитажа (6 зданий)",
                "building_levels": "1-3",
                "architect": "Растрелли Ф.-Б. Кваренги Д. Стасов В. П.",
                "start_date": "1764",
                "building_architecture": "Барокко",
                "opening_hours": "Tu, Th, Sa-Su 10:30-18:00; We, Fr 10:30-21:00;Mo off",
                "contact_phone": null,
                "contact_website": "https:\/\/www.hermitagemuseum.org\/",
                "contact_vk": "https:\/\/vk.com\/hermitage_museum",
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.313721, 59.941173]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бассейная улица, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Россия - моя история",
                "building_levels": "2",
                "architect": null,
                "start_date": "2017",
                "building_architecture": null,
                "opening_hours": "Tu-Su 10:00-20:00",
                "contact_phone": "+7 812 6170090",
                "contact_website": "https:\/\/myhistorypark.ru\/?city=spb",
                "contact_vk": "myhistorypark_spb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.340347, 59.865546]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заусадебная улица, 37",
                "city_function": "Культура, развлечения, творчество",
                "name": "Фондохранилище Государственного Эрмитажа \"Старая Деревня\"",
                "building_levels": "1-8",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-18:00",
                "contact_phone": "7 (812) 340-10-26",
                "contact_website": "https:\/\/www.hermitagemuseum.org\/wps\/portal\/hermitage\/?lng=ru",
                "contact_vk": "https:\/\/vk.com\/hermitage_storage",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.262616, 59.989427]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Михайлова, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей хлеба",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Sa 10:00-18:00",
                "contact_phone": "+7 812 2945090",
                "contact_website": "http:\/\/muzei-xleb.ru",
                "contact_vk": "https:\/\/vk.com\/club37126776",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.360398, 59.953624]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Университетская набережная, 1-3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Зоологический музей Зоологического института РАН",
                "building_levels": "2.5",
                "architect": "Захаров А. Д. Лукини И. Ф.",
                "start_date": "1826-1832",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo,We-Su 11:00-18:00;Tu off;We[2] off",
                "contact_phone": "+7 812 3280112",
                "contact_website": "http:\/\/www.zin.ru\/museum",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.304837, 59.942363]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Вяземский переулок, 8",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мастерская М. К. Аникушина",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "12:00-18:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.gmgs.ru\/expoz\/amasterskaja",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.297075, 59.97323]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петровская набережная, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Домик Петра I",
                "building_levels": "1",
                "architect": null,
                "start_date": "1703, 1844",
                "building_architecture": null,
                "opening_hours": "Mo,We,Fr-Su 10:00-18:00; Tu off; Th 13:00-21:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.rusmuseum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.330879, 59.953263]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кирочная улица, 43",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государственный мемориальный музей А.В. Суворова",
                "building_levels": "1-5",
                "architect": null,
                "start_date": null,
                "building_architecture": "pseudo-russian",
                "opening_hours": "We 13:00-21:00; Th-Su 10:00-18:00; Mo-Tu off",
                "contact_phone": "+7 812 5793914",
                "contact_website": "http:\/\/suvorovmuseum.ru\/",
                "contact_vk": "https:\/\/vk.com\/club16957387",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.376585, 59.943233]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 114",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Октябрьской железной дороги",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 11:00-16:00",
                "contact_phone": "+7 812 4363448",
                "contact_website": "http:\/\/railway-museum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.310739, 59.908028]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 2-4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей политической истории России",
                "building_levels": "1,2,3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.polithistory.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.325067, 59.954588]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 29-я линия В.О., 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Эрарта - Музей и галереи современного искусства",
                "building_levels": "5",
                "architect": null,
                "start_date": null,
                "building_architecture": "stalinist neoclassicism",
                "opening_hours": "We-Mo 10:00-22:00",
                "contact_phone": "+78123240809",
                "contact_website": "https:\/\/www.erarta.com\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.251621, 59.931896]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ивана Черных, 23",
                "city_function": "Культура, развлечения, творчество",
                "name": "Нарвская застава",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": "+7 812 3008329",
                "contact_website": "http:\/\/narvskaya-zastava.ru",
                "contact_vk": "https:\/\/vk.com\/club13399530",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.282963, 59.901689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ново-Александровская улица, 23",
                "city_function": "Культура, развлечения, творчество",
                "name": "Невская застава",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": "7 (812) 362-18-10",
                "contact_website": "http:\/\/nevzastava.ru\/",
                "contact_vk": "https:\/\/vk.com\/nevzastava",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.460084, 59.865704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Пискарёвского мемориального кладбища",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 08:00-17:30",
                "contact_phone": "7 (812) 297-57-29",
                "contact_website": "http:\/\/pmemorial.ru\/memorial",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.42089, 59.994364]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 72Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Пискарёвского мемориального кладбища",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 08:00-17:30",
                "contact_phone": "7 (812) 297-57-29",
                "contact_website": "http:\/\/pmemorial.ru\/memorial",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.420432, 59.994463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 24А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Арктики и Антарктики",
                "building_levels": "1-4",
                "architect": "Володихин И. П.",
                "start_date": "1901-1902",
                "building_architecture": "Модерн",
                "opening_hours": "We-Su 10:00-18:00",
                "contact_phone": "+78125712549",
                "contact_website": "http:\/\/www.polarmuseum.ru",
                "contact_vk": "https:\/\/vk.com\/polarmuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.353813, 59.927455]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Центральный музей связи имени А.С. Попова (Дворец А. А. Безбородко)",
                "building_levels": "2",
                "architect": "Кваренги Д. Любимов Н. А.",
                "start_date": "1780",
                "building_architecture": "Классицизм",
                "opening_hours": "Tu-Sa 10:30-18:00; Th[-1] off",
                "contact_phone": "+7 812 5710060",
                "contact_website": "http:\/\/rustelecom-museum.ru\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.302429, 59.932441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Стачек, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Нарвские ворота",
                "building_levels": null,
                "architect": "Стасов В. П.",
                "start_date": "1827-1834",
                "building_architecture": "Классицизм",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.2738, 59.900917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 2-4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Территория Государственного музея политической истории России",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": "7 (812) 313-61-63",
                "contact_website": "http:\/\/www.polithistory.ru\/",
                "contact_vk": "https:\/\/vk.com\/polithistorymuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.325067, 59.954588]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей петербургского авангарда (Дом Матюшина)",
                "building_levels": "2",
                "architect": "Вейнберг Е. П.",
                "start_date": "1894",
                "building_architecture": null,
                "opening_hours": "Th-Mo 11:00-18:00; Tu 11:00-17:00",
                "contact_phone": "+7 812 3476898",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.312365, 59.970875]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Камская улица, 8\/90",
                "city_function": "Культура, развлечения, творчество",
                "name": "Петербургский музей кукол",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-18:00",
                "contact_phone": "+7 812 3277224",
                "contact_website": "http:\/\/www.museumdolls.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.259499, 59.947234]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Соляной переулок, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей обороны и блокады Ленинграда",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:00-17:00; Tu off",
                "contact_phone": "+7 812 2757547",
                "contact_website": "http:\/\/blokadamus.ru",
                "contact_vk": "https:\/\/vk.com\/blockademuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.340868, 59.944436]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-квартира А. С. Пушкина",
                "building_levels": "1-3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:30-17:00; Tu off; Fr[-1] off",
                "contact_phone": "+7 812 5713531",
                "contact_website": "http:\/\/www.museumpushkin.ru\/vserossijskij_muzej_a._s._pushkina\/muzej-kvartira_a.s.pushkina.html",
                "contact_vk": "https:\/\/vk.com\/museumpushkinspb",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.321168, 59.941186]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 8Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей военного костюма",
                "building_levels": "1-2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": "7 (952) 271-34-23",
                "contact_website": "http:\/\/museumvk.ru\/ru\/visitors",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.367117, 59.933731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 94",
                "city_function": "Культура, развлечения, творчество",
                "name": "Юсуповский дворец",
                "building_levels": "3",
                "architect": "Михайлов А. А.",
                "start_date": "1830-1832",
                "building_architecture": "Классицизм",
                "opening_hours": "11:00-18:00",
                "contact_phone": null,
                "contact_website": "https:\/\/www.yusupov-palace.ru\/",
                "contact_vk": "https:\/\/vk.com\/yusupov_palace",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299016, 59.929475]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Набережная Лейтенанта Шмидта, 31а",
                "city_function": "Культура, развлечения, творчество",
                "name": "Подводная лодка С-189",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-19:00",
                "contact_phone": "+7 904 6137099",
                "contact_website": "http:\/\/www.museum-s-189.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.275614, 59.932199]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шкиперский проток, 12Н",
                "city_function": "Культура, развлечения, творчество",
                "name": "Подводная лодка Д-2 «Народоволец»",
                "building_levels": null,
                "architect": null,
                "start_date": "1929",
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-17:00; Th[-1] off \"санитарный день\"",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.23343, 59.933077]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Университетская набережная, 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "дворец Меншикова",
                "building_levels": "3",
                "architect": "Фонтана Д. М. Шедель Г. И.",
                "start_date": "1710-1714",
                "building_architecture": "Барокко",
                "opening_hours": "Tu, Th, Sa-Su 10:30-18:00; We, Fr 10:30-20:00",
                "contact_phone": "+7 812 3231112",
                "contact_website": "https:\/\/www.hermitagemuseum.org\/wps\/portal\/hermitage\/visitus\/menshikov-palace\/Index\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "limited",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.295961, 59.938811]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Инженерная улица, 4\/1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Российский этнографический музей",
                "building_levels": "2",
                "architect": "Росси К. И.",
                "start_date": "1819-1825",
                "building_architecture": "Классицизм",
                "opening_hours": "We-Sa 10:00-17:00; Tu 10:00-20:00; Su 11:00-18:00",
                "contact_phone": "7 (812) 570-54-21",
                "contact_website": "http:\/\/www.ethnomuseum.ru\/",
                "contact_vk": "https:\/\/vk.com\/ethnomuseum",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.334337, 59.937955]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дворцовая площадь, 6-8",
                "city_function": "Культура, развлечения, творчество",
                "name": "Восточное крыло Главного штаба",
                "building_levels": "4",
                "architect": "Карл Иванович Росси",
                "start_date": "1829",
                "building_architecture": "Барокко",
                "opening_hours": "Tu,Th,Sa-Su 10:30-18:00; We,Fr 10:30-21:00",
                "contact_phone": null,
                "contact_website": "https:\/\/www.hermitagemuseum.org\/wps\/portal\/hermitage\/visitus\/general-staff\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": "yes",
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.318006, 59.938703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, шоссе Революции, 84Э",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Стрит-Арта (Музей уличного искусства)",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 12:00-20:00",
                "contact_phone": "+7 (812) 244-14-94",
                "contact_website": "https:\/\/streetartmuseum.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.456984, 59.96348]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Растрелли, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Звонница Смольного собора",
                "building_levels": "1-6",
                "architect": "Кваренги Д.",
                "start_date": "1806-1808",
                "building_architecture": "Классицизм",
                "opening_hours": "Mo-Su 07:00-20:00",
                "contact_phone": "7 (812) 900-70-15",
                "contact_website": "http:\/\/smolnyspb.ru\/",
                "contact_vk": "https:\/\/vk.com\/smolnycobor",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.395558, 59.948848]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Пушкинская улица, 2\/19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мемориальный Музей-дача А.С. Пушкина",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.museumpushkin.ru\/info\/rateinfo.php",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.400166, 59.723992]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Пушкинская улица, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Интерактивный музей-театр \"Сказки Пушкина\"",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": "+78129952650",
                "contact_website": "https:\/\/skazka-pushkina.ru",
                "contact_vk": "https:\/\/vk.com\/skazka_pushkina",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.40252, 59.722391]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мемориальный Музей-Лицей А.С. Пушкина",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.museumpushkin.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.396941, 59.717482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Леонтьевская улица, 28",
                "city_function": "Культура, развлечения, творчество",
                "name": "Историко-литературный музей г. Пушкина",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.405071, 59.721102]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Павловский парк 20 литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Павловский дворец",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.466731, 59.693682]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20З",
                "city_function": "Культура, развлечения, творчество",
                "name": "Розовый павильон",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/pavlovskmuseum.ru\/about\/park\/layout\/45\/1162\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.466659, 59.690272]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица, 9",
                "city_function": "Культура, развлечения, творчество",
                "name": "Екатерининский дворец",
                "building_levels": "3",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/tzar.ru\/museums\/palaces\/c_atherine",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.395414, 59.715871]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Константиновский парк, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Константиновский дворец",
                "building_levels": "4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.058438, 59.853857]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20М",
                "city_function": "Культура, развлечения, творчество",
                "name": "Холодная ванна",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/pavlovskmuseum.ru\/about\/park\/layout\/39\/1145\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.450642, 59.686316]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, территория Больничная Горка, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Путевой дворец Петра I",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.044927, 59.853731]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Верхний парк, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Большой Меншиковский дворец",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.754062, 59.914917]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Верхний парк, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворец Петра III",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "May-Oct Tu-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.757862, 59.910451]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Верхний парк 3Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каменное зало",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.747504, 59.91245]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Верхний парк, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Китайская кухня",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.744665, 59.909057]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Верхний парк, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Павильон Катальной горки",
                "building_levels": "4",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.744378, 59.9165]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Верхний парк, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Китайский дворец",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.743857, 59.910194]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Гаванная улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Ретро Автомобилей",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 10:00-18:00; Mo off",
                "contact_phone": null,
                "contact_website": "https:\/\/vk.com\/amuseum",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.703891, 60.191633]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Левашово, Горское шоссе, 143",
                "city_function": "Культура, развлечения, творчество",
                "name": null,
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.190841, 60.09381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Дворцовая площадь, 8",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей семьи Бенуа",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.912489, 59.883376]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Дворцовая улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Александровский дворец",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.392701, 59.720998]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Дворцовый проспект, 50",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей \"Картинный дом\"",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.753756, 59.916965]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Дворцовый проспект, 48",
                "city_function": "Культура, развлечения, творчество",
                "name": null,
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.759748, 59.915743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, дорога к Шалашу Ленина, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Шалаш Владимира Ильича Ленина",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.030949, 60.082171]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Еленинская улица, 25\/15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Краеведческий музей г. Ломоносова",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.772774, 59.913902]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Магазейная улица, 40\/27",
                "city_function": "Культура, развлечения, творчество",
                "name": "Царскосельская коллекция",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Sa 11:00-17:00",
                "contact_phone": null,
                "contact_website": "http:\/\/www.museum.ru\/m2545",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.408233, 59.723003]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Посадская улица, 21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Квартира святого Иоанна Кронштадтского",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo,We,Th,Fr,Sa,Su 12:00-17:00",
                "contact_phone": "+7 812 3117745",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.761311, 59.996052]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Правленская улица, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Государевы потехи",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Mo 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.911447, 59.883476]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Правленская улица, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей игральных карт",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.911087, 59.882663]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Приморское шоссе, 411",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей-усадьба И.Е. Репина \"Пенаты\"",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.896615, 60.155843]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Профсоюзная улица, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Краеведческий музей",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.777535, 59.907938]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Емельянова, 3Ж",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей \"Сарай\"",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Tu 10:00-19:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.976916, 60.07972]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Фуражный переулок, 4\/8",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей гвардейских стрелковых полков российской императорской армии",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu 11:00-14:00; We,Fr 09:00-17:00; Th 14:00-17:00; Sa[1,3],Su[2,4] 11:00-15:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.380861, 59.707506]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Якорная площадь, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Морской Никольский собор",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 09:00-18:00",
                "contact_phone": "+7 921 3792993",
                "contact_website": "http:\/\/visitkronshtadt.ru\/morskoy_sobor",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.777795, 59.991705]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Якорная площадь, 2Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Pile Contemporary",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/www.facebook.com\/pilecontemporary\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.781604, 59.992006]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица, 9В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Агатовые комнаты",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.395234, 59.714963]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица, 9Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Камеронова галерея",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.395603, 59.714482]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, парк Александрия, 7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворец\"Коттедж\"",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.945367, 59.878441]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Средняя улица, 1\/9",
                "city_function": "Культура, развлечения, творчество",
                "name": "выставка \"Восковых персон. Любовь и власть\"",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.399447, 59.718194]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Большой дворец",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.905751, 59.883905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "дворец Марли",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "дворец Монплезир",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Соборная площадь, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей храмов Царскосельского благочиния",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.407128, 59.720417]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей костюма",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/www.pavlovskmuseum.ru\/activities\/exposition\/regular\/9\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.454101, 59.68514]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 65к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Пулковской обсерватории",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.326217, 59.771771]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "дворец \"Эрмитаж\"",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Mo 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, парк Александрия, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Фермерский дворец",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.933635, 59.879064]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Центральная аллея",
                "city_function": "Культура, развлечения, творчество",
                "name": "Царскосельский арсенал. Императорская коллекция оружия",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 11:00-17:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [30.385245, 59.719328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Императорские яхты",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Садовая улица, 8",
                "city_function": "Культура, развлечения, творчество",
                "name": "выставка Придворный экипаж",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Tu 10:00-18:00; Th-Su 10:00-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.399205, 59.71745]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20О",
                "city_function": "Культура, развлечения, творчество",
                "name": "Храм Дружбы",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/pavlovskmuseum.ru\/about\/park\/layout\/39\/1147\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.455817, 59.688187]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургское шоссе, 111М",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей викингов",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.981407, 59.868623]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Царицын остров, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Царицын павильон",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.911554, 59.876689]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Советский бульвар, 29",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории Ижорского завода",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.594121, 59.746238]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Средняя улица, 1\/9",
                "city_function": "Культура, развлечения, творчество",
                "name": "выставка \"Реликвии войны. 1941-1945 гг.\"",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Su 11:00-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.399447, 59.718194]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 77",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей мостов",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.085369, 59.733119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 39А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Кронштадского Морского завода",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.763027, 59.993644]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Коммунистическая улица, 1АА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мемориальный музей изобретателя радио А. С. Попова",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.769109, 59.989004]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Левашово, Горское шоссе, 143",
                "city_function": "Культура, развлечения, творчество",
                "name": "Левашовская пустошь",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.190841, 60.09381]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Ленинградская улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории Кронштадта",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Tu 11:00-18:00; Th-Su 11:00-18:00",
                "contact_phone": "+7 812 4350873",
                "contact_website": "http:\/\/www.kronstadt.ru\/museum\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.791243, 59.986826]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Фермская дорога, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей Первой Мировой войны",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.386413, 59.72544]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Павловск, Песчаный переулок, 11\/16",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории г. Павловска",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.442953, 59.683495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворцовая телеграфная станция",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.929997, 59.877393]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Андреевская улица, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кронштадтский Морской музей",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We,Fr,Sa,Su 11:00-18:00",
                "contact_phone": "+7 812 3110300",
                "contact_website": "http:\/\/www.kronstadt.ru\/mormuseum\/mormus.htm",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.763216, 59.99616]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Якорная площадь, 2А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей истории Кронштадта",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 11:00-18:00",
                "contact_phone": "+7 812 4350873",
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.781083, 59.991804]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, посёлок Усть-Ижора, Шлиссельбургское шоссе, 48",
                "city_function": "Культура, развлечения, творчество",
                "name": "Александр Невский и Ижорская земля",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.604802, 59.804047]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, территория Форт Константин, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей маячной службы",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 12:00-20:00",
                "contact_phone": null,
                "contact_website": "https:\/\/kronfort.com\/mayak\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.700702, 59.995314]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "city_function": "Культура, развлечения, творчество",
                "name": "Парк Патриот",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.735125, 60.006093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Правленская улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом игральных карт",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.91133, 59.88316]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музей фонтанного дела",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Tu-Su 10:30-18:00; Tu[-1] off",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.905751, 59.883905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Большой грот",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "11:00-19:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин",
                "city_function": "Культура, развлечения, творчество",
                "name": "Фёдоровский городок",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.416947, 59.722463]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, территория Форт Кроншлот, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Форт Кроншлот",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.746013, 59.979592]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, Полевая улица, 14А",
                "city_function": "Культура, развлечения, творчество",
                "name": "дача Зощенко",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.944253, 60.093465]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, парк Александрия, 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Фельдъегерский домик",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.933015, 59.878328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Банный корпус",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We-Tu 10:30-18:00",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Екатерининский корпус",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "off",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.913845, 59.885498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, Центральная аллея",
                "city_function": "Культура, развлечения, творчество",
                "name": "Музейно-исторический парк «Кронштадт Остров фортов»",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "contact_website": "https:\/\/кронштадт.рф\/",
                "contact_vk": "kronshtadt_rf",
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "street"
            },
            "geometry": {"type": "Point", "coordinates": [29.743821, 59.994693]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Особая кладовая",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Mo 10:30-18:00; Th[-1] off",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.905751, 59.883905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Церковный корпус",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "Th-Mo 10:30-18:00; Th[-1] off",
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.905751, 59.883905]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Софийский бульвар, 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Московские ворота",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "http:\/\/ilmp.ru\/?page_id=106",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.420701, 59.716111]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, территория Форт Константин, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "форт Константин",
                "building_levels": "2",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.700702, 59.995314]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Александровский парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Белая башня",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.377852, 59.720993]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Колонистский парк, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ольгин павильон",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.907431, 59.877104]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "city_function": "Культура, развлечения, творчество",
                "name": "Фондовая площадка центрального музея Октябрьской Железной Дороги",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.379514, 59.80722]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Луговой парк, литА",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дворец \"Бельведер\"",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.879736, 59.848574]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, остров Котлин",
                "city_function": "Культура, развлечения, творчество",
                "name": "Форт «Риф»",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": "https:\/\/fortreef.ru",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.735125, 60.006093]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Екатерининский парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Павильон «Грот»",
                "building_levels": "1",
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": null,
                "contact_phone": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.394156, 59.712163]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, исторический район Фридентальская Колония",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом-музей Павла Петровича Чистякова",
                "building_levels": null,
                "architect": null,
                "start_date": null,
                "building_architecture": null,
                "opening_hours": "We,Sa,Su 10:00-17:00",
                "contact_phone": "+7 812 4517085",
                "contact_website": "http:\/\/nimrah.ru\/muschist\/",
                "contact_vk": null,
                "contact_instagram": null,
                "wheelchair": null,
                "type": "Музей",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.427268, 59.714037]}
        }
    ]
}
