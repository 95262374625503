// export const COLORS = ['#F86624', '#775DD0',
//     '#FF4560', '#4CAF50', '#008FFB',
//     '#880E4F', '#A300D6', '#00BFA5',
//     '#5653FE', '#00B1F2', '#558B2F',
//     '#662E9B', '#1B998B', '#A5978B',
//     '#D4526E', '#FF6F00', '#2c3e50',
//     '#16a085']

export const COLORS = {
    green_zones: '#6CBB3C',
    embankments: '#A23BEC',
    beaches: '#FDD017',
    architectural_structures: '#1569C7',
    monuments: '#4863A0',
    viewpoints: '#E41B17',
    shop_souvenirs: '#8BB381',
    religious_buildings: '#F87217',
    bridges: '#990012',
    aqua_parks: '#1589FF',
    swimming_pools: '#3BB9FF',
    sports_grounds: '#3B9C9C',
    sports_centers: '#57FEFF',
    stadiums: '#306EFF',
    bars_pubs: '#C68E17',
    bakeries: '#C35817',
    restaurants: '#9CB071',
    cafes_canteens: '#6F4E37',
    sushi_shawarma_fast_foods: '#FF2400',
    bowling_billiards: '#9DC209',
    art_spaces: '#FFCBA4',
    zoos: '#342D7E',
    cinemas: '#893BFF',
    museums: '#348017',
    amusements_parks: '#B048B5',
    theaters_concert_halls: '#800517',
    shopping_centers: '#4CC417',
    playgrounds: '#F9966B',
    landfills: '#657383',
    other: '#008FFB'
}

export const MAP_ICON_SIZE = 30
export const LEGEND_ICON_SIZE = 30
