export const cinemas = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "city_function": "Культура, развлечения, творчество",
                "name": "МФЦ \"Великан Парк\"",
                "contact_phone": "+7 812 6766151",
                "contact_website": "http:\/\/velikan-park.com",
                "opening_hours": "Su-Th 09:00-01:00; Fr-Sa 09:00-03:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 3.0,
                "level": null,
                "capacity": null,
                "wheelchair": "yes",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "КАРО",
                "contact_phone": "+7 812 3333982;+7 812 3333981",
                "contact_website": "https:\/\/www.karofilm.ru\/theatres\/20",
                "opening_hours": "Mo-Su 10:00-3:00",
                "screen": null,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5литО",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": "8‒800‒100‒38‒68",
                "contact_website": "http:\/\/www.formulakino.ru\/",
                "opening_hours": "Mo-Su 9:30-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 3.0,
                "capacity": null,
                "wheelchair": "no",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.377412, 59.830757]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Mirage Cinema",
                "contact_phone": "+7 812 6776060",
                "contact_website": "http:\/\/www.mirage.ru\/",
                "opening_hours": "Mo-Su,PH 10:00-24:00+",
                "screen": 11.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": 3.0,
                "capacity": 1216.0,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 68Б",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино Нео",
                "contact_phone": "8‒800‒100‒38‒68",
                "contact_website": "https:\/\/kinoteatr.ru\/raspisanie-kinoteatrov\/sankt-peterburg\/neo\/",
                "opening_hours": "Mo-Su 9:30-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 2.0,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.259337, 59.931549]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 35а",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "+7 812 6771592",
                "contact_website": "http:\/\/www.mirage.ru",
                "opening_hours": "24\/7",
                "screen": 4.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": 1001.0,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.302259, 59.959485]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Люксор",
                "contact_phone": "7 (812) 677‒62‒25",
                "contact_website": "http:\/\/www.luxorfilm.ru\/",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": 9.0,
                "cinema_3d": null,
                "building_levels": null,
                "level": 2.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.370504, 59.882699]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино Академ Парк",
                "contact_phone": "8‒800‒700‒01‒11",
                "contact_website": "https:\/\/kinoteatr.ru\/sankt-peterburg\/",
                "opening_hours": "Mo-Su 9:30-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 3.0,
                "level": 2.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Дивенская ул., 5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Art Lair",
                "contact_phone": "7‒999‒206‒22‒09",
                "contact_website": "https:\/\/vk.com\/artlaircinema",
                "opening_hours": "Mo, We, Sa 19:00-22:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 15.0,
                "level": 1.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320962, 59.959868]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино Заневский Каскад",
                "contact_phone": null,
                "contact_website": "https:\/\/kinoteatr.ru\/sankt-peterburg\/",
                "opening_hours": "Mo-Su 9:30-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "city_function": "Культура, развлечения, творчество",
                "name": "Mori Cinema",
                "contact_phone": null,
                "contact_website": "https:\/\/mori-cinema.ru\/cinema_detail\/107_mori_sinema\/",
                "opening_hours": "Mo-Su 9:30-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": "yes",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Итальянская улица, 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Cinema Grand Palace",
                "contact_phone": "8 (812) 449-94-99",
                "contact_website": "https:\/\/www.cinemagrandpalace.ru\/",
                "opening_hours": "Mo-Su 11:00-23:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.333646, 59.936215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Ленфильм",
                "contact_phone": "+7 911 9912502",
                "contact_website": "http:\/\/www.lenfilm.ru\/service\/Kinoteatr-Lenfilm\/",
                "opening_hours": "09:30-23:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 2.0,
                "capacity": null,
                "wheelchair": "no",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316982, 59.958273]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Караванная улица, 12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом кино",
                "contact_phone": "7 (812) 314‒56‒14",
                "contact_website": "http:\/\/domkinospb.com\/",
                "opening_hours": "Mo-Su 9:45-21:45",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": "no",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.342162, 59.936251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Караванная улица, 12",
                "city_function": "Культура, развлечения, творчество",
                "name": "Родина",
                "contact_phone": "+7 812 3142827",
                "contact_website": "http:\/\/rodinakino.ru\/",
                "opening_hours": "Mo-Su 10:00-22:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": "yes",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.342162, 59.936251]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ковенский переулок, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Out Cinema",
                "contact_phone": "8 (965) 094-56-60",
                "contact_website": "https:\/\/outcinema.ru\/",
                "opening_hours": "Mo-Su 17:00-23:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.359005, 59.937481]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "КАРО",
                "contact_phone": "+7 960 2315003",
                "contact_website": "https:\/\/www.karofilm.ru\/theatres\/16",
                "opening_hours": "Mo-Su 10:00-2:00",
                "screen": null,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.007668]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Купчинская улица, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кино-досуговый центр \"Чайка\"",
                "contact_phone": "+7 812 7723682",
                "contact_website": "http:\/\/www.kinocentr-chaika.ru\/",
                "opening_hours": "Mo-Su 10:00-20:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.379298, 59.845397]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каро-фильм",
                "contact_phone": "7 (812) 602‒01‒32",
                "contact_website": "https:\/\/karofilm.ru\/",
                "opening_hours": "Mo-Su 10:00-3:00",
                "screen": 7.0,
                "cinema_3d": null,
                "building_levels": 4.0,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 24",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кино&Театр Англетер",
                "contact_phone": "7 (812) 494‒59‒90",
                "contact_website": "http:\/\/www.angleterrecinema.ru\/",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.308771, 59.933915]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 88к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Синема 5",
                "contact_phone": "+7 812 4935200",
                "contact_website": "https:\/\/cinema5.ru\/piter2",
                "opening_hours": "Mo-Su 10:00-01:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": "yes",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.229154, 59.938743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Московский проспект, 202",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дружба",
                "contact_phone": "+7 812 3733061",
                "contact_website": "http:\/\/www.peterburg-kino.spb.ru\/druzba",
                "opening_hours": "Mo-Su 10:00-21:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.322022, 59.859565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "city_function": "Культура, развлечения, творчество",
                "name": "КАРО Фильм - Варшавский экспресс",
                "contact_phone": "7‒960‒231‒47‒19",
                "contact_website": "https:\/\/karofilm.ru\/",
                "opening_hours": "Mo-Su 10:00-3:00",
                "screen": 9.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": 2.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 80",
                "city_function": "Культура, развлечения, творчество",
                "name": "Пандора",
                "contact_phone": "+7 812 9815434",
                "contact_website": "http:\/\/vk.com\/pandoracinema",
                "opening_hours": "24\/7",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324851, 59.909635]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Народная улица, 4",
                "city_function": "Культура, развлечения, творчество",
                "name": "Невский",
                "contact_phone": "7 (812) 333‒33‒08",
                "contact_website": "http:\/\/nevskiy-cinema.ru\/",
                "opening_hours": "Mo-Su 9:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.458503, 59.880861]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 108А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Нева",
                "contact_phone": "7 (812) 314-00-45  ",
                "contact_website": "https:\/\/spb.kinoafisha.info\/cinema\/7964616\/",
                "opening_hours": "Mo-Su 10:00-2:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 1.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.357442, 59.932045]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 60И",
                "city_function": "Культура, развлечения, творчество",
                "name": "Аврора",
                "contact_phone": null,
                "contact_website": "http:\/\/www.avrora.spb.ru\/",
                "opening_hours": "Mo-Su 10:00-23:30",
                "screen": 2.0,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": "no",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339296, 59.934565]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Невский проспект, 67",
                "city_function": "Культура, развлечения, творчество",
                "name": "Художественный",
                "contact_phone": "+7 812 3140045",
                "contact_website": "http:\/\/poravkino.ru\/main\/",
                "opening_hours": "Mo-Su 9:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 1.0,
                "capacity": null,
                "wheelchair": "limited",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.353391, 59.931724]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 47к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Детский кинотеатр \"Заневский\"",
                "contact_phone": "8 (800) 250-80-25",
                "contact_website": "https:\/\/kinoteatr.ru\/",
                "opening_hours": "Mo-Su 10:00-23:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.413713, 59.926341]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 3к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Красносельский",
                "contact_phone": "7 (812) 759‒27‒90",
                "contact_website": "http:\/\/redkdk.spb.ru\/",
                "opening_hours": "Mo-Su 9:00-18:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 3.0,
                "level": 3.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.206911, 59.846929]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": null,
                "contact_website": "http:\/\/www.formulakino.ru\/",
                "opening_hours": "Mo-Su 9:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Фильмофонд",
                "contact_phone": "+7 812 5431271",
                "contact_website": "http:\/\/www.peterburg-kino.spb.ru\/filmofond",
                "opening_hours": "Mo-Su 11:00-22:30",
                "screen": 4.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.421384, 59.982104]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Планерная улица, 59",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "+7 812 6776060",
                "contact_website": "http:\/\/www.mirage.ru\/",
                "opening_hours": "Mo-Su 10:00-03:00",
                "screen": 10.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": 3.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.225291, 60.02222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, площадь Александра Невского, 2Е",
                "city_function": "Культура, развлечения, творчество",
                "name": "Москва",
                "contact_phone": "+7 812 2742012",
                "contact_website": "https:\/\/www.kino-moscow.ru\/",
                "opening_hours": "Tu-Sa 12:00-20:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.38697, 59.924794]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "+7 812 6776060",
                "contact_website": "http:\/\/www.mirage.ru\/",
                "opening_hours": "Mo-Su,PH 10:00-24:00+",
                "screen": null,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": 3.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пражская улица, 48\/50",
                "city_function": "Культура, развлечения, творчество",
                "name": "Киноград",
                "contact_phone": "8 (812) 207-12-97",
                "contact_website": "http:\/\/cinema-grad.com\/",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.404074, 59.863328]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": null,
                "contact_website": "http:\/\/www.formulakino.ru\/",
                "opening_hours": "Mo-Su 9:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 5.0,
                "level": 4.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каро фильм",
                "contact_phone": "7 (812) 333‒16‒85",
                "contact_website": "https:\/\/karofilm.ru\/",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "city_function": "Культура, развлечения, творчество",
                "name": "Синема Парк",
                "contact_phone": "7 (800) 700-01-11 ",
                "contact_website": "https:\/\/spb.kinoafisha.info\/cinema\/532678\/",
                "opening_hours": "Mo-Su 9:00-2:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино Родео Драйв",
                "contact_phone": "8‒800‒100‒38‒68",
                "contact_website": "https:\/\/kinoteatr.ru\/sankt-peterburg\/",
                "opening_hours": "Mo-Su 9:30-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 3.0,
                "level": 2.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": "+7 812 4484448",
                "contact_website": "http:\/\/www.formulakino.ru",
                "opening_hours": "Mo-Su 9:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каро-фильм",
                "contact_phone": null,
                "contact_website": "https:\/\/karofilm.ru\/",
                "opening_hours": "Mo-Su 10:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": "no",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "+7(812)677-18-40",
                "contact_website": "http:\/\/pda.mirage.ru\/cinema\/4\/4.htm",
                "opening_hours": "Mo-Su 10:00-3:00",
                "screen": 7.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "city_function": "Культура, развлечения, творчество",
                "name": "Синема Парк",
                "contact_phone": "8‒800‒700‒01‒11",
                "contact_website": "http:\/\/www.cinemapark.ru\/",
                "opening_hours": "Mo-Su 10:00-3:30",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 2.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Тепловозная, 31",
                "city_function": "Культура, развлечения, творчество",
                "name": "Киноград",
                "contact_phone": "7‒921‒347‒95‒50",
                "contact_website": "cinema-grad.ru",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": null,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "7 (812) 677‒60‒60",
                "contact_website": "http:\/\/www.mirage.ru\/",
                "opening_hours": "Mo-Su,PH 10:00-24:00+",
                "screen": 2.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": 334.0,
                "wheelchair": "yes",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 5М",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кинопаркинг",
                "contact_phone": "+78129000778",
                "contact_website": "https:\/\/www.kinoparking.org\/",
                "opening_hours": "Fr-Su 20:00-2:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.316111, 59.97753]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж",
                "contact_phone": "+7 812 6776060",
                "contact_website": "http:\/\/www.mirage.ru\/",
                "opening_hours": "Mo-Su,PH 10:00-24:00+",
                "screen": 7.0,
                "cinema_3d": "yes",
                "building_levels": null,
                "level": null,
                "capacity": 1216.0,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.379325, 59.870014]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 6",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кинополис",
                "contact_phone": "8 (812) 643-03-95; 8 (952) 243-46-21",
                "contact_website": "http:\/\/kinopolis-film.ru\/rumba\/",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": 4.0,
                "cinema_3d": "yes",
                "building_levels": 5.0,
                "level": 3.0,
                "capacity": 748.0,
                "wheelchair": "no",
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.265616, 59.879918]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Пик",
                "contact_phone": "+7 911 9260035",
                "contact_website": "http:\/\/kinopik.spb.ru",
                "opening_hours": "Mo-Su 9:30-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "address": "​Россия, Санкт-Петербург, Литейный проспект, 58",
                "city_function": "Культура, развлечения, творчество",
                "name": "киноклуб Братья Люмьер",
                "contact_phone": null,
                "contact_website": "https:\/\/lumiere-bros.livejournal.com\/",
                "opening_hours": "Tu-Sa 12:00-20:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.348468, 59.934033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": "8‒800‒700‒01‒11",
                "contact_website": "http:\/\/www.formulakino.ru",
                "opening_hours": "Mo-Su 9:30-1:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Марата, 86",
                "city_function": "Культура, развлечения, творчество",
                "name": "7D Кинотеатр",
                "contact_phone": "+7 812 4496864",
                "contact_website": "http:\/\/7d-kino.com",
                "opening_hours": "Mo-Su 10:00-22:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.339072, 59.918886]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 22к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Восход",
                "contact_phone": "8 (812) 738-82-00",
                "contact_website": "http:\/\/www.peterburg-kino.spb.ru\/voskhod",
                "opening_hours": "Mo-Su 10:00-21:30",
                "screen": null,
                "cinema_3d": null,
                "building_levels": 2.0,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.147946, 59.838815]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 126",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "+7 812 6776060",
                "contact_website": "http:\/\/www.mirage.ru\/",
                "opening_hours": "Mo-Su,PH 10:00-24:00+",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 1.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.204342, 59.987407]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино Меркурий",
                "contact_phone": "+7 812 6000906",
                "contact_website": "www.formulakino.ru",
                "opening_hours": "Mo-Su 9:30-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": 2.0,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Синема Стар",
                "contact_phone": "+7 812 4143070",
                "contact_website": "https:\/\/cinemastar.ru\/cinema\/spb-rio\/",
                "opening_hours": "Mo-Su 10:00-1:00",
                "screen": 6.0,
                "cinema_3d": null,
                "building_levels": null,
                "level": 1.0,
                "capacity": 1029.0,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Чайковского, 24",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кино Хауз",
                "contact_phone": null,
                "contact_website": "https:\/\/spb.kinohouse.me\/",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-06:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.353418, 59.946748]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Sky Film",
                "contact_phone": "8 (812) 615-11-00",
                "contact_website": "https:\/\/skyfilm.pulkovotri.ru\/",
                "opening_hours": "Mo-Su 10:00-24:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320584, 59.805912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Набережная Обводного канала, 74 лит Д",
                "city_function": "Культура, развлечения, творчество",
                "name": "Люмьер-Холл",
                "contact_phone": "7‒999‒222‒20‒00",
                "contact_website": "www.lumierehall.ru",
                "opening_hours": "Su-Th 11:00-23:00, Fr-Sa 11:00-21:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.329082, 59.910429]}
        },
        {"type": "Feature",
            "properties": {
                "address": "​Россия, Санкт-Петербург, Лиговский проспект, 30",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": "8‒800‒700‒01‒11",
                "contact_website": "http:\/\/www.formulakino.ru",
                "opening_hours": "Mo-Su 10:00-03:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ​Якорная улица, 5а",
                "city_function": "Культура, развлечения, творчество",
                "name": "КАРО",
                "contact_phone": "7‒967‒520‒85‒69",
                "contact_website": "www.karofilm.ru",
                "opening_hours": "Su-Th 10:00-01:00; Fr-Sa 10:00-03:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "address": "​Россия, Санкт-Петербург, Коломяжский проспект, 17 к1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Формула Кино",
                "contact_phone": "8‒800‒700‒01‒11",
                "contact_website": "kinoteatr.ru",
                "opening_hours": "Mo-Su 10:00-3:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.299034, 60.005437]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "city_function": "Культура, развлечения, творчество",
                "name": "КАРО",
                "contact_phone": "7‒967‒520‒85‒69",
                "contact_website": "www.karofilm.ru",
                "opening_hours": "Mo-We 10:00-24:00; Th-Su 10:00-01:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 80\/1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кинолахта",
                "contact_phone": "7 (812) 902‒34‒67",
                "contact_website": "kinolahta.ru",
                "opening_hours": "Su-Th 21:00-24:00; Fr-Sa 21:00-02:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.188514, 59.989]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Аврора",
                "contact_phone": "7 (812) 450‒54‒54",
                "contact_website": "www.peterburg-kino.spb.ru",
                "opening_hours": null,
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.921041, 59.878897]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Сестрорецк, площадь Свободы, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Курортный",
                "contact_phone": "7 (812) 437‒17‒31",
                "contact_website": "kinokurort.ru",
                "opening_hours": null,
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.972514, 60.093743]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 32\/17",
                "city_function": "Культура, развлечения, творчество",
                "name": "Авангард",
                "contact_phone": "7 (812) 465‒81‒17",
                "contact_website": "www.avangard-kino.ru",
                "opening_hours": null,
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.412042, 59.718067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 15",
                "city_function": "Культура, развлечения, творчество",
                "name": "Sky Film",
                "contact_phone": "7 (812) 615‒11‒00",
                "contact_website": "skyfilm.pulkovotri.ru",
                "opening_hours": null,
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.320584, 59.805912]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Ломоносов, Михайловская улица, 40\/7",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кинопарк",
                "contact_phone": "7 (812) 602‒99‒15",
                "contact_website": "kinolomonosov.ru",
                "opening_hours": null,
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.768237, 59.903088]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Октябрьская улица, 10",
                "city_function": "Культура, развлечения, творчество",
                "name": "Мираж Синема",
                "contact_phone": "8‒800‒505‒17‒85",
                "contact_website": "www.mirage.ru",
                "opening_hours": "Mo-We 10:00-24:00; Th-Su 10:00-01:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.624008, 59.738072]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Колпино, Павловская улица, 34",
                "city_function": "Культура, развлечения, творчество",
                "name": "Подвиг",
                "contact_phone": "7 (812) 469‒50‒79",
                "contact_website": "dk-dosug.ru",
                "opening_hours": null,
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.581554, 59.747294]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, Царицынская улица, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Каскад",
                "contact_phone": "7 (812) 450‒79‒10",
                "contact_website": "www.vkaskade.ru",
                "opening_hours": "Mo-Su 9:00-22:00",
                "screen": null,
                "cinema_3d": null,
                "building_levels": null,
                "level": null,
                "capacity": null,
                "wheelchair": null,
                "type": "Кинотеатр",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [29.916163, 59.876219]}
        }
    ]
}
