//https://t.me/elemnt3 Y:2021
import React from 'react';
import 'antd/dist/antd.css';
import {Collapse, Select, Button} from 'antd';
import {TableOutlined, MessageOutlined, BarChartOutlined, AppstoreOutlined} from '@ant-design/icons';
import amusements_parks from '../../../assets/download/amusements_parks.geojson'
import aqua_parks from '../../../assets/download/aqua_parks.geojson'
import architectural_structures from '../../../assets/download/architectural_structures.geojson'
import art_spaces from '../../../assets/download/art_spaces.geojson'
import bakeries from '../../../assets/download/bakeries.geojson'
import bars_pubs from '../../../assets/download/bars_pubs.geojson'
import beaches from '../../../assets/download/beaches.geojson'
import bowling_billiards from '../../../assets/download/bowling_billiards.geojson'
import bridges from '../../../assets/download/bridges.geojson'
import cafes from '../../../assets/download/cafes.geojson'
import cafes_canteens from '../../../assets/download/cafes_canteens.geojson'
import cinemas from '../../../assets/download/cinemas.geojson'
import embankments from '../../../assets/download/embankments.geojson'
import green_zones from '../../../assets/download/green_zones.geojson'
import industrial_enterprises_1 from '../../../assets/download/industrial_enterprises_1.geojson'
import industrial_enterprises_2 from '../../../assets/download/industrial_enterprises_2.geojson'
import industrial_enterprises_3 from '../../../assets/download/industrial_enterprises_3.geojson'
import industrial_enterprises_4 from '../../../assets/download/industrial_enterprises_4.geojson'
import landfills from '../../../assets/download/landfills.geojson'
import monuments from '../../../assets/download/monuments.geojson'
import museums from '../../../assets/download/museums.geojson'
import playgrounds from '../../../assets/download/playgrounds.geojson'
import religious_buildings from '../../../assets/download/religious_buildings.geojson'
import restaurants from '../../../assets/download/restaurants.geojson'
import shop_souvenirs from '../../../assets/download/shop_souvenirs.geojson'
import shopping_centers from '../../../assets/download/shopping_centers.geojson'
import sports_centers from '../../../assets/download/sports_centers.geojson'
import sports_grounds from '../../../assets/download/sports_grounds.geojson'
import stadiums from '../../../assets/download/stadiums.geojson'
import sushi_shawarma_fast_foods from '../../../assets/download/sushi_shawarma_fast_foods.geojson'
import swimming_pools from '../../../assets/download/swimming_pools.geojson'
import theaters_concert_halls from '../../../assets/download/theaters_concert_halls.geojson'
import viewpoints from '../../../assets/download/viewpoints.geojson'
import zoos from '../../../assets/download/zoos.geojson'

const {Panel} = Collapse;

const DownloadFilesSection = () => {
    function callback(event) {
        const id  = event.target.offsetParent.id;
        fetch(`https://basket-api.onti.actcognitive.org/nirma/downloader?dataTime=${Math.floor(Date.now() / 1000)}&id=${id}`)
            .then((response) => response.json())
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    const amusements_parks_text = 'Парки развлечений'
    const aqua_parks_text = 'Аквапарки'
    const architectural_structures_text = 'Архитектурные сооружения'
    const art_spaces_text = 'Арт пространства'
    const bakeries_text = 'Пекарни'
    const bars_pubs_text = 'Бары, пабы'
    const beaches_text = 'Пляжи'
    const bowling_billiards_text = 'Боулинг, бильярд'
    const bridges_text = 'Мосты'
    const cafes_text = 'Кафе'
    const cafes_canteens_text = 'Cтоловые'
    const cinemas_text = 'Кинотеатры'
    const embankments_text = 'Набережные'
    const green_zones_text = 'Зеленые зоны'
    const industrial_enterprises_1_text = 'Промышленные предприятия 1'
    const industrial_enterprises_2_text = 'Промышленные предприятия 2'
    const industrial_enterprises_3_text = 'Промышленные предприятия 3'
    const industrial_enterprises_4_text = 'Промышленные предприятия 4'
    const landfills_text = 'Cвалки'
    const monuments_text = 'Памятники'
    const museums_text = 'Музеи'
    const playgrounds_text = 'Детские площадки'
    const religious_buildings_text = 'Религиозные здания'
    const restaurants_text = 'Рестораны'
    const shop_souvenirs_text = 'Магазин сувениров'
    const shopping_centers_text = 'Торговые центры'
    const sports_centers_text = 'Спортивные центры'
    const sports_grounds_text = 'Спортивные площадки'
    const stadiums_text = 'Cтадионы'
    const sushi_shawarma_fast_foods_text = 'Суши, шаверма'
    const swimming_pools_text = 'Бассейны'
    const theaters_concert_halls_text = 'Театры, концертные залы'
    const viewpoints_text = 'Видовые точки'
    const zoos_text = 'Зоопарки'

    return (
        <div id='_download' className='block'>
            <div className='container-fluid'>
                <div className="titleHolder">
                    <h2>DataSet</h2>
                    <p>Скачать данные используемые в построение сервиса</p>
                </div>
                <Collapse  expandIconPosition='left'>
                    <Panel header="amusements_parks.geojson" key="1">
                        <div>{amusements_parks_text}<a href={amusements_parks} target="_blank" rel="noopener noreferrer" download>
                            <Button id='amusements_parks' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="aqua_parks.geojson" key="2">
                        <div>{aqua_parks_text}<a href={aqua_parks} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='aqua_parks' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="architectural_structures.geojson" key="3"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{architectural_structures_text}<a href={architectural_structures} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='architectural_structures' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="art_spaces.geojson" key="4"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{art_spaces_text}<a href={art_spaces} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='art_spaces' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="bakeries.geojson" key="5"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{bakeries_text}<a href={bakeries} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='bakeries' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="bars_pubs.geojson" key="6"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{bars_pubs_text}<a href={bars_pubs} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='bars_pubs' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="beaches.geojson" key="7"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{beaches_text}<a href={beaches} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='beaches' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="bowling_billiards.geojson" key="8"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{bowling_billiards_text}<a href={bowling_billiards} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='bowling_billiards' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="bridges.geojson" key="9"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{bridges_text}<a href={bridges} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='bridges' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="cafes.geojson" key="10"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{cafes_text}<a href={cafes} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='cafes' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="cafes_canteens.geojson" key="11"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{cafes_canteens_text}<a href={cafes_canteens} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='cafes_canteens' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="cinemas.geojson" key="12"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{cinemas_text}<a href={cinemas} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='cinemas' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="embankments.geojson" key="13"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{embankments_text}<a href={embankments} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='embankments' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="green_zones.geojson" key="14"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{green_zones_text}<a href={green_zones} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='green_zones' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="industrial_enterprises_1.geojson" key="15"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{industrial_enterprises_1_text}<a href={industrial_enterprises_1} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='industrial_enterprises_1' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="industrial_enterprises_2.geojson" key="16"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{industrial_enterprises_2_text}<a href={industrial_enterprises_2} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='industrial_enterprises_2' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="industrial_enterprises_3.geojson" key="17"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{industrial_enterprises_3_text}<a href={industrial_enterprises_3} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='industrial_enterprises_3' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="industrial_enterprises_4.geojson" key="18"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{industrial_enterprises_4_text}<a href={industrial_enterprises_4} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='industrial_enterprises_4' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="landfills.geojson" key="19"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{landfills_text}<a href={landfills} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='landfills' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="monuments.geojson" key="20"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{monuments_text}<a href={monuments} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='monuments' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="museums.geojson" key="21"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{museums_text}<a href={museums} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='museums' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="playgrounds.geojson" key="22"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{playgrounds_text}<a href={playgrounds} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='playgrounds' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="religious_buildings.geojson" key="23"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{religious_buildings_text}<a href={religious_buildings} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='religious_buildings' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="restaurants.geojson" key="24"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{restaurants_text}<a href={restaurants} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='restaurants' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="shop_souvenirs.geojson" key="25"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{shop_souvenirs_text}<a href={shop_souvenirs} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='shop_souvenirs' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="shopping_centers.geojson" key="26"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{shopping_centers_text}<a href={shopping_centers} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='shopping_centers' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="sports_centers.geojson" key="27"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{sports_centers_text}<a href={sports_centers} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='sports_centers' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="sports_grounds.geojson" key="28"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{sports_grounds_text}<a href={sports_grounds} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='sports_grounds' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="stadiums.geojson" key="29"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{stadiums_text}<a href={stadiums} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='stadiums' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="sushi_shawarma_fast_foods.geojson" key="30"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{sushi_shawarma_fast_foods_text}<a href={sushi_shawarma_fast_foods} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='sushi_shawarma_fast_foods' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="swimming_pools.geojson" key="31"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{swimming_pools_text}<a href={swimming_pools} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='swimming_pools' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="theaters_concert_halls.geojson" key="32"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{theaters_concert_halls_text}<a href={theaters_concert_halls} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='theaters_concert_halls' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="viewpoints.geojson" key="33"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{viewpoints_text}<a href={viewpoints} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='viewpoints' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>

                    <Panel header="zoos.geojson" key="34"
                           extra={<MessageOutlined style={{fontSize: '16px', color: '#08c'}}/>}>
                        <div>{zoos_text}<a href={zoos} target="_blank" rel="noopener noreferrer"
                                        download>
                            <Button id='zoos' style={{textAlign: 'center'}} type="link" size='small'
                                    onClick={callback}>Скачать</Button>
                        </a>
                        </div>
                    </Panel>
                </Collapse>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    );
};

export default DownloadFilesSection

