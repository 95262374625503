//https://t.me/elemnt3 Y:2021
import React from "react";
import for_tourists from "../../assets/images/for_tourists.svg";
import for_urbanists from "../../assets/images/for_urbanists.svg";
import {Row, Col, Card} from 'antd';
const {Meta} = Card;

function MapSurvey() {
    const cardsWrapperStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
    }
    const cardsStyle = {
        width: '794px',
    };

    const goToNewYearSurvey = () => {
        const a = document.createElement('a');
        a.target = "_blank";
        a.href = "http://app.mapsurvey.ru/surveys/Happy_New_Year/Happy_NY_description/";
        a.click();
    };

    const goToSpbSurvey = () => {
        const a = document.createElement('a');
        a.target = "_blank";
        a.href = "http://app.mapsurvey.ru/surveys/Research_Daily_life_SPb/Daily_description/";
        a.click();
    };

    return (
        <div className='contactBlock' style={{ marginTop: '40px' }}>
            <div className='container-fluid'>
                <div className='titleHolder'>
                    <h2>Пройдите наши опросы</h2>
                </div>

                <div style={cardsWrapperStyle}>
                    <Row
                        gutter={[48, 1]}
                        style={cardsStyle}
                    >
                        <Col xs={{span: 24}} sm={{span: 12}} md={{span: 12}}>
                            <Card
                                hoverable
                                cover={<img alt="PunchThems" src={for_tourists}/>}
                                onClick={goToNewYearSurvey}
                            >
                                <Meta title="Новогодний Санкт-Петербург"/>
                            </Card>
                        </Col>

                        <Col xs={{span: 24}} sm={{span: 12}} md={{span: 12}}>
                            <Card
                                hoverable
                                cover={<img alt="PunchThems" src={for_urbanists}/>}
                                onClick={goToSpbSurvey}
                            >
                                <Meta title="Условия жизни в Санкт-Петербурге"/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default MapSurvey
