export const bakeries = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 15",
                "opening_hours": "Mo-Fr 09:00-21:00;Sa-Su 10:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504400",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309077, 59.916275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 18",
                "opening_hours": "Mo-Fr  08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "https:\/\/tseh85.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.311916, 59.915833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 8-10",
                "opening_hours": "07:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314754, 59.915896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 34",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa 09:00-21:00;Su 10:00-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.287499, 59.943755]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и Вафли",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 46",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285568, 59.945508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе с яйцами",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Советская улица, 6к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364009, 59.931986]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 16-я линия Васильевского острова, 73",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504449",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.261197, 59.9438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и Пельмени",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 19-я линия Васильевского острова, 14\/54",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 981 1424239",
                "website": "https:\/\/www.kafekip.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.268338, 59.934123]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 2-я Красноармейская улица, 3",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa, Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316245, 59.915494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 22-я линия Васильевского острова, 5",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 08:00-19:00",
                "contact_phone": "+7 800 6007015;+7 905 2504408",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.264287, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 25",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504424",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.280268, 59.942043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 27",
                "opening_hours": "Mo-Sa 09:00-20:00; Su 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279927, 59.942376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 27",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279927, 59.942376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ешь с хлебом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 28",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309239, 59.911448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 9",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-20:00",
                "contact_phone": "+7 921 6440664",
                "website": "https:\/\/vk.com\/etloncoffee"
            },
            "geometry": {"type": "Point", "coordinates": [30.314934, 59.911877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 45\/34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276872, 59.942038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 53",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504403",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.275183, 59.943525]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 64\/25",
                "opening_hours": "Mo-Fr 08:00-19:30;Sa-Su 12:00-19:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.27088, 59.9465]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Автовская улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264691, 59.866445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 2",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 08:30-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323162, 59.933681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee 22",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 22",
                "opening_hours": "Mo-Th 08:30-23:00; Fr 08:30-00:00; Sa 10:00-01:00; Su 10:00-23:00",
                "contact_phone": "+7 812 9818722",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319875, 59.931129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 29",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 09:00-20:00; Su 10:00-20:00",
                "contact_phone": "+7 812 5701496",
                "website": "http:\/\/www.plushkin-dom.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.313631, 59.929732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Больше Кофе!",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Александровский парк, 3Г",
                "opening_hours": "Mo-Th 10:00-22:00; Fr 10:00-23:00; Sa,Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320467, 59.954493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": "09:30-23:00",
                "contact_phone": "+7 812 2404650",
                "website": "http:\/\/starbuckscoffee.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Английский проспект, 34",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa 10:00-21:00; Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285819, 59.922643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе & сливки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Артиллерийская улица, 2",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350633, 59.941944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.378544, 59.830798]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Барклаевская улица, 5Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332828, 59.894238]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 12",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 59.86631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hipster Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Биржевой переулок, 2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 981 1274373",
                "website": "https:\/\/vk.com\/hipstercoffee"
            },
            "geometry": {"type": "Point", "coordinates": [30.293563, 59.945017]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе муз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 4",
                "opening_hours": "Mo-Fr 08:00-20:00",
                "contact_phone": "+7 911 9361382",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291084, 59.999354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый Дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 49к2",
                "opening_hours": "http:\/\/pirogov-dvorik.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.222407, 60.006615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 55к1",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.213891, 60.00715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 11\/1",
                "opening_hours": "http:\/\/www.plushkin-dom.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294021, 59.960507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 24",
                "opening_hours": "Mo-Fr 08:00-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.287077, 59.963656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 9",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3633140 (доб. 7964)",
                "website": "https:\/\/www.coffeehouse.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.294327, 59.96035]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Noa",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 11",
                "opening_hours": "Mo-Th 08:00-23:00; Fr-Su 09:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.960976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee-one",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 16",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+78123243309",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319758, 59.962242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кофейня Бонч",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 16",
                "opening_hours": "Mo-Fr 08:30-23:00; Sa 10:00-23:00; Su 10:00-21:00",
                "contact_phone": "+7 812 7407083",
                "website": "http:\/\/www.bonchcoffee.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.317009, 59.935295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 23\/12",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 6151182",
                "website": "https:\/\/sever-metropol.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.313793, 59.934452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городские кондитерские №1",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 31",
                "opening_hours": "08:30-22:00",
                "contact_phone": "https:\/\/www.konditer1.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311394, 59.933658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 67",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504406",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296671, 59.929556]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венская кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 1-3",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 911 0014784",
                "website": "http:\/\/www.coffeeshopcompany.ru\/address\/city\/18\/468\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347219, 59.92731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 1-3",
                "opening_hours": "09:00-21:50",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347219, 59.92731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 1-3",
                "opening_hours": "08:00-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347219, 59.92731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 33",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304954, 59.921692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Посадская улица, 24\/2",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.329639, 59.960534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочка, кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Разночинная улица, 14",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": "+7 (812) 986–83–86",
                "website": "https:\/\/www.bulochka78.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.291973, 59.95876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Большевиков проспект, 24а",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.487689, 59.901251]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофестория",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большеохтинский проспект, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409508, 59.952223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 25",
                "opening_hours": "Mo-Fr 08:00-22:30;Sa-Su 10:00-22:30",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.281705, 59.939347]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 31",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504443",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.279037, 59.938559]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Энди Кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 106",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "+7 921 9450629",
                "website": "https:\/\/vk.com\/andycoffee_spb"
            },
            "geometry": {"type": "Point", "coordinates": [30.313308, 59.967565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня \"Бодрый Пабло\"",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 3",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 965 815-15-91",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291919, 59.953083]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 5",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504423",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.292997, 59.953772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Синнабон",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 83",
                "opening_hours": "Mo-Th 08:00-21:00; Fr 08:00-22:00; Sa 09:00-22:00; Su 10:00-21:00",
                "contact_phone": "+7 812 6404940",
                "website": "https:\/\/cinnabonrussia.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314233, 59.967318]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 96",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-23:00",
                "contact_phone": "+7 812 3462488",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311296, 59.965584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди любят",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 49",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su,PH 09:00-22:00",
                "contact_phone": "http:\/\/ludilove.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341021, 59.970767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Bon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 80",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333547, 59.984922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 15к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350193, 59.958913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/coffeeshopcompany.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "https:\/\/www.coffeehouse.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 17",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.267386, 59.848696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr.Black Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 79к9",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-23:00",
                "contact_phone": "+7 931 000 88 00",
                "website": "https:\/\/mrblackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.247794, 59.842436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 81к1",
                "opening_hours": "Mo-Fr 07:00-23:00;Sa-Su 08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.247866, 59.842594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 30",
                "opening_hours": "07:30-22:00",
                "contact_phone": "https:\/\/www.starbucks.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36956, 59.883611]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 72к8",
                "opening_hours": "Mo-Fr 07:00-21:00; Sa-Su 08:00-21:00",
                "contact_phone": "+7 341 290-82-92;+7 800 333-41-30",
                "website": "https:\/\/coffee-like.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.380035, 59.872037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 110",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317099, 59.848308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Racer",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Введенская улица, 22",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 09:00-20:00",
                "contact_phone": "+7 931 2216121",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307047, 59.955412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофестория",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Верейская улица, 40",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328184, 59.914182]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee варим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Витебский проспект",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361296, 59.856786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 19",
                "opening_hours": "08:30-22:00",
                "contact_phone": "+7 812 3634383",
                "website": "https:\/\/british-bakery.ru\/shops\/vladimirskiy-pr-19\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.346932, 59.928519]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 23",
                "opening_hours": "08:00-22:00",
                "contact_phone": "https:\/\/spb.cofix.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34686, 59.928154]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирогоф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 28",
                "opening_hours": "http:\/\/www.pirogof.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30843, 59.927378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Финляндский проспект 4А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341299, 59.974419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦЕХ85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 17к1",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.310568, 60.054554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Галерный проезд, 3",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 08:00-19:00",
                "contact_phone": "+7 800 6007015;+7 905 2504409",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.225542, 59.936954]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и Розы",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гельсингфорсская улица, 3к11Д",
                "opening_hours": "Mo-Fr 10:00-17:00; Sa-Su 00:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332487, 59.974703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 18",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36736, 59.928988]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr.Black Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 25",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 921 6380603;+7 931 000 88 00",
                "website": "https:\/\/mrblackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.31797, 59.931012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пончкофф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 34",
                "opening_hours": "10:00-22:00",
                "contact_phone": "https:\/\/www.ponchkoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32151, 59.929082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Surf Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 34",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 10:00-00:00",
                "contact_phone": "http:\/\/vk.com\/surfcoffeespb",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32151, 59.929082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сладкоежка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 44",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/www.sladkoezka.com.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324752, 59.926868]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr.Black Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Гороховая, 25",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 931 000 88 00",
                "website": "https:\/\/mrblackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312958, 59.934168]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 105к1",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412545, 60.026626]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 116к1",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.418186, 60.034978]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "08:30-22:00",
                "contact_phone": "+7 812 6405151 ext. 221",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee voice",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гренадерская улица, 20",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347785, 59.970686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 10",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.369444, 59.938581]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-Тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Типанова, 4в",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324483, 59.853763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Красноармейская 1-я, 11а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312679, 59.917574]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Black Coffee Co.",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Дивенская улица, 2\/13",
                "opening_hours": "Mo-Fr 08:00-22:00, Sa-Su 09:30-21:00",
                "contact_phone": "+7 921 7957913",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318168, 59.959652]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 14к2",
                "opening_hours": "Mo-Fr 08:30-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.246051, 60.016771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булково",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 2",
                "opening_hours": "Mo-Fr 08:30-20:30;Sa-Su 09:00-20:30",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321842, 60.016766]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 4к3",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321734, 60.016532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе-Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ждановская набережная, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289476, 59.953025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 25",
                "opening_hours": "Mo-Sa 09:00-20:00; Su 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24076, 59.9512]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Загородный проспект, 2",
                "opening_hours": "Mo-Sa 08:00-21:00, Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346752, 59.925944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 36",
                "opening_hours": "08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.337913, 59.92414]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и Тепло",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 39",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332128, 59.922251]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофикс",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "https:\/\/spb.cofix.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr.Black Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 70",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-23:00",
                "contact_phone": "+7 921 6380603;+7 931 000 88 00",
                "website": "https:\/\/mrblackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320458, 59.917218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Правда кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 9",
                "opening_hours": "07:30-21:00",
                "contact_phone": "https:\/\/pravdacoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344093, 59.926995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 9",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344093, 59.926995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка Хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398756, 59.844985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 14",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.410038, 59.928902]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 16",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 3634383",
                "website": "https:\/\/british-bakery.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.413335, 59.92956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 23",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.415132, 59.929348]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 71к1",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.440222, 59.933302]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заставская улица, 28",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.324438, 59.888677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 14В",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504444",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35781, 59.947996]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сладкоежка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 25",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa, Su 09:00-21:00",
                "contact_phone": "https:\/\/www.sladkoezka.com.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358538, 59.947509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 8",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349393, 59.833918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Elton кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349393, 59.833918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 6",
                "opening_hours": "Mo-Fr 07:30-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "https:\/\/www.korjov.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337005, 59.922282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 25",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504427",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.306966, 59.911011]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "https:\/\/bagginscoffee.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе муз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 31",
                "opening_hours": "Mo-Sa 09:00-22:00;Su 10:00-22:00",
                "contact_phone": "+7 908 4492065",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284562, 59.947108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 35А",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa, Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312239, 59.966111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 4",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319255, 59.95744]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504402",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309984, 59.966863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42Б",
                "opening_hours": "Mo-Fr 08:30-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.307846, 59.966246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 50",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306544, 59.970731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cup'n'Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 6",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318994, 59.957678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе с собой",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Канонерский остров, 28 к3 лит А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210684, 59.898589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Кантемировская, 24",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "http:\/\/pirogov-dvorik.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344893, 59.985903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бизе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Караванная улица, 24-26",
                "opening_hours": "Mo-Fr 08:30-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "http:\/\/bize.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341416, 59.934308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Караванная улица, 28",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 812 6405151 ext. 501",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341353, 59.933893]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 17",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504440",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.359077, 59.943706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венская кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 19",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.coffeeshopcompany.ru\/address\/city\/18\/49701\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360164, 59.943683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пончкофф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 3",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 6556147",
                "website": "https:\/\/www.ponchkoff.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350831, 59.943854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 46",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.366093, 59.944039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tucano Coffee Ecuador",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 8",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 09:00-23:00",
                "contact_phone": "http:\/\/tucanocoffee.com\/ru\/locations\/russia\/?city=62",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352771, 59.944233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6405151 ext. 236",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeCube",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19",
                "opening_hours": "Mo-Fr 07:00-22:00; Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/www.coffee-cube.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297569, 60.007173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 24",
                "opening_hours": "Mo-Fr 07:30-22:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296509, 60.002503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 24Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297884, 60.001257]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 26",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 6761715",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.295225, 60.004244]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 26Б",
                "opening_hours": "Mo-Su 08:00-15:30, 16:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297399, 60.003552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 1",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 6405151 ext. 232",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.007668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венская кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 13к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259984, 60.008878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦЕХ85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 67",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "https:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.225578, 60.03771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кондратьевский просп., 39",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388883, 59.97133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315374, 59.95703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 21\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319282, 59.956935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 47",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 4144612",
                "website": "https:\/\/sever-metropol.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311871, 59.956809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Караван",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кубинская улица, 76к7с2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296545, 59.843489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кузнецовская улица, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32575, 59.872046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 10",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.351558, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 10",
                "opening_hours": "Mo-Fr 08:30-20:00; Sa 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351558, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 15",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504441",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35481, 59.926828]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый Дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 34к1",
                "opening_hours": "Mo-Su 9:00-22:00",
                "contact_phone": "+7 812 4529774",
                "website": "http:\/\/pirogov-dvorik.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.398576, 59.827021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 29",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 10:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.279073, 59.911696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Bon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302941, 59.989684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 59",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 10:00-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318913, 59.994801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 78к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.19182, 59.859366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 88",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 7757786",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.204477, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 48",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297884, 59.913289]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Fresh",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 49",
                "opening_hours": "http:\/\/coffee-fr.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297237, 59.912608]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Holden Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 50",
                "opening_hours": "Mo-Fr 08:00-21:00, Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29792, 59.912517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 55",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297336, 59.910853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейный домик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Летний сад, литБ",
                "opening_hours": "We-Mo 10:00-21:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336943, 59.945928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 107",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353481, 59.920533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 119",
                "opening_hours": "Mo-Sa 08:00-21:00;Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504405",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.352394, 59.918859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 08:30-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504407",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "http:\/\/www.coffeeshopcompany.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Варим кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360964, 59.929601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 161",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504401",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.3488, 59.914015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 171",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.349016, 59.913532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 23",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504446",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362967, 59.934808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 3633140-7970",
                "website": "https:\/\/www.coffeehouse.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 911 8179786",
                "website": "http:\/\/www.coffeeshopcompany.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffit",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "http:\/\/www.starbucks.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 41\/83",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360308, 59.930683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 48",
                "opening_hours": "http:\/\/spb.cofix.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358125, 59.925484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50Н",
                "opening_hours": "Mo-Fr 08:00-19:00; Sa-Su 09:00-19:00",
                "contact_phone": "+7 800 6007015;+7 905 2504451",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362895, 59.923419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Синнабон",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 63",
                "opening_hours": "Mo-Th 08:00-22:30; Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "https:\/\/cinnabonrussia.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358287, 59.927572]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 63",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358287, 59.927572]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 65",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.357945, 59.927166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 76",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "http:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.355376, 59.9218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 84\/2",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355098, 59.921286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушка печёт",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 98",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35437, 59.920176]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе в радость",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Липовая аллея, 11",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": "https:\/\/www.facebook.com\/11coffeeroom",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256822, 59.985791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Липовая аллея, 15",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 775 77 85",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.256858, 59.986632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе муз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 34",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 995 9982656",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348711, 59.939906]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 40",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.348719, 59.938554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Surf Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 59",
                "opening_hours": "http:\/\/surfcoffee.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347615, 59.934042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Скуратов кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 14",
                "opening_hours": "6:55-23:05",
                "contact_phone": "http:\/\/skuratov.coffee\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325381, 59.936305]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Surf Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 9",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "https:\/\/www.surfcoffee.ru\/surf-coffee-stores\/spb-mkonushennaya",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324528, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffit",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 4\/1",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.coffit.spb.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314637, 59.936034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 7",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 3155371",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.31347, 59.936066]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня Круассан",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 66к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247075, 59.938888]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пончкофф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 82Б",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304468, 59.965106]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 89",
                "opening_hours": "Mo-Sa 08:00-22:00; Su 09:00-21:00",
                "contact_phone": "+7 921 6589929",
                "website": "http:\/\/coffeers.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309014, 59.967286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофетерий",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Манежная площадь, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.93653]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киоск по продаже выпечки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Маршала Тухачевского, 4а",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 11:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425013, 59.964804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 2",
                "opening_hours": "089:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252222, 59.990287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee 3",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Медиков проспект, 5 к7",
                "opening_hours": "Mo-Th, Su 10:00-22:00 Fr-Sa 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31629, 59.96983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 137",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314673, 59.881719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буду кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 145",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319021, 59.877353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 147",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/etloncoffee.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31903, 59.876404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вафли Кофе Разговоры",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 159",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 911 7818229",
                "website": "http:\/\/vaffelspb.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319776, 59.86986]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 166",
                "opening_hours": "https:\/\/www.coffeehouse.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320818, 59.879128]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 166",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504434",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320818, 59.879128]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 182",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321123, 59.875005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 192-194",
                "opening_hours": "Mo-Sa 8:00-20:00; Su 8:00-19:00",
                "contact_phone": "+7 812 3881307",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321599, 59.863364]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 195",
                "opening_hours": "+7 812 4587413",
                "contact_phone": "https:\/\/www.korjov.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320926, 59.850043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 197",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 3711118",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320988, 59.848665]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 2\/6",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317925, 59.925858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хаус",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 208",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321941, 59.855638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 216",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322345, 59.848552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 35",
                "opening_hours": "08:30-21:00",
                "contact_phone": "+7 812 6405151-26",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317835, 59.913641]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 42\/27",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/1277\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318608, 59.914155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee SOS",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Космонавтов проспект, 14Б",
                "opening_hours": "https:\/\/vk.com\/coffeesos",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бизе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Моховая улица, 46",
                "opening_hours": "Mo-Fr 08:30-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "http:\/\/bize.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345387, 59.93877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Surf Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "Su-Th 09:30-22:00; Fr,Sa 09:30-23:00",
                "contact_phone": "http:\/\/surfcoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофебара",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291173, 59.931359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофелософия",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 106",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304316, 59.924104]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffit",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 15",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.coffit.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326414, 59.936697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 17",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 12:00-23:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326316, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "Su-Th 08:00-22:30; Fr-Sa 08:00-23:30",
                "contact_phone": "+7 812 6405151",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 22",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "+7 812 3100160",
                "website": "http:\/\/pirogov-dvorik.ru\/contacts\/pirogovyy-dvorik-m-nevskiy-prospekt\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326477, 59.933879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦЕХ85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 65-67",
                "opening_hours": "Mo-Fr 08:00-22:00, Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314664, 59.926814]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 7",
                "opening_hours": "Mo-Fr 09:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "+7 921 9289281",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327367, 59.939068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofico",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 81",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306849, 59.926918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Хлебосолов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 83",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su,PH 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306229, 59.927008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Мартынова, 20",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 800 6007015;+7 905 2504429",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.258717, 59.974153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": "Mo-Sa 07:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 10",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 4954971;+7 812 6034190",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312661, 59.967687]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня «ЧБКафе»",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 27",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+79817992020",
                "website": "https:\/\/instagram.com\/cafe_bw"
            },
            "geometry": {"type": "Point", "coordinates": [30.320566, 59.941673]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 59",
                "opening_hours": "08:00-22:00",
                "contact_phone": "https:\/\/www.taleonimperialhotel.com\/restaurant\/st-petersburg-hotel-atrium-cafe\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318563, 59.935764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе на кухне",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 17",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343141, 59.935858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 4928305",
                "website": "https:\/\/www.coffeehouse.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.30304, 59.98643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 2",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "+7 812 3290909",
                "website": "http:\/\/pirogov-dvorik.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 59.987042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 100",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.coffeeshopcompany.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355619, 59.932008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 104",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 07:00-01:00",
                "contact_phone": "+7 812 5799535",
                "website": "https:\/\/sever-metropol.ru\/wheretobuy\/spb\/kafe-konditerskie\/nevskij-104\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.356589, 59.931882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 110",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.357918, 59.931715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 111\/3",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504425",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.37062, 59.928686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "Mo-Su 09:30-23:00",
                "contact_phone": "+7 812 3851142",
                "website": "http:\/\/starbuckscoffee.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Правда кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 118",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-20:00",
                "contact_phone": "https:\/\/pravdacoffee.ru\/coffeeshop\/лиговский-пр-т-10-118\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362329, 59.931594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня \"Аддис\"",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 120",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "https:\/\/vk.com\/coffeeaddis",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363667, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 122",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.364215, 59.930818]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 124",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504435",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.364916, 59.930742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашний хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 127А",
                "opening_hours": "http:\/\/tort-na-zakaz-spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37486, 59.927125]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 128",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.366443, 59.930669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 130",
                "opening_hours": "07:00-23:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/2824\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366856, 59.930516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 15",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318204, 59.936138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 173",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504442",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.382909, 59.924172]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венская кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 22-24",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.coffeeshopcompany.ru\/address\/city\/18\/105\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324097, 59.935944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 26",
                "opening_hours": "08:30-22:00",
                "contact_phone": "+7 812 3132261",
                "website": "https:\/\/british-bakery.ru\/shops\/malaya-konyushennaya-ul-16-26n\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325291, 59.935818]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 35",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "https:\/\/www.korjov.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332631, 59.934172]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 38\/4",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/www.starbucks.ru\/store\/1023953\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331274, 59.935232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 48",
                "opening_hours": "Mo-Sa 10:00-21:00, Su 11:00-21:00",
                "contact_phone": "+7 981 8316543",
                "website": "http:\/\/coffeeshopcompany.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.334266, 59.934754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 5",
                "opening_hours": "https:\/\/www.coffeehouse.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313326, 59.936719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "opening_hours": "24\/7",
                "contact_phone": "+7 499 2704040",
                "website": "http:\/\/www.starbucks.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 6",
                "opening_hours": "07:00-23:00",
                "contact_phone": "+7 812 3128915;+7 812 3127637",
                "website": "https:\/\/sever-metropol.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314476, 59.937012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская хорошего настроения",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 60",
                "opening_hours": "07:30-23:00",
                "contact_phone": "+7 911 7770308",
                "website": "http:\/\/teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33917, 59.93406]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 74-76",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504421",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347336, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 78",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 812 4587476",
                "website": "https:\/\/sever-metropol.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.348594, 59.932879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 81",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "+7 996 7661445",
                "website": "http:\/\/www.cofix.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.359508, 59.930944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венская кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": "Mo-Su 06:30-23:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 94",
                "opening_hours": "08:30-22:00",
                "contact_phone": "https:\/\/british-bakery.ru\/shops\/nevskiy-pr-94\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35357, 59.932261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Славянские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Новоизмайловский проспект, 13 к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301702, 59.841382]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские Пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 41\/14",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410757, 59.929705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фамильная выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 23к2",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271132, 60.019203]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Lovers",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Бойцова, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310559, 59.922291]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 11",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/2150\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31612, 59.927752]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 13\/11",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504412",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316569, 59.927301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 20",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316326, 59.928293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffit",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 26",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/www.coffit.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316991, 59.927392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Big Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313595, 59.930913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee el Grano",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Сергея Тюленина, 4\/23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324366, 59.933248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Петровский проспект, 5",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504447",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.27221, 59.959233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Moose",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Петрозаводская улица, 13",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288487, 59.965453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ваше кафе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283277, 59.960043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 40",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425157, 59.985025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3549, 59.95566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 4",
                "opening_hours": "Mo-Su 09:15-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504411",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.276073, 59.90052]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 9",
                "opening_hours": "Mo-Fr 07:30-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.273495, 59.899925]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Политехническая, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свежий хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 9Т",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-Тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Проспект Непокоренных, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 29",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504415",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.3731, 60.007461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 31",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369893, 60.009606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦЕХ85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Полтавский проезд, 2",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.366839, 59.926278]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6405151 ext. 230",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бизе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 1",
                "opening_hours": "Mo-Fr 08:30-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "http:\/\/bize.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304666, 59.933005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 149к1",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 800 6007015;+7 905 2504430",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.199141, 59.985152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bliss coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 93в",
                "opening_hours": "10:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Туристская улица, 22",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 2401203",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.206696, 59.999282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 19А",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.48202, 59.906937]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 3к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471393, 59.917637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Фото",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 8к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426594, 59.897158]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 35",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.262454, 60.008437]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 39",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 800 6007015;+7 905 2504417",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.264269, 60.00792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КофеХауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3633140",
                "website": "https:\/\/coffeehouse.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296635, 60.003984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "http:\/\/www.coffeehouse.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "https:\/\/www.coffeehouse.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 46",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 775 77 85",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327519, 60.044424]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 3",
                "opening_hours": "Mo-Fr 07:30-21:00;Sa-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316281, 59.969232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 116к1",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.395171, 59.971848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 7",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504414",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.422085, 59.950682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504414",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.401945, 60.011959]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 12",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.392692, 60.01476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирогоф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 21к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40048, 60.010987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 21к1",
                "opening_hours": "08:00-21:30",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.40048, 60.010987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504438",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 115",
                "opening_hours": "09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438398, 59.8932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 229\/7",
                "opening_hours": "Mo-Su 07:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469579, 59.864561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 15",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 3859646",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327196, 60.053952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "Mo-Su 08:00-21:30",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 21\/139",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3633140 доб.7971",
                "website": "https:\/\/www.coffeehouse.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.335559, 60.051917]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 34",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.334257, 60.05362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 48",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 775 77 85",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353831, 60.049761]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 87к1",
                "opening_hours": "09:00-22:00",
                "contact_phone": "+7 812 7779274",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.414521, 60.036097]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 43",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390608, 59.858042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каравай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 39",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268509, 59.887783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 54",
                "opening_hours": "Mo 08:00-21:01;Sa-Su 09:00-21:00;Tu-Fr 08:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504413",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.265095, 59.882573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каравай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 57",
                "opening_hours": "Mo-Fr 09:30-21:00, Sa,Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256543, 59.87575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 66",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263056, 59.880405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 75",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.25949, 59.867222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирогоф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 86",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260406, 59.869643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север\/Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 88",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260703, 59.868744]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 96",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.261709, 59.866039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 82",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337275, 60.016262]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364081, 60.000263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 22",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-23:00",
                "contact_phone": "+7 812 2728273",
                "website": "http:\/\/sever.spb.su\/wheretobuy\/2862\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35976, 59.94412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Шаумяна, 8",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504418",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.41639, 59.936048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 113к1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325633, 60.037836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 3634383",
                "website": "https:\/\/british-bakery.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 51",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/www.coffeehouse.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323585, 60.014886]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 14",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35163, 59.834252]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 19",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 775 77 85",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.350265, 59.834935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.starbucks.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Costa Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗВ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27027, 59.801698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 65",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504431",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.329765, 59.767444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 13",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345692, 59.924627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 16-18",
                "opening_hours": "http:\/\/www.plushkin-dom.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346797, 59.924609]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 37",
                "opening_hours": "09:00-20:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351271, 59.922048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 39",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.351747, 59.921858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 41",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504445",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.352421, 59.921579]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 2",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.296959, 59.913776]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Boutique",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ропшинская улица, 3-5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297444, 59.957913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Российский проспект, 8",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.470639, 59.926666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пончкофф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Саблинская улица, 12",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307253, 59.957742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 103",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/3051\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289188, 59.919008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 17",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 08:30-21:00",
                "contact_phone": "https:\/\/bagginscoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33025, 59.93232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 17",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33025, 59.93232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня на Садовой",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 25",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32469, 59.929885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 25",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.32469, 59.929885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 26",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504426",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.329927, 59.931607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 29",
                "opening_hours": "08:00-22:00",
                "contact_phone": "https:\/\/sever-metropol.ru\/wheretobuy\/spb\/kafe-konditerskie\/sadovaya-d-29\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322641, 59.929037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 32\/1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323683, 59.929033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 40Б",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "https:\/\/british-bakery.ru\/shops\/sadovaya-40\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320539, 59.927662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": "Mo-Fr 07:30-22:00; Sa-Su 08:30-22:00",
                "contact_phone": "http:\/\/www.starbucks.ru\/store\/1023954",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "City Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 54",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "http:\/\/www.citycoffee.club\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309544, 59.922972]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 55-57",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 09:00-20:00; Su 10:00-20:00",
                "contact_phone": "http:\/\/www.plushkin-dom.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30878, 59.923261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 7-9-11",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504419",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.335451, 59.935651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и Пельмени",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 85",
                "opening_hours": "Sa, Su 10:00-22:00; Mo-Fr 08:00-22:00",
                "contact_phone": "www.kafekip.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292584, 59.919373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Way",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 13",
                "opening_hours": "08:00-21:00",
                "contact_phone": "http:\/\/coffeeway.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31612, 59.926828]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 4",
                "opening_hours": "Mo-Fr 08:00-22:30; Sa 08:30-22:30; Su 09:00-22:30",
                "contact_phone": "https:\/\/spb.cofix.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318779, 59.926359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦЕХ85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Смольный проспект, 17",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.39651, 59.944292]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr.Black Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Спасский переулок, 7",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 931 000 88 00",
                "website": "https:\/\/mrblackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318015, 59.928627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Арго",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Среднегаванский проспект, 6",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "http:\/\/pekarniya-argo.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246321, 59.930615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 53",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 6420638",
                "website": "http:\/\/fvolchek.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.416021, 59.959787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе с собой",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 100",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241065, 59.931684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 13",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504433",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.284032, 59.944896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr.Black Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 25",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 931 000 88 00",
                "website": "https:\/\/mrblackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.280232, 59.943814]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе муз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 21к4",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 08:00-20:00",
                "contact_phone": "+7 906 3031704",
                "website": "https:\/\/coffee-moose.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.275543, 59.910284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 43-45",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "+7 812 2520106",
                "website": "http:\/\/pirogov-dvorik.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.274959, 59.902131]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Караван",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 1\/8",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368348, 59.932144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 12",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/sever.spb.su\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369884, 59.934227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 3\/5",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.368554, 59.932365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сытнинская улица",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309176, 59.957755]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 12",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.3087, 59.957863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeCube",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Таврический переулок, 13а",
                "opening_hours": "Mo-Su 07:30-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384445, 59.950114]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Тепловозная улица, 31",
                "opening_hours": "Mo-Fr 07:00-21:00;Sa-Su 07:30-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.500607, 59.830748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, территория Усть-Славянка, Заводская улица, 31",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015;+7 905 2504432",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.556212, 59.831725]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Семейная пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 26к1",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409616, 60.043179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Токсовская улица, 2",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa 09:00-09:00;Su 21:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.425409, 60.039207]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торжковская улица, 5",
                "opening_hours": "Mo-Fr 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3094, 59.987825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Туристская улица, 10к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202626, 59.989967]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Туристская улица, 28к1",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 3260703",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.209382, 60.003228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Удельный проспект, 27а",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316874, 60.01803]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня Coffee Way",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 3",
                "opening_hours": "Mo-Su 10:00-21:45",
                "contact_phone": "+79818883750",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.145377, 59.855258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 33",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504439",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35428, 59.959629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Bon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35181, 59.95612]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Слойка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 5",
                "opening_hours": "Mo-Su 08:00-18:00",
                "contact_phone": "+7 904 607-25-25",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317692, 59.977481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белинского, 8",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/2948\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347318, 59.938432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffetta",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 2к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373711, 59.869399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "opening_hours": "+7 812 6405151",
                "contact_phone": "http:\/\/bushe.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379325, 59.870014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня \"Соffettа\"",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 6к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377412, 59.870822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 8А",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 10:00-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.380951, 59.871933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Блохина, 25",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "https:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.295836, 59.95247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Брянцева, 20к1",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.406104, 60.039202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 10",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268617, 59.878735]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ворошилова, 2",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 09:00-19:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.441875, 59.922724]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 11",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "http:\/\/www.coffeeshopcompany.ru\/address\/city\/18\/1082\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360416, 59.933686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 19",
                "opening_hours": "Mo-Fr 08:00-22:30; Sa-Su 09:00-22:30",
                "contact_phone": "+7 812 2729925",
                "website": "https:\/\/www.korjov.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360604, 59.935412]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 1Б",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-21:00",
                "contact_phone": "https:\/\/pirozhki.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360344, 59.932081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Miru",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 24\/27Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358799, 59.938094]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 3-5",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": "+7 812 3132261",
                "website": "https:\/\/british-bakery.ru\/shops\/vosstaniya-ul-3-5n\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360389, 59.93268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 55",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.361161, 59.943323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 9",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/2951\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360443, 59.933365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cup in Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 20",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302852, 59.927892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 41",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/sever.spb.su\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287257, 59.924704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 43-45",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.286844, 59.924609]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 16",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 775 77 85",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.469615, 59.9045]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лепёшки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 16Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469022, 59.904983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bageens coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 24к1",
                "opening_hours": "+7 812 1233388",
                "contact_phone": "http:\/\/bagginscoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479119, 59.9063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 27к1",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.479918, 59.905592]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Караван Востока",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "+7 812 3102440",
                "website": "http:\/\/sever.spb.su\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 812 6405151 ext. 252",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "https:\/\/www.korjov.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня-кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 2",
                "opening_hours": "Mo-Fr 9:00-20:00; Sa 10:00-18:00",
                "contact_phone": "http:\/\/vk.com\/spbakery",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360128, 60.008873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Синнабон",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 28",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "https:\/\/cinnabonrussia.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359697, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 28",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.359697, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бизе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 41",
                "opening_hours": "http:\/\/bize.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357604, 59.935701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 51",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 6405151 ext. 241",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.359769, 59.935665]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 20",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.466848, 59.919833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 16",
                "opening_hours": "Mo-Fr 09:00-22:00 Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357289, 59.954462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Elton Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357289, 59.954462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 35",
                "opening_hours": "https:\/\/www.coffeehouse.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358233, 59.955002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 35",
                "opening_hours": "Mo-Fr 07:30-21:00;Sa-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358233, 59.955002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Elton Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Котовского, 1\/10Б",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 10:00-20:00; Su 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3306, 59.961107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Слойка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Кропоткина, 1",
                "opening_hours": "09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311655, 59.959228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Saint Espresso",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326926, 59.955007]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Латышских Стрелков, 19",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504453",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.450651, 59.931783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 70",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 775 77 85",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.345279, 59.835383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 88",
                "opening_hours": "https:\/\/www.coffeehouse.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350561, 59.832683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый Дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 88",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350561, 59.832683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 6405151 ext. 245",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Room",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CUP'N'CUP",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 5",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "http:\/\/cup-n-cup.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314287, 59.96534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31567, 59.965124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Babe's Bakery",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su,PH 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345593, 59.921498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сладкоежка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 2\/73-75",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/www.sladkoezka.com.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355816, 59.931242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Больше кофе!",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 22-24",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "https:\/\/instagram.com\/bolshe_coffee",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353741, 59.928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 3",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 812 6405151 ext. 253",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354756, 59.930832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 3",
                "opening_hours": "https:\/\/spb.cofix.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354756, 59.930832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeers",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 9",
                "opening_hours": "Mo-Sa 07:30-23:00; Su 09:00-21:00",
                "contact_phone": "+7 921 6589929",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354226, 59.929804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 12к1",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.266299, 59.878793]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маршала Захарова, 62к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.213442, 59.849596]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffit",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 2\/94",
                "opening_hours": "http:\/\/www.coffit.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35384, 59.932599]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофеварим",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 28",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354936, 59.938491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 51к1",
                "opening_hours": "Mo-Su 08:00-21:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196958, 60.003408]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 14\/117",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.177105, 59.834284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 14",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347264, 59.942958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Хлебосолов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Правды, 16",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su,PH 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341138, 59.9223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Правды, 16",
                "opening_hours": "Mo-Fr 08:00-20:00;Sa-Su 10:00-18:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341138, 59.9223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Time for Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 29",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307424, 59.971398]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 37Щ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299941, 59.971564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Time For Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297578, 59.971172]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 38С",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298845, 59.971136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Радищева, 39Д",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504416",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.364153, 59.94262]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Решетникова, 5",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323845, 59.878667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожки у Кота",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Рылеева, 41",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363389, 59.942187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeehype",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Савушкина",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246204, 59.984503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 11",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.295027, 59.986038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeshop company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 126",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204342, 59.987407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeCube",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 36",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286215, 59.984814]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 14к1",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa 08:00-22:00;Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.345584, 59.971425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 19",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.346438, 59.970853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе-Хауз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Elton Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Типанова, 25к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347453, 59.85388]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeshop Company",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Типанова, 27\/39",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/www.coffeeshopcompany.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349699, 59.853812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Типанова, 3",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504428",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325336, 59.85369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Тюшина, 11",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015;+7 905 2504450",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347228, 59.917475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "opening_hours": "Fr-Sa 10:00-23:00;Mo,Tu,We,Th,Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 19",
                "opening_hours": "+7 800 3334130",
                "contact_phone": "https:\/\/coffee-like.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348172, 59.946369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 38\/9",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358646, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee BON",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331337, 59.961179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Wave",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Шаврова, 9А",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": "https:\/\/instagram.com\/coffee.wave",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.232082, 60.02303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeCube",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Шелгунова, 12к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453777, 59.865808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 6",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.390365, 59.833325]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе у девчат",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Урхов переулок, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281238, 59.893042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ушаковская набережная, 5",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312643, 59.982082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бриош",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 10",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 812 6704481;+7 981 8236663;+7 812 9811598",
                "website": "https:\/\/vk.com\/brioshkrona"
            },
            "geometry": {"type": "Point", "coordinates": [30.311403, 60.01217]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356364, 59.944999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кофе с собой",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352897, 59.945067]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каравай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Херсонская улица, 24",
                "opening_hours": "Mo-Fr 08:30-20:00; Sa-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386287, 59.92685]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Цветочная улица",
                "opening_hours": "Mo-Fr 08:30-19:00;Sa-Su 09:00-18:00",
                "contact_phone": "+7 800 6007015;+7 905 2504436",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.329702, 59.888514]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, Кирпичный переулок, 2",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "+7 981 7009477",
                "website": "http:\/\/www.cofix.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.315464, 59.936111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский просп., 44",
                "opening_hours": "07:00-23:00; Jun-Aug 00:00-24:00",
                "contact_phone": "http:\/\/sever.spb.su\/wheretobuy\/1275\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327546, 59.941047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 26",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-20:00",
                "contact_phone": "+7 800 6007015",
                "website": "https:\/\/bagginscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.294066, 59.963034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 9\/13",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "http:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.290239, 59.959872]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Школьная улица, 41",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 6405151 ext. 235",
                "website": "http:\/\/bushe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.261331, 59.98769]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦЕХ85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Юнтоловский проспект, 49к1",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "https:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.142017, 60.032767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311916, 59.915833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Cartel",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 20",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311466, 59.915837]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №35",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 8-10",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314754, 59.915896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и вафли",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 46",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285568, 59.945508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Советская улица, 12",
                "opening_hours": "08:00-21:00",
                "contact_phone": "http:\/\/www.lavkapekarya.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365096, 59.931062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cup'n'Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Советская улица, 12",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/cup-n-cup.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365096, 59.931062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starte",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 1-я Утиная улица, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298701, 60.033985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 13-я Красноармейская улица, 23",
                "opening_hours": "Mo-Fr 08:00-21:00, Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/etloncoffee.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29774, 59.915282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 15-я линия Васильевского острова, 68",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263433, 59.944143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "WayCup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 41А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352654, 59.999746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 51",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360514, 59.998193]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 19",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/zhanrua.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368446, 59.931526]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonapé",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 25\/2",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/www.bonape.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369767, 59.931089]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №5",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 18\/6",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368285, 59.932951]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 5-й Предпортовый проезд, 1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310406, 59.840704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Stop Express",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 6-й Верхний переулок, 12",
                "opening_hours": "https:\/\/stopexpress.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354298, 60.075896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wake Up Owl",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 2\/49",
                "opening_hours": "08:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316614, 59.911556]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafemax",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 30\/16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308816, 59.911444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Монастырская выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​7-я линия В.О., 68а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278822, 59.943066]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №48",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 28",
                "opening_hours": "09:00-19:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2814, 59.940235]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 7-я Советская улица, 18\/21",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37274, 59.936129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 45\/34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276872, 59.942038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №23",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 49",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27574, 59.942989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 18",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279136, 59.939338]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Space",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 10:00-20:00; Su 11:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киоск по продаже хлебобулочных изделий",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, аллея Котельникова, 1А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293635, 60.012377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колос",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Алтайская улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325938, 59.849627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Алтайская улица, 3",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322956, 59.849076]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Английский проспект, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285146, 59.922494]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тёплое место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Английский проспект, 28",
                "opening_hours": "http:\/\/pekarnitm.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284885, 59.923595]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Английский проспект, 40",
                "opening_hours": "07:30-21:30",
                "contact_phone": "http:\/\/www.lavkapekarya.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286754, 59.921606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дукани",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Английский проспект, 52",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289988, 59.917853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378544, 59.830798]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Изба-пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Д",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378885, 59.830056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Изба-пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37928, 59.829373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofe to go",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37928, 59.829373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shirshov&Co",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Балтийская улица, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28352, 59.898733]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №88",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Барочная улица, 8",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa 08:00-18:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287796, 59.96602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Way Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Басков переулок, 4",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "https:\/\/way-cup.business.site\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354684, 59.939739]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 59.86631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №14",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 39",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su 09:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319524, 59.864625]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Espresso people",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Белоостровская улица, 22",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317386, 59.986929]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro100 Cafe",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Белоостровская улица, 23",
                "opening_hours": "10:00-20:00",
                "contact_phone": "http:\/\/pro100.cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.98738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уютное кафе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Белоостровская улица, 28",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa, Su 11:00-20:00",
                "contact_phone": "+7 812 3350100",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311412, 59.987371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Все дома",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский 49к1",
                "opening_hours": "Mo-Fr 09:15-17:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 54\/32с3",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2109, 60.006008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу Пури",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 54\/32с3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2109, 60.006008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Bon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 5к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292853, 60.000686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293024, 59.961188]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 2",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295575, 59.9593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29447, 59.95985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Большая Зеленина, 13",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "https:\/\/www.korjov.com",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295324, 59.96357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чорек",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323719, 59.940001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Московская улица",
                "opening_hours": "Mo-Fr 07:30-22:00; Sa 08:00-22:00; Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346752, 59.925944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 16",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "http:\/\/bulkahleba.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346222, 59.925326]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №6",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большая Пороховская улица, 19",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415599, 59.953268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дедас Пури",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Большой проспект В.О., 16",
                "opening_hours": "Mo-Fr 07:30-22:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285352, 59.939343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теплое место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 39\/14",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276818, 59.937891]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские кондитерские",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 80",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3087, 59.963944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №9",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 47",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341542, 59.970335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 49",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/ludilove.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341021, 59.970767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Siesta",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 68к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33599, 59.979664]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 76",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su,PH 09:00-22:00",
                "contact_phone": "http:\/\/watrushka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334472, 59.983504]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кифлице",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 6",
                "opening_hours": "Mo-Fr 09:30-18:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353256, 59.9578]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бакерай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322174, 59.915138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышландия",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 16",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322174, 59.915138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Будапештская улица",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385802, 59.849483]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 46",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379334, 59.857414]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 46",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379334, 59.857414]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная N68",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 74к1",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390581, 59.845455]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная Усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259418, 59.842061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "https:\/\/coffee-like.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 17",
                "opening_hours": "9:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267386, 59.848696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 17",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/tseh85.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.267386, 59.848696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 43",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389368, 59.860843]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffie to Go",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Варшавская улица",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314206, 59.862153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 58",
                "opening_hours": "http:\/\/lavkapekarya.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314709, 59.860012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonapé",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 63к1",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 09:00-21:00",
                "contact_phone": "http:\/\/www.bonape.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314116, 59.849153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PANE",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 6к2",
                "opening_hours": "Mo-Su,PH 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315266, 59.879353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Калач",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Витебский проспект, 47п",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355942, 59.847715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковный хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348459, 59.928149]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bengel",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 7",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": "http:\/\/bengel.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347516, 59.93089]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonapé",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 37\/6",
                "opening_hours": "http:\/\/www.bonape.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307801, 59.92396]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №39",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 45",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307666, 59.922088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь Пышка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Волковский проспект, 30",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357496, 59.896657]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Воронежская улица, 54",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346456, 59.913429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Leon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Воронежская улица, 54",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346456, 59.913429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Выборгское шоссе, 1Б",
                "opening_hours": "Mo-Fr 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Выборгское шоссе, 1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312041, 60.051239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прованс",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 34",
                "opening_hours": "http:\/\/provansspb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316362, 60.040802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Глухая Зеленина улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281642, 59.96362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №44",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 29",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31921, 59.930142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 45",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": "https:\/\/bulochka78.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322228, 59.928117]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная Лавка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 59",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328004, 59.924221]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Розы",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 66",
                "opening_hours": "08:00-21:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330951, 59.92281]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тандыр",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 102",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409661, 60.02637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка АМ!",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Гражданский проспект, 26а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380286, 60.008419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Гражданский проспект, 40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401945, 60.011959]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Panda Express",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 76",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397947, 60.014661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тоне #1",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Гражданский проспект, 84 к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385721, 60.01691]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 27\/2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369911, 59.938288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пирожки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Грибакиных, 27в",
                "opening_hours": "http:\/\/b-pirogki.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466974, 59.864286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PANE",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Дачный проспект, 21к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257091, 59.840117]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Димитрова, 13а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385802, 59.849483]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 14к1",
                "opening_hours": "http:\/\/buhanka.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333448, 59.831955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bakery",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 7к7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332478, 59.826302]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Елецкая, 15Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318635, 60.016627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди любят",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321842, 60.016766]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белорусский Дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318321, 60.016654]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318635, 60.016627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект",
                "opening_hours": "https:\/\/vatrushkadomoi.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332343, 59.922143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 2",
                "opening_hours": "https:\/\/etloncoffee.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346788, 59.927608]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №31",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 20",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342279, 59.925858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Загородный проспект, 48в",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/b-pirogki.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332343, 59.922143]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shirshov",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 52",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "http:\/\/shirshov-bakery.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328921, 59.919815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебник",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 20",
                "opening_hours": "https:\/\/british-bakery.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414692, 59.929849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная №64",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 23",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415132, 59.929348]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сёстры",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 24\/35",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416201, 59.930124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Слойка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "https:\/\/tseh85.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ам! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Заставская улица, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320692, 59.888627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 25",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358538, 59.947509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 8",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/ludilove.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349393, 59.833918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338065, 59.92056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 6",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/watrushka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337005, 59.922282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bengel",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 4",
                "opening_hours": "http:\/\/bulkahleba.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308376, 59.915643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Монастырская выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 7А",
                "opening_hours": "http:\/\/bengel.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305825, 59.916451]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lobby Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 17",
                "opening_hours": "Mo-Fr 07:45-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334293, 59.936133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 2\/21",
                "opening_hours": "http:\/\/lobbycoffee.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319372, 59.95698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшкин дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 26-28",
                "opening_hours": "https:\/\/coffee-like.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 31-33",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31276, 59.964976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 32",
                "opening_hours": "Mo-Fr 08:00-22:30;Sa-Su 08:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.312913, 59.96407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee to go",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 43",
                "opening_hours": "http:\/\/bulkahleba.com",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309265, 59.968538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лаваш хачапури",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 44\/16Б",
                "opening_hours": "Mo-Fr 08:00-22:00;Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307972, 59.969205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 44\/16Б",
                "opening_hours": "Mo-Fr 07:45-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307972, 59.969205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каравай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 59",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306148, 59.972357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волконский",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318815, 59.957908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Bon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 17",
                "opening_hours": "http:\/\/wolkonsky.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340446, 59.984602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344147, 59.984796]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Капитанская улица, 4",
                "opening_hours": "08:00-21:00",
                "contact_phone": "https:\/\/coffee-like.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214736, 59.954773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Карпинского, 15 к3",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa,Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424097, 60.007614]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rocco Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 28к2",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359859, 59.944323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361898, 59.944107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Комендантский проспект, 2д",
                "opening_hours": "http:\/\/leningradskie.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260065, 60.007438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 34к1",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242413, 60.021146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 51к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.238577, 60.031]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 71",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221608, 60.041099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кременчугская улица, 11к2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369291, 59.922666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350453, 59.92763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №15",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 8",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350777, 59.927567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cannelle",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 46",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269686, 59.911236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 3к1",
                "opening_hours": "https:\/\/cannelle.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368123, 59.987407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебчик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 3к8",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3731, 59.987015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#Пекарня v1.0",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 7к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377448, 59.988599]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная No57",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 59",
                "opening_hours": "Mo-Su 07:30-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318913, 59.994801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тёплое место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 114",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242934, 59.852447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 119Б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/pekarnitm.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258322, 59.851345]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Навруз",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 125",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262742, 59.851399]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 138\/5",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27839, 59.852316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 161",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318913, 59.851801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 162",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307379, 59.852773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "лаваш",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 162А",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30781, 59.85214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 72к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.184184, 59.862357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 77к1",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.190751, 59.858268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Добро",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 88",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204477, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Калачи - Пекарня на Лермонтовском",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 10\/53",
                "opening_hours": "Mo-Sa 08:00-22:00; Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292826, 59.922675]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №40",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 55",
                "opening_hours": "Mo-Sa 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297336, 59.910853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь-Пышка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 1А",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353975, 59.960422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 4А",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa 10:00-21:00; Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351684, 59.962489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 5",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352474, 59.962386]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Рус",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Лесной проспект, 63Б",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341219, 59.984904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Zernyshkin bakery",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 63Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341219, 59.984904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Walkman Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 106",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353912, 59.919477]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тандыр",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 111-113-115И",
                "opening_hours": "Mo-Su 07:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352717, 59.919216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинский лаваш",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 130",
                "opening_hours": "Mo-Su 07:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350759, 59.914525]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь-Пышка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 27\/7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362356, 59.933924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 33-35",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/king-pyshka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361476, 59.932748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "One Price Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 43-45",
                "opening_hours": "http:\/\/bulkahleba.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35993, 59.930196]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mouse Tail",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 46А",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358224, 59.925863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рэпйоу кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 64-66",
                "opening_hours": "Mo-Fr 07:45-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356113, 59.922815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Липовая аллея, 15",
                "opening_hours": "07:00-23:00",
                "contact_phone": "https:\/\/vsrap.shop\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256858, 59.986632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булки-баранки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 35",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347974, 59.940772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булки-баранки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 40",
                "opening_hours": "11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348719, 59.938554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе в квадрате",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Литейный проспект, 56а",
                "opening_hours": "Mo-Su 08:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348585, 59.941416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЛюдиЛюбят",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 8",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349214, 59.946288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая сказка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лоцманская улица",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/ludilove.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277259, 59.918129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сестрорецкий хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Лыжный переулок, 2",
                "opening_hours": "09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.201333, 59.99638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мука",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Львиный переулок, 1",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 09:00-22:00",
                "contact_phone": "+7 967 5510283",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300705, 59.92731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Магнитогорская улица, 21к1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429963, 59.937797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Балканская улица, 27Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381059, 59.829834]}
        },
        {"type": "Feature",
            "properties": {
                "name": "БротХаус",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 12",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325408, 59.936575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pane",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325408, 59.936575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №53",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 19",
                "opening_hours": "http:\/\/pane-bakery.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310245, 59.934853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарсон",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 22",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa 11:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309113, 59.934064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная 58",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 7\/4",
                "opening_hours": "Mo-Sa 09:00-22:30; Su 09:00-21:00",
                "contact_phone": "https:\/\/www.garcon.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322291, 59.957305]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волконский",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 3\/54",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33723, 59.934601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Добрый пекарь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект, 11",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/wolkonsky.com\/spb",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327232, 59.913776]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Добропек",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 65к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242628, 59.938568]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 75",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235702, 59.937932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 1Б",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290419, 59.953619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Маршала Казакова, 1 к4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237867, 59.856474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Loran",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 19к2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.217278, 59.996245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "БулкаХлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 35к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.199572, 59.995638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 47к1",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "http:\/\/vk.com\/bulkakhleba",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196985, 59.994346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Татарская выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 47к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196985, 59.994346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 147",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31903, 59.876404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape Булочная с кафе (один столик)",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 155\/23",
                "opening_hours": "Mo-Su 08:30-21:30",
                "contact_phone": "https:\/\/людилюбят.рф",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319713, 59.871306]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 165",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32, 59.866499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теплое место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 167",
                "opening_hours": "http:\/\/bushe.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.863884]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 171",
                "opening_hours": "Mo-Su 07:00-22:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31983, 59.862321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Миндаль",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 172",
                "opening_hours": "08:00-21:00",
                "contact_phone": "http:\/\/www.bonape.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320881, 59.877619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 195",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320926, 59.850043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Leon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 2\/6",
                "opening_hours": "https:\/\/coffee-like.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317925, 59.925858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "One Price Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 2\/6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317925, 59.925858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 23",
                "opening_hours": "Mo-Fr 08:00-23:00;Sa-Su 08:00-22:30",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317665, 59.915882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ам! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 37\/1",
                "opening_hours": "https:\/\/coffee-like.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317916, 59.913501]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лепешка №1",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317961, 59.925385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебник",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317961, 59.925385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 43",
                "opening_hours": "Mo-Su 07:00-21:00",
                "contact_phone": "https:\/\/xleb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317916, 59.912247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пуд хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 49",
                "opening_hours": "http:\/\/людилюбят.рф\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317943, 59.911511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №7",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 7",
                "opening_hours": "07:00-20:00",
                "contact_phone": "http:\/\/pbread.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.924718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь-пышка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 7",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.924718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ам! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 73",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317934, 59.905001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 73к5",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314808, 59.904189]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ам! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский проспект, 75",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/buhanka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31815, 59.903422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Московский район, Авиационная улица, 28",
                "opening_hours": "07:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328507, 59.854797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Моховая улица, 26",
                "opening_hours": "07:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345548, 59.941903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №66",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Моховая улица, 27-29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344947, 59.94202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#ЯвКофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Моховая улица, 39",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344812, 59.939821]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#Мука",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мытнинская улица, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37751, 59.934078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №17",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Мытнинская улица, 20",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378499, 59.934822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PrestoCoffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 107\/6",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299932, 59.925872]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cup'n'Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 16",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327232, 59.935687]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cup'n'Cup",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/cup-n-cup.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Bon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 30-32Ж",
                "opening_hours": "Mo-Fr 10:00-21:00; Sa-Su 12:00-21:00",
                "contact_phone": "http:\/\/cup-n-cup.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327968, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №28",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 79\/23",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307595, 59.926828]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ласточка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 28",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.948374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бриошь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 108",
                "opening_hours": "https:\/\/vk.com\/lastochkabakery",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31294, 59.908028]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/brioshspb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Island",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 09:00-21:00",
                "contact_phone": "http:\/\/pishechnaya-fabrika.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 122",
                "opening_hours": "Mo-Fr 07:30-21:30; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297507, 59.908047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Набережная Обводного канала, 122а",
                "opening_hours": "https:\/\/coffee-like.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329325, 59.912256]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 142\/16",
                "opening_hours": "http:\/\/b-pirogki.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275938, 59.908678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Слойкин",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 14литЗ",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "http:\/\/pekarnya-buhanka.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.39386, 59.917511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 16",
                "opening_hours": "Mo-Fr 07:00-20:00; Sa-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306903, 59.969218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "12 апреля",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 44",
                "opening_hours": "07:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320387, 59.936444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 6",
                "opening_hours": "Mo-Fr 08:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322974, 59.9417]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самаркандские лепешки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Набережная реки Фонтанки, 130а",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308179, 59.907501]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 53к2",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303355, 59.986988]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебник",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 23\/2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273351, 59.903133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковный хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Народного Ополчения проспект, 143Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тандыр",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Науки проспект, 21\/1 лит А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарсон",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 103",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368051, 59.929628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fresh Point",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 109",
                "opening_hours": "https:\/\/www.garcon.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369632, 59.929024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 11\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.936404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 118",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "https:\/\/coffee-like.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362329, 59.931594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 120",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363667, 59.930895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №60",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 15",
                "opening_hours": "http:\/\/leningradskie.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318204, 59.936138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Месье Патиссье",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 190",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384958, 59.924046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ам! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 27",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327052, 59.93502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 32-34",
                "opening_hours": "http:\/\/www.amtasty.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329037, 59.935511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 35В",
                "opening_hours": "https:\/\/coffee-like.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331526, 59.933974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №90",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 52",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335775, 59.934515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочные Ф. Вольчека №89",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 78",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "http:\/\/fvolchek.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.348594, 59.932879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Хлебосолов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Невский проспект, 88",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351801, 59.932464]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 26к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306463, 59.860852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 21",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406885, 59.9363]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 13",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/lavkapekarya.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318635, 60.016627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Изба-пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Олеко Дундича, 21 лит А",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410874, 59.830997]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Олеко Дундича, 28а",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406409, 59.838715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буфет",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, орабельная, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.219587, 59.875239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хворост",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ординарная улица, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304693, 59.967628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Парадная улица, 3к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367261, 59.942079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 27к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268437, 60.019451]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Парголовская улица, 11к1",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3458, 59.986043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Перекопская улица, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272354, 59.901978]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №26",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 26",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "https:\/\/zhanrua.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316991, 59.927392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Матюшенко, 10",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 09:00-20:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.441803, 59.876192]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ваша пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, переулок Матюшенко, 8",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442647, 59.876639]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сдоба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 19",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291173, 59.957561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PANE",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405511, 59.957584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408413, 59.964863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffew Port",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 2к2Щ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405313, 59.958606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бурже",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356436, 59.955737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 28",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368518, 60.007834]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашний пекарь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Политехническая, 1п",
                "opening_hours": "Mo-Fr 08:00-18:30",
                "contact_phone": "https:\/\/coffee-like.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363802, 59.992038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Drive Café (АЗС Сервис)",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 3-5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303561, 59.932775]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пражская улица, 26к1",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 10:00-20:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": "https:\/\/hlebnasushny.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.392, 59.872656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 137к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216146, 59.983531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee SOS",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72б",
                "opening_hours": "Mo-Fr 08:00-19:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Просвещения проспект, 19в",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321572, 60.051949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Просвещения проспект, 36Б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/watrushka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334248, 60.051553]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Просвещения проспект, 74 к2г",
                "opening_hours": "http:\/\/b-pirogki.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386134, 60.041853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Просвещения проспект, 88г",
                "opening_hours": "+7 812 7028833",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369183, 60.045736]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Franzette",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 38к1",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221949, 60.027256]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482479, 59.908507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 110",
                "opening_hours": "http:\/\/bushe.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202096, 59.832747]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петергофские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Героев, 23",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.162121, 59.863012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424888, 59.896567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шодо",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 25",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415464, 59.893926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 3",
                "opening_hours": "Mo-Su 8:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4269, 59.897722]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeCube",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 2к7",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316308, 60.002054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Александръ",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект КИМа, 4",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242125, 59.948884]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ProСлойка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 57к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247138, 60.028105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Печка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cofix",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоти",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 30",
                "opening_hours": "http:\/\/www.cofix.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496789, 59.945644]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня Тоне",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 11к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371734, 60.039503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница.",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 12к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 911 1477308",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367503, 60.039265]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 22к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37442, 60.046998]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каравай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 64Г",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347857, 60.040096]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашний пекарь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 64к4",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347812, 60.040195]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теплого Хлеба и Под Одеяло",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 10к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316335, 59.974707]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282154, 59.850156]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парфе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Наставников, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488381, 59.940871]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 14к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402169, 60.012413]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 17к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385595, 60.014895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Румяный хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 30к1",
                "opening_hours": "Mo-Su 10:00-20:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418932, 60.008099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonapé",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Науки, 69",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424322, 60.005967]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе и булочка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 49",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38785, 59.994247]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 110к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4686, 59.866667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теплое Место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 138к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484715, 59.845785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeeport",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 271",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479254, 59.851245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 21д",
                "opening_hours": "http:\/\/www.coffeeport.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369183, 60.045736]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Микадо",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 27к3А",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa,Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340671, 60.051068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 32к1",
                "opening_hours": "Mo-Fr 08:00-18:30",
                "contact_phone": "+7 800 7770218",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331634, 60.054055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BakeryShop",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 33к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344533, 60.050273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 76А или 76к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395189, 60.041004]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 87д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369183, 60.045736]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Калачи",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304199, 59.924564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сдоба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291614, 59.922138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 73",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286403, 59.92097]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городские кондитерские N 1",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 15",
                "opening_hours": "Mo-Sa 08:00-21:00; Su 10:00-20:00",
                "contact_phone": "http:\/\/leningradskie.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374501, 59.854788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Все дома",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 16",
                "opening_hours": "Mo-Fr 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382074, 59.857206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 4",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370549, 59.855308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Любимая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 58к2",
                "opening_hours": "08:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413057, 59.865533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теплое место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 5к3",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371231, 59.854314]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебников",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Славы, 5к3",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371231, 59.854314]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 4\/1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275552, 59.899261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 40",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269093, 59.887228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 69",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259247, 59.869517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 75",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25949, 59.867222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Британские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 90",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261116, 59.867918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 11\/57",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.946058]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Route",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 14",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359787, 59.946157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 5",
                "opening_hours": "https:\/\/vk.com\/coffeeroute",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358978, 59.948208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 113к1",
                "opening_hours": "07:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325633, 60.037836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №8",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 113к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326567, 60.037625]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №22",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня - Пури",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 132",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330035, 60.046818]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мишка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 27Д",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327241, 60.007866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мишка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 9к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436278, 59.937761]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 10к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352133, 59.836409]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 19",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350265, 59.834935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 14Г",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "http:\/\/tseh85.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325732, 59.8321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cosmos Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 105б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/buhanka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348405, 59.923712]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АМ! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 23",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348252, 59.923595]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №27",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 34",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35066, 59.922774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №27",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 43\/1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352852, 59.921376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Репищева улица, 19к3",
                "opening_hours": "9:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286763, 60.025646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Хлебосолов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Решетникова, 3",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321725, 59.878676]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Саблинская улица, 13",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30728, 59.957246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 105",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288155, 59.918787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mouse Tail",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 10Б",
                "opening_hours": "www.lavkapekarya.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336475, 59.936544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 13-15\/50Б",
                "opening_hours": "https:\/\/mousetail.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3352, 59.935209]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зерна",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 32\/1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "https:\/\/zhanrua.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323683, 59.929033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 32\/1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/zernacafe.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323683, 59.929033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "One Price Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 38",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321788, 59.928158]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Хлебосолов",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 40Б",
                "opening_hours": "http:\/\/onepricecoffee.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320539, 59.927662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Leon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 44",
                "opening_hours": "Mo 09:00-18:00; Tu-Sa 09:00-19:30; Su 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31497, 59.925344]}
        },
        {"type": "Feature",
            "properties": {
                "name": "4 Cups",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 5",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335739, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeers",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 54",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su,PH 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309544, 59.922972]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Moon",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 56",
                "opening_hours": "http:\/\/coffeers.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307271, 59.922219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Садовая улица, 91",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291416, 59.919103]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хорошее кафе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 48к4",
                "opening_hours": "Mo-Fr 08:00-21:30; Sa,Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38255, 60.025296]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня Тоне",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 54Д\/2",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393968, 60.034699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Светлановский проспект, 62а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369183, 60.045736]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №2",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 66",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/b-pirogki.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398244, 60.040353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lobby Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 64к1",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314682, 59.987046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецкая улица",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290985, 59.98692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сиреневый бульвар, 16к6",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362625, 60.052142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин выпечки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сиреневый бульвар, 18А",
                "opening_hours": "Mo-Fr 08:30-19:00",
                "contact_phone": "http:\/\/lavkapekarya.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364081, 60.051872]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Смолячкова, 14а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342458, 59.970781]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Солдатский переулок, 2",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360784, 59.936697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Солидарности проспект, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.491855, 59.921114]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Весёлый пекарь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 10\/9",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341677, 59.923383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Time for Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Спасский переулок, 6-8",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318222, 59.928943]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каравай",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 27\/16",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415707, 59.953646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №86",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 100",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241065, 59.931684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 100",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241065, 59.931684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282011, 59.944337]}
        },
        {"type": "Feature",
            "properties": {
                "name": "OnePriceCoffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 34",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27698, 59.942444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Калачи",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 21к2",
                "opening_hours": "https:\/\/onepricecoffee.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275525, 59.911092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито-Гврито",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 35Б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277232, 59.903661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 41",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "http:\/\/www.chitogvrito.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275327, 59.902343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ам! Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277699, 59.903381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeers",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 43-45Б",
                "opening_hours": "http:\/\/www.amtasty.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274456, 59.901842]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка АМ!",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Стачек площадь, 1 к1",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/coffeers.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 59.875863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царские пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Стачек площадь, 1 к1",
                "opening_hours": "07:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 59.875863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Стачек проспект, 208 к2",
                "opening_hours": "Mo-Sa 08:00-21:00; Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240194, 59.845749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Стачек проспект, 90Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 59.875863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкины пироги",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Стачек проспект, 96а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 59.875863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №74",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Столярный переулок, 7",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": "http:\/\/b-pirogki.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311475, 59.927428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Brioche",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 2",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366623, 59.931535]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПечьЛанч",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 26-28",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373333, 59.937328]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булки-баранки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 3\/5",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368554, 59.932365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сашина пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 42",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380457, 59.941741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточные сладости",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Счастливая улица, 4",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273216, 59.845568]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №13",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307774, 59.957723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 20",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311448, 59.958255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Тверская улица, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386718, 59.947036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Тепловозная, 31",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510389, 59.828699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 7",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395701, 60.045354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Типанова, 3а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324483, 59.853763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мой хлебный",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 20к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367027, 60.016397]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Товарищеский проспект, 22к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.487788, 59.914935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торжковская, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30304, 59.98643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ай вкусно",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Time for coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Торфяная дорога, 4в",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255573, 59.994148]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Торфяная дорога, 4г",
                "opening_hours": "11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255573, 59.994148]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Торфяная дорога, 6 ст1",
                "opening_hours": "Mo-Su,PH 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фаст-фуд",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Торфяная дорога, 6г",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa, Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тили-тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Торфяная дорога, 6д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241874, 59.986637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вау! Десерт",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Слойка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебник",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256885, 59.990012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чумовой кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7Т",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257684, 59.990714]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Cantine",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7Ф",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260056, 59.990804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pride mart",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Труда площадь, 4Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297776, 59.933298]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Туристская улица, 28к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209382, 60.003228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан Руа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Туристская улица, 30к1",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.21028, 60.005036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 25",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315006, 60.017468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 25",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315006, 60.017468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bakery Street",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 5",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146257, 59.853401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мука",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 7",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146203, 59.85181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городские пекарни",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Адмирала Черокова, 18к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.142089, 59.853482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Jakov la Patisserie",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 8к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314817, 59.977197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Объедение",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Аккуратова, 12",
                "opening_hours": "http:\/\/jakovspb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307289, 60.016271]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Александра Матросова, 9",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347543, 59.982896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Архитектора Баранова",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359679, 59.956358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная N94",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425409, 59.895732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пражские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 36к2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440482, 59.879389]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бул-лавка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 1к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37601, 59.868898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 8",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38176, 59.872516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №24",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Блохина, 23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297336, 59.952326]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №18",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Брянцева, 20к1",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406104, 60.039202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прованс",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 11к4",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40287, 60.006246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Восстания, 37-39",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360919, 59.939717]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ай да, Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Всеволода Вишневского, 10",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/bulkahleba.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301594, 59.96711]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербургские пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293572, 59.926111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебушек",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 39",
                "opening_hours": "Mo-Fr 07:45-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287706, 59.924826]}
        },
        {"type": "Feature",
            "properties": {
                "name": "121 градус",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 20к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392225, 59.850233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Румяный Хлеб Булочная с кофе и столиками",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 22к3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394363, 59.850816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 22к3",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394363, 59.850816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 16В",
                "opening_hours": "Mo-Su 08:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468878, 59.904099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебчик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киоск по продаже выпечки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Есенина, 22",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336628, 60.045529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Baggins Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffeers",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 6",
                "opening_hours": "7:30-22:00 open",
                "contact_phone": "http:\/\/bagginscoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323333, 59.924578]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №49",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 38",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "http:\/\/coffeers.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363569, 59.935949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "pro100.cafe",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Калинина, 13",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252968, 59.897569]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная №62",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Коли Томчака, 18\/26",
                "opening_hours": "http:\/\/pro100.cafe",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325157, 59.88841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee syndicate",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 18",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464476, 59.91931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 5\/1",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 11:00-20:00",
                "contact_phone": "+7 812 3350100",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.449996, 59.913144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булка хлеба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 6к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45057, 59.915535]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 12Б",
                "opening_hours": "+7 921 4203053",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314341, 60.053081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мини-пекарня Ам!",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 16",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357289, 59.954462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная №1",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 45",
                "opening_hours": "Mo-Fr 08:00-22:00, Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35349, 59.955674]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 51",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351926, 59.955836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин кондитерских и хлебобулочных изделий",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 48к2",
                "opening_hours": "7:00-21:00",
                "contact_phone": "http:\/\/lavkapekarya.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234858, 59.955773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городские кондитерские N 1",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Красного Курсанта, 25В",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280906, 59.959935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Snack Rroom",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Латышских Стрелков, 31",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44686, 59.932924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тепло",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 47",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235469, 59.841536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 53",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236825, 59.840591]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ленина, 27",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302259, 59.963327]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 19",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329424, 59.849889]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая на Льва Толстого",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/bushe.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 4",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314036, 59.965696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee First",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 7",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314871, 59.965268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 60\/7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347597, 59.92184]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Moroshka",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 78",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341946, 59.920167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Station",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Марата, 79а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345593, 59.921498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф.Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к2Д",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa 09:00-22:00; Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246392, 59.859994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маршала Тухачевского, 13",
                "opening_hours": "08:00-21:00",
                "contact_phone": "http:\/\/fvolchek.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432272, 59.966354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два гуся",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 19\/15",
                "opening_hours": "Mo-Fr 17:30-20:00; Sa-Su,PH 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355672, 59.93855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeVarka",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 21а",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355187, 59.937905]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №10",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354936, 59.938491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лаванда",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Мира, 37",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328265, 59.962034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PANE",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Нахимова, 9",
                "opening_hours": "Mo-Sa 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.223485, 59.941867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Олеко Дундича, 6",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38891, 59.828916]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fazer",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ольги Форш, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394084, 60.045426]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 51к1",
                "opening_hours": "https:\/\/www.fazer.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196958, 60.003408]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346195, 59.942913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Mie (булочная)",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 13-15",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343788, 59.94248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские булочные",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 21",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346438, 59.942588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 34к1",
                "opening_hours": "http:\/\/leningradskie.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.147847, 59.833855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 6\/5",
                "opening_hours": "09:00-21:00",
                "contact_phone": "www.hlebnaya-usadba.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456302, 59.908683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинский лаваш",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Полярников, 13",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434634, 59.878048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 31",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305879, 59.971353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Рихарда Зорге, 4к1",
                "opening_hours": "http:\/\/www.lindfors.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.179782, 59.851019]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невские берега",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295872, 59.986169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Симонова, 1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323441, 60.056175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Стахановцев, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407838, 59.927617]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Типанова, 8",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331903, 59.851878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бул-Лавка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Уточкина, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262966, 60.012728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тесто",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ушинского, 33к3",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414782, 60.040218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ушинского, 35Б",
                "opening_hours": "http:\/\/vk.com\/testodoma",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414674, 60.040663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Хошимина, 11к1",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327052, 60.052335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ХЛЕБ`S",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Хошимина, 16",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32486, 60.053696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Выпечка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349348, 59.946396]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mishka Bakery",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352492, 59.946743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлеб & Сыр",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 36",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булки-баранки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 38\/9",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358646, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeVarka",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 38\/9",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 09:00-22:00",
                "contact_phone": "http:\/\/bulki-baranki.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358646, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee route 90",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 59",
                "opening_hours": "Mo-Sa 08:00-21:00; Su 09:00-19:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36011, 59.946266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 15",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331337, 59.961179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Слойка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 15к2В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331732, 59.961877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 2",
                "opening_hours": "08:00-20:00",
                "contact_phone": "http:\/\/sloykabakery.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33255, 59.958084]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Etlon coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 5",
                "opening_hours": "07:00-20:00",
                "contact_phone": "https:\/\/bulochka78.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332729, 59.958791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295566, 59.907316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша семья",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 36-38-40",
                "opening_hours": "Mo-Fr 08:00-21:30; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292, 59.904771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня Тоне",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Щербакова, 6А",
                "opening_hours": "Mo-Fr 08:30-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298558, 60.028411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городская пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 11Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390752, 59.832222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Учительская улица, 18к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405241, 60.032218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мишка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31267, 60.013632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "A Petit Paris",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31267, 60.013632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тёплое место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 32",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308511, 60.017112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки пирожки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Финский переулок, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353813, 59.956512]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки - Russian donuts",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фонтанная улица, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366892, 59.938698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Nevsky Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 12",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352151, 59.945517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арнаут",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360002, 59.945396]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека №43",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 42",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361646, 59.945382]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин при заводе \"Каравай\"",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Херсонская улица, 22",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385712, 59.927689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Художников проспект, 26а",
                "opening_hours": "Mo-Fr 08:00-20:00, Sa,Su 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351136, 60.044846]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Leon Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 18",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339458, 59.927405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Time for Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339458, 59.927405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Way Cup Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34907, 59.939167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 26",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/людилюбят.рф\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357092, 59.938987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Семейный Пекарь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 52",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365913, 59.938703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/bakerfamily.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295324, 59.96357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышка-пышка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Шамшева улица, 8",
                "opening_hours": "http:\/\/fvolchek.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300848, 59.960336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 33к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.431248, 59.959539]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 74к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197075, 60.004942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Энгельса проспект, 133а",
                "opening_hours": "09:30-21:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Южное шоссе, 57к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402735, 59.856054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Юнтоловский проспект, 49к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.142017, 60.032767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Юнтоловский проспект, 49к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.142835, 60.031211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Якорная улица, 5А",
                "opening_hours": "Mo-Fr 07:00-23:00; Sa-Su 08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, ​Ярослава Гашека, 12а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожочный ларёк",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ярослава Гашека, 13а",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389296, 59.836057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинская пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Яхтенная, 38Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.219173, 59.994562]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царскосельский хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 41",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41753, 59.728455]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411109, 59.72909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Есть хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Валерия Гаврилина, 13к1",
                "opening_hours": "http:\/\/hlebnaya-usadba.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335005, 60.079281]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебное место",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Николая Рубцова, 9",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/instagram.com\/estxleb",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341443, 60.076977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Петергоф, Ботаническая улица, 18к6",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 (981) 842-11-54",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.854438, 59.869948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Церковная лавка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Усть-Ижора, ​Шлиссельбургское шоссе, 217а",
                "opening_hours": "Mo-Fr 07:00-22:00; Sa, Su 08:00-22:00",
                "contact_phone": "+7 967 5745388",
                "website": "http:\/\/hlebnaya-usadba.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.602045, 59.804151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин свежей выпечки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, ​1 Мая, 1 к1",
                "opening_hours": "Mo-Fr 07:45-22:00; Sa-Su 09:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.103714, 59.73041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свежий хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, набережная реки Сестры, 17Д",
                "opening_hours": "Mo-Sa 09:00-21:00",
                "contact_phone": "+7 952 2269019",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.970074, 60.100856]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Город 78",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный, Советская улица, 27",
                "opening_hours": "Mo-Sa 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.157238, 60.122565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Мишеля",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, ​Полевая, 15Б",
                "opening_hours": "Mo-Su 07:30-20:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.566829, 59.814161]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлеб насущный",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 108",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.08865, 59.733373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буханка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Валерия Гаврилина, 13к1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335758, 60.079716]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 17",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "buhanka.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34375, 60.077547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8к1",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336717, 60.072085]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, Театральная улица, 12а",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/fvolchek.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.083035, 59.723207]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, ​Фёдора Абрамова, 4и",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331361, 60.068852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная Усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Павловское шоссе, 25",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 6420638",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416322, 59.706177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, ​Фёдора Абрамова, 4в",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "http:\/\/hlebnaya-usadba.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336079, 60.069758]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельница",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, ​Заречная, 25",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329488, 60.074695]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Panier",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 25к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329726, 60.071656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КруаСаныч",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Октябрьский бульвар, 7\/29",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405598, 59.725124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бублик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Валерия Гаврилина, 5",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334735, 60.0749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338463, 60.066274]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волконский",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, ​Парковая, 1 лит К",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": "7‒904‒559‒17‒99",
                "website": "http:\/\/www.bonape.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.399549, 59.710748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная душа",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, Красногородская улица, 17к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "7‒931‒369‒40‒85",
                "website": "http:\/\/wolkonsky.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.076732, 59.717845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 53",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.090647, 59.739668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Традиционная пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронштадт, Петровская улица, 5",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": "+7 931 3613667",
                "website": "hlebnaya-usadba.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.783924, 59.986085]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин кондитерских изделий и мороженого",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 25\/2",
                "opening_hours": "Mo-Su 08:30-20:30",
                "contact_phone": "7‒952‒205‒54‒65",
                "website": "традиционная-пекарня.рф"
            },
            "geometry": {"type": "Point", "coordinates": [29.764309, 59.995694]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка Пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Победы, 7",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": "7 (812) 943‒12‒31",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773443, 59.907763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 25к1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "7 (812) 984‒06‒66",
                "website": "lavkapekarya.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.326589, 60.071211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люди Любят Хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 19к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "http:\/\/www.bonape.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34446, 60.078375]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Народный кондитер",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 4",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "7‒921‒444‒45‒95",
                "website": "людилюбят.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.334558, 60.070536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная Ф. Вольчека",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8к1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "7 (812) 914-14-15",
                "website": "narodny-konditer.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.336877, 60.072599]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 4Ц",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "7 (812) 677‒61‒89",
                "website": "fvolchek.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.390766, 59.705313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Поэзия вкуса",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 16\/19",
                "opening_hours": "24\/7",
                "contact_phone": "7‒931‒342‒55‒81",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408456, 59.719207]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Павловск, Детскосельская улица, 17\/17",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "7 (812) 642‒06‒38",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429547, 59.686135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня Ф. Вольчека “Булочная N84”",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Борисова, 4",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "hlebnaya-usadba.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.971949, 60.101572]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кренделек Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Володарского, 4\/2",
                "opening_hours": "Mo-Su 08:30-21:00",
                "contact_phone": "7 (812) 476‒79‒33",
                "website": "fvolchek.ru"
            },
            "geometry": {"type": "Point", "coordinates": [29.972206, 60.096129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ватрушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Токарева, 16В",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "7 (812) 943‒12‒31",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.954257, 60.089746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Токарева, 1Б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "7 (812) 642‒06‒38",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.955237, 60.086241]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сестрорецкий хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Токарева, 8",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "7‒911‒180‒02‒86",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.954299, 60.086303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 40к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.143861, 59.788699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бриош",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронштадт, Советская улица, 33Б",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "hlebnaya-usadba.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778402, 59.99369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буду Кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Пулковское шоссе, 60к1Б",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "7 (812) 434‒57‒04",
                "website": "http:\/\/vk.com\/kroncafebriosh"
            },
            "geometry": {"type": "Point", "coordinates": [30.329934, 59.793877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Булочная 24",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 25к2",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "7 (812) 943‒12‒31",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329295, 60.072166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буше",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 25",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 9811598",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406485, 59.719383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грушенька",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Железнодорожная улица, 36",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426901, 59.726239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грушенька",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Генерала Хазова, 4",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398952, 59.738044]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грушенька",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Красной Звезды, 24",
                "opening_hours": "09:00-20:00",
                "contact_phone": "+7 (812) 640-51-51, 224",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405481, 59.70832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грушенька",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Конюшенная улица, 1",
                "opening_hours": "Mo-Fr 09:00-21:30, Sa-Su 10:00-21:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443483, 59.686462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грушенька",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 14\/50",
                "opening_hours": "Mo-Fr 07:00-21:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 4668112",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405823, 59.717904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе Кондитерская",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Петергоф, Калининская улица, 9",
                "opening_hours": "Mo-Fr 09:00-18:00; Su 11:00-19:00",
                "contact_phone": "+7 812 4765188",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.911864, 59.883065]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 4С",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": "+7 812 4522840",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390473, 59.70651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кофейня \"Кекс и кофе\"",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Колпино, Тверская улица, 50",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.611477, 59.737413]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавка пекаря",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Архитектора Данини, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418123, 59.696037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дворик",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка, Ростовская улица, 14-16",
                "opening_hours": "http:\/\/lavkapekarya.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466874, 59.738979]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дом",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 67",
                "opening_hours": "Mo-Fr 08:00-21:00, Sa-Su 10:00-21:00",
                "contact_phone": "http:\/\/pirogov-dvorik.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.088569, 59.735889]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирогоф",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416544, 59.72733]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 28",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 812 3831751",
                "website": "https:\/\/www.pirogof.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [29.763189, 59.992952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожковая",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ломоносов, улица Рубакина",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.768745, 59.916463]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 16",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 9232955",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406802, 59.718489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Ломоносов, Привокзальная площадь, 1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.770731, 59.917581]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 85",
                "opening_hours": "Mo-Su 08:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.083549, 59.729102]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские блины",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Лицейский переулок, 5",
                "opening_hours": "Mo-Sa 08:00-20:00; Su 11:00-20:00",
                "contact_phone": "+7 812 4668112",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398632, 59.718091]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Север-Метрополь",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 61к1",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.08896, 59.737489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлеб из прошлого века",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Вишерская улица, 1к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371028, 59.810867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, Театральная улица, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.082088, 59.723577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебник",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 25",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405848, 59.719605]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебниковъ",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 21",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": "+7 953 1706377",
                "website": "https:\/\/xleb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.408732, 59.719482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царскосельский хлеб",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 41",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 (981) 842-11-54",
                "website": "http:\/\/хлебниковъ.рф\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.417587, 59.728352]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь пышка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Дубковское шоссе, 2А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.958025, 60.093331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 214к2",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272259, 60.073323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех 85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-02:00",
                "contact_phone": "http:\/\/tseh85.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316992, 59.819914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех85",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Николая Рубцова, 5",
                "opening_hours": "Mo-Fr 07:45-22:00; Sa-Su 09:30-22:00",
                "contact_phone": "https:\/\/tseh85.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342752, 60.076113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Экспресс Кафе",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Средняя улица, 8",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39945, 59.718525]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8",
                "opening_hours": "https:\/\/coffee-like.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338317, 60.073759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Coffee Like",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 4",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": "https:\/\/coffee-like.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.334993, 60.070253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "CoffeeCube",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326978, 59.784958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Costa Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗВ",
                "opening_hours": "Fr 08:00-22:00;Mo-Th 08:00-21:00;Sa-Su 09:00-22:00",
                "contact_phone": "+7 812 7757785",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26983, 59.801446]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пекарня",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Вишерская улица, 14",
                "opening_hours": "kfzolushka.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374231, 59.806593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золушка",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Колпино, Павловская улица, 82",
                "opening_hours": "Mo-Fr 07:30-21:00;Sa-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.567188, 59.748025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дача",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, посёлок Комарово, Морская улица, 2",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 341 2908292;+7 800 3334130",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.80706, 60.185738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Briosh",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронштадт, ​Ленина проспект, 13а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.766291, 59.997448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киоск по продаже хлебобулочных изделий",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, ​Садовая, 20а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446383, 59.687804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киоск по продаже хлебобулочных изделий",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, ​Садовая, 20д",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444739, 59.687115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирожки, мороженое",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, Красногородская улица, 17",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "7 (812) 946‒54‒57",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.076511, 59.718297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Красное Село, улица Массальского, 4",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.087647, 59.738214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 8",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.768165, 59.998623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эклер",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Колпино, бульвар Трудящихся, 15к5",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.569722, 59.73705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Keb House & Good Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкин, Леонтьевская улица, 9А",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401615, 59.717865]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Keb House & Good Coffee",
                "city_function": "Питание",
                "type": "Булочная",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Привокзальная площадь, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432947, 59.69313]}
        }
    ]
}
