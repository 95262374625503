export const amusements_parks = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк имени Бабушкина",
                "city_function": "Культура, развлечения, творчество",
                "name": "Верёвочный парк",
                "opening_hours": "Fr-Su 12:00-20:00",
                "contact_website": "http:\/\/kingkongpark.ru\/highparks\/park_babushkina\/",
                "contact_vk": null,
                "contact_phone": "+7 921 9692902",
                "barrier": null,
                "description": "Веревочный парк",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.439216, 59.882975]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, территория Озерки",
                "city_function": "Культура, развлечения, творчество",
                "name": "Верёвочный парк",
                "opening_hours": "12:00-22:00",
                "contact_website": "http:\/\/ozerki.treetotree.ru\/",
                "contact_vk": null,
                "contact_phone": "+7 965 0160806",
                "barrier": "fence",
                "description": "Веревочный парк",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.305879, 60.034214]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "city_function": "Культура, развлечения, творчество",
                "name": "Веревочный парк \"Высотный Город\"",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/vysotnygorod.ru\/",
                "contact_vk": "https:\/\/vk.com\/vgorod",
                "contact_phone": "+7 812 4256864",
                "barrier": null,
                "description": "Веревочный парк",
                "level": 3.0,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Александровский парк, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Восток",
                "opening_hours": "10:00-18:00",
                "contact_website": "http:\/\/vostokpark.ru\/",
                "contact_vk": null,
                "contact_phone": "7 (812) 640-46-11",
                "barrier": null,
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.307743, 59.951533]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "city_function": "Культура, развлечения, творчество",
                "name": "Высотный Город",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/vysotnygorod.ru\/",
                "contact_vk": "https:\/\/vk.com\/vgorod",
                "contact_phone": "+7 812 4256864",
                "barrier": null,
                "description": "Веревочный парк",
                "level": 2.0,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кузнецовская ул., 25В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Гагарин Парк",
                "opening_hours": "Mo-Fr 12:00-21:00; Sa-Su,PH 11:00-22:00",
                "contact_website": "http:\/\/gagarinpark.ru",
                "contact_vk": "https:\/\/vk.com\/gagarinparkspb",
                "contact_phone": "+7 921 1865236",
                "barrier": "fence",
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.328795, 59.868166]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Сосновка",
                "city_function": "Культура, развлечения, творчество",
                "name": "Детские аттракционы",
                "opening_hours": "Mo-Fr 12:00-19:00; Sa-Su 11:00-19:00",
                "contact_website": null,
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.351308, 60.013636]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 21Э",
                "city_function": "Культура, развлечения, творчество",
                "name": "Диво-остров",
                "opening_hours": "Mo-Fr 12:00-22:00; Sa-Su 11:00-23:00",
                "contact_website": "https:\/\/www.divo-ostrov.ru\/",
                "contact_vk": "https:\/\/vk.com\/divoostrov",
                "contact_phone": "+7 812 3239707",
                "barrier": null,
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.251342, 59.9726]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 3-5",
                "city_function": "Культура, развлечения, творчество",
                "name": "Дом Великана",
                "opening_hours": "11:00-00:00",
                "contact_website": "https:\/\/bm5spb.ru\/",
                "contact_vk": "https:\/\/vk.com\/bm5spb",
                "contact_phone": "+7 812 9385506",
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.317287, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Кронштадт, территория Форт Константин",
                "city_function": "Культура, развлечения, творчество",
                "name": "Кингвинч",
                "opening_hours": "Mo-Fr 12:00-22:00; Sa-Su,PH 11:00-23:00",
                "contact_website": "http:\/\/kingwinch.ru\/",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": null,
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.703142, 59.998619]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 32",
                "city_function": "Культура, развлечения, творчество",
                "name": "Маза-парк",
                "opening_hours": "10:00-06:00",
                "contact_website": "http:\/\/mazapark.ru\/",
                "contact_vk": "https:\/\/vk.com\/club3442",
                "contact_phone": "+7 812 6433333",
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": 3.0,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.370504, 59.882699]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "city_function": "Культура, развлечения, творчество",
                "name": "Норвежский веревочный парк «Елагин»",
                "opening_hours": "Jun 6-Oct 31: Mo-Fr 11:00-21:00; Sa 10:00-21:00; Su 10:00-20:00",
                "contact_website": "http:\/\/norwaypark.ru\/spb\/elagin\/",
                "contact_vk": "https:\/\/vk.com\/norwaypark",
                "contact_phone": "+7 812 9240608",
                "barrier": null,
                "description": "Парк построен в урбанистическом стиле на искусственных опорах. В парке есть 4 трассы, 50 различных этапов и 12 зип-лайнов. Для людей любого возраста и физической подготовки.",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Выборгский район, территория 1-е Парголово",
                "city_function": "Культура, развлечения, творчество",
                "name": "Парк активного отдыха \"Зима-Лето\"",
                "opening_hours": null,
                "contact_website": "http:\/\/zimaletopark.ru\/",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 60.057932]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк Есенина",
                "city_function": "Культура, развлечения, творчество",
                "name": "Парк аттракционов  \"Восторг\"",
                "opening_hours": "Sa-Su 11:00-20:00",
                "contact_website": "http:\/\/parkesenina.ru",
                "contact_vk": null,
                "contact_phone": "+7 812 9500332",
                "barrier": "fence",
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.482022, 59.913119]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3В",
                "city_function": "Культура, развлечения, творчество",
                "name": "Парк аттракционов",
                "opening_hours": null,
                "contact_website": "http:\/\/vostokpark.ru\/",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, парк имени Бабушкина",
                "city_function": "Культура, развлечения, творчество",
                "name": "Парк Сказок",
                "opening_hours": "11:00-20:00",
                "contact_website": "https:\/\/www.parkskazok.ru\/",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.439216, 59.882975]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "city_function": "Культура, развлечения, творчество",
                "name": "Планета Лета",
                "opening_hours": "Mo-Fr 12:00-22:00; Sa-Su 11:00-23:00",
                "contact_website": null,
                "contact_vk": "https:\/\/vk.com\/planetaleta",
                "contact_phone": null,
                "barrier": "fence",
                "description": "Парк аттракционов",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "city_function": "Культура, развлечения, творчество",
                "name": "Родео Драйв",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "www.aqua-rodeo.ru",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366993, 60.033892]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергоф, территория Шуваловка",
                "city_function": "Культура, развлечения, творчество",
                "name": "Русская деревня \"Шуваловка\"",
                "opening_hours": null,
                "contact_website": "http:\/\/www.shuvalovka.ru\/",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": null,
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.982216, 59.868144]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Зеленогорск, Зеленогорский парк культуры и отдыха",
                "city_function": "Культура, развлечения, творчество",
                "name": "Советские аттракционы",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": null,
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "other"
            },
            "geometry": {"type": "Point", "coordinates": [29.701761, 60.190321]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "city_function": "Культура, развлечения, творчество",
                "name": "Хлоп Топ",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "https:\/\/familyparks.ru\/about\/",
                "contact_vk": null,
                "contact_phone": "7 (812) 425-13-11",
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341336, 59.853385]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "city_function": "Культура, развлечения, творчество",
                "name": "Хлоп Топ",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/familyparks.ru\/about\/",
                "contact_vk": null,
                "contact_phone": "7 (812) 425-13-11",
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.206392, 59.991089]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "city_function": "Культура, развлечения, творчество",
                "name": "Хлоп Топ",
                "opening_hours": "Mo-Su 10:00-22:01",
                "contact_website": "https:\/\/familyparks.ru\/about\/",
                "contact_vk": null,
                "contact_phone": "7 (812) 425-13-11",
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331905, 60.052578]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "city_function": "Культура, развлечения, творчество",
                "name": "Angry Birds Activity Park",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "activityparkspb.ru",
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Семейный парк развлечений",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354234, 59.987876]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Морская набережная, 35",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": 2.0,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.219811, 59.959264]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Культуры, 41",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": "exact"
            },
            "geometry": {"type": "Point", "coordinates": [30.389656, 60.053224]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Солдата Корзуна, 1, к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.216101, 59.834067]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25, к. 1, лит А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315665, 59.820033]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Фучика, 2",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358318, 59.877887]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Планерная, 59",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.22539, 60.022147]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, ул. Якорная, 5А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41652, 59.939901]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51А",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144974, 59.849498]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "city_function": "Культура, развлечения, творчество",
                "name": "Joki Joya",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/joki-joya.ru\/",
                "contact_vk": "vk.com\/joki_joya_info",
                "contact_phone": "7 (812) 426-18-00",
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332931, 60.058911]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Долгоозёрная, 14 к2",
                "city_function": "Культура, развлечения, творчество",
                "name": "GORILLAPARK",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246554, 60.016704]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "city_function": "Культура, развлечения, творчество",
                "name": "Волшебная миля",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334142, 60.058495]}
        },
        {"type": "Feature",
            "properties": {
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "city_function": "Культура, развлечения, творчество",
                "name": "КотоВаська",
                "opening_hours": null,
                "contact_website": null,
                "contact_vk": null,
                "contact_phone": null,
                "barrier": null,
                "description": "Семейный парк активного отдыха",
                "level": null,
                "wheelchair": null,
                "type": "Парк развлечений",
                "precision": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377969, 59.829355]}
        }
    ]
}
