//https://t.me/elemnt3 Y:2021
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
// import Loader from "react-loader-spinner";
import './app.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, {FlyToInterpolator, Layer, Popup, ScaleControl, Source} from 'react-map-gl';
import Pins from './pins';
import CityInfo from './city-info';
import LegendaPanel from "./LegendaPanel";
import PURE_TOPIC from './pure_topic.json';
import white from '../../../../assets/thematicsquare/white.geojson'
import black from '../../../../assets/thematicsquare/black.geojson'

import { amusements_parks } from '../../../../assets/service_types/amusements_parks.js'
import { aqua_parks } from '../../../../assets/service_types/aqua_parks.js'
import { architectural_structures } from '../../../../assets/service_types/architectural_structures.js'
import { art_spaces } from '../../../../assets/service_types/art_spaces.js'
import { bakeries } from '../../../../assets/service_types/bakeries.js'
import { bars_pubs } from '../../../../assets/service_types/bars_pubs.js'
import { beaches } from '../../../../assets/service_types/beaches.js'
import { bowling_billiards } from '../../../../assets/service_types/bowling_billiards.js'
import { bridges } from '../../../../assets/service_types/bridges.js'
import { cafes } from '../../../../assets/service_types/cafes.js'
import { cafes_canteens } from '../../../../assets/service_types/cafes_canteens.js'
import { cinemas } from '../../../../assets/service_types/cinemas.js'
import { embankments } from '../../../../assets/service_types/embankments.js'
import { green_zones } from '../../../../assets/service_types/green_zones.js'
import { industrial_enterprises_1 } from '../../../../assets/service_types/industrial_enterprises_1.js'
import { industrial_enterprises_2 } from '../../../../assets/service_types/industrial_enterprises_2.js'
import { industrial_enterprises_3 } from '../../../../assets/service_types/industrial_enterprises_3.js'
import { industrial_enterprises_4 } from '../../../../assets/service_types/industrial_enterprises_4.js'
import { landfills } from '../../../../assets/service_types/landfills.js'
import { monuments } from '../../../../assets/service_types/monuments.js'
import { museums } from '../../../../assets/service_types/museums.js'
import { playgrounds } from '../../../../assets/service_types/playgrounds.js'
import { religious_buildings } from '../../../../assets/service_types/religious_buildings.js'
import { restaurants } from '../../../../assets/service_types/restaurants.js'
import { shop_souvenirs } from '../../../../assets/service_types/shop_souvenirs.js'
import { shopping_centers } from '../../../../assets/service_types/shopping_centers.js'
import { sports_centers } from '../../../../assets/service_types/sports_centers.js'
import { sports_grounds } from '../../../../assets/service_types/sports_grounds.js'
import { stadiums } from '../../../../assets/service_types/stadiums.js'
import { sushi_shawarma_fast_foods } from '../../../../assets/service_types/sushi_shawarma_fast_foods.js'
import { swimming_pools } from '../../../../assets/service_types/swimming_pools.js'
import { theaters_concert_halls } from '../../../../assets/service_types/theaters_concert_halls.js'
import { viewpoints } from '../../../../assets/service_types/viewpoints.js'
import { zoos } from '../../../../assets/service_types/zoos.js'

import DropDownMenu_ from "./dropDown/DropDownMenu2";
import { Button, message, Select } from "antd";
import { API_BASE_URL } from "../../../../config";
import { COLORS } from "../../../../utils/constants";

const TOKEN = `${process.env.REACT_APP_MAP_KEY}`;


const ControlPanel = (props) => {
    // const [visibility, setVisibility] = useState({})
    const [data, setData] = useState([])
    // const [route, setRoute] = useState(null)
    const [forR, setForRouteTopic] = useState(null)
    const [Activitity, setActivitity] = useState(null)
    const [Sq, setSq] = useState([])
    const [min, setMinutes] = useState(null)
    const [countRoute, setCountRoute] = useState(null)
    const [serviceTypes, setServiceTypes] = useState([])
    const [selectedServiceTypes, setSelectedServiceTypes] = useState([])
    // const [heritage, setHeritage] = useState(null) //Достопримечательности
    const [servicesToShow, setServicesToShow] = useState([])

    // let selectedServiceTypesTemp = []

    const onVisibilityChangeDropDown = async (name) => {
        async function getServicesTypes() {
            let activity = name === 'Прогулка на свежем воздухе' ? 'open_air_walk' : name === 'Посещение популярных' +
            ' мест' ? 'visiting_popular_palaces' : name === 'Прогулка с детьми дошкольного возраста' ? 'walking_with_preschool_children' : name === 'Прогулка с детьми младшего школьного возраста' ? 'walking_with_younger_schoolchildren' : name === 'Прогулка с детьми среднего школьного возраста' ? 'walking_with_middle_schoolchildren' : name === 'Прогулка с детьми старшего школьного возраста' ? 'walking_with_high_schoolchildren' : name === 'Туристическая прогулка' ? 'tourist_walk' : ''

            const url = `${API_BASE_URL}leisure_activities/${activity}/service_types/`
            const response = await fetch(url)
            return response.json()
        }

        const serviceType = await getServicesTypes()
        setServiceTypes(serviceType)

        setActivitity(name) //added english language thematic
        message.info(`Выбранный вид досуга: ${name}`);
        setForRouteTopic(name)
        // setVisibility({[name]: true});
    };

    const DropDownMenu_ = (props) => {
        const onChangeServiceType = (sTypes) => {
            setServicesToShow(sTypes)
            setSelectedServiceTypes(sTypes)
        }

        const removeValues = (value) => {
            const filteredServiceTypes = selectedServiceTypes.filter(e => e !== value)
            const serviceIndex = selectedServiceTypes.findIndex(e => e === value)

            const SqCopy = [...Sq]
            SqCopy.splice(serviceIndex, 1)
            setSq([...SqCopy])

            setSelectedServiceTypes(filteredServiceTypes)

            const filteredData = data.filter(e => Object.keys(e)[0] !== value)
            setData(filteredData)
        }

        // const onChangeCountRoute = (Count) => {
        //     setCountRoute(Count)
        // }

        const {Option} = Select;

        // const arrMinuts = Array.apply(0, new Array(120)).map(function (_, i) {
        //     return i + 1
        // });

        // const arrCountRoutes = Array.apply(0, new Array(10)).map(function (_, i) {
        //     return i + 1
        // });

        const clearFormData = () => {
            // console.log('clearFormData')
            // setRoute(null)
            setData([]) //ENTITIES for Icon Foto Camera Clear
            setForRouteTopic(null) //clear Legend Data
            setActivitity(null)
            setServiceTypes([])
            setSq([])
            setMinutes(null)
            setCountRoute(null)
            setSelectedServiceTypes([])
            setServicesToShow([])
        }

        const fetchData = () => {
            const SqResult = fetchServiceTypes(Activitity)

            if (SqResult.length) {
                setSq([...SqResult])

                const geoResult = []
                SqResult.forEach((geo, index) => {
                    const geoKey = selectedServiceTypes[index]
                    geoResult.push({
                        [geoKey]: []
                    })

                    for (const feature of geo['features']) {
                        if (feature.geometry.type === 'Point') {
                            const newFeature = {
                                feature_type: 'Point',
                                longitude: feature.geometry.coordinates[0],
                                latitude: feature.geometry.coordinates[1],
                                name: feature.properties.name,
                                contact_vk: feature.properties.contact_vk,
                                contact_instagram: feature.properties.contact_instagram,
                                contact_phone: feature.properties.contact_phone,
                                website: feature.properties.website ?? feature.properties.contact_website,
                                address: feature.properties.address,
                                city_function: feature.properties.city_function,
                                type: feature.properties.type,
                                district: feature.properties.district
                            }
                            geoResult[index][geoKey].push(newFeature)
                        } else if (feature.geometry.type === 'MultiPolygon') {
                            const newFeature = {
                                feature_type: 'MultiPolygon',
                                longitude: feature.geometry.coordinates[0][0][0][0],
                                latitude: feature.geometry.coordinates[0][0][0][1],
                                name: feature.properties.name,
                                contact_vk: feature.properties.contact_vk,
                                contact_instagram: feature.properties.contact_instagram,
                                contact_phone: feature.properties.contact_phone,
                                website: feature.properties.website ?? feature.properties.contact_website,
                                address: feature.properties.address,
                                city_function: feature.properties.city_function,
                                type: feature.properties.type,
                                district: feature.properties.district
                            }
                            geoResult[index][geoKey].push(newFeature)
                        }
                    }
                })

                setData([...geoResult])
            }
        }

        return (
            <div>
                <Select
                    showSearch
                    style={{width: 250}}
                    placeholder={"Вид досуга"}
                    value={Activitity}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={onVisibilityChangeDropDown}
                >
                    {props.items.map(name => (
                        <Option key={name} value={name}>{name}</Option>
                    ))}
                </Select>
                <Select
                    style={{width: 260}}
                    placeholder={"Типы сервисов"}
                    mode="multiple"
                    tokenSeparators={[" ", ","]}
                    onChange={onChangeServiceType}
                    onDeselect={removeValues}
                    value={servicesToShow}
                >
                    {serviceTypes.map(({value, label}) => (
                        <Option key={value} value={value}>{label}</Option>
                    ))}
                </Select>

                <Button
                    type="danger"
                    onClick={fetchData}
                >
                    Посчитать
                </Button>

                <Button
                    type="secondary"
                    onClick={clearFormData}
                >
                    Очистить фильтр
                </Button>
            </div>
        )
    }

    // const fetchRoute = (forR) => {
    //     fetch(`${process.env.REACT_APP_URL_API}theme=${forR}&travel_time=60&number_of_routes=10`)
    //         .then((response) => response.json())
    //         .then((json) => setRoute(json))
    //         .catch((error) => {
    //             console.log('fetch data failed', error);
    //         });
    // };
    //
    // const fetchRouteAndMinute = (min) => {
    //     fetch(`${process.env.REACT_APP_URL_API}theme=${forR}&travel_time=${min}&number_of_routes=${countRoute}`)
    //         .then((response) => response.json())
    //         .then((json) => setRoute(json))
    //         .catch((error) => {
    //             console.log('fetch data failed', error);
    //         });
    // }
    // const fetchRouteAndCountRoute = (forR, min, countRoute) => {
    //     forR && min && countRoute && fetch(`${process.env.REACT_APP_URL_API}theme=${forR}&travel_time=${min}&number_of_routes=${countRoute}`)
    //         .then((response) => response.json())
    //         .then((json) => setRoute(json))
    //         .catch((error) => {
    //             console.log('fetch data failed', error);
    //         });
    // }

    const fetchServiceTypes = () => {
        const result = []

        for (const selectedServiceType of selectedServiceTypes) {
            if (selectedServiceType === 'amusements_parks') {
                result.push(amusements_parks)
            }

            if (selectedServiceType === 'aqua_parks') {
                result.push(aqua_parks)
            }

            if (selectedServiceType === 'architectural_structures') {
                result.push(architectural_structures)
            }

            if (selectedServiceType === 'art_spaces') {
                result.push(art_spaces)
            }

            if (selectedServiceType === 'bakeries') {
                result.push(bakeries)
            }

            if (selectedServiceType === 'bars_pubs') {
                result.push(bars_pubs)
            }

            if (selectedServiceType === 'beaches') {
                result.push(beaches)
            }

            if (selectedServiceType === 'bowling_billiards') {
                result.push(bowling_billiards)
            }

            if (selectedServiceType === 'bridges') {
                result.push(bridges)
            }

            if (selectedServiceType === 'cafes') {
                result.push(cafes)
            }

            if (selectedServiceType === 'cafes_canteens') {
                result.push(cafes_canteens)
            }

            if (selectedServiceType === 'cinemas') {
                result.push(cinemas)
            }

            if (selectedServiceType === 'embankments') {
                result.push(embankments)
            }

            if (selectedServiceType === 'green_zones') {
                result.push(green_zones)
            }

            if (selectedServiceType === 'industrial_enterprises_1') {
                result.push(industrial_enterprises_1)
            }

            if (selectedServiceType === 'industrial_enterprises_2') {
                result.push(industrial_enterprises_2)
            }

            if (selectedServiceType === 'industrial_enterprises_3') {
                result.push(industrial_enterprises_3)
            }

            if (selectedServiceType === 'industrial_enterprises_4') {
                result.push(industrial_enterprises_4)
            }

            if (selectedServiceType === 'landfills') {
                result.push(landfills)
            }

            if (selectedServiceType === 'monuments') {
                result.push(monuments)
            }

            if (selectedServiceType === 'museums') {
                result.push(museums)
            }

            if (selectedServiceType === 'playgrounds') {
                result.push(playgrounds)
            }

            if (selectedServiceType === 'religious_buildings') {
                result.push(religious_buildings)
            }

            if (selectedServiceType === 'restaurants') {
                result.push(restaurants)
            }

            if (selectedServiceType === 'shop_souvenirs') {
                result.push(shop_souvenirs)
            }

            if (selectedServiceType === 'shopping_centers') {
                result.push(shopping_centers)
            }

            if (selectedServiceType === 'sports_centers') {
                result.push(sports_centers)
            }

            if (selectedServiceType === 'sports_grounds') {
                result.push(sports_grounds)
            }

            if (selectedServiceType === 'stadiums') {
                result.push(stadiums)
            }

            if (selectedServiceType === 'sushi_shawarma_fast_foods') {
                result.push(sushi_shawarma_fast_foods)
            }

            if (selectedServiceType === 'swimming_pools') {
                result.push(swimming_pools)
            }

            if (selectedServiceType === 'theaters_concert_halls') {
                result.push(theaters_concert_halls)
            }

            if (selectedServiceType === 'viewpoints') {
                result.push(viewpoints)
            }

            if (selectedServiceType === 'zoos') {
                result.push(zoos)
            }
        }

        return result
    }

    //coordinate route all ok
    // const jsonRoute = useMemo(() => {
    //     // console.log('jsonRoute["routes"] = ', route['routes'])
    //     return route;
    // }, [route]);

    // const heritage = useMemo(() => {
    //     return route;
    // }, [route]);

    // useEffect(() => {
    //     PURE_TOPIC.map(item => setVisibility(prevState => ({...prevState, [item]: false})))
    // }, [])

    // useEffect(() => {
    //     console.log('useEffect Sq = ', Sq)
    // }, [Sq]);
    //

    const [popupInfo, setPopupInfo] = useState(null);

    // useEffect(() => {
    //     console.log('useEffect Sq = ', Sq)
    // }, [Sq])
    //
    // useEffect(() => {
    //     console.log('useEffect data = ', data)
    // }, [data]);
    //
    // useEffect(() => {
    //     console.log('useEffect servicesToShow = ', servicesToShow)
    // }, [servicesToShow]);
    //
    //
    // useEffect(() => {
    //     console.log('useEffect selectedServiceTypes = ', selectedServiceTypes)
    // }, [selectedServiceTypes]);

    return (
        <>
            {Sq.length && Sq.map((geo, index) => (
                <Source key={index} id={`${index}-source`} type="geojson" data={geo}>
                    <Layer
                        id={`${index}-layer`}
                        type="fill"
                        source={`${index}-source`}
                        onClick={setPopupInfo}
                        paint={{
                            "fill-color": COLORS[selectedServiceTypes[index]] || COLORS['other']
                        }}
                    />

                    <div>
                        {popupInfo && (
                            <Popup
                                tipSize={5}
                                anchor="top"
                                longitude={popupInfo.longitude}
                                latitude={popupInfo.latitude}
                                closeOnClick={false}
                                onClose={setPopupInfo}
                            >
                                <CityInfo info={popupInfo} data={data} />
                            </Popup>
                        )}
                    </div>

                    {/*<div>*/}
                    {/*    {data.length && geo.features[0].geometry.type === 'MultiPolygon' && popupInfo (*/}
                    {/*        <Popup*/}
                    {/*            tipSize={5}*/}
                    {/*            anchor="top"*/}
                    {/*            longitude={popupInfo.longitude}*/}
                    {/*            latitude={popupInfo.latitude}*/}
                    {/*            closeOnClick={false}*/}
                    {/*            onClose={setPopupInfo}*/}
                    {/*        >*/}
                    {/*            <CityInfo info={popupInfo} data={data} />*/}
                    {/*        </Popup>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </Source>
            ))}

            {data.length && data.map((geo, index) => (
                geo[selectedServiceTypes[index]] &&
                    <div key={index}>
                        <Pins
                            data={geo[selectedServiceTypes[index]]}
                            onClick={setPopupInfo}
                            serviceType={selectedServiceTypes[index]}
                        />

                        <div>
                            {popupInfo && (
                                <Popup
                                    tipSize={5}
                                    anchor="top"
                                    longitude={popupInfo.longitude}
                                    latitude={popupInfo.latitude}
                                    closeOnClick={false}
                                    onClose={setPopupInfo}
                                >
                                    <CityInfo info={popupInfo}/>
                                </Popup>
                            )}
                        </div>
                    </div>
            ))}
            <DropDownMenu_ items={PURE_TOPIC}/>

            {Activitity && Sq.length && selectedServiceTypes.length && (
                <LegendaPanel
                    name={forR}
                    m={min}
                    r={countRoute}
                    colorText={props.colorText}
                    selectedServices={selectedServiceTypes}
                    services={serviceTypes}
                    data={Sq}
                />
            )}
        </>
    );
};
export default function Map_() {
    const mapStyle = {
        satelliteV9: 'mapbox://styles/mapbox/satellite-v9',
        darkV9: 'mapbox://styles/mapbox/dark-v9',
        lightV9: 'mapbox://styles/mapbox/light-v10'
    }
    const colorTextRadioValue = {
        darkV9: 'white',
        satelliteV9: 'white',
        lightV9: 'black',
    }

    const [viewport, setViewport] = useState({
        latitude: 59.944955,
        longitude: 30.335427,
        zoom: 11.5,
        bearing: 0,
        pitch: 0,
    });

    // const [radioValue] = useState('lightV9');
    const [radioValue, setRadioValue] = useState('lightV9');

    const onChangeRadioValue = (e) => {
        setRadioValue(e.target.value);
    }

    // const onClick = useCallback(event => {
    //     console.log(event)
    //     const feature = event.features && event.features[0];
    //     console.log(feature)
    //     if (feature) {
    //         console.log(`Clicked layer ${feature.layer.id}`); // eslint-disable-line no-alert
    //     }
    // }, []);

    const mapRef = useRef()
    // const [dataView, setDataView] = useState([])
    return (
        <div id='_map' className='entities'>
            <MapGL
                ref={mapRef}
                {...viewport}
                mapStyle={mapStyle[`${radioValue}`]}
                width="100%"
                height="100%"
                onViewportChange={setViewport}
                mapboxApiAccessToken={TOKEN}
                transitionDuration={500}
                transitionInterpolator={new FlyToInterpolator()}
            >
                <div style={{position: 'absolute', left: 10, bottom: 30}}>
                    <ScaleControl container={document.getElementById('_map')}/>
                </div>
                {/*<Radio.Group onChange={onChangeRadioValue} value={radioValue}>*/}
                {/*    <Radio style={{color: colorTextRadioValue[`${radioValue}`]}} value={'lightV9'}>Светлая тема</Radio>*/}
                {/*    <Radio style={{color: colorTextRadioValue[`${radioValue}`]}} value={'darkV9'}>Темная тема</Radio>*/}
                {/*    <Radio style={{color: colorTextRadioValue[`${radioValue}`]}} value={'satelliteV9'}>Спутниковая тема</Radio>*/}
                {/*</Radio.Group>*/}
                <ControlPanel colorText={colorTextRadioValue[`${radioValue}`]}/>
            </MapGL>
        </div>
    );
}

