//https://t.me/elemnt3 Y:2021
import * as React from 'react';
import {Marker} from 'react-map-gl';
import {MAP_ICON_SIZE, LEGEND_ICON_SIZE} from "../../../../utils/constants";

const ICONA = `m448.387 116.234h-70.857c-3.054 0-5.893-1.647-7.408-4.299l-13.601-23.801c-9.874-17.279-28.37-28.013-48.271-28.013h-104.5c-19.901 0-38.398 10.734-48.271 28.013l-13.601 23.801c-1.516 2.652-4.354 4.299-7.408 4.299h-70.857c-35.076 0-63.613 28.537-63.613 63.613v208.419c0 35.076 28.537 63.613 63.613 63.613h384.774c35.076 0 63.613-28.537 63.613-63.613v-208.419c0-35.076-28.537-63.613-63.613-63.613zm48.613 272.032c0 26.805-21.808 48.613-48.613 48.613h-384.774c-26.805 0-48.613-21.808-48.613-48.613v-208.419c0-26.805 21.808-48.613 48.613-48.613h70.857c8.423 0 16.252-4.543 20.432-11.857l13.601-23.801c7.21-12.617 20.716-20.455 35.248-20.455h104.499c14.532 0 28.038 7.838 35.248 20.455l13.601 23.801c4.179 7.313 12.008 11.857 20.432 11.857h70.857c26.805 0 48.613 21.808 48.613 48.613v208.419z`;
const ICONE = `m256 388.782c-24.627 0-48.021-7.795-67.651-22.544-3.311-2.488-8.013-1.82-10.501 1.491s-1.82 8.013 1.491 10.501c22.249 16.716 48.758 25.551 76.661 25.551 4.142 0 7.5-3.358 7.5-7.5s-3.358-7.499-7.5-7.499z`;
const ICONG = `m256 371.718c52.757 0 95.677-42.921 95.677-95.677 0-19.448-5.834-38.195-16.874-54.212-2.35-3.41-7.02-4.27-10.431-1.919s-4.27 7.021-1.919 10.432c9.306 13.502 14.224 29.305 14.224 45.699 0 44.486-36.192 80.677-80.677 80.677s-80.677-36.192-80.677-80.677 36.192-80.677 80.677-80.677c16.394 0 32.197 4.919 45.7 14.225 3.41 2.351 8.081 1.491 10.431-1.92 2.351-3.411 1.491-8.081-1.919-10.431-16.018-11.039-34.764-16.874-54.211-16.874-52.757 0-95.677 42.921-95.677 95.677s42.919 95.677 95.676 95.677z`;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Pins(props) {
    const {data, onClick, serviceType} = props;

    return data.map((city, index) => (
        <Marker key={`marker-${index}`}
                longitude={city.longitude}
                latitude={city.latitude}
        >
            {/* Рекреационные зоны */}
            {serviceType === 'green_zones' &&
                <svg version="1.1" viewBox="0 0 30 30"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 1">
                <path opacity="1" stroke="#6CBB3C" fill="none" strokeWidth="1" strokeLinecap="round" d="M0.975861+15C0.975861+7.25468+7.25468+0.975861+15+0.975861C22.7453+0.975861+29.0241+7.25468+29.0241+15C29.0241+22.7453+22.7453+29.0241+15+29.0241C7.25468+29.0241+0.975861+22.7453+0.975861+15Z" strokeLinejoin="round"/>
                </g>
                <g id="Слой 2">
                <g opacity="1">
                <g opacity="1">
                <path fill="none" strokeLinecap="round" opacity="1" strokeLinejoin="round" d="M19.2975+24.8708L19.2975+21.4375" strokeWidth="1.5" stroke="#6CBB3C"/>
                <path opacity="1" strokeLinecap="round" strokeWidth="1.5" fill="none" stroke="#6CBB3C" strokeLinejoin="round" d="M10.1703+24.8708L10.1703+19.5546"/>
                <path fill="none" stroke="#6CBB3C" strokeWidth="1.5" d="M22.3295+15.3452C22.8652+15.1069+23.3855+14.827+23.8611+14.4565L20.799+10.4713C21.4242+10.2949+22.0266+10.0211+22.5686+9.62597L19.2975+5.11969L16.0255+9.61363C16.5703+10.0105+17.1755+10.2852+17.8034+10.4615L14.7339+14.4565C15.2095+14.827+15.7297+15.1069+16.2655+15.3452C14.8708+17.4841+13.3274+19.3704+13.3274+19.3704C15.0533+20.5523+16.7876+21.3267+19.2975+21.3267C21.6934+21.3267+23.5799+20.5523+25.3059+19.3704L22.3295+15.3452Z" opacity="1" strokeLinecap="round" strokeLinejoin="round"/>
                <path strokeLinecap="round" fill="none" strokeWidth="1.5" opacity="1" d="M15.8886+9.06188C15.3866+8.5178+14.6316+8.03641+13.8212+8.03641C13.8212+6.56834+12.5954+5.37842+11.0831+5.37842C9.88649+5.37842+8.87977+6.12798+8.50738+7.16639L8.50738+7.16639C8.45261+7.16286+8.40059+7.15044+8.34492+7.15044C6.83255+7.15044+5.60677+8.34036+5.60677+9.80851C5.60677+10.2276+5.71538+10.6183+5.89337+10.971C5.18236+11.3457+4.69406+12.0687+4.69406+12.9096C4.69406+13.8637+5.31835+14.6692+6.18999+14.9811L5.82673+15.8538C5.824+15.8599+5.82126+15.867+5.81852+15.8733L5.81852+15.8733C5.8176+15.876+5.81669+15.8795+5.81578+15.8831C5.75007+16.0372+5.69712+16.1994+5.66244+16.3669C5.62594+16.5378+5.60677+16.7151+5.60677+16.8966C5.60677+18.3648+6.83255+19.5546+8.34492+19.5546C8.52563+19.5546+8.70087+19.5361+8.87063+19.5033C8.99294+19.4803+9.10794+19.4405+9.22386+19.4023C9.26492+19.3881+9.30874+19.3802+9.3489+19.3641C9.49858+19.3066+9.64005+19.2348+9.77514+19.1534C9.77697+19.1524+9.77878+19.1515+9.78061+19.1507C9.92026+19.0664+10.049+18.9708+10.1703+18.8645C10.6559+19.2888+11.2912+19.5546+11.9958+19.5546C12.8346+19.5546+13.616+18.9367+14.1181+18.3607" stroke="#6CBB3C" strokeLinejoin="round"/>
                </g>
                <path strokeLinecap="round" stroke="#6CBB3C" d="M10.421+24.8803L21.8922+24.8799" opacity="1" strokeLinejoin="round" strokeWidth="1.5" fill="none"/>
                </g>
                </g>
                </svg>
            }

            {/* Набережные */}
            {serviceType === 'embankments' &&
                <svg version="1.1" viewBox="0 0 30 30"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                    onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 2">
                <path stroke="#A23BEC" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="1" d="M0.975861+15C0.975861+7.25468+7.25468+0.975861+15+0.975861C22.7453+0.975861+29.0241+7.25468+29.0241+15C29.0241+22.7453+22.7453+29.0241+15+29.0241C7.25468+29.0241+0.975861+22.7453+0.975861+15Z" opacity="1"/>
                </g>
                <g id="Слой 3">
                <path strokeWidth="1.5" strokeLinecap="round" d="M23.9425+16.1119L23.9425+12.1119" opacity="1" fill="none" stroke="#A23BEC" strokeLinejoin="round"/>
                <path stroke="#A23BEC" strokeLinecap="round" fill="#A23BEC" d="M10.0356+16.1119L23.9425+16.1119" strokeLinejoin="round" strokeWidth="1.5" opacity="1"/>
                <g opacity="1">
                <path d="M11.5227+11.0335L10.9734+13.4292L9.42381+15.6344L9.42471+15.635C9.34805+15.7439+9.30202+15.8762+9.30202+16.0196C9.30202+16.3899+9.60214+16.6901+9.97243+16.6901C10.1993+16.6901+10.3988+16.5768+10.5202+16.4045L10.5211+16.4051L12.1861+14.0354L12.5841+12.8633" opacity="1" fill="#A23BEC"/>
                <path opacity="1" d="M16.3161+15.6839L16.3174+15.683L13.5736+11.3575L14.386+7.75512C14.5131+7.19197+14.0847+6.65631+13.5073+6.65631L12.4029+6.65631C11.9682+6.65631+11.5485+6.81453+11.2223+7.10146L10.2444+7.96138C10.094+8.09368+9.99252+8.27156+9.95587+8.46844C9.82514+9.1726+9.51138+10.8625+9.50513+10.9014C9.49708+10.9505+9.49485+11.0243+9.49485+11.0243C9.50066+11.328+9.74759+11.5727+10.0526+11.5727C10.2871+11.5727+10.4868+11.4281+10.57+11.2234C10.57+11.2234+10.5914+11.1751+10.6001+11.1266C10.6089+11.0781+10.9997+8.97102+10.9997+8.97102L12.0949+8.05725L11.5427+10.616C11.4451+11.0685+11.5483+11.5412+11.8257+11.9117L15.1855+16.4012L15.1869+16.4006C15.3261+16.6198+15.5873+16.7536+15.8765+16.7013C16.14+16.6537+16.3554+16.4399+16.4086+16.1775C16.445+15.9938+16.4041+15.8222+16.3161+15.6839Z" fill="#A23BEC"/>
                <path d="M11.9874+4.94251C11.9874+5.68287+12.5875+6.28356+13.3276+6.28356C14.0693+6.28356+14.6691+5.68287+14.6691+4.94251C14.6691+4.20259+14.0693+3.6019+13.3276+3.6019C12.5875+3.6019+11.9874+4.20259+11.9874+4.94251Z" opacity="1" fill="#A23BEC"/>
                <path d="M14.0336+9.22764L14.4045+9.75727C14.4566+9.83168+14.5303+9.88867+14.6157+9.92018L16.8931+10.7622C16.9217+10.7667+16.9503+10.7709+16.9802+10.7709C17.2889+10.7709+17.5389+10.5209+17.5389+10.2123C17.5389+10.0129+17.4339+9.83928+17.2768+9.74051L15.2492+8.96551L14.4023+7.59943" opacity="1" fill="#A23BEC"/>
                </g>
                <g opacity="1">
                <path stroke="#A23BEC" strokeLinecap="round" strokeLinejoin="round" d="M8.03087+18.9682C8.03087+19.8246+7.24794+20.5196+6.28325+20.5196C5.31856+20.5196+4.53562+19.8246+4.53562+18.9682" opacity="1" strokeWidth="1.5" fill="none"/>
                <path opacity="1" strokeWidth="1.5" strokeLinecap="round" fill="none" strokeLinejoin="round" d="M11.5261+18.9682C11.5261+19.8246+10.7432+20.5196+9.7785+20.5196C8.81381+20.5196+8.03087+19.8246+8.03087+18.9682" stroke="#A23BEC"/>
                <path opacity="1" strokeLinejoin="round" d="M15.0214+18.9682C15.0214+19.8246+14.2384+20.5196+13.2737+20.5196C12.3091+20.5196+11.5261+19.8246+11.5261+18.9682" strokeWidth="1.5" stroke="#A23BEC" strokeLinecap="round" fill="none"/>
                <path strokeLinecap="round" fill="none" strokeWidth="1.5" strokeLinejoin="round" d="M18.5166+18.9682C18.5166+19.8246+17.7337+20.5196+16.769+20.5196C15.8043+20.5196+15.0214+19.8246+15.0214+18.9682" opacity="1" stroke="#A23BEC"/>
                </g>
                <g opacity="1">
                <path strokeLinejoin="round" stroke="#A23BEC" d="M12.9786+22.8467C12.9786+23.7031+12.1957+24.3981+11.231+24.3981C10.2663+24.3981+9.48338+23.7031+9.48338+22.8467" strokeLinecap="round" fill="none" strokeWidth="1.5" opacity="1"/>
                <path strokeLinejoin="round" stroke="#A23BEC" strokeLinecap="round" strokeWidth="1.5" fill="none" d="M16.4739+22.8467C16.4739+23.7031+15.6909+24.3981+14.7263+24.3981C13.7616+24.3981+12.9786+23.7031+12.9786+22.8467" opacity="1"/>
                <path strokeLinejoin="round" fill="none" stroke="#A23BEC" strokeWidth="1.5" strokeLinecap="round" d="M19.9691+22.8467C19.9691+23.7031+19.1862+24.3981+18.2215+24.3981C17.2568+24.3981+16.4739+23.7031+16.4739+22.8467" opacity="1"/>
                <path stroke="#A23BEC" strokeWidth="1.5" opacity="1" strokeLinecap="round" d="M23.4644+22.8467C23.4644+23.7031+22.6814+24.3981+21.7168+24.3981C20.7521+24.3981+19.9691+23.7031+19.9691+22.8467" fill="none" strokeLinejoin="round"/>
                </g>
                </g>
                </svg>
            }

            {/* Пляжи */}
            {serviceType === 'beaches' &&
                <svg viewBox="0 0 30 30" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 1">
                <path strokeWidth="1" strokeLinecap="round" stroke="#FDD017" opacity="1" fill="none" strokeLinejoin="round" d="M0.975861+15C0.975861+7.25468+7.25468+0.975861+15+0.975861C22.7453+0.975861+29.0241+7.25468+29.0241+15C29.0241+22.7453+22.7453+29.0241+15+29.0241C7.25468+29.0241+0.975861+22.7453+0.975861+15Z"/>
                </g>
                <g id="Слой 2">
                <g opacity="1">
                <path strokeLinejoin="round" d="M8.18464+23.5148L23.8941+23.5148" stroke="#FDD017" strokeLinecap="round" strokeWidth="1.5" opacity="1" fill="none"/>
                <path opacity="1" fill="none" d="M15.5022+23.0705L16.9995+19.3272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#FDD017"/>
                <path strokeLinejoin="round" d="M22.9888+23.0705L21.4915+19.3272" strokeWidth="1.5" fill="none" stroke="#FDD017" opacity="1" strokeLinecap="round"/>
                <path fill="none" strokeLinecap="round" d="M21.4775+19.3281L16.9995+19.3272" opacity="1" strokeLinejoin="round" stroke="#FDD017" strokeWidth="1.5"/>
                <path strokeLinejoin="round" strokeWidth="1.5" fill="none" opacity="1" d="M23.8552+21.4906L19.2142+21.4906L13.873+21.4906" strokeLinecap="round" stroke="#FDD017"/>
                <path stroke="#FDD017" d="M12.6252+18.9951L13.873+21.4906" fill="none" strokeLinecap="round" strokeWidth="1.5" opacity="1" strokeLinejoin="round"/>
                <g opacity="1">
                <path d="M11.7221+9.84512L8.09862+23.3681" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#FDD017" opacity="1"/>
                <path fill="none" opacity="1" strokeLinejoin="round" stroke="#FDD017" d="M12.8267+6L17+13.3299L5.54759+10.2612L12.8267+6Z" strokeLinecap="round" strokeWidth="1.5"/>
                </g>
                </g>
                </g>
                </svg>
            }

            {/* Памятники */}
            {serviceType === 'monuments' &&
                <svg viewBox="0 0 30 30" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 1">
                        <path
                            d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                            opacity="1" strokeLinecap="round" fill="none" strokeWidth="1" strokeLinejoin="round"
                            stroke="#4863A0"/>
                    </g>
                    <g id="Слой 2">
                        <g opacity="1">
                            <path strokeLinejoin="round" stroke="#4863A0" d="M11+27L11+21" fill="none"
                                  strokeLinecap="round" strokeWidth="1.5" opacity="1"/>
                            <path strokeWidth="1.5" d="M21+27L21+21" opacity="1" strokeLinecap="round"
                                  strokeLinejoin="round" fill="none" stroke="#4863A0"/>
                            <path strokeLinecap="round" fill="none" opacity="1" strokeLinejoin="round"
                                  stroke="#4863A0" d="M11+21L21+21" strokeWidth="1.5"/>
                            <path strokeWidth="1.5" strokeLinecap="round" d="M14+24L18+24" fill="none"
                                  stroke="#4863A0" strokeLinejoin="round" opacity="1"/>
                            <path opacity="1" strokeLinejoin="round" strokeWidth="1.5" fill="none" stroke="#4863A0"
                                  d="M13.0942+7.93802L18.4406+7.93802L18.4406+12.1715L18.4406+15.0773L13.0942+15.0773L13.0942+7.93802Z"
                                  strokeLinecap="round"/>
                            <path stroke="#4863A0" strokeLinejoin="round" fill="none"
                                  d="M14.697+20.7481L13.0942+15.0773" opacity="1" strokeLinecap="round"
                                  strokeWidth="1.5"/>
                            <path strokeLinecap="round" stroke="#4863A0" d="M16.8356+20.7481L18.4406+15.0773"
                                  opacity="1" fill="none" strokeWidth="1.5" strokeLinejoin="round"/>
                            <path fill="none" strokeWidth="1.5" stroke="#4863A0" strokeLinecap="round" opacity="1"
                                  d="M18.4406+10.9792L23.0764+10.9977C23.0764+10.9977+24.6639+10.9977+24.6493+9.46787C24.6347+7.93802+23.0764+7.93802+23.0764+7.93802L18.4406+7.93802"
                                  strokeLinejoin="round"/>
                            <path strokeLinecap="round" strokeWidth="1.5" fill="none"
                                  d="M13.0942+7.93802C13.0942+7.93802+8.6195+12.0735+10.1111+13.5754C11.6026+15.0773+13.0942+13.251+13.0942+13.251"
                                  opacity="1" strokeLinejoin="round" stroke="#4863A0"/>
                            <path opacity="1" strokeLinejoin="round" d="M10+27L22+27" strokeLinecap="round"
                                  fill="none" strokeWidth="1.5" stroke="#4863A0"/>
                            <path strokeWidth="1.5" strokeLinejoin="round" opacity="1" strokeLinecap="round"
                                  d="M13.7051+5.25483C13.7051+4.0382+14.7391+3.05193+16.0147+3.05193C17.2902+3.05193+18.3242+4.0382+18.3242+5.25483C18.3242+5.72042+18.1728+6.15227+17.9143+6.50803C17.4974+7.08188+16.8021+7.45772+16.0147+7.45772C14.7391+7.45772+13.7051+6.47145+13.7051+5.25483Z"
                                  fill="none" stroke="#4863A0"/>
                        </g>
                    </g>
                </svg>
            }

            {/* Архитектурные сооружения */}
            {serviceType === 'architectural_structures' &&
                <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
                       style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                    >
                <defs/>
                <g id="Слой 1">
                <path stroke="#1569C7" d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" strokeLinejoin="round" strokeWidth="1" strokeLinecap="round" fill="none" opacity="1"/>
                </g>
                <g id="Слой 3">
                <g opacity="1">
                <path strokeLinejoin="round" d="M7+26L25+26" opacity="1" strokeWidth="1.5" fill="none" strokeLinecap="round" stroke="#1569C7"/>
                <path strokeLinecap="round" d="M24+26L24+14" strokeLinejoin="round" opacity="1" stroke="#1569C7" strokeWidth="1.5" fill="none"/>
                <path strokeLinecap="round" d="M8+26L8+14" opacity="1" fill="none" strokeWidth="1.5" stroke="#1569C7" strokeLinejoin="round"/>
                <path opacity="1" strokeLinejoin="round" d="M8+14L24+14" fill="none" strokeLinecap="round" strokeWidth="1.5" stroke="#1569C7"/>
                <path stroke="#1569C7" d="M23.8571+14L27+11" strokeLinejoin="round" strokeWidth="1.5" strokeLinecap="round" fill="none" opacity="1"/>
                <path strokeLinecap="round" stroke="#1569C7" opacity="1" strokeLinejoin="round" fill="none" strokeWidth="1.5" d="M8.14286+14L5+11"/>
                <path d="M5+11C10.2526+11+27+11+27+11" opacity="1" fill="none" strokeLinejoin="round" strokeWidth="1.5" strokeLinecap="round" stroke="#1569C7"/>
                <path strokeLinejoin="round" d="M24.9048+6.95532L7.21164+6.95532" strokeLinecap="round" opacity="1" stroke="#1569C7" strokeWidth="1.5" fill="none"/>
                <path opacity="1" stroke="#1569C7" strokeWidth="1.5" strokeLinecap="round" fill="none" d="M20.4815+6.95532L20.4815+9.06643" strokeLinejoin="round"/>
                <path stroke="#1569C7" fill="none" d="M16.0582+6.95532L16.0582+9.06643" opacity="1" strokeWidth="1.5" strokeLinejoin="round" strokeLinecap="round"/>
                <path strokeLinecap="round" d="M11.6349+6.95532C11.6349+6.95532+11.6349+8.01088+11.6349+9.06643" opacity="1" strokeWidth="1.5" fill="none" stroke="#1569C7" strokeLinejoin="round"/>
                <path d="M7.09375+6.25C6.67729+6.25+6.34375+6.58354+6.34375+7C6.32922+8.33333+6.32623+9.66666+6.3125+11C6.3125+11.4399+6.65388+11.7812+7.09375+11.7812C7.53362+11.7812+7.90625+11.4399+7.90625+11C7.89252+9.66666+7.85828+8.33333+7.84375+7C7.84375+6.58354+7.5102+6.25+7.09375+6.25Z" opacity="1" fill="#1569C7"/>
                <path strokeWidth="1.5" fill="none" strokeLinecap="round" stroke="#1569C7" opacity="1" strokeLinejoin="round" d="M24.9048+7L24.9048+11"/>
                <path strokeLinecap="round" opacity="1" fill="none" strokeWidth="1.5" strokeLinejoin="round" d="M13+26L13+20" stroke="#1569C7"/>
                <path opacity="1" fill="none" stroke="#1569C7" d="M19+26L19+20" strokeLinejoin="round" strokeWidth="1.5" strokeLinecap="round"/>
                <path opacity="1" stroke="#1569C7" strokeLinecap="round" fill="none" strokeLinejoin="round" d="M13+20C13+20+13+17+16+17C19+17+19+20+19+20" strokeWidth="1.5"/>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8+23L13+23" strokeWidth="1.5" opacity="1" fill="none" stroke="#1569C7"/>
                <path stroke="#1569C7" d="M8+20L13+20" strokeLinecap="round" opacity="1" strokeWidth="1.5" fill="none" strokeLinejoin="round"/>
                <path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" d="M19+20L24+20" opacity="1" stroke="#1569C7"/>
                <path d="M19+23L24+23" strokeLinecap="round" strokeWidth="1.5" fill="none" strokeLinejoin="round" stroke="#1569C7" opacity="1"/>
                </g>
                </g>
            </svg>
            }

            {/* Видовые точки */}
            {serviceType === 'viewpoints' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 1">
                        <path stroke="#E41B17" fill="none" strokeWidth="1" strokeLinecap="round" opacity="1"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinejoin="round"/>
                    </g>
                    <g id="Слой 2">
                        <path fill="none" d="M20+13L21+27L11+27L12+13M12+13L20+13" opacity="1" strokeWidth="1.5"
                              stroke="#E41B17" strokeLinejoin="round" strokeLinecap="round"/>
                        <path stroke="#E41B17" d="M12+23L20+21M11.8462+19.1914L20+17" strokeLinecap="round" opacity="1"
                              strokeLinejoin="round" strokeWidth="1.5" fill="none"/>
                        <path stroke="#E41B17" strokeLinecap="round" strokeLinejoin="round" opacity="1"
                              d="M14+8L14+13L18+13L18+8" fill="none" strokeWidth="1.5"/>
                        <path fill="none" opacity="1" stroke="#E41B17" strokeLinecap="round" strokeLinejoin="round"
                              d="M13+8L15.9977+5L19+8" strokeWidth="1.5"/>
                        <path opacity="1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"
                              stroke="#E41B17" d="M13+8L19+8"/>
                        <path d="M21+27L22+27" strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="#E41B17"
                              strokeWidth="1.5" opacity="1"/>
                        <path fill="none" strokeWidth="1.5" strokeLinecap="round" stroke="#E41B17"
                              strokeLinejoin="round" d="M11+27L10+27" opacity="1"/>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16+5L16+4"
                              stroke="#E41B17" fill="none" opacity="1"/>
                        <path strokeLinejoin="round" fill="none" strokeWidth="1.5" strokeLinecap="round"
                              stroke="#E41B17" opacity="1" d="M16+27L16+25"/>
                        <path strokeWidth="1.5" opacity="1" fill="none" stroke="#E41B17" d="M20+10L25+8"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path opacity="1" d="M20+10L25+12" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round" fill="none" stroke="#E41B17"/>
                        <path strokeWidth="1.5" strokeLinejoin="round" fill="none" stroke="#E41B17" opacity="1"
                              strokeLinecap="round" d="M25+8L25+12"/>
                    </g>
                </svg>
            }

            {serviceType === 'shop_souvenirs' &&
                <svg viewBox="0 0 32 32" version="1.1"
                    style={{
                       cursor: 'pointer',
                       transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                    }}
                    width={MAP_ICON_SIZE}
                    height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                    >
                    <defs/>
                    <g id="Слой 1">
                    <path strokeLinecap="round" d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" strokeLinejoin="round" fill="none" opacity="1" stroke="#8BB381" strokeWidth="1"/>
                    </g>
                    <g id="Слой 2">
                    <g opacity="1">
                    <path fill="none" stroke="#8BB381" strokeWidth="1.5" strokeLinecap="round" opacity="1" d="M26.4547+18.0664L5.54535+18.0664L5.54535+22.8916C5.54535+23.7794+6.26591+24.5+7.15376+24.5L24.8462+24.5C25.7341+24.5+26.4547+23.7794+26.4547+22.8916L26.4547+18.0664ZM14.3916+21.2832L17.6084+21.2832M7.55586+18.0664L13.5874+12.0348L19.6189+18.0664" strokeLinejoin="round"/>
                    <path fill="none" strokeWidth="1.5" strokeLinecap="round" d="M17.5453+15.5L19.3181+13.6432L23.6399+17.9666" stroke="#8BB381" strokeLinejoin="round" opacity="1"/>
                    <path strokeLinejoin="round" stroke="#8BB381" strokeLinecap="round" fill="#8BB381" opacity="1" strokeWidth="1.5" d="M21.6294+10.8285C21.1853+10.8285+20.8252+11.1886+20.8252+11.6327C20.8252+12.0769+21.1853+12.4369+21.6294+12.4369C22.0736+12.4369+22.4336+12.0769+22.4336+11.6327C22.4336+11.1886+22.0736+10.8285+21.6294+10.8285Z"/>
                    <path fill="none" strokeLinejoin="round" strokeWidth="1.5" strokeLinecap="round" opacity="1" stroke="#8BB381" d="M7.54535+17.5L7.54535+8.5L24.5453+8.5L24.5453+17.5M8.54535+5.5L23.5453+5.5"/>
                    <path stroke="#8BB381" strokeWidth="1.5" opacity="1" fill="none" d="M23+6L23+8" strokeLinecap="round" strokeLinejoin="round"/>
                    <path stroke="#8BB381" opacity="1" strokeWidth="1.5" d="M9+8L9+6" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    </g>
                    </svg>
            }

            {serviceType === 'religious_buildings' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 1">
                <path strokeLinecap="round" strokeWidth="1" fill="none" d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" opacity="1" strokeLinejoin="round" stroke="#F87217"/>
                </g>
                <g id="Слой 2">
                <g opacity="1">
                <g opacity="1">
                <path d="M21.8713+11.1051L21.8713+8.21019M20.4035+9.65764L23.3391+9.65764M10.1287+11.5876L10.1287+8.21019M8.66092+9.65764L11.5965+9.65764M16+6.28025L16+3.38534M14.5322+4.8328L17.4678+4.8328M24.8069+17.3822C25.4136+16.8853+25.7854+16.2291+25.7854+15.5102C25.7854+13.1846+22.6492+12.6683+21.8713+11.1389C21.2744+12.292+19.3564+12.8806+18.4464+14.082C18.1479+14.4777+17.9571+14.9408+17.9571+15.5102C17.9571+16.2291+18.3289+16.8853+18.9356+17.3822M13.0644+17.3822C13.6711+16.8853+14.0429+16.2291+14.0429+15.5102C14.0429+13.1846+10.9067+12.6683+10.1287+11.1389C9.53182+12.292+7.61387+12.8806+6.70383+14.082C6.40537+14.4777+6.21455+14.9408+6.21455+15.5102C6.21455+16.2291+6.5864+16.8853+7.1931+17.3822M18.5687+11.5924C19.0995+11.1722+19.4249+10.6173+19.4249+10.0099C19.4249+8.04373+16.6806+7.60708+16+6.31402C15.4779+7.28912+13.7997+7.78657+13.0032+8.8022C12.7419+9.13656+12.5751+9.52833+12.5751+10.0099C12.5751+10.6178+12.9005+11.1726+13.4313+11.5924" opacity="1" strokeLinecap="round" fill="none" stroke="#F87217" strokeLinejoin="round" strokeWidth="1.5"/>
                <path stroke="#F87217" strokeWidth="1.5" d="M13.0644+21.1495L13.0644+17.3774L7.1931+17.3822L7.1931+24.6147L24.8069+24.6147L24.8069+17.3822L18.9356+17.3774L18.9356+21.1495M18.4464+14.082L18.4464+11.5876L13.5536+11.5876L13.5536+14.0772" opacity="1" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                <path strokeWidth="1.5" stroke="#F87217" d="M16+16.8343L16+19.8681" opacity="1" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.7967+22.3659L21.7967+19.8672" opacity="1" fill="none" strokeLinecap="round" stroke="#F87217" strokeWidth="1.5" strokeLinejoin="round"/>
                <path stroke="#F87217" strokeLinecap="round" strokeLinejoin="round" d="M10.1266+22.3659L10.1266+19.5023" opacity="1" fill="none" strokeWidth="1.5"/>
                </g>
                <path opacity="1" strokeLinecap="round" stroke="#F87217" strokeLinejoin="round" d="M24.8069+24.6147L25.5102+24.6147" strokeWidth="1.5" fill="none"/>
                <path strokeLinejoin="round" strokeLinecap="round" stroke="#F87217" opacity="1" d="M7.1931+24.6147L6.48977+24.6147" fill="none" strokeWidth="1.5"/>
                </g>
                </g>
                </svg>
            }

            {serviceType === 'bridges' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 2">
                <path fill="none" stroke="#990012" opacity="1" stroke-linecap="round" d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" stroke-width="1" stroke-linejoin="round"/>
                </g>
                <g id="Слой 1">
                <path opacity="1" stroke-linejoin="round" stroke="#990012" stroke-width="1.5" stroke-linecap="round" d="M26.7692+17.6923L26.7692+21.4615" fill="none"/>
                <path stroke="#990012" fill="none" opacity="1" stroke-linecap="round" d="M27.8462+22L20.3077+22" stroke-width="1.5" stroke-linejoin="round"/>
                <path d="M22.6144+10.1538L25.6923+10.1538" stroke-width="1.5" stroke="#990012" fill="none" stroke-linecap="round" stroke-linejoin="round" opacity="1"/>
                <path d="M6.30769+10.1538C6.30769+11.3553+6.30769+17.6923+6.30769+17.6923" stroke-width="1.5" opacity="1" stroke="#990012" fill="none" stroke-linejoin="round" stroke-linecap="round"/>
                <path fill="none" d="M9.53846+10.1538L9.53846+17.6923" stroke-linecap="round" opacity="1" stroke="#990012" stroke-width="1.5" stroke-linejoin="round"/>
                <path d="M5.23077+17.6923L10.6154+17.6923" stroke="#990012" fill="none" opacity="1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6154+17.6923L10.6154+22" opacity="1" fill="none" stroke-width="1.5" stroke="#990012" stroke-linecap="round" stroke-linejoin="round"/>
                <path opacity="1" stroke-linecap="round" stroke="#990012" stroke-linejoin="round" fill="none" d="M11.6923+22L4.15385+22" stroke-width="1.5"/>
                <path stroke="#990012" stroke-linecap="round" stroke-linejoin="round" d="M5.23077+22L5.23077+17.6923" stroke-width="1.5" fill="none" opacity="1"/>
                <path d="M6.30769+10.1538L9.53846+10.1538" fill="none" stroke-linejoin="round" opacity="1" stroke="#990012" stroke-linecap="round" stroke-width="1.5"/>
                <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke="#990012" d="M10.6154+17.6923L19.6352+13.9562" stroke-width="1.5" opacity="1"/>
                <path stroke-width="1.5" fill="none" stroke="#990012" stroke-linecap="round" opacity="1" d="M22.4615+10.1538C22.4615+11.3553+22.4615+17.6923+22.4615+17.6923" stroke-linejoin="round"/>
                <path opacity="1" d="M25.6923+10.1538L25.6923+17.6923" fill="none" stroke-linecap="round" stroke-width="1.5" stroke="#990012" stroke-linejoin="round"/>
                <path stroke-width="1.5" stroke-linecap="round" fill="none" stroke="#990012" opacity="1" stroke-linejoin="round" d="M21.3846+17.6923L26.7692+17.6923"/>
                <path stroke-width="1.5" fill="none" opacity="1" d="M21.3846+22L21.3846+17.6923" stroke="#990012" stroke-linejoin="round" stroke-linecap="round"/>
                <path d="M7.91288+10.1538L7.91288+7.72215" stroke-width="1.5" fill="none" opacity="1" stroke="#990012" stroke-linejoin="round" stroke-linecap="round"/>
                <path opacity="1" d="M24.1581+10.1538L24.1647+10.1538" stroke-linejoin="round" fill="none" stroke-linecap="round" stroke-width="1.5" stroke="#990012"/>
                <path opacity="1" stroke="#990012" stroke-width="1.5" fill="none" d="M24.1581+10.1538L24.1581+7.70805" stroke-linecap="round" stroke-linejoin="round"/>
                <g opacity="1">
                <path d="M16+25C16+25+16+26+14+26C12+26+12+25+12+25C12+25+12+26+10+26C8+26+8+25+8+25" stroke="#990012" fill="none" opacity="1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5"/>
                <path opacity="1" stroke="#990012" stroke-linecap="round" d="M24+25C24+25+24+26+22+26C20+26+20+25+20+25C20+25+20+26+18+26C16+26+16+25+16+25" fill="none" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
                </g>
                </svg>
            }

            {serviceType === 'aqua_parks' &&
                <svg viewBox="0 0 32 32" version="1.1"
                style={{
                     cursor: 'pointer',
                     transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                 }}
                 width={MAP_ICON_SIZE}
                 height={MAP_ICON_SIZE}
                 onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 2">
                <path d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" fill="none" opacity="1" strokeWidth="1" strokeLinejoin="round" stroke="#1589FF" strokeLinecap="round"/>
                </g>
                <g id="Слой 1">
                <g opacity="1">
                <path strokeWidth="1.5" opacity="1" d="M15.2121+12.7391C9.294+12.7391+6.97228+11.5554+6.97228+9.82553C6.97228+9.0061+7.65514+7.45829+11.0239+7.45829C12.162+7.45829+13.2546+7.59486+14.3471+7.91353C20.2652+10.0531+12.8449+16.2444+5.19685+18.7027C5.19685+21.6617+7.65514+23.4372+10.2045+23.4372C11.9344+23.4372+12.7993+22.7543+12.7993+22.7543C15.6673+20.9789+21.0847+17.2004+21.0847+12.7391C21.0847+8.64191+16.0771+6.36572+11.0694+6.36572C7.42752+6.36572+5.15133+8.14115+5.15133+10.2808C5.15133+13.9682+9.33952+15.0152+12.6172+15.0152" strokeLinecap="round" stroke="#1589FF" fill="none" strokeLinejoin="round"/>
                <path stroke="#1589FF" d="M21.4931+12.4659C24.088+12.4659+27.6844+6.95752+20.9013+5C20.9469+5+26+5.72838+26+9.64343C26+13.604+21.4476+15.125+20.9013+15.125" strokeLinejoin="round" fill="none" strokeWidth="1.5" strokeLinecap="round" opacity="1"/>
                </g>
                <g opacity="1">
                <path strokeLinecap="round" strokeWidth="1.5" opacity="1" d="M21.2919+26C21.2919+26+20.2653+27.0265+18.4689+27.0265C16.6725+27.0265+15.6459+26+15.6459+26" strokeLinejoin="round" stroke="#1589FF" fill="none"/>
                <path opacity="1" strokeWidth="1.5" strokeLinejoin="round" stroke="#1589FF" d="M15.6459+26.0265C15.6459+26.0265+14.6194+27.0531+12.823+27.0531C11.0265+27.0531+10+26.0265+10+26.0265" strokeLinecap="round" fill="none"/>
                </g>
                <g opacity="1">
                <path stroke="#1589FF" strokeLinejoin="round" fill="none" opacity="1" strokeLinecap="round" strokeWidth="1.5" d="M21.6459+22.9735C21.6459+22.9735+20.6194+24+18.823+24C17.0265+24+16+22.9735+16+22.9735"/>
                <path strokeLinecap="round" stroke="#1589FF" opacity="1" d="M27.2919+22.9735C27.2919+22.9735+26.2653+24+24.4689+24C22.6725+24+21.6459+22.9735+21.6459+22.9735" strokeLinejoin="round" fill="none" strokeWidth="1.5"/>
                </g>
                </g>
                </svg>
            }

            {serviceType === 'swimming_pools' &&
                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeLinecap="round" opacity="1" fill="none" strokeWidth="1" strokeLinejoin="round"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              stroke="#3BB9FF"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path stroke="#3BB9FF"
                                  d="M18.823+19.1194C18.823+19.1194+17.7964+20.1459+16+20.1459C14.2036+20.1459+13.177+19.1194+13.177+19.1194"
                                  strokeLinejoin="round" fill="none" strokeWidth="1.5" strokeLinecap="round"
                                  opacity="1"/>
                            <path strokeLinecap="round" strokeWidth="1.5" opacity="1"
                                  d="M24.4689+19.1194C24.4689+19.1194+23.4424+20.1459+21.6459+20.1459C19.8495+20.1459+18.823+19.1194+18.823+19.1194"
                                  strokeLinejoin="round" stroke="#3BB9FF" fill="none"/>
                            <path strokeWidth="1.5" opacity="1" stroke="#3BB9FF"
                                  d="M27.2919+20.1459C25.4954+20.1459+24.4689+19.1194+24.4689+19.1194"
                                  strokeLinecap="round" fill="none" strokeLinejoin="round"/>
                            <path opacity="1" strokeWidth="1.5" strokeLinejoin="round" stroke="#3BB9FF"
                                  d="M13.177+19.1194C13.177+19.1194+12.1505+20.1459+10.3541+20.1459C8.55763+20.1459+7.53109+19.1194+7.53109+19.1194"
                                  strokeLinecap="round" fill="none"/>
                            <path strokeLinecap="round" strokeWidth="1.5" opacity="1" stroke="#3BB9FF" fill="none"
                                  strokeLinejoin="round"
                                  d="M7.53109+19.1194C7.53109+19.1194+6.50456+20.1459+4.70813+20.1459"/>
                            <path strokeLinecap="round" stroke="#3BB9FF" opacity="1"
                                  d="M18.823+22.2255C18.823+22.2255+17.7964+23.2521+16+23.2521C14.2036+23.2521+13.177+22.2255+13.177+22.2255"
                                  strokeLinejoin="round" fill="none" strokeWidth="1.5"/>
                            <path opacity="1"
                                  d="M24.4689+22.2255C24.4689+22.2255+23.4424+23.2521+21.6459+23.2521C19.8495+23.2521+18.823+22.2255+18.823+22.2255"
                                  stroke="#3BB9FF" strokeLinecap="round" fill="none" strokeWidth="1.5"
                                  strokeLinejoin="round"/>
                            <path opacity="1" strokeLinejoin="round"
                                  d="M27.2919+23.2521C25.4954+23.2521+24.4689+22.2255+24.4689+22.2255" stroke="#3BB9FF"
                                  fill="none" strokeLinecap="round" strokeWidth="1.5"/>
                            <path strokeLinejoin="round"
                                  d="M13.177+22.2255C13.177+22.2255+12.1505+23.2521+10.3541+23.2521C8.55763+23.2521+7.53109+22.2255+7.53109+22.2255"
                                  strokeLinecap="round" strokeWidth="1.5" opacity="1" fill="none" stroke="#3BB9FF"/>
                            <path strokeLinejoin="round" fill="none" stroke="#3BB9FF" strokeLinecap="round"
                                  opacity="1" strokeWidth="1.5"
                                  d="M7.53109+22.2255C7.53109+22.2255+6.50456+23.2521+4.70813+23.2521"/>
                            <path
                                d="M18.823+16.0398C18.823+16.0398+17.7964+17.0663+16+17.0663C14.2036+17.0663+13.177+16.0398+13.177+16.0398"
                                opacity="1" strokeLinejoin="round" stroke="#3BB9FF" fill="none" strokeLinecap="round"
                                strokeWidth="1.5"/>
                            <path
                                d="M24.4689+16.0398C24.4689+16.0398+23.4424+17.0663+21.6459+17.0663C19.8495+17.0663+18.823+16.0398+18.823+16.0398"
                                fill="none" strokeLinecap="round" strokeWidth="1.5" stroke="#3BB9FF" opacity="1"
                                strokeLinejoin="round"/>
                            <path strokeWidth="1.5" stroke="#3BB9FF" strokeLinecap="round" fill="none"
                                  strokeLinejoin="round" opacity="1"
                                  d="M27.2919+17.0663C25.4954+17.0663+24.4689+16.0398+24.4689+16.0398"/>
                            <path stroke="#3BB9FF"
                                  d="M13.177+16.0398C13.177+16.0398+12.1505+17.0663+10.3541+17.0663C8.55763+17.0663+7.53109+16.0398+7.53109+16.0398"
                                  opacity="1" strokeLinecap="round" fill="none" strokeWidth="1.5"
                                  strokeLinejoin="round"/>
                            <path strokeLinejoin="round" strokeWidth="1.5" fill="none" opacity="1"
                                  d="M7.53109+16.0398C7.53109+16.0398+6.50456+17.0663+4.70813+17.0663" stroke="#3BB9FF"
                                  strokeLinecap="round"/>
                            <path opacity="1" strokeLinecap="round" strokeWidth="1.5" fill="none" stroke="#3BB9FF"
                                  d="M25.7521+9.1362C25.7521+10.4137+24.7173+11.4469+23.4424+11.4469C22.1659+11.4469+21.1327+10.4137+21.1327+9.1362C21.1327+7.86125+22.1659+6.82753+23.4424+6.82753C24.7173+6.82753+25.7521+7.86125+25.7521+9.1362Z"
                                  strokeLinejoin="round"/>
                            <path strokeLinejoin="round" opacity="1" fill="none" strokeLinecap="round"
                                  strokeWidth="1.5"
                                  d="M20.3412+14.5C20.5162+14.1977+20.6194+13.8482+20.6194+13.4724C20.6194+13.07+20.4988+12.6969+20.2986+12.3802L20.3017+12.3756L15.7834+5.31919C15.5535+4.97479+15.1618+4.74793+14.7168+4.74793C14.4653+4.74793+14.2323+4.82286+14.0342+4.9481L14.0296+4.94708L8.00689+7.95174L8.00843+7.95482C7.57575+8.16115+7.27446+8.59897+7.27446+9.11018C7.27446+9.82003+7.84829+10.3939+8.55763+10.3939C8.77166+10.3939+8.97081+10.3364+9.1484+10.2435L9.15097+10.2486L14.2467+7.84909L15.8932+10.5222L9.32753+14.5"
                                  stroke="#3BB9FF"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'sports_grounds' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path
                            d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                            stroke="#3B9C9C" opacity="1" strokeLinejoin="round" fill="none" strokeWidth="1"
                            strokeLinecap="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path strokeLinecap="round" strokeLinejoin="round" opacity="1" fill="none"
                                  strokeWidth="1.5" d="M5.5+8.5L5.5+23.5" stroke="#3B9C9C"/>
                            <path stroke="#3B9C9C" d="M26.5+23.5L26.5+8.5" opacity="1" strokeLinejoin="round"
                                  fill="none" strokeLinecap="round" strokeWidth="1.5"/>
                            <path opacity="1" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5"
                                  stroke="#3B9C9C" d="M9.7+10.5L9.7+18.5" fill="none"/>
                            <path strokeWidth="1.5" stroke="#3B9C9C" strokeLinejoin="round" opacity="1" fill="none"
                                  d="M13.9+10.5L13.9+18.5" strokeLinecap="round"/>
                            <path strokeLinejoin="round" opacity="1" d="M18.1+10.5L18.1+18.5" stroke="#3B9C9C"
                                  strokeLinecap="round" strokeWidth="1.5" fill="none"/>
                            <path opacity="1" strokeLinejoin="round" d="M22.3+10.5L22.3+18.5" strokeLinecap="round"
                                  stroke="#3B9C9C" strokeWidth="1.5" fill="none"/>
                            <path d="M26.5+13.5L5.5+13.5" fill="none" stroke="#3B9C9C" strokeLinecap="round"
                                  strokeWidth="1.5" strokeLinejoin="round" opacity="1"/>
                            <path strokeLinejoin="round" opacity="1" fill="none" strokeLinecap="round"
                                  d="M26.5+16.5L5.5+16.5" strokeWidth="1.5" stroke="#3B9C9C"/>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                                  d="M26.5+19.5L5.5+19.5" fill="none" stroke="#3B9C9C" opacity="1"/>
                            <path stroke="#3B9C9C" strokeLinecap="round" strokeWidth="1.5" d="M26.5+10.5L5.5+10.5"
                                  fill="none" opacity="1" strokeLinejoin="round"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'sports_centers' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeLinecap="round" fill="none" stroke="#57FEFF"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeWidth="1" opacity="1" strokeLinejoin="round"/>
                    </g>
                    <g id="Слой 3">
                        <g opacity="1">
                            <path d="M4.05132+16L5.54491+16" strokeLinejoin="round" stroke="#57FEFF" fill="none"
                                  strokeLinecap="round" opacity="1" strokeWidth="1.5"/>
                            <path opacity="1" strokeLinecap="round" strokeWidth="1.5" fill="none"
                                  d="M8.84125+20.3561C9.11159+20.0648+9.27887+19.6625+9.27887+19.2184L9.27887+15.44L9.27887+12.7816C9.27887+11.8934+8.61124+11.1724+7.78528+11.1724C6.95933+11.1724+6.2917+11.8917+6.2917+12.7816L6.2917+19.2184C6.2917+20.1066+6.95933+20.8276+7.78528+20.8276C8.19751+20.8276+8.57091+20.6473+8.84125+20.3561Z"
                                  strokeLinejoin="round" stroke="#57FEFF"/>
                            <path fill="none" stroke="#57FEFF" strokeLinecap="round"
                                  d="M11.8284+23.5744C12.0988+23.2832+12.266+22.8809+12.266+22.4367L12.266+9.56326C12.266+8.67498+11.5984+7.95407+10.7725+7.95407C9.9465+7.95407+9.27887+8.67338+9.27887+9.56326L9.27887+22.4367C9.27887+23.325+9.9465+24.0459+10.7725+24.0459C11.1847+24.0459+11.5581+23.8657+11.8284+23.5744Z"
                                  strokeLinejoin="round" strokeWidth="1.5" opacity="1"/>
                            <path stroke="#57FEFF" d="M12.266+16L19.734+16" fill="none" strokeLinejoin="round"
                                  opacity="1" strokeWidth="1.5" strokeLinecap="round"/>
                            <path d="M26.4551+16L27.9487+16" strokeLinejoin="round" strokeLinecap="round" fill="none"
                                  stroke="#57FEFF" strokeWidth="1.5" opacity="1"/>
                            <path opacity="1" strokeWidth="1.5"
                                  d="M23.1588+20.3561C22.8884+20.0648+22.7211+19.6625+22.7211+19.2184L22.7211+15.44L22.7211+12.7816C22.7211+11.8934+23.3888+11.1724+24.2147+11.1724C25.0407+11.1724+25.7083+11.8917+25.7083+12.7816L25.7083+19.2184C25.7083+20.1066+25.0407+20.8276+24.2147+20.8276C23.8025+20.8276+23.4291+20.6473+23.1588+20.3561Z"
                                  stroke="#57FEFF" strokeLinejoin="round" strokeLinecap="round" fill="none"/>
                            <path strokeLinejoin="round" opacity="1" stroke="#57FEFF"
                                  d="M20.1716+23.5744C19.9012+23.2832+19.734+22.8809+19.734+22.4367L19.734+9.56326C19.734+8.67498+20.4016+7.95407+21.2275+7.95407C22.0535+7.95407+22.7211+8.67338+22.7211+9.56326L22.7211+22.4367C22.7211+23.325+22.0535+24.0459+21.2275+24.0459C20.8153+24.0459+20.4419+23.8657+20.1716+23.5744Z"
                                  strokeLinecap="round" strokeWidth="1.5" fill="none"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'stadiums' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeLinecap="round" fill="none" stroke="#306EFF"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              opacity="1" strokeWidth="1" strokeLinejoin="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path strokeLinejoin="round"
                                  d="M10.2857+5L21.7143+5C22.9767+5+24+5.98497+24+7.2L24+24.8C24+26.015+22.9767+27+21.7143+27L10.2857+27C9.02335+27+8+26.015+8+24.8L8+7.2C8+5.98497+9.02335+5+10.2857+5Z"
                                  stroke="#306EFF" fill="none" opacity="1" strokeWidth="1.5" strokeLinecap="round"/>
                            <path opacity="1" strokeLinejoin="round" d="M12.5714+27L12.5714+22.6" stroke="#306EFF"
                                  strokeWidth="1.5" strokeLinecap="round" fill="none"/>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.5714+22.6L19.4286+22.6"
                                  stroke="#306EFF" fill="none" opacity="1" strokeWidth="1.5"/>
                            <path strokeLinejoin="round" d="M19.4286+22.6L19.4286+27" opacity="1" strokeWidth="1.5"
                                  strokeLinecap="round" fill="none" stroke="#306EFF"/>
                            <path strokeLinejoin="round" d="M12.5714+5L12.5714+9.4" stroke="#306EFF"
                                  strokeLinecap="round" opacity="1" strokeWidth="1.5" fill="none"/>
                            <path d="M12.5714+9.4L19.4286+9.4" opacity="1" strokeLinejoin="round" stroke="#306EFF"
                                  fill="none" strokeLinecap="round" strokeWidth="1.5"/>
                            <path d="M19.4286+9.4L19.4286+6.1" strokeWidth="1.5" strokeLinejoin="round"
                                  stroke="#306EFF" fill="none" opacity="1" strokeLinecap="round"/>
                            <path opacity="1" strokeWidth="1.5" stroke="#306EFF" strokeLinecap="round" fill="none"
                                  strokeLinejoin="round" d="M8+16L24+16"/>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12.5714+16C12.5714+14.1775+14.1065+12.7+16+12.7C17.8935+12.7+19.4286+14.1775+19.4286+16C19.4286+17.8225+17.8935+19.3+16+19.3C14.1065+19.3+12.5714+17.8225+12.5714+16Z"
                                  strokeWidth="1.5" stroke="#306EFF" fill="none" opacity="1"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'bars_pubs' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeLinejoin="round"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              stroke="#C68E17" fill="none" opacity="1" strokeWidth="1" strokeLinecap="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <g opacity="1">
                                <path opacity="1" strokeLinejoin="round" strokeWidth="1.5"
                                      d="M22.2575+10.6221L22.2575+24.2917C22.2575+26.1079+21.3494+26.9682+19.581+26.9682L10.5954+26.9682C8.82694+27.016+7.91882+26.1079+7.91882+24.3394L7.91882+10.3831M22.2575+13.1553C22.2575+13.1553+23.4046+12.6773+24.6473+12.6773C25.9856+12.6773+27.0371+13.5854+27.0371+15.0671C27.0371+18.1738+25.5554+21.7585+22.7355+21.7585L22.2575+21.7585"
                                      stroke="#C68E17" strokeLinecap="round" fill="none"/>
                                <path opacity="1" strokeLinejoin="round"
                                      d="M21.3016+5.98591C20.5369+5.98591+19.8199+6.36827+19.3898+6.94182C18.9596+6.36827+18.2427+5.98591+17.478+5.98591C16.9522+5.98591+16.4742+6.1293+16.0919+6.41607C15.6139+5.60354+14.658+5.03+13.6543+5.03C12.5072+5.03+11.5035+5.69914+11.0733+6.70284C10.5954+6.27268+10.0218+5.98591+9.35269+5.98591C8.01441+5.98591+6.96291+7.03741+6.96291+8.37569C6.96291+9.71397+8.01441+10.7655+9.35269+10.7655C10.213+10.7655+10.9777+10.3353+11.3601+9.61838C11.8859+10.2875+12.6984+10.7655+13.6543+10.7655C14.4668+10.7655+15.136+10.4309+15.6617+9.95295C15.9485+10.2397+16.522+10.8133+16.522+11.578C16.522+14.1112+15.5661+15.0671+15.5661+16.023C15.5661+17.0745+16.4265+17.9348+17.478+17.9348C18.5295+17.9348+19.3898+17.0745+19.3898+16.023C19.3898+15.0671+18.4339+14.1112+18.4339+11.578C18.4339+10.7655+19.103+10.1919+19.3898+9.80956C19.8199+10.3831+20.5369+10.7655+21.3016+10.7655C22.6399+10.7655+23.6914+9.71397+23.6914+8.37569C23.6914+7.03741+22.6399+5.98591+21.3016+5.98591ZM19.1986+14.5891L22.2575+14.5891M8.39678+14.5891L15.7095+14.5891"
                                      stroke="#C68E17" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
                            </g>
                            <path strokeWidth="1.5" d="M12+15L12+26" strokeLinejoin="round" stroke="#C68E17"
                                  fill="none" opacity="1" strokeLinecap="round"/>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18+18L18+26" strokeWidth="1.5"
                                  stroke="#C68E17" fill="none" opacity="1"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'bakeries' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-LEGEND_ICON_SIZE / 2}px,${-LEGEND_ICON_SIZE}px)`
                     }}
                     width={LEGEND_ICON_SIZE}
                     height={LEGEND_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path opacity="1" strokeWidth="1" fill="none"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinecap="round" stroke="#C35817" strokeLinejoin="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path strokeLinejoin="round" strokeWidth="1.5"
                                  d="M16+4.86946C10.2998+4.86946+4.7865+9.07433+4.7865+16C4.7865+22.9257+10.2998+27.1305+16+27.1305C21.7002+27.1305+27.2135+22.9875+27.2135+16C27.2135+9.07433+21.7002+4.86946+16+4.86946Z"
                                  fill="none" stroke="#C35817" opacity="1" strokeLinecap="round"/>
                            <path fill="none"
                                  d="M16+12.4082C13.9357+12.4082+12.2622+14.0163+12.2622+16C12.2622+17.9837+13.9357+19.5918+16+19.5918C18.0643+19.5918+19.7378+17.9837+19.7378+16C19.7378+14.0163+18.0643+12.4082+16+12.4082Z"
                                  opacity="1" stroke="#C35817" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path opacity="1" fill="none" stroke="#C35817" strokeLinecap="round"
                                  d="M6.1346+21.1305C7.06906+21.9962+8.91266+21.647+9.66023+22.018C10.2209+22.3272+11.6693+23.9968+13.0243+24.1204C14.0989+24.2441+15.2203+23.1929+15.8744+23.1929C16.6687+23.1929+17.3695+24.2441+18.4441+23.9349C19.1917+23.7494+19.7057+23.0074+20.4532+22.76C21.1073+22.5127+22.0418+22.8837+23.1632+22.5745C24.0976+22.3272+24.2001+21.9344+25.1346+21.1305C25.8354+20.5122+26.06+20.2247+26.5272+19.73"
                                  strokeWidth="1.5" strokeLinejoin="round"/>
                            <path
                                d="M22.5879+10.4347L23.1953+12.5372M22.5927+15.4503L24.1346+16.1305M22.1346+18.9556L20.7796+20.1305M9.59275+19.1305L11.1346+20.3673M7.55651+15.8218L8.90163+16.7966M8.73848+12.1749L10+11M11.1408+7.8376L11.8884+9.25984M14.3647+7.62898L16+8M18+10.6184L19.4484+10"
                                strokeLinecap="round" fill="none" opacity="1" stroke="#C35817" strokeLinejoin="round"
                                strokeWidth="1.5"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'restaurants' &&
                <svg viewBox="0 0 32 32" version="1.1"
                         style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                    >
                    <defs/>
                    <g id="Слой 2">
                    <path stroke="#9CB071" opacity="1" strokeLinejoin="round" strokeLinecap="round" d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" strokeWidth="1" fill="none"/>
                    </g>
                    <g id="Слой 1">
                    <g opacity="1">
                    <path stroke="#9CB071" strokeWidth="1.5" strokeLinejoin="round" fill="none" opacity="1" d="M8.24269+5.9338C7.4394+5.9338+6.7882+6.585+6.7882+7.3883C6.7882+8.19159+7.4394+8.84279+8.24269+8.84279C9.04599+8.84279+9.69719+8.1916+9.69719+7.3883C9.69719+6.585+9.04599+5.9338+8.24269+5.9338Z" strokeLinecap="round"/>
                    <path strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="1.5" opacity="1" d="M10.8181+15.6304L9.69719+15.6304L9.69719+11.7518C9.60604+10.9334+9.04606+10.2973+8.24269+10.2973C7.43933+10.2973+6.7882+10.9484+6.7882+11.7518L6.79305+16.236C6.85608+16.9899+7.48636+17.5698+8.24269+17.5698L10.7478+17.5698" stroke="#9CB071"/>
                    <path strokeWidth="1.5" d="M10.4244+19.0243L6.75378+19.0243C6.00035+19.0243+5.37782+18.4371+5.3337+17.6852L5.3337+10.7821M6.30337+19.0243L5.81854+23.3877" strokeLinecap="round" opacity="1" stroke="#9CB071" fill="none" strokeLinejoin="round"/>
                    <path strokeWidth="1.5" stroke="#9CB071" strokeLinejoin="round" d="M23.7573+5.9338C22.954+5.9338+22.3028+6.585+22.3028+7.3883C22.3028+8.19159+22.954+8.84279+23.7573+8.84279C24.5606+8.84279+25.2118+8.1916+25.2118+7.3883C25.2118+6.585+24.5606+5.9338+23.7573+5.9338Z" strokeLinecap="round" opacity="1" fill="none"/>
                    <path fill="none" strokeLinecap="round" d="M21.0602+15.6304L22.3028+15.6304L22.3028+11.7518C22.394+10.9334+22.9539+10.2973+23.7573+10.2973C24.5607+10.2973+25.2118+10.9484+25.2118+11.7518L25.207+16.236C25.1439+16.9899+24.5136+17.5698+23.7573+17.5698L21.3026+17.5698" stroke="#9CB071" opacity="1" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path fill="none" opacity="1" strokeWidth="1.5" d="M26.6663+10.7821L26.6663+17.6852C26.6222+18.4371+25.9997+19.0243+25.2462+19.0243L21.5756+19.0243M25.6966+19.0243L26.1815+23.3877M20.3635+13.2063L11.6365+13.2063L10.182+22.9029C10.182+22.9029+10.7275+23.3877+12.1213+23.3877C13.5152+23.3877+14.0607+22.9029+14.0607+22.9029C14.0607+22.9029+14.5756+23.3877+16+23.3877C17.424+23.3877+17.9393+22.9029+17.9393+22.9029C17.9393+22.9029+18.4542+23.3877+19.8787+23.3877C21.3026+23.3877+21.818+22.9029+21.818+22.9029L20.3635+13.2063ZM14.0607+22.9029L14.0607+19.9939M17.9393+22.9029L17.9393+20.9636" strokeLinejoin="round" stroke="#9CB071" strokeLinecap="round"/>
                    </g>
                    </g>
                    </svg>
            }

            {serviceType === 'cafes_canteens' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeWidth="1" stroke="#6F4E37"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinejoin="round" fill="none" strokeLinecap="round" opacity="1"/>
                    </g>
                    <g id="Слой 3">
                        <path strokeWidth="1.5" strokeLinecap="round"
                              d="M6.87727+25.1227L13.8318+17.7844M15.8773+15.6261L23.4045+7.68349M24.8773+25.1227L14.2409+13.8995M17.4318+17.2664L15.3864+19.4248L8.10455+11.7411C6.95909+10.5325+6.95909+8.63315+8.10455+7.42449L8.10455+7.42449L17.4318+17.2664ZM25.1227+9.49648L21.3591+13.4678C20.7045+14.1585+19.7227+14.1585+19.0682+13.4678L17.9227+12.2591C17.2682+11.5685+17.2682+10.5325+17.9227+9.84181L21.6864+5.8705"
                              stroke="#6F4E37" opacity="1" strokeLinejoin="round" fill="none"/>
                    </g>
                </svg>
            }

            {serviceType === 'sushi_shawarma_fast_foods' &&
                <svg viewBox="0 0 32 32" version="1.1"
                    style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                        >
                    <defs/>
                    <g id="Слой 2">
                    <path opacity="1" strokeLinejoin="round" fill="none" d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" strokeWidth="1" stroke="#FF2400" strokeLinecap="round"/>
                    </g>
                    <g id="Слой 3">
                    <g opacity="1">
                    <path strokeLinejoin="round" strokeWidth="1.5" fill="none" opacity="0.994361" strokeLinecap="round" d="M10+26L21+26" stroke="#FF2400"/>
                    <path opacity="0.994361" d="M21+26C24.6364+26+25+22+25+22" fill="none" stroke="#FF2400" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path opacity="0.994361" strokeLinecap="round" strokeWidth="1.5" stroke="#FF2400" strokeLinejoin="round" fill="none" d="M10+26C7.27273+26+7+22+7+22"/>
                    <g opacity="1">
                    <path stroke="#FF2400" strokeWidth="1.5" d="M6+13L26+13" strokeLinecap="round" fill="none" opacity="0.994361" strokeLinejoin="round"/>
                    <path opacity="0.994361" strokeLinejoin="round" fill="none" strokeLinecap="round" d="M6+13C6+13+7+6+16+6" strokeWidth="1.5" stroke="#FF2400"/>
                    <path d="M26+13C26+13+25+6+16+6" fill="none" strokeWidth="1.5" strokeLinejoin="round" strokeLinecap="round" opacity="0.994361" stroke="#FF2400"/>
                    </g>
                    <path fill="none" strokeLinecap="round" stroke="#FF2400" opacity="0.994361" strokeWidth="1.5" strokeLinejoin="round" d="M6+16C9+18+9+14+11+16C13+18+14+14+16+16C18+18+19+14+21+16C23+18+24+14+26+16"/>
                    <path strokeLinejoin="round" fill="none" strokeLinecap="round" strokeWidth="1.5" opacity="0.994361" d="M6.57143+19L25.4286+19C26.2964+19+27+19.6716+27+20.5L27+20.5C27+21.3284+26.2964+22+25.4286+22L6.57143+22C5.70355+22+5+21.3284+5+20.5L5+20.5C5+19.6716+5.70355+19+6.57143+19Z" stroke="#FF2400"/>
                    </g>
                    </g>
                    </svg>
            }

            {serviceType === 'bowling_billiards' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path opacity="1" strokeWidth="1" strokeLinejoin="round" fill="none" stroke="#9DC209"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinecap="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path
                                d="M12.9664+11.6481C9.11896+11.6481+6+14.6371+6+18.3241C6+22.0111+9.11896+25+12.9664+25C16.8138+25+19.9328+22.0111+19.9328+18.3241C19.9328+14.6371+16.8138+11.6481+12.9664+11.6481Z"
                                opacity="1" strokeLinejoin="round" stroke="#9DC209" fill="none" strokeLinecap="round"
                                strokeWidth="1.5"/>
                            <path
                                d="M14.708+14.1516C14.2271+14.1516+13.8372+14.5252+13.8372+14.9861C13.8372+15.447+14.2271+15.8206+14.708+15.8206C15.1889+15.8206+15.5788+15.447+15.5788+14.9861C15.5788+14.5252+15.1889+14.1516+14.708+14.1516ZM11.2248+14.1516C10.7439+14.1516+10.354+14.5252+10.354+14.9861C10.354+15.447+10.7439+15.8206+11.2248+15.8206C11.7057+15.8206+12.0956+15.447+12.0956+14.9861C12.0956+14.5252+11.7057+14.1516+11.2248+14.1516ZM12.9664+17.4896C12.4855+17.4896+12.0956+17.8632+12.0956+18.3241C12.0956+18.785+12.4855+19.1586+12.9664+19.1586C13.4473+19.1586+13.8372+18.785+13.8372+18.3241C13.8372+17.8632+13.4473+17.4896+12.9664+17.4896Z"
                                strokeWidth="1.5" opacity="1" strokeLinecap="round" stroke="#9DC209" fill="#9DC209"
                                strokeLinejoin="round"/>
                            <path strokeWidth="1.5" strokeLinecap="round"
                                  d="M19+10L22+10M17.3204+24.1655C17.3744+24.5961+18.7851+25+20.7983+25L20.807+25C22.8203+25+24.231+24.5961+24.285+24.1655C24.285+24.1655+25.1558+20.2017+25.1558+18.3241C25.1558+15.4034+22.5434+12.97+22.5434+11.0223C22.5434+9.07458+23.416+9.14468+23.416+7.4757C23.416+6.09378+22.2456+4.97222+20.8036+4.97222C19.3615+4.97222+18.1912+6.09378+18.1912+7.4757C18.1912+9.14468+19.062+9.07458+19.062+11.0223C19.062+11.7833+18.6631+12.6178+18.179+13.5258"
                                  fill="none" stroke="#9DC209" strokeLinejoin="round" opacity="1"/>
                            <path opacity="1" d="M18.8116+12.2723L22.4368+12.2723" strokeWidth="1.5" fill="none"
                                  strokeLinejoin="round" strokeLinecap="round" stroke="#9DC209"/>
                            <path opacity="1" fill="none" stroke="#9DC209" strokeLinecap="round" strokeWidth="1.5"
                                  d="M25.1558+24.9722L7.09847+24.9722" strokeLinejoin="round"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'art_spaces' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeWidth="1" fill="none"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinejoin="round" strokeLinecap="round" stroke="#FFCBA4" opacity="1"/>
                    </g>
                    <g id="Слой 1">
                        <path strokeLinecap="round" d="M8+6L24+6L24+22L8+22L8+6Z" stroke="#FFCBA4" opacity="1"
                              strokeLinejoin="round" strokeWidth="1.5" fill="none"/>
                        <path strokeLinejoin="round" opacity="1"
                              d="M13+22L11.9485+6.33132M8+13L23+13M8+16L24+16M20+13.7304L20+22" fill="none"
                              strokeLinecap="round" strokeWidth="1.5" stroke="#FFCBA4"/>
                        <path strokeLinecap="round" fill="none" stroke="#FFCBA4" d="M24+22L26+22"
                              strokeLinejoin="round" opacity="1" strokeWidth="1.5"/>
                        <path strokeLinecap="round" fill="none" stroke="#FFCBA4" strokeWidth="1.5"
                              strokeLinejoin="round" d="M8.5+22L6+22" opacity="1"/>
                        <path stroke="#FFCBA4" opacity="1" fill="none" strokeWidth="1.5" strokeLinecap="round"
                              d="M8+26L9+22" strokeLinejoin="round"/>
                        <path stroke="#FFCBA4" strokeWidth="1.5" d="M22.8611+22L24+26" strokeLinejoin="round"
                              opacity="1" strokeLinecap="round" fill="none"/>
                        <path strokeLinejoin="round" strokeLinecap="round" d="M16+22L16+24" opacity="1"
                              stroke="#FFCBA4" fill="none" strokeWidth="1.5"/>
                        <path strokeWidth="1.5" strokeLinecap="round" stroke="#FFCBA4" d="M19+6L18+3" opacity="1"
                              fill="none" strokeLinejoin="round"/>
                        <path strokeLinejoin="round" fill="none" strokeWidth="1.5" d="M13+6L14+3" opacity="1"
                              strokeLinecap="round" stroke="#FFCBA4"/>
                    </g>
                </svg>
            }

            {serviceType === 'zoos' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeLinejoin="round" strokeLinecap="round" opacity="1" fill="none" strokeWidth="1"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              stroke="#342D7E"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path stroke="#342D7E" strokeLinejoin="round" fill="#342D7E" strokeWidth="1.5"
                                  d="M23.6665+11.4551C23.196+11.4551+22.8146+11.8337+22.8146+12.3007C22.8146+12.7677+23.196+13.1463+23.6665+13.1463C24.1369+13.1463+24.5183+12.7677+24.5183+12.3007C24.5183+11.8337+24.1369+11.4551+23.6665+11.4551Z"
                                  opacity="1" strokeLinecap="round"/>
                            <path stroke="#342D7E" strokeLinejoin="round" opacity="1" fill="none" strokeWidth="1.5"
                                  strokeLinecap="round"
                                  d="M22.9041+23.205C24.4911+23.205+24.5183+21.6026+24.5183+21.6026L24.5183+19.0657C24.5183+16.6286+22.7029+17.0634+20.7878+17.0634C18.8727+17.0634+17.9151+18.014+17.9151+20.8657C17.9151+23.7175+18+26+18+26L14+26L14+21L11+21L11+26L7+26L7+20C7+20+4.9262+17.1529+4.9262+13.992C4.9262+10.831+7.38193+7.55751+11.7426+7.22697C16.1032+6.89643+14+8+14+8"/>
                            <path strokeLinejoin="round" strokeLinecap="round" stroke="#342D7E"
                                  d="M21.111+20.757C21.111+22.1582+22.255+23.2938+23.6665+23.2938C25.078+23.2938+25.7679+22.504+26.222+20.757C26.222+20.757+27.0738+17.3415+27.0738+13.1463C27.0738+8.9512+24.5652+6.38135+21.9628+6.38135C20.5675+6.38135+19.4073+7.22697+19.4073+7.22697C19.4073+7.22697+18.1619+6.38135+16.8518+6.38135C15.523+6.38135+13.4445+7.76817+13.4445+10.6095C13.4445+14.5678+14.0849+17.0634+16+17.0634C17.9151+17.0634+17.9151+14.844+17.9151+13.261"
                                  strokeWidth="1.5" fill="none" opacity="1"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'cinemas' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path opacity="1" strokeWidth="1" strokeLinejoin="round" stroke="#893BFF" fill="none"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinecap="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path strokeWidth="1.5" stroke="#893BFF" strokeLinecap="round" opacity="1"
                                  strokeLinejoin="round" fill="none"
                                  d="M6.75+12.3L20.75+12.3L20.75+23.3L6.75+23.3L6.75+12.3ZM20.75+20.3L20.75+16.3L27.25+14.3L27.25+21.9L20.75+20.3ZM16.9318+4.7C14.8231+4.7+13.1136+6.40132+13.1136+8.5C13.1136+10.5987+14.8231+12.3+16.9318+12.3C19.0405+12.3+20.75+10.5987+20.75+8.5C20.75+6.40132+19.0405+4.7+16.9318+4.7Z"/>
                            <path strokeLinecap="round" d="M9.11364+16.2L13.8864+16.2" stroke="#893BFF" opacity="1"
                                  strokeLinejoin="round" fill="none" strokeWidth="1.5"/>
                            <path strokeLinejoin="round" strokeLinecap="round" fill="none" stroke="#893BFF"
                                  opacity="1" strokeWidth="1.5"
                                  d="M6.75+9.3C6.75+7.64315+8.09315+6.3+9.75+6.3C11.4069+6.3+12.75+7.64315+12.75+9.3C12.75+10.9569+11.4069+12.3+9.75+12.3C8.09315+12.3+6.75+10.9569+6.75+9.3Z"/>
                            <path strokeLinecap="butt" fill="none" opacity="1" stroke="#893BFF" strokeWidth="1.5"
                                  d="M11.75+11.3L13.75+11.3" strokeLinejoin="round"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'museums' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path strokeLinejoin="round" strokeWidth="1" strokeLinecap="round" stroke="#348017"
                              fill="none" opacity="1"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path strokeLinecap="round" strokeWidth="1.5"
                                  d="M6.76339+24.2446C6.30156+24.2446+5.99367+23.9367+5.99367+23.4749L5.99367+21.9354C5.99367+21.4736+6.30156+21.1657+6.76339+21.1657L25.2366+21.1657C25.6984+21.1657+26.0063+21.4736+26.0063+21.9354L26.0063+23.4749C26.0063+23.9367+25.6984+24.2446+25.2366+24.2446L6.76339+24.2446Z"
                                  strokeLinejoin="round" fill="none" opacity="1" stroke="#348017"/>
                            <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" opacity="1"
                                  d="M6.76339+10.6829C6.30156+10.6829+5.99367+10.375+5.99367+9.91317L5.99367+9.14345C5.99367+8.68162+6.30156+8.60465+6.76339+8.37373L16+3.75542L25.2366+8.37373C25.6984+8.60465+26.0063+8.68162+26.0063+9.14345L26.0063+9.91317C26.0063+10.375+25.6984+10.6829+25.2366+10.6829L6.76339+10.6829Z"
                                  stroke="#348017" fill="none"/>
                            <path opacity="1" strokeWidth="1.5" strokeLinejoin="round"
                                  d="M17.5394+21.1249L14.4606+21.1249L14.4606+11.7055C14.4606+11.1403+14.7685+10.7636+15.2303+10.7636L16.7697+10.7636C17.2315+10.7636+17.5394+11.1403+17.5394+11.7055L17.5394+21.1249Z"
                                  stroke="#348017" strokeLinecap="round" fill="none"/>
                            <path strokeWidth="1.5"
                                  d="M23.6972+21.1254L20.6183+21.1254L20.6183+11.7063C20.6183+11.1411+20.9262+10.7644+21.388+10.7644L22.9275+10.7644C23.3893+10.7644+23.6972+11.1411+23.6972+11.7063L23.6972+21.1254Z"
                                  opacity="1" fill="none" strokeLinecap="round" stroke="#348017"
                                  strokeLinejoin="round"/>
                            <path strokeLinecap="round"
                                  d="M11.3817+21.1254L8.30282+21.1254L8.30282+11.7063C8.30282+11.1411+8.61071+10.7644+9.07254+10.7644L10.612+10.7644C11.0738+10.7644+11.3817+11.1411+11.3817+11.7063L11.3817+21.1254Z"
                                  opacity="1" strokeWidth="1.5" fill="none" stroke="#348017" strokeLinejoin="round"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'amusements_parks' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path opacity="1" strokeWidth="1" strokeLinejoin="round" stroke="#B048B5"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinecap="round" fill="none"/>
                    </g>
                    <g id="Слой 3">
                        <g opacity="1">
                            <path d="M16+14.5778L11.9832+27.5193" opacity="1" strokeLinejoin="round" stroke="#B048B5"
                                  fill="none" strokeLinecap="round" strokeWidth="1.5"/>
                            <path strokeWidth="1.5" stroke="#B048B5" fill="none" strokeLinecap="round"
                                  strokeLinejoin="round" opacity="1" d="M20.0168+27.5193L16+14.5778"/>
                            <path strokeLinejoin="round" stroke="#B048B5" opacity="1" strokeWidth="1.5"
                                  d="M9.97476+27.5193L22.0252+27.5193" strokeLinecap="round" fill="none"/>
                            <path strokeWidth="1.5" fill="none" opacity="1" strokeLinecap="round" stroke="#B048B5"
                                  strokeLinejoin="round"
                                  d="M21+21.1313C21+20.0992+21.9011+19.2625+23.0127+19.2625C24.1242+19.2625+25.0254+20.0992+25.0254+21.1313C25.0254+22.1633+24.1242+23+23.0127+23C21.9011+23+21+22.1633+21+21.1313Z"/>
                            <path stroke="#B048B5" strokeWidth="1.5" opacity="1"
                                  d="M23.9703+14.8009C23.9703+13.8063+24.8724+13+25.9852+13C27.0979+13+28+13.8063+28+14.8009C28+15.7955+27.0979+16.6018+25.9852+16.6018C24.8724+16.6018+23.9703+15.7955+23.9703+14.8009Z"
                                  fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                            <path strokeWidth="1.5"
                                  d="M6.96213+8.15064C6.96213+7.10554+7.85853+6.25832+8.96429+6.25832C10.0701+6.25832+10.9664+7.10554+10.9664+8.15064C10.9664+9.19575+10.0701+10.043+8.96429+10.043C7.85853+10.043+6.96213+9.19575+6.96213+8.15064Z"
                                  fill="none" stroke="#B048B5" strokeLinecap="round" strokeLinejoin="round"
                                  opacity="1"/>
                            <path strokeWidth="1.5" stroke="#B048B5" fill="none" opacity="1" strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6.96213+21.9929C6.96213+20.9608+7.85853+20.1242+8.96429+20.1242C10.0701+20.1242+10.9664+20.9608+10.9664+21.9929C10.9664+23.025+10.0701+23.8617+8.96429+23.8617C7.85853+23.8617+6.96213+23.025+6.96213+21.9929Z"/>
                            <path fill="none" stroke="#B048B5" strokeLinejoin="round"
                                  d="M3.99979+15.0225C3.99979+13.9304+4.89527+13.045+5.9999+13.045C7.10452+13.045+8+13.9304+8+15.0225C8+16.1146+7.10452+17+5.9999+17C4.89527+17+3.99979+16.1146+3.99979+15.0225Z"
                                  strokeWidth="1.5" opacity="1" strokeLinecap="round"/>
                            <path strokeWidth="1.5"
                                  d="M21+7.89233C21+6.84722+21.9011+6+23.0127+6C24.1242+6+25.0254+6.84722+25.0254+7.89233C25.0254+8.93743+24.1242+9.78466+23.0127+9.78466C21.9011+9.78466+21+8.93743+21+7.89233Z"
                                  opacity="1" strokeLinecap="round" stroke="#B048B5" fill="none"
                                  strokeLinejoin="round"/>
                            <path strokeWidth="1.5" strokeLinecap="round"
                                  d="M17.929+5.53008C17.6967+6.37739+16.9211+7+16+7C14.8954+7+14+6.10457+14+5C14+3.89543+14.8954+3+16+3C17.1046+3+18+3.89543+18+5C18+5.18352+17.9753+5.36126+17.929+5.53008"
                                  fill="none" stroke="#B048B5" strokeLinejoin="round" opacity="1"/>
                            <path strokeWidth="1.5" d="M10.2198+7.01782C11.3821+6.26347+12.726+5.73048+14.1793+5.48547"
                                  strokeLinejoin="round" stroke="#B048B5" fill="none" opacity="1"
                                  strokeLinecap="butt"/>
                            <path opacity="1" fill="none" strokeWidth="1.5" stroke="#B048B5" strokeLinecap="butt"
                                  strokeLinejoin="round"
                                  d="M6.06492+13.2236C6.27345+11.916+6.77942+10.6974+7.51754+9.62781"/>
                            <path opacity="1" d="M8.0523+20.2289C7.22363+19.2428+6.60674+18.0982+6.26437+16.853"
                                  strokeWidth="1.5" fill="none" strokeLinejoin="round" strokeLinecap="butt"
                                  stroke="#B048B5"/>
                            <path opacity="1" fill="none" stroke="#B048B5" strokeLinecap="butt" strokeWidth="1.5"
                                  d="M21.7088+22.1838C20.0878+23.2166+18.1207+23.8218+16+23.8218C14.1376+23.8218+12.3936+23.355+10.8985+22.5418"
                                  strokeLinejoin="round"/>
                            <path strokeLinejoin="round" strokeWidth="1.5" fill="none" stroke="#B048B5"
                                  d="M25.8725+16.2784C25.6042+17.6064+25.0275+18.8346+24.2134+19.8977" opacity="1"
                                  strokeLinecap="butt"/>
                            <path d="M23.9605+8.94208C24.9887+10.1709+25.7491+11.393+26+13" strokeWidth="1.5"
                                  strokeLinejoin="round" fill="none" stroke="#B048B5" strokeLinecap="butt"
                                  opacity="1"/>
                            <path strokeLinecap="butt" opacity="1" fill="none" strokeLinejoin="round"
                                  strokeWidth="1.5" stroke="#B048B5"
                                  d="M17.8113+5.4839C19.0672+5.69449+20.2416+6.12012+21.2879+6.71783"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'theaters_concert_halls' &&
                <svg viewBox="0 0 32 32" version="1.1"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 1">
                        <path opacity="1" strokeWidth="1" strokeLinejoin="round" stroke="#800517"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              strokeLinecap="round" fill="none"/>
                    </g>
                    <g id="Слой 2">
                        <g opacity="1">
                            <path
                                d="M24+7.5L24+24.5L18+24.5C18+21.2243+19.1875+18.6875+19.1875+18.6875C19.1875+18.6875+16+13.1752+16+8.0625"
                                opacity="1" strokeLinejoin="round" stroke="#800517" fill="none" strokeLinecap="round"
                                strokeWidth="1.5"/>
                            <path strokeWidth="1.5" opacity="1" fill="none" strokeLinecap="round" stroke="#800517"
                                  strokeLinejoin="round" d="M26+7.5L6+7.5"/>
                            <path strokeLinejoin="round" stroke="#800517" opacity="1" strokeWidth="1.5"
                                  d="M19+18.5L24+18.5" strokeLinecap="round" fill="none"/>
                            <path strokeWidth="1.5" strokeLinejoin="round" fill="none" stroke="#800517"
                                  d="M8+7.5L8+24.5L14+24.5C14+21.3614+12.8125+18.6875+12.8125+18.6875C12.8125+18.6875+16+12.6127+16+7.5"
                                  strokeLinecap="round" opacity="1"/>
                            <path stroke="#800517" strokeWidth="1.5" opacity="1" d="M13+18.5L8+18.5" fill="none"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <path
                            d="M58.2165+3.05448C57.1119+3.05448+56.2165+3.94991+56.2165+5.05448C56.2165+6.15905+57.1119+7.05448+58.2165+7.05448C59.3211+7.05448+60.2165+6.15905+60.2165+5.05448C60.2165+3.94991+59.3211+3.05448+58.2165+3.05448Z"
                            strokeLinejoin="miter" opacity="1" fill="none" strokeLinecap="butt" stroke="#800517"
                            strokeWidth="1"/>
                        <path fill="none" stroke="#800517" strokeWidth="1"
                              d="M61.2165+12.0545L55.2165+12.0545C55.2165+10.2655+54.2165+9.05448+54.2165+9.05448C54.2165+9.05448+55.2165+8.05448+58.2165+8.05448C61.2165+8.05448+62.2165+9.05448+62.2165+9.05448C62.2165+9.05448+61.2165+10.3815+61.2165+12.0545ZM55.2165+14.0545L61.2165+14.0545L61.2165+16.0545L55.2165+16.0545L55.2165+14.0545ZM56.2165+12.0545L56.2165+14.0545M60.2165+12.0545L60.2165+14.0545"
                              strokeLinecap="butt" opacity="1" strokeLinejoin="miter"/>
                    </g>
                </svg>
            }

            {serviceType === 'shopping_centers' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                <defs/>
                <g id="Слой 2">
                <path d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" opacity="1" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#4CC417" fill="none"/>
                </g>
                <g id="Слой 1">
                <g opacity="1">
                <path stroke="#4CC417" fill="none" strokeLinejoin="round" opacity="1" strokeWidth="1.5" d="M7.8945+7.2656L24.1055+7.2656L24.1055+25.3321L7.8945+25.3321L7.8945+7.2656Z" strokeLinecap="round"/>
                <path strokeLinecap="round" stroke="#4CC417" strokeWidth="1.5" fill="none" d="M12+7C12+7+11+3+16+3C21+3+20+7+20+7" opacity="1" strokeLinejoin="round"/>
                <g opacity="1">
                <path stroke="#4CC417" fill="none" opacity="1" strokeLinecap="round" strokeWidth="1.5" strokeLinejoin="round" d="M16.0765+11C13.2728+11+11+13.2694+11+16.0688C11+18.8682+13.2728+21.1376+16.0765+21.1376C18.8801+21.1376+21.1529+18.8682+21.1529+16.0688C21.1529+13.2694+18.8801+11+16.0765+11Z"/>
                <path opacity="1" d="M18.1532+15.1472C17.7709+15.1472+17.4609+15.4567+17.4609+15.8384C17.4609+16.2201+17.7709+16.5296+18.1532+16.5296C18.5355+16.5296+18.8454+16.2201+18.8454+15.8384C18.8454+15.4567+18.5355+15.1472+18.1532+15.1472ZM13.9997+15.1472C13.6174+15.1472+13.3075+15.4567+13.3075+15.8384C13.3075+16.2201+13.6174+16.5296+13.9997+16.5296C14.382+16.5296+14.692+16.2201+14.692+15.8384C14.692+15.4567+14.382+15.1472+13.9997+15.1472Z" fill="#4CC417"/>
                <path d="M14.7206+18.3728C15.0381+18.6032+15.4996+18.8336+16.0765+18.8336C16.6533+18.8336+17.1148+18.6032+17.4319+18.3728" opacity="1" stroke="#4CC417" fill="none" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5"/>
                </g>
                </g>
                </g>
                </svg>
            }

            {serviceType === 'playgrounds' &&
                <svg version="1.1" viewBox="0 0 32 32"
                     style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                >
                    <defs/>
                    <g id="Слой 2">
                        <path opacity="1" fill="none" strokeLinejoin="round"
                              d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z"
                              stroke="#F9966B" strokeWidth="1" strokeLinecap="round"/>
                    </g>
                    <g id="Слой 1">
                        <g opacity="1">
                            <path strokeWidth="1.5" strokeLinejoin="round" strokeLinecap="round" opacity="1"
                                  stroke="#F9966B" fill="none"
                                  d="M23.0693+14.947C22.8583+13.952+22.8753+11.794+24.6183+11.555C26.6253+11.279+27.9673+13.871+28.3723+16.194L17.9203+18.111M23.8013+7.5C22.6967+7.5+21.8013+8.39543+21.8013+9.5C21.8013+10.6046+22.6967+11.5+23.8013+11.5C24.9058+11.5+25.8013+10.6046+25.8013+9.5C25.8013+8.39543+24.9058+7.5+23.8013+7.5ZM5.80126+10.5C4.69669+10.5+3.80126+11.3954+3.80126+12.5C3.80126+13.6046+4.69669+14.5+5.80126+14.5C6.90583+14.5+7.80126+13.6046+7.80126+12.5C7.80126+11.3954+6.90583+10.5+5.80126+10.5ZM24+17L25+20M8.40626+17.394C8.13726+16.096+7.00726+14.42+5.69726+14.663C4.36926+14.909+3.16926+16.306+3.80126+20.806L13.8553+18.857M9+20L8+23"/>
                            <path strokeLinejoin="round" stroke="#F9966B" strokeWidth="1.5" fill="none"
                                  strokeLinecap="round" opacity="1"
                                  d="M15.8013+16.5C14.6967+16.5+13.8013+17.3954+13.8013+18.5C13.8013+19.6046+14.6967+20.5+15.8013+20.5C16.9058+20.5+17.8013+19.6046+17.8013+18.5C17.8013+17.3954+16.9058+16.5+15.8013+16.5ZM15.8013+20.5L15.8013+24.5M12.8013+24.5L18.8013+24.5"/>
                        </g>
                    </g>
                </svg>
            }

            {serviceType === 'landfills' &&
                <svg viewBox="0 0 32 32" version="1.1"
                      style={{
                         cursor: 'pointer',
                         transform: `translate(${-MAP_ICON_SIZE / 2}px,${-MAP_ICON_SIZE}px)`
                     }}
                     width={MAP_ICON_SIZE}
                     height={MAP_ICON_SIZE}
                     onClick={() => onClick(city)}
                    >
                    <defs/>
                    <g id="Слой 2">
                    <path d="M1+16C1+7.71573+7.71573+1+16+1C24.2843+1+31+7.71573+31+16C31+24.2843+24.2843+31+16+31C7.71573+31+1+24.2843+1+16Z" opacity="1" strokeLinecap="round" strokeWidth="1" strokeLinejoin="round" stroke="#657383" fill="none"/>
                    </g>
                    <g id="Слой 1">
                    <g opacity="1">
                    <path opacity="1" strokeLinejoin="round" fill="none" strokeLinecap="round" d="M23.4898+9.63572L23.4898+25.5C23.4898+26.5302+22.651+27.3664+21.6174+27.3664L10.3826+27.3664C9.34902+27.3664+8.51016+26.5302+8.51016+25.5L8.51016+9.63572M12.2551+11.5021L12.2551+24.5668M16+11.5021L16+24.5668M19.7449+11.5021L19.7449+24.5668M5.70147+8.70253L26.2985+8.70253" strokeWidth="1.5" stroke="#657383"/>
                    <path stroke="#657383" strokeWidth="1.5" opacity="1" d="M19.7884+8.36639L19.7884+6.4944C19.7884+5.46602+18.9532+4.63361+17.9215+4.63361L14.1654+4.63361C13.1336+4.63361+12.2985+5.46602+12.2985+6.4944L12.2985+8.36639" strokeLinecap="round" fill="none" strokeLinejoin="round"/>
                    </g>
                    </g>
                    </svg>
            }
        </Marker>
    ));
}

export default React.memo(Pins);
