export const cafes = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Гауранга",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 17",
                "opening_hours": "13:00-19:00",
                "contact_phone": "+7 960 2508999;+7 812 3245498",
                "website": "http:\/\/gauranga.ucoz.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.364027, 59.936368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Frida",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 57",
                "opening_hours": "Tu-Su 11:00-22:00+",
                "contact_phone": "+7 812 2726816",
                "website": "http:\/\/cafe-frida.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358349, 59.946261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отец и Сыр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 8М",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28935, 59.911656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Flat Beer Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 54к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236143, 59.983162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Полтавская улица, 3",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 6076865",
                "website": "http:\/\/spb.lindfors.ru\/restorany\/ul-poltavskaya-dom-3"
            },
            "geometry": {"type": "Point", "coordinates": [30.370351, 59.928108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 25",
                "opening_hours": "Mo-Fr 09:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 3140868",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322614, 59.937468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 9-11Л",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341632, 59.917632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "City Grill",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 1\/47А",
                "opening_hours": "Mo-Th 11:00-23:00, Fr-Sa 12:00-01:00, Su 12:00-23:00",
                "contact_phone": "+7 812 9382708",
                "website": "http:\/\/www.citygrillexpress.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.34606, 59.932126]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 97к2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432981, 59.896874]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киликия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 40",
                "opening_hours": "10:00-06:00",
                "contact_phone": "+7 812 3272208",
                "website": "http:\/\/kilikia.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.319812, 59.930372]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ноян Тапан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 84",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 (812) 714-31-18",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292377, 59.918959]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Buuza Room",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 20",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316326, 59.928293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тимоша",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рижская улица, 12",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416363, 59.921128]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Библиотека вкусов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 31",
                "opening_hours": "Mo-Su 08:00-06:00",
                "contact_phone": "+7 812 2441594",
                "website": "http:\/\/ilovenevsky.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321914, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штрудельбург",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 18",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 3059126",
                "website": "http:\/\/штрудельбург.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.298576, 59.99665]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дикая Сибирь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сытнинская площадь, 3",
                "opening_hours": "17:00-05:00",
                "contact_phone": "+7 911 0375916",
                "website": "https:\/\/vk.com\/wildsib"
            },
            "geometry": {"type": "Point", "coordinates": [30.311152, 59.957169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kwakker",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 7",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 9293737",
                "website": "http:\/\/kwakker.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314413, 59.977584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Займемся кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 28",
                "opening_hours": "Mo-Fr 08:00-21:00 Sa-Su 10:00-21:00",
                "contact_phone": "+7 911 9306372",
                "website": "https:\/\/www.zaymemsyakofe.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.303669, 59.959043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cake and Breakfast",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 7",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 921 8660029",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353786, 59.943809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Укроп",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 47",
                "opening_hours": "09:00-23:00",
                "contact_phone": "http:\/\/cafe-ukrop.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361071, 59.941876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr. Fox Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366883, 59.935313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cake and Breakfast",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Манежный переулок, 1\/4К",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353723, 59.943043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стуккей кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": "+79110800101",
                "website": "http:\/\/stukkey.tilda.ws"
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мистер шницель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Полтавский проезд, 2В",
                "opening_hours": "Mo-Fr 08:30-23:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366353, 59.92515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ласточка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Зоологический переулок, 1-3",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/instagram.com\/lastochkabakery",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303022, 59.949177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлеб и кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 63к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357388, 59.838602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венеция",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 4-я Советская улица, 18\/9",
                "opening_hours": "Mo-Fr 10:00-22:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 2716758",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370207, 59.933501]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ностальгия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Русановская улица, 17к4",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 953 3677010",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.512213, 59.862587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эдда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Биржевой переулок, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293563, 59.945017]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grill post",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кременчугская улица, 11к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371222, 59.922878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ketch Up Burgers",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный просп., 59",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-01:00",
                "contact_phone": "http:\/\/ketchupburgers.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347615, 59.934042]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясорубка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 31",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348037, 59.941439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "City Grill",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "Su-Th 10:00-23:00, Fr-Sa 10:00-07:00",
                "contact_phone": "http:\/\/www.citygrillexpress.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wave Burgers",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 38",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 9972849",
                "website": "http:\/\/wave-burgers.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.320827, 59.930642]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СБ Бургерс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 126\/2",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-23:30",
                "contact_phone": "+7 952 2220022",
                "website": "http:\/\/sbburgers.me\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.365527, 59.930908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Moo Moo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": "Su-Th 08:00-23:30, Fr-Sa 08:00-01:30",
                "contact_phone": "+7 (812) 955-22-88",
                "website": "www.moomooburgers.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здесь и сейчас",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 50",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364979, 59.938698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волконский",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 8",
                "opening_hours": "08:00-21:00",
                "contact_phone": "+7 911 2978326",
                "website": "http:\/\/wolkonsky.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318815, 59.957908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мама Люба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354145, 59.94422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ferma",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 6430400",
                "website": "https:\/\/white-group.ru\/restaurants\/view\/konditerskaya-ferma\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 33",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 7142571",
                "website": "http:\/\/spb.lindfors.ru\/restorany\/ul-dekabristov-33"
            },
            "geometry": {"type": "Point", "coordinates": [30.291173, 59.925619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Waff-Waff",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 30-32Ж",
                "opening_hours": "Tu-Su 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327968, 59.931878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pan&Cake",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Мира, 7Г",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314727, 59.959282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лорд бургер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 20к1",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386359, 59.874277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sisters",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 108",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/thesisters.cafe\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31294, 59.908028]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ôpetit",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Блохина, 23",
                "opening_hours": "+7 812 4243626",
                "contact_phone": "https:\/\/o-petit.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297336, 59.952326]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ферма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 13",
                "opening_hours": "https:\/\/white-group.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282262, 59.940343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нолькалорий",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 19",
                "opening_hours": "http:\/\/www.nolcalor.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363919, 59.936088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волшебная сковородка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 9",
                "opening_hours": "Mo-Fr 10:00-22:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 2716758",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370072, 59.933744]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тесто-Плюс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 8",
                "opening_hours": "Mo-Fr 10:00-21:00; Su 11:00-20:00; Sa, PH 11:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325723, 59.964462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 32",
                "opening_hours": "Mo-Su 09:00-19:00",
                "contact_phone": "+7 960 281-42-64",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287877, 59.943422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузинский уголок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 17\/56",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316102, 59.932171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "БамБарБия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 41",
                "opening_hours": "+7 900 6506514",
                "contact_phone": "http:\/\/bambarbia.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354711, 59.946311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Виват Маэстро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленская улица, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.497516, 59.940339]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 50",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9444490;+7 812 4956713",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.334778, 59.934592]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Изюм",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 14",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9392576",
                "website": "http:\/\/cafe-izum.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.358781, 59.947942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Караванная улица, 28",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7-812-944-44-90;+7 812 3477149",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341353, 59.933893]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Умное питание",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Воронежская улица, 116",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343491, 59.909165]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чудо мангал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 59",
                "opening_hours": "http:\/\/chudomangal.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332271, 59.927757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Здесь и Сейчас",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 2",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 9068691",
                "website": "http:\/\/www.ziscafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Olmega",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 61к3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345045, 59.984152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рамен Шифу",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 15",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28202, 59.937607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Десятка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 3к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29677, 59.955475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лотос",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Науки, 17к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385595, 60.014895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бар Мэм",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 50",
                "opening_hours": "12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322929, 60.012543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тхайди",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рузовская улица, 1",
                "opening_hours": "Mo-Su 12:00-23:30",
                "contact_phone": "http:\/\/www.aziya-eda.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326217, 59.918503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чуфальня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 28-30к27",
                "opening_hours": "Mo-Su 07:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32681, 59.929019]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайна Таун",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "+7 812 4492472",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Небесный ФУД Город",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "Mo-Su 13:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайская Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 10",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344884, 59.926864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tiger Lily",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 19",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334948, 59.93607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe-City",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 55",
                "opening_hours": "Su-Th 11:00-22:00; Fr-Sa 11:00-23:00",
                "contact_phone": "+7 911 9247151",
                "website": "https:\/\/sushi-city.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.307801, 59.955237]}
        },
        {"type": "Feature",
            "properties": {
                "name": "12 Комнат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 19",
                "opening_hours": "10:00-06:00",
                "contact_phone": "http:\/\/www.12komnat.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314637, 59.934795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Playloft Gaga",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 15",
                "opening_hours": "12:00-23:00; Fr-Sa 12:00-06:00",
                "contact_phone": "+7 812 9248361",
                "website": "https:\/\/playloftgaga.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311116, 59.935169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kiosk59",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 59",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332271, 59.927757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 4",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-21:00",
                "contact_phone": "https:\/\/spb.stolle.ru\/addresses\/1-ya-krasnoarmejskaya-4-ugol-moskovskogo-pr-ta",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316272, 59.915918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Freak Zone",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Советская улица, 4",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 2446826",
                "website": "http:\/\/vk.com\/freak_zone"
            },
            "geometry": {"type": "Point", "coordinates": [30.362976, 59.931815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торты",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 13-я линия Васильевского острова, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271033, 59.940551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Koffein",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 18-я линия Васильевского острова, 49",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260864, 59.941236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mad Espresso Team",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 27\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372246, 59.930219]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Третий кластер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 4",
                "opening_hours": "08:30-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370414, 59.938018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Civil",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370414, 59.938018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 1\/25",
                "opening_hours": "Mo-Su 7:30-23:00",
                "contact_phone": "+7 812 3133260",
                "website": "http:\/\/www.starbucks.ru\/store\/1007240\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.323576, 59.935322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Check Point",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 33\/5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311718, 59.929227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Espresso Bike",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7",
                "opening_hours": "https:\/\/espressobike.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321042, 59.932378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фильтр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 18",
                "opening_hours": "Mo-Fr 08:00-21:00, Sa-Su 10:00-21:00",
                "contact_phone": "+7 981 9342680",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32071, 59.974342]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Ю",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6771286",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.37919, 59.82741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ла Петра",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Варьете",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бассейная, 41м",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326235, 59.864259]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 13",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 812 6771288",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.271949, 60.000285]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Порт-Брю",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 11",
                "opening_hours": "Mo-Fr 08:00-21:00;Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346105, 59.92575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Caffe Nero",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 68к1",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33599, 59.979664]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 13\/9",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/spb.stolle.ru\/addresses\/vladimirskij-pr-15",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347363, 59.92975]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pitcher",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 13\/9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347363, 59.92975]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 19",
                "opening_hours": "http:\/\/www.starbucks.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346932, 59.928519]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КУЛЬТУРА - vinyl store & brew bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 114к1",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-21:00",
                "contact_phone": "+7 812 3747024",
                "website": "https:\/\/www.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.416749, 60.033108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wild Bean Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 22к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.455008, 59.904825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Caffe Prego",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дивенская улица, 5",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320962, 59.959868]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Di Neve",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кулинарная Фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заставская улица, 46к1",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313766, 59.888379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6771289",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клуб Завтрак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 16\/19",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su,PH 10:00-21:00",
                "contact_phone": "+7 812 9035152",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342916, 59.935435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 31-33",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 3464898",
                "website": "https:\/\/www.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.31276, 59.964976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 40",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 812 6773112",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.310694, 59.966255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361197, 59.94367]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парадная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 7",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su,PH 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353786, 59.943809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прокофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колокольная улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351073, 59.92837]}
        },
        {"type": "Feature",
            "properties": {
                "name": "You & Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Коломенская улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353202, 59.92313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Даблби",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 65",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 11:00-21:00",
                "contact_phone": "+7 (812) 928-06-18",
                "website": "http:\/\/double-b.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.306292, 59.953673]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "+7 812 6771265",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Costa",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "+7 812 6407260",
                "contact_phone": "http:\/\/www.costacoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rudy's Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 33-35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361476, 59.932748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Святой Кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50Ж",
                "opening_hours": "Mo-Sa 09:00-22:00; Su 13:00-22:00",
                "contact_phone": "+79312082008",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3594, 59.925232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "One Price Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 84\/2",
                "opening_hours": "https:\/\/onepricecoffee.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355098, 59.921286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gotcha brew bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 41",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347857, 59.939442]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Знакомьтесь, Джо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347686, 59.934786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Coffee & Breakfast",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 3\/54",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/www.coffee-breakfast.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33723, 59.934601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Даблби",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 18",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "http:\/\/double-b.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322498, 59.942822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Медовик & Кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мичуринская улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327402, 59.95566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 145",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319021, 59.877353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41 лит 3А",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6771262",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.302789, 59.787284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сныть",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мошков переулок, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321384, 59.943679]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6771306",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рубаи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 40\/68",
                "opening_hours": "Mo-Su 11:00+",
                "contact_phone": "http:\/\/rubai.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344623, 59.933708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 55",
                "opening_hours": "24\/7",
                "contact_phone": "+7 931 2294121",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.303929, 59.986511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1886 Bonomi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 104",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 12:00-18:00",
                "contact_phone": "+7 812 9291886",
                "website": "http:\/\/1886bonomi.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.356589, 59.931882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 108",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6771299",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.357496, 59.931774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 11\/2",
                "opening_hours": "09:00-23:00",
                "contact_phone": "https:\/\/spb.stolle.ru\/addresses\/nevskij-pr-d11",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.936404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "Mo-Su 09:30-23:00",
                "contact_phone": "+7 812 3851142",
                "website": "http:\/\/starbuckscoffee.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Департамент Кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 150К",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373791, 59.928654]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 18",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6771300",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318249, 59.936557]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 26",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.starbucks.ru\/store\/1007738",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325291, 59.935818]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 55",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/www.starbucks.ru\/store\/1005961",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350292, 59.931999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fixcoff",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SmackCoffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Антоненко, 5",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 3748334;+7 981 9951667",
                "website": "https:\/\/smackcoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311556, 59.929718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sky wine bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Пирогова, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300345, 59.928379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6776745",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бери и беги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 53",
                "opening_hours": "Mo-Fr 07:30-20:00; Sa-Su 09:00-21:00",
                "contact_phone": "+7 911 9992767",
                "website": "https:\/\/vk.com\/berybegy"
            },
            "geometry": {"type": "Point", "coordinates": [30.282819, 59.960607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, площадь Александра Невского, 2В",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6771284",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.386916, 59.925475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Онтроме",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, площадь Александра Невского, 2Е",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 981 7000075",
                "website": "https:\/\/ontrome.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.38697, 59.924794]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "http:\/\/starbuckscoffee.ru\/ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 2",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su 09:00-21:00",
                "contact_phone": "+7 812 4554700",
                "website": "https:\/\/spb.stolle.ru\/addresses\/pr-aviakonstruktorov-2"
            },
            "geometry": {"type": "Point", "coordinates": [30.246231, 60.009588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "http:\/\/starbuckscoffee.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6771294",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 34",
                "opening_hours": "08:00-21:00",
                "contact_phone": "+7 812 4090014",
                "website": "https:\/\/www.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.334257, 60.05362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 74\/1",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "https:\/\/www.stolle.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259202, 59.876549]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 37",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 3181146",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325067, 60.011243]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энтузиастов, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.476496, 59.948226]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "https:\/\/shoko.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 677-76-48",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Costa",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "+7 960 2440943",
                "contact_phone": "http:\/\/www.costacoffee.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271195, 59.799983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Четыре фрегата",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 54",
                "opening_hours": "08:00-20:00",
                "contact_phone": "https:\/\/kofes.ru\/kofeynya-4-fregata",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309544, 59.922972]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 8\/7",
                "opening_hours": "Su-Sa 09:00-21:00",
                "contact_phone": "+7 812 3398767",
                "website": "https:\/\/spb.stolle.ru\/addresses\/ul-sadovaya-8"
            },
            "geometry": {"type": "Point", "coordinates": [30.336592, 59.937008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клуб Завтрак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сапёрный переулок, 16\/36",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359859, 59.941096]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 45",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "+7 812 6771305",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.273863, 59.941939]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чеховъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 46",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272902, 59.941231]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grey's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тележный переулок, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374456, 59.928537]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 10",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148063, 59.851625]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Е.Д.А и кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 31",
                "opening_hours": "Mo-Sa 10:00-23:00; Su 12:00-23:00",
                "contact_phone": "+7 812 6770238",
                "website": "http:\/\/edaikofe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353651, 59.958782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rudy's Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белинского, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347848, 59.938081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 32",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 3278284",
                "website": "https:\/\/www.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.360236, 59.940447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358233, 59.955002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мансарда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 36-38",
                "opening_hours": "Mo-Th, Su 12:00-23:59; Fr-Sa 12:00-6:00",
                "contact_phone": "http:\/\/clubmansarda.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352519, 59.925705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мадлен",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Мира, 25",
                "opening_hours": "Mo-Fr 09:30-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323746, 59.96112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 25",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "+7 812 6771302",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.347929, 59.942683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 31",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 3275285",
                "website": "http:\/\/www.lindfors.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.305879, 59.971353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 3",
                "opening_hours": "Mo-Sa 08:00-21:00; Su 09:00-21:00",
                "contact_phone": "+7 812 4929692",
                "website": "https:\/\/www.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.299222, 59.986722]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Щелкунчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 8",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 4951364",
                "website": "http:\/\/vkontakte.ru\/club14905617"
            },
            "geometry": {"type": "Point", "coordinates": [30.293329, 59.923811]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 6773146",
                "website": "https:\/\/shoko.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Спасибокофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 4",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "https:\/\/instagram.com\/spasibocoffee",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343689, 59.946851]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, Михайловская улица, 2\/9",
                "opening_hours": "10:00-22:00",
                "contact_phone": "https:\/\/www.philharmonia.spb.ru\/about\/restaurants\/chocolate\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331481, 59.936061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 2",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34907, 59.939167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fika vegan bistro",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317764, 59.96866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Музей специй",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 6",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": "+7 812 986-50-90",
                "website": "http:\/\/spicemuseum.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.289233, 59.940492]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Паштейш & Кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 36",
                "opening_hours": "Mo-Su 08:30-21:00",
                "contact_phone": "+7 931 2809420",
                "website": "http:\/\/pasteis.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.332631, 59.957696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 15",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327196, 60.053952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "FAB to go",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Банковский переулок, 4",
                "opening_hours": "+7 81244 88310 (доб.1192)",
                "contact_phone": "https:\/\/fabstore.ru\/fab-go",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324402, 59.930602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Легенды на Коломенской",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Коломенская улица, 23",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": "+7 (999) 243-31-92",
                "website": "https:\/\/www.instagram.com\/legends_na_kolomenskoy\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353193, 59.923608]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каледонский лес",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 22",
                "opening_hours": "Mo-We 14:00-22:00; Th-Fr 14:00-23:00; Sa 13:00-23:00; Su 13:00-22:30",
                "contact_phone": "+7 921 3356010",
                "website": "https:\/\/vk.com\/klles"
            },
            "geometry": {"type": "Point", "coordinates": [30.316551, 59.928023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Характер кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 14",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318105, 59.923013]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bob i List",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Большой проспект П.С., 10 лит Б",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292278, 59.954417]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обычные люди",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рыбацкая улица, 4",
                "opening_hours": "Mo-Fr 08:30-23:00 Sa-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29756, 59.958169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рада & K",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 36",
                "opening_hours": "11:00-22:00",
                "contact_phone": "+7 812 3851226",
                "website": "https:\/\/www.rada-k.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322489, 59.928379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hygge",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 14Д, подъезд 1",
                "opening_hours": "Mo-Th 10:00-23:00, Fr-Sa 10:00-01:00, Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353723, 59.939005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Orangery",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Потёмкинская улица, 2Г",
                "opening_hours": "Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00, Su 11:00-21:00",
                "contact_phone": "+7 (965) 050-92-92",
                "website": "https:\/\/vk.com\/orangeryspb"
            },
            "geometry": {"type": "Point", "coordinates": [30.368455, 59.948429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, переулок Матвеева, 5А",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292458, 59.926706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Печка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 124\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296357, 59.908457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pita's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 12",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354145, 59.94422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Golden",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295629, 59.918629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские пышки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 8-10Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314476, 59.915711]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленпышка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 8",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29447, 59.95985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь-Пышка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 7",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "http:\/\/king-pyshka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.924718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царь-Пышка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 12",
                "opening_hours": "http:\/\/king-pyshka.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3087, 59.957863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 16",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469615, 59.9045]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два гуся",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350777, 59.927567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Исфара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332011, 59.876373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 10А",
                "opening_hours": "Mo-Th,Su 10:00-23:00 open; Fr-Sa,PH 00:00-00:00 open",
                "contact_phone": "+7-812-944-44-90",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318114, 59.958593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 21Б",
                "opening_hours": "09:00-23:00",
                "contact_phone": "https:\/\/cafebrynza.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363173, 59.935187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "opening_hours": "09:00-23:00",
                "contact_phone": "+7-812-944-44-90;+7 812 498 74 88",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 86",
                "opening_hours": "24\/7",
                "contact_phone": "+7-812-944-44-90;",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.31921, 59.9033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 1\/77",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9444490",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.930962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 6\/13",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 812 9444490",
                "website": "www.cafebrynza.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.288029, 59.945666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арарат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Б",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": "+7 921 7816461",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472903, 59.865384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зимний сад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42",
                "opening_hours": "11:00-22:00",
                "contact_phone": "+7 812 3464019",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309984, 59.966863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Песнь моряка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 14\/16",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 6797872",
                "website": "http:\/\/spb.sailorsong.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.342036, 59.935561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Салхино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 25",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 2327891",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317575, 59.957016]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кобулети",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 16",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 6123330",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314646, 59.876003]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Саирме",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 29",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 812 2302511",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286673, 59.964237]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Georgia Small",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281499, 59.938243]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вази",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 8\/4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28767, 59.94006]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мимино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 92",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333493, 59.987717]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкали Хачапури",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 8",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 5756836",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348531, 59.921719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канарейка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бугский переулок, 6",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285748, 59.937738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мир шашлыка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 23В",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375947, 59.874612]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Iverioni",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 31к1",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/vk.com\/iverioni",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384347, 59.865786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chela",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 7",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364332, 59.929732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 45Б",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 9052061208",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321429, 59.927355]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эллада",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Двинская улица, 10к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247623, 59.908295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная \"Гемуани\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 7",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+79312701817",
                "website": "https:\/\/absolutbank.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.446052, 59.876233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ода",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307172, 59.917732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сулико",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 14",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317539, 59.959152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Георгия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Клинский проспект, 22",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322785, 59.914624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 111-113-115В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352744, 59.919477]}
        },
        {"type": "Feature",
            "properties": {
                "name": "David",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 52",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34863, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Georgia",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 75",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 3256500",
                "website": "https:\/\/www.restoclub.ru\/site\/all\/main\/28518\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.235702, 59.937932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито-Гврито",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 61",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31806, 59.909977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нканак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Смоленки, 29В",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": "+7 812 3500642",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252986, 59.948113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kazbegi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 9959070",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дом хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Перекопская улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270539, 59.902343]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Orera",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Потёмкинская улица, 7",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367234, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mziuri",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 27",
                "opening_hours": "10:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296806, 59.950727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Синица",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 6к1Б",
                "opening_hours": "Mo-Fr 10:00-20:00; Sa-Su 11:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427358, 59.897433]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито гврито",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vinograd",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 68к2",
                "opening_hours": "Mo-Th 11:00-23:00; Fr 11:00-03:00; Sa 12:00-03:00; Su 12:00-23:00",
                "contact_phone": "+7 812 4123242",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427493, 59.901012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аиси",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 32к1",
                "opening_hours": "+7 952 3913366",
                "contact_phone": "http:\/\/vk.com\/cafe.aisi",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331634, 60.054055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пхали-Хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 53к1",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 9240946",
                "website": "https:\/\/phali-hinkali.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.380843, 60.042923]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гудаури",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272938, 59.890961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Долче Вита",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Шаумяна, 1к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413578, 59.940163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоти",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энтузиастов",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480125, 59.948924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Багратиони",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 65",
                "opening_hours": "11:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30481, 59.922116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арагви",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 82",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292727, 59.919013]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 10\/9, подъезд 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341443, 59.923405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 17",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/chitogvrito.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27672, 59.912418]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кэт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Стремянная улица, 20",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 5713377",
                "website": "http:\/\/cafe-cat.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353624, 59.930859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Греми",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 57",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "+7 905 2133481",
                "website": "http:\/\/www.gremi-cafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.379424, 59.940501]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мари",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 11-13",
                "opening_hours": "+7 812 5411452",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351935, 59.956304]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Заходи генацвале",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 52",
                "opening_hours": "+79216424129",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44713, 59.873514]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амра",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 81к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.445791, 59.873961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури & Хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 12к1",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 4554677",
                "website": "www.kolkhida.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.457802, 59.917493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Генацвале",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Комиссара Смирнова, 5\/7",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 952 2641113",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352483, 59.962859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито гврито",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ленина, 45",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297426, 59.965106]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури и вино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 56",
                "opening_hours": "Su-Th 11:30-00:00; Fr-Sa 11:30-01:00",
                "contact_phone": "+7 812 2736797",
                "website": "http:\/\/hachapuriivino.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.355169, 59.943534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санатрело",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Радищева, 27",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/sanatrelo.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36382, 59.938897]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Orera",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 29\/28",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "https:\/\/cafe-750.business.site\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342467, 59.926841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 13",
                "opening_hours": "Mo-Sa 10:00-23:00; Su 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293401, 59.986092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грузия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 14к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345584, 59.971425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чача & Пури",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 19",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289098, 59.923234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ju Ju's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 28",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284957, 59.921768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мукузани",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 36",
                "opening_hours": "https:\/\/mukuzani.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357586, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 36",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359302, 59.938933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Медея",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мичуринская улица, 19",
                "opening_hours": "Mo-Sa 11:00-23:00; Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326998, 59.956444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 2к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.127258, 59.774272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сиртаки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 102",
                "opening_hours": "Mo-Th 10:00-01:00, Fr 10:00-02:00, Sa 09:00-02:00, Su 08:00-02:00",
                "contact_phone": "+7 911 1021212",
                "website": "https:\/\/www.akrotaki.ru\/sirtaki\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.35622, 59.931936]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сиртаки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 70",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345737, 59.933244]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337365, 59.856704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дым",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 96",
                "opening_hours": "Mo-Fr 11:00-22:00; Sa, Su 12:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29032, 59.918075]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завтра будет кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 2",
                "opening_hours": "Mo-Su 08:30-22:00",
                "contact_phone": "+7 812 9414143",
                "website": "http:\/\/www.zavtrabudetkofe.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.328022, 59.937215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Biergarten",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Крылова, 4",
                "opening_hours": "Mo-Th, Su, PH 11:00-23:00 open, Fr-Sa 11:00-01:00 open",
                "contact_phone": "+7 812 2440898",
                "website": "http:\/\/www.beergarden-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.300381, 59.985858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Doggy Dog",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 10\/51",
                "opening_hours": "Mo-Th 09:00-23:00; Fr-Sa 09:00-02:00; Su 09:00-23:00",
                "contact_phone": "+7 812 6122118",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359912, 59.93548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баскин Роббинс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 19",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/baskinrobbins.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326199, 59.936237]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венеция",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 107",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su,PH 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368994, 59.929263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баскин Роббинс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 32к1",
                "opening_hours": "http:\/\/baskinrobbins.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331634, 60.054055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ice",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗВ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27027, 59.801698]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусные штучки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Красного Курсанта, 1",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": "+7 812 2356067",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293707, 59.954917]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Индиш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 89Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429155, 59.899162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tandoor & grill",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 49",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9211038",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285442, 59.924352]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tandoor & Grill",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355681, 59.940046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "7 специй",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 5С",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 12:00-22:00",
                "contact_phone": "+7 (911) 037-22-62",
                "website": "http:\/\/7spicesstreetfood.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318303, 59.969587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Jiva Burgers",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белинского, 9",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": "+7 931 5782678",
                "website": "https:\/\/jivaburgers.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.346599, 59.93809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кашмир",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 7",
                "opening_hours": "Mo 13:00+; Tu-Sa 11:00+",
                "contact_phone": "http:\/\/cafe-kashmir.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346761, 59.926161]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vaffel & Wine",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 41",
                "opening_hours": "Su-Th 10:00-00:00; Fr-Sa 10:00+",
                "contact_phone": "+7 812 9602641",
                "website": "http:\/\/vaffelspb.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321177, 59.928816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Династия - Кофе с собой",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 78",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39828, 60.014985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подвалъ бродячей собаки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 4",
                "opening_hours": "Mo-Su 11:30-23:30",
                "contact_phone": "+7 812 3128047",
                "website": "http:\/\/www.vsobaka.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.328507, 59.937175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сеновал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 82",
                "opening_hours": "11:30-23:30",
                "contact_phone": "+7 812 5711086",
                "website": "https:\/\/www.senoval-traktir.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.303364, 59.930223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Рижской",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Таллинская улица, 7Л",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 4452323",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417297, 59.923279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Матросова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Александра Матросова, 14",
                "opening_hours": "Mo-Fr 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33838, 59.980056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фортеция",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 7",
                "opening_hours": "+7 812 2339468",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327906, 59.954926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Casual",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 9",
                "opening_hours": "Mo-Fr 10:00-18:00; Sa 10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31567, 59.965124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд-кафе Нева",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Университетская набережная, 5",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302932, 59.940862]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе на Цветочной",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Цветочная улица, 6М",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": "+7 812 3257203",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329873, 59.892735]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Генерала Симоняка, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.203399, 59.830133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 4",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332397, 59.958359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Шателена, 1",
                "opening_hours": "Mo-Sa 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365293, 60.001802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Dnevnik",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 55",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": "+7 921 4336685",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361161, 59.943323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Any Day",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Херсонская улица, 12-14",
                "opening_hours": "Mo-Su 08:00-18:00",
                "contact_phone": "+7 812 3855868",
                "website": "anydaycafe.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.381005, 59.928654]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два гуся",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 19\/15",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355672, 59.93855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Giros",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 50",
                "opening_hours": "Mo-Fr 11:00-22:00; Sa-Su 12:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325947, 59.926048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Easy Hummus",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240715, 59.924934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сыто-Пьяно",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318392, 59.93062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Елагином",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26514, 59.980114]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Caffe Italia",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Бакунина, 5",
                "opening_hours": "Mo-Fr 09:00-24:00; Sa-Su 10:00-24:00",
                "contact_phone": "+7 812 9056474",
                "website": "https:\/\/vk.com\/caffeitaliaspb"
            },
            "geometry": {"type": "Point", "coordinates": [30.374995, 59.929799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АНТОНИО",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 54А",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_phone": "+7 911 0881829",
                "website": "http:\/\/vk.com\/pasta_antonio"
            },
            "geometry": {"type": "Point", "coordinates": [30.152285, 59.790793]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pasta Fresca",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360712, 59.936832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ItSu",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.830676]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Персе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 41",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324492, 60.012503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gusti Giusti",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 101",
                "opening_hours": "+7 (981) 943-69-95",
                "contact_phone": "www.gustigiusti.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289503, 59.91908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "LaCelletta Caffè",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, наб. реки Фонтанки, 30",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 921 7880069",
                "website": "http:\/\/www.lacelletta.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.343554, 59.938766]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Italy",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 58",
                "opening_hours": "https:\/\/italy-group.ru\/restaurants\/italy-bolshoy\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303534, 59.96107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филадельфия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 90-92Б",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9235959",
                "website": "http:\/\/delmar-spb.ru\/restaurants\/philadelphia-nevsky\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353067, 59.93236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Yumm",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 19к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312706, 59.874941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У дома",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Морская набережная, 39к4",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228255, 59.959854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ronny",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "Mo, Su 12:00-22:00; Fr, Sa 12:00-00:00",
                "contact_phone": "+7 (812) 907-27-08",
                "website": "http:\/\/ronnybistro.ru\/menu\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Такояки-Мисэ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Лизы Чайкиной, 19",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_phone": "+7 965 0959253",
                "website": "http:\/\/такояки.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.303211, 59.954935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОМС",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗД",
                "opening_hours": "Mo-Fr 08:00-19:00; Sa 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275677, 59.799942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ярумэн",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 9",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+7 812 3129771",
                "website": "https:\/\/www.yarumen.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.313074, 59.935944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ярумэн",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 88",
                "opening_hours": "12:00-23:00",
                "contact_phone": "https:\/\/www.yarumen.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351801, 59.932464]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рюмочка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 12",
                "opening_hours": "Mo-Su 06:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424888, 59.896567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чебуречная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281139, 59.941294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 11",
                "opening_hours": "24\/7",
                "contact_phone": "+7 904 6476488",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364961, 59.929488]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дилижанс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заставская улица, 28",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324438, 59.888677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chicken Inn",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курская улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350094, 59.911755]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оранж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 138",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35039, 59.913956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлык Хауз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 128",
                "opening_hours": "11:00-02:00",
                "contact_phone": "+7 812 7175715",
                "website": "http:\/\/shashlik-house.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.366443, 59.930669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлык Хауз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 1\/8",
                "opening_hours": "11:00-02:00",
                "contact_phone": "http:\/\/shashlik-house.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368348, 59.932144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Луна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Весёлый Посёлок",
                "opening_hours": "+7 931 2628636",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475337, 59.898164]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канари",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Есенина, 5Б",
                "opening_hours": "Mo-Su 10:00-07:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331481, 60.037032]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дили",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Трефолева, 4к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279567, 59.886722]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Морская улица, 1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.159939, 59.990863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 79",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 944-44-90",
                "website": "https:\/\/cafebrynza.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.331202, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито Тандыр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лагманная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 114Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321006, 59.891593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7В",
                "opening_hours": "11:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320333, 59.932563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Café Omo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284993, 59.946734]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бейрут",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Стремянная улица",
                "opening_hours": "Su-Th 12:00-00:00; Fr-Sa 12:00-01:00",
                "contact_phone": "+7 960 2345111",
                "website": "https:\/\/www.beirutcafespb.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.351495, 59.930944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Печора",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Белградская улица, 4\/2",
                "opening_hours": "Mo-Su 11:00-03:00",
                "contact_phone": "+7 962 6904400",
                "website": "http:\/\/cafepechora.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.357038, 59.873731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафешечка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 249",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345557, 59.908331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ням-Ням",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 275к1",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341578, 59.904266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бегемот",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 97",
                "opening_hours": "12:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432505, 59.897095]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Минутка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Славы, 30к1",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+78123600698",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387419, 59.858349]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Посидим!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Невзоровой, 6",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 5600860",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415428, 59.890798]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ольминского, 14",
                "opening_hours": "Mo-Fr 11:00-24:00; Sa-Su 12:00-24:00",
                "contact_phone": "+7 812 3651757",
                "website": "http:\/\/kafe-green.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.421744, 59.898173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "оАзис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Политрука Пасечника, 5к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.108887, 59.780907]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лента",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Руставели, 61",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.43494, 60.031445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чито Гврито Тандыр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297641, 59.965264]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Заставская улица, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325157, 59.88841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sibaristica Coffee Roasters",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 199-201К",
                "opening_hours": "Mo-Fr 09:30-19:00; Sa 11:00-18:00",
                "contact_phone": "+7 812 3094216",
                "website": "https:\/\/sibaristica.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.283861, 59.910203]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каньон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 37",
                "opening_hours": "Mo-Th 09:30-23:00; Fr 09:00-06:00; Sa 11:30-06:00; Su 11:30-23:00",
                "contact_phone": "+7 812 5601543",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421384, 59.887349]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фарш Ball",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 81",
                "opening_hours": "Su-Th 10:00-23:00; Fr-Sa 10:00-00:00",
                "contact_phone": "+7 812 9618207",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314027, 59.96689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Паштет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 46",
                "opening_hours": "Su-Th 11:30-23:30, Fr-Sa 11:30-01:00",
                "contact_phone": "http:\/\/pashtetspb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310909, 59.928681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "sea food",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Парадная улица, 3к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367261, 59.942079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "San Diego",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "2Taco & Co",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 13",
                "opening_hours": "Mo-We 14:00-23:30, Th-Fr 14:00-03:00, Sa 12:00-03:00, Su 12:00-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352421, 59.935773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vaffel",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 55",
                "opening_hours": "Mo-Fr 08:30-21:00; Sa, Su 10:00-21:00",
                "contact_phone": "+7 812 9249820",
                "website": "vaffelspb.com"
            },
            "geometry": {"type": "Point", "coordinates": [30.361161, 59.943323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жемчужина Востока",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 11-я линия Васильевского острова, 18В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27521, 59.938392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточная кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Смоленский проспект, 26",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41268, 59.897248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточная ночь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 33",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376468, 59.859181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Омега",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 52",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306184, 59.971033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Карфаген",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 127",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266739, 59.85134]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский район, Авиационная улица, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324788, 59.856171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточная сказка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 78",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215769, 59.832937]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восток",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Осипенко, 2",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483512, 59.94568]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Московский проспект, 68",
                "opening_hours": "Mo-Su 00:00-06:30, 08:30-20:00, 20:45-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318967, 59.907171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Узбекская кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 141\/36",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33644, 60.053301]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 8",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421959, 59.897551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коктебель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Типанова, 8",
                "opening_hours": "Mo-Su 11:00-02:00",
                "contact_phone": "+7 921 3416472",
                "website": "http:\/\/vk.com\/koktebel_cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.331903, 59.851878]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Джаз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 2",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 812 9965395",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419911, 59.898995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Форжинг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 904 6343300",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349753, 59.927419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 49",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261942, 59.94362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Осетинские пироги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348378, 59.985336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жар-Птица",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 89\/20",
                "opening_hours": "http:\/\/www.pie-delivery.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355987, 59.923861]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клёво",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 113к1",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325633, 60.037836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рыжий кот",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 15",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328436, 59.957633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 26\/16",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 2430026",
                "website": "http:\/\/www.lindfors.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.411916, 59.929826]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Яблочный пирог",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 14",
                "opening_hours": "09:00-22:00",
                "contact_phone": "+7 812 5791100",
                "website": "http:\/\/pekarnya.balzak.spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354657, 59.935588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аллегро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 25\/2",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/pizzaallegro.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369767, 59.931089]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 3-я Красноармейская улица, 2\/31",
                "opening_hours": "http:\/\/streetbar.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317153, 59.914475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 4",
                "opening_hours": "http:\/\/streetbar.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320719, 59.916545]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Берёза и базилик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 37\/18",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 965 0076776; +7 905 2056776",
                "website": "https:\/\/berezaibazilik.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.342135, 59.935268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "LaPause",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 3",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268635, 60.002202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 8",
                "opening_hours": "Mo-Fr 11:00-23:30; Sa-Su 12:00-23:30",
                "contact_phone": "http:\/\/streetbar.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318033, 59.924366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Liman",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 33",
                "opening_hours": "Mo-Fr 10:00-00:00; Sa-Su 11:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360811, 59.938428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уно моменто",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Крыленко, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48105, 59.897975]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Форно Браво",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 1-3",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": "+7 812 2344350",
                "website": "http:\/\/www.beercard.ru\/forno-bravo"
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.965277]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Искра",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": "Mo-Su 10:00-02:00",
                "contact_phone": "+7 999 5280798",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rimini",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 46Б",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 2728618",
                "website": "http:\/\/cafe-rimini.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362778, 59.94536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лиман",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 13",
                "opening_hours": "+7 (812) 3858700",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305564, 59.990552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рис на бис!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 61",
                "opening_hours": "Mo-Fr 11:00-20:00; Sa-Su 13:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342341, 59.973325]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Arancino Pizza",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 5",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-24:00",
                "contact_phone": "http:\/\/www.arancinopizza.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325211, 59.937468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Паштейш & Кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гатчинская улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299564, 59.96226]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Slurp",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 63",
                "opening_hours": "12:00-23:00",
                "contact_phone": "+78129583469",
                "website": "ramenslurp.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.308888, 59.963579]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mashita",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 15",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/mashita-ramen.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340653, 59.925592]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mashita",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 60",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "http:\/\/mashita-ramen.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33917, 59.93406]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Укроп",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Конюшенная улица, 14",
                "opening_hours": "09:00-23:00",
                "contact_phone": "+7 812 9463035",
                "website": "http:\/\/www.cafe-ukrop.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325381, 59.936305]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лепим - пельмени & вареники",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 12",
                "opening_hours": "+7 812 9877773",
                "contact_phone": "https:\/\/vk.com\/lepimsp",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292952, 59.954273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вояж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 18А",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": "+7 812 2354543",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294713, 59.955309]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Еда как дома",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 26-28",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34721, 59.919396]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брандмейстер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заставская улица, 21к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322839, 59.888961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Момо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 11:00-22:00",
                "contact_phone": "+7 812 3851140",
                "website": "http:\/\/momo.rest\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309984, 59.966863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкий мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 2",
                "opening_hours": "http:\/\/t-most.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319551, 59.958178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой гвоздь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26974, 59.945864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трапезная паломника",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 100Г",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32274, 59.89746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мелисса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 91",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318734, 59.895393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "От ложки до поварешки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 91",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318734, 59.895393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Борщ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мытнинская улица, 4\/48",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374312, 59.931116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кукуруза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 199-201Ж",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284759, 59.909797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельменная №1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 7",
                "opening_hours": "Mo-Fr 10:00-20:00; Sa-Su 11:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.968151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Batista",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рында",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петровская коса, 9к2",
                "opening_hours": "Mo-Sa 07:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241937, 59.964079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкус Розмарина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334409, 59.959269]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкус Rозмарина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279864, 59.961116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мяса мне!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 7к2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295198, 60.003921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пластилин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 39",
                "opening_hours": "11:00-23:59",
                "contact_phone": "+7 812 4126705",
                "website": "http:\/\/www.germes-catering.ru\/cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.406993, 59.911985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венеция",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Софийская улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39757, 59.88115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коммуналка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Татарский переулок, 1",
                "opening_hours": "+7 812 4255649",
                "contact_phone": "http:\/\/artcafe-kommunalka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305834, 59.953209]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фиорд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Туристская улица, 30к1",
                "opening_hours": "11:00-24:00",
                "contact_phone": "+7 812 6202044",
                "website": "http:\/\/www.fiord-cafe.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.21028, 60.005036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмеш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399447, 59.879561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Космос",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 1-3И",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363757, 59.958805]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Edu Berry",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 1-3И",
                "opening_hours": "Mo-Su 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363757, 59.958805]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкий мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 33\/8",
                "opening_hours": "http:\/\/t-most.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334373, 59.957638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маяк",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 20",
                "opening_hours": "Mo-Su,PH 12:00-23:00",
                "contact_phone": "https:\/\/cafe-699.business.site\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35481, 59.936859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лепим и варим",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 4",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 921 9999010",
                "website": "http:\/\/lepimivarim.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.353975, 59.932847]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барвинок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Мира, 7",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315644, 59.959246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "TerritoriЯ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рентгена, 5А",
                "opening_hours": "Mo-Fr 10:00-12:00 open \"breakfast\", Mo-Fr 12:00-18:00 open; PH off",
                "contact_phone": "+7 812 9319275",
                "website": "https:\/\/clubterritoria.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320117, 59.963224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лукоморье",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рентгена, 9Б",
                "opening_hours": "Mo-Fr 10:00-18:00; PH off",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321941, 59.96353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Точка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Розенштейна, 34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28829, 59.904536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Встреча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413955, 59.893037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старое кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Смолячкова, 14к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345584, 59.971425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 7к2",
                "opening_hours": "11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356759, 59.832561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Земледельческая улица, 5",
                "opening_hours": "10:00-17:00",
                "contact_phone": "http:\/\/www.vkusno-i-nedorogo.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32407, 59.989738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусный Обед",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная реки Смоленки, 14",
                "opening_hours": "10:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268051, 59.947991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 29П",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37663, 60.006894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградская столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Куйбышева, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331023, 59.95634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Новая столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Мира, 15к1",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318653, 59.960088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Винегрет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 71",
                "opening_hours": "+7 812 4333000",
                "contact_phone": "http:\/\/www.vinegreat.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337131, 59.840239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лепим и варим",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Маяковского, 4",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 921 9999010",
                "website": "lepimivarim.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.354047, 59.933068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квартирка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 1\/25",
                "opening_hours": "12:00-01:00",
                "contact_phone": "+7 812 9006550",
                "website": "http:\/\/reca.rest\/restaurants\/Kvartirkasadovaya"
            },
            "geometry": {"type": "Point", "coordinates": [30.337482, 59.93529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградское",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3Д",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318788, 59.949501]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 4",
                "opening_hours": "Mo-Fr 08:00-19:00",
                "contact_phone": "http:\/\/tarelkas.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326199, 59.981231]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кирочный Двор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 36",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9071037",
                "website": "https:\/\/banket.life\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.364395, 59.944048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sofa & Par",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Краснопутиловская улица, 14\/12",
                "opening_hours": "Su-Fr 12:30-03:00; Fr-Sa, PH 12:30-04:00",
                "contact_phone": "+7 812 9266367",
                "website": "http:\/\/sofapar.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.265751, 59.872602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербург",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 7",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "http:\/\/www.barpeterburg.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282783, 59.936945]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трактир на Боровой",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 59-61",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339503, 59.909743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мит Стар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 55",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306795, 59.971672]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 15",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 812 2411547",
                "website": "http:\/\/spb.lindfors.ru\/restorany\/ul-kirochnaya-d-17"
            },
            "geometry": {"type": "Point", "coordinates": [30.357164, 59.943715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алые Паруса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкская набережная, 3к2",
                "opening_hours": "09:00-23:00",
                "contact_phone": "+7 812 2333430",
                "website": "https:\/\/parkking.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321213, 59.953286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Первая пельменная на Невском",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 94",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "+7 812 7186512",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35357, 59.932261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Cantine",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, площадь Карла Фаберже, 8Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436045, 59.935574]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ржавая подкова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 99",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282127, 59.919044]}
        },
        {"type": "Feature",
            "properties": {
                "name": "L. Brik",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 16",
                "opening_hours": "+7 812 7646303",
                "contact_phone": "http:\/\/lbrik-cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356939, 59.928492]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Изба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 67",
                "opening_hours": "11:00+",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304028, 59.921926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На всю Ивановскую",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 75",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444103, 59.875989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Конфети",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Новосёлов, 11Л",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": "+7 812 4095333",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.458314, 59.882451]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Огниво",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 6",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356436, 59.955737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Canteen",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 56",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269228, 59.940172]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Всеволода Вишневского, 12А",
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298872, 59.96829]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусняшка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Ивана Фомина, 7к3",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348225, 60.047429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У друзей",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Моховая улица, 31Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344857, 59.941087]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маэстро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 44\/16Б",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9703057",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307972, 59.969205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "уральский мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Уральская улица, 4",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 911 0181853",
                "website": "http:\/\/уральскиймост.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.266048, 59.950335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дикмаn’s deli",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Адмиралтейского канала, 2Т",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 911 7072323",
                "website": "http:\/\/dikmansdeli.ru\/restaurants\/new-holland"
            },
            "geometry": {"type": "Point", "coordinates": [30.287392, 59.92938]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 12литА",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+79119430900",
                "website": "http:\/\/greenboxes.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.292566, 59.954088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 27",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "https:\/\/ginza.ru\/spb\/restaurant\/pyshechka_cher",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358188, 59.944999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Крыленко, 26",
                "opening_hours": "+7 812 9413077",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.485389, 59.900114]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясорубка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 5",
                "opening_hours": "Su-Th 10:00-23:00, Fr-Sa 10:00-06:00",
                "contact_phone": "+7 812 9285518",
                "website": "https:\/\/www.myasorubka01.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.327654, 59.939762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr. Chan",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317764, 59.96866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Aziana",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский просп., 19",
                "opening_hours": "http:\/\/www.azianacafe.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346932, 59.928519]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Выборгский район, муниципальный округ Сергиевское",
                "opening_hours": "Mo-Th 11:00-01:00; Fr 11:00-02:00; Sa 12:00-02:00; Su 12:00-01:00",
                "contact_phone": "+7 812 2447331",
                "website": "http:\/\/wasabico.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354747, 60.063752]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Na Kuchne",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Двинская улица, 3",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 2092002",
                "website": "https:\/\/nakuhne-spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.255977, 59.909391]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Этаж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373055, 60.0457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обухоffка 120",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Б",
                "opening_hours": "Mo-Su 1:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472903, 59.865384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Don Garcia",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 2",
                "opening_hours": "https:\/\/vk.com\/el_taquito",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246231, 60.009588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тандыр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "Mo-Su,PH 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пинч!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белинского, 5",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": "+7 921 9058911",
                "website": "http:\/\/pinch.wtf\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.345818, 59.938072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 41",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321177, 59.928816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 6",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-21:00",
                "contact_phone": "+7 812 4564800",
                "website": "https:\/\/spb.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.446672, 59.875817]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 14",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422642, 59.895777]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайный Дом",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": "Mo,Tu,Th 11:00-02:00, We 13:00-02:00, Fr,Sa 11:00-05:00, Su 11:00-03:00",
                "contact_phone": "http:\/\/teahousesmall.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сытнинская площадь, 3",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/chainikoff.ru\/coffeemap_27_38.html",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311152, 59.957169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЧБ Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 27",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+79817992020",
                "website": "https:\/\/taplink.cc\/cafe_bw"
            },
            "geometry": {"type": "Point", "coordinates": [30.320566, 59.941673]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lindfors",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 128Б",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "+7 812 3880778",
                "website": "http:\/\/www.lindfors.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.320297, 59.889029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Black Unicorn",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкская улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311332, 59.960201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Муслим",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 10",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333385, 60.003115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Навруз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Московский проспект, 133Б",
                "opening_hours": "Mo-Sa 11:00-00:00; Su 11:00-23:00",
                "contact_phone": "+7 812 3161416",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323854, 59.885359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотая рыбка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 132",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291488, 59.908529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Райхан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 9к2",
                "opening_hours": "http:\/\/rayhanspb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273746, 59.904681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белый сад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сортировочная-Московская улица, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428661, 59.867222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эгриси",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 26-28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373333, 59.937328]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Узбекская Кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к2Ж",
                "opening_hours": "09:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253498, 59.988694]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хорезм",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 25к1ЭА",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477816, 59.904022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкий мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 27",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279927, 59.942376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкий мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Галерная улица, 19",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 931 2541020",
                "website": "t-most.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.296276, 59.934082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дед Хо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311637, 59.965764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pho'n'Roll",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 22",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": "+7 812 5048845",
                "website": "https:\/\/www.phonrollcafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326477, 59.933879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pho'n'Roll",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 97",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": "+7 812 5048845",
                "website": "https:\/\/www.phonrollcafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322075, 59.92387]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нячанг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рузовская улица, 19",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "http:\/\/cafe-nhatrang.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328992, 59.915977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pho'n'Roll",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 27",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": "+7 812 5048845",
                "website": "https:\/\/www.phonrollcafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.355367, 59.935746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pho'n'Roll",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 34",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": "+7 812 5048845",
                "website": "https:\/\/www.phonrollcafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.354972, 59.939947]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Happyfrukt",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Totoro",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240715, 59.924934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "FD Антикафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 11\/2",
                "opening_hours": "11:00+",
                "contact_phone": "+7 911 0281444",
                "website": "http:\/\/fdfree.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.936404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "cafe+",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-й Муринский проспект, 17",
                "opening_hours": "Mo-Fr 12:00-18:00",
                "contact_phone": "+7 921 9614886",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344452, 59.989297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr. O",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 3-5-7-9",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314341, 59.916329]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завтрак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 42",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa, Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28634, 59.94472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штрогель & пироги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 44",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286161, 59.944896]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я линия Васильевского острова, 50",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 812 3287860",
                "website": "https:\/\/www.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.284957, 59.946004]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каспий",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Никитинская улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294102, 60.015709]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лепка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Советская улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365096, 59.931062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vaffel",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 1-я Советская улица, 12",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/vaffelspb.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365096, 59.931062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тепло",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 10-я Красноармейская улица, 11",
                "opening_hours": "Mo-Fr 08:30-20:00; Sa 10:00-18:00",
                "contact_phone": "https:\/\/vk.com\/teplocoffee",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296303, 59.912991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рохат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 10-я Советская улица, 15\/27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381041, 59.936282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сакартвело",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 12-я линия Васильевского острова, 13",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274267, 59.938482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кувшин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 14-я линия Васильевского острова, 41",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269093, 59.940037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МИ8",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 14-я линия Васильевского острова, 9",
                "opening_hours": "+7 812 3210894",
                "contact_phone": "http:\/\/www.cafemi8.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274276, 59.935507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У подворья",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 15-я линия Васильевского острова, 2\/27",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275237, 59.933266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Siberika",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 19-я линия Васильевского острова, 2",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 904 5580274",
                "website": "https:\/\/cafesiberika.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.271401, 59.931418]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе на Муринском",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 19",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 981 9586636",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33953, 60.001419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лагуна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-я Комсомольская улица, 27к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.142547, 59.836857]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Nora",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-я Красноармейская улица, 9\/3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313506, 59.915427]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Камея",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 2-я Советская улица, 21",
                "opening_hours": "https:\/\/cameo.spb.ru\/about\/firmennyie-magazinyi\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368977, 59.931368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Диета",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 22-я линия Васильевского острова, 3к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265455, 59.931513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У биржи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 27-я линия Васильевского острова",
                "opening_hours": "Mo-Th 10:30-03:00; Fr-Su 10:00-05:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259616, 59.928956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Keep Calm Lounge",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 3-я Красноармейская улица, 13",
                "opening_hours": "http:\/\/keepcalmspb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311152, 59.914692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Valentina",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 3-я Красноармейская улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314521, 59.91443]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Деметра",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 3-я линия Васильевского острова, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28449, 59.943422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Другие Миры",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 3-я линия Васильевского острова, 8",
                "opening_hours": "11:00-22:00",
                "contact_phone": "+7 904 5148366",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286682, 59.941547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колхи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 3-я Советская улица, 11",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 2717031",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370297, 59.9319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "El Grano",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 4-я Красноармейская улица, 2\/35",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317395, 59.913749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый Тбилиси",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285307, 59.940627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cats",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 4-я Советская улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368725, 59.93401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "I лайк",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 4-я Советская улица, 7",
                "opening_hours": "10:00-21:00",
                "contact_phone": "http:\/\/shoyspb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367153, 59.934186]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дева",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 5-й Предпортовый проезд, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310406, 59.840704]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гараж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 5-й Предпортовый проезд, 14к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312985, 59.832028]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ротонда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 5-я линия Васильевского острова, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281229, 59.943368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русское Солнце",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 5-я Советская улица, 24\/13",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371132, 59.934321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-й Верхний переулок, 1В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372884, 60.074554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 21",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.941515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Complimenti",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.941515]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квартирка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280268, 59.942043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лидо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 27",
                "opening_hours": "We-Fr 11:00-19:00; Sa-Su 12:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279927, 59.942376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белочка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 29",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279388, 59.942845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наше",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 6-я линия Васильевского острова, 59Бк1",
                "opening_hours": "Mo-Fr 08:30-17:00; Sa, Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272749, 59.948559]}
        },
        {"type": "Feature",
            "properties": {
                "name": "21 Верста",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 1",
                "opening_hours": "http:\/\/www.21verst.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317476, 59.911863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китайская кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 28",
                "opening_hours": "12:00-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309239, 59.911448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ал Сумах",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 24",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282172, 59.939658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Укроп",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 30",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281112, 59.94051]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Id Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица, 15-17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301244, 59.914565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ozzi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Красноармейская улица, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299752, 59.914561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Joly Woo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 25",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28016, 59.939694]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дамаск",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я линия Васильевского острова, 55",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274905, 59.943755]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Глазурь и Кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 2",
                "opening_hours": "Mo-Fr 08:30-22:00; Sa-Su 10:00-22:00",
                "contact_phone": "+7 812 9045499",
                "website": "https:\/\/m.vk.com\/glazeandcoffee"
            },
            "geometry": {"type": "Point", "coordinates": [30.370045, 59.938148]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Афрасиаб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 47",
                "opening_hours": "https:\/\/afrasiab.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379622, 59.934353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ламаджо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 8-я Советская улица, 9-13",
                "opening_hours": "09:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372022, 59.936994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Учкудук",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 16",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.279163, 59.939208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "But first coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 34",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277097, 59.941087]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Family Friends",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 54",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272695, 59.944846]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sparrow Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, 9-я линия Васильевского острова, 72",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269874, 59.947297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Neste",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Авангардная улица, 36",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.18572, 59.826234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe 22",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319875, 59.931129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прана бар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 31",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "https:\/\/pranabar.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312733, 59.929475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bunker",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 35",
                "opening_hours": "https:\/\/www.bunker-coffee.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311125, 59.929078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обед-сервис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312751, 59.929182]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шумли",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 38",
                "opening_hours": "https:\/\/www.schumli.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312751, 59.929182]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотая середина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 6",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322605, 59.933054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SmokeTime",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 7В",
                "opening_hours": "Su-Th 12:00-00:00, Fr-Sa 12:00-06:00",
                "contact_phone": "http:\/\/smoketime.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320333, 59.932563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барака",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 8-10",
                "opening_hours": "09:00-23:00",
                "contact_phone": "http:\/\/samadevacafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322049, 59.932509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gills",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 8-10",
                "opening_hours": "Su-Th 12:00-23:00, Fr-Sa 12:00-01:00",
                "contact_phone": "http:\/\/gillscafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322049, 59.932509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Горный орел",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Александровский парк, 1аЖ",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308933, 59.953448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крокодил",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Александровский парк, 1АЖ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306795, 59.951921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Планетарий",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311655, 59.955111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мантра Бар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Александровский парк, 4к3",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314503, 59.955849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вояж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Алтайская улица, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322956, 59.849076]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Миндаль",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Английская набережная, 26",
                "opening_hours": "+7 812 3123238;+7 812 9541039",
                "contact_phone": "http:\/\/mindalcafe.ru\/restorany\/mindal_na_anglijskoj\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294327, 59.934109]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Орли",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Английский проспект, 48",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288326, 59.919662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Али",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 21",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329091, 59.926715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кемер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329091, 59.926715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Никала",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325417, 59.92855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Халол",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 7",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325794, 59.928356]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трапеза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Аптекарский переулок, 4",
                "opening_hours": "+7 921 9117112",
                "contact_phone": "https:\/\/www.cafetrapeza.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328202, 59.942849]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Остров",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Аптекарский проспект, 8",
                "opening_hours": "Mo-Su 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319093, 59.971037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Морошка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Артиллерийская ул., 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351693, 59.941615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дабл Капс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Артиллерийский переулок, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34889, 59.941272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Авто-ретро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Афонская улица, 1к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306014, 60.016231]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гвидо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Смена",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Басков переулок, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359841, 59.939631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Юлианна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Басков переулок, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359841, 59.939631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pitcher Кофейня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бассейная улица, 12",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa 09:00-22:00; Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 59.86631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ялта",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Белградская улица, 40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368698, 59.850477]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказочная страна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Белградская улица, 52к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370818, 59.84683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У карьера",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Белевский проспект, 22А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430673, 59.871942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grand Cafe Грядка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Белоостровская улица, 17к2",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321258, 59.986821]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гагра",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Библиотечный переулок, 6",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296914, 59.90737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Карман",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Библиотечный переулок, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296914, 59.90737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ирина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323989, 59.876228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Da!bro",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 33",
                "opening_hours": "Mo-Sa 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311161, 59.875425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крым",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Благодатная улица, 35",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9220607",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314637, 59.875452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Витамин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263972, 60.001586]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gости",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291084, 59.999354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lion",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бокситогорская улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.447561, 59.952069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дилижанс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Болотная улица, 1",
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351998, 59.996744]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 11\/1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294021, 59.960507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чешский двор;Рандеву",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 14",
                "opening_hours": "Mo-Sa 10:00-20:00;Su 10:00-19:00;24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291066, 59.961629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "3 ступеньки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 15",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29147, 59.961899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буфетъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 21",
                "opening_hours": "Mo-Su 09:00-23:50",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289673, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "KЛEVER",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 21",
                "opening_hours": "Tu-Fr 12:00-02:00; Sa-Su 12:00-05:00",
                "contact_phone": "http:\/\/vk.com\/klevercafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289673, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Enjoy Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 8к2",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293213, 59.960282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барслона",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 1",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324151, 59.941029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Stories",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 1",
                "opening_hours": "http:\/\/stories.ru.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324151, 59.941029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фруктовая лавка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 15",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 6557617",
                "website": "http:\/\/fruktlove.ru\/boljshaya_konyushennaya_ul._15"
            },
            "geometry": {"type": "Point", "coordinates": [30.323234, 59.939]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Саквояж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 17",
                "opening_hours": "Mo-Th 11:00-01:00; Fr-Sa 12:00-02:00; Su 12:00-01:00",
                "contact_phone": "+7 812 4959796",
                "website": "http:\/\/саквояжспб.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.323126, 59.938784]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарсон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 3",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/www.garcon.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324016, 59.940587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vikingi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321914, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хельсинкикафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 8",
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.93795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Под лестницей",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "EclairChic",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ikrafood",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ko[h] Chang",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Saviv",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Конюшенная улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323665, 59.939848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ник-фуд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 16к5",
                "opening_hours": "Mo-Fr 11:00-17:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321887, 59.962597]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Иерусалим",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 9",
                "opening_hours": "24\/7",
                "contact_phone": "+7 950 0037647",
                "website": "https:\/\/instagram.com\/kafeierusalim"
            },
            "geometry": {"type": "Point", "coordinates": [30.31453, 59.960773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 10Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318087, 59.936864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Coffee & Breakfast",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 14",
                "opening_hours": "https:\/\/www.coffee-breakfast.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317674, 59.935958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Drive Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 15",
                "opening_hours": "Mo-Fr 08:00-19:00",
                "contact_phone": "+7 495 419-21-85",
                "website": "https:\/\/hlebnasushny.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315284, 59.935002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кинг Понг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 16",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "http:\/\/www.kingpong.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317009, 59.935295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арт-Буфет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 38",
                "opening_hours": "+7 921 9970738",
                "contact_phone": "http:\/\/artbufet.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311323, 59.933072]}
        },
        {"type": "Feature",
            "properties": {
                "name": "WöD",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 45",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30543, 59.931995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rock Star Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 6",
                "opening_hours": "+7 812 3098883",
                "contact_phone": "https:\/\/rockstarcafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31806, 59.937206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 9",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/greenboxes.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317009, 59.936066]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штрогель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 1-3",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/strogell.ru\/kafe\/kafe-shtrogel-na-bolshoj-moskovskoj",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347219, 59.92731]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 14\/1",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346555, 59.92552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "64 зерна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 9",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/64zerna.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346357, 59.925931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Скала",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Озёрная улица, 106к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298845, 60.046149]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмень",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305861, 59.923942]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Zen",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 29",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305187, 59.922783]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Афрасиаб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 31",
                "opening_hours": "https:\/\/afrasiab.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30516, 59.922422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pastila",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Подьяческая улица, 39",
                "opening_hours": "Mo-Th 13:00-02:00, Fr 13:00-05:00, Sa 14:00-05:00, Su 14:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304972, 59.920104]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Палома",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пороховская улица, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41356, 59.953344]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу шашлык",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30613, 59.960016]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Денисов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 34",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305187, 59.960025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The wall bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 42\/16",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_phone": "+7 812 9811601",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306687, 59.960949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аридж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 45",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312104, 59.963742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ananda",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большая Пушкарская улица, 45",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312104, 59.963742]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Республика кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 47",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274016, 59.937116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Европа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 62",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 9602257;+7 965 7634450",
                "website": "http:\/\/www.кафе-европа.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.264574, 59.933307]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зачет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 62",
                "opening_hours": "Mo-Sa 08:00-22:00; Su 10:00-19:00",
                "contact_phone": "+7 921 9154146",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264574, 59.933307]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мельник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 72",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25816, 59.931621]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе 8\/4",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 8\/4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28767, 59.94006]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Долина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 82",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252474, 59.929948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алекс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 83",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254001, 59.933577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый двор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 83",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254001, 59.933577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лугела",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 90",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24951, 59.929136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pio Nero",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 20\/5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295476, 59.955831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На всех парах",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291919, 59.953083]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe Mirage",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 35Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302259, 59.959485]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чебуреки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 4",
                "opening_hours": "Mo-Su 11:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290886, 59.953025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Викинги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 41",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303786, 59.960458]}
        },
        {"type": "Feature",
            "properties": {
                "name": "YODA Noodle Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 47",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305043, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фреш бар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 55\/6",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30693, 59.962336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дубай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 77",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313236, 59.966142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Счастье",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 88",
                "opening_hours": "Mo-Th 08:00-23:00; Fr 08:00-00:00; Sa 09:00-00:00; Su 09:00-23:00",
                "contact_phone": "+7 812 9830880",
                "website": "http:\/\/www.schastye.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.309993, 59.964759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лаборатория кофе cuattro",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 38-40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.970191]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колхида",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 47",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa,Su,PH 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341542, 59.970335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирога",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 59",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340248, 59.972843]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шестой этаж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 60",
                "opening_hours": "Mo-Fr 09:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339063, 59.974261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hub",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 60",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339063, 59.974261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Визит",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 76",
                "opening_hours": "10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334472, 59.983504]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Анталия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 11-13",
                "opening_hours": "Mo-Sa 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.920902]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rainbow",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 24",
                "opening_hours": "Mo-Th 09:00-22:00; Fr 09:00-02:00; Sa 11:00-02:00; Su 11:00-00:00",
                "contact_phone": "+79522181099",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347435, 59.919815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Umami",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 3",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348423, 59.92295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мята Lounge",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боровая улица, 32",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346357, 59.917962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Caffe Dea",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Боровая, 47 к2 лит А",
                "opening_hours": "Mo-Fr 09:30-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343536, 59.914259]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восход",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бородинская улица, 2\/86",
                "opening_hours": "08:30-21:00",
                "contact_phone": "https:\/\/voskhodcoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331346, 59.925858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МитСтар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35561, 59.957809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пивной домъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 3к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35463, 59.958169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мясторан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Боткинская улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354226, 59.957498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антикафе \"SmokeTime\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Брестский бульвар, 8",
                "opening_hours": "Mo-Su 14:00-02:00",
                "contact_phone": "+7 911 7773100",
                "website": "https:\/\/vk.com\/smoketime_cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.199581, 59.856036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Trizet",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бронницкая улица, 10",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/trizet-cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321321, 59.915995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Монте Кристо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Будапештская улица 11Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386107, 59.868035]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Андалуз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 19к1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372363, 59.864611]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пещера",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 87к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400507, 59.834022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хомяк",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 97к2",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 4533555",
                "website": "http:\/\/cafehome.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.405529, 59.828803]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259418, 59.842061]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебная усадьба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 102к1",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251746, 59.841355]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дачный экспресс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 102к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251423, 59.841672]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Caffe Time",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267054, 59.852479]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Farmers Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бумажная улица, 16к1",
                "opening_hours": "Mo-Fr 0:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266443, 59.905583]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro100.Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бумажная улица, 4",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 812 3350100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.271662, 59.90335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Синема",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 150",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422857, 59.831191]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kitchen",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 24к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365734, 59.886316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gusli",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 72Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37539, 59.876694]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 72к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378283, 59.874024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурный домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 96",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393411, 59.859894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 96",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 6707181",
                "website": "https:\/\/spb.stolle.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.393411, 59.859894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Brioche",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 104",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31735, 59.849302]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чулан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 14",
                "opening_hours": "Mo-Sa 11:00-23:00",
                "contact_phone": "+7 812 3699927",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31506, 59.876594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grand Kafe Milana",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 56",
                "opening_hours": "https:\/\/grandkafemilana.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314646, 59.861069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 94",
                "opening_hours": "Mo-Su,PH 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315096, 59.852904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невская поминальная трапеза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Варшавская улица, 98",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 911 1570978",
                "website": "http:\/\/pominki-cafe-spb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.315284, 59.850283]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хутор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Введенская улица, 17",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304639, 59.955692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pegodya",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Вербная улица, 12к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287347, 60.023371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мангал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Витебский проспект, 41к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360245, 59.855873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "La Pasta",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 11",
                "opening_hours": "Mo-Th 11:00-23:00, Fr-Sa 11:00-01:00, Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347462, 59.930363]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sicaffe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 13\/9",
                "opening_hours": "http:\/\/sicafferussia.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347363, 59.92975]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 15",
                "opening_hours": "08:00-23:00",
                "contact_phone": "http:\/\/greenboxes.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347309, 59.929276]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 44-46",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308313, 59.921439]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Günter's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 45",
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307666, 59.922088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Салхино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 55",
                "opening_hours": "11:00-22:00",
                "contact_phone": "http:\/\/salhinospb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307541, 59.920492]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Asiatiq",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Вознесенский проспект, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307613, 59.920073]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Армянский домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Волжский переулок",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284759, 59.939257]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чебуреки у Ларисы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Волховский переулок, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290742, 59.944972]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sokol",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Волынский переулок, 4",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/www.sokolcoffee.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321438, 59.938491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro100.Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Выборгская набережная, 29",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 812 3350100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.338065, 59.968088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алекс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Выборгская набережная, 43",
                "opening_hours": "Mo-Fr 09:30-17:00; Sa 11:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33493, 59.972532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прованс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 34",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 9825836",
                "website": "http:\/\/provansspb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316362, 60.040802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Диана",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 12к2",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240284, 59.930764]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кабанчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 17",
                "opening_hours": "Mo-Su 11:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241101, 59.930791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мука",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гаванская улица, 35",
                "opening_hours": "Mo-Fr 08:30-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "+7 812 7023030;+7 812 7024646",
                "website": "https:\/\/muka.cafe\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.23908, 59.934691]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские блины",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 13",
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342431, 59.945833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Принткафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 19",
                "opening_hours": "10:00-21:00",
                "contact_phone": "http:\/\/printcafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342512, 59.945234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Книги и кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 20",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 2724990",
                "website": "http:\/\/bookcoffee.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.343213, 59.94467]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ешь фреш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гагаринская улица, 34",
                "opening_hours": "http:\/\/esh-fresh.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343437, 59.943192]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Малибу",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 20к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253633, 60.000065]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lunch Time",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Галерная улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299339, 59.934632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкалъ-Паласъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Галерная улица, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29792, 59.934587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хорас",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Галерная улица, 26",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290796, 59.932135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Галерной",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Галерная улица, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29447, 59.933523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chez Jules",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гангутская улица, 16",
                "opening_hours": "Mo-Fr 09:00-23:00, Sa 10:00-23:00, Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341847, 59.945107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жан-Жак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гатчинская улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30278, 59.960588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рада & К",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гатчинская улица, 27-29",
                "opening_hours": "11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297767, 59.962985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый Баку",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гатчинская улица, 31-33",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297183, 59.963242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фаджиоли",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гельсингфорсская улица, 4к1",
                "opening_hours": "Mo-Fr 09:00-18:00; Su 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335415, 59.974054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sound кафе LADЫ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Глухая Зеленина улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281642, 59.96362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Депо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 14",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366533, 59.929308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Royal Food",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363569, 59.929962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ялла",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363937, 59.929853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сулаймон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гончарная улица, 7",
                "opening_hours": "10:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364332, 59.929732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сoffee Room Street",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 11",
                "opening_hours": "Mo-Th 09:00-23:00, Fr 09:00-00:00, Sa-Su 10:00-00:00",
                "contact_phone": "https:\/\/www.coffeeroomspb.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.934425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Georgia",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 11",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312814, 59.934425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sicaffe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 2\/6",
                "opening_hours": "Mo-Su 09:00-23:30",
                "contact_phone": "+7 812 3852523",
                "website": "http:\/\/sicafferussia.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.310838, 59.936273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Hot Berries",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 21",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317243, 59.93144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Zoom",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 22",
                "opening_hours": "Mo-Fr 09:02-24:00; Sa-Su 10:02-24:00",
                "contact_phone": "+7 812 6121329",
                "website": "http:\/\/www.cafezoom.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.318419, 59.931098]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русская забава",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 3",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "http:\/\/русская-забава.рф\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31064, 59.93584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bread & Meat",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32151, 59.929082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bazar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 35-37",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320279, 59.929443]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gusto-Густо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 40",
                "opening_hours": "Su-Th 11:00-23:00, Fr-Sa 11:00+",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323863, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кунак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 42",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324222, 59.927234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grán",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 46",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su,PH 11:00-22:00",
                "contact_phone": "+79626888111",
                "website": "https:\/\/instagram.com\/grancoffee"
            },
            "geometry": {"type": "Point", "coordinates": [30.324995, 59.926679]}
        },
        {"type": "Feature",
            "properties": {
                "name": "2A Concept",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 49",
                "opening_hours": "09:00-22:00",
                "contact_phone": "https:\/\/2aconcept.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323459, 59.927261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "О, мой боул!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323809, 59.927022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Unicorn Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323809, 59.927022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фунт изюма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 52",
                "opening_hours": "http:\/\/funtspb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328615, 59.924379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rêve",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 55",
                "opening_hours": "10:00-20:00",
                "contact_phone": "https:\/\/reve-coffee-shop.business.site\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324636, 59.926503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нафт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 62, подъезд 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330313, 59.923166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Море внутри тебя",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 77",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330888, 59.922242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мука",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bica",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Seize the Impress",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 13-15",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312392, 59.928145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маковый Jam",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312535, 59.927842]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Julia Child",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданская улица, 27",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3087, 59.92713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марципан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 113к3",
                "opening_hours": "+7 812 6430544;+7 952 2642353",
                "contact_phone": "http:\/\/www.marzipan.su\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417459, 60.031171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 43к1",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399465, 60.013362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Огонь да мясо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 75к7",
                "opening_hours": "+7 812 9320108",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403077, 60.017216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наше кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Графский проезд",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326899, 59.928528]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самба Суши",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369444, 59.938581]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мимино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 11",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366533, 59.935088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "British Bakery",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Греческий проспект, 25",
                "opening_hours": "08:30-22:00",
                "contact_phone": "https:\/\/british-bakery.ru\/shops\/grecheskiy-pr-25\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369803, 59.937855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венерди",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Гродненский переулок, 15",
                "opening_hours": "https:\/\/www.eurasia-hotel.ru\/hotels\/privilegia\/cafe",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363048, 59.941488]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой Лев",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45941, 59.898968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Карат Плюс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дальневосточный проспект, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464908, 59.893177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "магазин-кафе \"Метрополь\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дачный проспект, 19к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255789, 59.840835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro100 Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Двинская улица, 10к3",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 11:00-20:00",
                "contact_phone": "+7 812 3350100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.247776, 59.907722]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трикатель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дегтярный переулок, 11Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384499, 59.938559]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шесть шампуров",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Дерптский переулок, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287688, 59.913316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восток",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Днепропетровская улица, 63",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357846, 59.911421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Днепропетровская улица, 67Ж",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356463, 59.909414]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жемчужина востока",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244784, 60.017216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алхимик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246374, 60.017423]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Якорь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, дорога на Турухтанные Острова, 24к5",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226908, 59.869946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайный Домъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Думская улица, 5\/22",
                "opening_hours": "12:00+",
                "contact_phone": "http:\/\/modern-cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328085, 59.933393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Central Park",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262481, 59.979272]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Радуга",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров, 4литО",
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.255519, 59.977382]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Остров",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Т",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260568, 59.977751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нота",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Елагин остров, 4Х",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254334, 59.980803]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Енот",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 14",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31718, 60.016294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Комфорт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 16",
                "opening_hours": "11:00-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316236, 60.01624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дубай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Железноводская улица, 68",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234364, 59.949177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградские пышки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344884, 59.926864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлеб и кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340653, 59.925592]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тройка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 27",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/troika-cond.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337041, 59.924167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vino Piano",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 28",
                "opening_hours": "09:30-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340248, 59.925047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шахрисабз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 36",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337913, 59.92414]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СССР",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загородный проспект, 40",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33688, 59.923721]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бар Друзья",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загребский бул., 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398756, 59.844985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эля",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 33к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410254, 59.833289]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Загребский бульвар, 9",
                "opening_hours": "+7 812 9813555",
                "contact_phone": "http:\/\/www.housemenu.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398756, 59.844985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Айва",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Замшина улица, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407299, 59.979894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сёстры",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 24\/35",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa, Su 10:00-22:00",
                "contact_phone": "+7 812 775-77-85",
                "website": "https:\/\/tseh85.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.416201, 59.930124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тэйст Лаб Групп",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 37",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420477, 59.930196]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У речки каменки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заповедная улица, 63",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23961, 60.043022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клёцки по-флотски",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заставская улица, 15",
                "opening_hours": "Mo-Fr 08:00-19:00; Sa 12:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325588, 59.888961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сосисочная #1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Заставская улица, 15",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 7757785",
                "website": "https:\/\/tseh85.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325588, 59.888961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мерани",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 14В",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 921 3081441",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35781, 59.947996]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мангал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Захарьевская улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352385, 59.947577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лисья нора",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 5к1",
                "opening_hours": "Mo-Fr 09:30-21:00; Sa-Su 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352663, 59.833633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пир пирогов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Звёздная улица, 5к1",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352663, 59.833633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хочу хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335182, 59.922688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подкова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 22",
                "opening_hours": "http:\/\/olius.spb.ru\/nedorogoe-kafe-v-czentre-sankt-peterburga",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341416, 59.918643]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СКА",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338065, 59.92056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paprika",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 6",
                "opening_hours": "Mo-Fr 08:00-24:00; Sa-Su 10:00-24:00",
                "contact_phone": "http:\/\/paprikacafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337005, 59.922282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Греческая таверна \"Узо\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442423, 59.874553]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домовой",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ивановская улица, 8\/77",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444588, 59.875239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Типичный Питер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 20",
                "opening_hours": "Tu,Th,Su 18:00-23:00, We,Fr,Sa 18:00-06:00",
                "contact_phone": "https:\/\/typicalpiter.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307801, 59.910293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васаби",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 10к1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475382, 59.939631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cheatmeal",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Инструментальная улица, 3Г",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324312, 59.974041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Espresso people",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Инструментальная, 3",
                "opening_hours": "Mo-Fr 08:30-18:00",
                "contact_phone": "+7 905 2251130",
                "website": "http:\/\/espressopeople.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321761, 59.974964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Garçon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Исаакиевская площадь, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303292, 59.93424]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Терраса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Искровский проспект, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468959, 59.905619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котелок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Исполкомская улица, 5",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380098, 59.926882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русская кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 10\/5",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335227, 59.936377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "чайка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 15",
                "opening_hours": "Su-Th 12:00-01:00; Fr-Sa 12:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333646, 59.936215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Песнь Моряка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 16\/19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342916, 59.935435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Josh Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334293, 59.936133]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 21",
                "opening_hours": "http:\/\/pishechnaya-fabrika.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335505, 59.936039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Check Point",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 29",
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339251, 59.935547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Caffetteria",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Итальянская улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339754, 59.935484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Там-Там",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кавалергардская улица, 2\/48",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382263, 59.942768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русалка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кавалергардская улица, 4",
                "opening_hours": "Mo-Sa 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382299, 59.943142]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буфет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кавалергардская улица, 8",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382307, 59.944053]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурная Хинкальная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28811, 59.943963]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури и вино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кадетская линия, 29",
                "opening_hours": "Mo-Th 11:30-00:00; Fr-Sa 11:30-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284993, 59.946734]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дранковъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 10",
                "opening_hours": "10:00-21:30",
                "contact_phone": "+7 911 9912502",
                "website": "http:\/\/www.lenfilm.ru\/service\/Kafe_Drankov\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.316982, 59.958273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "LingvaCup",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 16",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317341, 59.959395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Verlé Garden",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 25\/2",
                "opening_hours": "Su-Th 10:00-22:00; Fr-Sa 10:00-23:00",
                "contact_phone": "http:\/\/verlegarden.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314449, 59.963138]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 26-28",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/coffeemap_26_26.html",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Postscriptum",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 38\/96",
                "opening_hours": "Mo-Fr 09:00-22:00, Sa-Su 12:00-22:00",
                "contact_phone": "+7 812 9206049",
                "website": "http:\/\/anticafe-ps.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.311188, 59.965903]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Friendly coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310694, 59.966255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 42",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309984, 59.966863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мускат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Камышовая улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226279, 60.009121]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два товарища",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Канонерский остров, 22к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.220359, 59.903223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Минутка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347237, 59.985881]}
        },
        {"type": "Feature",
            "properties": {
                "name": "AsForsaj",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Караваевская улица, 47",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.517369, 59.82855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "11 Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Караванная улица, 11\/64",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340895, 59.934069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магриб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Караванная улица, 3\/35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340886, 59.935236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Centrale",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Караванная улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340967, 59.934659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Birch",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 3",
                "opening_hours": "Mo-Su 13:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350831, 59.943854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ИВ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 36",
                "opening_hours": "08:00-22:00",
                "contact_phone": "https:\/\/banket.life\/o-nas\/kafe-pekarnja-iv\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364395, 59.944048]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Горка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 50Е",
                "opening_hours": "Th-Tu 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369614, 59.945387]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Е.Д.А и кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 8Б",
                "opening_hours": "Mo-Sa 10:00-23:00; Su 12:00-23:00",
                "contact_phone": "http:\/\/edaikofe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351226, 59.94421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Клинский проспект, 21",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321186, 59.914669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Grün",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242036, 59.924397]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плов Ванлов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240715, 59.924934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gypsy Fish",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кожевенная линия, 40Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240715, 59.924934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Georgian's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колокольная улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350534, 59.928825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тифлис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298315, 59.998774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Take five",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 27к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248773, 60.018228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кантанта",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 4к2",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 812 4580715",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266146, 60.002018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "TheEater's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 71",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.221608, 60.041099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два друга",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Кондратьевский проспект, 23",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38882, 59.966376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лери 33",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 33",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38767, 59.971159]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 49",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38882, 59.972375]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Берлога",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 964 3499993",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297776, 59.933298]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Умао",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 11",
                "opening_hours": "http:\/\/umaocafe.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299285, 59.933956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аппетит",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298638, 59.933771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ля Русь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Конногвардейский бульвар, 15",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298638, 59.933771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ForAll",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Конюшенная площадь, 2В",
                "opening_hours": "https:\/\/anticafe.forall.site\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326073, 59.940154]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro100.Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Корабельная улица, 6к4",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": "+7 812 3350100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.24288, 59.877962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Якорь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Косая линия, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257774, 59.928654]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гюлистан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красногвардейский район, улица Пугачёва, 9",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418555, 59.951065]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красносельский район, территория Старо-Паново, Рабочая улица, 3",
                "opening_hours": "08:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.169604, 59.814034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Веранда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Крестовский проспект, 21Э",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251342, 59.9726]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рэро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкская улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312158, 59.958516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paros",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкская улица, 8",
                "opening_hours": "Mo-Su 13:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312158, 59.958516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Симсим",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 21\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319282, 59.956935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моцарт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31815, 59.956962]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cafe de Clie",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 27",
                "opening_hours": "11:00-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317278, 59.957093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "РосАл",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 35",
                "opening_hours": "09:00-23:00",
                "contact_phone": "+7 812 9648967",
                "website": "http:\/\/www.t-most.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314395, 59.957151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дамаск",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 45",
                "opening_hours": "+7 812 4985588;+7 965 7698173",
                "contact_phone": "http:\/\/damask-spb.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312616, 59.956922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Le Menu",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 79\/1",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 812 9335229",
                "website": "http:\/\/le-menu.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.306382, 59.949767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд-Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кубинская улица, 77",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299141, 59.839448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "pro100.cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кубинская улица, 77",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812-335-0-100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.299141, 59.839448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ruzi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнецовская улица, 22",
                "opening_hours": "Mo-Fr 11:00-24:00; Sa-Su 00:00-01:00,12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308691, 59.871893]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Family Cake",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнецовская улица, 23\/155",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318985, 59.871468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Питер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнецовская улица, 30",
                "opening_hours": "Mo-Su,PH 11:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316039, 59.871848]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе Куба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 14А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354226, 59.927288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Melange",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 2-4Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350103, 59.927685]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пловная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 3",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349753, 59.927419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Абажур",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кузнечный переулок, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350777, 59.927567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Луна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Купчинская улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389296, 59.836057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зимняя вишня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 15Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385506, 59.838679]}
        },
        {"type": "Feature",
            "properties": {
                "name": "чайхана \"Ширин\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 32к1А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398226, 59.828527]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Венера",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 32к1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398953, 59.828342]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жемчуг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Купчинская улица, 32к1Г",
                "opening_hours": "Mo-Th 12:00-00:00; Fr-Sa 13:00-01:00; Su 13:00-23:00",
                "contact_phone": "+7 921 3997933",
                "website": "https:\/\/www.cafe-jemchug.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.399133, 59.828785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Драники",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 36-38",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277133, 59.911502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Solaris lab",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 49",
                "opening_hours": "Mo-Th, Su 13:00-23:00; Fr, Sa 13:00-00:00",
                "contact_phone": "http:\/\/solarislab.ru\/kafe\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267862, 59.91162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gilavar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курская улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346402, 59.912558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Granola",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кушелевская дорога, 3к2",
                "opening_hours": "Mo-Fr 08:00-22:00, Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369704, 59.987992]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Релакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лабораторная улица, 14А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382748, 59.976454]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эйс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 14к1",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309436, 59.993847]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мимино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 5",
                "opening_hours": "10:00-23:00+",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302941, 59.989684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bar bronze",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 59",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318913, 59.994801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мишель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ланское шоссе, 69",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322543, 59.996034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mañana",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лахтинская улица, 25АБ",
                "opening_hours": "+7 812 9230377",
                "contact_phone": "http:\/\/vk.com\/manjana",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297533, 59.964007]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КлинIN",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 83к2В",
                "opening_hours": "+7 812 3097171",
                "contact_phone": "https:\/\/www.clin-in.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148728, 59.992785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Manyfactura",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290994, 59.966102]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашний повар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Левашовский проспект, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284706, 59.966746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Karlsson boom",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 104",
                "opening_hours": "Tu-Su 12:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226881, 59.852624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 151",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": "+7 812 6767720",
                "website": "http:\/\/www.lindfors.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.300983, 59.851422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЛаЛаЛанч",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 160",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301055, 59.852384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Joconde",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 162А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30781, 59.85214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Иверия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 176",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315572, 59.852538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хомяк",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 79к3",
                "opening_hours": "+7 812 7470557",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.196293, 59.85693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки пирожки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296015, 59.918544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дед Хо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 10\/53",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292826, 59.922675]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ретро кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 19",
                "opening_hours": "Mo-Su 10:00-03:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294461, 59.919946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Харчевня по-домашнему",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 22-24",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 9130822",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296357, 59.918683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Университетское кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 44А",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298369, 59.913839]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Time",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 49",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297237, 59.912608]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бородино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 50",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29792, 59.912517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моя Семья",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 54",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298809, 59.910356]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349815, 59.966732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайный домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Летний сад, литВ",
                "opening_hours": "We-Mo 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33564, 59.94636]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летний сад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Летний сад, литД",
                "opening_hours": "We-Mo 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336314, 59.945004]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sweet Biscuit",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 105",
                "opening_hours": "https:\/\/www.sweetbiscuit.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354334, 59.921773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Смайли",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 106",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3269644",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353912, 59.919477]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Скена",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 11",
                "opening_hours": "+7 812 2751215",
                "contact_phone": "https:\/\/vk.com\/ckena",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364108, 59.936823]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Боккаччо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 110",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353615, 59.918995]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури Hot Лаваш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 133",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351711, 59.917678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Big Bull",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 135",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351549, 59.917484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза Кебаб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 139",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351459, 59.917204]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Веселый Чижик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360964, 59.929601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Waffelcoff",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 16",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/www.waffelcoff.com",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360964, 59.929601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Место встречи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 162",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349205, 59.912102]}
        },
        {"type": "Feature",
            "properties": {
                "name": "brobar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 233",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346312, 59.909396]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 27\/7",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362356, 59.933924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Форрест",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 270З",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325965, 59.893236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Околица",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 283",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334212, 59.897758]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кукерия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 48",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358125, 59.925484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "добрая кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 50П",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361709, 59.925317]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 51",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/pishechnaya-fabrika.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359499, 59.929393]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Халяль",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 53",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359338, 59.929136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фо-лигов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 57-59",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/fonari-spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.928438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#Кофейня №1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 57-59Э",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/soon\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358736, 59.928235]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дружба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 64-66",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356113, 59.922815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Катюша",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 65",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357945, 59.927166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wag",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 65",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357945, 59.927166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Soko",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 68",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355843, 59.922481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Под лестницей",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Koh Chang",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 74",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35552, 59.922021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оливка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 75-77",
                "opening_hours": "09:00-21:00",
                "contact_phone": "http:\/\/papamamaspb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356768, 59.925159]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Краснодарский Парень",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 79",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "https:\/\/vk.com\/krdparen_spb",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.924997]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пловная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 89\/20",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355987, 59.923861]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе на Лиговском",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 9\/31",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 906 2427175",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36426, 59.936999]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Генацвале",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 97",
                "opening_hours": "11:00+",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355026, 59.922774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 99",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354873, 59.922503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "pro100.cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Липовая аллея, 9",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 812 3350100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.257532, 59.984463]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гном и дом",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 16",
                "opening_hours": "http:\/\/agencyholidays.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349097, 59.94472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Склад 17",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 18\/2",
                "opening_hours": "https:\/\/m.vk.com\/zonasklada",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34907, 59.944359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vega",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 21",
                "opening_hours": "Mo-Fr 09:00-20:00; Sa-Su 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348189, 59.943255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "She Ra",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 32",
                "opening_hours": "10:00-21:00",
                "contact_phone": "+7 812 9204618",
                "website": "https:\/\/she-ra.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.348746, 59.94024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 35",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347974, 59.940772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ray",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 43",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347866, 59.939113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наршараб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 45\/8",
                "opening_hours": "http:\/\/www.narsharab.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347947, 59.938797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сладкая фантазия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 46",
                "opening_hours": "07:45-21:45",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348666, 59.937616]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Popio",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 51",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su,PH 10:00-23:00",
                "contact_phone": "http:\/\/www.popio.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347776, 59.937197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мариинский буфетъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 56литЗ",
                "opening_hours": "09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351747, 59.934263]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котокафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 60",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34845, 59.933785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 61",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347507, 59.933677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мамуния",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 63",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347579, 59.93319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kombucha",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 63",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347579, 59.93319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лифляндия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лифляндская улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262634, 59.906255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "В космосе у мамы Аси",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Лодейнопольская улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291012, 59.965372]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские традиции",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Люботинский просп., 5",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339889, 59.883295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские традиции",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Люботинский проспект, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339889, 59.883295]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмешь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Люботинский проспект, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340455, 59.885697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Базилик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Десятинная улица, 11",
                "opening_hours": "11:00-24:00",
                "contact_phone": "+7 812 9174356",
                "website": "https:\/\/vk.com\/cafebazilik"
            },
            "geometry": {"type": "Point", "coordinates": [30.294937, 60.033036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Модильяни",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Десятинная улица, 11",
                "opening_hours": "12:00-21:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294937, 60.033036]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарсон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 22",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/www.garcon.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309113, 59.934064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Счастье",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 24",
                "opening_hours": "Mo-Th 09:00-01:00, Fr-Sa 09:00-02:00, Su 09:00-00:00",
                "contact_phone": "http:\/\/schastye.com\/restaurants\/isakiy",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308771, 59.933915]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cake and Breakfast",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 5А",
                "opening_hours": "08:00-21:00",
                "contact_phone": "+7 921 8660029",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313883, 59.936206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Subway",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Посадская улица, 17",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329442, 59.957994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури тетушки Марико",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 3\/54",
                "opening_hours": "Mo-Th, Su 12:00-00:00; Fr-Sa 12:00-02:00",
                "contact_phone": "+7 911 920-72-90",
                "website": "https:\/\/vhachapuri.ru\/spb"
            },
            "geometry": {"type": "Point", "coordinates": [30.33723, 59.934601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дубай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338101, 59.93507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Юлии",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малодетскосельский проспект, 1",
                "opening_hours": "10:00-15:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330214, 59.914637]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Papa John's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270863, 59.945788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Как раньше...",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "opening_hours": "12:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Снежинка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Nevsky Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны",
                "opening_hours": "Mo-Sa 08:00-21:00; Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298665, 59.961228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вега",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 47",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298423, 59.960841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Малый проспект Петроградской стороны, 89",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309014, 59.967286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Smiley",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Манежный переулок, 19\/40",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360137, 59.942949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Huqqa",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Манежный переулок, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353732, 59.943404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мгинская улица, 7",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36895, 59.900746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сити",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251863, 59.990994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Время Есть",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Межевой канал, 3к2",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25277, 59.910988]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pro100.Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мельничная улица, 4",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 812 3350100",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.396124, 59.917078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайная на Миллионной",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Миллионная улица, 10",
                "opening_hours": "08:00-23:00",
                "contact_phone": "https:\/\/chaynaya.net\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32398, 59.943381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русская кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Митрофаньевское шоссе, 10",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300049, 59.898954]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Адмирал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Митрофаньевское шоссе, 2к1",
                "opening_hours": "http:\/\/admiral.obiz.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300893, 59.907428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка столовая",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 109",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318734, 59.889882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Vse вкусно",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 115а",
                "opening_hours": "12:00-23:00; Mo-Fr 12:00-16:00 \"business lunch\"",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318653, 59.888392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Витамин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 131",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316138, 59.884347]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старбакс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 137",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "http:\/\/www.starbucks.ru\/store\/1022503\/RU\/Electra",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314673, 59.881719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Море",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 143",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318976, 59.878261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мама утка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 145А",
                "opening_hours": "Mo-Fr 11:00-24:00; Sa-Su 12:00-24:00",
                "contact_phone": "+7 812 3697880",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317755, 59.877357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сандра",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 147",
                "opening_hours": "Mo-Sa 10:00-18:00; Su off",
                "contact_phone": "+7 812 3880805;+7 812 3882660",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31903, 59.876404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bonape",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 155\/23",
                "opening_hours": "Mo-Su 07:00-22:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319713, 59.871306]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Победа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 159",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319776, 59.86986]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Del-mar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 159",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319776, 59.86986]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Flame",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 171",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31983, 59.862321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Империал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 176",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320917, 59.876802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уголёк",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 180",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320971, 59.87617]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 195",
                "opening_hours": "https:\/\/spb.lindfors.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320926, 59.850043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 195",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320926, 59.850043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 20",
                "opening_hours": "Mo-Sa 08:00-20:00; Su 08:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318338, 59.920167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Филадельфия на Московском",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 208",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 923-84-84",
                "website": "http:\/\/delmar-spb.ru\/restaurants\/philadelphiacafe\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.321941, 59.855638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Oliva",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 216",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322345, 59.848552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Модерн",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 220",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322498, 59.846739]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мармариса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317665, 59.915882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317665, 59.915882]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 31",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31779, 59.914371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ваниль и корица",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 41",
                "opening_hours": "http:\/\/vanilkoritsa.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31797, 59.912693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вегетарианскую пирамида",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4АА",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319767, 59.924812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ешь Фреш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 4АА",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319767, 59.924812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фильтр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317961, 59.911344]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mirelli's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 55",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318006, 59.910871]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дым коромыслом",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 57",
                "opening_hours": "11:30-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318024, 59.910618]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 6",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": "http:\/\/spb.lindfors.ru\/restorany\/moskovskiy-pr-6",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318042, 59.924749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Brükva",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 60\/129",
                "opening_hours": "Mo-Fr 08:30-20:00; Sa 10:00-20:00",
                "contact_phone": "http:\/\/brukva.pro\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319695, 59.909206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mix",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317117, 59.924718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Любимая Кофейня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский проспект, 72",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318985, 59.906499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "FreeДым",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский район, улица Победы, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324546, 59.863378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бризоль",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московское шоссе, 16к1",
                "opening_hours": "Mo-Fr 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338101, 59.834827]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Street",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московское шоссе, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339215, 59.832317]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Творческие люди",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Моховая улица, 39",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 925 8442404",
                "website": "https:\/\/www.instagram.com\/tvorcheskie.coffee\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.344812, 59.939821]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прекрасная зелёная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Моховая улица, 41",
                "opening_hours": "http:\/\/labelleverte.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344776, 59.939388]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мошков переулок, 5Б",
                "opening_hours": "http:\/\/chayki-bar.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322426, 59.942214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пикник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Муринская дорога, 68к1",
                "opening_hours": "http:\/\/ресторанпикник.рф",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45738, 60.032047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мытнинская улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38158, 59.937039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Relax",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Мясная улица, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281436, 59.918751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мостик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 102Б",
                "opening_hours": "12:00-23:00",
                "contact_phone": "http:\/\/cafemostik.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302546, 59.925092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 15",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/greenboxes.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326414, 59.936697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Barraquito",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326316, 59.936453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BB&Burgers",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "https:\/\/bbburgers.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Jungle",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 18-20",
                "opening_hours": "08:00-00:00",
                "contact_phone": "+7 812 9171234",
                "website": "http:\/\/wynwood.co\/restoran\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.326756, 59.934677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антуан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 22",
                "opening_hours": "Mo-Th 08:30-22:00; Fr 08:30-23:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326477, 59.933879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ёгурти и вафли",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "opening_hours": "http:\/\/egurti.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычный дворик №1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326055, 59.933041]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Oh! My Tea",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 43",
                "opening_hours": "12:00-21:00",
                "contact_phone": "https:\/\/ohmytea.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320521, 59.931134]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Jiva Burgers",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 49",
                "opening_hours": "https:\/\/jivaburgers.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31824, 59.930462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Saint Petersburg",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327654, 59.939762]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аъло",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 54",
                "opening_hours": "http:\/\/alo-halol.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315841, 59.926981]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Allegro",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 55",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315536, 59.929425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 56-58",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": "https:\/\/ginza.ru\/spb\/restaurant\/pyshechka",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315347, 59.926151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1848",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 68",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312455, 59.925619]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алазани",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 78",
                "opening_hours": "http:\/\/alazani-spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307532, 59.92626]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмени и Выпечка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 80",
                "opening_hours": "10:00-21:00",
                "contact_phone": "http:\/\/30cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306903, 59.926368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Est!Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 11",
                "opening_hours": "Mo-We, Su 11:00-23:00; Th-Sa 11:00-00:00",
                "contact_phone": "http:\/\/www.estcafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296015, 59.923351]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Suzani",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Крюкова канала, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297372, 59.921768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пуэр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Лейтенанта Шмидта, 13",
                "opening_hours": "Mo-Su 16:00+",
                "contact_phone": "+7 812 9259922",
                "website": "http:\/\/vkontakte.ru\/club779113"
            },
            "geometry": {"type": "Point", "coordinates": [30.282073, 59.935692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Usoff",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Лейтенанта Шмидта, 19",
                "opening_hours": "10:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277762, 59.934461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трапеза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Лейтенанта Шмидта, 29",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274851, 59.933059]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Радости",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 28",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280897, 59.948374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Баристократ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118АХ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300669, 59.903751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дачница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 122",
                "opening_hours": "http:\/\/dacha122.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297507, 59.908047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Феникс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 14литЗ",
                "opening_hours": "10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39386, 59.917511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mr E",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 193",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286888, 59.909576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Dinner Hall",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 24",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375822, 59.913677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амилен",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 24Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377439, 59.914385]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Питер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355798, 59.913862]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эспланада",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 48",
                "opening_hours": "http:\/\/esplanada-spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351477, 59.914538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уч-Кудук",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 55",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358116, 59.914723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Три шара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 61Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356759, 59.914858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Акбар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 96",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320216, 59.908218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пуэрто",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 30",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301639, 59.9697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Место встречи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 5к33",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316677, 59.968925]}
        },
        {"type": "Feature",
            "properties": {
                "name": "футура",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 9к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315581, 59.968669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На абордаж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/na-abordazh.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307675, 59.931797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "4.64",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 104",
                "opening_hours": "12:00-06:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291901, 59.92754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rafinad",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 28",
                "opening_hours": "08:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319973, 59.938802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Троицкий мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 30",
                "opening_hours": "08:30-22:30",
                "contact_phone": "http:\/\/t-most.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320009, 59.938572]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Распутин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 37",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/rasputin-cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318976, 59.940533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дачники",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 44",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "http:\/\/reca.rest\/restaurants\/dachnikinevski20",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320387, 59.936444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Drive Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 74",
                "opening_hours": "Mo-Fr 08:00-19:00",
                "contact_phone": "+7 495 989-45-60",
                "website": "https:\/\/hlebnasushny.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.306337, 59.930566]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечтатели",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 11",
                "opening_hours": "Fr-Sa 08:00-02:00, Su-Th 08:00-23:00",
                "contact_phone": "+7 921 7613155",
                "website": "http:\/\/www.thedreamerscafe.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.342782, 59.936792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе в польском саду",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 118Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311682, 59.919211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Литературное",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 118Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311682, 59.919211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Африка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 130",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 12:00-23:00",
                "contact_phone": "+7 812 9225872",
                "website": "http:\/\/art-afrika.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.304226, 59.917935]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Под лестницей",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": "https:\/\/instagram.com\/podlestnicei",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сivil Coffee Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": "+7 (981) 122-48-27",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цивил",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": "10:00-00:00",
                "contact_phone": "+7 981 1224827",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цифербург",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bombay Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Doris Day",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 20",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341192, 59.940623]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе Музея Фаберже",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 21",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342997, 59.934867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Le Moujik",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 52",
                "opening_hours": "https:\/\/lemoujik.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34112, 59.930169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Музыка кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 55",
                "opening_hours": "Mo-Th, Su 10:00-23:00; Fr, Sa 10:00-00:00",
                "contact_phone": "+79692076837",
                "website": "https:\/\/muscoffee.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336305, 59.929267]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Soulmate",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 55",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "https:\/\/www.soulmatespb.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336305, 59.929267]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Okna",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 59",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa 10:00-22:00; Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332271, 59.927757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rosetta",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 66",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337095, 59.928374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "3 Гнома",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 79",
                "opening_hours": "http:\/\/3gnoma.spb.ru\/kofejnya.html",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326666, 59.925885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рюмка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301244, 59.990323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сурхон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274213, 59.903548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Горячие хычины",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 29",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273144, 59.902357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кура. Греча. Суп.",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 9к2",
                "opening_hours": "https:\/\/www.kura-grecha-sup.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273746, 59.904681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Народное кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Народная улица, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.460686, 59.878527]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ажур",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Народная улица, 45",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475651, 59.882645]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лев",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Народная улица, 81",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483772, 59.883963]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Louie",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 100",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355619, 59.932008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарсон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 103",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 7170728",
                "website": "https:\/\/www.garcon.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.368051, 59.929628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fresh Point",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 109",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369632, 59.929024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Забыли Сахар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 812 3312424; +7 812 3212020",
                "website": "https:\/\/забылисахар.рф\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обед Буфет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "http:\/\/ginza.ru\/spb\/restaurant\/obedbufet",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Deli",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marselo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 132Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367216, 59.930381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Doris",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 150",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372956, 59.928293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тамада",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 151",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379316, 59.925498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Nevskiy.166",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 166",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377555, 59.926688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невский дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 23",
                "opening_hours": "12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322677, 59.935538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зингеръ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 28",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": "+7 812 5718223",
                "website": "http:\/\/singercafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.325866, 59.93584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Soup and sammich cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 35Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330493, 59.933212]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Абрикосовъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 40-42",
                "opening_hours": "09:00-23:00",
                "contact_phone": "http:\/\/www.abrikosov-spb.com\/coffee-house\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332316, 59.934943]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Stories",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 48",
                "opening_hours": "http:\/\/stories.ru.com\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334266, 59.934754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квартирка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 49\/2",
                "opening_hours": "Mo-Su 12:00-01:00",
                "contact_phone": "http:\/\/reca.rest\/restaurants\/kvartirkanevski51",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348558, 59.932324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#Кофейня №1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 9989855",
                "website": "http:\/\/delmar-spb.ru\/restaurants\/kofeynya-1-na-nevskom\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Буфет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 60И",
                "opening_hours": "+7 812 3142601",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339296, 59.934565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брынза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 66",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342009, 59.933758]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fukujuen",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 69",
                "opening_hours": "http:\/\/fukujuen-russia.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354397, 59.931607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chocolate Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 71",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/www.шоколадныйбар.рф\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355071, 59.931517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Замок Гоя",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 74-76",
                "opening_hours": "http:\/\/zamokgoya.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347336, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рублин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 77",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35737, 59.93126]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алёнка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 79",
                "opening_hours": "08:00-22:00",
                "contact_phone": "https:\/\/shop.alenka.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3587, 59.931057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gurmani",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The AM Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Waffelcoff",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "По-Питерски",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 87\/2",
                "opening_hours": "http:\/\/www.pyshkipopiterski.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363775, 59.930543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чичи-бичи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Невский проспект, 95",
                "opening_hours": "10:00-23:00",
                "contact_phone": "https:\/\/www.garcon.ru\/cheburechnaya",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366479, 59.930187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурный домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 34к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306166, 59.857979]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 49к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30366, 59.859461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 39к1",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 921 6437609",
                "website": "https:\/\/xleb.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.410227, 59.930331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рюмочка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415851, 59.925642]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Новочеркасский проспект, 43\/17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411251, 59.928212]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Морской бриз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 108",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486665, 59.866368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Причал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 40Т",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436314, 59.903877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нева",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Октябрьская набережная, 98к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473747, 59.871007]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лаймочка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ординарная улица, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309517, 59.964908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шарлотка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Парашютная улица, 27к2",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268635, 60.020044]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Серебряный век",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Парголовская улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346312, 59.98462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый город",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Перекупной переулок, 12\/13",
                "opening_hours": "+7 812 4004151",
                "contact_phone": "http:\/\/oldgorod.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378023, 59.929015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каттакурган",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Бринько, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316111, 59.925286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фергана",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Бринько, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315482, 59.925331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ШарБар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 6",
                "opening_hours": "Mo-Th 11:00-23:00; Fr-Su 12:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313595, 59.930913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Benny Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Гривцова, 6",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su,PH 10:00-20:00",
                "contact_phone": "http:\/\/bennycoffee.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313595, 59.930913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штрогель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Крылова, 1, подъезд 1",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 812 4100085;+7 812 4100089",
                "website": "http:\/\/strogell.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.332712, 59.932843]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антракт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Крылова, 2",
                "opening_hours": "http:\/\/cafe-antrakt.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333745, 59.932847]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Silent Tea",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Пирогова, 18",
                "opening_hours": "http:\/\/silenttea.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300345, 59.928379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашняя кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Ульяны Громовой, 3",
                "opening_hours": "Mo-Fr 11:00-21:00; Sa-Su 12:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361269, 59.934136]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Don Mimi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, переулок Ульяны Громовой, 8Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362464, 59.934312]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Афиет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дюжина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chocolate Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Funky Tea",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gelateria",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Llao Llao",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Milo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петровский остров",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26205, 59.95985]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Eda Veda",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 20",
                "opening_hours": "Mo-Su 09:00-17:30",
                "contact_phone": "http:\/\/www.veda-house.ru\/edaru.html",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334355, 59.959958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gastronomist",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 26-28",
                "opening_hours": "http:\/\/gastronomist.pro",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334463, 59.961034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белый кабачок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334876, 59.957629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Авроры",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334876, 59.957629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На волне",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петроградская наб., 2-4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336171, 59.955706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мангал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 12\/24",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293069, 59.956376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайхана Хурма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пироговская набережная, 17к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338281, 59.963922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Garden house",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пироговская набережная, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338326, 59.965948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ламантин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 2к2Щ",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405313, 59.958606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Brown",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 63",
                "opening_hours": "Mo-Fr 08:30-17:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437967, 59.994234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самовар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Планерная улица, 17",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.237355, 59.998661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шафран",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Планерная улица, 49",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228875, 60.017877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дзирна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, площадь Труда, 4В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293087, 59.932437]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоко-Мокко",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, площадь Труда, 6",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/shoko-mokko-kafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293832, 59.93131]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Круассан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, площадь Чернышевского, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319165, 59.869571]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невский двор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Поварской переулок",
                "opening_hours": "http:\/\/www.nevskydvor.ru\/nashe_kafe_nevskij_dvor\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352124, 59.92965]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Food studio",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Поварской переулок, 17\/12",
                "opening_hours": "+7 952 3528301",
                "contact_phone": "http:\/\/vk.com\/foodstudio",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351459, 59.928875]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жюль Верн",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Подольская улица, 4",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_phone": "http:\/\/cafejv.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322875, 59.917551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жюль Верн",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Подольская улица, 6",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_phone": "+7 812 7101762;+7 921 9501810",
                "website": "http:\/\/cafejv.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.323162, 59.917335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "pro100.cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Полевая Сабировская улица, 49",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+7 (812) 335-01-00",
                "website": "http:\/\/pro100.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.27177, 59.996452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Select",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368518, 60.007834]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Погребок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 29Щ",
                "opening_hours": "09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378265, 60.005598]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербургеры",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 31",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369893, 60.009606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские традиции",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 43",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388991, 59.968583]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 45к2",
                "opening_hours": "09:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387976, 59.969187]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Смолянка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 59Щ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382604, 59.972064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Таврический сад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Потёмкинская улица, 4А",
                "opening_hours": "09:00-21:00",
                "contact_phone": "+7 812 6050086",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369174, 59.946459]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папарацци",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 10",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303184, 59.932256]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коннект",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 7",
                "opening_hours": "Mo-Sa 11:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302429, 59.932441]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Яблоко Room",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Почтамтский переулок, 6",
                "opening_hours": "Mo-Fr 10:00-19:00",
                "contact_phone": "https:\/\/bistroappleroom.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302654, 59.931918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Печка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Почтамтский переулок, 8",
                "opening_hours": "+7 812 3120343",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302905, 59.931693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лаор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Прачечный переулок, 10",
                "opening_hours": "http:\/\/cafe.lahore.spb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302007, 59.928577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ермак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Прибрежная улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.512572, 59.837947]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Анталия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Придорожная аллея, 19А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364081, 60.054563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": "Tu-Su 14:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шале",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Приморский парк Победы",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243671, 59.971452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Приморский проспект",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.245009, 59.983711]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Встреча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 32к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224384, 60.026303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кабанчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 32к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224384, 60.026303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Розовый фламинго",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 6",
                "opening_hours": "+7 812 3433179",
                "contact_phone": "http:\/\/vk.com\/rozflamingo",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.243473, 60.011055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антре",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Бакунина, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374798, 59.930151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури & Хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.4799, 59.908164]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ситипанда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 24к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.490617, 59.900064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Диалог",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 32А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.492666, 59.896292]}
        },
        {"type": "Feature",
            "properties": {
                "name": "вечерний Питер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 118к1",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.185424, 59.831955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стрелец",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 131к2",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.157333, 59.834836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафетерий",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 171к2",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": "+7 (812) 607-35-97",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.110253, 59.837038]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Девятого Января, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453176, 59.84508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петроградский буфет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301585, 59.949605]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 25",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297435, 59.950551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Питер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 5\/1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304154, 59.949542]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кам-ин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 7\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302555, 59.949551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Stories",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Добролюбова, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30225, 59.949564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крепёж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 1",
                "opening_hours": "+7 904 6348724",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428328, 59.898192]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подвальчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 12",
                "opening_hours": "11:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424888, 59.896567]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пилот",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295836, 60.003853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У камина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 32к1А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26117, 60.014216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Телега",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 46к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251243, 60.022791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Максиматик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 55",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354711, 59.844886]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкальная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 65к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358017, 59.836685]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шарден",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Кузнецова, 11к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.189592, 59.86181]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Культуры",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380161, 60.051163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дуэт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 11к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371734, 60.039503]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Family Look",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 13к1",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331445, 60.042487]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашний очаг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 1к1",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318446, 60.043628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Энигма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 35А",
                "opening_hours": "10:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352142, 60.038578]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 41",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358053, 60.037027]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белые ночи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 60к1",
                "opening_hours": "Mo-Su 11:00-03:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34023, 60.041867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис club",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 60к1Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341129, 60.041993]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бахористан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 64",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348549, 60.040245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подиум",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Луначарского, 80к1Г",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375211, 60.035189]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычный домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Маршака, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.454604, 60.025282]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Durum",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Маршала Блюхера, 1к1Д",
                "opening_hours": "12:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35923, 59.986317]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Neste",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Маршала Блюхера, 2к7",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362347, 59.986551]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папоротник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Маршала Блюхера, 45",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 (911) 775-82-75",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411503, 59.975112]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Босфор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 24",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235613, 59.860306]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Antalya",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 41",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218563, 59.85111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Junior Room",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 4к1",
                "opening_hours": "Mo-Fr 10:00-21:00; Sa,Su 10:00-22:00",
                "contact_phone": "+7 961 8004422",
                "website": "http:\/\/juniorroom.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314691, 59.972249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пингвин кофе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 9Б",
                "opening_hours": "Mo-Fr 08:30-19:00",
                "contact_phone": "+7 921 7709570",
                "website": "https:\/\/www.facebook.com\/pingvincoffee"
            },
            "geometry": {"type": "Point", "coordinates": [30.315617, 59.970727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рим",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 19\/30",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422777, 59.954101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дружба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 65А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406805, 59.969772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Народное",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 20В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280708, 59.84668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 26к4",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269596, 59.836396]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Багульник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Науки, 17к2",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 812 4974085;+7 981 1378346",
                "website": "http:\/\/cledum.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.385595, 60.014895]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Элегия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Науки, 23",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402124, 60.010807]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ты и я",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Науки, 8к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389656, 60.015831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Удача",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": "Mo-Sa 10:00-22:00; Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фитнес",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367386, 59.998279]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самарканд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 63Б",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407622, 59.993577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дуэт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Непокорённых, 63кА",
                "opening_hours": "09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407856, 59.99323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фолиант",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 105",
                "opening_hours": "Tu-Su 09:30-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434068, 59.895222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "АСС",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 116к1Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472292, 59.86443]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис Групп",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 119",
                "opening_hours": "08:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438084, 59.892771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Диарони",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Ш",
                "opening_hours": "10:00-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.471456, 59.865686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Айс-Град",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 149Х",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438722, 59.884099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Елли-Пилли",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 149Э",
                "opening_hours": "http:\/\/www.elli-pilli.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.439036, 59.881697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотое руно",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 17",
                "opening_hours": "Mo-Sa 09:00-19:00",
                "contact_phone": "+7 921 975-01-89",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400759, 59.917105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Русские пироги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 209",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464414, 59.867493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Golden Rose",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 217",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.466147, 59.866644]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квазар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 243",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473999, 59.862348]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Gastrogramm",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 271",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.479254, 59.851245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Долина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 75В",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425436, 59.900795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечта",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 75В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.425436, 59.900795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сити",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 76к4",
                "opening_hours": "Mo-Sa 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.433224, 59.897361]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Проспект",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 93",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429774, 59.89881]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Амур",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Пархоменко, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333295, 60.000821]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Араз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 23к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33829, 60.051585]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Данго",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 33к1",
                "opening_hours": "+7 812 2960701",
                "contact_phone": "https:\/\/vk.com\/club13534971",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344533, 60.050273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дубай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 33к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344533, 60.050273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусная кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 74к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389575, 60.042447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пиала",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 76к1",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Su 12:00-03:00",
                "contact_phone": "+7 812 2447329",
                "website": "http:\/\/chaihanapiala.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.395189, 60.041004]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кабачок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 99",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428598, 60.033185]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Три реки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 117",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27875, 59.917745]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Riravo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304981, 59.924555]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Калачи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 23",
                "opening_hours": "Mo-Sa 08:00-21:00; Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304199, 59.924564]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пеликан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301288, 59.924163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хороший день",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300794, 59.924055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Риго",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 45",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294937, 59.92327]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аларчин мост",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 73",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/alarchin-most.nethouse.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286403, 59.92097]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сальвадор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Сизова, 28",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 11:00-02:00",
                "contact_phone": "+7 812 3012691",
                "website": "https:\/\/vk.com\/grandcafesalvador"
            },
            "geometry": {"type": "Point", "coordinates": [30.274923, 60.012687]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рюмка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 101к1Е",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246554, 59.859158]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бекер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 2\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2753, 59.899663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кабанчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 32",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270889, 59.889047]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Учкудук",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 47А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258385, 59.878432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Rotana",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 47Ш",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259652, 59.879683]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Love story",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 67",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258807, 59.871156]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Петровича",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 67к1",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25852, 59.872638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Райский уголок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 67к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.259113, 59.870502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "бецалель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 72",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262859, 59.878382]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Циферблат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 72",
                "opening_hours": "Mo-Su 11:00-23:30",
                "contact_phone": "+7 (960) 285 69 46",
                "website": "https:\/\/vk.com\/tvoyziferblat"
            },
            "geometry": {"type": "Point", "coordinates": [30.262859, 59.878382]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эклер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 75",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.25949, 59.867222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Первая профитрольная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 102к4",
                "opening_hours": "Mo-Su 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329819, 60.019001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточный дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 82",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337275, 60.016262]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крендель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Художников, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344623, 60.038488]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд Иверия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Художников, 26",
                "opening_hours": "+7 812 5991010;+7 911 2720999;+7 965 0096066",
                "contact_phone": "http:\/\/www.grandiveria.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352654, 60.048251]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чеснок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Художников, 30к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355268, 60.051275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 13",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358772, 59.945734]}
        },
        {"type": "Feature",
            "properties": {
                "name": "PoBo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359778, 59.948456]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Миндаль",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 5",
                "opening_hours": "12:00+",
                "contact_phone": "http:\/\/www.mindalcafe.ru\/restorany\/mindal_na_chernyshevskogo\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358978, 59.948208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Охте",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Шаумяна, 4к1",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415321, 59.93919]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 111к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32433, 60.036811]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышкинъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 150А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335433, 60.054082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пестрая корова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325327, 60.007276]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ИграЕм",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 58",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32248, 60.015183]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чебуречная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 59к2",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322426, 60.017544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе бильярд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 63к1",
                "opening_hours": "+7 812 5533270",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322453, 60.018322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Блик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 8",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328453, 59.999944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mafia",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 10",
                "opening_hours": "Mo-Th 10:00-23:00; Fr, Sa 11:00-02:00, Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.433943, 59.93992]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-магазин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 32В",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339476, 59.85167]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фрэш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 10к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350902, 59.836147]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шато",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковская улица, 6к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350795, 59.839108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вояж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 107",
                "opening_hours": "+7 812 7044609",
                "contact_phone": "http:\/\/cafe.voyzh.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313829, 59.750363]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Neste",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 109",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3132, 59.74918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SteakClub",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 18",
                "opening_hours": "12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325929, 59.82888]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У плюшки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 20к4",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327034, 59.827727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/www.cinnabonrussia.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lakka",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 40к4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327897, 59.818124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Puro Gusto",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.798253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 53к2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324043, 59.781736]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shell",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 81",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319704, 59.755195]}
        },
        {"type": "Feature",
            "properties": {
                "name": "БуфетЪ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинская улица, 7",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/buffet.ru\/Pushkin7\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357065, 59.930029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34271, 59.925845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Море внутри тебя",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 16-18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346797, 59.924609]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жаровня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343231, 59.925606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дым",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 34",
                "opening_hours": "https:\/\/cafedym.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35066, 59.922774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плов готов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 35",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": "+7 (812) 907-34-36",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350956, 59.922197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невские берега",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Разъезжая улица, 40",
                "opening_hours": "Mo-Fr 08:00-20:00; Sa-Su 10:00-20:00",
                "contact_phone": "http:\/\/www.cake.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352079, 59.922192]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Расстанная улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347291, 59.907419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Черная метка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Расстанная улица, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34253, 59.908218]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бульбашенок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Резная улица, 19\/8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281009, 59.965974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Очаг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Репищева улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28255, 60.024037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Станцiонный буфетъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ржевская площадь, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.510183, 59.9807]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Red Door",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 12",
                "opening_hours": "Mo-Th 10:00-21:00; Fr 10:00-23:00; Sa, Su 12:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294776, 59.913699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Будем знакомы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296959, 59.913776]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вселенная вкуса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277043, 59.913465]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Любимое",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Российский проспект",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472247, 59.925958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хванчкара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Российский проспект, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472355, 59.929682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Craft Lab",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Русановская улица, 17к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.511108, 59.864851]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Поляна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Рюхина улица, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256642, 59.973784]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Васин Сад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, сад Василеостровец",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257037, 59.934389]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки Петербургские",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33025, 59.93232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Суфле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33025, 59.93232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Метрополь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 22\/2",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 3101875",
                "website": "https:\/\/sever-metropol.ru\/wheretobuy\/spb\/sadovaya-ul-22\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.333538, 59.933122]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Траттория",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 22\/2",
                "opening_hours": "11:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333538, 59.933122]}
        },
        {"type": "Feature",
            "properties": {
                "name": "РИС Бабл ти и Вьетнамская еда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 28-30к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327923, 59.930751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Money Honey",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 28-30к13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327627, 59.930169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бухара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 28-30к29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325911, 59.928974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ляванги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 28-30к29",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325911, 59.928974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вгости",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322174, 59.928861]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Едакоff",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320225, 59.927991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Doner House",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 40Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320539, 59.927662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой берег",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315913, 59.925723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#Кофейня №1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 44",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/koff-sadovaya\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31497, 59.925344]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lagman Delivery",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 51",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/lagman-delivery.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310667, 59.924037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ManGall",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 53",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309804, 59.923622]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Турон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 70",
                "opening_hours": "+7 911 814-82-84",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29597, 59.919739]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Том Сойер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Садовая улица, 91",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291416, 59.919103]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панорама",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Свердловская набережная, 60В",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409275, 59.955475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Яши",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Севастопольская улица, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266335, 59.891105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Луна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Северный проспект, 16к4",
                "opening_hours": "Mo-Su 09:00-06:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342818, 60.031517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Терраса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Северный проспект, 7",
                "opening_hours": "Mo-Fr 10:00-20:00;Sa-Su 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335685, 60.0302]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Public Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318779, 59.926359]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 7",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317773, 59.927482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Jefferson-Bar",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323737, 59.993293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mustang lounge Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Серпуховская улица, 7",
                "opening_hours": "11:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321833, 59.91632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кусака",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецкая улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.294147, 59.986934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нева",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388039, 59.929605]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вег кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 30Н",
                "opening_hours": "Mo-Fr 08:00-17:00",
                "contact_phone": "+7 921 9178644;+7 921 9178645",
                "website": "https:\/\/www.veg.cafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.385784, 59.930223]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самарканд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 46-48Ж",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391425, 59.934006]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Берег",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 52",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.39209, 59.934506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бар \"Лагуна\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сиреневый бульвар, 11к2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356472, 60.052887]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельмондо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Советский переулок, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309526, 59.915612]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Советский переулок, 1",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309526, 59.915612]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточная кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Софийская улица, 33к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399304, 59.875524]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Софийская улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395091, 59.886492]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бриз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 15",
                "opening_hours": "11:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343509, 59.921836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Crumbs",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343509, 59.921836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чуланчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Спасский переулок, 7",
                "opening_hours": "Mo-Th 11:00-23:00, Fr 11:00-01:00, Sa 13:00-01:00, Su 13:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318015, 59.928627]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клио",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Среднегаванский проспект, 10",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244919, 59.930444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Broadway",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415976, 59.954678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Глухарь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 33\/15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415752, 59.955246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кансай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Среднеохтинский проспект, 33\/15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415752, 59.955246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Самарканд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 1",
                "opening_hours": "+7 963 2440622",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288703, 59.946315]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пирог",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 34",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27698, 59.942444]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Под гвоздём",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 42\/25",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27424, 59.941628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котлетное бюро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 61",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266946, 59.939956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SPARTA GYROS",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 61",
                "opening_hours": "Mo-Su 10:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266946, 59.939956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алиса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Средний проспект Васильевского острова, 67",
                "opening_hours": "Mo-Sa 09:00-22:00; Su 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265266, 59.939433]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фонарь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276055, 59.911782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вальтер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 37, подъезд 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276522, 59.903052]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточная кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 52",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275471, 59.901906]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чердак",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 52",
                "opening_hours": "Mo-Th 11:00-23:30, Fr 11:00-01:00, Sa-Su 12:00-23:30",
                "contact_phone": "http:\/\/cafecherdak.ru\/%D0%B3%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F\/%D0%BD%D0%B0%D1%80%D0%B2%D1%81%D0%BA%D0%B0%D1%8F\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275471, 59.901906]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царские пироги",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Старо-Петергофский проспект, 54",
                "opening_hours": "Mo-Fr 07:00-21:00; Sa 09:00-21:00; Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275327, 59.901409]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shell",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Стародеревенская улица, 1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244452, 59.984238]}
        },
        {"type": "Feature",
            "properties": {
                "name": "София",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Стародеревенская улица, 34к1",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246994, 60.00792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Агния",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Столярный переулок, 13",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/kafe-agnija.mozello.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312652, 59.926381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рога и копыта",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Столярный переулок, 13",
                "opening_hours": "12:00+",
                "contact_phone": "https:\/\/horns.tastetoeat.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312652, 59.926381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "UKUSNO",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Столярный переулок, 13",
                "opening_hours": "+7 (931) 585-71-79",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312652, 59.926381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ланское",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Студенческая улица, 10",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327618, 59.989265]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури & Хинкали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 1\/8",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/kolkhida.ru\/suvorovski\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368348, 59.932144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вера",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 25",
                "opening_hours": "07:30-23:00",
                "contact_phone": "https:\/\/www.hotelvera.ru\/cafe-eng.html",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373729, 59.937089]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 3\/5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368554, 59.932365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарсон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 47",
                "opening_hours": "09:00-21:00",
                "contact_phone": "https:\/\/www.garcon.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377924, 59.939708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белая ночь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 56",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386152, 59.944508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "teaCOFFsky",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 56",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386152, 59.944508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хорошее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суздальский проспект, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.383817, 60.051365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Армуду",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Съезжинская улица, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295072, 59.95434]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антракт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Таврическая улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378005, 59.94937]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Forget me not coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Таврическая улица, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378445, 59.944341]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ИванИваныч",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Таврическая улица, 31-33",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378364, 59.945968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кеци",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тамбовская улица, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355142, 59.913884]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ДВ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тверская улица, 1",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379523, 59.946703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Uno Due Tre",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тверская улица, 20",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38759, 59.94705]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЧК",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Горелово, улица Мира, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.134283, 59.776577]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ботардо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3И",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322022, 59.950853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петровская столовая",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Петропавловская крепость, 3И",
                "opening_hours": "Mo-Su 09:30-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322022, 59.950853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невское",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папаша Клаусс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Приморский проспект, 72",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212616, 59.994315]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Drive",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Усть-Славянка, Советский проспект, 55к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.553437, 59.82438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Circle К",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Усть-Славянка, Советский проспект, 57",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.554631, 59.823163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Левона",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395701, 60.045354]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хванчкара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 15к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368662, 60.015925]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ферма Бенуа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 17Г",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.370168, 60.019321]}
        },
        {"type": "Feature",
            "properties": {
                "name": "WOK",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Тихорецкий проспект, 6к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36824, 60.012013]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мархаба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Торговый переулок, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331014, 59.928054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дружба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Торжковская улица, 24",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315374, 59.991475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "i Вкусно",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "Mo-Su,PH 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7Б",
                "opening_hours": "Mo-Su,PH 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258987, 59.989319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусно Сытно",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Транспортный переулок, 13",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362284, 59.919833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петербургский буфет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Троицкая площадь Петроградской стороны, 3",
                "opening_hours": "Mo-Fr 10:00-18:00; Sa-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327367, 59.953394]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ржавая подкова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Троицкий проспект, 8",
                "opening_hours": "10:00-22:00",
                "contact_phone": "http:\/\/www.tr-8.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300938, 59.915697]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алхимик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Туристская улица, 10к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.202626, 59.989967]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечта",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Туристская улица, 20к2",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.205384, 59.998297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "I`pie Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Туристская улица, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20737, 59.999125]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стряпуха",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 25",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315006, 60.017468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышки",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Удельный проспект, 27",
                "opening_hours": "10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314628, 60.018039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ул. Марата, 56-58\/29",
                "opening_hours": "Mo-Fr 08:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349645, 59.922422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cities Lounge",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ул. Радищева, 44",
                "opening_hours": "http:\/\/citiesloungespb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363398, 59.943287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нака-чай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ул. Чайковского, 83\/7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367054, 59.946198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Причал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Крылова, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302843, 59.985899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пьяный Грузин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 15",
                "opening_hours": "Mo-Su 12:00-24:00",
                "contact_phone": "+79119253481",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352115, 59.95662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Этюд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Лебедева, 17\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352537, 59.957178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Tradizzini",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 5",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317692, 59.977481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хмель-бар",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Асафьева, 4А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323944, 60.048669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невское",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 3",
                "opening_hours": "Mo-Fr 08:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418519, 59.897767]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колхида",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 48",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.445702, 59.875235]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Урарту",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 52",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44713, 59.873514]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Погребок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 71\/8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443132, 59.876748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домъ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Бадаева, 6к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.45004, 59.917696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lemonade",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белинского, 11",
                "opening_hours": "14:00-23:00",
                "contact_phone": "+7 921 6548954",
                "website": "http:\/\/vk.com\/lemonaderoof"
            },
            "geometry": {"type": "Point", "coordinates": [30.347022, 59.938076]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Flat & Wide",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белинского, 8",
                "opening_hours": "Mo-Th 12:00-23:00, Fr 12:00-02:00, Sa 13:00-02:00, Su 13:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347318, 59.938432]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402367, 59.880708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стряпуха",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 8",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38176, 59.872516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Беседа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Беринга, 32к3",
                "opening_hours": "Mo-Fr 09:00-23:00; Sa-Su 10:00-23:00",
                "contact_phone": "+7 812 352-64-85",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.233187, 59.94435]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КайZен",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Блохина",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298522, 59.95211]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Синема",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Блохина, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303202, 59.951353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Черника",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Блохина, 8",
                "opening_hours": "Mo-Fr 10:00-24:00; Sa-Su 12:00+",
                "contact_phone": "+7 812 9300544",
                "website": "http:\/\/www.chernika-cafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.302627, 59.951119]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кронверк",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Блохина, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302932, 59.951443]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хинкали и вино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица ​Боровая, 26-28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347462, 59.91904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Вавиловых, 14",
                "opening_hours": "08:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390563, 60.024752]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Избушка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275076, 59.877258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Диета",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Верности, 10к3",
                "opening_hours": "Mo-Su 14:00-23:00",
                "contact_phone": "+7 812 5962905",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407299, 60.008082]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Paramount",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 13",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360478, 59.934001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гамарджоба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 16",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360011, 59.936792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Под лестницей",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 24\/27Б",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_phone": "https:\/\/instagram.com\/podlestnicei",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358799, 59.938094]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fish & Chips",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 34",
                "opening_hours": "Mo-Su 10:00-02:00",
                "contact_phone": "+7 812 7198742",
                "website": "http:\/\/molly.so"
            },
            "geometry": {"type": "Point", "coordinates": [30.360326, 59.940718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Midi Modi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 35",
                "opening_hours": "https:\/\/midimodi.cafe\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360883, 59.939077]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Toasteria",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Восстания, 9",
                "opening_hours": "Su-Th 11:00-23:00, Fr-Sa 11:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360443, 59.933365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трапеза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Всеволода Вишневского, 18",
                "opening_hours": "Mo-Fr 10:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299815, 59.968831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Любава",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Глинки, 2",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296159, 59.927473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Teatro",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Глинки, 2",
                "opening_hours": "12:00-24:00",
                "contact_phone": "http:\/\/teatrocafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296159, 59.927473]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Балт-Триумф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Грибакиных, 27Б",
                "opening_hours": "6:30-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.455897, 59.84923]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трали-вали",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Губина, 1\/32",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26143, 59.896684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отменная пельменная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305493, 59.928573]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Линдфорс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 19",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 3152383",
                "website": "http:\/\/spb.lindfors.ru\/restorany\/ul-dekabristov-19"
            },
            "geometry": {"type": "Point", "coordinates": [30.300956, 59.927815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1818",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 31",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/cafe1818.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291748, 59.925746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маска",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 48",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288928, 59.924663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Своя комната",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 55",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283448, 59.923852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наири",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306723, 59.92883]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кройцберг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 7",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3056, 59.928897]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маркет Шашлык",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 9",
                "opening_hours": "10:00-24:00",
                "contact_phone": "https:\/\/www.marketshashlik.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304585, 59.928631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белое дерево",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Демьяна Бедного, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381158, 60.034897]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Конд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 16к1",
                "opening_hours": "Mo-Sa 09:00-21:00; Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385389, 59.848547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чистые пруды",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 32",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408538, 59.852172]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378364, 59.845428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каттакурган",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Достоевского",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34774, 59.92373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый город",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Достоевского",
                "opening_hours": "Mo-Fr 10:00-23:00; Sa, Su 11:00-23:00",
                "contact_phone": "+7 812 5721960;+7 911 7991879",
                "website": "http:\/\/cafesg.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.34774, 59.92373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "You Can",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Егорова, 3",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313793, 59.915522]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Изумруд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Есенина, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331328, 60.039018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "23 этажа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 9233806;+7 961 8104431",
                "website": "http:\/\/cafe-23.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечта тут",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Есенина, 1к1",
                "opening_hours": "Su-Th 14:00-23:00; Fr-Su 14:00-01:00",
                "contact_phone": "+7 911 755-64-78",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330502, 60.028928]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эдем",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Есенина, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336628, 60.045529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ретро-блюз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Есенина, 36к5",
                "opening_hours": "+7 812 9242588;+7 812 9284598;+7 812 9748313",
                "contact_phone": "http:\/\/www.retro-blues.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344641, 60.055358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пещера",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 1\/4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319425, 59.926206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "https:\/\/greenboxes.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бар \"Олимпиец\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 57П",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340922, 60.021447]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд-кафе \"На привале\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 62",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337814, 60.022674]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арт-клуб Куклы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жака Дюкло, 6к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358071, 60.010088]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сираб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жени Егоровой, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308358, 60.063603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Батабат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жукова",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38882, 59.966376]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Californication",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 13",
                "opening_hours": "http:\/\/californication.cafe\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352421, 59.935773]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bao Mochi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 2",
                "opening_hours": "Su-Th 11:00-24:00, Fr-Sa 11:00-01:00",
                "contact_phone": "http:\/\/baomochi.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349025, 59.936178]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bez Uma",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353912, 59.935737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гастролёр",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 22",
                "opening_hours": "Su-Th 12:00-23:00; Fr-Sa 12:00+",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357828, 59.936079]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Slurp",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 31",
                "opening_hours": "https:\/\/ramenslurp.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356059, 59.935719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рассвет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 49",
                "opening_hours": "08:30-21:30",
                "contact_phone": "http:\/\/rajayoga-bk.ru\/005.html",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359185, 59.935669]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Печка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361673, 59.935638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Congo",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 57",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361673, 59.935638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lavanda Eclair",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 57",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361673, 59.935638]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Buona Cucina",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 32",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256858, 59.852633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бухара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 54",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256894, 59.848294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дягилев",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Зодчего Росси, 2",
                "opening_hours": "https:\/\/magnifique.catering\/restorany-i-kafe\/kafe-dyagilev\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336071, 59.930534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фреш",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ивана Черных, 29а",
                "opening_hours": "Mo-Fr 10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284265, 59.901531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Флэшка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 30В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48273, 59.923775]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Солнечная долина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Коммуны, 64",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486826, 59.963251]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Закусочная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Коммуны, 67У",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484141, 59.967678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Важная рыба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 12",
                "opening_hours": "https:\/\/vipfish.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314871, 60.052245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Место встречи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 15к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315131, 60.054653]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Удача",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 29к6",
                "opening_hours": "11:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318886, 60.057927]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Energy",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Композиторов, 6",
                "opening_hours": "+7 812 9035281",
                "contact_phone": "http:\/\/vk.com\/energy_cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317099, 60.047232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша Арсенальная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 1-3АС",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37027, 59.95648]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шар шара",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 10",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361709, 59.954273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Флэт Уайт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Константина Заслонова, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346671, 59.918341]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Леопольд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Кораблестроителей, 30",
                "opening_hours": "Mo-Su 12:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.212346, 59.952493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сабина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Костюшко, 68",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309346, 59.844958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Крыленко, 21к1С",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.476595, 59.89644]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рюмочная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Лёни Голикова, 73",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248584, 59.833656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "хинкальная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ленина, 16\/42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306642, 59.961102]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белые ночи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 10",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32999, 59.858968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "AlPetra",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 101",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348899, 59.832313]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кварели",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Лизы Чайкиной, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304082, 59.955093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Greenbox",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 4",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+7 911 9430900;+7 911 9431011",
                "website": "http:\/\/greenboxes.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.314036, 59.965696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе быстрого питания",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 6-8Б",
                "opening_hours": "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32009, 59.965467]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wood",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата",
                "opening_hours": "Mo-Th 10:00-23:00; Fr-Su 12:00+",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345593, 59.921498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ChaChaПури",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 12",
                "opening_hours": "https:\/\/www.chachapurispb.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354846, 59.929632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pitcher",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 2\/73-75",
                "opening_hours": "http:\/\/pitcherbar.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355816, 59.931242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Укроп",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 23",
                "opening_hours": "http:\/\/cafe-ukrop.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352843, 59.92786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Healthy Conscience",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 35",
                "opening_hours": "https:\/\/healthy-conscience.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351558, 59.925768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "SalDeRiso",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354415, 59.930201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отдых",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 65\/20",
                "opening_hours": "http:\/\/otdyh-kafe.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345234, 59.921642]}
        },
        {"type": "Feature",
            "properties": {
                "name": "GyroPlate",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 67\/17",
                "opening_hours": "10:00-23:00",
                "contact_phone": "http:\/\/gyroplate.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34403, 59.921299]}
        },
        {"type": "Feature",
            "properties": {
                "name": "MFC Foods",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Марата, 69-71",
                "opening_hours": "Mo-Fr 8:30-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343177, 59.921033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волшебный вкус",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маркина, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307729, 59.955656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аврора",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 37к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.284661, 59.894121]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каприз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Захарова, 48к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20242, 59.852005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мертвец или Азалия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1Е",
                "opening_hours": "+7 921 9613283",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249312, 59.860744]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Встреча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 24к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225327, 59.859162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Юнона",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 35Б",
                "opening_hours": "09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.208142, 59.863057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Встреча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 35Д",
                "opening_hours": "09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.208969, 59.862275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аиша",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 35к5в",
                "opening_hours": "09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.208142, 59.863057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Флотилия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 35к5г",
                "opening_hours": "09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.208214, 59.862989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 35р",
                "opening_hours": "10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211035, 59.862506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бар-Хан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 50к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.197497, 59.861236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Daisy Lounge",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 27",
                "opening_hours": "Mo-Su 12:00-06:00",
                "contact_phone": "+79992006719",
                "website": "http:\/\/daisylounge.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.35569, 59.940294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Granola",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 3",
                "opening_hours": "Mo-Fr 08:00-22:30; Sa-Su 09:00-22:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355071, 59.933974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pasta Bar 1315",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355071, 59.933974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Dahongpao",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 40",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354963, 59.940898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Артура",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 6",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354047, 59.933068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#M10",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Мира, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316227, 59.959949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЧК",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Мира, 11",
                "opening_hours": "https:\/\/www.instagram.com\/coffee.tchk.spb",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316263, 59.959588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Салют",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Мира, 36",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3284, 59.962489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Метро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 29",
                "opening_hours": "Mo-Fr 09:30-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.234876, 59.948235]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоти",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Олеко Дундича, 34А",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42116, 59.834207]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Олимпикс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ольминского, 4",
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426091, 59.900335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гринвич",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 34к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215841, 59.999813]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чудо плов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.251567, 59.992213]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Брецель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Орбели, 17",
                "opening_hours": "Mo-Fr 09:00-23:00, Tu-Su 08:00-15:00; Sa 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342036, 60.002297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пират",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Орджоникидзе, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339314, 59.845229]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мираж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Орджоникидзе, 61",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Sa 13:00-03:00; Su 13:00-01:00",
                "contact_phone": "+7 812 3826636",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362598, 59.842205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кухня Востока",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 14к5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.176279, 59.833452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оранжевый Лайм",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Партизана Германа, 37к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.173835, 59.82921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Африка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Печатника Григорьева, 13",
                "opening_hours": "+7 812 3154250",
                "contact_phone": "http:\/\/www.art-afrika.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348603, 59.918674]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ковчег",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Пионерстроя, 31к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.126818, 59.835871]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кристалл",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Пионерстроя, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.130977, 59.841423]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рустави",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146715, 59.836147]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сытный домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.468707, 59.91069]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 16А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469678, 59.912084]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ирина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475867, 59.91249]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Диван",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477188, 59.91383]}
        },
        {"type": "Feature",
            "properties": {
                "name": "УсЛада",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Подковырова, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304756, 59.963273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "No roots",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Правды, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33944, 59.92184]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ПаЛюБи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304909, 59.971046]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Leon Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303597, 59.971087]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Очаг",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 38Л",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291784, 59.970853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Щегол",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Радищева, 38\/20",
                "opening_hours": "Mo-Fr 09:15-21:00; Sa-Su 10:15-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363398, 59.941853]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ауровилль",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Радищева, 5-7",
                "opening_hours": "12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36276, 59.936417]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Great Meal",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Репина, 35",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.286718, 59.943539]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Царство пирогов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Решетникова, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323845, 59.878667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ramen",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344363, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайный дом",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 13",
                "opening_hours": "13:00+",
                "contact_phone": "http:\/\/teahouse-grand.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343949, 59.929407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трудовыебудни Papaya",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 14",
                "opening_hours": "We-Su 18:00-03:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345459, 59.930913]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фартук",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 15-17",
                "opening_hours": "10:00-03:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343671, 59.92892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Waff & Love",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 26",
                "opening_hours": "http:\/\/waffandlove.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344605, 59.929357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Waff&Love",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344605, 59.929357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Династия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 29\/28",
                "opening_hours": "12:00-00:00",
                "contact_phone": "https:\/\/apriori-resto.ru\/nashi-restorany\/dynasty-cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342467, 59.926841]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рубинштейна, 38",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/chainikoff.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343267, 59.927103]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bake Me Up",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Рылеева, 13",
                "opening_hours": "08:00-21:00",
                "contact_phone": "http:\/\/www.bakemeup.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354603, 59.942363]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Апрель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 126",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204342, 59.987407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Fratelli",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 143к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.20233, 59.989481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трактир",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289278, 59.985791]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лилия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Севастьянова, 8",
                "opening_hours": "Mo-Fr 09:00-24:00, Sa,Su 11:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327923, 59.875434]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашняя кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405951, 59.897808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папа Джонс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 13",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405951, 59.897808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Водопад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 154",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.445621, 59.864661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Руспина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 20\/32",
                "opening_hours": "+7 812 9052024",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41056, 59.896224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Green",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 35",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420127, 59.888708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Легенда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Седова, 91к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.441489, 59.867796]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Гагика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 12",
                "opening_hours": "11:00-23:00",
                "contact_phone": "http:\/\/www.ygagika68.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291263, 59.923338]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Том Сойер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 19",
                "opening_hours": "Mo-Fr 10:00-21:00; Sa-Su 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289098, 59.923234]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арго",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 2",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295216, 59.924005]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СССР",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Союза Печатников, 27",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285074, 59.922309]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Тамбасова, 32Г",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.156866, 59.828066]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сафари",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Танкиста Хрустицкого, 54",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252178, 59.838552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Витамин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Тельмана, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477385, 59.891015]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе быстрого питания",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Тельмана, 54к3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.487033, 59.894531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Квартал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Типанова, 18, подъезд 3",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 3735203",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334149, 59.851955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Краски Вкуса",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": "https:\/\/cinnabonrussia.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Smoke Lab",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Типанова, 38",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Sa 12:00-03:00; Su 12:00-02:00",
                "contact_phone": "+78122071719",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360919, 59.852814]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Relax",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Трефолева, 2БН",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281364, 59.886713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкус House",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ушинского, 35к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414934, 60.040901]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старое кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ушинского, 35к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414934, 60.040901]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Элегант",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Ушинского, 5к1",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 921 3931696",
                "website": "http:\/\/elegant-kafe.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.405511, 60.029103]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Горячая кружка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Циолковского, 10Б",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288856, 59.91037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ланселот",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Циолковского, 13-15",
                "opening_hours": "Mo-Su 09:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287176, 59.909576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лёгкое Чувство Голода",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 23",
                "opening_hours": "Mo-Su 12:00-22:00",
                "contact_phone": "http:\/\/lchg.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349726, 59.946356]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Арт-кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341856, 59.94645]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пышечная фабрика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 38\/9",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/pishechnaya-fabrika.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358646, 59.946689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нака чай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 83\/7",
                "opening_hours": "Mo-Fr 10:00-02:00, Sa-Su 12:00-02:00",
                "contact_phone": "+7 812 9143305",
                "website": "http:\/\/naka-chai.ru\/chainaya\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.367054, 59.946198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд-Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 15",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331337, 59.961179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "DOZA cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 15",
                "opening_hours": "Mo-Su 07:30-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331337, 59.961179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Виктория",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чекистов, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.14394, 59.845492]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Камина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Чернова, 7",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.469651, 59.8642]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Усадьба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Черняховского, 16\/5",
                "opening_hours": "11:00-23:30",
                "contact_phone": "http:\/\/кафе-усадьба.рф\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357155, 59.918286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тетрис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Черняховского, 33",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357936, 59.918296]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Луна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Шаврова, 26к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2435, 60.026541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Визави",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Шаврова, 5к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.227483, 60.021141]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лимон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Шателена, 26",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358736, 60.005859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Время обедать",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Швецова, 23Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.285236, 59.896526]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кон Коронель",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 10",
                "opening_hours": "11:00-24:00",
                "contact_phone": "https:\/\/www.con-coronel.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295566, 59.907316]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уч-Кудук",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296941, 59.907663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восточный рай",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Шотмана, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486198, 59.901662]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петровский погребок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Яблочкова, 20",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297731, 59.951056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Республика кошек",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Якубовича, 10",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 812 3120487",
                "website": "https:\/\/www.catsrepublic.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.301253, 59.933054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Декабрист",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Якубовича, 2",
                "opening_hours": "Mo-Fr 08:00-23:00; Sa-Su,PH 09:00-23:00",
                "contact_phone": "https:\/\/www.decabrist.net\/cafe",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303364, 59.933686]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Presso",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Уральская улица, 10к1",
                "opening_hours": "10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257603, 59.952804]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Смола",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Уральская улица, 2",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "+79052146611",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26744, 59.949055]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сеновал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Учебный переулок, 2",
                "opening_hours": "Mo-Su 11:30-23:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333448, 60.039386]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Огонь да Мясо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Учительская улица, 19к3",
                "opening_hours": "Mo-Su 08:00-24:00",
                "contact_phone": "+7 812 9334080",
                "website": "http:\/\/smoke-fire.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.401343, 60.032182]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайный домик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Феодосийская улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400921, 59.961881]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mamma Mia столовая",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Финский переулок, 1",
                "opening_hours": "+7 921 3325514",
                "contact_phone": "http:\/\/www.pinta-spb.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35234, 59.956786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bake Me Up",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фонарный переулок, 8",
                "opening_hours": "07:30-21:00",
                "contact_phone": "http:\/\/www.bakemeup.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.305223, 59.928911]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ширван",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Бухарестская, 142 к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ruokkalla",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 31",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "+7 911 9233788",
                "website": "http:\/\/ruokkalla.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.361898, 59.944909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Австерия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 32",
                "opening_hours": "http:\/\/avsteria.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357352, 59.945427]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восток",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363353, 59.9449]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сундук",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 42",
                "opening_hours": "11:00-24:00",
                "contact_phone": "https:\/\/www.cafesunduk.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361646, 59.945382]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Заводные яйца",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 48",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_phone": "+7 812 275-88-96",
                "website": "http:\/\/заводныеяйца.рф\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.363443, 59.945364]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Mango",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Фурштатская улица, 50",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "http:\/\/mango-cafe.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364206, 59.945351]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Поцелуйчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Хасанская улица, 2\/1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477331, 59.937355]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Афрасиаб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Херсонская улица, 1\/7",
                "opening_hours": "https:\/\/afrasiab.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376163, 59.92984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хива",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Хрустальная улица, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404559, 59.906156]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Джана",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Хрустальная улица, 31",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404667, 59.906634]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Хрустальная, 31а",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401774, 59.910415]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У тёщи на блинах",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Цветочная улица, 16Л",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330681, 59.888045]}
        },
        {"type": "Feature",
            "properties": {
                "name": "#Кофейня №1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, Кирпичный переулок, 1",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/delmar-spb.ru\/restaurants\/kofeinia1\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315266, 59.935831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shawarma Mix",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, Кирпичный переулок, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315697, 59.935457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "The Buddy",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 14",
                "opening_hours": "Mo-Fr 11:00-23:00; Sa-Su 11:00-01:00",
                "contact_phone": "+7 812 9194346",
                "website": "https:\/\/cafebuddy.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.338479, 59.927874]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Джамала",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326908, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bao",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 26",
                "opening_hours": "Su-Th 12:00-23:00, Fr-Sa 12:00-02:00",
                "contact_phone": "+7 812 3127304",
                "website": "http:\/\/baoumao.com\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.341488, 59.926688]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе на Ломоносова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33131, 59.930363]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мамэле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 28",
                "opening_hours": "https:\/\/m.vk.com\/makemehummus",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357712, 59.938964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Lu",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358987, 59.938933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Демократ",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 58",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367494, 59.938509]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 38",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296321, 59.964453]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Magnifique",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 50",
                "opening_hours": "Mo-Su 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299339, 59.967358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Love Cake",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 52",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301342, 59.967813]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Атмосфера",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Чкаловский проспект, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288407, 59.9593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ошхона у Насреддина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Чугунная улица, 14литЗ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352259, 59.972168]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Михалыча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шамшева улица, 8",
                "opening_hours": "Mo-Su 12:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300848, 59.960336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "James Cook",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шведский переулок, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325345, 59.939009]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шкипер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шкиперский проток, 14к1",
                "opening_hours": "Mo-Th 10:00-19:00; Fr 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225066, 59.93268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барселона",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 114",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.475427, 59.967025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Feel Fresh",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 54",
                "opening_hours": "http:\/\/feelfreshbar.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373315, 59.948983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шпинат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 54\/2",
                "opening_hours": "10:00-15:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373899, 59.949961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старый город",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 56Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375956, 59.949596]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вилена",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Шуваловский проспект, 55Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.230896, 60.026788]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычный дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южно-Приморский парк",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.167386, 59.852411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Встреча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южное шоссе, 35 к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414674, 59.863003]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Друзьям",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южное шоссе, 46к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424007, 59.866545]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Южное",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Южное шоссе, 66",
                "opening_hours": "Mo-Su 11:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414036, 59.863016]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Островок счастья",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Якорная улица, 15",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434473, 59.94184]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котлетная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Якорная улица, 6",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417297, 59.942971]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ярославский проспект, 43",
                "opening_hours": "11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320423, 60.016609]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цитрус",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ярославский проспект, 47\/5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319767, 60.017625]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фиорд",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Яхтенная улица, 1к1",
                "opening_hours": "Mo-Su 11:00-02:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.215571, 59.984274]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Терминал",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Санкт-Петербург, ул. Белинского, 11",
                "opening_hours": "07:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347022, 59.938076]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро Василеостровское",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 11-я линия Васильевского острова, 34\/47",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272965, 59.941844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ложка - Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 12-я линия Васильевского острова, 13",
                "opening_hours": "Mo-Fr 10:00-21:00; Sa-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274267, 59.938482]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 13-я линия Васильевского острова, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276118, 59.936106]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Питание+",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 17-я линия Васильевского острова, 22",
                "opening_hours": "Mo-Fr 08:30-19:00",
                "contact_phone": "+79219460123",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.266757, 59.938284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусняшка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 26-я линия Васильевского острова, 5к4",
                "opening_hours": "Mo-Fr 08:00-18:00",
                "contact_phone": "+7 911 7230103",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.261376, 59.927775]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая ложка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 4-я линия Васильевского острова, 35-37",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280079, 59.94513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 5-й Верхний переулок, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362203, 60.072291]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 5-й Предпортовый проезд, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308053, 59.840157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 6-я Красноармейская улица, 5-7",
                "opening_hours": "Mo-Fr 08:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315769, 59.91258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канапе",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 7-я Красноармейская улица, 10",
                "opening_hours": "Mo-Fr 11:00-20:00; Sa-Su 11:00-19:00",
                "contact_phone": "https:\/\/canape.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314844, 59.911552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 7-я линия Васильевского острова, 34",
                "opening_hours": "08:30-23:00",
                "contact_phone": "+7 981 7849814",
                "website": "http:\/\/market-place.me\/ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.280097, 59.940993]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, 7-я Советская улица, 18\/21, подъезд 1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372839, 59.936408]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Адмиралтейский район, Казанская улица, 45",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307361, 59.928054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайная бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Барочная улица, 4АД",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287481, 59.965822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Баррикадной",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Баррикадная улица, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262005, 59.891286]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Бестужевская улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393779, 59.987294]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Студенческая столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Биржевая линия, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296563, 59.942994]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 14к2Б",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267063, 59.998661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 18к1",
                "opening_hours": "10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260379, 59.999723]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Bistro",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Зеленина улица, 22",
                "opening_hours": "Mo-Su 09:00-19:30",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287526, 59.963471]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Питание+",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 11",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa, Su 10:00-21:00",
                "contact_phone": "+7 812 911-23-45",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315805, 59.960976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 18",
                "opening_hours": "Mo-Fr 9:00-18:00; PH off",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322093, 59.962665]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Студенческие столовые",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Монетная улица, 34",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328651, 59.964052]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бамбук",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 10Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318087, 59.936864]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два перца",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Морская улица, 65",
                "opening_hours": "Mo-Fr 10:00-19:00",
                "contact_phone": "http:\/\/www.dvaperca.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298081, 59.930358]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая трамвайного парка Бэгинс",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большая Посадская улица, 24\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329639, 59.960534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.281705, 59.939347]}
        },
        {"type": "Feature",
            "properties": {
                "name": "\"Китайский ресторан\"",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой проспект Васильевского острова, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.288362, 59.941362]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 82",
                "opening_hours": "08:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308987, 59.964129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345045, 59.959899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344156, 59.964016]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 38-40",
                "opening_hours": "Mo-Fr 10:00-18:00; Sa,Su 11:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.970191]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Большой Сампсониевский проспект, 59",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340248, 59.972843]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Боровая улица, 49",
                "opening_hours": "Mo-Fr 08:30-15:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340868, 59.91166]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Боровая улица, 59-61",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339503, 59.909743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе, столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Брюсовская улица, 1",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.42619, 59.98629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кулинария",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 98",
                "opening_hours": "Mo-Fr 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.253732, 59.841116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364674, 59.887905]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро по-восточному",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Варшавская улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314206, 59.862153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Месье Оливье",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Введенский канал, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328579, 59.918029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая домашняя кухня",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Верхняя улица, 12",
                "opening_hours": "11:00 - 17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368698, 60.056957]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дон Педро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347309, 59.929276]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 23",
                "opening_hours": "24\/7",
                "contact_phone": "+7 906 2294239",
                "website": "https:\/\/st1.one\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.34686, 59.928154]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Волхонское шоссе, 109",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.149123, 59.802834]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Выборгская набережная, 61",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": "+7 981 7850736",
                "website": "http:\/\/market-place.me\/ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.322471, 59.980682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая «Красная нить»",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Гельсингфорсская улица, 3литЗ",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336422, 59.974541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №37",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Гороховая улица, 73",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33043, 59.922544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Гражданский проспект, 119 к4",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404433, 60.019675]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 72А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398055, 60.013924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Чашка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Двинская улица, 10",
                "opening_hours": "Mo-Su 11:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248935, 59.90894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Блины",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Демонстрационный проезд",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324483, 59.853763]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Дерптский переулок, 15",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.287742, 59.912278]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Днепропетровская улица, 12Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358574, 59.911349]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Домостроительная улица, 7",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373082, 60.068646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро Самса",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Дровяная улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293051, 59.909617]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мандарин кафе-бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Енотаевская улица, 42",
                "opening_hours": "Малая Одесса",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318321, 60.016654]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Картошка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 65к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432802, 59.93021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Заставская улица, 28",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324438, 59.888677]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Зверинская улица, 17Б",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299366, 59.952826]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Народная столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 16\/30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307837, 59.911159]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 9\/2",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307065, 59.915607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Ириновский проспект, 1К",
                "opening_hours": "Mo-Su 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.447938, 59.950226]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №5",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 11",
                "opening_hours": "11:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318976, 59.958629]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Каменноостровский проспект, 26-28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313901, 59.962845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая на территории завода",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Канонерский остров, 41АБ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.228309, 59.908498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кантемировская улица, 24",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.344506, 59.985521]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349357, 59.944215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кинза",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кирочная улица, 8",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352771, 59.944233]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КиО",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 12 к1",
                "opening_hours": "unknown-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.260065, 60.007438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро 24",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Комендантский проспект, 12в",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246913, 60.008518]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая К15",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 15к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382011, 59.964786]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №32",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кондратьевский проспект, 32",
                "opening_hours": "Mo-Fr 9:00-21:00, Sa-Su 11:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.382002, 59.965822]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Кондратьевский проспект, 85",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410155, 59.982658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Нива-СВ",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Корабельная улица, 6ДН",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24235, 59.876486]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Косая линия, 24\/25",
                "opening_hours": "11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252537, 59.922345]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Костромской проспект, 62",
                "opening_hours": "+7 965 0753212",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31258, 60.026365]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кронверкский проспект, 5",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324267, 59.954714]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кубинская улица, 78В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297444, 59.842639]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Кубинская улица, 80Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298171, 59.842092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Купчинская, 32 лит Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399259, 59.829961]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Курляндская улица, 8к2М",
                "opening_hours": "Mo-Fr 11:00-17:00",
                "contact_phone": "+7 812 6359996",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289745, 59.911141]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 9\/55",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292386, 59.922327]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лесной проспект, 61 к7",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341219, 59.984904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кинза",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 158",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349375, 59.912368]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обед в саду",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 21",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/stolovayastreet.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363524, 59.935529]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Market Place",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 43-45",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35993, 59.930196]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая на Лиговском",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 69",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357631, 59.926436]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 84\/2Б",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355807, 59.921137]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 93",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355538, 59.923284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЛанчБокс",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 26",
                "opening_hours": "https:\/\/www.bistrolunchbox.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348998, 59.941912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городская столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 45\/8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347947, 59.938797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два гуся",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 48",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348684, 59.937076]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Литейный проспект, 53",
                "opening_hours": "https:\/\/cafe-1108.business.site\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347704, 59.936332]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лифляндская улица, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262634, 59.906255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лифляндская улица, 3",
                "opening_hours": "Mo-Fr 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262553, 59.907271]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Лоцманская улица, 20",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27822, 59.917826]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два перца",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 16",
                "opening_hours": "Mo-Fr 10:00-19:00; Sa-Su 11:00-20:00",
                "contact_phone": "http:\/\/www.dvaperca.com\/obedennye-zaly-dva-pertsa-ul-malaya-morskaya-d-16",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310649, 59.934538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Малая Садовая улица, 6",
                "opening_hours": "Mo-Fr 09:00-22:00; Sa-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337994, 59.934835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечта",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Малоохтинский проспект, 61",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399834, 59.929646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Малоохтинский проспект, 64Б",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": "+7 911 8209020",
                "website": "http:\/\/market-place.me\/ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.402385, 59.931319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 2к2",
                "opening_hours": "Mo-Fr 07:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249617, 59.99003]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Менделеевская улица, 2",
                "opening_hours": "Mo-Fr 08:00-18:00; PH off",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.351917, 59.97337]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Менделеевская улица, 9",
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366102, 59.976319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкусные обеды",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Минеральная улица, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364611, 59.964665]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Митрофаньевское шоссе, 4-6-8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300219, 59.9008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро Хауз",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Московский проспект, 193А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320872, 59.851765]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Новая столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Московский проспект, 2\/6",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317925, 59.925858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Московский проспект, 21",
                "opening_hours": "08:30-23:00",
                "contact_phone": "+7 981 7850486",
                "website": "http:\/\/market-place.me\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.317422, 59.916748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кушать подано",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Московский проспект, 31",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31779, 59.914371]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лайм",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Моховая улица, 13",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34483, 59.944215]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Султан",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Моховая улица, 16",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.345674, 59.944107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Мытнинская улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379316, 59.934984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 14",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327286, 59.936034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая на Грибоедова",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 22",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/evro-stolovaya.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326477, 59.933879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волна FM",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 25",
                "opening_hours": "08:00-22:00",
                "contact_phone": "http:\/\/www.volna-fm.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324779, 59.932779]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная канала Грибоедова, 8\/1",
                "opening_hours": "http:\/\/market-place.me\/ru\/spb\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327636, 59.936792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Экспресс бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная Макарова, 10",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.293761, 59.945472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Быстроежка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 120Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298692, 59.906932]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Набережная Обводного канала, 122г",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298989, 59.907929]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 138",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.28007, 59.908647]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 142\/16",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275938, 59.908678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 46",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353166, 59.914227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Еда",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 28",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302196, 59.969511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная реки Карповки, 5к3Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315446, 59.970227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Ложка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная реки Мойки, 56",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.315024, 59.932518]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая ОАО \"Адмиралтейские верфи\"",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная реки Фонтанки, 203ЭЛ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274339, 59.918593]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, набережная Чёрной речки, 41к7",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": "+7 981 7849616",
                "website": "http:\/\/market-place.me\/ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.307981, 59.985624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 13",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.273692, 59.904252]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Народная улица, 16",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464566, 59.881033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Науки проспект, 21\/1 лит А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.418195, 60.011846]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Куверт",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 109",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/evro-stolovaya.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369632, 59.929024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 11\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31603, 59.936404]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 118",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362329, 59.931594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовкин",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 142",
                "opening_hours": "Mo-Fr 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369542, 59.929587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Market Place",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 22-24",
                "opening_hours": "08:00-05:30",
                "contact_phone": "+7 981 8544833",
                "website": "http:\/\/market-place.me\/ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.324097, 59.935944]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 23",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.322677, 59.935538]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 5",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313326, 59.936719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 53",
                "opening_hours": "Mo-Su 08:00-23:00",
                "contact_phone": "+7 812 3149949",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349708, 59.932153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 54",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.336889, 59.934366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 60",
                "opening_hours": "Mo-Su 07:30-23:00",
                "contact_phone": "+7 911 7770308",
                "website": "http:\/\/www.teremok.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.33917, 59.93406]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Невский проспект, 85",
                "opening_hours": "10:00-23:00",
                "contact_phone": "+7 981 1698828",
                "website": "http:\/\/market-place.me\/ru\/spb\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, просп. Большевиков, 3к9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Приличная столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новгородская улица, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38653, 59.936332]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два перца",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новгородская улица, 18",
                "opening_hours": "Mo-Fr 10:00-19:00",
                "contact_phone": "http:\/\/www.dvaperca.com\/kafe-kafe-stolovaya-ul-novgorodskaya-d-18",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.386323, 59.936963]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30711, 59.871035]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309804, 59.86706]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307172, 59.866405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30764, 59.870253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308044, 59.869756]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30931, 59.869743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30984, 59.870262]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308017, 59.867746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309328, 59.867733]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Новоизмайловский проспект, 16к9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.307595, 59.867064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Новороссийская, 48а",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353148, 59.994792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Олеко Дундича, 40а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432514, 59.845198]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Перевозная улица, 6\/11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27884, 59.922345]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Ложка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, переулок Антоненко, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312275, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, переулок Антоненко, 10",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312275, 59.929484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лайм",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Петроградская набережная, 26-28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334463, 59.961034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинградская столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Петропавловская улица, 4",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314206, 59.966102]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алекс",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Пироговская набережная, 17к1",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338281, 59.963922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хлебосольный Дом",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 47 к46",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436162, 59.980808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Пискарёвский проспект, 47Х",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.430367, 59.981636]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Планерная улица, 24Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235343, 60.007312]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Планерная, 79 к3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224779, 60.033576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Marketplace",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, площадь Конституции, 3",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa,Su 11:00-22:00",
                "contact_phone": "+7 911 2320546",
                "website": "http:\/\/market-place.me\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.305825, 59.8505]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, площадь Конституции, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308259, 59.850988]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая Солянка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, площадь Ленина, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356436, 59.955737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Добрая столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Подъездной переулок, 18 лит А",
                "opening_hours": "Mo-Fr 11:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333349, 59.918228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая № 62",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Подъездной переулок, 4",
                "opening_hours": "Mo-Fr 09:00-17:30; Sa,Su off",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331346, 59.920384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая ФТК",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 21",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.366614, 60.000906]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Алладин",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Политехническая улица, 31",
                "opening_hours": "Mo-Fr 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369893, 60.009606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Политехническая, 20 к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363128, 59.997541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 72",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361808, 59.983936]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая на Почтамтской",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Почтамтская улица, 6",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.304145, 59.932549]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Как дома",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Прачечный переулок, 3",
                "opening_hours": "09:00-19:00",
                "contact_phone": "+7 812 9315323",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.301118, 59.929461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Придорожная аллея",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.361179, 60.055421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ваша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Промышленная улица, 19Р",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258196, 59.896616]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Промышленная улица, 22",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265257, 59.897587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Промышленная, 5 лит А",
                "opening_hours": "Mo-Fr 08:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269066, 59.898905]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 11А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.244775, 60.012201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Александровской Фермы, 29Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444552, 59.854201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сытный Дворик",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Бакунина, 2",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.372094, 59.929894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 17А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.480475, 59.908074]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Елизарова, 36А",
                "opening_hours": "Mo-Fr 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404577, 59.891182]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Микс",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 13к3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289862, 60.004357]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая,шаверма",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Королёва, 59к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246374, 60.028753]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колобок",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обедов",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 47",
                "opening_hours": "08:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389269, 60.055726]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На углу - Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Медиков, 9Б",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": "+7 911 0909555",
                "website": "https:\/\/vk.com\/stolovay_na_uglu"
            },
            "geometry": {"type": "Point", "coordinates": [30.315617, 59.970727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лавянги бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Металлистов",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405691, 59.969673]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Металлистов, 111",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390904, 59.973955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.400759, 59.917105]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Империя Обедов",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 51К",
                "opening_hours": "Mo-Fr 10:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421384, 59.903201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 83",
                "opening_hours": "Mo-Sa 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426864, 59.900628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 86М",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.438991, 59.89408]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 97",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432505, 59.897095]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восток",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 109-111",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280007, 59.91816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая от ЭКО ферма «Семейная»",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Римского-Корсакова, 8\/18",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.306364, 59.924253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ленинград",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278813, 59.896319]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обедов",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 47АИ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.247867, 59.885547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Тореза, 33А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355017, 60.006656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро Зам-Зам",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Чернышевского, 9",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358951, 59.947009]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 26Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436296, 59.954912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 70Бк2",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435075, 59.971596]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Питание+",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 10",
                "opening_hours": "Mo-Fr 09:00-20:30;Sa-Su 10:00-20:30",
                "contact_phone": "+7 812 2518895;+7 909 5793973",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.295234, 59.913713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 4-6",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296384, 59.913735]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Рижский проспект, 54",
                "opening_hours": "Mo-Fr 08:00-21:00; Sa 10:00-20:00",
                "contact_phone": "https:\/\/stolovayauyut.business.site\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.276809, 59.913162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Садовой",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Садовая улица, 25",
                "opening_hours": "09:00-22:00",
                "contact_phone": "http:\/\/evro-stolovaya.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32469, 59.929885]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Домашний вкус",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Садовая улица, 49",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312517, 59.92479]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Блины и кофе",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Садовая улица, 50Б",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310595, 59.92355]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Садовая улица, 65",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.30481, 59.922116]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Садовая улица, 7-9-11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335451, 59.935651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Садовая улица, 95",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.2905, 59.919351]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Светлановский проспект",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.369551, 60.019702]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро Пирамида",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, ​Седова, 114а",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420459, 59.888952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая № 1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Сенная площадь, 6",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.318231, 59.926179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 57\/26",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313694, 59.987506]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №5",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Сердобольская улица, 64К",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316308, 59.987118]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Синопская набережная, 38",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.389835, 59.932554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Софийская, 31а",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.401846, 59.868135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Когда я ем...",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Социалистическая улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343132, 59.922594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Ставропольская улица, 10",
                "opening_hours": "09:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385452, 59.949596]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ложка - Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Столярный переулок, 8",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.311412, 59.927946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 44",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380942, 59.942002]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая N5",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Сытнинская улица, 18",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310613, 59.958151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Таллинская улица, 2",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407397, 59.922206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, территория 3-й район Морского порта",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.203372, 59.881746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая ПЛП",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, территория Гладкий Остров, 1Ш",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.239386, 59.891733]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, территория Малая Охта",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411215, 59.929159]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ляванги",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, территория Озеро Долгое",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.249105, 60.013587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, территория Ольгино",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.139807, 60.00078]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Милти",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7",
                "opening_hours": "08:30-19:00",
                "contact_phone": "https:\/\/www.mealty.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257325, 59.9894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Трамвайный проспект, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263155, 59.855751]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наша столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Троицкий проспект, 5",
                "opening_hours": "Mo-Su 09:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299267, 59.916121]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовкинъ",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Тульская улица, 11",
                "opening_hours": "Mo-Fr 09:00-21:00; Sa 10:00-21:00; Su 11:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391542, 59.9428]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Закусочная",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 12",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424465, 59.896152]}
        },
        {"type": "Feature",
            "properties": {
                "name": "бистро \"Пышки\"",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 42к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.444067, 59.876675]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Восстания, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360173, 59.939794]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Восстания, 4",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359724, 59.932883]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 19",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300956, 59.927815]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №37",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 22-24",
                "opening_hours": "Mo-Fr 09:00-20:00; Sa-Su 10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.302366, 59.927824]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 31",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291748, 59.925746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Декабристов, 36\/1",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.292593, 59.925493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Достоевского, 30",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347866, 59.923581]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Еда",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Жуковского, 5",
                "opening_hours": "09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350848, 59.935795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Калинина, 13",
                "opening_hours": "Mo-Fr 10:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.252968, 59.897569]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайная ложка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Карбышева, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353265, 59.993001]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая завода «Радиоприбор»",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Коли Томчака, 32В",
                "opening_hours": "Mo-Fr 10:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325687, 59.884063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Султан",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Комиссара Смирнова, 5\/7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352483, 59.962859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Комсомола, 37к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.358978, 59.957111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая ПК «Темп»",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Константина Заслонова, 8Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349025, 59.919292]}
        },
        {"type": "Feature",
            "properties": {
                "name": "География Вкусов",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Котовского, 4\/39В",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": "+79119221712",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329064, 59.962003]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 36",
                "opening_hours": "11:30-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.330978, 59.846924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Альнаир",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 86",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349681, 59.832909]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Супница",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Университетская столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 6-8С",
                "opening_hours": "Mo-Fr 10:30-16:00; PH off; Jul-Aug off \"закрыта на время каникул\"",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321725, 59.96589]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №5",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Льва Толстого, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314871, 59.965268]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №1",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Марата, 1\/71",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/st1.one\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354909, 59.931201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Марата, 19",
                "opening_hours": "08:00-23:00",
                "contact_phone": "+7 812 3149949",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.353355, 59.92855]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовкин",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Марата, 27",
                "opening_hours": "08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352304, 59.927022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Марата, 56-58\/29",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349645, 59.922422]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Борщ",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Маршала Говорова, 35а",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.278786, 59.888866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 1к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24871, 59.860093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 35Л",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.210927, 59.863292]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кинза",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 10",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354235, 59.9336]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Два гуся",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Маяковского, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.355376, 59.934777]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая \"Все свои\"",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Мира, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313523, 59.959012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Моисеенко, 43",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393599, 59.937332]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Морошка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Оптиков, 4к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.248881, 59.995476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Малая Одесса",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34235, 59.942426]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая ложка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 13-15",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343788, 59.94248]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Пестеля, 6",
                "opening_hours": "08:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342764, 59.942746]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 39",
                "opening_hours": "10:00-16:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.29509, 59.97169]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Профессора Попова, 43",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.291829, 59.971339]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Решетникова, 5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323845, 59.878667]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Салова, 36",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378795, 59.896057]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая,магазин",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Самойловой, 5П",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.374501, 59.901766]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вкуснофф",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Седова, 13",
                "opening_hours": "Mo-Fr 08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405951, 59.897808]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Седова, 61",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428508, 59.87901]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Софьи Ковалевской, 10А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410263, 60.012575]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Стасовой, 14",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443995, 59.968588]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кавказ столовая-кафе при ресторане",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Стахановцев, 5",
                "opening_hours": "10:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406086, 59.928555]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Типанова, 3",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325336, 59.85369]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Морошка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Трефолева, 1литО",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.283735, 59.887733]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Трефолева, 2Р",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.280007, 59.885309]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Химиков, 2к10",
                "opening_hours": "09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.467728, 59.965854]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Микс",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Хлопина, 6А",
                "opening_hours": "Mo-Sa 09:00-22:00; Su 10:00-22:00",
                "contact_phone": "http:\/\/www.miks-spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367072, 60.001541]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 2\/7Р",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342099, 59.946991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 2\/7Р",
                "opening_hours": "Mo-Fr 09:30-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.342099, 59.946991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колобок",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 40",
                "opening_hours": "07:30-21:00",
                "contact_phone": "https:\/\/kolobok.spb.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359733, 59.946685]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бригантина",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 47",
                "opening_hours": "Mo-Su 07:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356014, 59.946324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чайковского, 54",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.364485, 59.946622]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Медина",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Чапаева, 11\/4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331822, 59.960201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, улица Шкапина, 6",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296941, 59.907663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Уманский переулок, 68 к3",
                "opening_hours": "08:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.443653, 59.962782]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Уральская улица, 4",
                "opening_hours": "Sa 10:00-17:00; Mo-Fr 09:00-18:00",
                "contact_phone": "+7 905 2174476",
                "website": "http:\/\/уральскиймост.рф"
            },
            "geometry": {"type": "Point", "coordinates": [30.266048, 59.950335]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Урюпин переулок, 15А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.289521, 59.898201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Фермское шоссе, 39",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.312787, 60.01745]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе \"Каравай\"",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Херсонская улица, 22",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385712, 59.927689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Автомася",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Цветочная улица, 16П",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331202, 59.886311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Цветочная улица, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329505, 59.885801]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая №5",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 18",
                "opening_hours": "Mo-Sa 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339458, 59.927405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тарелка",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Ломоносова, 20",
                "opening_hours": "08:00-22:00",
                "contact_phone": "+7 812 3149949",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340105, 59.927238]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волна FM",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 1\/38",
                "opening_hours": "Mo-Fr 08:00-22:00; Sa-Su 09:00-22:00",
                "contact_phone": "http:\/\/www.volna-fm.com\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349564, 59.938852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Еда",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Центральный район, улица Некрасова, 60",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368402, 59.938392]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро «Ел»",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Шпалерная улица, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.384401, 59.948429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Новая столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Южное шоссе, 37к1",
                "opening_hours": "10:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424142, 59.864873]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фрида",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Чайковского, 57",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35887, 59.946058]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Софийская Усадьба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Фуражный переулок, 4\/8",
                "opening_hours": "09:00-18:00",
                "contact_phone": "7‒931‒393‒03‒49",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38052, 59.707594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "33 пингвина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 16",
                "opening_hours": "Mo-Fr 10:00-20:00; Sa-Su 1:00-19:00",
                "contact_phone": "+7 800 1005033",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.76703, 59.99738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "5-ый Форт",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 348",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.974636, 60.103342]}
        },
        {"type": "Feature",
            "properties": {
                "name": "7:0",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, улица Танкистов, 26к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.567763, 59.745636]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Авокадо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 65",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.410072, 59.729545]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Акватория",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.946822, 60.110991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аль-шам",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, улица Веры Слуцкой, 81",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.578962, 59.737212]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Антонио",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 54А",
                "opening_hours": "Mo-Su 12:00-21:00",
                "contact_phone": "+7 911 0881829",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.152195, 59.790699]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Апельсин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60Ю",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.894308, 59.881832]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Атлантис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 29",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.899519, 59.874403]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аура",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, территория Павильон Урицкого",
                "opening_hours": "Mo-Th 11:00-24:00; Fr-Su 12:00-02:00",
                "contact_phone": "+7 812 9269997",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380337, 59.733304]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бабушкина кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов",
                "opening_hours": "Mo-Su 11:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763856, 59.916163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Барский уголок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Царицынская улица, 2",
                "opening_hours": "11:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.91612, 59.875967]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Башня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Школьная улица, 39\/33Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.412123, 59.733814]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Белорусский дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, бульвар Алексея Толстого, 5А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419712, 59.731121]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бель-Вю",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Фабричная улица, 1Н",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.893347, 59.888689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Берёзка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, бульвар Красных Курсантов, 63Г6",
                "opening_hours": "09:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.864175, 59.878648]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бригантина",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Краснофлотское шоссе, 49АН",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.681517, 59.929921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бриз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Коммунаров, 1",
                "opening_hours": "+7 812 4325550",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.955904, 60.093554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вена",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 30",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 4506447",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.914893, 59.878984]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Витамин",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 503к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256275, 60.102284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Влаваше",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Советская улица, 33Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778374, 59.993661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Волконский",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Екатерининский парк",
                "opening_hours": "+7 931 3613667",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399406, 59.710771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вояж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 107",
                "opening_hours": "+7 812 7044609",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.313954, 59.750558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вояж",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Екатерининский переулок, 15",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.765188, 59.916337]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гаечка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Первомайская улица, 5А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.76295, 59.917236]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гвидо",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 51",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.090559, 59.741066]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Где-то Лето",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Советская улица, 33",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778347, 59.993493]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Герард Миллер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Ботаническая улица, 66к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.82744, 59.874963]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Голландская Кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Коммунистическая улица, 1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 9215005",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.769234, 59.98806]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Приморское шоссе, 591",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.666904, 60.194311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Граф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Хабаровская улица, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.258285, 60.079254]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гуд Бар(сеть Чайникофф)",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Славянка, Колпинское шоссе, 38к1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.481386, 59.740996]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Д.В.О.Е.",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна, Фронтовая улица, 9А",
                "opening_hours": "PH,Mo-Su 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.034434, 59.847844]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Двести два",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 202",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.975656, 60.049738]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Додо Пицца",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 20к1",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 800 3330060",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340854, 60.080753]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Додо пицца",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 85",
                "opening_hours": "Mo-Th 10:00-23:00; Fr-Su 10:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.083114, 59.729121]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Душевная компания",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 16",
                "opening_hours": "Mo-Fr 10:00-20:00;Sa-Su 11:00-19:00",
                "contact_phone": "+7 921 9235758",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.767051, 59.99727]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Душенька",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 35",
                "opening_hours": "10:00-21:00",
                "contact_phone": "+7 812 4666882",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409981, 59.718327]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ДЮК",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Новодеревенская улица, 4",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.428819, 59.721395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жаркофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 18к1",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": "+7 951 6553649",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.341882, 60.079951]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Жела",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Леонтьевская улица, 36А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406291, 59.722318]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой уголок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 212Ж",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290062, 60.06955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Золотой Якорь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Макаровская улица, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763261, 59.989274]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Интеграл",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Двадцать Пятого Октября, 4к4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.11883, 59.701274]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Камин в Репино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, 1-я Новая улица",
                "opening_hours": "+7 921 9263099",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.861876, 60.163987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Карат",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 85",
                "opening_hours": "Mo-Su 11:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.082846, 59.729083]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 10А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393937, 59.69931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Арсенальный переулок, 4А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.771352, 59.985469]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 4к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.388532, 59.706458]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Средняя улица, 1\/9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398922, 59.718323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 16\/19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408476, 59.719245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "кафе \"Поворот\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Пухтоловская дорога, 3с1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.727985, 60.216886]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе бистро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный, Железнодорожная улица, 28",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.148311, 60.118678]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе персидской кухни",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Николая Соколова, 28",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 981 1536766",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.006817, 60.054795]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-бар \"Охота\"",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Суздальский проспект, 2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.300274, 60.066991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-магазин Каравай.",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Советская улица, 31",
                "opening_hours": "Mo-Su 08:00-19:00",
                "contact_phone": "+7 812 3115459",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.778574, 59.993429]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе-пиццерия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Приморское шоссе, 536В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.699413, 60.18975]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Каштан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 25\/2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 4351102",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.764422, 59.995682]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Килька",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 35",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416915, 59.727293]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Клюква",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Дворцовый проспект, 16А",
                "opening_hours": "Mo-Su 11:00-01:00",
                "contact_phone": "+7 812 4221388",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.77212, 59.915126]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Континент",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, проспект Ленина, 53",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.570258, 59.750576]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коржов",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 25",
                "opening_hours": "Mo-Sa 08:00-19:00; Su 08:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405715, 59.719348]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КруаСаныч",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Торговая площадь, 8В",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 904 6077383",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.91433, 59.877673]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крым",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, Красногородская улица, 17",
                "opening_hours": "Mo-Th 12:00-24:00; Fr 13:00-24:00; Sa 00:00-02:00,13:00-24:00; Su 00:00-02:00,12:00-24:00",
                "contact_phone": "+7 904 6111365",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.07518, 59.718222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Летнее кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, территория Форт Константин",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.70663, 59.994381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лоза",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный, Ленинградская улица, 54",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.171055, 60.120534]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лора",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Парковая улица",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298051, 60.068251]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Луна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, Заводской проспект",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.578099, 59.730651]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Люнер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Большевистская улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.76363, 59.997933]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Маленькая Грузия",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, бульвар Красных Курсантов, 63Г6",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.863729, 59.878673]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мангал шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Удаловская улица, 19",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434357, 59.719716]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мар. Ан",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Садовая улица, 21к2Г",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.575687, 59.813445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Медведь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Репино, Нагорная улица, 2А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.872087, 60.171438]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Медведь",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, улица Ижорского Батальона, 3к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.610094, 59.735554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Место встречи",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Коммунаров, 2\/4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.957111, 60.093087]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мечта",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Конюшенная улица, 38\/37",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 4517280",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415196, 59.721781]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мимино",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Красная улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773803, 59.990161]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мозайка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Дубковское шоссе, 42",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.943593, 60.090861]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мокафлор",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 16\/19",
                "opening_hours": "Mo-Su 09:00-20:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408595, 59.719163]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Молочня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.458928, 59.687311]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Муравейник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, Приморское шоссе, 350",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.973878, 60.104502]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Широкой",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Привокзальная площадь, 6\/2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426614, 59.723091]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Навруз",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, улица Танкистов, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.561166, 59.748487]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нар-Шараб",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Зосимова, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.762318, 60.001397]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Наутилус",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Петровская улица, 10к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.774983, 59.986921]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нур",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 9к2",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393873, 59.702916]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Железнодорожная улица, 56",
                "opening_hours": "12:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420172, 59.734008]}
        },
        {"type": "Feature",
            "properties": {
                "name": "оАзис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, улица Политрука Пасечника, 5к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.108687, 59.780857]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОМС",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗД",
                "opening_hours": "Mo-Fr 08:00-19:00; Sa 09:00-17:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.275676, 59.79964]}
        },
        {"type": "Feature",
            "properties": {
                "name": "от Питера до Москвы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.476428, 59.75924]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Панорама",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Московская Славянка, 17А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496572, 59.747887]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Папа Карло",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, Пролетарская улица, 60",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.565257, 59.741203]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парус",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Карла Маркса, 9",
                "opening_hours": "Mo-Su 10:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.76883, 59.998379]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пельменная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 18",
                "opening_hours": "Mo-Su 08:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.764517, 59.994512]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Петергофское",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 60Ю",
                "opening_hours": "Su-Th 11:00-23:00; Fr-Sa 12:00-00:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.894054, 59.881858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пицца и Суши",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Первомайская улица, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.3743, 59.807125]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пицца Море",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Токарева, 10",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.952928, 60.087255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Плюшка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Гусарская улица, 4к5",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.387678, 59.706571]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подворье",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.766822, 59.998028]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Подкова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Пионерская улица, 7",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.569622, 59.811029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Полис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, проспект Ленина, 50А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.707013, 60.204969]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Приоритет",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Володарского, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.97208, 60.097693]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Продуктовая лавка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Станюковича",
                "opening_hours": "Mo-Su,PH 09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.725438, 60.008889]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рекон",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 5",
                "opening_hours": "12:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398336, 59.722089]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ресторанчик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Генерала Хазова, 25",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411601, 59.737825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ретро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Колпинское шоссе, 35А",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.44684, 59.725216]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ржавая устрица",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Торговая площадь, 8В",
                "opening_hours": "Mo-Th 08:00-23:00; Fr 08:00-05:00; Sa 10:00-05:00; Su 10:00-24:00",
                "contact_phone": "+7 981 9719517",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.914291, 59.877696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Родник",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Песочный, Ленинградская улица, 70",
                "opening_hours": "Mo-Th 10:00-16:00; Fr 10:00-15:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.188477, 60.122434]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Романс",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 27",
                "opening_hours": "11:00-23:00",
                "contact_phone": "+7 812 4505324",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.918271, 59.878947]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ротонда",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Максима Горького, 2Ас2",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.955268, 60.112468]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Северное",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20ВД",
                "opening_hours": "10:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453755, 59.686543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 31",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": "+7 812 3113905",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763968, 59.994641]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сказочный дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Красносельское шоссе, 55",
                "opening_hours": "08:00-21:00",
                "contact_phone": "+7 951 6882444",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.362829, 59.704096]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Славянка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20Д",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446548, 59.687405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Славянский дворик",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петродворцовый район, посёлок Стрельна, Фронтовая улица, 3У",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "+7 812 4214113",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.03452, 59.849156]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сластена",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, бульвар Алексея Толстого, 1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.421725, 59.7304]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Софийская усадьба",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Фуражный переулок, 4\/8",
                "opening_hours": "09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380585, 59.707636]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старая Корчма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Широкая улица, 1А",
                "opening_hours": "Mo-Su 13:00-01:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420699, 59.721242]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Старая крепость",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Восстания, 13",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.768781, 60.001423]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Стимул",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Красноармейская улица, 33А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.779653, 59.906516]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Суши Шок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 35",
                "opening_hours": "10:00-22:00",
                "contact_phone": "+7 905 2899988",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41653, 59.727291]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Суши.Пицца.",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Мосина, 4Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.966014, 60.092232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Толстый Папа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Валерия Гаврилина, 5",
                "opening_hours": "Mo-Su 12:00-23:00",
                "contact_phone": "+7 812 9880812",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334041, 60.075003]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трактир",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Посадская улица, 3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.765246, 60.000816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Трюм",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Петергофская улица, 10В",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.862911, 59.874991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЧК",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Горелово, улица Мира, 11",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.134249, 59.776587]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Клюквы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.568228, 59.807303]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Михалыча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, территория Решетниково, 5А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.695503, 60.237799]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Палыча",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 16",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.767197, 59.996246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "У Штиглица",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Привокзальная площадь, 7",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.924651, 59.864489]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Улыбка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Привокзальная площадь, 4",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427272, 59.72209]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Флора",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Широкая улица, 26",
                "opening_hours": "09:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.426292, 59.722785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фортуна",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Андреева, 1",
                "opening_hours": "+7 812 4343910",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.951396, 60.110547]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Форум",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Садовая улица, 17",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.56927, 59.811257]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапурный дом",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Кадетский бульвар, 24",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.407362, 59.704258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хуторок",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Радищева, 16",
                "opening_hours": "12:00-24:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.408878, 59.708244]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чайникофф",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Фёдора Абрамова, 8к1",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338308, 60.07257]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Гражданская улица, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.765832, 59.99807]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Поселковая улица, 12соор1",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.391977, 59.810983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, Выборгское шоссе, 399",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.26386, 60.084091]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Ленинградская улица, 35",
                "opening_hours": "09:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417222, 59.726983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шайба в игре",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово, Привокзальная улица, 24А",
                "opening_hours": "+78124337331",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.807063, 60.185474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шашлычная",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 2к3",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.12713, 59.774269]}
        },
        {"type": "Feature",
            "properties": {
                "name": "шоколад",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, проспект Ленина, 9",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.706019, 60.194513]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Московский район, муниципальный округ Пулковский Меридиан",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6771262",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269057, 59.79758]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317515, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шоколадница",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "contact_phone": "+7 812 6777648",
                "website": "http:\/\/www.33pingvina.ru\/"
            },
            "geometry": {"type": "Point", "coordinates": [30.269168, 59.800204]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Штолле",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Конюшенная улица, 39",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "+7 812 6705700",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416637, 59.721467]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эрмитажная кухня",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Екатерининский парк, литЕ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404808, 59.714554]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bona Capona",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Октябрьский бульвар, 33",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413266, 59.722644]}
        },
        {"type": "Feature",
            "properties": {
                "name": "BullHouse",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, проспект Ленина, 18\/12",
                "opening_hours": "12:00-24:00",
                "contact_phone": "+7 812 4616990",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.585504, 59.74979]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317539, 59.819663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "http:\/\/vk.com\/pasta_antonio",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.338073, 60.066512]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cirkle K",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Витебский проспект, 130",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393748, 59.792308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Costa",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "+7 960 2440943",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272312, 59.799814]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Deli",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Московское шоссе, 309",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/vk.com\/aurapushkin",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.484613, 59.756124]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Drive Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, Гатчинское шоссе, 17",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.079583, 59.716511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "First House Burger",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 29\/23",
                "opening_hours": "10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409807, 59.720322]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ice",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗВ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.270373, 59.801756]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Keb House & Good Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 23Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.409064, 59.719632]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Kebcake coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица, 61",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416614, 59.725664]}
        },
        {"type": "Feature",
            "properties": {
                "name": "l`Americano",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, проспект Ленина, 13",
                "opening_hours": "+7 812 3111010",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.765827, 59.997663]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Love",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Санкт-Петербургский проспект, 41",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.914012, 59.879452]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Night city",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Железнодорожная улица, 56",
                "opening_hours": "http:\/\/www.kafe-brizspb.ru",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420344, 59.734479]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Park cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Нижний парк",
                "opening_hours": "summer",
                "contact_phone": "https:\/\/kafevena.wixsite.com\/kafevena",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.913093, 59.889934]}
        },
        {"type": "Feature",
            "properties": {
                "name": "People Love Coffee",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Пулковское, Кокколевская улица",
                "opening_hours": "Mo-Fr 07:00-19:00, Sa-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.365146, 59.755049]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pizza Bonifacio",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Советская улица, 35",
                "opening_hours": "Mo-Th 12:00-01:00; Fr-Su 12:00-02:00",
                "contact_phone": "+7 812 3119357",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.777528, 59.994188]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Puro Gusto",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41А",
                "opening_hours": "http:\/\/wolkonsky.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267767, 59.797735]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Pushkin Cake",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 5",
                "opening_hours": "Mo-Su 08:00-22:00",
                "contact_phone": "http:\/\/cafe.voyzh.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398702, 59.721983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Smile",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 32\/17",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_phone": "+7 812 4664322",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.411881, 59.71833]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Starbucks",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 41литЗА",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269237, 59.80032]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sushi City",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпинский район, посёлок Металлострой, Полевая улица, 9",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.566279, 59.813346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Sushiset",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 12",
                "opening_hours": "http:\/\/vk.com\/letogde_to",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323424, 60.071544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Taxi",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Сапёрная улица, 65А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376735, 59.69859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wake up!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, Оранжерейная улица",
                "opening_hours": "08:00-20:00",
                "contact_phone": "http:\/\/www.gollandkitchen.ru",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.417894, 59.726737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Wild Bean",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Московское шоссе, 231к4",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472333, 59.762469]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ACADEMIA",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Дворцовый проспект, 16А",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "7 (812) 422‒13‒88",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.772264, 59.91507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кюхельбекер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, Павловск, Садовая улица, 20ВД",
                "opening_hours": "10:00-19:00",
                "contact_phone": "7 (812) 320‒28‒98",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.453756, 59.686543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, ​Бадаевский 1-й проезд, 5Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397385, 59.806228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Queenstown",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, улица Коммуны, 17\/10",
                "opening_hours": "Mo-Th 11:00-24:00; Fr-Su 12:00-01:00",
                "contact_phone": "7 (812) 461‒50‒83",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.59066, 59.749356]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Bigшаверма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Колпино, улица Братьев Радченко, 7\/25А",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/dodopizza.ru\/",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.579555, 59.750983]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дюнер",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Посадская улица, 16",
                "opening_hours": "Mo-Su 10:00-24:00",
                "contact_phone": "7‒953‒140‒03‒80",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.763764, 59.998155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговая душа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Ломоносов, Красноармейская улица, 33А",
                "opening_hours": "Mo-Su 08:00-20:00",
                "contact_phone": "7 (812) 920‒52‒50",
                "website": "http:\/\/www.facebook.com\/dk144"
            },
            "geometry": {"type": "Point", "coordinates": [29.779682, 59.906523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Металлострой, дорога на Металлострой, 10",
                "opening_hours": "Mo-Fr 09:00-18:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.561109, 59.799592]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Neste",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 109",
                "opening_hours": "24\/7",
                "contact_phone": "7 (812) 704‒68‒78",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.31323, 59.749171]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шаверма",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Генерала Хазова, 23А",
                "opening_hours": "24\/7",
                "contact_phone": "http:\/\/кофеспб.рус",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.406825, 59.737797]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Газпромнефть",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 190к1",
                "opening_hours": "24\/7",
                "contact_phone": "8‒800‒700‒51‒51",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360918, 60.087205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Circle К",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 53к2",
                "opening_hours": "24\/7",
                "contact_phone": "8‒800‒555‒46‒47",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.32403, 59.7817]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Neste",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Пулковское шоссе, 72",
                "opening_hours": "24\/7",
                "contact_phone": "8‒800‒222‒24‒41",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326823, 59.781244]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Александровская, Волхонское шоссе, 10А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.343795, 59.732713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shell",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 81",
                "opening_hours": "24\/7",
                "contact_phone": "7‒981‒859‒24‒83",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.319504, 59.755237]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Молодёжное, Приморское шоссе, 659",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "7 (812) 433‒22‒81",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.519173, 60.193522]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Павловск, улица Красного Курсанта, 9",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "7 (812) 452‒21‒25",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.456202, 59.683165]}
        },
        {"type": "Feature",
            "properties": {
                "name": "На Львовской",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Стрельна, Львовская улица, 29",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.028922, 59.849232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Зеленогорск, Вокзальная улица, 2",
                "opening_hours": "Mo-Su 07:00-23:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.701438, 60.205749]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Appetit",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 29",
                "opening_hours": "24\/7",
                "contact_phone": "7‒960‒230‒33‒77",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.89937, 59.87421]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Газпромнефть",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Астрономическая улица, 11",
                "opening_hours": "24\/7",
                "contact_phone": "8‒800‒700‒51‒51",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.80626, 59.874956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сан-МарьинО у Марата",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, территория Марьино, 18А",
                "opening_hours": "Mo-Su 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.939021, 59.834099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, территория Старо-Паново, Рабочая улица, 3",
                "opening_hours": "Mo-Sa 08:00-19:00",
                "contact_phone": "7‒981‒189‒53‒23",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.169406, 59.814051]}
        },
        {"type": "Feature",
            "properties": {
                "name": "X-BURGERS",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Форт Константин, 3",
                "opening_hours": "7‒931‒953‒51‒83",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.703214, 59.998111]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Киоск по продаже шавермы и самсы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, ​Железнодорожная, 81е",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420669, 59.739646]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Uno Momento",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Тярлево, Нововестинская улица, 2А",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "7 (812) 244‒28‒88",
                "website": "http:\/\/karavay.spb.ru"
            },
            "geometry": {"type": "Point", "coordinates": [30.453119, 59.699743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Коптильщики",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Ораниенбаумское шоссе, 2А",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "7‒931‒397‒52‒53",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.862683, 59.893775]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Газпромнефть",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Песочный, Белоостровское шоссе, 13",
                "opening_hours": "24\/7",
                "contact_phone": "8‒800‒700‒51‒51",
                "website": "http:\/\/vk.com\/kashtan_kafe"
            },
            "geometry": {"type": "Point", "coordinates": [30.121268, 60.129544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пироговый дом",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 67",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": "7 (812) 929‒33‒01",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.08851, 59.735894]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Shawermoon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, Красногородская улица, 17",
                "opening_hours": "24\/7",
                "contact_phone": "https:\/\/vk.com\/cafe_klukva",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.075245, 59.717897]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Green Cafe",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Константиновская улица, 1",
                "opening_hours": "Mo-Su 09:00-19:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.917543, 59.877297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Авенариус",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, улица Максима Горького, 2К",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "7 (812) 980‒55‒17",
                "website": "https:\/\/www.korjov.com"
            },
            "geometry": {"type": "Point", "coordinates": [29.948508, 60.116431]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сова",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Поселковая улица, 12В",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_phone": "7‒911‒029‒97‒67",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394516, 59.80863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бистро",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Лисий Нос, Центральная улица, 2",
                "opening_hours": "24\/7",
                "contact_phone": "7‒981‒947‒05‒98",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.00962, 60.015162]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Circle К",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, Пулковское шоссе, 68",
                "opening_hours": "24\/7",
                "contact_phone": "8‒800‒555‒46‒47",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327323, 59.783771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кафе",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, улица Гидростроителей, 8",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.718188, 60.010013]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Чебуреки и булочная у Ларисы",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Курортный район, посёлок Комарово, 2-я Дачная улица, 3Б",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.810794, 60.18517]}
        },
        {"type": "Feature",
            "properties": {
                "name": "столовка Третьего парка",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Сестрорецк, дорога к Шалашу Ленина, 1Е",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.028753, 60.079224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ежевика",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Пушкин, улица Архитектора Данини, 11\/6",
                "opening_hours": "11:00-23:30",
                "contact_phone": "+7 812 4071407",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.413572, 59.696411]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кают Компания Палуба 1",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Кронштадт, Якорная площадь, 7АЩ",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.77366, 59.992182]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СтрекоZа",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Мастеровой переулок, 3",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.893952, 59.880426]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Хачапури",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, ​Аннинское шоссе, 57Б",
                "opening_hours": "24\/7",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.126787, 59.781601]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Chicken's",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Красное Село, проспект Ленина, 59",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.088842, 59.738129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Cinnabon",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Михаила Дудина, 6к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_phone": "+7 814 6404940;+7 812 6408731",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33723, 60.066616]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Daam",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, посёлок Шушары, Колпинское шоссе, 36к1",
                "opening_hours": "Mo-Su 11:00-23:00",
                "contact_phone": "7‒931‒397‒04‒04",
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477719, 59.738839]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Пушкинский район, посёлок Шушары, территория Детскосельский, Центральная улица, 15",
                "opening_hours": "https:\/\/mechtapushkin.ru\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.463762, 59.726443]}
        },
        {"type": "Feature",
            "properties": {
                "name": "столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Колпино, Северная улица, 14",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.614347, 59.762413]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, посёлок Парголово, улица Первого Мая, 109",
                "opening_hours": "Mo-Su 12:00-15:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.232969, 60.073665]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Столовая",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Ломоносов, Пароходная улица, 6А",
                "opening_hours": null,
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.756987, 59.919603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Теремок",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 9",
                "opening_hours": "http:\/\/pavlovskmuseum.ru\/about\/park\/layout\/36\/1140\/",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.903115, 59.881245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тёщины блины",
                "city_function": "Питание",
                "type": "Столовая",
                "address": "Россия, Санкт-Петербург, Пушкин, Московская улица, 35",
                "opening_hours": "10:00-22:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40992, 59.718287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тандыр Да!",
                "city_function": "Питание",
                "type": "Кафе",
                "address": "Россия, Санкт-Петербург, Петергоф, Разводная улица, 29",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_phone": null,
                "website": null
            },
            "geometry": {"type": "Point", "coordinates": [29.89972, 59.874963]}
        }
    ]
}
