//https://t.me/elemnt3 Y:2021
import React from "react";
import {Anchor} from "antd";
import logo from '../../assets/new_logo.svg';
// import logo from '../../assets/logo_.svg';

const {Link} = Anchor

const NHeader = () => {
    return (
        <div className="container-fluid">
            <div className="header">
                <div className="logo">
                    <img
                        src={logo}
                        className='logo logo-header'
                        alt='logo'
                    />
                    <a href="#_main">
                        Leisure Spb
                    </a>
                </div>
                <Anchor  targetOffset='25'>
                    <Link href="#_main" title="Главная"/>
                    <Link href="#_download" title="Данные"/>
                    <Link href="#_analytics" title="Аналитика"/>
                    <Link href="#_map" title="Карта"/>
                    <Link href="#_about" title="О сервисе"/>
                </Anchor>
            </div>
        </div>
    )
}

export default NHeader;
