export const shopping_centers = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Академ-Парк",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/academ-park.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.398567, 60.012246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Адмиралтейский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Московский проспект, 3АБ",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/admtk.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.316982, 59.925344]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Атлантик Сити»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 126",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/atlantic-city.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204342, 59.987407]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Парк Хаус»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 41",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/atrium-northmall.ru\/",
                "contact_phone": "+7 812 4480300"
            },
            "geometry": {"type": "Point", "coordinates": [30.389656, 60.053224]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Французский Бульвар",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, бульвар Новаторов, 11к2",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/frbulvar.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268293, 59.849908]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Гарден Сити",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85литВ",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/gardencity.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.146042, 59.994063]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мебельный центр Круиз",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 4к2",
                "opening_hours": "11:00-21:00",
                "contact_website": "http:\/\/kruizmebel.ru\/",
                "contact_phone": "+7 967 5352110;+7 812 9063366"
            },
            "geometry": {"type": "Point", "coordinates": [30.266146, 60.002018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Olympic Plaza",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, Стремянная улица, 21\/5",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/olympic-plaza-spb.com\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35402, 59.93034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пассаж",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Невский проспект, 48",
                "opening_hours": "10:00-21:00; Su 11:00-21:00",
                "contact_website": "http:\/\/passage.spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.334266, 59.934754]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Платформа",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Мебельная улица, 2Д",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "http:\/\/platforma-tk.ru\/",
                "contact_phone": "+7 812 6359662"
            },
            "geometry": {"type": "Point", "coordinates": [30.252222, 59.990287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Платформа",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Науки, 19к3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/platforma-tk.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.393779, 60.013681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Платформа",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Одоевского, 27",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "http:\/\/platforma-tk.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.236753, 59.948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Платформа",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 65к5",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "http:\/\/platforma-tk.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.434167, 59.93245]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Платформа",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Транспортный переулок, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/platforma-tk.ru\/ligovskij-prospekt.html",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.356732, 59.920668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Порт Находка»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, территория Рыбацкое",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/portnahodka.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.5027, 59.834732]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Сенная",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 3С",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/sennaya.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320171, 59.925155]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый Дом купца Яковлева",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Садовая улица, 38",
                "opening_hours": "10:00-21:00",
                "contact_website": "http:\/\/tdya.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321788, 59.928158]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Термин",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Измайловский проспект, 12",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.308268, 59.912639]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Старая Деревня",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 2к1",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "http:\/\/tk-sd.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.254765, 59.988563]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Морской",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Морская набережная, 35",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_website": "http:\/\/tkmorskoy.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.219811, 59.959264]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Остров",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 19",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/tkostrov.spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.47929, 59.944373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Перрон\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 65",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tkperron.ru",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.432568, 59.932108]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Родео Драйв»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tkrodeo.ru\/",
                "contact_phone": "+7 812 4566845"
            },
            "geometry": {"type": "Point", "coordinates": [30.367755, 60.033701]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Пять озёр»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Долгоозёрная улица, 14к2",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/trk-5ozer.ru\/",
                "contact_phone": "+7 812 6779353"
            },
            "geometry": {"type": "Point", "coordinates": [30.246051, 60.016771]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ «Адмирал»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Малая Морская улица, 4\/1",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/trk-admiral.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314727, 59.936034]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сити Молл",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 17к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/trk-citymall.ru",
                "contact_phone": "+7 921 8559894"
            },
            "geometry": {"type": "Point", "coordinates": [30.300875, 60.005374]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Европолис»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Полюстровский проспект, 84",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/trk-europolis.ru\/",
                "contact_phone": "+7 812 6020692"
            },
            "geometry": {"type": "Point", "coordinates": [30.353903, 59.9876]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лондон Молл",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/trk-londonmall.ru\/",
                "contact_phone": "+7 (812) 456-62-92"
            },
            "geometry": {"type": "Point", "coordinates": [30.446878, 59.912914]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Континент»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, улица Ленсовета, 97",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/trkcontinent.ru\/na_zvezdnoy\/?cont=4",
                "contact_phone": "+7 812 411-47-10"
            },
            "geometry": {"type": "Point", "coordinates": [30.347354, 59.832227]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый комплекс",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 1\/44",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/uslugaspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290401, 59.924695]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Владимирский Пассаж",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 19",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/vpassage.spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346932, 59.928519]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гранд Палас",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Невский проспект, 44",
                "opening_hours": "11:00-21:00",
                "contact_website": "http:\/\/www.grand-palace.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.333304, 59.935484]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Гранд Каньон»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 154",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.grandcanyon.ru",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.33502, 60.059086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Гулливер",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, улица Торфяная дорога, 7Б",
                "opening_hours": "PH,Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.gulliverspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.277672, 59.985768]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ «Июнь»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, Индустриальный проспект, 24",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/www.june.ru\/spb\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.473981, 59.946175]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Капитолий\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 19к2",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/www.kapitoliy.ru\/ru\/malls\/udelnyi-park",
                "contact_phone": "+7 812 3368694"
            },
            "geometry": {"type": "Point", "coordinates": [30.297659, 60.008594]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Космос\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Типанова, 27\/39",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.kosmostk.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349699, 59.853812]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Кубатура",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Фучика, 9",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.kubatura.spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.368923, 59.87603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мебель Холл",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, площадь Карла Фаберже, 8Е",
                "opening_hours": "11:00-20:00",
                "contact_website": "http:\/\/www.m-holl.ru\/",
                "contact_phone": "+7 812 3371717"
            },
            "geometry": {"type": "Point", "coordinates": [30.439692, 59.935205]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Масштаб\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 13",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/www.macshtab.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320405, 59.806966]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Невский Центр»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Невский проспект, 114-116",
                "opening_hours": "Mo-Su 10:00-23:00",
                "contact_website": "http:\/\/www.nevskycentre.ru",
                "contact_phone": "+7 812 3139313"
            },
            "geometry": {"type": "Point", "coordinates": [30.359149, 59.931941]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ «Жемчужная Плаза»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, Петергофское шоссе, 51",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.pearlplaza.ru",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.144245, 59.849202]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ«Питерлэнд»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Приморский проспект, 72",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "http:\/\/www.piterland.ru",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.209894, 59.981708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Планета Нептун»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, улица Марата, 86",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.planeta-neptun.ru",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.339072, 59.918886]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Южный полюс»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Пражская улица, 48\/50",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/www.southpolus.ru",
                "contact_phone": "+7 931 2349349"
            },
            "geometry": {"type": "Point", "coordinates": [30.404074, 59.863328]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Светлановский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 33к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.svetlanovsky.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.326298, 60.009602]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Звенигородский»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Звенигородская улица, 1к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.tkzvenigorodskiy.ru",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335802, 59.922314]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Питер Радуга»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Космонавтов, 14",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.trkraduga.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.35048, 59.868948]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центр услуг «Нарвский»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Нарвский проспект, 18",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.uslugaspb.ru\/lease\/narvskiy",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.274213, 59.903548]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центр услуг «Суворовский»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Суворовский проспект, 35",
                "opening_hours": "Mo-Fr 10:00-20:00; Sa 10:00-18:00",
                "contact_website": "http:\/\/www.uslugaspb.ru\/lease\/suvorovskiy",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.376091, 59.938671]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ДаЛпортСити",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 35к1",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_website": "https:\/\/dalportcityspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.241928, 60.003858]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Варшавский Экспресс»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, набережная Обводного канала, 118С",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "https:\/\/discount.spb.ru\/",
                "contact_phone": "+7 812 3331020"
            },
            "geometry": {"type": "Point", "coordinates": [30.307352, 59.908101]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лето",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/letomall.ru\/",
                "contact_phone": "+7 812 3633231"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Радиус",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Волковский проспект, 32",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/radius-sale.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.357496, 59.896012]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Пик",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Ефимова, 2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/tk-pik.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320845, 59.926499]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Бонус",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 31к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/tkbonus.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.497849, 59.94472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Атмосфера»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Комендантская площадь, 1",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/trkatmosfera.ru\/",
                "contact_phone": "+7 812 4599512"
            },
            "geometry": {"type": "Point", "coordinates": [30.259822, 60.007668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Континент»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Байконурская улица, 14",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/trkcontinent.ru\/",
                "contact_phone": "+7 812 3333911"
            },
            "geometry": {"type": "Point", "coordinates": [30.272623, 60.002081]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Континент»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 99",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/trkcontinent.ru\/na_stachek\/",
                "contact_phone": "+7 812 3331200"
            },
            "geometry": {"type": "Point", "coordinates": [30.248342, 59.858787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Афонский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Афонская улица, 25",
                "opening_hours": "09:00-23:00",
                "contact_website": "https:\/\/vk.com\/universam_afonskiy",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303489, 60.026145]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Априори\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Большой проспект Петроградской стороны, 58",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.apriori-gallery.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.303534, 59.96107]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балкания Nova 2",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская улица, 17",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.380762, 59.825208]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балканский-1",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5И",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/1\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379828, 59.828659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балканский-2",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Е",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/2\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37928, 59.829373]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балканский-3",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Я",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/3\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378157, 59.829287]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балканский-4",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Д",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/4\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.378885, 59.830056]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балканский-5",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5В",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/5\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377843, 59.829929]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балканский-6",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5литО",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/6\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.377412, 59.830757]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Балкания Nova 1",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Балканская площадь, 5Ю",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.balkansky.ru\/buildings\/7\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.37919, 59.82741]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ Галерея",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 30",
                "opening_hours": "10:00-23:00",
                "contact_website": "https:\/\/www.galeria.spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.360613, 59.927558]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Галерея",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 11В",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367638, 59.868401]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Лиговъ»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Лиговский проспект, 153",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/www.ligov.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.349133, 59.914615]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Villa",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119к3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.tc-villa.ru\/",
                "contact_phone": "+79213973293"
            },
            "geometry": {"type": "Point", "coordinates": [30.226099, 59.988946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ «Меркурий»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 141",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.trk-mercury.ru\/",
                "contact_phone": "+7 812 3448028"
            },
            "geometry": {"type": "Point", "coordinates": [30.20578, 59.990899]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Монпансье»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Планерная улица, 59",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.trk-monpansie.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.225291, 60.02222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Норд»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 19",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.trk-nord.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331193, 60.052703]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Невский»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.trknevsky.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.482479, 59.908507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Дельта",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 105к4",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.242359, 59.854581]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Шувалово\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, пр. Энгельса, 139\/21",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.335559, 60.051917]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Эврика",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 115",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.evrika.biz\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.269811, 59.838164]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Сытный»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Сытнинская площадь, 4Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310002, 59.957395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Виктория",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 129к6",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.271312, 59.851331]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ «Рио»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, улица Фучика, 2",
                "opening_hours": "Mo-Su 09:00-23:00",
                "contact_website": "https:\/\/www.spb-rio.ru\/",
                "contact_phone": "+7 812 414-31-61"
            },
            "geometry": {"type": "Point", "coordinates": [30.35852, 59.877416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Северная галерея»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 84к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/northgallery.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.415572, 60.036852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Благодатный»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Юрия Гагарина, 2к3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.337086, 59.874183]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый комплекс",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Адмирала Трибуца, 3",
                "opening_hours": "Mo-Su 10:00-20:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.145377, 59.855258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Славянский базар",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Товарищеский проспект, 20\/27",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486548, 59.915025]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Академический",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 41",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/akadem.tkspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.397812, 60.011536]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Гражданочка\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 51",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.375507, 60.04384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Модуль",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 30к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483646, 59.923955]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Гулливер»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Торфяная дорога, 7Т",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/trk-gulliver.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257684, 59.990714]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отал",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Школьная улица, 106",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.219066, 59.990696]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Зебра",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Косыгина, 30к1",
                "opening_hours": "Mo-Su 09:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.49766, 59.946049]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый комплекс",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Дыбенко, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.477816, 59.904022]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Пражский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Пражская улица, 26к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.392, 59.872656]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Славянский базар",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Софийская улица, 33к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.399304, 59.875524]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый город",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 11Б",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367934, 59.86879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Международный\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 74",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381688, 59.870867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ракита",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Маршала Захарова, 34А",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.194048, 59.853989]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Измайловский гостиный двор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, 1-я Красноармейская улица, 15",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.309077, 59.916275]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Юго-Запад\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Маршала Жукова, 35к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/tk-uz.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.224482, 59.855877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Путиловский рынок",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Народного Ополчения, 6",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/putilovskiyspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.282029, 59.852859]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Чудный\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Чудновского, 10",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.476262, 59.924952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Призма",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Бутлерова, 42",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.403732, 60.010659]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр \"Сытный\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Сытнинская площадь, 4",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310065, 59.957021]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый двор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 138к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.332128, 60.050785]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Лидер",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Гаккелевская улица, 34",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.257666, 60.006597]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Драйвпарк",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 2к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.177016, 59.988743]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Бульвар\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Сикейроса, 10к4",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.329702, 60.035571]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Шайба\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Стачек, 66",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263056, 59.880405]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ ''Флагман''",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Светлановский проспект, 48к4",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.38255, 60.025296]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Миксер",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Димитрова, 22к3",
                "opening_hours": "09:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394363, 59.850816]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Электра",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Московский проспект, 137",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tc-elektra.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314673, 59.881719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый город",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Будапештская улица, 11Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367934, 59.86879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Чёрная речка»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 7",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.297641, 59.986475]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый дом «Ладога»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 31",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.427421, 59.958877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Народный",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Хошимина, 14",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tknarodniy.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.323468, 60.053179]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Комендант",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Уточкина, 3к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.263694, 60.01024]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Космос",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Авиаконструкторов, 5к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246976, 60.011068]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Ланской",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Студенческая улица, 10",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/www.tk-lanskoy.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.327618, 59.989265]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Елизаровский\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 8к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_website": "http:\/\/elizarovskij.com\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.422848, 59.897032]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Балатон\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 5",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.385065, 59.830676]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Заневский каскад-2",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 71к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/zkaskad.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.440222, 59.933302]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торгово-развлекательный комплекс \"Вояж\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 124к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324348, 60.040222]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Питер»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Типанова, 21",
                "opening_hours": "10:00-21:00",
                "contact_website": "http:\/\/tk-piter.ru\/",
                "contact_phone": "+7 812 335-36-84, +7 812 335-69-80"
            },
            "geometry": {"type": "Point", "coordinates": [30.340707, 59.853288]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Фиолент",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 100к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/tk-fiolent.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.220036, 59.853265]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРЦ «Пулково 3»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Шереметьевская улица, 15",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/www.pulkovotri.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.320584, 59.805912]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Смайл\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 27",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tc-smile.ru\/",
                "contact_phone": "+7 812 4493049"
            },
            "geometry": {"type": "Point", "coordinates": [30.485892, 59.900507]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Виктория\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Пограничника Гарькавого, 36к7",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.147847, 59.832308]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Бум",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 31к1",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.483143, 59.922084]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Silver",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Просвещения, 30к4",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.328786, 60.054949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Озерки»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 120",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tk-ozerki.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.321204, 60.036537]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рынок \"Гражданский\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Руставели, 45",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435389, 60.019023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Каменка",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Глухарская улица, 9",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.218419, 60.030043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Нео",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 38",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/tkneo.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.431077, 59.933099]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Орловский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, шоссе Революции, 8",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.414045, 59.959719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Международный\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 74к3",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.381625, 59.871346]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нарва-2",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Трамвайный проспект, 17к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_website": "http:\/\/narvaspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268392, 59.853315]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр \"Алекс\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Бабушкина, 38к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/nevsky-stars.ru\/tc_alex",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.442414, 59.878093]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Польза\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Уткин проспект, 13к15",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437904, 59.932054]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Ржевка\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Наставников, 35к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.488237, 59.950244]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"ON\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Обуховской Обороны, 120Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.472903, 59.865384]}
        },
        {"type": "Feature",
            "properties": {
                "name": "РК «Крыша»",
                "city_function": "Культура, развлечения, творчество",
                "type": "РК",
                "address": "Россия, Санкт-Петербург, проспект Культуры, 20",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.373055, 60.0457]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пактор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Энгельса, 113к1",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/www.pactor.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.325633, 60.037836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нарва",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 128к2",
                "opening_hours": "Mo-Su 09:00-21:00",
                "contact_website": "http:\/\/narvaspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268419, 59.852425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Долина роз»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Аккуратова, 13",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.310793, 60.016186]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Рубикон»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Гражданский проспект, 119",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.420657, 60.033971]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Штрих-код\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Народная улица, 3",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "http:\/\/tk-shtrikhkod.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.460686, 59.878527]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Орион",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 35",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.262454, 60.008437]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Ульянка",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 101",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.204836, 59.834135]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Интерио»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Полевая Сабировская улица, 54",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.268518, 59.997185]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Пионер",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Пионерская улица, 21",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.290922, 59.958043]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Галерея 1814",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, площадь Стачек, 7",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.27221, 59.900719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Ярус\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Есенина, 5Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.331481, 60.037032]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр \"Сампсониевский\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Фокина, 1к1",
                "opening_hours": "11:00-20:30",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.340608, 59.966867]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Заневский каскад",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Заневский проспект, 67к2",
                "opening_hours": "10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.437635, 59.933023]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Променад",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Комендантский проспект, 9к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": "+7 812 3136533"
            },
            "geometry": {"type": "Point", "coordinates": [30.263559, 60.004307]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невский Атриум",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Марата, 1\/71",
                "opening_hours": "10:00-22:00",
                "contact_website": "http:\/\/nevskyatrium.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.354909, 59.931201]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый комплекс Румба",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Васи Алексеева, 6",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.265616, 59.879918]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Ладожские ряды»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Энергетиков, 3Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.436817, 59.930787]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Международный»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, улица Белы Куна, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/xn--80ahbbfvbqmcjqhtv8k.xn--p1ai\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.379325, 59.870014]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «Невский 2»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 18к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.48353, 59.908737]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Renaissance Hall",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Владимирский проспект, 21",
                "opening_hours": "Mo-Su 10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.34606, 59.928235]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Эврика",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Седова, 11",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40101, 59.899803]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК Лето",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Пулковское шоссе, 25к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/letomall.ru\/",
                "contact_phone": "8 800 200-95-12"
            },
            "geometry": {"type": "Point", "coordinates": [30.317252, 59.819802]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ \"Купеческий двор\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Коломяжский проспект, 15к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.299429, 60.001523]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грифонъ",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 33к1",
                "opening_hours": "Mo-Su 11:00-20:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.490007, 59.897718]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Мир",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Стародеревенская улица, 34к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.24659, 60.007137]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Крокус",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Стародеревенская улица, 36",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/krokusspb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246994, 60.00792]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сабина",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Стародеревенская улица, 34к1Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.246779, 60.007425]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Уют",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, проспект Большевиков, 24к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.490617, 59.900064]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Галерея дизайна",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Ленинский проспект, 104",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226881, 59.852624]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Тимуровский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Тимуровская улица, 13",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.402672, 60.043835]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Миллер",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Испытателей, 37",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/www.millercenter.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.264538, 60.007092]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Шкиперский молл",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Малый проспект Васильевского острова, 88",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.230627, 59.938252]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Подсолнух",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Савушкина, 119к3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.226099, 59.988946]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Южный\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Ярослава Гашека, 6",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/uzh.spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.390365, 59.833325]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК «River House»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Академика Павлова, 5",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.317692, 59.977481]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый двор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Коллонтай, 18",
                "opening_hours": "10:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.464476, 59.91931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Правобережный",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Искровский проспект, 12",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.462886, 59.911065]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эврика",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Шлиссельбургский проспект, 5к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.489674, 59.840845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК Зевс",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 36",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.214484, 59.859528]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Вега\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Маршала Казакова, 7",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.240796, 59.860062]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦФТ Нарвский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Промышленная улица, 6",
                "opening_hours": "Mo-Su 11:00-20:00",
                "contact_website": "http:\/\/cdp-narvsky.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.272929, 59.898774]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург,просп. Большевиков, 3к9",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.446034, 59.906197]}
        },
        {"type": "Feature",
            "properties": {
                "name": "галн",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Зины Портновой, 32",
                "opening_hours": "24\/7",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.256858, 59.852633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТК \"Клондайк\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, 2-й Муринский проспект, 38",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.359877, 59.999467]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый двор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, проспект Науки, 21к1",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.40048, 60.010987]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Экополис",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Выборгское шоссе, 13",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/ecopolis-spb.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.314045, 60.048584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вещевой рынок",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Руставели, 45Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.435847, 60.018232]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Северный торговый дом",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Шаврова, 10к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.23157, 60.023565]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Свобода",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Планерная улица, 24Б",
                "opening_hours": "Mo-Su 11:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.235343, 60.007312]}
        },
        {"type": "Feature",
            "properties": {
                "name": "торговый комплекс «Богатырский»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Богатырский проспект, 14к2Б",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.267063, 59.998661]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговые ряды",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, улица Подвойского, 34к6",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.486332, 59.915716]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Аура",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 85к2",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151252, 59.993266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый комплекс ",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый комплекс",
                "address": "Россия, Санкт-Петербург, Замшина улица, 58А",
                "opening_hours": "24\/7",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.405152, 59.979772]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК \"Континент\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Бухарестская улица, 30",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/trkcontinent.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.36956, 59.883611]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ладога",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, улица Кржижановского, 8",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.46524, 59.931603]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Туристская улица, 34",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.211798, 60.007672]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТРК «Охта-Молл»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный комплекс",
                "address": "Россия, Санкт-Петербург, Брантовская улица, 3",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "http:\/\/okhtamall.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.41806, 59.940132]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Дудергофский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, территория Горелово, Красносельское шоссе, 54к3",
                "opening_hours": "11:00-21:00",
                "contact_website": "http:\/\/dudergofskiy.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.14879, 59.792029]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарден Plus",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Лахтинский проспект, 83",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.149635, 59.992866]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торгово-развлекательный центр \"Оазис\"",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия, Санкт-Петербург, проспект Ветеранов, 147",
                "opening_hours": "09:00-21:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.135954, 59.83607]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Дунай",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Дунайский проспект, 27к1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "https:\/\/dunay-tc.ru\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.363209, 59.83186]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый дом",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Лермонтовский проспект, 54",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.298809, 59.910356]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Свечной»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Большая Московская улица, 14\/1",
                "opening_hours": "Mo-Su 10:00-22:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.346555, 59.92552]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ «Светлана»",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия, Санкт-Петербург, Апраксин переулок, 1",
                "opening_hours": "11:00-20:00",
                "contact_website": "no",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.324519, 59.928956]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Березка",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Садовая улица, 17",
                "opening_hours": "09:00-21:00",
                "contact_website": "+7 (812) 464-71-68",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.569561, 59.811424]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый дом Усть-Ижора",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Петрозаводское шоссе, 17",
                "opening_hours": "10:00-20:00",
                "contact_website": "+7 (812) 920-41-51",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.605709, 59.797462]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Дворик",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Павловская улица, 82",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.567472, 59.748017]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эльф",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Пролетарская улица, 60",
                "opening_hours": "09:00-21:00",
                "contact_website": "+7 (812) 481-20-62",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.564752, 59.74153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ока",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, бульвар Трудящихся, 12",
                "opening_hours": "10:00-20:00",
                "contact_website": "+7 (812) 481-73-30",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.571779, 59.737157]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СВ",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, бульвар Трудящихся, 18к4Б",
                "opening_hours": "09:00-21:00",
                "contact_website": "+7 (812) 460-64-33",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.581693, 59.735543]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колпинский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, улица Веры Слуцкой, 46\/2",
                "opening_hours": "09:00-20:00",
                "contact_website": "+7 (812) 481-76-92",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.581398, 59.73926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Меркурий",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Пролетарская улица, 36",
                "opening_hours": "10:00-22:00",
                "contact_website": "trkmercury.ru",
                "contact_phone": "+7 (812) 461-25-25"
            },
            "geometry": {"type": "Point", "coordinates": [30.584, 59.740668]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, улица Анисимова, 5к5",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.605833, 59.732977]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОКА",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Тверская улица, 58\/6",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.612517, 59.735035]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Ока",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, улица Ижорского Батальона, 13",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.617372, 59.736273]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОКА",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Октябрьская улица, 8",
                "opening_hours": "09:00-23:00",
                "contact_website": "+7 (812) 490-68-14",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.622854, 59.738875]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОКА",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Тверская улица, 36\/9",
                "opening_hours": "10:00-20:00",
                "contact_website": "+7 (812) 463-40-90",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.611144, 59.740446]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ОКА",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Тверская улица, 36к3",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.611766, 59.740151]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Марс",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Колпино, Тверская улица, 10А",
                "opening_hours": "09:00-21:00",
                "contact_website": "+7 (812) 463-38-90",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.609845, 59.745689]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Славянка",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  территория Московская Славянка, 17А",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.496662, 59.747806]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Оазис",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  территория Ленсоветовский, 11",
                "opening_hours": "10:00-21:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.470843, 59.759297]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Магазин инструмента",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Поселковая улица, 12В",
                "opening_hours": "09:00-21:00",
                "contact_website": "+7 (911) 165-90-16",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.395097, 59.808115]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Адамант",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Московское шоссе, 24",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.404023, 59.796347]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Масштаб",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Шереметьевская улица, 13",
                "opening_hours": "09:00-22:00",
                "contact_website": "www.macshtab.ru",
                "contact_phone": "+7 (812) 332-18-18"
            },
            "geometry": {"type": "Point", "coordinates": [30.320542, 59.806915]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пулково-3",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Шереметьевская улица, 15",
                "opening_hours": "07:00-23:00",
                "contact_website": "www.pulkovotri.ru",
                "contact_phone": "+7 (967) 535-00-21"
            },
            "geometry": {"type": "Point", "coordinates": [30.320778, 59.805986]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Outlet Village Pulkovo",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Пулковское шоссе, 60к1",
                "opening_hours": "10:00-21:00",
                "contact_website": "www.ovpulkovo.com",
                "contact_phone": "+7 (812) 383-90-00"
            },
            "geometry": {"type": "Point", "coordinates": [30.330016, 59.79366]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пуд удачи",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Железнодорожная улица, 81",
                "opening_hours": "08:00-22:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.419425, 59.739605]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эврика",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  улица Генерала Хазова, 9",
                "opening_hours": "Пн-Сб 11:00-19:00 Вс 11:00-18:00",
                "contact_website": "www.evrika.biz",
                "contact_phone": "+7 (812) 465-75-71"
            },
            "geometry": {"type": "Point", "coordinates": [30.401776, 59.737235]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Ленинградская улица, 35",
                "opening_hours": "10:00-21:00",
                "contact_website": "+7 (812) 470-53-47",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.416724, 59.727258]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиный Двор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Московская улица, 25",
                "opening_hours": "09:00-22:00",
                "contact_website": "bc-gostiniydvor.ru",
                "contact_phone": "+7 (812) 244-68-02"
            },
            "geometry": {"type": "Point", "coordinates": [30.407408, 59.719011]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Константиновский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Полковая улица, 1\/25",
                "opening_hours": "11:00-20:00",
                "contact_website": "трк-константиновский.рф",
                "contact_phone": "+7 (812) 677-20-43"
            },
            "geometry": {"type": "Point", "coordinates": [30.381501, 59.702419]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Павловск, Слуцкая улица, 9",
                "opening_hours": "09:00-21:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.429891, 59.689728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Красногородский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, Красногородская улица, 17",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.075582, 59.717998]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МТК",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, Кингисеппское шоссе, 10к1",
                "opening_hours": "09:00-21:00",
                "contact_website": "+7 (812) 749-48-81",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.072856, 59.722271]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, Театральная улица, 10",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.082604, 59.723434]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МТК",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, проспект Ленина, 87",
                "opening_hours": "10:00-20:00",
                "contact_website": "+7 (812) 749-48-81",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.082748, 59.728353]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Красносельский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, улица Лермонтова, 21Д",
                "opening_hours": "10:00-20:00",
                "contact_website": "тц-к.рф",
                "contact_phone": "+7 (812) 741-83-74"
            },
            "geometry": {"type": "Point", "coordinates": [30.080627, 59.733973]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Колибри",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, проспект Ленина, 63к1",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.088046, 59.736868]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Тетрис",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Красное село, проспект Ленина, 51",
                "opening_hours": "08:00-23:00",
                "contact_website": "tk-tetris.ru",
                "contact_phone": "+7 (921) 948-07-69"
            },
            "geometry": {"type": "Point", "coordinates": [30.090438, 59.741255]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гарден Сити",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Лахтинский проспект, 85В",
                "opening_hours": "11:00-21:00",
                "contact_website": "http:\/\/gardencity.ru\/",
                "contact_phone": "7 (812) 244-48-48"
            },
            "geometry": {"type": "Point", "coordinates": [30.146619, 59.993974]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Паргос",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Выборгское шоссе, 369к6",
                "opening_hours": "09:00-21:00",
                "contact_website": "http:\/\/www.pargos.ru\/",
                "contact_phone": "7 (812) 324-33-24"
            },
            "geometry": {"type": "Point", "coordinates": [30.283479, 60.073781]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Китай город",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Выборгское шоссе, 503к1",
                "opening_hours": "10:00-22:00",
                "contact_website": "https:\/\/kitgorod.ru\/1\/",
                "contact_phone": "7 (981) 737-17-09"
            },
            "geometry": {"type": "Point", "coordinates": [30.260618, 60.098851]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вирон",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  посёлок Лисий Нос, Центральная улица, 2",
                "opening_hours": "09:00-22:00",
                "contact_website": "7 (921) 966-40-27",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.00979, 60.015281]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Скиф",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Сестрорецк, улица Володарского, 12А",
                "opening_hours": "10:00-19:00",
                "contact_website": "7 (812) 437-69-94",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.971057, 60.098845]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый домъ",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Сестрорецк, улица Коммунаров, 2\/4",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.957378, 60.0931]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сестрорецкий",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Сестрорецк, улица Воскова, 15",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.959852, 60.093348]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Репино Бэй",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  посёлок Репино, Приморское шоссе, 441А",
                "opening_hours": "09:00-22:00",
                "contact_website": "7 (812) 240-20-13",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.85947, 60.164726]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Курортный",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Зеленогорск, Вокзальная улица, 7",
                "opening_hours": "09:00-23:00",
                "contact_website": "http:\/\/tk-kurortny.ru\/",
                "contact_phone": "7 (812) 433-38-11"
            },
            "geometry": {"type": "Point", "coordinates": [29.703608, 60.205798]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Центральный",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Зеленогорск, проспект Ленина, 19",
                "opening_hours": "11:00-19:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.705975, 60.198381]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Зеленогорск, Приморское шоссе, 559\/1",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.691315, 60.192728]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Зеленогорск, Приморское шоссе, 516А",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.725374, 60.189323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Илатан",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Зеленогорск, Речной переулок, 4",
                "opening_hours": "10:00-19:00",
                "contact_website": "http:\/\/ilatan.ru\/",
                "contact_phone": "7 (812) 433-37-60"
            },
            "geometry": {"type": "Point", "coordinates": [29.703286, 60.198936]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Байкал",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Поселок Бугры, Шоссейная улица, 7Б",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.394271, 60.068724]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Лидер",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Сестрорецк, улица Токарева, 8",
                "opening_hours": "10:00-20:00",
                "contact_website": "7‒921‒431‒51‒61",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.953847, 60.086582]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Грот",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Сестрорецк, улица Токарева, 13А",
                "opening_hours": "11:00-20:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.955069, 60.089606]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Гостиный двор",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Кронштадт, проспект Ленина, 16",
                "opening_hours": "10:00-20:00",
                "contact_website": "http:\/\/www.gdkronshtadt.ru\/",
                "contact_phone": "7 (921) 966-69-88"
            },
            "geometry": {"type": "Point", "coordinates": [29.767494, 59.996726]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ЦентR",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Кронштадт, улица Карла Маркса, 6\/29",
                "opening_hours": "09:00-21:00",
                "contact_website": "http:\/\/xn--80auegbdkd4a.xn--p1ai\/",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.769916, 59.998585]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ТЦ Формакрон",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Кронштадт, Флотская улица, 27",
                "opening_hours": "10:00-20:00",
                "contact_website": "7 (812) 311-66-95",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.769576, 59.998058]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр Кронштадтский",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Кронштадт, проспект Ленина, 13",
                "opening_hours": "10:00-21:00",
                "contact_website": "http:\/\/kronshtadtdbu.ru\/",
                "contact_phone": "7 (921) 930-63-72"
            },
            "geometry": {"type": "Point", "coordinates": [29.766004, 59.997498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котлин",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Кронштадт, улица Гидростроителей, 8",
                "opening_hours": "09:00-20:00",
                "contact_website": "7 (812) 439-04-94",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.718372, 60.010443]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Aura",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  ​Лахтинский проспект, 85 к2 лит А",
                "opening_hours": "10:00-22:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [30.151252, 59.993266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парнас",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  4-й Верхний переулок, 19Б",
                "opening_hours": "10:00-22:00",
                "contact_website": "tkparnas.info",
                "contact_phone": "7 (812) 426‒78‒18"
            },
            "geometry": {"type": "Point", "coordinates": [30.343159, 60.073122]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Парнас Сити",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  улица Михаила Дудина, 6к1",
                "opening_hours": "10:00-22:00",
                "contact_website": "parnas-city.ru",
                "contact_phone": "7 (812) 612‒08‒50"
            },
            "geometry": {"type": "Point", "coordinates": [30.336287, 60.066958]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Термин",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Петергоф, переулок Ломоносова, 1",
                "opening_hours": "09:00-21:00",
                "contact_website": "7 (812) 640‒56‒82",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.8637, 59.87508]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Невский Гранит",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  ​Петергоф, Гостилицкая, 4",
                "opening_hours": "09:00-21:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.857224, 59.876237]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Южный",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Ломоносов, ​Федюнинского, 16",
                "opening_hours": "10:00-20:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.774683, 59.900173]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Нитекс",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Ломоносов, ​Федюнинского, 7",
                "opening_hours": "10:00-19:00",
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.771058, 59.901748]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ракета",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торгово развлекательный центр",
                "address": "Россия,  Петергоф, Санкт-Петербургский проспект, 60",
                "opening_hours": "09:00-21:00",
                "contact_website": "http:\/\/raketagroup.ru\/",
                "contact_phone": "7 (812) 420-50-03"
            },
            "geometry": {"type": "Point", "coordinates": [29.890364, 59.882416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Петергоф, улица Халтурина, 1",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.86369, 59.880713]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Рамбов",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Ломоносов, ​Красноармейская улица, 19",
                "opening_hours": "09:00-21:00",
                "contact_website": "7 (812) 422-88-04",
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.775266, 59.907378]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Торговый центр",
                "city_function": "Культура, развлечения, творчество",
                "type": "Торговый центр",
                "address": "Россия,  Ломоносов, ​улица Победы, 7",
                "opening_hours": null,
                "contact_website": null,
                "contact_phone": null
            },
            "geometry": {"type": "Point", "coordinates": [29.773352, 59.90788]}
        }
    ]
}
