export const industrial_enterprises_2 = {
    "type": "FeatureCollection",
    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
    "features": [
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения г. Кронштадт",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 2,
                "address": "улица Гидростроителей, 2\nКронштадт, Санкт-Петербург, Россия, 197761"
            },
            "geometry": {"type": "Point", "coordinates": [29.726834, 60.014086]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Асфальто-бетонный завод",
                "organisation": "Санкт-Петербургское государственное унитарное дорожное специализированное предприятие \"Курортное\"",
                "type": "Другое",
                "category": 2,
                "address": "Кронштадтское шоссе, 11\nКронштадт, Санкт-Петербург, Россия, 197761"
            },
            "geometry": {"type": "Point", "coordinates": [29.742914, 60.007695]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Противотуберкулезный диспансер",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения  «Противотуберкулезный диспансер №14» ",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "проспект Обуховской Обороны, 231\nСанкт-Петербург, Россия, 192012"
            },
            "geometry": {"type": "Point", "coordinates": [30.471406, 59.86416]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПБ ГБУЗ «Александровская больница»",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения «Городская Александровская больница» ",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "проспект Солидарности, 4к3\nСанкт-Петербург, Россия, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.489771, 59.927472]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургская бумажная фабрика - филиал АО \"Гознак\" (фабрика)",
                "organisation": "Санкт-Петербургская бумажная фабрика - филиал акционерного общества \"Гознак\"",
                "type": "Другое",
                "category": 2,
                "address": "набережная реки Фонтанки, 144\nСанкт-Петербург, Россия, 190103"
            },
            "geometry": {"type": "Point", "coordinates": [30.289518, 59.915514]}
        },
        {"type": "Feature",
            "properties": {
                "name": " площадка Варшавская, ",
                "organisation": "Общество с ограниченной ответственностью  \"Газпром трансгаз Санкт-Петербург\"",
                "type": "Другое",
                "category": 2,
                "address": "Варшавская улица, 3к2Б\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.307118, 59.880936]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производственная площадка",
                "organisation": "Общество с ограниченной ответственностью \"Завод по фпроизводству железобетонных изделий и конструкций морского гидротехнического строительства Санкт-Петербурга\"",
                "type": "Другое",
                "category": 2,
                "address": "дорога на Турухтанные Острова, 26к4\nСанкт-Петербург, Россия, 198096"
            },
            "geometry": {"type": "Point", "coordinates": [30.221868, 59.868898]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Финляндский вокзал",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "улица Комсомола, 37к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.359159, 59.957395]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восстановительный поезд 3023 ст. Санкт-Петербург Сортировочный Московский Дирекции аварийно-восстановительных средств - структурного подразделения Октябрьской ж.д.-  филиала ОАО РЖД",
                "organisation": "Восстановительный поезд 3023 ст. Санкт-Петербург сортировочный московский Дирекции аварийно-восстановительных свойств структурное подразделение Октябрьской ж.д. филиал ОАО РЖД",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Фарфоровский Пост, 3\nСанкт-Петербург\n192236"
            },
            "geometry": {"type": "Point", "coordinates": [30.404093, 59.880033]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Восстановительный поезд 3024 ст. Санкт-Петербург Финляндский Дирекции аварийно-восстановительных средств - структурного подраздениения Октябрьской ж.д. - филиала ОАО РЖД",
                "organisation": "Восстановительный поезд 3024 ст. Санкт-Петербург финляндский Дирекции аварийно-восстановительных средств структурное подразделение Октябрьской ж.д. филиал ОАО РЖД",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "улица Комсомола, 37к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.360067, 59.959445]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Витебская-Сортировочная",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Витебская Сортировочная ул., 1, Санкт-Петербург, 192102"
            },
            "geometry": {"type": "Point", "coordinates": [30.348676, 59.88809]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Ладожский Вокзальный Комплекс",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Заневский проспект, 73\nСанкт-Петербург, Россия, 195112"
            },
            "geometry": {"type": "Point", "coordinates": [30.440635, 59.931922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Металлострой",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "дор. на Металлострой, д. №5, корпус 45, литер АО, пос. Металлострой, Санкт-Петербург, 196641"
            },
            "geometry": {"type": "Point", "coordinates": [30.53771, 59.789026]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Предпортовая",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Предпортовая ул., 1, Санкт-Петербург, 196240"
            },
            "geometry": {"type": "Point", "coordinates": [30.290901, 59.832836]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Ручьи",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "улица Руставели, 19А\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.440114, 60.004991]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Сортировочная-Московская, модульная котельная 5 парк",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Сортировочная-Московская улица, 18\nСанкт-Петербург, Россия, 192174"
            },
            "geometry": {"type": "Point", "coordinates": [30.427365, 59.868657]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Сортировочная-Московская, Котельная ТЧ-7",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Сортировочная-Московская улица, 2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.424345, 59.871712]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция СПб-Балтийский",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "набережная Обводного канала, 120Б\nСанкт-Петербург, Россия, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.298686, 59.906684]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Среднерогатская",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Московское шоссе, 23АЮ\nСанкт-Петербург, Россия, 196158"
            },
            "geometry": {"type": "Point", "coordinates": [30.352844, 59.816831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Котельная ТЧ-20",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Лесной проспект, 25\nСанкт-Петербург, Россия, 194044"
            },
            "geometry": {"type": "Point", "coordinates": [30.351352, 59.968191]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Фарфоровская, котельная Восстановитедльный поезд №3023",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": " Фарфоровский Пост, 62\nСанкт-Петербург\n192236"
            },
            "geometry": {"type": "Point", "coordinates": [30.408938, 59.878285]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Фарфоровская, котельная ОПЧ-10",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "территория Фарфоровский Пост, 44\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.406805, 59.879254]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Шушары, Котельная ДС",
                "organisation": "Санкт-Петербургский территориальный участок Октябрьской дирекции по тепловодоснабжению-структурное подразделение Центральной дирекции по тепловодоснабжению-филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "1-й Бадаевский проезд, 1\nпосёлок Шушары, Пушкинский район, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.387984, 59.806892]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г. Санкт-Петербург, ул. Корабельная, д.6",
                "organisation": "Общество с ограниченной ответственностью \"МБК\"",
                "type": "Другое",
                "category": 2,
                "address": "г. Санкт-Петербург, ул. Корабельная, д.6"
            },
            "geometry": {"type": "Point", "coordinates": [30.242, 59.874968]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Закрытое акционерное общество \"Контейнерный терминал Санкт-Петербург\"",
                "organisation": "Закрытое акционерное общество \"Контейнерный терминал Санкт-Петербург\"",
                "type": "Другое",
                "category": 2,
                "address": "Угольная гавань, Элеваторная площадка 22 литера Щ, Санкт-Петербург, 198096"
            },
            "geometry": {"type": "Point", "coordinates": [30.19885, 59.870298]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок Единой Системы Газоснабжения (ЕСГ) в Московском районе г. Санкт-Петербург: ГРС \"Шоссейная\"; Газопровод лупинг 2 к ГРС \"Шоссейная\"",
                "organisation": "Филиал Общества с ограниченной отчетственностью \"Газпром трансгаз Санкт-Петербург\" Колпинское линейное производственное управление магистральных газопроводов",
                "type": "Производственное управление газопроводов",
                "category": 2,
                "address": "Пулковское шоссе, 42к2\nСанкт-Петербург, Россия, 196158"
            },
            "geometry": {"type": "Point", "coordinates": [30.332487, 59.814708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок Единой Системы Газоснабжения (ЕСГ) в Петродворцовом районе г. Санкт-Петербург: ГРС ЛГУ; ГРС Ломоносов; МГ Петродворец-Ломоносов; МГ Петродворец-Ломоносов, Низино 0 км; МГ Кипень-Петродворец; Газопровод отвод на ГРС ЛГУ",
                "organisation": "Филиал Общества с ограниченной отчетственностью \"Газпром трансгаз Санкт-Петербург\" Колпинское линейное производственное управление магистральных газопроводов",
                "type": "Производственное управление газопроводов",
                "category": 2,
                "address": "Гостилицкое шоссе, 135\nПетергоф, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [29.805427, 59.865013]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок Единой Системы Газоснабжения (ЕСГ) в  Пушкинском районе г. Санкт-Петербург: ГРС Московская Славянка; ГРС Южная ТЭЦ,  Газопровод: лупинг - 2 к ГРС Шоссейная",
                "organisation": "Филиал Общества с ограниченной отчетственностью \"Газпром трансгаз Санкт-Петербург\" Колпинское линейное производственное управление магистральных газопроводов",
                "type": "Производственное управление газопроводов",
                "category": 2,
                "address": "территория Московская Славянка, 1\nпосёлок Шушары, Пушкинский район, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.502628, 59.74364]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург-Витебская механизированная дистанция погрузочно - разгрузочных работ. Контейнерная площадка грузового двора.",
                "organisation": "Открытое акционерное общество «Российские железные дороги»",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Минеральная улица, 25\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.365554, 59.965214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Великолукский п\/у Санкт-Петербург Витебской механизированной дистанции погрузочно-разгрузочных работ",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Полтавская улица, 9\nСанкт-Петербург, Россия, 191036"
            },
            "geometry": {"type": "Point", "coordinates": [30.366596, 59.926702]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"Кожно-венерологический диспансер №6\"",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Кожно-венерологический диспансер №6\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "улица Лётчика Пилютова, 41\nСанкт-Петербург, Россия, 198264"
            },
            "geometry": {"type": "Point", "coordinates": [30.13926, 59.826616]}
        },
        {"type": "Feature",
            "properties": {
                "name": "станция Нева",
                "organisation": "Санкт-Петербургская дистанция гражданских сооружений Октябрьской дирекции по эксплуатации зданий и сооружений -структурного подразделения Октябрьской железной дороги - филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "ул. Народная, 102 корп 1, Санкт-Петербург, 193315"
            },
            "geometry": {"type": "Point", "coordinates": [30.49513, 59.888633]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"Городская поликлиника № 15\"",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Городская поликлиника № 15\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Удельный проспект, 22\nСанкт-Петербург, Россия, 194214"
            },
            "geometry": {"type": "Point", "coordinates": [30.312794, 60.020922]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пассажирский порт Санкт-Петербург",
                "organisation": "Акционерное Общество \"Пассажирский Порт Санкт-Петербург \"Морской фасад\"",
                "type": "Другое",
                "category": 2,
                "address": "проспект Крузенштерна, 18с4\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.194955, 59.948978]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ст.Левашово",
                "organisation": "Санкт-Петербургская дистанция гражданских сооружений Октябрьской дирекции по эксплуатации зданий и сооружений -структурного подразделения Октябрьской железной дороги - филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.21829, 60.104345]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ст.Новая Деревня",
                "organisation": "Санкт-Петербургская дистанция гражданских сооружений Октябрьской дирекции по эксплуатации зданий и сооружений -структурного подразделения Октябрьской железной дороги - филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296504, 59.991721]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Корпус. Санкт-Петербург, ул. Благодатная, д.2",
                "organisation": "Акционерное общество \"Всероссийский научно-исследовательский  проектно-конструкторский и технологический институтт электромашиностроения\"",
                "type": "Другое",
                "category": 2,
                "address": "Благодатная улица, 2\nСанкт-Петербург, Россия, 196128"
            },
            "geometry": {"type": "Point", "coordinates": [30.300938, 59.875953]}
        },
        {"type": "Feature",
            "properties": {
                "name": "г.Санкт-Петербург, Глухоозерское шоссе, дом 13а",
                "organisation": "Санкт-Петербургская дистанция гражданских сооружений Октябрьской дирекции по эксплуатации зданий и сооружений -структурного подразделения Октябрьской железной дороги - филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Глухоозёрское шоссе, 13\nСанкт-Петербург, Россия, 192019"
            },
            "geometry": {"type": "Point", "coordinates": [30.37407, 59.911498]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вагонное ремонтное депо Санкт-Петербург-Сортировочный-Витебский",
                "organisation": "Вагонное ремонтное депо Санкт-Петербург-Сортировочный-Витебский-обособленное структурное подразделение открытого акционерного общества \"Вагонная ремонтная компания-2\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.348331, 59.888122]}
        },
        {"type": "Feature",
            "properties": {
                "name": " №1 Производственная территория по адресу г. Санкт-Петербург  ул. Аккуратова д.2",
                "organisation": "Федеральное государственное бюджетное учреждение \"Национальный медицинский исследовательский центр имени В.А. Алмазова\" Министерства здравоохранения Российской Федерации",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "г. Санкт-Петербург  ул. Аккуратова д.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.301594, 60.015377]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка ЗАО ПНТ",
                "organisation": "Закрытое акционерное общество \"Петербургский нефтяной терминал\"",
                "type": "Другое",
                "category": 2,
                "address": "территория Элеваторная Площадка, 32\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.17884, 59.88129]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Прибрежный склад нефтепродуктов",
                "organisation": "Общество с ограниченной ответственностью \"Несте Санкт-Петербург\"",
                "type": "Другое",
                "category": 2,
                "address": "дорога на Турухтанные Острова, 25\nСанкт-Петербург, Россия, 198096"
            },
            "geometry": {"type": "Point", "coordinates": [30.219763, 59.874825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "1, 2, 3 районы порта",
                "organisation": "Акционерное общество \"Морской порт Санкт-Петербург\"",
                "type": "Предприятия морского порта",
                "category": 2,
                "address": "Межевой канал, 5\nСанкт-Петербург, Россия, 198035"
            },
            "geometry": {"type": "Point", "coordinates": [30.250565, 59.911109]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Эксплуатационное локомотивное депо Санкт-Петербург-Варшавский (основная площадка)",
                "organisation": "Октябрьская дирекция тяги - структурное подразделение Дирекции тяги - филиала ОАО \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.350396, 59.884474]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моторвагоное депо Санкт-Петербург - Московское (Финляндская площадка)",
                "organisation": "Моторвагонное депо Санкт-Петербург-Московское Октябрьской Дирекции моторвагонного подвижного состава-структурного подразделения Центральной Дирекции моторвагонного подвижного состава филиала ОАО \"РЖД\" ",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.546455, 59.784626]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург-Московская дистанция электроснабжения (ЭЧ-3)",
                "organisation": "ОАО \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "муниципальный округ Ивановский\nНевский район, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.433704, 59.864719]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург-Московская дистанция электроснабжения (ЭЧ-3) , г. Санкт-Петербург, ул. Набережная Обводного канала д.43",
                "organisation": "ОАО \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Санкт-Петербург, ул. Набережная Обводного канала д.43"
            },
            "geometry": {"type": "Point", "coordinates": [30.371384, 59.915246]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург-Московская дистанция электроснабжения, г. Санкт-Петербург, Невский район, Южное шоссе, д.37",
                "organisation": "ОАО \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "г. Санкт-Петербург, Невский район, Южное шоссе, д.37"
            },
            "geometry": {"type": "Point", "coordinates": [30.420396, 59.863391]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург-Московская дистанция электроснабжения, г. Санкт-Петербург, г. Колпино, ул. Межевая ",
                "organisation": "ОАО \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "г. Санкт-Петербург, г. Колпино, ул. Межевая "
            },
            "geometry": {"type": "Point", "coordinates": [30.608458, 59.756949]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производственная территория№1",
                "organisation": "Санкт-Петербург Балтийская дистанция электроснабжения, структурное подразделение филиала ОАО \"РЖД\" Трансэнерго Октябрьской дирекции по Энергообеспечению",
                "type": "Другое",
                "category": 2,
                "address": "территория Станционный Посёлок, 7\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.164322, 59.823557]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Механические мастерские ст. Ржевка",
                "organisation": "Санкт – Петербург - Финляндская дистанция пути – структурное подразделение Октябрьской дирекции инфраструктуры – структурного подразделения Центральной дирекции инфраструктуры – филиала ОАО «РЖД»",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.513321, 59.981206]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург-Витебская механизированная дистанция погрузочно - разгрузочных работ. Сооружение контейнерной площадки ",
                "organisation": "Открытое акционерное общество «Российские железные дороги»",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Минеральная улица, 25"
            },
            "geometry": {"type": "Point", "coordinates": [30.365554, 59.965214]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петеребургское государственное казенное учреждение здравоохраненияя \"Городская психиатрическая больница № 3 имени И.И. Скворцова-Степанова\"",
                "organisation": "Санкт-Петребургское государственное казенное учреждение здравоохранения \"Городская психиатрическая больница № 3 имени И.И. Скворцова-Степанова\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Фермское шоссе, 36АВ\nСанкт-Петербург, Россия, 197341"
            },
            "geometry": {"type": "Point", "coordinates": [30.309136, 60.021324]}
        },
        {"type": "Feature",
            "properties": {
                "name": "вокзал станции Новый Петергоф Санкт-Петербург-Витебского регионального участка Октябрьской дирекции пассажирских обустройств - структурного подразделения Центральной дирекции пассажирских обустройств - филиала ОАО \"РЖД\".",
                "organisation": "открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [29.926601, 59.863753]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПБ ГБУЗ \"ДГМКЦ ВМТ ИМ. К.А. Раухфуса  (Санкт-Петербург, Лиговский пр., д.8 лит А)",
                "organisation": "Санкт-Петербургское Государственное бюджетное учреждение здравоохранения «Детский городской многопрофильный клинический центр высоких медицинских технологий им. К.А. Раухфуса» ",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Санкт-Петербург, Лиговский пр., д.8 лит А"
            },
            "geometry": {"type": "Point", "coordinates": [30.363973, 59.93442]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГТС Финляндский Санкт-Петербург Витебской механизированной дистанции погрузочно-разгрузочных работ",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Полтавская улица, 9\nСанкт-Петербург, Россия, 191036"
            },
            "geometry": {"type": "Point", "coordinates": [30.366596, 59.926702]}
        },
        {"type": "Feature",
            "properties": {
                "name": "материальный склад ст. Сортировочный-Московский",
                "organisation": "Санкт-Петербургский отдел материально-технического обеспечения",
                "type": "Другое",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.424908, 59.866708]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ГТС Шушары Санкт-Петербург Витебской механизированной дистанции погрузочно-разгрузочных работ",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Железнодорожная улица, 46\nпосёлок Шушары, Пушкинский район, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.408312, 59.784613]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Навалочная п\/у Санкт-Петербург Витебской механизированной дистанции погрузочно-разгрузочных работ",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.371671, 59.907261]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Транспортная служба, участок цеха по ремонту оборудования",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие \"Ленсвет\"",
                "type": "Другое",
                "category": 2,
                "address": "улица Руставели, 1\nСанкт-Петербург, Россия, 195273"
            },
            "geometry": {"type": "Point", "coordinates": [30.439234, 59.998243]}
        },
        {"type": "Feature",
            "properties": {
                "name": "городская больница",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения «Городская больница №9»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Крестовский проспект, 18Б\nСанкт-Петербург, Россия, 197110"
            },
            "geometry": {"type": "Point", "coordinates": [30.260392, 59.968039]}
        },
        {"type": "Feature",
            "properties": {
                "name": "192019, Санкт-Петербург, ул. Книпович, д. 12, кор.2",
                "organisation": "Акционерное общество \"РСК\"",
                "type": "Другое",
                "category": 2,
                "address": "192019, Санкт-Петербург, ул. Книпович, д. 12, кор.2"
            },
            "geometry": {"type": "Point", "coordinates": [30.391749, 59.90533]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Промплощадка №2 (Санкт-Петербург-Балтийская дистанция пути)",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Митрофаньевское шоссе, 3\nСанкт-Петербург, Россия, 198095"
            },
            "geometry": {"type": "Point", "coordinates": [30.29943, 59.9026]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моторвагонное депо Санкт-Петербург - Московское (площадка Металлострой)",
                "organisation": "Моторвагонное депо Санкт-Петербург-Московское Октябрьской Дирекции моторвагонного подвижного состава-структурного подразделения Центральной Дирекции моторвагонного подвижного состава филиала ОАО \"РЖД\" ",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.546593, 59.784532]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Моторвагонное депо Санкт-Петербург-Балтийский – структурное подразделение Октябрьской дирекции моторвагонного подвижного состава – структурного подразделения Центральной дирекции моторвагонного подвижного состава – филиала ОАО «РЖД»",
                "organisation": "Моторвагонное депо Санкт-Петербург-Балтийский-структурное подразделение Октябрьской дирекции моторвагонного подвижного состава - структурного подразделения Центральной дирекции моторвагонного подвижного состава - филиала ОАО \"РЖД\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.296053, 59.889722]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Участок Санкт-Петербург Сортировочный-Московский",
                "organisation": "Открытое Акционерное Общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.396262, 59.891466]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Дунайский пр., д. 61",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 2,
                "address": "Дунайский пр., д. 61"
            },
            "geometry": {"type": "Point", "coordinates": [30.427304, 59.84504]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка Сабировская ул., д. 48",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 2,
                "address": "Сабировская ул., д. 48"
            },
            "geometry": {"type": "Point", "coordinates": [30.280888, 59.989544]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург - Витебская дистанция сигнализации, централизации и блокировки, г.Санкт-Петербург, улица Боровая д.57",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "г.Санкт-Петербург, улица Боровая д.57"
            },
            "geometry": {"type": "Point", "coordinates": [30.339763, 59.910253]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Главный материальный склад",
                "organisation": "Главный материальный склад Санкт-Петербургской дирекции материально технического обеспечения ",
                "type": "Другое",
                "category": 2,
                "address": "3-й Рыбацкий проезд, 9\nСанкт-Петербург, Россия, 192177"
            },
            "geometry": {"type": "Point", "coordinates": [30.499699, 59.82666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Угольный склад Главного материального склада",
                "organisation": "Главный материальный склад Санкт-Петербургской дирекции материально технического обеспечения ",
                "type": "Другое",
                "category": 2,
                "address": "3-й Рыбацкий проезд, 9\nСанкт-Петербург, Россия, 192177"
            },
            "geometry": {"type": "Point", "coordinates": [30.499699, 59.82666]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Родильный дом №13",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения «Родильный дом № 13»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Костромская улица, 4\nСанкт-Петербург, Россия, 191124"
            },
            "geometry": {"type": "Point", "coordinates": [30.389572, 59.942266]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ДПС Среднерогатская Санкт-Петербург Витебской механизированной дистанции погрузочно-разгрузочных работ",
                "organisation": "Открытое акционерное общество \"Российские железные дороги\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.352844, 59.816831]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ «Городская поликлиника № 32»",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение  здравоохранения «Городская поликлиника № 32»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Вяземский переулок, 3\nСанкт-Петербург, Россия, 197022"
            },
            "geometry": {"type": "Point", "coordinates": [30.303237, 59.971904]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ «Городская поликлиника № 32» (Женская консультация № 14)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение  здравоохранения «Городская поликлиника № 32»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Каменноостровский проспект, 44Б\nСанкт-Петербург, Россия, 197022"
            },
            "geometry": {"type": "Point", "coordinates": [30.308646, 59.96863]}
        },
        {"type": "Feature",
            "properties": {
                "name": "198320,г. Санкт-Петербург, ул.Свободы, д.59,к. 2",
                "organisation": "Общество с ограниченной ответственностью \"Амкор Спешиелти Картон Санкт-Петербург\"",
                "type": "Другое",
                "category": 2,
                "address": "198320,г. Санкт-Петербург, ул.Свободы, д.59,к. 2"
            },
            "geometry": {"type": "Point", "coordinates": [30.06027, 59.734239]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Электродепо \"Невское\"",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие \"Петербургский метрополитен\"",
                "type": "Предприятие метрополитена",
                "category": 2,
                "address": "5-й Рыбацкий проезд, 18Б\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.514708, 59.822929]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Электродепо \"Автово\"",
                "organisation": "Государственное унитарное предприятие \"Петербургский метрополитен\"",
                "type": "Предприятие метрополитена",
                "category": 2,
                "address": "проспект Стачек, 100\nСанкт-Петербург, Россия, 198207"
            },
            "geometry": {"type": "Point", "coordinates": [30.280321, 59.856491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Электродепо \"Московское\"",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие \"Петербургский метрополитен\"",
                "type": "Предприятие метрополитена",
                "category": 2,
                "address": "Витебский проспект, 107Ж\nСанкт-Петербург, Россия, 196233"
            },
            "geometry": {"type": "Point", "coordinates": [30.376046, 59.822681]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Электродепо Выборгское",
                "organisation": "Государственное унитарное предприятие \"Петербургский метрополитен\"",
                "type": "Предприятие метрополитена",
                "category": 2,
                "address": "проспект Энгельса, 162\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.319491, 60.068926]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Объединённые мастерские ГУП \"Петербургский метрополитен\"",
                "organisation": "Государственное унитарное предприятие \"Петербургский метрополитен\"",
                "type": "Предприятие метрополитена",
                "category": 2,
                "address": "3-й Рыбацкий проезд, 4\nСанкт-Петербург, Россия, 192177"
            },
            "geometry": {"type": "Point", "coordinates": [30.500409, 59.829807]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"ГП №27\" (Детское поликлиническое отделение)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Городская поликлиника №27\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "проспект Римского-Корсакова, 35\nСанкт-Петербург, Россия, 190068"
            },
            "geometry": {"type": "Point", "coordinates": [30.299698, 59.923789]}
        },
        {"type": "Feature",
            "properties": {
                "name": "№ 1",
                "organisation": "Закрытое акционерное общество \"ОПТЕКОМ Санкт-Петербург\"",
                "type": "Другое",
                "category": 2,
                "address": "проспект Металлистов, 96Е\nСанкт-Петербург, Россия, 195221"
            },
            "geometry": {"type": "Point", "coordinates": [30.405637, 59.968531]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения г. Сестрорецк",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [29.952318, 60.079323]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КОС пос. Молодежное",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [29.527013, 60.205433]}
        },
        {"type": "Feature",
            "properties": {
                "name": "КОС п. Репино",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [29.860979, 60.167917]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения г.Зеленогорск",
                "organisation": "Государственное унитарное предприятие  \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [29.707035, 60.197581]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ «ПТД № 4»",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Противотуберкулезный диспансер №4\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "проспект Ленина, 1\/5\nКолпино, Санкт-Петербург, Россия, 196653"
            },
            "geometry": {"type": "Point", "coordinates": [30.595828, 59.749298]}
        },
        {"type": "Feature",
            "properties": {
                "name": "194144, Санкт-Петербург, ул. Чугунная..д.20Е",
                "organisation": "Общество с ограниченной ответственностью \"Евроком\"",
                "type": "Другое",
                "category": 2,
                "address": "194144, Санкт-Петербург, ул. Чугунная..д.20Е"
            },
            "geometry": {"type": "Point", "coordinates": [30.362563, 59.972753]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ «Городская больница № 14»",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения «Городская больница № 14»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "улица Косинова, 19\/9\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.27193, 59.895527]}
        },
        {"type": "Feature",
            "properties": {
                "name": "МАПП Люття Санкт-Петербургский филиал ФГКУ Росгранстрой ",
                "organisation": "ФГКУ \"Дирекция по строительству и эксплуатации объектов Росграницы\"",
                "type": "Другое",
                "category": 2,
                "address": "Бассейная улица, 12\nСанкт-Петербург, Россия, 196070"
            },
            "geometry": {"type": "Point", "coordinates": [30.314997, 59.86631]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пассажирское вагонное депо Санкт-Петербург Московский  - структурное подразделение Северо-Западного филиала Акционерного общества «Федеральная пассажирская компания»",
                "organisation": "Пассажирское вагонное депо Санкт-Петербург Московский  - структурное подразделение Северо-Западного филиала Акционерного общества «Федеральная пассажирская компания»",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "набережная Обводного канала, 30к3\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.364368, 59.913113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "ООО \"ЛСР. Строительство-СЗ\", Завод (194292, г. Санкт-Петербург, переулок 3-й  Верхний, дом 5, литер Т, пом\/каб 2-Н, 3-Н\/277) ",
                "organisation": "Общество с ограниченной ответственностью \"ЛСР. Строительство-Северо-Запад\"",
                "type": "Другое",
                "category": 2,
                "address": "194292, г. Санкт-Петербург, переулок 3-й  Верхний, дом 5, литер Т"
            },
            "geometry": {"type": "Point", "coordinates": [30.354244, 60.064511]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"Стоматологическая поликлиника № 20\"",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Стоматологическая поликлиника № 20\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "улица Маршала Казакова, 14к3\nСанкт-Петербург, Россия, 198302"
            },
            "geometry": {"type": "Point", "coordinates": [30.237167, 59.857825]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка АО \"Петербургский тракторный завод\"",
                "organisation": "Акционерное общество \"Петербургский тракторный завод\"",
                "type": "Другое",
                "category": 2,
                "address": "проспект Стачек, 47\nСанкт-Петербург, Россия, 198097"
            },
            "geometry": {"type": "Point", "coordinates": [30.260406, 59.880518]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Машиностроительное предприятие СПб ОАО \"Красный Октябрь\"",
                "organisation": "Санкт-Петербургское открытое акционерное общество «Красный Октябрь»",
                "type": "Другое",
                "category": 2,
                "address": "Политехническая улица, 13-15\nСанкт-Петербург, Россия, 194021"
            },
            "geometry": {"type": "Point", "coordinates": [30.365769, 59.996692]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения «Родильный дом № 18» ",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения  \"Родильный дом № 18\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "проспект Солидарности, 6\nСанкт-Петербург, Россия, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.491088, 59.925478]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка ООО \"Производственная компания \"Петербурженка\"",
                "organisation": "Общество с ограниченной  ответственностью \"Производственная компания \"Петербурженка\"",
                "type": "Другое",
                "category": 2,
                "address": "улица Ворошилова, 2Б\nСанкт-Петербург, Россия, 193318"
            },
            "geometry": {"type": "Point", "coordinates": [30.44112, 59.923491]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка № 2-195727, г.Санкт-Петербург, ул.Республиканская 22",
                "organisation": "Открытое акционерное общество научно-производственный комплекс \"Северная заря\"",
                "type": "Другое",
                "category": 2,
                "address": "Республиканская улица, 22\nСанкт-Петербург, Россия, 195112"
            },
            "geometry": {"type": "Point", "coordinates": [30.413614, 59.935561]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завод -  Общество с ограниченной ответственностью \"Петербургский завод прецизионных сплавов\"",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский завод прецизионных сплавов \"",
                "type": "Другое",
                "category": 2,
                "address": "27-я линия В.О., 6к2\nСанкт-Петербург, Россия, 199106"
            },
            "geometry": {"type": "Point", "coordinates": [30.259343, 59.92877]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка по адресу: 195426, г. Санкт-Петербург, улица Хасанская, дом 5, лит. А",
                "organisation": "Акционерное общество \"Автопарк №7 Спецтранс\"",
                "type": "Другое",
                "category": 2,
                "address": "195426, г. Санкт-Петербург, улица Хасанская, дом 5, лит. А"
            },
            "geometry": {"type": "Point", "coordinates": [30.479236, 59.936228]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Сервисное локомотивное депо \"Санкт-Петербург - Сортировочный Витебский\"",
                "organisation": "Общество с ограниченной ответственностью \"ЛокоТех-Сервис\" Сервисное локомотивное депо Санкт-Петербург - Сортировочный Витебский",
                "type": "Другое",
                "category": 2,
                "address": "Витебская Сортировочная улица, 31\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.350992, 59.884952]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пассажирское вагонное депо Санкт-Петербург Московский производственный участок по подготовке пассажирских вагонов в рейс Мурманск (1 группы) - структурное подразделение Северо-Западного филиала АО \"ФПК\"",
                "organisation": "Пассажирское вагонное депо Санкт-Петербург Московский  - структурное подразделение Северо-Западного филиала Акционерного общества «Федеральная пассажирская компания»",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.367389, 59.910715]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Пассажирское вагонное депо Санкт-Петербург Московский структурное подразделение Северо-Западного филиала АО \"ФПК\" , Санкт-Петербург-Витебский",
                "organisation": "Пассажирское вагонное депо Санкт-Петербург Московский  - структурное подразделение Северо-Западного филиала Акционерного общества «Федеральная пассажирская компания»",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "Невский проспект, 85\nСанкт-Петербург, Россия, 191036"
            },
            "geometry": {"type": "Point", "coordinates": [30.362059, 59.930146]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вагонный Участок Санкт-Петербург - Московский структурное подразделение Северо-Западного филиала АО \"ФПК\" (ЛВЧ-8)",
                "organisation": "Вагонный участок Санкт-Петербург Московский Северо-Западного филиала АО \"ФПК\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "набережная Обводного канала, 30к3\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.364368, 59.913113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка \"Красное Село\"  Филиала ООО \"Газпром трансгаз Санкт-Петербург\" Управление технологического транспорта и специальной техники",
                "organisation": "Общество с ограниченной ответственностью \"Газпром трансгаз Санкт-Петербург\"",
                "type": "Другое",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.086196, 59.733745]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Цех по ремонту оборудования, транспортная служба, производственно-диспетчерская служба",
                "organisation": "Санкт-Петербургское государственное унитарное предприятие \"Ленсвет\"",
                "type": "Другое",
                "category": 2,
                "address": "улица Руставели, 1\nСанкт-Петербург, Россия, 195273"
            },
            "geometry": {"type": "Point", "coordinates": [30.439234, 59.998243]}
        },
        {"type": "Feature",
            "properties": {
                "name": "195197, Санкт-Петербург, ул. Жукова, д. 23.",
                "organisation": "Общество с ограниченной ответсвенностью \"Руссо-Балт\"",
                "type": "Другое",
                "category": 2,
                "address": "195197, Санкт-Петербург, ул. Жукова, д. 23"
            },
            "geometry": {"type": "Point", "coordinates": [30.38812, 59.967074]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная по адресу Санкт-Петербург, г.Петергоф, Разводная улица, д.15, лит.Д",
                "organisation": "Общество с ограниченной ответственностью \"Энергосервис\"",
                "type": "Котельная",
                "category": 2,
                "address": "г.Петергоф, Разводная улица, д.15, лит.Д"
            },
            "geometry": {"type": "Point", "coordinates": [29.895412, 59.878658]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная по адресу Санкт-Петербург, г.Петергоф, Ропшинское шоссе, д.3, корп.11, лит.А",
                "organisation": "Общество с ограниченной ответственностью \"Энергосервис\"",
                "type": "Котельная",
                "category": 2,
                "address": "Санкт-Петербург, г.Петергоф, Ропшинское шоссе, д.3, корп.11, лит.А"
            },
            "geometry": {"type": "Point", "coordinates": [29.949382, 59.856153]}
        },
        {"type": "Feature",
            "properties": {
                "name": "котельная по адресу Санкт-Петербург, Морской переулок, дом 3",
                "organisation": "Общество с ограниченной ответственностью \"Энергосервис\"",
                "type": "Котельная",
                "category": 2,
                "address": "Санкт-Петербург, Морской переулок, дом 3"
            },
            "geometry": {"type": "Point", "coordinates": [30.296096, 59.910284]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Вагонный участок Санкт-Петербург - Московский структурное подразделение Северо-Западного филила АО \"ФПК\" (ЛВЧ-8)",
                "organisation": "Вагонный участок Санкт-Петербург Московский Северо-Западного филиала АО \"ФПК\"",
                "type": "Предприятия железнодорожного транспорта",
                "category": 2,
                "address": "набережная Обводного канала, 30к3\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.364368, 59.913113]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург г, пер.5-й Верхний, д.13, лит.А, 194292",
                "organisation": "Общество с ограниченной ответственностью \"СМУ \"Электронстрой-Эталон\"",
                "type": "Другое",
                "category": 2,
                "address": "Санкт-Петербург г, пер.5-й Верхний, д.13, лит.А, 194292"
            },
            "geometry": {"type": "Point", "coordinates": [30.364251, 60.072879]}
        },
        {"type": "Feature",
            "properties": {
                "name": "производственная площадка, 194362, г. Санкт-Петербург, пос. Парголово, ул. Донецкая, д. 2а.",
                "organisation": "Общество с ограниченной ответственностью \"Бетокон\"",
                "type": "Другое",
                "category": 2,
                "address": " 194362, г. Санкт-Петербург, пос. Парголово, ул. Донецкая, д. 2а."
            },
            "geometry": {"type": "Point", "coordinates": [30.323126, 60.081628]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения п. Понтонный",
                "organisation": "Государственное унитарное предприятие «Водоканал Санкт-Петербурга»",
                "type": "Водоканал",
                "category": 2,
                "address": "Заводская улица, 8\nтерритория Усть-Славянка, Санкт-Петербург, Россия, 192076"
            },
            "geometry": {"type": "Point", "coordinates": [30.546349, 59.832448]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Встроенная автоматизированная газовая водогрейная котельная установленной мощностью 0,320 МВт по адресу 195276, г. Санкт-Петербург, ул. Демьяна Бедного, д. 27, корпус 2, лит. А, пом. 3Н",
                "organisation": "Общество с ограниченной ответственностью \"Энергогазмонтаж\"",
                "type": "Другое",
                "category": 2,
                "address": "195276, г. Санкт-Петербург, ул. Демьяна Бедного, д. 27, корпус 2, лит. А,"
            },
            "geometry": {"type": "Point", "coordinates": [30.392252, 60.048584]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Политехническая ул., д.29",
                "organisation": "Федеральное государственное автономное образовательное учреждение высшего образования \"Санкт-Петербургский политехнический университет Петра Великого\"",
                "type": "Образовательное учреждение",
                "category": 2,
                "address": "Политехническая улица, 29\nСанкт-Петербург, Россия, 194064"
            },
            "geometry": {"type": "Point", "coordinates": [30.3731, 60.007461]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Отдельно стоящая автоматизированная водогрейная котельная установленной мощностью 5,0 МВт  по адресу 197022, Санкт-Петербург, Аптекарский пр., д. 16, корпус 2, литера П",
                "organisation": "Общество с ограниченной ответственностью \"Энергогазмонтаж\"",
                "type": "Другое",
                "category": 2,
                "address": "197022, Санкт-Петербург, Аптекарский пр., д. 16, корпус 2, литера П"
            },
            "geometry": {"type": "Point", "coordinates": [30.320737, 59.975495]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная площадка ООО \"Петербургский энергетик\"",
                "organisation": "Общество с ограниченной ответственностью \"Петербургский энергетик\"",
                "type": "Другое",
                "category": 2,
                "address": "Кондратьевский проспект, 3\nСанкт-Петербург, Россия, 195009"
            },
            "geometry": {"type": "Point", "coordinates": [30.375094, 59.958156]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ Клиническая больница Святителя Луки",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения Клиническая больница Святителя Луки",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Чугунная улица, 46\nСанкт-Петербург, Россия, 194044"
            },
            "geometry": {"type": "Point", "coordinates": [30.368274, 59.972018]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербургский Магистральный сортировочный центр -обособленное структурное подразделение УФПС г. Санкт-Петербурга и Ленинградской области - филиала ФГУП \"Почта России\". ",
                "organisation": "Управление федеральной почтовой связи г. Санкт-Петербурга и Ленинградской области - филиал ФГУП \"Почта России\"",
                "type": "Другое",
                "category": 2,
                "address": "Полтавский проезд, 9А\nСанкт-Петербург, Россия, 191036"
            },
            "geometry": {"type": "Point", "coordinates": [30.364739, 59.926352]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения п. Металлострой",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 2,
                "address": null
            },
            "geometry": {"type": "Point", "coordinates": [30.573514, 59.807803]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Канализационные очистные сооружения больницы им. П.П. Кащенко",
                "organisation": "Государственное унитарное предприятие \"Водоканал Санкт-Петербурга\"",
                "type": "Водоканал",
                "category": 2,
                "address": "Троицкий проспект, 3\nСанкт-Петербург, Россия, 190005"
            },
            "geometry": {"type": "Point", "coordinates": [30.300166, 59.916144]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"Родильный дом №10\"",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Родильный дом №10\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "улица Тамбасова, 21\nСанкт-Петербург, Россия, 198259"
            },
            "geometry": {"type": "Point", "coordinates": [30.155365, 59.831037]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Санкт-Петербург, г. Петергоф, Астрономическая ул., д.8 к.2, лит.В",
                "organisation": "Общество с ограниченной ответственностью \"Тенсар Инновэйтив Солюшнз\"",
                "type": "Другое",
                "category": 2,
                "address": "Санкт-Петербург, г. Петергоф, Астрономическая ул., д.8 к.2, лит.В"
            },
            "geometry": {"type": "Point", "coordinates": [29.807871, 59.869476]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Завод",
                "organisation": "Филиал Общества с ограниченной ответственностью \"ПепсиКо Холдингс\" в г. Санкт- Петербург",
                "type": "Другое",
                "category": 2,
                "address": "5-й Верхний переулок, 2\nСанкт-Петербург, Россия, 194292"
            },
            "geometry": {"type": "Point", "coordinates": [30.378595, 60.067717]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производство ООО \"Керамика\" на площадке по адресу: Санкт-Петербург, ул. Курчатова, д.10",
                "organisation": "Общество с ограниченной ответственностью \"Керамика\" ",
                "type": "Другое",
                "category": 2,
                "address": "Санкт-Петербург, ул. Курчатова, д.10"
            },
            "geometry": {"type": "Point", "coordinates": [30.361026, 60.009121]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Городская поликлиника № 22",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \" Городская поликлиника № 22\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Финляндская улица, 13к4БТ\nКолпино, Санкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.574621, 59.753518]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка",
                "organisation": "ФКУ ИК-5 УФСИН России по г. Санкт-Петербургу и Ленинградской области",
                "type": "Другое",
                "category": 2,
                "address": "Северный проезд, 1\nпосёлок Металлострой, Колпинский район, Санкт-Петербург, Россия, 196641"
            },
            "geometry": {"type": "Point", "coordinates": [30.544759, 59.803976]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка ФГБУ «Ленинградская МВЛ», г. Санкт-Петербург, Московское шоссе, д. 15",
                "organisation": "Федеральное государственное бюджетное учреждение «Ленинградская межобластная ветеринарная лаборатория»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "г. Санкт-Петербург, Московское шоссе, д. 15"
            },
            "geometry": {"type": "Point", "coordinates": [30.357451, 59.822177]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ \"ГВВ\", госпиталь",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Госпиталь для ветеранов войн\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Народная улица, 21к2\nСанкт-Петербург, Россия"
            },
            "geometry": {"type": "Point", "coordinates": [30.46847, 59.879059]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Производственная территория",
                "organisation": "Филиал \"Сименс Мобилити ГМбХ\" в г. Санкт-Петербурге",
                "type": "Другое",
                "category": 2,
                "address": "196641, г. Санкт-Петербург, Металлострой п., На Металлострой дор., д. 3, к. 10, стр. 1"
            },
            "geometry": {"type": "Point", "coordinates": [30.547822, 59.784852]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Обособленное подразделение завод ЖБИ Управления производственно-технической комплектации филиал ОАО \"Метрострой\"",
                "organisation": "Открытое акционерное общество по строительству метрополитена в городе Санкт-Петербурге Метрострой",
                "type": "Предприятие метрополитена",
                "category": 2,
                "address": "Загородный проспект, 52А\nСанкт-Петербург, Россия, 190013"
            },
            "geometry": {"type": "Point", "coordinates": [30.330249, 59.920522]}
        },
        {"type": "Feature",
            "properties": {
                "name": "Площадка № 1",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Стоматологическая поликлиника № 31 Невского района\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "проспект Солидарности, 12к1\nСанкт-Петербург, Россия, 193312"
            },
            "geometry": {"type": "Point", "coordinates": [30.494843, 59.919759]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПБ ГБУЗ \"Городская Покровская Больница\"",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение здравоохранения \"Городская Покровская Больница\"",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Большой проспект Васильевского острова, 85К\nСанкт-Петербург, Россия, 199106"
            },
            "geometry": {"type": "Point", "coordinates": [30.252417, 59.931446]}
        },
        {"type": "Feature",
            "properties": {
                "name": "СПб ГБУЗ «Городская поликлиника № 32» (Станция неотложной медицинской помощи)",
                "organisation": "Санкт-Петербургское государственное бюджетное учреждение  здравоохранения «Городская поликлиника № 32»",
                "type": "Медицинское учреждение",
                "category": 2,
                "address": "Вяземский переулок, 3\nСанкт-Петербург, Россия, 197022"
            },
            "geometry": {"type": "Point", "coordinates": [30.303237, 59.971904]}
        }
    ]
}
