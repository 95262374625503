//https://t.me/elemnt3 Y:2021
import React, {useState} from "react";
import {Card, Col, Modal, Rate, Row} from 'antd';
import for_entities from '../../assets/images/for_entities.svg';
import r_1 from '../../assets/images/r_1.jpg';
import r_2 from '../../assets/images/r_2.jpg';
import r_3 from '../../assets/images/r_3.jpg';
import r_4 from '../../assets/images/r_4.jpg';
import r_5 from '../../assets/images/r_5.jpg';
import r_6 from '../../assets/images/r_6.jpg';
import EntitiesBase from "./dashComponent/EntitiesBase";
import EntitiesColumn from "./dashComponent/EntitiesColumn";
import MakeWordCloud from "./dashComponent/cloudy";
import GreenZones from "./dashComponent/GreenZones";
const {Meta} = Card;

const items = [
    {
        key: '1',
        iconImg: <img src={r_1}/>,
        title: 'Theme route #1',
    },
    {
        key: '2',
        iconImg: <img src={r_2}/>,
        title: 'Theme route #2',
    },
    {
        key: '3',
        iconImg: <img src={r_3}/>,
        title: 'Theme route #3',
    },
    {
        key: '4',
        iconImg: <img src={r_4}/>,
        title: 'Theme route #4',
    },
    {
        key: '5',
        iconImg: <img src={r_5}/>,
        title: 'Theme route #5',
    },
    {
        key: '6',
        iconImg: <img src={r_6}/>,
        title: 'Theme route #6',
    },
]

function Routes() {
    const [isModalVisibleEntitiesBase, setIsModalVisibleEntitiesBase] = useState(false);
    const showModalEntitiesBase = () => {
        setIsModalVisibleEntitiesBase(true);
    };
    const handleCancelEntitiesBase = () => {
        setIsModalVisibleEntitiesBase(false);
    };
    const [isModalVisibleGreenZones, setIsModalVisibleGreenZones] = useState(false);
    const showModalGreenZones = () => {
        setIsModalVisibleGreenZones(true);
    };
    const handleCancelGreenZones = () => {
        setIsModalVisibleGreenZones(false);
    };
    const [isModalVisibleEntitiesColumn, setIsModalVisibleEntitiesColumn] = useState(false);

    const showModalEntitiesColumn = () => {
        setIsModalVisibleEntitiesColumn(true);
    };
    const handleCancelEntitiesColumn = () => {
        setIsModalVisibleEntitiesColumn(false);
    };

    const [isModalVisiblePunchThems, setIsModalVisiblePunchThems] = useState(false);

    const showModalPunchThems = () => {
        setIsModalVisiblePunchThems(true);
    };
    const handleCancelPunchThems = () => {
        setIsModalVisiblePunchThems(false);
    };
    return (
        <div id='_analytics' className='block'>
            <div className='container-fluid' style={{ maxWidth: '1500px' }}>
                <div className="titleHolder">
                    <h2>Аналитика</h2>
                    <p>Сводные аналитические данные</p>
                </div>
                <Row gutter={[16, 1]}>
                    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                        <Card
                            hoverable
                            cover={<img alt="Топ зеленых зон" src={for_entities}/>}
                            onClick={showModalGreenZones}
                        >
                            <Rate disabled defaultValue={5}/>
                            <Meta title="Топ зеленых зон"/>
                        </Card>
                        <Modal
                            footer={null}
                            width={'100%'}
                            centered={true}
                            title="Топ зеленых зон"
                            visible={isModalVisibleGreenZones}
                            onCancel={handleCancelGreenZones}>
                            <GreenZones/>
                        </Modal>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                        <Card
                            hoverable
                            cover={<img alt="Рейтинг МО для всех возрастов" src={for_entities}/>}
                            onClick={showModalEntitiesBase}
                        >
                            <Rate disabled defaultValue={5}/>
                            <Meta title="Рейтинг МО для всех возрастов"/>
                        </Card>
                        <Modal
                            footer={null}
                            width={'100%'}
                            centered={true}
                            title="Рейтинг МО для всех возрастов"
                            visible={isModalVisibleEntitiesBase}
                            onCancel={handleCancelEntitiesBase}>
                            <EntitiesBase/>
                        </Modal>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                        <Card
                            hoverable
                            cover={<img alt="Популярные городские объекты" src={for_entities}/>}
                            onClick={showModalEntitiesColumn}
                        >
                            <Rate disabled defaultValue={5}/>
                            <Meta title="Популярные городские объекты"/>
                        </Card>
                        <Modal
                            footer={null}
                            width={'100%'}
                            centered={true}
                            title="Популярные городские объекты"
                            visible={isModalVisibleEntitiesColumn}
                            onCancel={handleCancelEntitiesColumn}>
                            <EntitiesColumn/>
                        </Modal>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                        <Card
                            hoverable
                            cover={<img alt="PunchThems" src={for_entities}/>}
                            onClick={showModalPunchThems}
                        >
                            <Rate disabled allowHalf defaultValue={5}/>
                            <Meta title="Облако 'Топ городских объектов'"/>
                        </Card>
                        <Modal
                            footer={null}
                            width={'100%'}
                            centered={true}
                            title="Городские объекты "
                            visible={isModalVisiblePunchThems}
                            onCancel={handleCancelPunchThems}>
                            <MakeWordCloud/>
                        </Modal>
                    </Col>
                    {/*<Col xs={{span: 24}} sm={{span: 12}} md={{span: 8}}>*/}
                    {/*    <Card hoverable cover={<img alt="Тематический маршрут #4" src={r_6}/>}>*/}
                    {/*        <Rate disabled allowHalf defaultValue={4.5}/>*/}
                    {/*        <Meta title="Тематический маршрут #4"/>*/}
                    {/*    </Card>*/}
                    {/*</Col>*/}
                    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 8}}>
                        {/*<Card hoverable cover={<img alt="Тематический маршрут #5" src={r_6}/>}>*/}
                        {/*    <Rate disabled defaultValue={4}/>*/}
                        {/*    <Meta title="Тематический маршрут #5"/>*/}
                        {/*</Card>*/}
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 8}}>
                        {/*<Card*/}
                        {/*    hoverable*/}
                        {/*    cover={<img alt="Тематический маршрут #6" src={r_6}/>}*/}
                        {/*>*/}
                        {/*    <Rate disabled defaultValue={4}/>*/}
                        {/*    <Meta title="Тематический маршрут #6"/>*/}
                        {/*</Card>*/}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Routes
